import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { connect } from "react-redux";
import {
  ChipCompo,
  MyComponentWithIconProps,
} from "../../components/Comman/IconButton";
import { getClassById } from "./class.slice";

function Row({
  row,
  rowAdd,
  rowClassDetailDelete,
  rowClassDetailEdit,
  rowClassDetailStatus,
  rowStatus,
  rowDelete,
  rowEdit,
  getClassById,
}) {
  const [open, setOpen] = React.useState(false);

  const changeStatus = (e, id) => {
    e.stopPropagation();
    rowStatus(id);
  };
  const onClickDelete = (e, id) => {
    e.stopPropagation();
    rowDelete(id);
  };
  const onClickAdd = (e, id) => {
    e.stopPropagation();
    rowAdd(id);
  };
  const onClickEdit = (e, id) => {
    e.stopPropagation();
    rowEdit(id);
  };
  const onClickClassDetailDelete = (e, id) => {
    e.stopPropagation();
    rowClassDetailDelete(id);
  };
  const onClickClassDetailEdit = (e, id, classdDetailid) => {
    e.stopPropagation();
    rowClassDetailEdit(id, classdDetailid);
  };
  const changeClassDetailStatus = (e, id, classdDetailid) => {
    e.stopPropagation();
    rowClassDetailStatus(id, classdDetailid);
  };

  React.useEffect(() => {});
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="td" scope="row">
          <center> {row.index}</center>
        </TableCell>
        <TableCell>{row.progName}</TableCell>
        <TableCell>{row.tenantName}</TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell align="right">{row.sortOrderNo}</TableCell>

        <TableCell>
          {row.isActive == "Active" ? (
            <center>
              {" "}
              <ChipCompo
                label="Active"
                variant="outlined"
                size="small"
                color="success"
                onClick={(e) => changeStatus(e, row.id)}
              />
            </center>
          ) : (
            <center>
              <ChipCompo
                label="Inactive"
                variant="outlined"
                size="small"
                color="error"
                onClick={(e) => changeStatus(e, row.id)}
              />
            </center>
          )}
        </TableCell>
        <TableCell>
          <MyComponentWithIconProps
            statusImage={EditIcon}
            color="primary"
            fontSize="medium"
            title="Edit"
            onClick={(e) => onClickEdit(e, row.id)}
          />
          &nbsp;&nbsp;
          <MyComponentWithIconProps
            statusImage={DeleteIcon}
            color="error"
            fontSize="medium"
            title="Delete"
            onClick={(e) => onClickDelete(e, row.id)}
          />
          &nbsp;&nbsp;
          <MyComponentWithIconProps
            statusImage={AddCircleIcon}
            color="primary"
            fontSize="medium"
            title="Add Year Details"
            onClick={(e) => onClickAdd(e, row.id)}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <center>Sr. No.</center>
                    </TableCell>
                    <TableCell>Program Title</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.classDetails.map((historyRow, index) => (
                    <TableRow key={historyRow.id}>
                      <TableCell component="td" scope="row">
                        <center> {index + 1}</center>
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {historyRow.courseName}
                      </TableCell>
                      <TableCell>
                        <center>
                          {historyRow.isActive == "Active" ? (
                            <ChipCompo
                              label="Active"
                              variant="outlined"
                              size="small"
                              color="success"
                              onClick={(e) =>
                                changeClassDetailStatus(
                                  e,
                                  row.id,
                                  historyRow.id
                                )
                              }
                            />
                          ) : (
                            <ChipCompo
                              label="Inactive"
                              variant="outlined"
                              size="small"
                              color="error"
                              onClick={(e) =>
                                changeClassDetailStatus(
                                  e,
                                  row.id,
                                  historyRow.id
                                )
                              }
                            />
                          )}
                        </center>
                      </TableCell>
                      <TableCell>
                        <MyComponentWithIconProps
                          statusImage={EditIcon}
                          color="primary"
                          fontSize="medium"
                          title="Edit"
                          onClick={(e) =>
                            onClickClassDetailEdit(e, row.id, historyRow.id)
                          }
                        />{" "}
                        <MyComponentWithIconProps
                          statusImage={DeleteIcon}
                          color="error"
                          fontSize="medium"
                          title="Delete"
                          onClick={(e) =>
                            onClickClassDetailDelete(e, historyRow.id)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function CollapsibleTable({
  getClassById,
  rowAdd,
  rowClassDetailEdit,
  rowClassDetailDelete,
  rowClassDetailStatus,
  rowStatus,
  rowDelete,
  rowEdit,
  classInfo,
  data,
}) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Sr. No.</TableCell>
            <TableCell>Program Type </TableCell>
            <TableCell>Institute Name</TableCell>
            <TableCell>Name</TableCell>

            <TableCell width="175px">Sort Order Number</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <Row
              key={row.id}
              classInfo={classInfo}
              getClassById={getClassById}
              row={row}
              rowEdit={rowEdit}
              rowAdd={rowAdd}
              rowStatus={rowStatus}
              rowDelete={rowDelete}
              rowClassDetailStatus={rowClassDetailStatus}
              rowClassDetailEdit={rowClassDetailEdit}
              rowClassDetailDelete={rowClassDetailDelete}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const mapStateToProps = (state) => ({
  classInfo: state.class,
});

const mapDispatchToProps = {
  getClassById,
};

export default connect(mapStateToProps, mapDispatchToProps)(CollapsibleTable);
