
  // import { createSlice } from "@reduxjs/toolkit";
// import { cloneDeep } from "lodash";
// import endpoints from "../../../config/endpoints";
import axios from "axios";
import { decodeData } from "../../components/Comman/Util/Base64";

export const downloadF = ( { url, ext = "pdf", openNewTab = false } ) =>
{
  try
  {
    const name = localStorage.getItem( "token" );
    const token = decodeData( name );
    axios
      .get( url, {
        headers: {
          Authorization: token,
          shouldrefresh: "true",
        },
        responseType: "blob",
      } )
      .then( ( response ) =>
      {
        if ( !openNewTab || ext !== "pdf" )
        {
          var blob = new Blob( [ response.data ], { type: "application/" + ext } );
          var link = document.createElement( "a" );
          link.href = window.URL.createObjectURL( blob );
          link.download = "Report_" + new Date() + "." + ext;
          link.click();
        } else if ( openNewTab && ext === "pdf" )
        {
          const file = new Blob( [ response.data ], { type: "application/pdf" } );
          const fileURL = URL.createObjectURL( file );
          const pdfWindow = window.open();
          pdfWindow.location.href = fileURL;
        }
      } )
      .catch( ( error ) =>
      {
        console.log( "Error ========>", error );
      } );
  } catch ( e )
  {
    return console.error( e.message );
  }
};

// export const downdloadF = ( { url } ) =>
//     async ( dispatch ) =>
//     {
//         try
//         {
//             const response = apiGet( {
//                 url: URL
//             } ).then( response =>
//             {
//                 var blob = new Blob( [ response.data ], { type: "application/pdf" } );
//                 var link = document.createElement( 'a' );
//                 link.href = window.URL.createObjectURL( blob );
//                 link.download = "Report_" + new Date() + ".pdf";
//                 link.click();
//             } ).catch( ( error ) =>
//             {
//                 console.log( "Error ========>", error );

//             } );
//         } catch ( e )
//         {
//             return console.error( e.message );
//         }
//     };

//                 if ( success )
//                 {
//                     const preparedCertificateList = data.data;
//                     console.log( preparedCertificateList );
//                     let row = [];
//                     let index = 0;
//                     row = preparedCertificateList.map( ( data ) =>
//                     {
//                         index = index + 1;
//                         let companyData = {
//                             index: index,
//                             id: data.id,
//                         };
//                         return companyData;
//                     } );
//                     dispatch( preparedCertificateListSuccess( { row } ) );
//                 }
//                 return success;
//             } );
//             return response;
//         } catch ( e )
//         {
//             return console.error( e.message );
//         }
//     };
