import { Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Alert from "../../Alert/Alert";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicTable from "../../components/Comman/MaterialUIRTTable";
import * as myConstClass from "../../config/messageconstant";
import {
  getApplicantById,
  getApplicantPrefByAppId,
  getRoundDetailsById,
  resetDelete,
} from "../ApplicationForm/applicant.slice";
import { logOut } from "../Login/login.slice";
import {
  getApplicantByDeclrationId,
  getDeclrationById,
} from "../onlineAppProcess/declaration.slice";
import StudentPreview1 from "../StudentPreview1/index";

const CapRoundDetails = ({
  getApplicantById,
  applicantData,
  getDeclrationById,
  goToApplicant,
  getApplicantPrefByAppId,
  logOut,
  loginUser,
}) => {
  let navigate = useNavigate();
  const [isEdit, setEdit] = useState(false);
  const [applicant, setApplicant] = useState(false);

  const [trustList, setTrustList] = React.useState([
    [{ title: "Sr. No.", name: "id" }],
    [{ title: "Round Details", name: "name" }],
  ]);
  const getRowID = async (id) => {
    console.log(id);
    getDeclrationById({ appliId: id }).then(() => {
      setEdit(true);
    });
  };
  useEffect(() => {
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
  });
  const getRowData = async (id) => {
    console.log(id);
    getApplicantPrefByAppId({ appliId: id });
    setApplicant(true);
  };

  useEffect(() => {
    setApplicant(false);
  }, []);

  const onClose = () => {
    goToApplicant();
  };

  const applicantDetails = (id) => {
    console.log(id);
    setApplicant(true);
    getApplicantById({ appliId: id });
  };
  const backToList = () => {
    setApplicant(false);
  };

  return (
    <>
      {applicantData.isServerFail && (
        <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />
      )}
      <Paper sx={{ borderRadius: 2 }} elevation={0}>
        {!applicant && (
          <>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              spacing={2}
              columnSpacing={1}
              container
              sx={{ m: 0.5 }}
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 20 }}
                    label="Round Details"
                  />
                </Grid>
              </Grid>
            </Grid>
            <br />
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <DynamicTable
                data={applicantData?.roundDetailsList}
                tableHead={[
                  {
                    title: "Sr. No.",
                    name: "index",
                    positionCenter: true,
                    alignCenter: "center",
                    showInscreen: true,
                  },
                  {
                    title: "Round Details",
                    name: "name",
                    positionCenter: false,
                    showInscreen: true,
                  },
                  {
                    title: "Action",
                    name: "action",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                  },
                ]}
                rowViewData={getRowData}
                showView={true}
              />
            </Box>
            <br />
            <div align="right">
              <ButtonCompo
                size="medium"
                type="button"
                variant="contained"
                name="Back"
                onClick={onClose}
              />
            </div>
          </>
        )}

        {applicant && applicantData?.isFetchApplicantData && (
          <StudentPreview1
            backToCap={backToList}
            prefCapList={applicantData?.applicantData}
            data={applicantData?.applicantData}
          />
        )}
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({
  appliData: state.appli,
  applicantData: state.applicant,
  loginUser: state.user,
});

const mapDispatchToProps = {
  getApplicantByDeclrationId,
  getApplicantPrefByAppId,
  getApplicantById,
  resetDelete,
  getRoundDetailsById,
  getDeclrationById,
  logOut,
};
export default connect(mapStateToProps, mapDispatchToProps)(CapRoundDetails);
