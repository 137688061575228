import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Buttton";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../components/Comman/Label";
import { resetDelete } from "../ApplicationForm/applicant.slice";
import { logOut } from "../Login/login.slice";
import InstitudeTable from "./StudentDirectAdmissionQualificationTable";
import DynamicTable from "../../components/Comman/MaterialUIRTTable";
import { onlyNumber } from "../../components/Comman/Util/Validations";
const university = [
  {
    id: "Directorate of Arts Maharashtra State",
    name: "Directorate of Arts Maharashtra State",
  },

  {
    id: "Maharashtra State Board of Secondary and Higher Secondary Education",
    name: "Maharashtra State Board of Secondary and Higher Secondary Education",
  },
  {
    id: "Maharashtra state Board of Technical Education",
    name: "Maharashtra state Board of Technical Education",
  },
  {
    id: "Maharashtra state Board of Vocational Education Examination(MSBVEE)",
    name: "Maharashtra state Board of Vocational Education Examination(MSBVEE)",
  },
  {
    id: "Shreemati Nathibai Damodar Thackarsey Women's University",
    name: "Shreemati Nathibai Damodar Thackarsey Women's University",
  },
  {
    id: "University of Mumbai",
    name: "University of Mumbai",
  },
];
const delhiUniversity = [
  {
    id: "Board of Higher Secondary Education",
    name: "Board of Higher Secondary Education",
  },

  {
    id: "Board of Technical Education, Delhi",
    name: "Board of Technical Education, Delhi",
  },
  {
    id: "Central Board of Secondary Education",
    name: "Central Board of Secondary Education",
  },
  {
    id: "Council for the Indian School Certificate Examinations",
    name: "Council for the Indian School Certificate Examinations",
  },
  {
    id: "International Board",
    name: "International Board",
  },
  {
    id: "National Institute of open Schooling",
    name: "National Institute of open Schooling",
  },
  {
    id: "Rashtriya Sanskrit Sansthanam, Delhi",
    name: "Rashtriya Sanskrit Sansthanam, Delhi",
  },
  {
    id: "Urdu Education Board",
    name: "Urdu Education Board",
  },
];
// let row = [];
const columns = [
  {
    title: "qualification",
    name: "qualification",
    positionCenter: false,
    alignCenter: "left",
    showInscreen: true,
  },
  {
    title: "Country",
    name: "country",
    positionCenter: true,
    alignCenter: "left",
    showInscreen: true,
  },
  {
    title: "Board",
    name: "board",
    positionCenter: true,
    alignCenter: "left",
    showInscreen: true,
  },
  {
    title: "University",
    name: "university",
    positionCenter: true,
    alignCenter: "left",
    showInscreen: true,
  },
  {
    title: "School / Collage",
    name: "school",
    positionCenter: true,
    alignCenter: "left",
    showInscreen: true,
  },
  {
    title: "Exam Seat Number",
    name: "examSeat",
    positionCenter: true,
    alignCenter: "left",
    showInscreen: true,
  },
  {
    title: "Certificate",
    name: "certificate",
    positionCenter: true,
    alignCenter: "center",
    showInscreen: true,
  },
  {
    title: "Passing Year",
    name: "passingYear",
    positionCenter: true,
    alignCenter: "center",
    showInscreen: true,
  },
  {
    title: "Marks Obtain",
    name: "marksObtain",
    positionCenter: true,
    alignCenter: "right",
    showInscreen: true,
  },
  {
    title: "Marks Out Of",
    name: "marksOutOf",
    positionCenter: true,
    alignCenter: "right",
    showInscreen: true,
  },

  {
    title: "Percentage",
    name: "percentage",
    positionCenter: true,
    alignCenter: "right",
    showInscreen: true,
  },

  {
    title: "Action",
    name: "action",
    positionCenter: true,
    alignCenter: "center",
    showInscreen: true,
  },
];
const QualificationDetails = ({
  loginUser,
  resetDelete,
  logOut,
  applicationList,
  saveAllData,
  getQualiDetails,
  backToInstructions,
}) => {
  const [qualiList, setQualiList] = useState([]);
  const [rowData, setRowData] = useState([]);

  const toDate = applicationList?.applicationData?.startDate.split("-");
  const appToDate =
    toDate === "" ? "" : new Date(toDate[2], toDate[1] - 1, toDate[0]);
  const fromDate = applicationList?.applicationData?.lastDate.split("-");
  const appFromDate =
    fromDate === "" ? "" : new Date(fromDate[2], fromDate[1] - 1, fromDate[0]);

  const navigate = useNavigate();
  const [qualification, setQualification] = useState("");
  const [formErrors, setformErrors] = useState({});
  const [formData, setFormData] = useState({});

  useEffect(() => {
    console.log(saveAllData);
    console.log(applicationList.applicationData);
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
    if (rowData.length === 0 && qualiList.length === 0) {
      // row = applicationList?.documentData.filter((row) => row.type === 1);
      setRowData(applicationList?.documentData.filter((row) => row.type === 1));
    }
  }, []);

  const QualiDetails = () => {
    if (rowData.length === 0 && qualiList.length !== 0) {
      getQualiDetails(qualiList);
    } else {
      let formError = {
        emptyListError: "Please add all qualification details",
      };
      setformErrors(formError);
    }
  };

  const ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      setQualification(newValue);
    } else {
      setQualification("");
    }
  };
  const changedHandler = (e) => {
    const { value, name } = e.target;
    e.preventDefault();
    let data = {};
    if (value === "") {
      data = {
        ...formData,
        [name]: "",
        percentage: "",
      };
    } else {
      if (onlyNumber.test(value)) {
        if (name === "marksOutOf") {
          if (
            formData.marksObtain !== undefined &&
            formData.marksObtain !== ""
          ) {
            let per = (formData.marksObtain / value) * 100;
            data = {
              ...formData,
              [name]: value,
              percentage: per.toFixed(2),
            };
          } else {
            data = {
              ...formData,
              [name]: value,
              percentage: "",
            };
          }
        } else {
          if (formData.marksOutOf !== undefined && formData.marksOutOf !== "") {
            let per = (value / formData.marksOutOf) * 100;

            data = {
              ...formData,
              [name]: value,
              percentage: per.toFixed(2),
            };
          } else {
            data = {
              ...formData,
              [name]: value,
              percentage: "",
            };
          }
        }
      }
    }
    setFormData(data);
  };

  const changeHandler = (e) => {
    const { value, name } = e.target;
    let data = {
      ...formData,
      [name]: value,
    };
    setFormData(data);
  };
  const submitHandler = () => {
    if (checkValidation()) {
      console.log(qualification);
      console.log(formData);
      const qualificationList = rowData.filter(
        (row) => row.id === qualification
      );

      let dataList = {
        qualification: qualificationList[0].name,
        id: qualification,
        country:
          formData.country === "Other"
            ? formData.countryName
            : formData.country,
        board: formData.board,
        state:
          formData.qualificationStatus === undefined
            ? "Completed"
            : formData.qualificationStatus,
        university: formData.university,
        school: formData.school,
        examSeat: formData.examSeat,
        certificate:
          formData.certificate == undefined ? "" : formData.certificate,
        // certificate: formData.certificate,
        passingYear: formData.passingYear,
        marksObtain: formData.marksObtain,
        marksOutOf: formData.marksOutOf,
        percentage: formData.percentage,
      };
      console.log(dataList);
      const filteredQualification = rowData.filter(
        (row) => row.id !== qualification
      );

      setRowData(filteredQualification);
      qualiList.push(dataList);
      setQualification("");
      setFormData({});
      setformErrors({});
    }
  };
  const checkValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (!formData.country) {
      formIsValid = false;
      formErrors["countryError"] = "*Select country";
    }

    if (qualification === "") {
      formIsValid = false;
      formErrors["qualificationError"] = "*Select qualification";
    }
    if (formData.country == 1 && !formData.countryName) {
      formIsValid = false;
      formErrors["countryNameError"] = "*Enter country";
    }

    if (!formData.board) {
      formIsValid = false;
      formErrors["boardError"] = "*Select board";
    }
    if (!formData.university) {
      formIsValid = false;
      formErrors["uniError"] =
        formData.board == 2 ? "*University is required" : "*Select university";
    }

    if (!formData.school) {
      formIsValid = false;
      formErrors["schoolError"] = "*School is required";
    }

    if (!formData.examSeat) {
      formIsValid = false;
      formErrors["examSeatError"] = "*Exam seat number is required";
    }

    // if (!formData.certificate) {
    // formIsValid = false;
    // formErrors["certificateError"] = "*Certificate number is required";
    // }
    if (!formData.passingYear) {
      formIsValid = false;
      formErrors["passingYearError"] = "*Passing year is required";
    } else if (formData.passingYear && formData.passingYear.length !== 4) {
      formIsValid = false;
      formErrors["passingYearError"] = "*Maximum 4 digit required";
    }

    if (formData.qualificationStatus !== "Pursuing") {
      if (!formData.marksOutOf) {
        formIsValid = false;
        formErrors["marksOutOfError"] = "*Marks out of is required";
      } else if (!onlyNumber.test(formData.marksOutOf)) {
        formIsValid = false;
        formErrors["marksOutOfError"] = "*Only number allowed";
      } else if (
        formData.marksObtain &&
        formData.marksOutOf &&
        +formData.marksObtain > +formData.marksOutOf
      ) {
        formIsValid = false;
        formErrors["marksOutOfError"] =
          "*Out of marks must be greater than obtain marks";
      }
    }
    if (formData.qualificationStatus !== "Pursuing") {
      if (!formData.marksObtain) {
        formIsValid = false;
        formErrors["marksObtainError"] = "*Marks obtain is required";
      } else if (!onlyNumber.test(formData.marksObtain)) {
        formIsValid = false;
        formErrors["marksOutOfError"] = "*Only number allowed ";
      } else if (
        formData.marksObtain &&
        formData.marksOutOf &&
        +formData.marksObtain > +formData.marksOutOf
      ) {
        formIsValid = false;
        formErrors["marksObtainError"] =
          "*Obtain marks must be less than out of marks";
      }
    }

    setformErrors(formErrors);
    return formIsValid;
  };
  const rowDelete = (id) => {
    swal("Do you want to delete", {
      buttons: ["Cancel", true],
    }).then((willDelete) => {
      if (willDelete) {
        const filteredQualification = qualiList.filter((row) => row.id !== id);
        const qualificationList = applicationList?.documentData.filter(
          (row) => row.id === id
        );
        let rows = [];
        rows = [...rowData, qualificationList[0]];
        setRowData(rows);
        setQualification("");
        setQualiList(filteredQualification);
      }
    });
  };
  return (
    <>
      {/* {applicationList.isServerFail && <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete}/>} */}
      <Grid
        item
        sm={12}
        xs={12}
        md={12}
        lg={12}
        rowSpacing={2}
        spacing={2}
        columnSpacing={1}
        container
        justifyContent="space-evenly"
      >
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          container
          justifyContent="space-evenly"
        >
          {/* <Grid item xs={12} sm={12} md={0.5}></Grid> */}
          <Grid item xs={12} sm={12} md={12}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 20 }}
              label="Application Form"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-evenly"
        direction="row"
        // spacing={2}
      >
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          sx={{ marginTop: 3 }}
          container
          justifyContent="space-evenly"
        >
          {/* <Grid item xs={12} sm={12} md={0.5}></Grid> */}
          <Grid item xs={12} sm={12} md={12}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 16 }}
              label="Application Details :"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              color="primary"
              name="appdet"
              size="small"
              value={
                applicationList?.applicationData !== ""
                  ? applicationList?.applicationData?.title
                  : ""
              }
              fullWidth
              disabled
            />
          </Grid>
        </Grid>

        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          sx={{ marginTop: 3 }}
          container
          spacing={1}
          justifyContent="space-evenly"
        >
          <Grid
            item
            sm={6}
            xs={12}
            md={6}
            lg={6}
            container
            justifyContent="space-evenly"
          >
            {/* <Grid item xs={12} sm={12} md={0.5}></Grid> */}
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Application From Date :"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <BasicDatePicker
                name="displayFrom"
                value={appToDate}
                disabled={true}
                error={" "}
              />
            </Grid>
          </Grid>

          <Grid
            item
            sm={6}
            xs={12}
            md={6}
            lg={6}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Application To Date :"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <BasicDatePicker
                name="displayTo"
                value={appFromDate}
                disabled={true}
                error={" "}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          container
          justifyContent="space-evenly"
        >
          <Grid sx={{ mb: -2 }} item xs={12} sm={12} md={12}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 16 }}
              label="Qualification :"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <AutoComplete
              keyColName={"id"}
              value={qualification}
              name={"qualification"}
              options={rowData}
              onChange={ChangeHandlerSearch}
            />
            <FormHelperText error>
              {formErrors.qualificationError
                ? formErrors.qualificationError
                : " "}
            </FormHelperText>
          </Grid>
        </Grid>

        {qualification !== "" && (
          <>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs :12, sm :6, md :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Qualification Status :"
                  />
                </Grid>

                <Grid sx={{ mb: 1 }} item xs={12} sm={12} md={12}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="Completed"
                      name="qualificationStatus"
                      value={
                        formData.qualificationStatus
                          ? formData.qualificationStatus
                          : "Completed"
                      }
                      onChange={changeHandler}
                      row
                    >
                      <FormControlLabel
                        value="Completed"
                        control={<Radio />}
                        label="Completed"
                      />
                      <FormControlLabel
                        value="Pursuing"
                        control={<Radio />}
                        label="Pursuing"
                      />
                    </RadioGroup>
                  </FormControl>
                  {/* <FormControl color={"primary"} fullWidth={true}>
    <Select
    className="text-black"
    name={"qualificationStatus"}
    value={formData.qualificationStatus ? formData.qualificationStatus : "Completed"}
    onChange={changeHandler}
    size="small"
    fullWidth={true}
    {...(formErrors.boardError
    ? {
    error: true,
    }
    : " ")}
    >
    <MenuItem value="">
    <em>NA</em>
    </MenuItem>
   
    <MenuItem value="Completed">
    Completed
    </MenuItem>
   
    <MenuItem value="Pursuing">
    Pursuing
    </MenuItem>
    </Select>
   
    <FormHelperText error>
    {formErrors.boardError ? formErrors.boardError : " "}
    </FormHelperText>
    </FormControl> */}
                </Grid>
                {/* {formErrors.boardError && <FormHelperText error>{formErrors.boardError} </FormHelperText>} */}
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs :12, sm :6, md :1}}
                container
                justifyContent="space-evenly"
              ></Grid>
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs :12, sm :6, md :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Country :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      className="text-black"
                      name={"country"}
                      value={formData.country ? formData.country : ""}
                      onChange={changeHandler}
                      size="small"
                      fullWidth={true}
                      {...(formErrors.countryError
                        ? {
                            error: true,
                          }
                        : "")}
                    >
                      <MenuItem value="">
                        <em>NA</em>
                      </MenuItem>

                      <MenuItem value="India">India</MenuItem>

                      <MenuItem value="Other">Other</MenuItem>
                    </Select>

                    <FormHelperText error>
                      {formErrors.countryError ? formErrors.countryError : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                sx={{ marginTop: 3 }}
                // columnSpacing={{xs :12, sm :6, md :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  {formData.country === "Other" && (
                    <TextField
                      color="primary"
                      name="countryName"
                      size="small"
                      value={formData.countryName ? formData.countryName : ""}
                      onChange={changeHandler}
                      fullWidth
                      error={formErrors.countryNameError ? true : false}
                      helperText={
                        formErrors.countryNameError
                          ? formErrors.countryNameError
                          : " "
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs :12, sm :6, md :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*State :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      className="text-black"
                      name={"board"}
                      value={formData.board ? formData.board : ""}
                      onChange={changeHandler}
                      size="small"
                      fullWidth={true}
                      {...(formErrors.boardError
                        ? {
                            error: true,
                          }
                        : " ")}
                    >
                      <MenuItem value="">
                        <em>NA</em>
                      </MenuItem>

                      <MenuItem value="Maharashtra State board">
                        Maharashtra State board
                      </MenuItem>

                      <MenuItem value="Delhi/Center Boards">
                        Delhi/Center Boards
                      </MenuItem>

                      <MenuItem value="Other">Other</MenuItem>
                    </Select>

                    <FormHelperText error>
                      {formErrors.boardError ? formErrors.boardError : " "}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs :12, sm :6, md :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*University :"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  {formData.board != "Other" && (
                    <FormControl color={"primary"} fullWidth={true}>
                      <Select
                        className="text-black"
                        name={"university"}
                        value={formData.university ? formData.university : ""}
                        onChange={changeHandler}
                        size="small"
                        fullWidth={true}
                        {...(formErrors.uniError
                          ? {
                              error: true,
                            }
                          : "")}
                      >
                        <MenuItem value="">
                          <em>NA</em>
                        </MenuItem>

                        {formData.board === "Maharashtra State board"
                          ? university.map((uni) => {
                              return (
                                <MenuItem value={uni.id}>{uni.name}</MenuItem>
                              );
                            })
                          : delhiUniversity.map((uni) => {
                              return (
                                <MenuItem value={uni.id}>{uni.name}</MenuItem>
                              );
                            })}
                      </Select>

                      <FormHelperText error>
                        {formErrors.uniError ? formErrors.uniError : " "}
                      </FormHelperText>
                    </FormControl>
                  )}
                  {formData.board == "Other" && (
                    <TextField
                      color="primary"
                      name="university"
                      size="small"
                      value={formData.university ? formData.university : ""}
                      onChange={changeHandler}
                      fullWidth
                      error={formErrors.uniError ? true : false}
                      helperText={
                        formErrors.uniError ? formErrors.uniError : " "
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs :12, sm :6, md :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*School/Collage (Full name) :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="school"
                    size="small"
                    value={formData.school ? formData.school : ""}
                    onChange={changeHandler}
                    fullWidth
                    error={formErrors.schoolError ? true : false}
                    helperText={
                      formErrors.schoolError ? formErrors.schoolError : " "
                    }
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs :12, sm :6, md :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Certificate Number :"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="certificate"
                    size="small"
                    value={formData.certificate ? formData.certificate : ""}
                    onChange={changeHandler}
                    fullWidth
                    error={formErrors.certificateError ? true : false}
                    helperText={" "}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs :12, sm :6, md :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Exam Seat Number :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="examSeat"
                    size="small"
                    value={formData.examSeat ? formData.examSeat : ""}
                    onChange={changeHandler}
                    fullWidth
                    error={formErrors.examSeatError ? true : false}
                    helperText={
                      formErrors.examSeatError ? formErrors.examSeatError : " "
                    }
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs :12, sm :6, md :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Passing Years :"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="passingYear"
                    size="small"
                    type="number"
                    inputProps={{ maxLength: 4 }}
                    value={formData.passingYear ? formData.passingYear : ""}
                    onChange={changeHandler}
                    fullWidth
                    error={formErrors.passingYearError ? true : false}
                    helperText={
                      formErrors.passingYearError
                        ? formErrors.passingYearError
                        : " "
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            {formData.qualificationStatus == "Pursuing" ? (
              <>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    // columnSpacing={{xs :12, sm :6, md :1}}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Marks Obtained :"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="marksObtain"
                        size="small"
                        value={formData.marksObtain ? formData.marksObtain : ""}
                        onChange={changedHandler}
                        fullWidth
                        helperText={" "}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    // columnSpacing={{xs :12, sm :6, md :1}}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Marks Out Off :"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="marksOutOf"
                        size="small"
                        value={formData.marksOutOf ? formData.marksOutOf : ""}
                        onChange={changedHandler}
                        fullWidth
                        helperText={" "}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    // columnSpacing={{xs :12, sm :6, md :1}}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Marks Obtained :"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="marksObtain"
                        size="small"
                        value={formData.marksObtain ? formData.marksObtain : ""}
                        onChange={changedHandler}
                        fullWidth
                        error={formErrors.marksObtainError ? true : false}
                        helperText={
                          formErrors.marksObtainError
                            ? formErrors.marksObtainError
                            : " "
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    // columnSpacing={{xs :12, sm :6, md :1}}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Marks Out Off :"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="marksOutOf"
                        size="small"
                        value={formData.marksOutOf ? formData.marksOutOf : ""}
                        onChange={changedHandler}
                        fullWidth
                        error={formErrors.marksOutOfError ? true : false}
                        helperText={
                          formErrors.marksOutOfError
                            ? formErrors.marksOutOfError
                            : " "
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            {/* <Grid
    item
    sm={12}
    xs={12}
    md={12}
    lg={12}
    columnSpacing={1}
    container
    justifyContent="space-evenly"
    >
    <Grid
    item
    sm={12}
    xs={12}
    md={6}
    lg={6}
    // columnSpacing={{xs :12, sm :6, md :1}}
    container
    justifyContent="space-evenly"
    >
    <Grid item xs={12} sm={12} md={12}>
    <LabelCompo
    className="text-black"
    style={{ fontSize: 16 }}
    label="*Marks Obtained :"
    />
    </Grid>
   
    <Grid item xs={12} sm={12} md={12}>
    <TextField
    color="primary"
    name="marksObtain"
    size="small"
    // type="number"
    value={formData.marksObtain ? formData.marksObtain : " "}
    onChange={changeHandler}
    fullWidth
    error={formErrors.marksObtainError ? true : false}
    helperText={
    formErrors.marksObtainError
    ? formErrors.marksObtainError
    : " "
    }
    />
    </Grid>
    </Grid>
   
    <Grid
    item
    sm={12}
    xs={12}
    md={6}
    lg={6}
    // columnSpacing={{xs :12, sm :6, md :1}}
    container
    justifyContent="space-evenly"
    >
    <Grid item xs={12} sm={12} md={12}>
    <LabelCompo
    className="text-black"
    style={{ fontSize: 16 }}
    label="*Marks Out Off :"
    />
    </Grid>
    <Grid item xs={12} sm={12} md={12}>
    <TextField
    color="primary"
    name="marksOutOf"
    size="small"
    // type="number"
    value={formData.marksOutOf ? formData.marksOutOf : " "}
    onChange={changeHandler}
    fullWidth
    error={formErrors.marksOutOfError ? true : false}
    helperText={
    formErrors.marksOutOfError ? formErrors.marksOutOfError : " "
    }
    />
    </Grid>
    </Grid>
    </Grid> */}
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs :12, sm :6, md :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Percentage :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="percentage"
                    size="small"
                    value={formData.percentage ? formData.percentage : " "}
                    onChange={changeHandler}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                sx={{ marginTop: 3 }}
                // columnSpacing={{xs :12, sm :6, md :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <ButtonCompo
                    size="button"
                    type="button"
                    variant="contained"
                    name="Add"
                    onClick={submitHandler}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        <Grid sx={{ marginTop: 1 }} item xs={12} sm={12} md={12}>
          <FormHelperText error>
            {formErrors.emptyListError ? formErrors.emptyListError : " "}
          </FormHelperText>
          <DynamicTable
            data={qualiList}
            tableHead={columns}
            showHeadDelete={true}
            showPegination={false}
            rowDelete={rowDelete}
          />
        </Grid>

        <Grid
          sx={{ marginTop: 1 }}
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          size="small"
          container
          justifyContent="flex-end"
        >
          <ButtonCompo
            size="medium"
            type="button"
            variant="outlined"
            name="Back"
            onClick={backToInstructions}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <ButtonCompo
            size="button"
            type="button"
            variant="contained"
            name="Save and Proceed"
            onClick={QualiDetails}
          />
        </Grid>
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => ({
  common: state.common,
  applicationList: state.directAdmission,
  loginUser: state.user,
});

const mapDispatchToProps = {
  logOut,
  resetDelete,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QualificationDetails);
