import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import endpoints from "../../config/endpoints";
const initialState = {
  admission: [],
  isFetchAdmission: false,

  applications:[],
  isFetchApplications: false,

  newsNotification: [],
  newsLatestNotification: [],
  newsDocNotification: [],
  newsDocNotificationData: [],
  newsDocNotificationFilter: [],
  newsDocNotificationDetails: {},
  isFilter: false,
  isFetch: false,
  isFetchNotification: false,
  isSave: false,
  isFetchLatestNotification: false,
};
let URL = endpoints.getNewsDocs;
const newsDocNotificationSlice = createSlice({
  name: "newsDocNotification",
  initialState,
  reducers: {
    newsDocNotificationSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        newsDocNotification: row,
        isFetch: true,
      };
    },

    getAdmissionSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        admission: row,
        isFetch: true,
      };
    },

    getApplicationsSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        applications: row,
        isFetch: true,
      };
    },

    


    //
    newsNotificationSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        newsNotification: row,
        isFetchNotification: true,
      };
    },
    
    newsLatestNotificationSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        newsLatestNotification: row,
        isFetchLatestNotification: true,
      };
    },

    newsDocNotificationFilterSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      console.log(action.payload);
      const { row } = action.payload;
      return {
        ...state,
        newsDocNotificationFilter: row,
        isFilter: true,
      };  
    },
    newsDocNotificationDetailsSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      console.log(action.payload);
      const { data } = action.payload;
      return {
        ...state,
        newsDocNotificationDetails: data,
        isSave: true,
      };
    },
    newsDocNotificationDetailsFail: (
      state = cloneDeep(initialState),
      action
    ) => {
      console.log(action.payload);
      return {
        ...state,
        newsDocNotificationDetails: {},
        isSave: false,
      };
    },
    newsDocNotificationFilterFailure: (
      state = cloneDeep(initialState),
      action
    ) => {
      console.log("hi");
      return {
        ...state,
        newsDocNotificationFilter: [],
        isFilter: false,
      };
    },
    newsDocNotificationFailure: (state = cloneDeep(initialState), action) => {
      console.log("hi");
      return {
        ...state,
        newsDocNotification: [],
      };
    },
  },
});

export const {
  newsDocNotificationFilterSuccess,
  newsDocNotificationDetailsSuccess,
  newsDocNotificationDetailsFail,
  newsDocNotificationFilterFailure,
  newsDocNotificationSuccess,
  newsDocNotificationFailure,
  newsNotificationSuccess,
  newsLatestNotificationSuccess,
  getAdmissionSuccess,
  getApplicationsSuccess
} = newsDocNotificationSlice.actions;

export default newsDocNotificationSlice.reducer;

export const getNewsDocNotification = () => async (dispatch) => {
  try {
    console.log("*************get************");
    //     consolehttp://192.168.2.16:8088/application/prelogin/news-document-notifications?trustId=1&type=1
    console.log("hi");
    await axios
      .get(URL + "?baseUrl="+window.location.host+"&type=1")
      .then((response) => {
        console.log("Success get newsDocNotification  ========>", response);
        let { data } = response.data;
        console.log(data);
        let row = [];
        let index = 0;
        data.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            documentName: data1.documentName,
            showOnScreen: data1.showOnScreen,
            description: data1.description,
            date:data1.date,
            title:data1.title,

            type: data1.type === 1 ? "Yes" : "No",
            orderNo: data1.orderNo,
            showLabel: data1.showLable ,
            labelName: data1.lableName ,
            isActive: data1.isActive === 1 ? "Active" : "Inactive",
          };
          row.push(bData);
          console.log(row);
        });
        dispatch(newsDocNotificationSuccess({ row }));
        return row;
      })
      .catch((error) => {
        console.log("Error get newsDocNotification========>", error);
      });
  } catch (e) {
    return console.error(e.message);
  }
};

export const getLatestNews = () => async (dispatch) => {
  try {
    console.log("*************get Latest News************");
    console.log("hi");
   const response= await axios
      .get(URL + "?baseUrl="+window.location.host+"&type=1")
      .then((response) => {
        console.log("Success get getLatestNews ========>", response);
        let { data } = response.data;
        console.log(data);
        let row = [];
        let index = 0;
        data.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            documentName: data1.documentName,
            showOnScreen: data1.showOnScreen,
            description: data1.description,
            date:data1.date,
            title:data1.title,
            type: data1.type === 1 ? "Yes" : "No",
            orderNo: data1.orderNo,
            showLabel: data1.showLable,
            labelName: data1.lableName,
            isActive: data1.isActive === 1 ? "Active" : "Inactive",
          };
          row.push(bData);
          console.log(row);
        });
        dispatch(newsNotificationSuccess({ row }));
        return row;
      })
      .catch((error) => {
        console.log("Error get news Notification========>", error);
      });
      return response
  } catch (e) {
    return console.error(e.message);
  }
};

export const getNotification = () => async (dispatch) => {
  try {
    console.log("*************get Notification************");
    console.log("hi");
    await axios
      .get(URL + "?baseUrl="+window.location.host+"&type=2")
      .then((response) => {
        console.log("Success get getNotification ========>", response);
        let { data } = response.data;
        console.log(data);
        let row = [];
        let index = 0;
        data.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            documentName: data1.documentName,
            showOnScreen: data1.showOnScreen,
            description: data1.description,
            date:data1.date,
            title:data1.title,
            type: data1.type === 1 ? "Yes" : "No",
            orderNo: data1.orderNo,
            showLabel: data1.showLable ,
            labelName: data1.lableName,
            isActive: data1.isActive === 1 ? "Active" : "Inactive",
          };
          row.push(bData);
          console.log(row);
        });
        dispatch(newsLatestNotificationSuccess({ row }));
        return row;
      })
      .catch((error) => {
        console.log("Error get Latest Notification========>", error);
      });
  } catch (e) {
    return console.error(e.message);
  }
};




// http://localhost:8088/application/prelogin/live-applications?trustId=1

let admissionURL =  endpoints.getOnlineAdmission;
export const getOnlineAdmission = () => async (dispatch) => {
  try {
    console.log("**************getOnlineAdmission************");
    console.log("hi there");
    await axios
      .get(admissionURL + "?baseUrl="+window.location.host)
      .then((response) => {
        console.log("Success get getOnlineAdmission ========>", response);
        let { data } = response.data;
        console.log(data);
        let row = [];
        let index = 0;
        data.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            lastDate: data1.lastDate,
            startDate: data1.startDate,
            title: data1.title,
            detailedDescription: data1.detailedDescription,
            shortSummary:data1.shortSummary,
            isActive: data1.isActive === 1 ? "Active" : "Inactive",
            showLabel: data1.highlightLable  ,
            labelName: data1.lableText,
          };
          row.push(bData);
          console.log(row);
        });
        dispatch(getAdmissionSuccess({ row }));
        return row;
      })
      .catch((error) => {
        console.log("Error Admission========>", error);
      });
  } catch (e) {
    return console.error(e.message);
  }
};


// get all applications


let applicationsUrl = endpoints.getAllApplication;
export const getAllApplication = () => async (dispatch) => {
  try {
    console.log("**************getAllApplication************");
    await axios
      .get(applicationsUrl)
      .then((response) => {
        console.log("Success get getAllApplication ========>", response);
        // let { data } = response.data;
        // console.log(data);
        // let row = {};
        // let index = 0;
        // data.map((data1) => {
        //   index = index + 1;
        //   let bData = {
        //     index: index,
        //     id: data1.id,
        //     // lastDate: data1.lastDate,
        //     // title: data1.title,
        //     // detailedDescription: data1.detailedDescription,
        //     // isActive: data1.isActive === 1 ? "Active" : "Inactive",
        //   };
        //   row.push(bData);
        //   console.log(row);
        // });
        // dispatch(getApplicationsSuccess({ row }));
        // return row;
      })
      .catch((error) => {
        console.log("Error getting applications========>", error);
      });
  } catch (e) {
    return console.error(e.message);
  }
};
