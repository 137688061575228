// import CallIcon from '@mui/icons-material/Call';
// import EmailIcon from '@mui/icons-material/Email';
// import SearchIcon from "@mui/icons-material/Search";
// import WatchLaterIcon from '@mui/icons-material/WatchLater';
// import InputBase from "@mui/material/InputBase";
// import { styled } from "@mui/material/styles";
// import * as React from 'react';
// import { connect } from "react-redux";
// import { onToggle } from "../CommonActions/common.slice";

// const DashboardHeader = ({ common, onToggle }) => {

//     const toggleDrawer = () => {
//         onToggle();
//     }
//     return (
//         <>
//             <div className='top_row'>
//                 <div className='top_left'>Welcome to Applicant Dashboard</div>
//                 <div className='top_right'>
//                     {/* <div className='top_search'>
//                         <form>
//                         <div className='search_put'>
//                         <SearchIcon/>    
//                         <input type="text" id="" name="fname" value="Search" className='input_search' />
//                         </div>
//                         </form>
                    
//                     </div> */}
//                     {/* <div className='font_size'>
//                         <a href='#'>A+</a> 
//                         <a href='#'><span className='font_medium'>A</span> </a>
//                         <a href='#'><span className='font_small'>A-</span></a>
//                     </div> */}
//                 </div>
//                 <div className='clr'></div>
//             </div>
//             <div className='logo_area'>
//                 <div className='institute_logo'>
// <img src="https://www.bmncollege.com/wp-content/uploads/2017/11/1-4.png"/>
//                 </div>
//                 <div className='institute_name'>
//                 Dr. Bhanuben Mahendra Nanavati College of Home Science (Autonomous) 
//                 </div>
//                 <div className='helpline'>
//                     <h3 className='helpline_title'>Technical Helpline Number</h3>
//                     <p className='help_time'><WatchLaterIcon/> (09:00 AM to 07:00 PM)</p>
//                     <p className='help_time'><CallIcon/> +911 255 46, 1800 2091 777</p>
//                 </div>
//                 <div className='clr'></div>
//             </div>

//         </>
//     );
// }

// const mapStateToProps = (state) => ({
//     common: state.common
// });

// const mapDispatchToProps = { onToggle };

// export default connect(mapStateToProps, mapDispatchToProps)(DashboardHeader)
