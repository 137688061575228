import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./routes/index";
import Landing from "./Pages/Landing/index";
import Login from "./Pages/Login/index";
import ErrorPage from "./components/ErrorPage";
import Landing1 from "./components/NewLanding1";
import { useState } from "react";
import ForgotUserId from "./Pages/ForgotUserId/index";
import ForgotUserPassword from "./Pages/ForgotUserPassword/index";
import StudentRegister from "./Pages/Student Register";
import Header from './components/NewHeader';
import AllNotification from "./Pages/AllNotifications/AllNotification";
import LatestNews from "./Pages/AllNotifications/LatestNews";
import Notifications from "./Pages/AllNotifications/Notifications";
import ImportantLinks from "./Pages/AllNotifications/ImportantLinks";
import ImportantDates from "./Pages/AllNotifications/ImportantDates";
import ContactUs from './components/ContactUs'

// import AllNotification from "./Pages/AllNotifications/AllNotification";
const App = () => {
  const [flag, setFlag] = useState(true);
  return (
    <>
{/* <Header/> */}
      <Routes>

        <Route path="/dashboard" element={<Landing1 />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgotuserid" element={<ForgotUserId />} />
        <Route path="/registerstudent" element={<StudentRegister />} />
        <Route path="/forgotuserpassword" element={<ForgotUserPassword />} />
        <Route path="/allNotifications" element={<AllNotification />} />
        <Route path="/allNotifications2" element={<LatestNews />} />
        <Route path="/allNotifications3" element={<Notifications />} />
        <Route path="/importantDates" element={<ImportantDates />} />
        <Route path="/contact-us" element={<ContactUs />} />

        {/* <Route path="/importantlinks" element={<ImportantLinks/>} /> */}
        <Route
          path="/*"
          element={
            <PrivateRoute>
              {!flag && <ErrorPage onCancel={setFlag} />}
              {flag && <Landing />}
            </PrivateRoute>
          }
        />

        {/* Private Route */}
        {/* <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Institute />
            </PrivateRoute>
          }
        />
        <Route
          path="/student"
          element={
            <PrivateRoute>
              <Landing />
            </PrivateRoute>
          }
        /> */}
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
    </>
  );
};

export default App;

// import { Route, Routes } from "react-router-dom";
// import PrivateRoute from "./routes/index";
// import Landing from "./Pages/Landing/index";
// import Login from "./Pages/Login/index";
// import ErrorPage from "./components/ErrorPage";
// import { useState } from "react";

// const App = () => {
//   const [flag, setFlag] = useState(false);
//   return (
//     <>
//       <Routes>
//         <Route path="/login" element={<Login />} />

//         <Route
//           path="/*"
//           element={
//             <PrivateRoute>
//               {flag && <ErrorPage onCancel={setFlag} />}
//               {!flag && <Landing />}
//             </PrivateRoute>
//           }
//         />

{
  /* Private Route */
}
{
  /* <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Institute />
            </PrivateRoute>
          }
        />
        <Route
          path="/student"
          element={
            <PrivateRoute>
              <Landing />
            </PrivateRoute>
          }
        /> */
}
{
  /* <Route path="*" element={<NotFound />} /> */
}
{
  /* </Routes>
    </>
  );
};

export default App; */
}
