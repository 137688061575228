import { withStyles } from "@mui/styles";
//import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import React from "react";
import Header from "../../components/NewHeader";
import Navbar from '../../components/Navbar'
const styles = (theme) => ({
  hello: {
    position: "absolute",
    left: "15.17%",
    right: "15.08%",
    top: "63.4%",
    bottom: "32.6%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: "normal",
    fontsize: "36px",
    lineheight: "40px",
    color: "#000000",
  },
  buttonC: {
    position: "absolute",

    left: "39.17%",
    right: "39.17%",
    top: "79.7%",
    bottom: "14.8%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: 500,
    fontsize: "18px",
    lineheight: "40px",
    color: "#FFFFFF",
  },
  helper: {
    position: "absolute",
    left: "9.58%",
    right: "9.5%",
    top: "70%",
    bottom: "22%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: "normal",
    fontsize: "20px",
    lineheight: "40px",

    textAlign: "center",

    color: "#666666",
  },
  title: {
    position: "absolute",
    left: "43.42%",
    right: "43.33%",
    top: "57%",
    bottom: "39%",

    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: 500,
    fontsize: "55px",
    lineheight: "40px",
    color: "#000000",
  },
})
const ErrorPage = (classes) => {


  return (
    <>
      <div align="center">
        {/* <Header/> */}
        <Header />
<Navbar/>
        <div className="new_content">
          {/* Online Admission */}
              <div className="admission_detail noti">
                {/* <div className="admission_det_date">End Date <span>dsdsd</span></div> */}
                <p className="admission_txt">
                  Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur.
                </p>
                <h6 className="news_time"></h6>
              </div>
        </div>

        <div className="new_content">
          {/* Online Admission */}
              <div className="admission_detail noti">
                {/* <div className="admission_det_date">End Date <span>dsdsd</span></div> */}
                <p className="admission_txt">
                  Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur.
                </p>
                <h6 className="news_time"></h6>
              </div>
        </div>

        <div className="new_content">
          {/* Online Admission */}
              <div className="admission_detail noti">
                {/* <div className="admission_det_date">End Date <span>dsdsd</span></div> */}
                <p className="admission_txt">
                  Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur.
                </p>
                <h6 className="news_time"></h6>
              </div>
        </div>

        <div className="new_content">
          {/* Online Admission */}
              <div className="admission_detail noti">
                {/* <div className="admission_det_date">End Date <span>dsdsd</span></div> */}
                <p className="admission_txt">
                  Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur.
                </p>
                <h6 className="news_time"></h6>
              </div>
        </div>
      </div>
    </>
  );
};

export default withStyles(styles)(ErrorPage);
