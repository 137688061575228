import FormControl from '@mui/material/FormControl';
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import * as React from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/* 
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
} */

export default function MultipleSelect({er,label,name1,value1,names,onChange}) {

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    onChange(value,name1)
   
  };

  return (
    <div>
      <FormControl color={"primary"} fullWidth={true}>
        <InputLabel id="TrustName" className="text-black">{label}</InputLabel>
        <Select
          // labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={value1}
          name={name1}
          className="text-black"
          onChange={handleChange}
          input={<OutlinedInput  />} label=""
          MenuProps={MenuProps}
          size="small"
          {...(er && { error: true })}
        >
          {names.map((name) => (
            <MenuItem
              key={name.id}
              value={name.id}
            >
              {name.name}
            </MenuItem>
          ))}
          
        </Select>
       
                 { er&&
                  <FormHelperText error>
                    {er}
                  </FormHelperText>
                }
      </FormControl>
    </div>
  );
}