import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid, Paper } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import Alert from "../../Alert/Alert";
import { getAlert } from "../../Alert/alert.slice";
import {
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "../../components/Comman/IconButton";
import DynamicTable from "../../components/Comman/MaterialUIRTTable";
import { DirectionSnackbar } from "../../components/Comman/SnakBarCompo";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
import { Loading1 } from "../../components/Loading1";
import Search from "../../components/Search";
import * as myConstClass from "../../config/messageconstant";
import { logOut } from "../Login/login.slice";
import {
  deleteQualificationById,
  filterQualificationData,
  getQualification,
  getQualificationById,
  resetDelete,
  resetSave,
  saveQualification,
} from "./qualification.slice";
import QualificationExcelData from "./QualificationExcelData";
import QualificationMasterForm from "./QualificationMasterForm";
import ViewInst from "./ViewQualification";

const breakpoint = 600;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    title: "Qualification name",
    name: "qualificationName",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    title: "Type",
    name: "qualificationType",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    title: "Sort Order Number",
    name: "sortOrderNo",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "right",
  },
  {
    title: "Status",
    name: "isActive",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
  },
];

const QualificationMaster = ({
  getQualification,
  getQualificationById,
  resetSave,
  saveQualification,
  deleteQualificationById,
  filterQualificationData,
  qualificationList,
  loginUser,
  logOut,
  getAlert,
  resetDelete,
}) => {
  const [isEdit, setEdit] = useState(false);
  const [status, setStatus] = useState(false);
  const [del, setDel] = useState(false);
  const [save, setSave] = useState(false);
  const [snakMsg, setSnakMsg] = useState("");
  const [editRow, setEditRow] = useState([]);
  const [viewRow, setViewRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteropen, setFilterOpen] = useState(false);
  const [openSnak, setOpenSnak] = useState(false);
  const navigate = useNavigate();
  const [filteredList, setfilterList] = React.useState([
    "Sr. No.",
    "Qualification name",
    "Type",
    "Sort Order Number",
    "Status",
  ]);
  const [qualiList, setQualiList] = React.useState([
    {
      title: "Sr. No.",
      name: "index",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      title: "Qualification name",
      name: "qualificationName",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      title: "Type",
      name: "qualificationType",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      title: "Sort Order Number",
      name: "sortOrderNo",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      title: "Status",
      name: "isActive",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      title: "Action",
      name: "action",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
  ]);

  const [breadCrumName, setBreadCrum] = useState(
    " Qualification Qualification name Master "
  );
  const [width, setWidth] = React.useState(window.innerWidth);

  const rowStatus = async (id) => {
    swal(myConstClass.changeActive, {
      buttons: ["Cancle", true],
    }).then((value) => {
      if (value) {
        const viewRow = qualificationList?.qualification.filter(
          (row) => row.id === id
        );
        let trustId = localStorage.getItem("trustId");
        let qualificationData = {
          id: viewRow[0].id,
          name: viewRow[0].qualificationName,
          type: viewRow[0].qualificationType === "UserFill" ? "1" : "0",
          sortOrderNo: viewRow[0].sortOrderNo,
          isActive: viewRow[0].isActive === "Active" ? "0" : "1",
          trust: {
            id: trustId,
          },
          delStatus: 1,
        };

        resetSave();
        setStatus(true);
        saveQualification({ qualificationData: qualificationData }).then(
          (respo) => {
            if (!respo) {
              getAlert({ message: myConstClass.serverMsg });
            } else {
              setOpenSnak(true);
              setSnakMsg(myConstClass.activeStatus);
              getQualification().then((respo) => {
                if (!respo) {
                  getAlert({ message: myConstClass.serverMsg });
                }
              });
            }
          }
        );
      }
    });
  };
  const getRowID = async (id) => {
    setViewRow([]);
    const editedRow = qualificationList?.qualification.filter(
      (row) => row.id === id
    );
    if (editedRow.length > 0) {
      setEdit(true);
      setEditRow([]);
      return await setEditRow(editedRow);
    }
    return setEditRow([]);
  };
  const getRowData = async (id) => {
    setOpen(true);
    const viewRow = qualificationList?.qualification.filter(
      (row) => row.id === id
    );
    if (viewRow.length > 0) {
      return await setViewRow(viewRow);
    }
  };
  const data = (instData) => {
    resetSave();
    setSave(true);
    saveQualification({ qualificationData: instData }).then(() => {
      setOpenSnak(true);
      if (instData.id != null) {
        setSnakMsg(myConstClass.updateMsg);
      } else {
        setSnakMsg(myConstClass.saveMsg);
      }
      getQualification().then((respo) => {
        if (!respo) {
          getAlert({ message: myConstClass.serverMsg });
        }
      });
    });

    setEdit(false);
    setEditRow([]);
  };

  const saveAndNextData = (data) => {
    resetSave();
    setSave(true);

    saveQualification({ qualificationData: data }).then(() => {
      setOpenSnak(true);
      if (data.id != null) {
        setSnakMsg(myConstClass.updateMsg);
      } else {
        setSnakMsg(myConstClass.saveMsg);
      }
      getQualification().then((respo) => {
        if (!respo) {
          getAlert({ message: myConstClass.serverMsg });
        }
      });
    });

    setEdit(true);
    setEditRow([]);
  };
  useEffect(() => {
    getQualification().then((respo) => {
      if (!respo) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
  });

  const startEdit = () => {
    setEdit(true);
  };
  const breadCrum = (prevBreadCrum) => {
    setBreadCrum(breadCrumName + prevBreadCrum);
  };
  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be retreived. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setDel(true);
        deleteQualificationById({ qualificationId: id }).then((respo) => {
          console.log(respo);
          if (!respo) {
            getAlert({ message: myConstClass.serverMsg });
          } else {
            setOpenSnak(true);
            setSnakMsg(myConstClass.deleteMsg);
            setDel(false);
            getQualification().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
          }
        });
      }
    });
  };

  const qualificationFilteredData = qualificationList?.qualification.filter(
    (item) =>
      item.qualificationName
        .toLowerCase()
        .includes(searchValue.toLowerCase()) ||
      item.qualificationType
        .toLowerCase()
        .includes(searchValue.toLowerCase()) ||
      item.sortOrderNo === parseInt(searchValue) ||
      item.isActive.toLowerCase() === searchValue
  );

  const filteredRows = (searchvalue) => {
    setSearchValue(searchvalue);
    const filteredRow = qualificationList?.qualification.filter((row) => {
      let qualificationName = row?.qualificationName;
      let qualificationType = row?.qualificationType;
      let sortOrderNo = row?.sortOrderNo;
      let isActive = row?.isActive;
      return (
        qualificationName.toLowerCase().includes(searchvalue.toLowerCase()) ||
        qualificationType.toLowerCase().includes(searchvalue.toLowerCase()) ||
        sortOrderNo === parseInt(searchvalue) ||
        isActive.toLowerCase() === searchvalue
      );
    });

    if (qualificationList?.qualification.length === filteredRows.length) {
      filterQualificationData({ row: filteredRow, flag: false });
    } else {
      filterQualificationData({ row: filteredRow, flag: true });
    }
  };
  const stopEdit = () => {
    setBreadCrum("   >   Master   >   Qualification Name Master  ");
    setEditRow([]);
    setEdit(false);
  };

  const addFile = (file) => {};
  const closeFilter = () => {
    setQualiList([
      {
        title: "Sr. No.",
        name: "index",
        positionCenter: true,
        showInscreen: true,
        alignCenter: "center",
      },
      {
        title: "Qualification name",
        name: "qualificationName",
        positionCenter: false,
        showInscreen: true,
        alignCenter: "left",
      },
      {
        title: "Type",
        name: "qualificationType",
        positionCenter: false,
        showInscreen: true,
        alignCenter: "left",
      },
      {
        title: "Sort Order Number",
        name: "sortOrderNo",
        positionCenter: false,
        showInscreen: true,
        alignCenter: "right",
      },
      {
        title: "Status",
        name: "isActive",
        positionCenter: true,
        showInscreen: true,
        alignCenter: "center",
      },
      {
        title: "Action",
        name: "action",
        positionCenter: false,
        showInscreen: true,
        alignCenter: "right",
      },
    ]);
    setfilterList([
      "Sr. No.",
      "Qualification name",
      "Type",
      "Sort Order Number",
      "Status",
      "Action",
    ]);
    setFilterOpen(false);
  };
  const handleChange = (event) => {
    setFilterOpen(true);
    const {
      target: { value },
    } = event;
    if (value.length > filteredList.length) {
      console.log("filteredList");
      console.log(filteredList);
      console.log("value");
      console.log(value);
      console.log("qualiList");
      console.log(qualiList);
      let row = [];
      const a = qualiList.map((qualiData) => {
        let trust = value.filter((namesRow) => namesRow === qualiData.title);

        if (trust.length !== 0) {
          console.log("shraddha");
          let qData = {
            ...qualiData,
            showInscreen: true,
          };
          row.push(qData);
        } else {
          let qData = {
            ...qualiData,
            showInscreen: false,
          };
          row.push(qualiData);
        }

        return setQualiList(row);
      });
      console.log(row);
    } else {
      let row = [];
      let difference = filteredList.filter((x) => !value.includes(x));
      const qualificationList = qualiList.filter((data) => {
        if (data.title === difference[0]) {
          let QualiData = {
            ...data,
            showInscreen: false,
          };
          row.push(QualiData);
        } else {
          row.push(data);
        }
      });
      console.log("row");
      console.log(row);
      setQualiList(row);
    }

    setfilterList(typeof value === "string" ? value.split(",") : value);

    if (value.length === 0) {
      setFilterOpen(false);
    }
  };
  return (
    <>
      <div>
        <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
          <TitleAndBreadCrum
            title="Qualification"
            breadCrumName={breadCrumName}
          />
        </Paper>
        &nbsp;&nbsp;
        <br />
        {/* {qualificationList.isDelete && qualificationList.error == true && (
          <Alert msg={myConstClass.deleteFailMsg} closeAlert={resetDelete} />
        )}
        {qualificationList.isServerFail && (
          <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />
        )} */}
        {qualificationList.isDelete && qualificationList.error == false ? (
          <DirectionSnackbar
            open={openSnak}
            severity="success"
            msg={myConstClass.deleteMsg}
            closeSnak={setOpenSnak}
          />
        ) : qualificationList.isSave ? (
          <DirectionSnackbar
            open={openSnak}
            severity="success"
            msg={snakMsg}
            closeSnak={setOpenSnak}
          />
        ) : (
          ""
        )}
        {/*  {<DirectionSnackbar open={openSnak} severity='success' msg={snakMsg} closeSnak={setOpenSnak} />} */}
        <Paper sx={{ p: 2, pt: 4, borderRadius: 2 }} elevation={0}>
          <div>
            {viewRow.length >= 0 && editRow.length >= 0 && isEdit && (
              <QualificationMasterForm
                OnSave={data}
                isEdit={editRow}
                onCancel={stopEdit}
                breadCrum={breadCrum}
                handleFile={addFile}
                saveAndNext={saveAndNextData}
              />
            )}
          </div>
          {open && (
            <ViewInst data={viewRow} openparam={open} closeView={setOpen} />
          )}
          <Grid>
            {!isEdit && (
              <div>
                {width > breakpoint ? (
                  <>
                    <Box
                      sx={{ mr: 2 }}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Box sx={{ m: -1, width: 300 }}>
                        <Search
                          filteredRows={filteredRows}
                          searchValue={searchValue}
                        />
                      </Box>
                      <Box>
                        <FormControl sx={{ m: -1, width: 250 }}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={filteredList}
                            name="sdf"
                            size="small"
                            onChange={handleChange}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {names.map((name) => {
                              return (
                                <MenuItem key={name.name} value={name.title}>
                                  <Checkbox
                                    checked={
                                      filteredList.indexOf(name.title) > -1
                                    }
                                  />
                                  <ListItemText primary={name.title} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>

                        {filteropen && (
                          <>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <QualificationExcelData
                              csvData={qualificationFilteredData}
                              qualificationList={qualiList}
                              fileName="Qualification Name Master"
                              type="pdf"
                            />
                          </>
                        )}
                        {filteropen && (
                          <>
                            &nbsp;&nbsp;
                            <QualificationExcelData
                              csvData={qualificationFilteredData}
                              qualificationList={qualiList}
                              fileName="Qualification Name Master"
                              type="excel"
                            />
                          </>
                        )}

                        {filteropen && (
                          <>
                            &nbsp;&nbsp;
                            <FontAwesomeIconCompo
                              className="fa-times-circle"
                              color="primary"
                              title="cancle"
                              baseClassName="fas"
                              onClick={closeFilter}
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <QualificationExcelData
                              csvData={qualificationFilteredData}
                              qualificationList={qualiList}
                              fileName="Qualification Name Master"
                              type="pdf"
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;
                            <QualificationExcelData
                              csvData={qualificationFilteredData}
                              qualificationList={qualiList}
                              fileName="Qualification Name Master"
                              type="excel"
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;&nbsp;
                            <MyComponentWithIconProps
                              statusImage={AddCircleIcon}
                              color="primary"
                              title=""
                              fontSize="large"
                              sx={{ m: -1 }}
                              onClick={startEdit}
                            />
                          </>
                        )}
                      </Box>
                    </Box>
                    <Box sx={{ mt: 2, p: 1.5 }}>
                      {/* <QualificationTable
                    filteredColumns={qualiList}
                    data={qualificationFilteredData}
                    rowEdit={getRowID}
                    rowView={getRowData}
                    rowDelete={deleteData}
                    rowStatus={rowStatus}
                  />  
                   */}
                      <DynamicTable
                        data={qualificationFilteredData}
                        tableHead={qualiList}
                        rowEdit={getRowID}
                        rowDelete={deleteData}
                        rowStatus={rowStatus}
                        showHeadDelete={true}
                        showHeadEdit={true}
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      rowSpacing={2}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid xs={12} sm={12} md={12} lg={12} rowSpacing={2}>
                        <Search
                          filteredRows={filteredRows}
                          searchValue={searchValue}
                        />
                      </Grid>
                      <Grid xs={12} sm={12} md={12} lg={12} sx={{ m: 1 }}>
                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={filteredList}
                            name="sdf"
                            size="small"
                            onChange={handleChange}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {names.map((name) => {
                              return (
                                <MenuItem key={name.name} value={name.title}>
                                  <Checkbox
                                    checked={
                                      filteredList.indexOf(name.title) > -1
                                    }
                                  />
                                  <ListItemText primary={name.title} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <Grid
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          sx={{ m: 1, textAlign: "right" }}
                        >
                          {filteropen && (
                            <>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <QualificationExcelData
                                csvData={qualificationFilteredData}
                                qualificationList={qualiList}
                                fileName="Qualification Name Master"
                                type="pdf"
                              />
                            </>
                          )}
                          {filteropen && (
                            <>
                              &nbsp;&nbsp;
                              <QualificationExcelData
                                csvData={qualificationFilteredData}
                                qualificationList={qualiList}
                                fileName="Qualification Name Master"
                                type="excel"
                              />
                            </>
                          )}

                          {filteropen && (
                            <>
                              &nbsp;&nbsp;
                              <FontAwesomeIconCompo
                                className="fa-times-circle"
                                color="primary"
                                title="cancle"
                                baseClassName="fas"
                                onClick={closeFilter}
                              />
                            </>
                          )}

                          {!filteropen && filteredList.length >= 0 && (
                            <>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <QualificationExcelData
                                csvData={qualificationFilteredData}
                                qualificationList={qualiList}
                                fileName="Qualification Name Master"
                                type="pdf"
                              />
                            </>
                          )}

                          {!filteropen && filteredList.length >= 0 && (
                            <>
                              &nbsp;&nbsp;
                              <QualificationExcelData
                                csvData={qualificationFilteredData}
                                qualificationList={qualiList}
                                fileName="Qualification Name Master"
                                type="excel"
                              />
                            </>
                          )}

                          {!filteropen && filteredList.length >= 0 && (
                            <>
                              &nbsp;&nbsp;&nbsp;
                              <MyComponentWithIconProps
                                statusImage={AddCircleIcon}
                                color="primary"
                                title=""
                                fontSize="large"
                                sx={{ m: -1 }}
                                onClick={startEdit}
                              />
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Box sx={{ mt: 2, p: 1.5 }}>
                      {/* <QualificationTable
                    filteredColumns={qualiList}
                    data={qualificationFilteredData}
                    rowEdit={getRowID}
                    rowView={getRowData}
                    rowDelete={deleteData}
                    rowStatus={rowStatus}
                  />  */}

                      <DynamicTable
                        data={qualificationFilteredData}
                        tableHead={qualiList}
                        rowEdit={getRowID}
                        rowDelete={deleteData}
                        rowStatus={rowStatus}
                        showHeadDelete={true}
                        showHeadEdit={true}
                      />
                    </Box>
                  </>
                )}
              </div>
            )}
          </Grid>

          {/* {!qualificationList?.isServerFail ? (
            <Loading1 size={50} />
          ) : status && !qualificationList?.isSave ? (
            <Loading1 size={50} />
          ) : save && !qualificationList?.isSave ? (
            <Loading1 size={50} />
          ) : del ? (
            <Loading1 size={50} />
          ) : (
            ""
          )} */}

          {!qualificationList.isServerFail &&
            (!qualificationList?.isDataFetch ? (
              <Loading1 size={50} />
            ) : status && !qualificationList?.isSave ? (
              <Loading1 size={50} />
            ) : save && !qualificationList?.isSave ? (
              <Loading1 size={50} />
            ) : del ? (
              <Loading1 size={50} />
            ) : (
              ""
            ))}
        </Paper>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  qualificationList: state.qualification,
  loginUser: state.user,
  common: state.common,
});

const mapDispatchToProps = {
  getQualification,
  resetSave,
  getQualificationById,
  saveQualification,
  deleteQualificationById,
  filterQualificationData,
  getAlert,
  logOut,
  resetDelete,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QualificationMaster);
