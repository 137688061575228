import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { decodeData } from "../../components/Comman/Util/Base64";
import endpoints from '../../config/endpoints';
import { tokenExp } from "../Login/login.slice";

const initialState = {
  university: [],
  isFetch: false,
  error: false,
  isDelete: false,
  isSave: false,
  isServerFail: false,
};
let URL = endpoints.university;
const universitySlice = createSlice({
  name: "university",
  initialState,
  reducers: {
    universitySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload
      return {
        ...state,
        isFetch: true,
        university: row,
      };
    },
    universityFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isFetch: false,
        university: [],
      };
    },
    serverFailed: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: true,
        error: false,
        isDelete: false,
      };
    },
    resetDeleteSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error: false,
        isDelete: false,
        isSave: false,
        isServerFail: false,
      };
    },
  },
});

export const { universitySuccess,resetDeleteSuccess, serverFailed,universityFailure } = universitySlice.actions;

export default universitySlice.reducer;

export const getUniversity = () => async (dispatch) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    let docs=await axios
      .get(URL + '?filters={"delStatus": "1","_mode" : "EXACT"}', { headers })
      .then((response) => {
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        }
        console.log("Success getUniversity  ========>", response.data.data.content);
        let data = response.data.data.content;
        let row = [];
        let index = 0
        data.map((data1) => {
          index = index + 1
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            uniAddress: data1.address,
            uniContact: data1.uniContact,
            isActive: data1.isActive === 1 ? "Active" : "Inactive",
          };
          row.push(bData);
          return bData
        });
        dispatch(universitySuccess({ row }));
        return row;
      })
      .catch((error) => {
        dispatch(serverFailed());

        console.log("Error getUniversity========>", error);
      });
      return docs;
  } catch (e) {
    dispatch(serverFailed());

    return console.error(e.message);
  }
};

export const getUniversityById = () => async (dispatch) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    let docs=await axios
      .get(URL + "/1", { headers })
      .then((response) => {
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        }
        console.log("Success With id getUniversityById ========>", response);
      })
      .catch((error) => {
        dispatch(serverFailed());

        console.log("Error getUniversityById ========>", error.response.data);
      });
      return docs;
  } catch (e) {
    dispatch(serverFailed());

    return console.error(e.message);
  }
};

export const saveUniversity =
  ({ formFeesData }) =>
    async (dispatch) => {
      try {
        let data = JSON.stringify(formFeesData);
        const name = localStorage.getItem("token");
        const token = decodeData(name);
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
          shouldrefresh: "true",
        };
        let docs=await axios
          .post(URL, data, { headers })
          .then((response) => {
            const { error } = response.data;
            if (error == true) {
              dispatch(tokenExp());
            }

          })
          .catch((error) => {
            dispatch(serverFailed());

            console.log("Error saveUniversity ========>", error.response.data);
          });
          return docs;
      } catch (e) {
        dispatch(serverFailed());

        return console.error(e.message);
      }
    };

export const deleteUniversityById =
  ({ formFeesId }) =>
    async (dispatch) => {
      try {

        const name = localStorage.getItem("token");
        const token = decodeData(name);
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
          shouldrefresh: "true",
        };
       await axios
          .delete(URL + "/" + formFeesId, { headers })
          .then((response) => {
         
            console.log(
              "Success With deletedeleteUniversitysById ========>",
              response
            );
          })
          .catch((error) => {
            console.log("Error deleteUniversityById ========>", error.response.data);
          });
      } catch (e) {
        return console.error(e.message);
      }
    };
    export const resetDelete = () => async (dispatch) => {
      try {
        dispatch(resetDeleteSuccess());
      } catch (e) {
        return console.error(e.message);
      }
    };
