import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import Alert from "../../Alert/Alert";
import { getAlert } from "../../Alert/alert.slice";
import {
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "../../components/Comman/IconButton";
import DynamicTable from "../../components/Comman/MaterialUIRTTable";
import { DirectionSnackbar } from "../../components/Comman/SnakBarCompo";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
import { Loading1 } from "../../components/Loading1";
import Search from "../../components/Search";
import * as myConstClass from "../../config/messageconstant";
import { logOut } from "../Login/login.slice";
import DocumentExcelData from "./DocExcelData";
import DocumentMForm from "./DocMasterForm";
import {
  deleteDocumentById,
  filterDocumentData,
  getDocument,
  getDocumentById,
  resetDelete,
  resetfilterData,
  resetSave,
  saveDocument,
} from "./document.slice";
import ViewDocumentMatser from "./ViewDocumentMaster";

const breakpoint = 600;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const names = [
  { title: "Document Name", name: "docName" },
  { title: "Sort Order Number", name: "sortOrderNo" },
  { title: "Status", name: "isActive" },
];

const DocumentMaster = ({
  getDocument,
  saveDocument,
  deleteDocumentById,
  resetDelete,
  resetfilterData,
  resetSave,
  loginUser,
  documentList,
  logOut,
  getAlert,
}) => {
  const dispatch = useDispatch();
  const [isEdit, setEdit] = useState(false);
  const [status, setStatus] = useState(false);
  const [del, setDel] = useState(false);
  const [save, setSave] = useState(false);
  const [editRow, setEditRow] = useState([]);
  const [viewRow, setViewRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [snakMsg, setSnakMsg] = useState("");
  const [openSnak, setOpenSnak] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteropen, setFilterOpen] = useState(false);
  const [filteredList, setfilterList] = React.useState([]);
  const navigate = useNavigate();
  const [docList, setDocList] = React.useState([
    [{ title: "Sr. No.", name: "index" }],
    [{ title: "Document Name", name: "docName" }],
    [{ title: "Sort Order Number", name: "sortOrderNo" }],
    [{ title: "Status", name: "isActive" }],
  ]);

  const [breadCrumName, setBreadCrum] = useState(
    "   >   Master   >  Document "
  );

  const [width, setWidth] = React.useState(window.innerWidth);
  const [documentDataList, setDocumentDataList] = React.useState([
    {
      title: "Sr. No.",
      name: "index",
      positionCenter: true,
      alignCenter: "center",
      showInscreen: true,
    },
    {
      title: "Document name",
      name: "docName",
      positionCenter: false,
      showInscreen: true,
      width: 350,
    },
    {
      title: "Sort Order Number",
      name: "sortOrderNo",
      positionCenter: false,
      alignCenter: "right",
      showInscreen: true,
    },
    {
      title: "Status",
      name: "isActive",
      positionCenter: true,
      alignCenter: "center",
      showInscreen: true,
    },
    {
      title: "Action",
      name: "action",
      positionCenter: false,
      alignCenter: "right",
      showInscreen: true,
    },
  ]);

  const rowStatus = async (id) => {
    swal(myConstClass.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        const viewRow = documentList?.document.filter((row) => row.id === id);
        let trustId = localStorage.getItem("trustId");

        let documentData = {
          id: viewRow[0].id,
          sortOrderNo: viewRow[0].sortOrderNo,
          name: viewRow[0].docName,
          isActive: viewRow[0].isActive === "Active" ? "0" : "1",
          delStatus: 1,
          trust: {
            id: trustId,
          },
        };
        resetSave();
        setStatus(true);
        saveDocument({ documentData: documentData }).then((respo) => {
          if (!respo) {
            getAlert({ message: myConstClass.serverMsg });
          } else {
            setOpenSnak(true);
            setSnakMsg(myConstClass.activeStatus);
            getDocument().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
            setStatus(false);
          }
        });
      }
    });
  };

  const getRowID = async (id) => {
    setViewRow([]);
    const editedRow = documentList?.document.filter((row) => row.id === id);
    if (editedRow.length > 0) {
      setEdit(true);
      setEditRow([]);
      return await setEditRow(editedRow);
    }

    return setEditRow([]);
  };
  const getRowData = async (id) => {
    setOpen(true);
    const viewRow = documentList?.document.filter((row) => row.id === id);
    if (viewRow.length > 0) {
      return await setViewRow(viewRow);
    }
    return setViewRow([]);
  };

  const data = (instData) => {
    resetSave();
    setSave(true);
    saveDocument({ documentData: instData }).then(() => {
      setSave(false);
      setOpenSnak(true);
      if (instData.id != null) {
        setSnakMsg(myConstClass.updateMsg);
      } else {
        setSnakMsg(myConstClass.saveMsg);
      }
      setSave(false);

      getDocument().then((respo) => {
        if (!respo) {
          getAlert({ message: myConstClass.serverMsg });
        }
      });
    });
    setEdit(false);
    setEditRow([]);
  };

  useEffect(() => {
    getDocument().then((respo) => {
      if (!respo) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
  });
  const saveAndNextData = (data) => {
    setSave(true);
    saveDocument({ documentData: data }).then((documentError) => {
      if (documentError.data !== null) {
        setOpenSnak(true);
        if (data.id != null) {
          setSnakMsg(myConstClass.updateMsg);
        } else {
          setSnakMsg(myConstClass.saveMsg);
        }
        setSave(false);
        getDocument().then((respo) => {
          if (!respo) {
            getAlert({ message: myConstClass.serverMsg });
          }
        });
      }
      setSave(false);
    });
    setEdit(true);
    setEditRow([]);
  };

  const startEdit = () => {
    setEdit(true);
    let t = localStorage.getItem("token");
  };
  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setDel(true);
        deleteDocumentById({ documentId: id }).then((resp) => {
          if (!resp) {
            getAlert({ message: myConstClass.serverMsg });
          } else {
            console.log(resp);
            setOpenSnak(true);
            setSnakMsg(myConstClass.deleteMsg);
            setDel(false);
            getDocument().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
          }
        });
      }
    });
  };
  const documentFilteredData = documentList?.document.filter(
    (item) =>
      item.docName.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.isActive.toLowerCase() === searchValue
  );

  const stopEdit = () => {
    setEditRow([]);
    setEdit(false);
  };

  const closeFilter = () => {
    setDocList([
      [{ title: "Sr. No.", name: "index" }],
      [{ title: "Document Name", name: "docName" }],
      [{ title: "Sort Order Number", name: "sortOrderNo" }],
      [{ title: "Status", name: "isActive" }],
    ]);
    setfilterList(["Document Name", "Status"]);
    setFilterOpen(false);
  };
  const handleChange = (event) => {
    setFilterOpen(true);
    const {
      target: { value },
    } = event;

    if (value.length > filteredList.length) {
      const a = value.map((value) => {
        const doc = names.filter((row) => row.title === value);
        const doc1 = [...docList, doc];

        return setDocList(doc1);
      });
    } else {
      let difference = filteredList.filter((x) => !value.includes(x));
      const documentListNew = docList.filter((data) => {
        return data[0].title !== difference[0];
      });
      setDocList(documentListNew);
    }

    setfilterList(typeof value === "string" ? value.split(",") : value);

    if (value.length === 0) {
      setFilterOpen(false);
    }
  };

  return (
    <>
      <div>
        <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
          <TitleAndBreadCrum title="Document" breadCrumName={breadCrumName} />
        </Paper>
        <br />
        {/* {documentList.isDelete && documentList.error == true && (
          <Alert msg={myConstClass.deleteFailMsg} closeAlert={resetDelete} />
        )}
        {documentList.isServerFail && (
          <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />
        )} */}

        {/* < ErrorHand error={"error"} msg={'This is an error alert — check it out!'} flag={true}/>  */}
        {documentList.isDelete && documentList.error == false ? (
          <DirectionSnackbar
            open={openSnak}
            severity="success"
            msg={myConstClass.deleteMsg}
            closeSnak={setOpenSnak}
          />
        ) : documentList.isSave ? (
          <DirectionSnackbar
            open={openSnak}
            severity="success"
            msg={snakMsg}
            closeSnak={setOpenSnak}
          />
        ) : (
          ""
        )}

        <Paper sx={{ p: 2, pt: 4, borderRadius: 2 }} elevation={0}>
          <div>
            {viewRow.length >= 0 && editRow.length >= 0 && isEdit && (
              <DocumentMForm
                OnSave={data}
                isEdit={editRow}
                onCancel={stopEdit}
                saveAndNext={saveAndNextData}
              />
            )}
          </div>
          {open && (
            <ViewDocumentMatser
              data={viewRow}
              openparam={open}
              closeView={setOpen}
            />
          )}
          <Grid>
            {!isEdit && (
              <div>
                {width > breakpoint ? (
                  <Box
                    sx={{ mr: 2 }}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Box sx={{ m: -1 }}>
                      <Search
                        filteredRows={setSearchValue}
                        searchValue={searchValue}
                      />
                    </Box>

                    <Box>
                      {filteropen && (
                        <>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <DocumentExcelData
                            csvData={documentFilteredData}
                            docList={documentDataList}
                            fileName="Document Master"
                            type="pdf"
                          />
                        </>
                      )}
                      {filteropen && (
                        <>
                          &nbsp;&nbsp;
                          <DocumentExcelData
                            csvData={documentFilteredData}
                            docList={documentDataList}
                            fileName="Document Master"
                            type="excel"
                          />
                        </>
                      )}

                      {filteropen && (
                        <>
                          &nbsp;&nbsp;
                          <FontAwesomeIconCompo
                            className="fa-times-circle"
                            color="primary"
                            title="Cancel"
                            baseClassName="fas"
                            onClick={closeFilter}
                          />
                        </>
                      )}

                      {!filteropen && filteredList.length >= 0 && (
                        <>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <DocumentExcelData
                            csvData={documentFilteredData}
                            docList={documentDataList}
                            fileName="Document Master"
                            type="pdf"
                          />
                        </>
                      )}

                      {!filteropen && filteredList.length >= 0 && (
                        <>
                          &nbsp;&nbsp;
                          <DocumentExcelData
                            csvData={documentFilteredData}
                            docList={documentDataList}
                            fileName="Document Master"
                            type="excel"
                          />
                        </>
                      )}

                      {!filteropen && filteredList.length >= 0 && (
                        <>
                          &nbsp;&nbsp;&nbsp;
                          <MyComponentWithIconProps
                            statusImage={AddCircleIcon}
                            color="primary"
                            title=""
                            fontSize="large"
                            sx={{ m: -1 }}
                            onClick={startEdit}
                          />
                        </>
                      )}
                    </Box>
                  </Box>
                ) : (
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    rowSpacing={2}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid xs={12} sm={12} md={12} lg={12} rowSpacing={2}>
                      <Search
                        filteredRows={setSearchValue}
                        searchValue={searchValue}
                      />
                    </Grid>

                    <Grid
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      sx={{ m: 1, textAlign: "right" }}
                    >
                      {filteropen && (
                        <>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <DocumentExcelData
                            csvData={documentFilteredData}
                            docList={documentDataList}
                            fileName="Document Master"
                            type="pdf"
                          />
                        </>
                      )}
                      {filteropen && (
                        <>
                          &nbsp;&nbsp;
                          <DocumentExcelData
                            csvData={documentFilteredData}
                            docList={documentDataList}
                            fileName="Document Master"
                            type="excel"
                          />
                        </>
                      )}

                      {filteropen && (
                        <>
                          &nbsp;&nbsp;
                          <FontAwesomeIconCompo
                            className="fa-times-circle"
                            color="primary"
                            title="Cancel"
                            baseClassName="fas"
                            onClick={closeFilter}
                          />
                        </>
                      )}

                      {!filteropen && filteredList.length >= 0 && (
                        <>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <DocumentExcelData
                            csvData={documentFilteredData}
                            docList={documentDataList}
                            fileName="Document Master"
                            type="pdf"
                          />
                        </>
                      )}

                      {!filteropen && filteredList.length >= 0 && (
                        <>
                          &nbsp;&nbsp;
                          <DocumentExcelData
                            csvData={documentFilteredData}
                            docList={documentDataList}
                            fileName="Document Master"
                            type="excel"
                          />
                        </>
                      )}

                      {!filteropen && filteredList.length >= 0 && (
                        <>
                          &nbsp;&nbsp;&nbsp;
                          <MyComponentWithIconProps
                            statusImage={AddCircleIcon}
                            color="primary"
                            title=""
                            fontSize="large"
                            sx={{ m: -1 }}
                            onClick={startEdit}
                          />
                        </>
                      )}
                    </Grid>
                  </Grid>
                )}
                <Box sx={{ mt: 2, p: 1.5 }}>
                  <DynamicTable
                    data={documentFilteredData}
                    tableHead={documentDataList}
                    rowEdit={getRowID}
                    rowDelete={deleteData}
                    rowStatus={rowStatus}
                    showHeadDelete={true}
                    showHeadEdit={true}
                  />
                </Box>
              </div>
            )}
          </Grid>

          {!documentList.isServerFail &&
            (!documentList?.isDataFetch ? (
              <Loading1 size={50} />
            ) : status && !documentList?.isSave ? (
              <Loading1 size={50} />
            ) : save && !documentList?.isSave ? (
              <Loading1 size={50} />
            ) : del ? (
              <Loading1 size={50} />
            ) : (
              ""
            ))}
        </Paper>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  documentList: state.document,
  isFetch: state.document,
  loginUser: state.user,
  common: state.common,
});

const mapDispatchToProps = {
  getDocument,
  deleteDocumentById,
  getDocumentById,
  resetfilterData,
  resetSave,
  saveDocument,
  filterDocumentData,
  getAlert,
  resetDelete,
  logOut,
};
export default connect(mapStateToProps, mapDispatchToProps)(DocumentMaster);
