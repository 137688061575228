import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import * as React from "react";
import { LabelCompo } from "../../components/Comman/Label";
import "../../CSS/View.css";

const styles = (theme) => ({
  MuiTable: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
  },

  MuiTableHead: {
    background: "#6673fd",
    color: "#FFF",
    border: "1px solid #ddd",
    fontFamily: "poppins",
  },
  MuiTableCell: {
    color: "#FFF",
    fontSize: "16px",
    fontFamily: "poppins",
  },
  MuiTableCellRoot: {
    padding: "7px",
    borderRight: "1px solid #ddd",
    fontFamily: "poppins",
  },
  MuiTableHeadRoot: {
    textAlign: "left",
    padding: "8px",
    border: "1px solid #ddd",
  },
});

const Step3 = ({ data, classes }) => {
  let userindex = 0;

  return (
    <>
      <Grid item xs={12} sm={6} md={12} />

      <div style={{ overflow: "auto" }}>
        <table className={classes.MuiTable}>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              align="left"
              width="10%"
            >
              &nbsp;Sr. No.
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              align="left"
            >
              &nbsp;Qualification criterias
              <br />
              &nbsp;
              <LabelCompo
                className="text-black"
                style={{ fontSize: 10 }}
                label=" Merit list will be generated depending on user's input against following criterias  "
              />
            </td>
          </tr>
          {data.applicationElegibilityDetails.map((data1, dataindex) => {
            if (data1.type == 1) {
              userindex = userindex + 1;
              return (
                <tr>
                  <td className={classes.MuiTableHeadRoot} align="left">
                    {userindex}
                  </td>
                  <td className={classes.MuiTableHeadRoot} align="left">
                    {data1.qualification}
                  </td>
                </tr>
              );
            }
          })}
        </table>
      </div>
    </>
  );
};
export default withStyles(styles)(Step3);
