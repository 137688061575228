import { Grid, MenuItem, Select, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import * as React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import {
  onlyNumber,
  validEmail,
} from "../../components/Comman/Util/Validations";
import * as myConstClass from "../../config/messageconstant";
import DammyLogo from "../../Images/DammyLogo.png";
import { getCategoryType } from "../CategoryMaster/category.slice";
import { downloadFile, saveFile } from "../UploadFile/file.slice";
import {
  deleteInstData,
  getInstByName,
  getInstByShortName,
} from "./institute.slice";

let docs;
class InstitudeForm extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    id: 0,
    instName: "",
    instShortName: "",
    tenantPhone: "",
    emailId: "",
    instAffi: "",
    instAddr: "",
    epfNo: "",
    profTaxNo: "",
    tanNo: "",
    panNo: "",
    uniCode: "",
    aicteCode: "",
    dteCode: "",
    trustId: "",
    dbName: "-",
    domainName: "-",
    flag: 0,
    isActive: "1",
    isEducational: "1",
    logo: DammyLogo,
    logoName: "",
    formErrors: [],
    breadCrum: "",
    logoData: "",
    instType: "",
    religionId: "",
    autonomousStatus: "0",
    minority: "0",
  };
  handleChange = (event) => {
    const fileUploaded = event.target.files[0];

    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({ logo: e.target.result });
    };
    this.setState({ logoName: fileUploaded });
    reader.readAsDataURL(event.target.files[0]);
  };
  handleClick = (event) => {
    this._name.click();
  };
  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  componentDidMount() {
    const { isEdit, getCategoryType, downloadFile, instituteData, institute } =
      this.props;
    getCategoryType();
    let trustId = localStorage.getItem("trustId");
    this.setState({
      trustId: trustId,
    });

    if (isEdit !== undefined && instituteData?.isFetchInstituteById === true) {
      const { fileData } = this.props;
      console.log(isEdit);
      downloadFile({ file: isEdit.logo }).then(() => {});
      this.setState({
        id: isEdit.id,
        instName: isEdit.tenantName,
        instShortName: isEdit.shortName,
        instAffi: isEdit.instAffiliation,
        instAddr: isEdit.address,
        epfNo: isEdit.epfNo,
        profTaxNo: isEdit.profTaxNo,
        tanNo: isEdit.tanNo,
        panNo: isEdit.panNo,
        uniCode: isEdit.uniCode,
        aicteCode: isEdit.aicteCode,
        dteCode: isEdit.dteCode,
        trustName: isEdit.trustName,
        isEducational: isEdit.isEducational,
        isActive: isEdit.isActive,
        emailId: isEdit.tenantEmail,
        tenantPhone: isEdit.tenantPhone,
        logo: fileData.filePath,
        instType: isEdit.instituteType,
        religionId: isEdit.minorityReligion,
        autonomousStatus: isEdit.autonomousStatus,
        minority: isEdit.minority,
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    const { fileData } = this.props;
    if (fileData.filePath !== nextProps.fileData.filePath) {
      this.setState({
        logo: nextProps.fileData.filePath,
      });
    }
  }
  handleFormValidation(name) {
    const {
      instName,
      instShortName,
      instAffi,
      instAddr,
      epfNo,
      tanNo,
      panNo,
      uniCode,
      aicteCode,
      tenantPhone,
      emailId,
      dteCode,
      trustName,
      logo,
      instType,
      religionId,
      autonomousStatus,
      minority,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (emailId === "" || !validEmail.test(emailId)) {
      if (emailId === "") {
        formIsValid = false;
        formErrors["emailIdErr"] = myConstClass.emailIdMsg;
      } else if (!validEmail.test(emailId)) {
        formIsValid = false;
        formErrors["emailIdErr"] = myConstClass.invalidEmailIdMsg;
      }
    }

    if (tenantPhone === "" || tenantPhone.length != 10) {
      if (tenantPhone === "") {
        formIsValid = false;
        formErrors["contactNoErr"] = myConstClass.contactNoMsg;
      } else if (!onlyNumber.test(tenantPhone)) {
        formIsValid = false;
        formErrors["contactNoErr"] = myConstClass.contactNumberMsg;
      } else if (tenantPhone.length > 0 && tenantPhone.length != 10) {
        formIsValid = false;
        formErrors["contactNoErr"] = myConstClass.contactNoLengthMsg;
      }
    }
    if (instName.trim() === "") {
      formIsValid = false;
      formErrors["instNameErr"] = myConstClass.instNameMsg;
    }
    if (instShortName.trim() === "") {
      formIsValid = false;
      formErrors["instShortNameErr"] = myConstClass.instShortNameMsg;
    }
    if (instAddr.trim() === "") {
      formIsValid = false;
      formErrors["instAddrErr"] = myConstClass.instAddr;
    }
    // if ( epfNo.trim() === "" )
    // {
    //   formIsValid = false;
    //   formErrors[ "EPFNoErr" ] = myConstClass.EPFNoMsg;
    // }
    // if ( tanNo.trim() === "" )
    // {
    //   formIsValid = false;
    //   formErrors[ "taxNoErr" ] = myConstClass.taxNoMsg;
    // }
    // if ( panNo.trim() === "" )
    // {
    //   formIsValid = false;
    //   formErrors[ "panNoErr" ] = myConstClass.panNoMsg;
    // }
    // if ( tanNo.trim() === "" )
    // {
    //   formIsValid = false;
    //   formErrors[ "tanNoErr" ] = myConstClass.tanNoMsg;
    // }
    // if ( uniCode.trim() === "" )
    // {
    //   formIsValid = false;
    //   formErrors[ "uniCodeErr" ] = myConstClass.uniCodeMsg;
    // }
    if (logo === DammyLogo) {
      formIsValid = false;
      formErrors["logoErr"] = myConstClass.logoMsg;
    }
    if (instType.trim() === "") {
      formIsValid = false;
      formErrors["instTypeErr"] = myConstClass.instTypeMsg;
    }
    if (religionId === "" && minority == "Minority") {
      formIsValid = false;
      formErrors["religionIdErr"] = myConstClass.religionMsg;
    }
    if (autonomousStatus.trim() === "") {
      formIsValid = false;
      formErrors["autonomousStatusErr"] = myConstClass.autonomousStatusMsg;
    }
    if (minority.trim() === "") {
      formIsValid = false;
      formErrors["minorityErr"] = myConstClass.minorityMsg;
    }
    console.log(formErrors);
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  componentDidUpdate() {
    const { fileData, isEdit } = this.props;
    console.log(fileData);
    if (
      this.props.instituteData.isFetch &&
      this.props.instituteData.isFetchShortName
    ) {
      const {
        id,
        instName,
        instShortName,
        trustId,
        instAffi,
        instAddr,
        logo,
        epfNo,
        profTaxNo,
        aicteCode,
        dteCode,
        tenantPhone,
        emailId,
        tanNo,
        panNo,
        uniCode,
        isEducational,
        domainName,
        dbName,
        logoName,
        flag,
        isActive,
        logoData,
        instType,
        religionId,
        autonomousStatus,
        minority,
      } = this.state;
      docs = {
        tenantName: instName,
        tenantPhone: tenantPhone,
        tenantEmail: emailId,
        trust: {
          id: trustId,
        },
        shortName: instShortName,
        instAffiliation: instAffi,
        isEducational: "1",
        epfNo: epfNo,
        profTaxNo: profTaxNo,
        tanNo: tanNo,
        panNo: panNo,
        uniCode: uniCode,
        aicteCode: aicteCode,
        dteCode: dteCode,
        isActive: isActive,
        delStatus: "1",
        address: instAddr,
        domainName: domainName,
        dbName: dbName,
        instituteType: instType,
        minorityReligion: religionId,
        autonomousStatus: autonomousStatus,
        minority: minority,
      };
      console.log(docs);

      if (id != 0) {
        docs = {
          ...docs,
          id: id,
        };
      }
      if (
        this.props.instituteData.instData.length == 0 &&
        this.props.instituteData.shortNameData.length == 0
      ) {
        if (flag == 1) {
          if (this.state.logo !== "" && this.state.logo == fileData.filePath) {
            docs = {
              ...docs,
              logo: fileData.file,
            };
            this.props.saveAndNext(docs);
            this.props.deleteInstData();
          } else {
            if (fileData.isFetch) {
              docs = {
                ...docs,
                logo: fileData.file,
              };
              this.props.saveAndNext(docs);
              this.props.deleteInstData();
            }
          }
          this.setState({
            id: 0,
            instName: "",
            instShortName: "",
            trustId: "",
            instAffi: "",
            instAddr: "",
            logo: "",
            epfNo: "",
            profTaxNo: "",
            aicteCode: "",
            dteCode: "",
            tenantPhone: "",
            emailId: "",
            tanNo: "",
            panNo: "",
            uniCode: "",
            isEducational: "",
            domainName: "",
            dbName: "",
            logoName: "",
            isActive: 0,
            formErrors: [],
            flag: 0,
            instType: "",
            religionId: "",
            autonomousStatus: "",
            minority: "",
          });
        } else if (flag == 2) {
          if (this.state.logo !== "" && this.state.logo == fileData.filePath) {
            docs = {
              ...docs,
              logo: fileData.file,
            };
            this.props.OnSave(docs);
            this.props.deleteInstData();
          } else {
            if (fileData.isFetch) {
              docs = {
                ...docs,
                logo: fileData.file,
              };
              this.props.OnSave(docs);
              this.props.deleteInstData();
            }
          }
        }
      } else {
        let formErrors = {};
        if (this.props.instituteData.shortNameData.length != 0) {
          formErrors["instShortNameErr"] = myConstClass.instShortNameUniMsg;
        }
        if (this.props.instituteData.instData.length != 0) {
          formErrors["instNameErr"] = myConstClass.instNameUniMsg;
        }

        this.setState({ formErrors: formErrors });
        this.props.deleteInstData();
      }
    }
  }

  saveHandler = (e) => {
    e.preventDefault();
    const { fileData, isEdit } = this.props;
    this.setState({
      flag: 1,
    });
    const { logoName } = this.state;

    const { saveFile, downloadFile } = this.props;

    const { id, instName, instShortName } = this.state;

    if (this.handleFormValidation()) {
      saveFile({ file: logoName });
      this.props.getInstByName({ id: id, instName: instName.trim() });
      this.props.getInstByShortName({
        id: id,
        shortName: instShortName.trim(),
      });
    } else {
      swal("Some fields are empty!");
      if (this.handleFormValidation()) {
        this.props.saveAndNext(docs);
      }else{
        swal("Some fields are empty!");
      }
    }
  };
  submitHandler = (e) => {
    e.preventDefault();
    const { fileData, isEdit } = this.props;
    this.setState({
      flag: 2,
    });
    const { logoName } = this.state;

    const { saveFile, downloadFile } = this.props;

    const { id, instName, instShortName } = this.state;

    if (this.handleFormValidation()) {
      saveFile({ file: logoName });
      this.props.getInstByName({ id: id, instName: instName.trim() });
      this.props.getInstByShortName({
        id: id,
        shortName: instShortName.trim(),
      });
      // this.props.OnSave(docs);
    } else {
      swal("Some fields are empty!");
      if (this.handleFormValidation()) {
        this.props.OnSave(docs);
      }else{
        swal("Some fields are empty!");
      }
    }
  };
  ChangeHandlerSearch = ( name, newValue ) =>
  {
    if(newValue!==null){
      this.setState({ [name]: newValue });
    }
    else{
      this.setState({ [name]: '' });
    }
  };
  render() {
    const {
      id,
      instName,
      instShortName,
      instAffi,
      instAddr,
      epfNo,
      profTaxNo,
      aicteCode,
      dteCode,
      tenantPhone,
      emailId,
      tanNo,
      panNo,
      uniCode,
      isEducational,
      isActive,
      instType,
      religionId,
      autonomousStatus,
      minority,
      flag,
    } = this.state;
    const {
      trustNameErr,
      contactNoErr,
      contactNumberLenErr,
      contactNoValidErr,
      emailIdErr,
      invalidEmailIdErr,
      instNameErr,
      instShortNameErr,
      instAddrErr,
      EPFNoErr,
      taxNoErr,
      tanNoErr,
      panNoErr,
      uniCodeErr,
      logoErr,
      instTypeErr,
      religionIdErr,
      autonomousStatusErr,
      minorityErr,
    } = this.state.formErrors;
    const { onCancel, instituteData, categoryList } = this.props;
    console.log(categoryList?.categoryList);
    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid container spacing={2}>
            <Grid
              sx={{ m: -1 }}
              item
              xs={12}
              md={12}
              size="small"
              container
              justifyContent="flex-end"
            >
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name=" Back to List"
                onClick={onCancel}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={1}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                // rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={2}
                  lg={2}
                  // rowSpacing={1}
                  // columnSpacing={{xs:12, sm:6, md:1}}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <img
                      id="target"
                      src={this.state.logo}
                      height="100px"
                      width="100"
                      align="center"
                      style={{ display: "block", margin: " 0 auto" }}
                    />
                    <input
                      color="primary"
                      accept="image/*"
                      type="file"
                      id="Inputfile"
                      ref={(input) => {
                        return (this._name = input);
                      }}
                      onChange={this.handleChange}
                      style={{ display: "none" }}
                    />
                  </Grid>
                  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                  <Grid item xs={12} sm={12} md={12}>
                    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Upload Logo"
                      onClick={this.handleClick}
                    />
                    {/* <label htmlFor="icon-button-file">
                &emsp;&emsp;
                <LabelCompo
                  className="text-black"
                  style={{ fontWeight: "bold", fontSize: 14,textAlign:"center",display:"block" }}
                  label="Upload Logo"
                  onClick={this.handleClick}
                />
              </label> */}
                    <FormHelperText error>
                      &emsp;&nbsp;&emsp;&nbsp; &nbsp;
                      {logoErr}
                    </FormHelperText>
                  </Grid>
                </Grid>
                {/* <Grid item xs={6} sm container spacing={2}> */}
                {/* <Grid item xs container direction="column" spacing={2}> */}
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={10}
                  lg={10}
                  rowSpacing={1}
                  // columnSpacing={{xs:12, sm:6, md:1}}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={5}
                    lg={5}
                    rowSpacing={3}
                    // columnSpacing={{xs:12, sm:6, md:1}}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Institute Name :"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} mt={-2}>
                      <TextField
                        size="small"
                        color="primary"
                        name="instName"
                        id="instName"
                        value={instName}
                        onChange={this.changeHandler}
                        fullWidth
                        error={instNameErr ? true : false}
                        helperText={instNameErr ? instNameErr : " "}
                      ></TextField>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={5}
                    lg={5}
                    rowSpacing={3}
                    // columnSpacing={{xs:12, sm:6, md:1}}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Institute Short Name :"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} mt={-2}>
                      <TextField
                        size="small"
                        color="primary"
                        name="instShortName"
                        id="instShortName"
                        value={instShortName}
                        onChange={this.changeHandler}
                        fullWidth
                        error={instShortNameErr ? true : false}
                        helperText={instShortNameErr ? instShortNameErr : " "}
                      ></TextField>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={5}
                    lg={5}
                    rowSpacing={1}
                    // columnSpacing={{xs:12, sm:6, md:1}}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Institute Affiliation :"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        size="small"
                        color="primary"
                        name="instAffi"
                        id="instAffi"
                        value={instAffi}
                        onChange={this.changeHandler}
                        fullWidth
                        helperText={" "}
                      ></TextField>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={5}
                    lg={5}
                    rowSpacing={3}
                    // columnSpacing={{xs:12, sm:6, md:1}}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Institute Type :"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} mt={-2}>
                      <FormControl color={"primary"} fullWidth={true}>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          className="text-black"
                          name="instType"
                          // id="instType"
                          value={instType}
                          onChange={this.changeHandler}
                          size="small"
                          fullWidth={true}
                        >
                          <MenuItem value="0">Goverment</MenuItem>
                          <MenuItem value="1">Goverment Aided</MenuItem>
                          <MenuItem value="2">Un-Aided</MenuItem>
                          <MenuItem value="3">University Managed</MenuItem>
                        </Select>

                        <FormHelperText error>
                          {instTypeErr ? instTypeErr : " "}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              rowSpacing={1}
              // columnSpacing={{xs:12, sm:6, md:1}}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="*Institute Address :"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  size="small"
                  color="primary"
                  name="instAddr"
                  id="instAddr"
                  value={instAddr}
                  onChange={this.changeHandler}
                  fullWidth
                  error={instAddrErr ? true : false}
                  helperText={instAddrErr ? instAddrErr : " "}
                ></TextField>
              </Grid>
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              rowSpacing={1}
              // columnSpacing={{xs:12, sm:6, md:1}}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="DTE Code :"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  size="small"
                  color="primary"
                  name="dteCode"
                  id="dteCode"
                  value={dteCode}
                  onChange={this.changeHandler}
                  fullWidth
                  helperText={" "}
                ></TextField>
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              rowSpacing={1}
              // columnSpacing={{xs:12, sm:6, md:1}}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="AICTE Code :"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  size="small"
                  color="primary"
                  name="aicteCode"
                  id="aicteCode"
                  value={aicteCode}
                  onChange={this.changeHandler}
                  fullWidth
                  helperText={" "}
                ></TextField>
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              rowSpacing={1}
              // columnSpacing={{xs:12, sm:6, md:1}}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="University Code :"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  size="small"
                  color="primary"
                  name="uniCode"
                  id="uniCode"
                  value={uniCode}
                  onChange={this.changeHandler}
                  fullWidth
                  error={uniCodeErr ? true : false}
                  helperText={uniCodeErr ? uniCodeErr : " "}
                ></TextField>
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={1}
              columnSpacing={{ xs: 12, sm: 6, md: 2 }}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Professional Tax Number :"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    size="small"
                    color="primary"
                    name="profTaxNo"
                    id="profTaxNo"
                    value={profTaxNo}
                    onChange={this.changeHandler}
                    fullWidth
                    error={taxNoErr ? true : false}
                    helperText={taxNoErr ? taxNoErr : " "}
                  ></TextField>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="EPF Number :"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    size="small"
                    color="primary"
                    name="epfNo"
                    id="epfNo"
                    value={epfNo}
                    onChange={this.changeHandler}
                    fullWidth
                    error={EPFNoErr ? true : false}
                    helperText={EPFNoErr ? EPFNoErr : " "}
                  ></TextField>
                </Grid>
              </Grid>
              {/* </Grid> */}
              <Grid item xs={12} sm={12} md={12}>
                {" "}
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="PAN Number :"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    size="small"
                    color="primary"
                    name="panNo"
                    id="panNo"
                    value={panNo}
                    onChange={this.changeHandler}
                    fullWidth
                    error={panNoErr ? true : false}
                    helperText={panNoErr ? panNoErr : " "}
                  ></TextField>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="TAN Number :"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    size="small"
                    color="primary"
                    name="tanNo"
                    id="tanNo"
                    value={tanNo}
                    onChange={this.changeHandler}
                    fullWidth
                    error={tanNoErr ? true : false}
                    helperText={tanNoErr ? tanNoErr : " "}
                  ></TextField>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                {" "}
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Contact Number :"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="tenantPhone"
                    size="small"
                    value={tenantPhone}
                    inputProps={{ maxLength: 10 }}
                    onChange={this.changeHandler}
                    fullWidth
                    error={contactNoErr ? true : false}
                    helperText={contactNoErr ? contactNoErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Email Id :"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="emailId"
                    size="small"
                    value={emailId}
                    onChange={this.changeHandler}
                    fullWidth
                    error={emailIdErr ? true : false}
                    helperText={emailIdErr ? emailIdErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                {" "}
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  rowSpacing={1}
                  // columnSpacing={{xs:12, sm:6, md:1}}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Minority :"
                    />
                  </Grid>

                  <Grid sx={{ mt: 1 }} item xs={12} sm={12} md={12}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="Is Educatoinal"
                        id="minority"
                        name="minority"
                        value={minority}
                        onChange={this.changeHandler}
                        size="small"
                        error={minorityErr ? true : false}
                        helperText={minorityErr ? minorityErr : " "}
                      >
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="Non Minority"
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Minority"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  rowSpacing={1}
                  // columnSpacing={{xs:12, sm:6, md:1}}
                  container
                  justifyContent="space-evenly"
                >
                  {minority === "1" ? (
                    <>
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Select Minority Religion :"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} mt={-2}>
                        <AutoComplete
                          keyColName={"id"}
                          value={religionId}
                          name={"religionId"}
                          options={categoryList?.categoryList}
                          onChange={this.ChangeHandlerSearch}
                        />

                        <FormHelperText error>
                          {religionIdErr ? religionIdErr : " "}
                        </FormHelperText>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                {" "}
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Autonomous Status :"
                  />
                </Grid>
                <Grid sx={{ mt: 1 }} item xs={12} sm={12} md={12}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="Is Educatoinal"
                      id="autonomousStatus"
                      name="autonomousStatus"
                      value={autonomousStatus}
                      onChange={this.changeHandler}
                      size="small"
                      {...(autonomousStatusErr
                        ? { error: true, helperText: autonomousStatusErr }
                        : "")}
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="Non-Autonomous"
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Autonomous"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              {/* <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Is Eductional :"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} mt={-1}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="Is Educatoinal"
                      id="isEducational"
                      name="isEducational"
                      value={isEducational}
                      onChange={this.changeHandler}
                      size="small"
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid> */}
              {/* </Grid> */}
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={1.2}
                lg={1.2}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Save"
                  fullWidth={true}
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={1.8}
                lg={1.8}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Save and Next"
                  fullWidth={true}
                  onClick={this.saveHandler}
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={1.2}
                lg={1.2}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Cancel"
                  fullWidth={true}
                  onClick={onCancel}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  fileData: state.file,
  instituteData: state.institute,
  categoryList: state.category,
});

const mapDispatchToProps = {
  saveFile,
  getInstByShortName,
  getInstByName,
  getCategoryType,
  deleteInstData,
  downloadFile,
};
export default connect(mapStateToProps, mapDispatchToProps)(InstitudeForm);
