import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

const initialState = {
  toggleNav: true,
  nav: [
    {
      name: "Master",
      path: "/master",
      roles: ["trust"],
      subMenu: [
        {
          name: "News Document Notification",
          path: "/newsDocNotification",
        },
        {
          name: "Document",
          path: "/document",
        },
        {
          name: "Qualification",
          path: "/qualification",
        },
        {
          name: "Program Type",
          path: "/program",
        },
        // {
        //   name: "Institute",
        //   path: "/institute",
        // },
        {
          name: "Program Title",
          path: "/programTitle",
        },
        {
          name: "Academic Year",
          path: "/academicYear",
        },
        {
          name: "Year Title",
          path: "/class",
        },
        {
          name: "Reservation Structure",
          path: "/reservationStructure",
        },
        {
          name: "Class Intake Detail",
          path: "/classIntakeDetail",
        },
        {
          name: "Category Seat Intake Detail",
          path: "/CategorySeatIntakeDetail",
        },
      ],
      permission: [],
    },
    {
      name: "Application Form ",
      path: "/listingOfOnlineAppForm",
      subMenu: [
        {
          name: "Application Form List",
          path: "/listingOfOnlineAppForm",
        },
      ],
      roles: ["trust"],
      permission: [],
    },
    {
      name: "Application form for direct admission",
      path: "/list-of-application-delcare",
      roles: ["trust"],
      permission: [],
    },
    {
      name: "Reports",
      path: "/reports",
      subMenu: [
        {
          name: "Reports",
          path: "/reports",
        },
      ],
      roles: ["trust"],
      permission: [],
    },
    {
      name: "Home",
      path: "/student-dashboard",
      roles: ["student"],
      permission: [],
    },
    {
      name: "Profile",
      path: "/interestedprogram",
      roles: ["applicant"],
      permission: [],
    },
    {
      name: "Latest News",
      path: "/latestNews",
      roles: ["student"],
      permission: [],
    },
    {
      name: "Notification",
      path: "/notification",
      roles: ["student"],
      permission: [],
    },
    // {
    //   name: "Basic Details",
    //   path: "/interestedprogram",
    //   roles: ["student"],
    //   permission: [],
    // },
    {
      name: "New Application Form",
      path: "/applicationForm",
      roles: ["student"],
      permission: [],
    },
    {
      name: "Applied Application Form",
      path: "/listofapplication",
      roles: ["student"],
      permission: [],
    },
    // {
    //   name: "Student Direct Admission",
    //   path: "/student-apply-direct-admission",
    //   roles: ["student"],
    //   permission: [],
    // },
    // {
    //   name: "Applied Application Form for direct admision",
    //   path: "/list-of-application-direct-admission",
    //   roles: ["student"],
    //   permission: [],
    // },
    {
      name: "Logout",
      path: "/logout",
      roles: ["trust"],
      permission: [],
    },
    {
      name: "Logout",
      path: "/logout",
      roles: ["student"],
      permission: [],
    },
  ],
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    toggleNav: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        toggleNav: !state.toggleNav,
      };
    },
  },
});

export const { toggleNav } = commonSlice.actions;

export default commonSlice.reducer;

//Actions
export const onToggle = () => async (dispatch) => {
  dispatch(toggleNav());
};
