import { Grid, Typography } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import * as React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import { Loading1 } from "../../components/Loading1";
import * as myConstClass from "../../config/messageconstant";
import { getbranch } from "../BranchMaster/branch.slice";
import GetClassDetails from "../Class/GetClassDetails";
import { getProgramTitleById } from "../ProgramMaster/program.slice";

let docs;

class ClassDetailForm extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    id: 0,
    className: "",
    branchId: "",
    isActive: "1",
    formErrors: [],
    breadCrum: "",
  };

  handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({ logo: e.target.result });
    };
    reader.readAsDataURL(event.target.files[0]);
    this.props.handleFile(fileUploaded);
  };
  handleClick = (event) => {
    this._name.click();
  };

  changeHandler = (event) => {
    const { name, value } = event.target;

    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  componentDidMount() {
    const { getbranch, getProgramTitleById, isEdit, classDetails } = this.props;
    getbranch();

    getProgramTitleById({ programTypeId: classDetails[0].progNameId });

    if (isEdit.length > 0) {
      this.setState({
        id: isEdit[0].id,
        branchId: isEdit[0].courseId,
      });
    }
  }

  handleFormValidation() {
    const { className, branchId } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (branchId === "") {
      formIsValid = false;
      formErrors["branchNameErr"] = myConstClass.branchNameMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const { id, className, branchId, isActive } = this.state;
    const { classDetails } = this.props;
    docs = {
      year: {
        id: classDetails[0].id,
      },
      programTitle: {
        id: branchId,
      },
      isActive: isActive,
      delStatus: 1,
    };
    if (id != 0) {
      docs = {
        ...docs,
        id: id,
      };
    }

    // this.props.OnSave(docs);
    if (this.handleFormValidation()) {
      this.props.OnSave(docs);
    }
  };
  saveHandler = (e) => {
    e.preventDefault();
    const { id, branchId, isActive } = this.state;
    const { classDetails } = this.props;
    docs = {
      year: {
        id: classDetails[0].id,
      },
      programTitle: {
        id: branchId,
      },
      isActive: isActive,
      delStatus: 1,
    };
    if (id != 0) {
      docs = {
        ...docs,
        id: id,
      };
    }

    // this.props.OnSave(docs);
    if (this.handleFormValidation()) {
      this.props.saveAndNext(docs);
    }
  };
  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };
  render() {
    const { className, branchId, isActive } = this.state;

    const { classNameErr, branchNameErr } = this.state.formErrors;
    const { onCancel, classDetails, programMaster, classList, branchList } =
      this.props;
    console.log(classDetails);
    return (
      <>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          container
          justifyContent="space-evenly"
        >
          <Grid
            item
            sm={8}
            xs={8}
            md={10}
            container
            justifyContent="flex-start"
          >
            <Typography sx={{ fontSize: "1.1rem" }}>
              Configuration of year with program title
            </Typography>
          </Grid>
          <Grid
            item
            sm={4}
            xs={4}
            md={2}
            sx={{ display: "inline-block" }}
            container
          >
            <ButtonCompo
              size="small"
              type="button"
              sx={{ float: "right" }}
              variant="outlined"
              name=" Back to List"
              onClick={onCancel}
            />
          </Grid>
        </Grid>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={1}
          >
            &nbsp;&nbsp; &nbsp;&nbsp;
            <GetClassDetails classDetails={classDetails} />
            <Grid item xs={12} sm={12} md={12}></Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              // rowSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 14 }}
                  label="*Select Program Title : "
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} mt={-1}>
                <AutoComplete
                  keyColName={"id"}
                  value={branchId}
                  name={"branchId"}
                  options={programMaster?.programTitleData}
                  onChange={this.ChangeHandlerSearch}
                />
                {branchNameErr && (
                  <FormHelperText error>{branchNameErr} </FormHelperText>
                )}
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={1}
                lg={1}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Save"
                  fullWidth={true}
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={1.7}
                lg={1.7}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Save and Next"
                  onClick={this.saveHandler}
                  fullWidth={true}
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={1.1}
                lg={1.1}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Cancel"
                  fullWidth={true}
                  onClick={onCancel}
                />
              </Grid>
            </Grid>
          </Grid>
          {!classList?.isFetchClass && <Loading1 size={50} />}
        </form>
      </>
    );
  }
}

// programTitleData
const mapStateToProps = (state) => ({
  programMaster: state.program,
  branchList: state.branch,
  classList: state.class,
  common: state.common,
});

const mapDispatchToProps = {
  getbranch,
  getProgramTitleById,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassDetailForm);
