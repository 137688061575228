import { Grid, Typography } from "@mui/material";

const GetClassDetails = ({ classDetails }) => {
  return (
    <>
    <Grid item sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{xs:12, sm:6, md:1}}
               container justifyContent="space-evenly">
                 <Grid item sm={12}
              xs={12}
              md={3}
              lg={3}
              rowSpacing={1}
              // columnSpacing={{xs:12, sm:6, md:1}}
               container justifyContent="space-evenly">
        <Grid item xs={7} sm={7} md={12}>
        <Typography color='primary'>Year Name</Typography>
        </Grid>
        <Grid item xs={5} sm={5} md={12}>
        <Typography> {classDetails[0].name}</Typography>
        </Grid>
        </Grid>
        <Grid item sm={12}
              xs={12}
              md={3}
              lg={3}
              rowSpacing={1}
              // columnSpacing={{xs:12, sm:6, md:1}}
               container justifyContent="space-evenly">
        <Grid item xs={7} sm={7} md={12}>
        <Typography color='primary'>Institute Name</Typography>
        </Grid>
        <Grid item xs={5} sm={5} md={12}>
        <Typography> {classDetails[0].tenantName}</Typography>
        </Grid>
        </Grid>
        <Grid item sm={12}
              xs={12}
              md={3}
              lg={3}
              rowSpacing={1}
              // columnSpacing={{xs:12, sm:6, md:1}}
               container justifyContent="space-evenly">
        <Grid item xs={7} sm={7} md={12}>
        <Typography color='primary'> Program Type Name</Typography>
        </Grid>
        <Grid item xs={5} sm={5} md={12}>
        <Typography> {classDetails[0].progName}</Typography>
        </Grid>
        </Grid>
        <Grid item sm={12}
              xs={12}
              md={2}
              lg={2}
              rowSpacing={1}
              // columnSpacing={{xs:12, sm:6, md:1}}
               container justifyContent="space-evenly">
        <Grid item xs={7} sm={7} md={12}>
          <Typography color='primary'>Sort Order No</Typography>
        </Grid>
        <Grid item xs={5} sm={5} md={12}>
        <Typography > {classDetails[0].sortOrderNo}</Typography>
        </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default GetClassDetails;
