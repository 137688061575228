import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid, Paper } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import Alert from "../../Alert/Alert";
import { getAlert } from "../../Alert/alert.slice";
import {
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "../../components/Comman/IconButton";
import DynamicTable from "../../components/Comman/MaterialUIRTTable";
import { DirectionSnackbar } from "../../components/Comman/SnakBarCompo";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
import { Loading1 } from "../../components/Loading1";
import Search from "../../components/Search";
import * as myConstClass from "../../config/messageconstant";
import { logOut } from "../Login/login.slice";
import { downloadFile } from "../UploadFile/file.slice";
import InstExcelData from "./InstExcelData";
import {
  deleteInstitute,
  filterInstData,
  getInstitute,
  getInstituteById,
  resetDelete,
  resetSave,
  saveInstitute,
} from "./institute.slice";
import InstitudeForm from "./InstituteForm";
import ViewInst from "./InstituteView";

const breakpoint = 600;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    title: "Name",
    name: "name",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    title: "Address",
    name: "instAddr",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  // {
  //   title: "Educational Status",
  //   name: "isEducational",
  //   positionCenter: false,
  //   showInscreen: true,
  //   alignCenter:'center'
  // },
  {
    title: "University Code",
    name: "uniCode",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    title: "Status",
    name: "isActive",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
  },
];

const InstituteMaster = ({
  getInstitute,
  filterInstData,
  getInstituteById,
  saveInstitute,
  downloadFile,
  deleteInstitute,
  instituteList,
  loginUser,
  logOut,
  resetSave,
  resetDelete,
  files,
}) => {
  const [isEdit, setEdit] = useState(false);
  const [status, setStatus] = useState(false);
  const [del, setDel] = useState(false);
  const [save, setSave] = useState(false);
  const [snakMsg, setSnakMsg] = useState("");
  const [editRow, setEditRow] = useState([]);
  const [viewRow, setViewRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteropen, setFilterOpen] = useState(false);
  const [openSnak, setOpenSnak] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filteredList, setfilterList] = React.useState([
    "Sr. No.",
    "Name",
    "Address",
    // "Educational Status",
    "University Code",
    "Status",
  ]);
  const [instList, setInstList] = React.useState([
    {
      title: "Sr. No.",
      name: "index",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      title: "Name",
      name: "name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      title: "Address",
      name: "instAddr",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    // {
    //   title: "Educational Status",
    //   name: "isEducational",
    //   positionCenter: false,
    //   showInscreen: true,
    //   alignCenter:'center'
    // },
    {
      title: "University Code",
      name: "uniCode",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      title: "Status",
      name: "isActive",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      title: "Action",
      name: "action",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
  ]);

  const [breadCrumName, setBreadCrum] = useState(" Institute ");
  const [width, setWidth] = React.useState(window.innerWidth);

  const getRowID = async (id) => {
    setViewRow([]);
    getInstituteById({ instId: id }).then(() => {
      setEdit(true);
    });
  };
  const getRowData = async (id) => {
    setOpen(true);
    const viewRow = instituteList?.institute.filter((row) => row.id === id);
    if (viewRow.length > 0) {
      return await setViewRow(viewRow);
    }
    return setViewRow([]);
  };
  const data = (instData) => {
    setSave(true);
    resetSave();
    saveInstitute({ instituteData: instData }).then(() => {
      setOpenSnak(true);
      if (instData.id != null) {
        setSnakMsg(myConstClass.updateMsg);
      } else {
        setSnakMsg(myConstClass.saveMsg);
      }
      setSave(false);
      getInstitute().then((respo) => {
        if (!respo) {
          getAlert({ message: myConstClass.serverMsg });
        }
      });
    });

    setEdit(false);
    setEditRow([]);
  };
  const saveAndNextData = (data) => {
    setSave(true);
    resetSave();
    saveInstitute({ instituteData: data }).then(() => {
      setOpenSnak(true);
      if (data.id != null) {
        setSnakMsg(myConstClass.updateMsg);
      } else {
        setSnakMsg(myConstClass.saveMsg);
      }
      setSave(false);

      getInstitute().then((respo) => {
        if (!respo) {
          getAlert({ message: myConstClass.serverMsg });
        }
      });
    });

    setEdit(true);
    setEditRow([]);
  };
  useEffect(() => {
    getInstitute().then((respo) => {
      if (!respo) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
  });

  const startEdit = () => {
    setEdit(true);
    let t = localStorage.getItem("token");
  };
  const breadCrum = (prevBreadCrum) => {
    setBreadCrum(breadCrumName + prevBreadCrum);
  };
  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setDel(true);
        deleteInstitute({ instId: id }).then(() => {
          setOpenSnak(true);
          setSnakMsg(myConstClass.deleteMsg);
          setDel(false);
          getInstitute().then((respo) => {
            if (!respo) {
              getAlert({ message: myConstClass.serverMsg });
            }
          });
        });
      }
    });
  };

  const instituteFilteredData = instituteList?.institute.filter((item) => {
    return (
      item.instShortName.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.instAffi.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.instAddr.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.isEducational.toLowerCase() === searchValue ||
      item.epfNo.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.profTaxNo.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.tanNo.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.panNo.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.uniCode.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.aicteCode.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.dteCode.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.instituteType.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.minorityReligion.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.autonomousStatus.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.minority.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.isActive.toLowerCase() === searchValue
    );
  });

  const stopEdit = () => {
    setBreadCrum("   >   Master   >   Institute  ");
    resetSave();
    setEdit(false);
  };
  const closeFilter = () => {
    setInstList([
      {
        title: "Sr. No.",
        name: "index",
        positionCenter: true,
        showInscreen: true,
        alignCenter: "center",
      },
      {
        title: "Name",
        name: "name",
        positionCenter: false,
        showInscreen: true,
        alignCenter: "left",
      },
      {
        title: "Address",
        name: "instAddr",
        positionCenter: false,
        showInscreen: true,
        alignCenter: "left",
      },
      // {
      //   title: "Educational Status",
      //   name: "isEducational",
      //   positionCenter: false,
      //   showInscreen: true,
      //   alignCenter:'center'
      // },
      {
        title: "University Code",
        name: "uniCode",
        positionCenter: false,
        showInscreen: true,
        alignCenter: "center",
      },
      {
        title: "Status",
        name: "isActive",
        positionCenter: true,
        showInscreen: true,
        alignCenter: "center",
      },
      {
        title: "Action",
        name: "action",
        positionCenter: false,
        showInscreen: true,
        alignCenter: "right",
      },
    ]);
    setfilterList([
      "Sr. No.",
      "Name",
      "Address",
      // "Educational Status",
      "University Code",
      "Status",
    ]);
    setFilterOpen(false);
  };
  const handleChange = (event) => {
    setFilterOpen(true);
    const {
      target: { value },
    } = event;
    if (value.length > filteredList.length) {
      let row = [];
      const a = instList.map((qualiData) => {
        let trust = value.filter((namesRow) => namesRow === qualiData.title);

        if (trust.length !== 0) {
          console.log("shraddha");
          let qData = {
            ...qualiData,
            showInscreen: true,
          };
          row.push(qData);
        } else {
          let qData = {
            ...qualiData,
            showInscreen: false,
          };
          row.push(qualiData);
        }

        return setInstList(row);
      });
      console.log(row);
    } else {
      let row = [];
      let difference = filteredList.filter((x) => !value.includes(x));
      const qualificationList = instList.filter((data) => {
        if (data.title === difference[0]) {
          let QualiData = {
            ...data,
            showInscreen: false,
          };
          row.push(QualiData);
        } else {
          row.push(data);
        }
      });
      setInstList(row);
    }

    setfilterList(typeof value === "string" ? value.split(",") : value);

    if (value.length === 0) {
      setFilterOpen(false);
    }
  };

  const rowStatus = async (id) => {
    swal(myConstClass.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        const viewRow = instituteList?.institute.filter((row) => row.id === id);

        let trustId = localStorage.getItem("trustId");
        let branchData = {
          id: viewRow[0].id,
          aicteCode: viewRow[0].aicteCode,
          dbName: viewRow[0].dbName,
          delStatus: viewRow[0].delStatus,
          domainName: viewRow[0].domainName,
          dteCode: viewRow[0].dteCode,
          epfNo: viewRow[0].epfNo,
          address: viewRow[0].instAddr,
          instAffiliation: viewRow[0].instAffi,
          tenantName: viewRow[0].instName,
          shortName: viewRow[0].instShortName,
          isActive: viewRow[0].isActive === "Active" ? "0" : "1",
          isEducational: viewRow[0].isEducational === "Yes" ? 1 : 0,
          logo: viewRow[0].logo,
          panNo: viewRow[0].panNo,
          profTaxNo: viewRow[0].profTaxNo,
          tanNo: viewRow[0].tanNo,
          uniCode: viewRow[0].uniCode,
          tenantEmail: viewRow[0].tenantEmail,
          tenantPhone: viewRow[0].tenantPhone,
          instituteType: viewRow[0].instituteType,
          minorityReligion: viewRow[0].minorityReligion,
          autonomousStatus: viewRow[0].autonomousStatus,
          minority: viewRow[0].minority,
          trust: {
            id: trustId,
          },
        };

        setStatus(true);
        saveInstitute({ instituteData: branchData }).then(() => {
          setOpenSnak(true);
          setSnakMsg(myConstClass.activeStatus);
          getInstitute().then((respo) => {
            if (!respo) {
              getAlert({ message: myConstClass.serverMsg });
            }
          });
          setStatus(false);
        });
      }
    });
  };
  return (
    <>
      <div>
        <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
          <TitleAndBreadCrum title="Institute" breadCrumName={breadCrumName} />
        </Paper>
        <br />
        {/* {instituteList.isDelete && instituteList.error == true && (
          <Alert msg={myConstClass.deleteFailMsg} closeAlert={resetDelete} />
        )}
        {instituteList.isServerFail && <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />}
        {files.isServerFail && <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />} */}

        {instituteList.isDelete && instituteList.error == false ? (
          <DirectionSnackbar
            open={openSnak}
            severity="success"
            msg={myConstClass.deleteMsg}
            closeSnak={setOpenSnak}
          />
        ) : instituteList.isSave ? (
          <DirectionSnackbar
            open={openSnak}
            severity="success"
            msg={snakMsg}
            closeSnak={setOpenSnak}
          />
        ) : (
          ""
        )}
        <Paper sx={{ p: 2, pt: 4, borderRadius: 2 }} elevation={0}>
          <div>
            {viewRow.length >= 0 && isEdit && (
              <InstitudeForm
                OnSave={data}
                isEdit={instituteList?.instituteById}
                onCancel={stopEdit}
                breadCrum={breadCrum}
                saveAndNext={saveAndNextData}
              />
            )}
          </div>
          {open && (
            <ViewInst data={viewRow} openparam={open} closeView={setOpen} />
          )}
          <Grid>
            {!isEdit && (
              <div>
                {width > breakpoint ? (
                  <>
                    <Box
                      sx={{ mr: 2 }}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Box sx={{ m: -1, width: 300 }}>
                        <Search
                          filteredRows={setSearchValue}
                          searchValue={searchValue}
                        />
                      </Box>
                      <Box>
                        <FormControl sx={{ m: -1, width: 250 }}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={filteredList}
                            name="sdf"
                            size="small"
                            onChange={handleChange}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {names.map((name) => {
                              return (
                                <MenuItem key={name.name} value={name.title}>
                                  <Checkbox
                                    checked={
                                      filteredList.indexOf(name.title) > -1
                                    }
                                  />
                                  <ListItemText primary={name.title} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>

                        {filteropen && (
                          <>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <InstExcelData
                              csvData={instituteFilteredData}
                              instituteList={instList}
                              fileName="institute"
                              type="pdf"
                            />
                          </>
                        )}
                        {filteropen && (
                          <>
                            &nbsp;&nbsp;
                            <InstExcelData
                              csvData={instituteFilteredData}
                              instituteList={instList}
                              fileName="institute"
                              type="excel"
                            />
                          </>
                        )}

                        {filteropen && (
                          <>
                            &nbsp;&nbsp;
                            <FontAwesomeIconCompo
                              className="fa-times-circle"
                              color="primary"
                              title="Cancel"
                              baseClassName="fas"
                              onClick={closeFilter}
                            />
                          </>
                        )}
                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <InstExcelData
                              csvData={instituteFilteredData}
                              instituteList={instList}
                              fileName="institute"
                              type="pdf"
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;
                            <InstExcelData
                              csvData={instituteFilteredData}
                              instituteList={instList}
                              fileName="institute"
                              type="excel"
                            />
                          </>
                        )}
                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;&nbsp;
                            <MyComponentWithIconProps
                              statusImage={AddCircleIcon}
                              color="primary"
                              title=""
                              fontSize="large"
                              sx={{ m: -1 }}
                              onClick={startEdit}
                            />
                          </>
                        )}
                      </Box>
                    </Box>
                    <Box sx={{ mt: 2, p: 1.5 }}>
                      <DynamicTable
                        data={instituteFilteredData}
                        tableHead={instList}
                        rowEdit={getRowID}
                        rowDelete={deleteData}
                        rowStatus={rowStatus}
                        showHeadDelete={true}
                        showHeadEdit={true}
                      />
                      {/* <InstitudeTable
                    filteredColumns={instList}
                    data={instituteFilteredData}
                    rowEdit={getRowID}
                    rowView={getRowData}
                    rowDelete={deleteData}
                    rowStatus={rowStatus}
                  />{" "} */}
                    </Box>
                  </>
                ) : (
                  <>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      rowSpacing={2}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid xs={12} sm={12} md={12} lg={12} rowSpacing={2}>
                        <Search
                          filteredRows={setSearchValue}
                          searchValue={searchValue}
                        />
                      </Grid>
                      <Grid xs={12} sm={12} md={12} lg={12} sx={{ m: 1 }}>
                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={filteredList}
                            name="sdf"
                            size="small"
                            onChange={handleChange}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {names.map((name) => {
                              return (
                                <MenuItem key={name.name} value={name.title}>
                                  <Checkbox
                                    checked={
                                      filteredList.indexOf(name.title) > -1
                                    }
                                  />
                                  <ListItemText primary={name.title} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <Grid
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          sx={{ m: 1, textAlign: "right" }}
                        >
                          {filteropen && (
                            <>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <InstExcelData
                                csvData={instituteFilteredData}
                                instituteList={instList}
                                fileName="institute"
                                type="pdf"
                              />
                            </>
                          )}
                          {filteropen && (
                            <>
                              &nbsp;&nbsp;
                              <InstExcelData
                                csvData={instituteFilteredData}
                                instituteList={instList}
                                fileName="institute"
                                type="excel"
                              />
                            </>
                          )}

                          {filteropen && (
                            <>
                              &nbsp;&nbsp;
                              <FontAwesomeIconCompo
                                className="fa-times-circle"
                                color="primary"
                                title="Cancel"
                                baseClassName="fas"
                                onClick={closeFilter}
                              />
                            </>
                          )}
                          {!filteropen && filteredList.length >= 0 && (
                            <>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <InstExcelData
                                csvData={instituteFilteredData}
                                instituteList={instList}
                                fileName="institute"
                                type="pdf"
                              />
                            </>
                          )}

                          {!filteropen && filteredList.length >= 0 && (
                            <>
                              &nbsp;&nbsp;
                              <InstExcelData
                                csvData={instituteFilteredData}
                                instituteList={instList}
                                fileName="institute"
                                type="excel"
                              />
                            </>
                          )}
                          {!filteropen && filteredList.length >= 0 && (
                            <>
                              &nbsp;&nbsp;&nbsp;
                              <MyComponentWithIconProps
                                statusImage={AddCircleIcon}
                                color="primary"
                                title=""
                                fontSize="large"
                                sx={{ m: -1 }}
                                onClick={startEdit}
                              />
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Box sx={{ mt: 2, p: 1.5 }}>
                      <DynamicTable
                        data={instituteFilteredData}
                        tableHead={[
                          {
                            title: "Sr. No.",
                            name: "index",
                            positionCenter: true,
                          },
                          {
                            title: "Name",
                            name: "name",
                            positionCenter: false,
                          },
                          {
                            title: "Address",
                            name: "instAddr",
                            positionCenter: false,
                          },
                          // {
                          //   title: "Educational Status",
                          //   name: "isEducational",
                          //   positionCenter: false,
                          // },
                          {
                            title: "University Code",
                            name: "uniCode",
                            positionCenter: false,
                          },
                          {
                            title: "Status",
                            name: "isActive",
                            positionCenter: true,
                          },
                          {
                            title: "Action",
                            name: "action",
                            positionCenter: false,
                          },
                        ]}
                        rowEdit={getRowID}
                        rowDelete={deleteData}
                        rowStatus={rowStatus}
                        showHeadDelete={true}
                        showHeadEdit={true}
                      />
                      {/* <InstitudeTable
                    filteredColumns={instList}
                    data={instituteFilteredData}
                    rowEdit={getRowID}
                    rowView={getRowData}
                    rowDelete={deleteData}
                    rowStatus={rowStatus}
                  />{" "} */}
                    </Box>
                  </>
                )}
              </div>
            )}
          </Grid>
          {!instituteList.isServerFail && !instituteList?.isFetchInstitute ? (
            <Loading1 size={50} />
          ) : status && !instituteList?.isSave ? (
            <Loading1 size={50} />
          ) : save && !instituteList?.isSave ? (
            <Loading1 size={50} />
          ) : del ? (
            <Loading1 size={50} />
          ) : (
            ""
          )}
        </Paper>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  instituteList: state.institute,
  common: state.common,
  loginUser: state.user,
  files: state.file,
});

const mapDispatchToProps = {
  getInstitute,
  filterInstData,
  getInstituteById,
  saveInstitute,
  deleteInstitute,
  downloadFile,
  getAlert,
  logOut,
  resetDelete,
  resetSave,
};
export default connect(mapStateToProps, mapDispatchToProps)(InstituteMaster);
