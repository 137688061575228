import { Grid, Typography } from "@mui/material";
import * as React from "react";
import "../../CSS/View.css";
const ViewPage = ({ data }) => {
  if (data.length > 0) {
    return (
      <Grid className="parent">
        <Grid className="LeftText">
          <Typography id="content1"> Program Type: : </Typography>

          <Typography id="content1">Is Active:</Typography>
        </Grid>

        {data.map((data) => {
          return (
            <Grid className="RightText">
              <Typography id="content1">{data.programType}</Typography>
              <Typography id="content1">{data.isActive}</Typography>
            </Grid>
          );
        })}
      </Grid>
    );
  } else {
    return <label>Error in view</label>;
  }
};
export default ViewPage;