import { Grid, Typography } from "@mui/material";

const GetProgDetails = ( { progDetails } ) =>
{
  return (
    <>
      {/* <Grid container justifyContent="space-evenly" direction="row" spacing={0}> */}
      <Grid item sm={12}
        xs={12}
        md={12}
        lg={12}
        rowSpacing={2}
        columnSpacing={{ xs: 12, sm: 6, md: 1 }}
        container justifyContent="space-evenly">
        <Grid item sm={12}
          xs={12}
          md={5}
          lg={5}
          rowSpacing={1}
          // columnSpacing={{xs:12, sm:6, md:1}}
          container justifyContent="space-evenly">
          <Grid item xs={7} sm={7} md={12}>
            <Typography color="primary">Program Type Name</Typography>
          </Grid>
          <Grid item xs={5} sm={5} md={12}>
            <Typography> {progDetails[ 0 ].progName}</Typography>
          </Grid>
        </Grid>
        <Grid item sm={12}
          xs={12}
          md={5}
          lg={5}
          rowSpacing={1}
          // columnSpacing={{xs:12, sm:6, md:1}}
          container justifyContent="space-evenly">
          <Grid item xs={7} sm={7} md={12}>
            <Typography color="primary"> Program Title Name</Typography>
          </Grid>
          <Grid item xs={5} sm={5} md={12}>
            <Typography> {progDetails[ 0 ].name}</Typography>
          </Grid>
        </Grid>
        <Grid item sm={12}
          xs={12}
          md={2}
          lg={2}
          rowSpacing={1}
          // columnSpacing={{xs:12, sm:6, md:1}}
          container justifyContent="space-evenly">
          {/* <Grid item xs={7} sm={7} md={12}>
          <Typography color="primary"> Direct Admission</Typography>
        </Grid>
        <Grid item xs={5} sm={5} md={12}>
          <Typography> {progDetails[0].dsAdmission}</Typography>
        </Grid> */}
        </Grid>
      </Grid>
    </>
  );
};
export default GetProgDetails;
