import { Divider, FormHelperText, Grid, Paper } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import * as myConstClass from "../../config/messageconstant";
import { getbranch } from "../BranchMaster/branch.slice";
import { getInstitute } from "../Institute/institute.slice";
import { getAlert } from "../../Alert/alert.slice";
import {
  getCourseByInst,
  getInstituteCoruseById,
  getDistictInstitute,
  getDistinctProgramTitle,
} from "./applicant.slice";
let docs;

let tableData = [];
class ChoiceCourseInstitudeForm extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    tableData: [],
    id: 0,
    courseId: "",
    institute: "",
    course1: "",
    institute1: "",
    formErrors: [],
  };

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
    const { applicationData } = this.props;

    if (name == "courseId") {
      this.props.getInstituteCoruseById({
        appliId: applicationData.applicationData.id,
        progTypeId: applicationData.applicationData.programTypeId,
        progTitleId: value,
      })
      // .then((response)=>{
      //   if(!response){
      //     this.props.getAlert({ message: myConstClass.serverMsg });
      //   }
      // })
    }
    if (name == "institute1") {
      this.props.getCourseByInst({
        appliId: applicationData.applicationData.id,
        progTypeId: applicationData.applicationData.programTypeId,
        instId: value,
      })
      // .then((response)=>{
      //   if(!response){
      //     this.props.getAlert({ message: myConstClass.serverMsg });
      //   }
      // })
    }
  };

  componentDidMount() {
    const {
      getbranch,
      getInstitute,
      getDistictInstitute,
      getDistinctProgramTitle,
      applicationData,
    } = this.props;
    tableData = [];
    // getbranch();
    // getInstitute();
    getDistictInstitute({
      appliId: applicationData.applicationData.id,
      progTypeId: applicationData.applicationData.programTypeId,
    })
    // .then((response)=>{
    //   if(!response){
    //     this.props.getAlert({ message: myConstClass.serverMsg });
    //   }
    // })
    getDistinctProgramTitle({
      appliId: applicationData.applicationData.id,
      progTypeId: applicationData.applicationData.programTypeId,
    })
    // .then((response)=>{
    //   if(!response){
    //     this.props.getAlert({ message: myConstClass.serverMsg });
    //   }
    // })
  }

  handleFormValidation() {
    const { courseId, institute, course1, institute1 } = this.state;
    let formErrors = {};

    let formIsValid = true;

    if (institute === "") {
      formIsValid = false;
      formErrors["instituteErr"] = myConstClass.instNameMsg;
    }
    if (courseId === "") {
      formIsValid = false;
      formErrors["courseErr"] = myConstClass.CourseMsgg;
    }
console.log(formErrors)
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  handleFormValidation1() {
    const { course1, institute1 } = this.state;
    let formErrors = {};

    let formIsValid = true;

    if (institute1 === "") {
      formIsValid = false;
      formErrors["instituteErr"] = myConstClass.instNameMsg;
    }
    if (course1 === "") {
      formIsValid = false;
      formErrors["courseErr"] = myConstClass.CourseMsgg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  submitHandler = (e) => {
    e.preventDefault();
    const { courseId, institute, course1, institute1 } = this.state;

    docs = {
      courseId: courseId,
      institute: institute,
      course1: course1,
      institute1: institute1,
    };

    this.props.OnSave(docs);
  };

  saveHandler = (e) => {
    e.preventDefault();
    const { id, courseId, institute, course1, institute1 } = this.state;

    docs = {
      id: id === 0 ? 0 : id,
      course: courseId,
      institute: institute,
      course1: course1,
      institute1: institute1,
    };

    if (this.handleFormValidation()) {
      this.props.saveAndNext(docs);
      let date = new Date();

      this.setState({
        id: 0,
        course: courseId,
        institute: institute,
        course1: course1,
        institute1: institute1,
      });
    }
  };

  componentDidUpdate() {}

  submitData = (e) => {
    e.preventDefault();
    const {
      data,
      branchMaster,
      applicantData,
      instituteList,
      applicationData,
    } = this.props;
    const { courseId, institute, institute1, course1 } = this.state;

    if (data.courseOrInstitute == 1 && this.handleFormValidation()) {
      const viewRow = applicantData?.distinctProgramTitleList.filter(
        (row) => row.id === courseId
      );
      const InstRow = applicantData?.progTitleInstList.filter(
        (row) => row.id === institute
      );
      console.log(InstRow);
      let tId = this.state.tableData.length + 1;
      let data1 = {
        id: tId.toString(),
        courseName: viewRow[0].name,
        courseId: viewRow[0].id,
        institute: InstRow[0].name,
        instituteId: InstRow[0].id,
        progTypeId: applicationData.applicationData.programTypeId,
      };
      tableData.push(data1);

      this.setState({
        tableData: tableData,
        course1: "",
        institute1: "",
      });

      this.props.onSave(data1);
    }
    if (data.courseOrInstitute != 1 && this.handleFormValidation1()) {
      const InstituteRow = applicantData?.distinctInstituteList.filter(
        (row) => row.id === institute1
      );

      const progTitleRow = applicantData?.instProgTitleList.filter(
        (row) => row.id === course1
      );
      console.log(InstituteRow);
      console.log(progTitleRow);
      let tId = this.state.tableData.length + 1;
      let data1 = {
        id: tId.toString(),
        courseName: progTitleRow[0].name,
        courseId: progTitleRow[0].id,
        institute: InstituteRow[0].name,
        instituteId: InstituteRow[0].id,
        progTypeId: applicationData.applicationData.programTypeId,
      };
      tableData.push(data1);

      this.setState({
        tableData: tableData,
        course1: "",
        institute1: "",
      });

      this.props.onSave(data1);
    }
  };

  multiSelectHandler = (value, name) => {
    this.setState({
      ...this.state,
      [name]: typeof value === "string" ? value.split(",") : value,
    });
  };
  ChangeHandlerSearch = (name, newValue) => {
    if(newValue!==null){
      this.setState({ [name]: newValue });
    }
    else{
      this.setState({ [name]: '' });
    }
  };
  render() {
    const { courseId, institute, course1, institute1 } = this.state;
    const { instituteErr, courseErr } = this.state.formErrors;
    const { data, branchMaster, applicantData, rowData } = this.props;
    return (
      <>
        <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
         
          
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              spacing={2}
              columnSpacing={1}
              container
              sx={{ m: 0.5 }}
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                {/* <Grid item xs={12} sm={12} md={0.5}></Grid> */}
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 20 }}
                    label="Choice Course Institute"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={12} />
            {data.courseOrInstitute == "1" ? (
              <>
                <Grid item xs={12} sm={6} md={5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Program Title : "
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Institute :"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2} />
                <Grid m={-1} item xs={2} sm={4} md={5}>
                  {/* <AutoComplete 
                  name={'courseId'} 
                  options={branchMaster?.branch} 
                  onChange={this.ChangeHandlerSearch} 
            /> */}
                  {/* {instituteNameErr && (
                  <FormHelperText error>{instituteNameErr} </FormHelperText>
                )} */}
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      className="text-black"
                      name="courseId"
                      value={courseId}
                      onChange={this.changedHandler}
                      size="small"
                      fullWidth={true}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {applicantData?.distinctProgramTitleList.map((branch) => {
                        return (
                          <MenuItem key={branch.id} value={branch.id}>
                            {branch.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  {courseErr && (
                    <FormHelperText error>{courseErr} </FormHelperText>
                  )}
                </Grid>

                <Grid m={-1} item xs={2} sm={4} md={5}>
                  {/* <AutoComplete 
                  name={'institute'} 
                  options={applicantData.progTitleInstList} 
                  onChange={this.ChangeHandlerSearch} 
            /> */}
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      className="text-black"
                      name="institute"
                      value={institute}
                      onChange={this.changedHandler}
                      size="small"
                      fullWidth={true}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {applicantData.progTitleInstList.map((branch) => {
                        const courseInstData = rowData.filter(
                          (rowData) =>
                            rowData.institute === branch.name &&
                            rowData.courseId === courseId
                        );
                        console.log(courseInstData);
                        if (
                          ((data.Government == true &&
                            branch.instituteType == "0") ||
                            (data.GovernmentAided == true &&
                              branch.instituteType == "1") ||
                            (data.unAided == true &&
                              branch.instituteType == "2") ||
                            (data.UniversityManaged == true &&
                              branch.instituteType == "3") ||
                            (data.nonAutonomous == true &&
                              branch.autonomousStatus == "0") ||
                            (data.autonomous == true &&
                              branch.autonomousStatus == "1") ||
                            (data.nonMiniority == true &&
                              branch.minority == "0") ||
                            (data.miniority == true &&
                              branch.minority == "1")) &&
                          courseInstData.length == 0
                        ) {
                          return (
                            <MenuItem key={branch.id} value={branch.id}>
                              {branch.name}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </FormControl>
                  {instituteErr && (
                    <FormHelperText error>{instituteErr} </FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12} sm={6} md={12}></Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={6} md={5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Institute :"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Program Title :"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2} />
                <Grid m={-1} item xs={2} sm={4} md={5}>
                  {/* <AutoComplete 
                  name={'institute1'} 
                  options={instituteList?.institute} 
                  onChange={this.ChangeHandlerSearch} 
            /> */}
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className="text-black"
                      name="institute1"
                      value={institute1}
                      onChange={this.changedHandler}
                      size="small"
                      fullWidth={true}
                    >
                      {applicantData?.distinctInstituteList.map((institute) => {
                        if (
                          (data.Government == true &&
                            institute.instituteType == "0") ||
                          (data.GovernmentAided == true &&
                            institute.instituteType == "1") ||
                          (data.unAided == true &&
                            institute.instituteType == "2") ||
                          (data.UniversityManaged == true &&
                            institute.instituteType == "3") ||
                          (data.nonAutonomous == true &&
                            institute.autonomousStatus == "0") ||
                          (data.autonomous == true &&
                            institute.autonomousStatus == "1") ||
                          (data.nonMiniority == true &&
                            institute.minority == "0") ||
                          (data.miniority == true && institute.minority == "1")
                        ) {
                          return (
                            <MenuItem key={institute.id} value={institute.id}>
                              {institute.name}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </FormControl>
                  {instituteErr && (
                    <FormHelperText error>{instituteErr} </FormHelperText>
                  )}
                </Grid>

                <Grid m={-1} item xs={2} sm={4} md={5}>
                  {/* <AutoComplete 
                  name={'course1'} 
                  options={applicantData?.instProgTitleList} 
                  onChange={this.ChangeHandlerSearch} 
            /> */}

                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      className="text-black"
                      name="course1"
                      value={course1}
                      onChange={this.changedHandler}
                      size="small"
                      fullWidth={true}
                    >
                      {applicantData?.instProgTitleList.map((institute) => {
                        const courseInstData = rowData.filter(
                          (rowData) =>
                            rowData.courseName === institute.name &&
                            rowData.instituteId === institute1
                        );
                        console.log(courseInstData);
                        if (courseInstData.length == 0) {
                          return (
                            <MenuItem key={institute.id} value={institute.id}>
                              {institute.name}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </FormControl>
                  {courseErr && (
                    <FormHelperText error>{courseErr} </FormHelperText>
                  )}
                </Grid>
                {/* <Grid item xs={12} sm={6} md={12}></Grid> */}
              </>
            )}

            {/* <Grid item xs={12} sm={6} md={1} /> */}
            <Grid m={-1} item xs={2} md={2} container justifyContent="flex-end">
              &nbsp;&nbsp;&nbsp;&nbsp;
              <ButtonCompo
                size="medium"
                type="button"
                variant="contained"
                name="Add"
                onClick={this.submitData}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              {" "}
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  branchMaster: state.branch,
  instituteList: state.institute,
  applicationData: state.appli,
  applicantData: state.applicant,
});

const mapDispatchToProps = {
  getbranch,
  getDistictInstitute,
  getDistinctProgramTitle,
  getInstitute,
  getInstituteCoruseById,
  getCourseByInst,
  getAlert
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChoiceCourseInstitudeForm);
