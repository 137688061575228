import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import Alert from "../../Alert/Alert";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicTable from "../../components/Comman/MaterialUIRTTable";
import { Loading1 } from "../../components/Loading1";
import * as myConstClass from "../../config/messageconstant";
import PriorityProcess from "../ApplicationForm/PriorityProcess";
import { logOut } from "../Login/login.slice";
import {
  getCourseDataById,
  getDeclrationStudentSide,
  getDirectAdmissionDeclrationById,
} from "../AdminDirectAdmission/directAdmission.slice";
import RoundDetails from "../RoundDetails";
import SearchExternalPlacedStudList from "../../components/SearchTable";
import { getAlert } from "../../Alert/alert.slice";
import { downloadF } from "../PDF/PDFDownload";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const rows = [
  {
    id: 1,
    application: "10th Form",
  },
  {
    id: 2,
    application: "12th Form",
  },
  {
    id: 3,
    application: "Bsc Form",
  },
];

const ListOfApplicationDirectAdmission = ({
  applicationList,
  getAlert,
  getDeclrationStudentSide,
  resetDelete,
  loginUser,
  logOut,
  getDirectAdmissionDeclrationById,
  getCourseDataById,
}) => {
  const [appId, setAppId] = useState(0);
  const [roundView, setRoundView] = useState(0);
  const [isEdit, setEdit] = useState(false);
  const [row, setRows] = useState(rows);
  const [id1, setId] = useState("");
  const [editRow, setEditRow] = useState([]);
  const [viewRow, setViewRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [capForm, setCapForm] = useState(false);
  const [filteropen, setFilterOpen] = useState(false);
  const [FL, setFL] = React.useState([]);
  const [filteredList, setfilterList] = React.useState([]);
  const [trustList, setTrustList] = React.useState([]);
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();

  const round = async (id) => {
    setRoundView(1);
    setAppId(id);
  };

  /* 
  useEffect(() => {},[]); */

  useEffect(() => {
    getDeclrationStudentSide().then((response) => {
      if (!response) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
  }, []);

  useEffect(() => {
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
  });

  const getUserRowData = (id) => {
    getDirectAdmissionDeclrationById({ appliId: id }).then((resp) => {
      if (!resp) {
        getAlert({ message: myConstClass.serverMsg });
      } else {
        navigate("/student-direct-admission-preview");
      }
    });
  };

  const pdfDownload = (row) => {
    console.log(row);
    downloadF({
      url:
        "/api/report/v1/user-application-report/" +
        row +
        "/for-direct-admission",

      ext: "pdf",
      openNewTab: true,
    });
  };

  const getRowData = async (id) => {
    setOpen(true);
    const viewRow = row.filter((row) => row.id === id);

    if (viewRow.length > 0) {
      return await setViewRow(viewRow);
    }
    return setViewRow([]);
  };
  const capAdd = (id) => {
    setId(id);

    setCapForm(true);
  };
  const goTolistOfApplications = () => {
    setRoundView(0);
  };
  const getFilteredTableData = () => {
    return applicationList?.applicationDeclrationStudentSide.filter(
      (currentRow) => {
        let isValid = false;
        if (
          (currentRow["title"] &&
            currentRow["title"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["index"] &&
            currentRow["index"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["programTitle"] &&
            currentRow["programTitle"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["applicationNumber"] &&
            currentRow["applicationNumber"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase()))
        ) {
          isValid = true;
          return true;
        }
        return isValid;
      }
    );
  };
  const onSearch = (value) => {
    setSearchValue(value);
  };
  return (
    <>
      <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={2}
          spacing={2}
          columnSpacing={1}
          container
          sx={{ m: 0.5 }}
          justifyContent="space-evenly"
        >
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={9} md={9}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 20 }}
                label="Applied Application Form For Direct Admission"
              />
            </Grid>
            <Grid sx={{ marginBottom: 2 }} item xs={12} sm={3} md={3}>
              <SearchExternalPlacedStudList onSearch={onSearch} />
            </Grid>
          </Grid>
        </Grid>
        {/* <br /> */}

        <DynamicTable
          tableHead={[
            {
              title: "Sr. No.",
              name: "index",
              positionCenter: true,
              showInscreen: true,
              alignCenter: "left",
            },
            {
              title: "Applications",
              name: "title",
              positionCenter: false,
              showInscreen: true,
              alignCenter: "left",
            },
            {
              title: "Program Title",
              name: "programTitle",
              positionCenter: false,
              showInscreen: true,
              alignCenter: "left",
            },
            {
              title: "Application No.",
              name: "applicationNumber",
              positionCenter: false,
              showInscreen: true,
              alignCenter: "left",
            },
            {
              title: "Action",
              name: "action",
              positionCenter: true,
              showInscreen: true,
              alignCenter: "center",
            },
          ]}
          data={getFilteredTableData()}
          rowView={getRowData}
          showHeadDelete={false}
          showHeadEdit={false}
          rounds={round}
          showApply={false}
          showRoundDetails={false}
          showPdfButton={true}
          showView={true}
          rowViewData={getUserRowData}
          pdfDownload={pdfDownload}
          showPegination={false}
        />
        <br></br>
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({
  applicationList: state.directAdmission,
  loginUser: state.user,
});

const mapDispatchToProps = {
  getDeclrationStudentSide,
  // resetDelete,
  logOut,
  getDirectAdmissionDeclrationById,
  getCourseDataById,
  getAlert,
  downloadF,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListOfApplicationDirectAdmission);
