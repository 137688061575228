import { FormHelperText, Grid, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Buttton";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../components/Comman/Label";
import AutoComplete from "../../components/Comman/AutoComplete";
import {
  onlyNumber,
  validEmail,
  textOnlyWithSpaces,
} from "../../components/Comman/Util/Validations";
import * as myConstClass from "../../config/messageconstant";
import swal from "sweetalert";
import { getParentContactValidation } from "./student.slice";
import { DirectionSnackbar } from "../../components/Comman/SnakBarCompo";
let docs;
class PersonalDetailsForm extends React.Component {
  state = {
    id: 0,
    formErrors: [],
    candidatename: "",
    firstname: "",
    middleName: "",
    lastname: "",
    fatherHusband: "",
    mothername: "",
    gender: "",
    dob: "",
    nationality: "Indian",
    relegion: "",
    category: "",
    caste: "",
    subcaste: "",
    bloodgroup: "",
    fathersoccupation: "",
    familyincome: "",
    validitycdno: "",
    validitycddate: "",
    castecertno: "",
    castecertdate: "",
    domicileof: "",
    mothertounge: "",
    parentmobile: "",
    studmobile: "",
    parentemail: "",
    studemail: "",
    remark: "",
    landline: "",
    aadhaarNo: "",
    birthPlace: "",
    martialStatus: 0,
    isStudentNriForeignNational: "",
    oms: "",
    medium: "",
    scholarship: 0,
    parentContactValidation: false,
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    {
      if (event.target.value === "" || onlyNumber.test(event.target.value)) {
        this.setState({
          ...this.state,
          [name]: value,
        });
      }
    }
  };

  changedHandler = (event) => {
    const { name, value } = event.target;
    if (
      event.target.value === "" ||
      textOnlyWithSpaces.test(event.target.value)
    ) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    {
      if (event.target.value === "" || onlyNumber.test(event.target.value)) {
        this.setState({
          ...this.state,
          [name]: value,
        });
      }
    }
  };

  changedHandlerContact = (event) => {
    const { name, value } = event.target;
    const { getParentContactValidation, getAlert, student } = this.props;
    console.log(event.target);

    getParentContactValidation({ contact: value }).then((response) => {
      if (response.data.error) {
        this.setState({
          ...this.state,
          [name]: "",
          parentContactValidation: true,
        });
      } else {
        this.setState({
          ...this.state,
          [name]: value,
        });
      }
    });
    // }
  };

  changedHandlerEmail = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  changeDob = (date) => {
    this.setState({
      dob: date,
    });
  };

  changeValidityDate = (date) => {
    this.setState({
      validitycddate: date,
    });
  };

  changeCasteDate = (date) => {
    this.setState({
      castecertdate: date,
    });
  };

  componentDidMount() {
    const { student } = this.props;

    if (Object.keys(student.applicant).length != 0) {
      const DOBArr =
        student.applicant.dateOfBirth == ""
          ? ""
          : student.applicant.dateOfBirth == null
          ? ""
          : student.applicant.dateOfBirth.split("-");
      const DOB =
        DOBArr == "" ? "" : new Date(DOBArr[2], DOBArr[1] - 1, DOBArr[0]);

      const CasteDobArr =
        student.applicant.castCertificateDate == ""
          ? ""
          : student.applicant.castCertificateDate == null
          ? ""
          : student.applicant.castCertificateDate.split("-");
      const CasteDob =
        CasteDobArr == ""
          ? ""
          : new Date(CasteDobArr[2], CasteDobArr[1] - 1, CasteDobArr[0]);

      const ValidityDobArr =
        student.applicant.validityCdDate == ""
          ? ""
          : student.applicant.validityCdDate == null
          ? ""
          : student.applicant.validityCdDate.split("-");
      const ValidityDob =
        ValidityDobArr == ""
          ? ""
          : new Date(
              ValidityDobArr[2],
              ValidityDobArr[1] - 1,
              ValidityDobArr[0]
            );
      this.setState({
        id: student.applicant.id,
        candidatename: student.applicant.firstName,
        middleName: student.applicant.middleName,
        lastname: student.applicant.lastName,
        gender: student.applicant.gender,
        dob: DOB,
        studemail: student.applicant.studentEmail,
        studmobile: student.applicant.studentMobile,
        aadhaarNo: student.applicant.aadhaarNo,
        birthPlace: student.applicant.birthPlace,
        nationality: student.applicant.nationality,
        relegion: student.applicant.religionId,
        caste: student.applicant.cast,
        category: student.applicant.studCategory,
        subcaste: student.applicant.subCast,
        bloodgroup: student.applicant.bloodGroup,
        validitycdno: student.applicant.validityCdNo,
        validitycddate: ValidityDob,
        castecertno: student.applicant.castCertificateNo,
        castecertdate: CasteDob,
        domicileof: student.applicant.domicileof,
        mothertounge: student.applicant.motherTongue,
        fatherHusband: student.applicant.fatherName,
        mothername: student.applicant.motherName,
        fathersoccupation: student.applicant.fatherOccupation,
        familyincome: student.applicant.annualIncome,
        parentmobile: student.applicant.parentMobile,
        parentemail: student.applicant.parentEmail,
        landline: student.applicant.landlineNo,
        remark: student.applicant.remark,
        medium: student.applicant.mediumOfLanguageInPreviousCollege,
        martialStatus: student.applicant.martialStatus,
        isStudentNriForeignNational:
          student.applicant.isStudentNriForeignNational,
        oms: student.applicant.oms,
        scholarship: student.applicant.scholarship,
      });
    } else {
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.student.applicant !== nextProps.student.applicant) {
      const DOBArr =
        nextProps.student.applicant.dateOfBirth == ""
          ? ""
          : nextProps.student.applicant.dateOfBirth.split("-");
      const DOB =
        DOBArr == "" ? "" : new Date(DOBArr[2], DOBArr[1] - 1, DOBArr[0]);

      const CasteDobArr =
        nextProps.student.applicant.castCertificateDate == ""
          ? ""
          : nextProps.student.applicant.castCertificateDate.split("-");
      const CasteDob =
        CasteDobArr == ""
          ? ""
          : new Date(CasteDobArr[2], CasteDobArr[1] - 1, CasteDobArr[0]);

      const ValidityDobArr =
        nextProps.student.applicant.validityCdDate == ""
          ? ""
          : nextProps.student.applicant.validityCdDate.split("-");
      const ValidityDob =
        ValidityDobArr == ""
          ? ""
          : new Date(
              ValidityDobArr[2],
              ValidityDobArr[1] - 1,
              ValidityDobArr[0]
            );
      this.setState({
        id: nextProps.student.applicant.id,
        candidatename: nextProps.student.applicant.firstName,
        middleName: nextProps.student.applicant.middleName,
        lastname: nextProps.student.applicant.lastName,
        gender: nextProps.student.applicant.gender,
        dob: DOB,
        studemail: nextProps.student.applicant.studentEmail,
        studmobile: nextProps.student.applicant.studentMobile,
        aadhaarNo: nextProps.student.applicant.aadhaarNo,
        birthPlace: nextProps.student.applicant.birthPlace,
        nationality:
          nextProps.student.applicant.nationality === ""
            ? "Indian"
            : nextProps.student.applicant.nationality,
        relegion:
          nextProps.student.applicant.religionId === ""
            ? "815692a9-a6c8-43d0-bd62-a44b5921470d"
            : nextProps.student.applicant.religionId,
        caste: nextProps.student.applicant.cast,
        category: nextProps.student.applicant.studCategory,
        subcaste: nextProps.student.applicant.subCast,
        bloodgroup: nextProps.student.applicant.bloodGroup,
        validitycdno: nextProps.student.applicant.validityCdNo,
        validitycddate: ValidityDob,
        castecertno: nextProps.student.applicant.castCertificateNo,
        castecertdate: CasteDob,
        domicileof:
          nextProps.student.applicant.domicileof === ""
            ? "Maharastra"
            : nextProps.student.applicant.domicileof,
        mothertounge: nextProps.student.applicant.motherTongue,
        fatherHusband: nextProps.student.applicant.fatherName,
        mothername: nextProps.student.applicant.motherName,
        fathersoccupation: nextProps.student.applicant.fatherOccupation,
        familyincome: nextProps.student.applicant.annualIncome,
        parentmobile: nextProps.student.applicant.parentMobile,
        parentemail: nextProps.student.applicant.parentEmail,
        landline: nextProps.student.applicant.landlineNo,
        remark: nextProps.student.applicant.remark,
        medium: nextProps.student.applicant.mediumOfLanguageInPreviousCollege,
        martialStatus: nextProps.student.applicant.martialStatus,
        isStudentNriForeignNational:
          nextProps.student.applicant.isStudentNriForeignNational,
        oms: nextProps.student.applicant.oms,
        scholarship: nextProps.student.applicant.scholarship,
      });
    }
  }
  changeFromDate = (date) => {
    let frmDate = moment(date).format("DD/MM/YYYY");
    let fromYear = new Date(date).getFullYear();
    let month = new Date(date).getMonth();
    let day = new Date(date).getDate();
    let changedToDate = new Date(fromYear + 1, month, day - 1);
    let toYear = new Date(changedToDate).getFullYear();
    this.setState({
      dob: date,
    });
  };

  changeValidatityDate = (date) => {
    let frmDate = moment(date).format("DD/MM/YYYY");
    let fromYear = new Date(date).getFullYear();
    let month = new Date(date).getMonth();
    let day = new Date(date).getDate();
    let changedToDate = new Date(fromYear + 1, month, day - 1);
    let toYear = new Date(changedToDate).getFullYear();
    this.setState({
      validitycddate: date,
    });
  };

  changeCasteDate = (date) => {
    let frmDate = moment(date).format("DD/MM/YYYY");
    let fromYear = new Date(date).getFullYear();
    let month = new Date(date).getMonth();
    let day = new Date(date).getDate();
    let changedToDate = new Date(fromYear + 1, month, day - 1);
    let toYear = new Date(changedToDate).getFullYear();
    this.setState({
      castecertdate: date,
    });
  };

  handleFormValidation(name) {
    const {
      candidatename,
      middleName,
      lastname,
      fatherHusband,
      mothername,
      gender,
      dob,
      nationality,
      relegion,
      category,
      caste,
      subcaste,
      bloodgroup,
      fathersoccupation,
      familyincome,
      mothertounge,
      parentmobile,
      studmobile,
      studemail,
      parentemail,
      remark,
      oms,
      aadhaarNo,
      birthPlace,
      martialStatus,
      scholarship,
      isStudentNriForeignNational,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (oms === "") {
      formIsValid = false;
      formErrors["omsErr"] = myConstClass.omsMsg;
    }

    // if ( scholarship === "" )
    // {
    //   formIsValid = false;
    //   formErrors[ "scholarShipErr" ] = myConstClass.isScholarShipMsg;
    // }

    if (dob === "") {
      formIsValid = false;
      formErrors["dobErr"] = myConstClass.dobMsg;
    }

    if (isStudentNriForeignNational === "") {
      formIsValid = false;
      formErrors["isStudentNriForeignNationalErr"] =
        myConstClass.isStudentNriForeignNationalMsg;
    }

    if (martialStatus === "") {
      formIsValid = false;
      formErrors["martialStatusErr"] = myConstClass.martialMsg;
    }

    if (gender === "") {
      formIsValid = false;
      formErrors["genderErr"] = myConstClass.genderMsg;
    }
    if (mothername === "") {
      formIsValid = false;
      formErrors["mothernameErr"] = myConstClass.motherNameMsg;
    }
    if (fatherHusband === "") {
      formIsValid = false;
      formErrors["fatherHusbandErr"] = myConstClass.fatherHusbandNameMsg;
    }
    if (candidatename === "") {
      formIsValid = false;
      formErrors["candidatenameErr"] = myConstClass.firstNameMsg;
    }

    // if ( familyincome === "" )
    // {
    //   formIsValid = false;
    //   formErrors[ "familyincomeErr" ] = myConstClass.annualIncomeMsg;
    // }
    if (fathersoccupation === "") {
      formIsValid = false;
      formErrors["fathersoccupationErr"] = myConstClass.fatherOccupationMsg;
    }
    if (bloodgroup === "") {
      formIsValid = false;
      formErrors["bloodgroupErr"] = myConstClass.bloodGroupMsg;
    }
    if (subcaste === "") {
      formIsValid = false;
      formErrors["subcasteErr"] = myConstClass.subcasteMsg;
    }
    if (caste === "") {
      formIsValid = false;
      formErrors["casteErr"] = myConstClass.casteMsg;
    }
    if (category === "" || category === null) {
      formIsValid = false;
      formErrors["categoryErr"] = myConstClass.catNameMsg;
    }
    if (relegion === "" || relegion === null) {
      formIsValid = false;
      formErrors["relegionErr"] = myConstClass.religionMsg;
    }
    if (nationality === "") {
      formIsValid = false;
      formErrors["nationalityErr"] = myConstClass.nationalityMsg;
    }

    // if ( aadhaarNo === "" || aadhaarNo.length != 12 )
    // {
    //   if ( aadhaarNo === "" )
    //   {
    //     formIsValid = false;
    //     formErrors[ "aadhaarNoErr" ] = myConstClass.InvalidadharMsg;
    //   } else if ( !onlyNumber.test( aadhaarNo ) )
    //   {
    //     formIsValid = false;
    //     formErrors[ "aadhaarNoErr" ] = myConstClass.InvalidadharMsg;
    //   } else if ( studmobile.length > 0 && aadhaarNo.length != 12 )
    //   {
    //     formIsValid = false;
    //     formErrors[ "aadhaarNoErr" ] = myConstClass.InvalidadharMsg;
    //   }
    // }

    if (studemail === "" || !validEmail.test(studemail)) {
      if (studemail === "") {
        formIsValid = false;
        formErrors["studemailErr"] = myConstClass.emailIdMsg;
      } else if (!validEmail.test(studemail)) {
        formIsValid = false;
        formErrors["studemailErr"] = myConstClass.invalidEmailIdMsg;
      }
    }

    if (studmobile === "" || studmobile.length != 10) {
      if (studmobile === "") {
        formIsValid = false;
        formErrors["studmobileErr"] = myConstClass.contactNoMsg;
      } else if (!onlyNumber.test(studmobile)) {
        formIsValid = false;
        formErrors["studmobileErr"] = myConstClass.contactNumberMsg;
      } else if (studmobile.length > 0 && studmobile.length != 10) {
        formIsValid = false;
        formErrors["studmobileErr"] = myConstClass.contactNoLengthMsg;
      }
    }

    if (parentmobile === "" || parentmobile.length != 10) {
      if (parentmobile === "") {
        formIsValid = false;
        formErrors["parentmobileErr"] = myConstClass.contactNoMsg;
      } else if (!onlyNumber.test(parentmobile)) {
        formIsValid = false;
        formErrors["parentmobileErr"] = myConstClass.contactNumberMsg;
      } else if (parentmobile.length > 0 && parentmobile.length != 10) {
        formIsValid = false;
        formErrors["parentmobileErr"] = myConstClass.contactNoLengthMsg;
      }
    }

    if (mothertounge === "") {
      formIsValid = false;
      formErrors["mothertoungeErr"] = myConstClass.mothertoungeMsg;
    }

    // if (middleName === "") {
    //   formIsValid = false;
    //   formErrors["middleNameErr"] = myConstClass.middleNameMsg;
    // }

    // if (lastname === "") {
    //   formIsValid = false;
    //   formErrors["lastNameErr"] = myConstClass.lastNameMsg;
    // }

    if (birthPlace === "") {
      formIsValid = false;
      formErrors["birthPlaceErr"] = myConstClass.birthplaceMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const {
      id,
      candidatename,
      middleName,
      lastname,
      fatherHusband,
      mothername,
      gender,
      dob,
      nationality,
      relegion,
      category,
      caste,
      subcaste,
      bloodgroup,
      fathersoccupation,
      familyincome,
      validitycdno,
      validitycddate,
      castecertno,
      castecertdate,
      domicileof,
      mothertounge,
      parentmobile,
      studmobile,
      studemail,
      parentemail,
      remark,
      medium,
      landline,
      aadhaarNo,
      birthPlace,
      martialStatus,
      isStudentNriForeignNational,
      oms,
      scholarship,
    } = this.state;

    let fd = dob === null ? "" : moment(dob).format("DD-MM-YYYY");
    let vd =
      validitycddate == "Invalid Date" || "" || null
        ? ""
        : moment(validitycddate).format("DD-MM-YYYY");
    let cd =
      castecertdate == "Invalid Date" || "" || null
        ? ""
        : moment(castecertdate).format("DD-MM-YYYY");

    const { getParentContactValidation, getAlert, student } = this.props;

    getParentContactValidation({ contact: parentmobile }).then((response) => {
      if (response.data.error) {
        this.setState({
          ...this.state,
          parentmobile: "",
          parentContactValidation: true,
        });
      } else {
        docs = {
          id: id,
          firstName: candidatename,
          middleName: middleName,
          lastName: lastname,
          motherName: mothername,
          gender: gender,
          dateOfBirth: fd,
          bloodGroup: bloodgroup,
          religionId: relegion,
          studCategory: category,
          cast: caste,
          subCast: subcaste,
          fatherOccupation: fathersoccupation,
          annualIncome: familyincome,
          fatherName: fatherHusband,
          nationality: nationality,
          domicileof: domicileof,
          motherTongue: mothertounge,
          parentMobile: parentmobile,
          parentEmail: parentemail,
          studentMobile: studmobile,
          studentEmail: studemail,
          landlineNo: landline,
          remark: remark,
          mediumOfLanguageInPreviousCollege: medium,
          validityCdNo: validitycdno,
          validityCdDate: vd === "Invalid date" ? "" : vd,
          castCertificateNo: castecertno,
          castCertificateDate: cd === "Invalid date" ? "" : cd,
          aadhaarNo: aadhaarNo,
          birthPlace: birthPlace,
          martialStatus: martialStatus,
          isStudentNriForeignNational: isStudentNriForeignNational,
          oms: oms,
          scholarship: scholarship,
          type: 1,
        };

        if (this.handleFormValidation()) {
          this.props.OnSave(docs);
        } else {
          // swal( {
          //   title: "Empty Fields",
          //   text: "Some fields are empty!",
          //   icon: "warning",
          //   buttons: true,
          //   dangerMode: true,
          // } )
          swal("Some fields are empty!");
        }
      }
    });
  };

  saveHandler = (e) => {
    e.preventDefault();
    const {
      id,
      candidatename,
      middleName,
      lastname,

      fatherHusband,
      mothername,
      gender,
      dob,
      nationality,
      relegion,
      category,
      caste,
      subcaste,
      bloodgroup,
      fathersoccupation,
      familyincome,
      validitycdno,
      validitycddate,
      castecertno,
      castecertdate,
      domicileof,
      mothertounge,
      parentmobile,
      parentemail,
      remark,
      medium,
      landline,
      aadhaarNo,
      birthPlace,
      martialStatus,
      isStudentNriForeignNational,
      oms,
      scholarship,
    } = this.state;

    docs = {
      id: id === 0 ? 0 : id,
      candidatename: candidatename,
      middleName: middleName,
      lastname: lastname,
      fatherHusband: fatherHusband,
      mothername: mothername,
      gender: gender,
      dob: dob,
      nationality: nationality,
      relegion: relegion,
      category: category,
      caste: caste,
      subcaste: subcaste,
      bloodgroup: bloodgroup,
      fathersoccupation: fathersoccupation,
      familyincome: familyincome,
      validitycdno: validitycdno,
      validitycddate: validitycddate,
      castecertno: castecertno,
      castecertdate: castecertdate,
      domicileof: domicileof,
      mothertounge: mothertounge,
      parentmobile: parentmobile,
      parentemail: parentemail,
      remark: remark,
      mediumOfLanguageInPreviousCollege: medium,
      landline: landline,
      aadhaarNo: aadhaarNo,
      birthPlace: birthPlace,
      martialStatus: martialStatus,
      isStudentNriForeignNational: isStudentNriForeignNational,
      oms: oms,
      scholarship: scholarship,
    };

    if (this.handleFormValidation()) {
      this.props.saveAndNext(docs);

      this.setState({
        id: 0,
        candidatename: candidatename,
        middleName: middleName,
        lastname: lastname,
        gender: gender,
        dob: dob,
        birthPlace: birthPlace,
        bloodgroup: bloodgroup,

        fatherHusband: fatherHusband,
        mothername: mothername,
        nationality: nationality,
        relegion: relegion,
        category: category,
        caste: caste,
        subcaste: subcaste,
        fathersoccupation: fathersoccupation,
        familyincome: familyincome,
        validitycdno: validitycdno,
        validitycddate: validitycddate,
        castecertno: castecertno,
        castecertdate: castecertdate,
        domicileof: domicileof,
        mothertounge: mothertounge,
        parentmobile: parentmobile,
        parentemail: parentemail,
        remark: remark,
        mediumOfLanguageInPreviousCollege: medium,
        landline: landline,
        aadhaarNo: aadhaarNo,
        martialStatus: martialStatus,
        isStudentNriForeignNational: isStudentNriForeignNational,
        oms: oms,
        scholarship: scholarship,
      });
    } else {
      swal("Some fields are empty!");
    }
  };

  parentContactValidationClose = () => {
    this.setState({
      parentContactValidation: false,
    });
  };
  render() {
    const {
      id,
      candidatename,
      middleName,
      lastname,
      fatherHusband,
      mothername,
      gender,
      dob,
      nationality,
      relegion,
      category,
      caste,
      subcaste,
      bloodgroup,
      fathersoccupation,
      familyincome,
      validitycdno,
      validitycddate,
      castecertno,
      castecertdate,
      domicileof,
      mothertounge,
      parentmobile,
      studmobile,
      studemail,
      parentemail,
      remark,
      medium,
      landline,
      aadhaarNo,
      birthPlace,
      martialStatus,
      isStudentNriForeignNational,
      oms,
      scholarship,
      parentContactValidation,
    } = this.state;

    const {
      lastNameErr,
      middleNameErr,
      mothertoungeErr,
      parentmobileErr,
      parentemailErr,
      casteErr,
      categoryErr,
      subcasteErr,
      bloodgroupErr,
      fathersoccupationErr,
      familyincomeErr,
      studmobileErr,
      studemailErr,
      validitycdnoErr,
      validitycddateErr,
      castecertnoErr,
      castecertdateErr,
      dobErr,
      nationalityErr,
      relegionErr,
      candidatenameErr,
      fatherHusbandErr,
      mothernameErr,
      genderErr,
      remarkErr,
      aadhaarNoErr,
      birthPlaceErr,
      martialStatusErr,
      omsErr,
      scholarShipErr,
      isStudentNriForeignNationalErr,
    } = this.state.formErrors;

    const { onCancel, religionMaster, categoryList, student, profile } =
      this.props;
    console.log(student);
    return (
      <>
        {parentContactValidation && (
          <DirectionSnackbar
            open={true}
            severity="error"
            msg={
              "Parent Mobile No cannot be the same as the registered mobile number."
            }
            closeSnak={this.parentContactValidationClose}
          />
        )}
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            <Grid item xs={12} sm={6} md={12} />

            <Grid
              columnSpacing={1}
              // columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="flex-start"
                marginBottom={2}
              >
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 12, color: "#808080" }}
                  label="Note : Name should be same as that of the marksheet. "
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} />
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                spacing={1}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={4}
                  lg={4}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*First Name  : "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="candidatename"
                      size="small"
                      value={candidatename}
                      fullWidth
                      onChange={this.changedHandler}
                      error={candidatenameErr ? true : false}
                      helperText={candidatenameErr ? candidatenameErr : " "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={4}
                  lg={4}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Middle Name  : "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="middleName"
                      size="small"
                      value={middleName}
                      fullWidth
                      onChange={this.changedHandler}
                      error={middleNameErr ? true : false}
                      helperText={middleNameErr ? middleNameErr : " "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={4}
                  lg={4}
                  columnSpacing={4}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Last Name  : "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="lastname"
                      size="small"
                      value={lastname}
                      fullWidth
                      onChange={this.changedHandler}
                      error={lastNameErr ? true : false}
                      helperText={lastNameErr ? lastNameErr : " "}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs  :12, sm  :6, md  :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Gender  :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className="text-black"
                      name="gender"
                      id="gender"
                      value={gender}
                      onChange={this.changedHandler}
                      size="small"
                      fullWidth={true}
                      {...(genderErr ? { error: true } : "")}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="m">Male</MenuItem>
                      <MenuItem value="f">Female</MenuItem>
                      <MenuItem value="t">Transgender</MenuItem>
                    </Select>
                  </FormControl>

                  <FormHelperText error>
                    {genderErr ? genderErr : " "}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs  :12, sm  :6, md  :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Date of Birth  :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <BasicDatePicker
                    color="primary"
                    name="dob"
                    size="small"
                    value={dob}
                    maxDate={new Date()}
                    fullWidth
                    onChange={this.changeFromDate}
                    error={dobErr ? dobErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs  :12, sm  :6, md  :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Birth Place  :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="birthPlace"
                    size="small"
                    value={birthPlace}
                    onChange={this.changedHandler}
                    fullWidth
                    error={birthPlaceErr ? true : false}
                    helperText={birthPlaceErr ? birthPlaceErr : " "}
                  />
                </Grid>
              </Grid>{" "}
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs  :12, sm  :6, md  :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Blood Group  :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      name="bloodgroup"
                      id="bloodgroup"
                      value={bloodgroup}
                      onChange={this.changedHandlerEmail}
                      size="small"
                      fullWidth={true}
                      {...(bloodgroupErr ? { error: true } : "")}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {student?.bloodGroup.map((blood) => {
                        return (
                          <MenuItem key={blood.id} value={blood.id}>
                            {blood.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <FormHelperText error>
                    {bloodgroupErr ? bloodgroupErr : " "}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={3}
                // columnSpacing={{xs  :12, sm  :6, md  :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Language( Medium in previous college ) :"
                  />
                </Grid>

                <Grid sx={{ marginTop: -3 }} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="medium"
                    size="small"
                    value={medium}
                    onChange={this.changedHandler}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs  :12, sm  :6, md  :1}}
                container
                sx={{ marginTop: 2 }}
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Student Contact No  :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="studmobile"
                    size="small"
                    value={studmobile}
                    inputProps={{ maxLength: 10 }}
                    onChange={this.changeHandler}
                    fullWidth
                    error={studmobileErr ? true : false}
                    helperText={studmobileErr ? studmobileErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                sx={{ marginTop: 2 }}
                // columnSpacing={{xs  :12, sm  :6, md  :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Student Email  :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="studemail"
                    size="small"
                    value={studemail}
                    onChange={this.changedHandlerEmail}
                    fullWidth
                    error={studemailErr ? true : false}
                    helperText={studemailErr ? studemailErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs  :12, sm  :6, md  :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Father's/Husband's Name  : "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="fatherHusband"
                    size="small"
                    value={fatherHusband}
                    onChange={this.changedHandler}
                    fullWidth
                    error={fatherHusbandErr ? true : false}
                    helperText={fatherHusbandErr ? fatherHusbandErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Mother's Name  :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="mothername"
                    size="small"
                    value={mothername}
                    onChange={this.changedHandler}
                    fullWidth
                    error={mothernameErr ? true : false}
                    helperText={mothernameErr ? mothernameErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs  :12, sm  :6, md  :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Martial Status  :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className="text-black"
                      name="martialStatus"
                      id="martialStatus"
                      value={martialStatus}
                      onChange={this.changeHandler}
                      size="small"
                      fullWidth={true}
                      {...(martialStatusErr ? { error: true } : "")}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={0}>Un-Married</MenuItem>
                      <MenuItem value={1}>Married</MenuItem>
                      <MenuItem value={2}>Divorce</MenuItem>
                      <MenuItem value={3}>widowed</MenuItem>
                    </Select>
                  </FormControl>

                  <FormHelperText error>
                    {martialStatusErr ? martialStatusErr : " "}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs  :12, sm  :6, md  :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Mother Tongue  :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="mothertounge"
                    size="small"
                    value={mothertounge}
                    onChange={this.changedHandler}
                    fullWidth
                    error={mothertoungeErr ? true : false}
                    helperText={mothertoungeErr ? mothertoungeErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs  :12, sm  :6, md  :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Father/Mother/Family Occupation  :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="fathersoccupation"
                    size="small"
                    value={fathersoccupation}
                    onChange={this.changedHandler}
                    fullWidth
                    error={fathersoccupationErr ? true : false}
                    helperText={
                      fathersoccupationErr ? fathersoccupationErr : " "
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs  :12, sm  :6, md  :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Family's Annual Income  :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className="text-black"
                      name="familyincome"
                      id="familyincome"
                      value={familyincome}
                      onChange={this.changedHandlerEmail}
                      size="small"
                      fullWidth={true}
                    >
                      {student?.annualIncome.map((annualIncome) => {
                        return (
                          <MenuItem
                            key={annualIncome.id}
                            value={annualIncome.id}
                          >
                            {annualIncome.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <FormHelperText error> </FormHelperText>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs  :12, sm  :6, md  :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Belong to other than Maharashtra : "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className="text-black"
                      name="oms"
                      id="oms"
                      value={oms}
                      onChange={this.changeHandler}
                      size="small"
                      fullWidth={true}
                      {...(omsErr
                        ? {
                            error: true,
                          }
                        : "")}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>

                      <MenuItem value={0}>No</MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                    </Select>
                  </FormControl>
                  <FormHelperText error>{omsErr ? omsErr : " "}</FormHelperText>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs  :12, sm  :6, md  :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Is Student NRI/Foreign National  :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className="text-black"
                      name="isStudentNriForeignNational"
                      id="isStudentNriForeignNational"
                      value={isStudentNriForeignNational}
                      onChange={this.changeHandler}
                      size="small"
                      fullWidth={true}
                      {...(isStudentNriForeignNationalErr
                        ? {
                            error: true,
                          }
                        : "")}
                    >
                      <MenuItem value="0">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="1">NRI</MenuItem>
                      <MenuItem value="2">Foreign National</MenuItem>
                    </Select>
                  </FormControl>
                  <FormHelperText error>
                    {isStudentNriForeignNationalErr
                      ? isStudentNriForeignNationalErr
                      : " "}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12} />
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs  :12, sm  :6, md  :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Aadhar No  :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="aadhaarNo"
                    size="small"
                    value={aadhaarNo}
                    inputProps={{ maxLength: 12 }}
                    onChange={this.changeHandler}
                    fullWidth
                    // error={aadhaarNoErr ? true : false}
                    // helperText={aadhaarNoErr ? aadhaarNoErr : " "}

                    helperText={" "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs  :12, sm  :6, md  :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Nationality  :"
                  />
                </Grid>
                {console.log(nationality)}
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="nationality"
                    size="small"
                    value={nationality}
                    onChange={this.changedHandler}
                    fullWidth
                    error={nationalityErr ? true : false}
                    helperText={nationalityErr ? nationalityErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs  :12, sm  :6, md  :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Religion  :"
                  />
                </Grid>

                <Grid sx={{ marginTop: -2 }} item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={relegion}
                    name={"relegion"}
                    fullWidth
                    options={religionMaster?.religion}
                    onChange={this.ChangeHandlerSearch}
                  />

                  <FormHelperText error>
                    {relegionErr ? relegionErr : " "}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Category  :"
                  />
                </Grid>

                <Grid sx={{ marginTop: -2 }} item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={category}
                    name={"category"}
                    options={categoryList?.category.filter(
                      (row) => row.type === "Original Category"
                    )}
                    onChange={this.ChangeHandlerSearch}
                  />
                  <FormHelperText error>
                    {categoryErr ? categoryErr : " "}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                sx={{ marginTop: -1 }}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Caste  :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="caste"
                    size="small"
                    value={caste}
                    onChange={this.changedHandler}
                    fullWidth
                    error={casteErr ? true : false}
                    helperText={casteErr ? casteErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs  :12, sm  :6, md  :1}}
                container
                sx={{ marginTop: -1 }}
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Subcaste  :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="subcaste"
                    size="small"
                    value={subcaste}
                    onChange={this.changedHandler}
                    fullWidth
                    error={subcasteErr ? true : false}
                    helperText={subcasteErr ? subcasteErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs  :12, sm  :6, md  :1}}
                container
                justifyContent="space-evenly"
              >
                {/* <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Is Scholarship  :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className="text-black"
                      name="scholarship"
                      id="scholarship"
                      value={scholarship}
                      onChange={this.changeHandler}
                      size="small"
                      fullWidth={true}
                      error={scholarShipErr ? true : false}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={0}>No</MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                    </Select>
                  </FormControl>

                  <FormHelperText error>
                    {scholarShipErr ? scholarShipErr : " "}
                  </FormHelperText>
                </Grid> */}

                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Landline No  :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="landline"
                    size="small"
                    value={landline}
                    onChange={this.changeHandler}
                    fullWidth
                    helperText=" "
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs  :12, sm  :6, md  :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Domicile State  :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="domicileof"
                    size="small"
                    value={domicileof}
                    onChange={this.changedHandler}
                    fullWidth
                    helperText=" "
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs  :12, sm  :6, md  :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Caste Certificate No  :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="castecertno"
                    size="small"
                    value={castecertno}
                    onChange={this.changedHandlerEmail}
                    fullWidth
                    error={castecertnoErr ? true : false}
                    helperText={castecertnoErr ? castecertnoErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs  :12, sm  :6, md  :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Caste Certificate Date  :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <BasicDatePicker
                    name="castecertdate"
                    value={castecertdate}
                    onChange={this.changeCasteDate}
                    maxDate={new Date()}
                    error={castecertdateErr ? castecertdateErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs  :12, sm  :6, md  :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Validity Certificate No  :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="validitycdno"
                    size="small"
                    value={validitycdno}
                    onChange={this.changedHandlerEmail}
                    fullWidth
                    error={validitycdnoErr ? true : false}
                    helperText={validitycdnoErr ? validitycdnoErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs  :12, sm  :6, md  :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Validity Certificate Date  :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <BasicDatePicker
                    name="validitycddate"
                    value={validitycddate}
                    onChange={this.changeValidatityDate}
                    maxDate={new Date()}
                    error={" "}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12} />
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs  :12, sm  :6, md  :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Parent's Contact No  :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="parentmobile"
                    size="small"
                    value={parentmobile}
                    inputProps={{ maxLength: 10 }}
                    onChange={this.changeHandler}
                    onBlur={this.changedHandlerContact}
                    fullWidth
                    error={parentmobileErr ? true : false}
                    helperText={parentmobileErr ? parentmobileErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs  :12, sm  :6, md  :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Parent's Email  :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="parentemail"
                    size="small"
                    value={parentemail}
                    onChange={this.changedHandlerEmail}
                    fullWidth
                    error={parentemailErr ? true : false}
                    helperText={parentemailErr ? parentemailErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} />
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                {/* <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Landline No  :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="landline"
                    size="small"
                    value={landline}
                    onChange={this.changeHandler}
                    fullWidth
                    helperText=' '
                  />
                </Grid> */}

                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Remark  :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="remark"
                    size="small"
                    value={remark}
                    onChange={this.changedHandler}
                    fullWidth
                    error={remarkErr ? true : false}
                    helperText={remarkErr ? remarkErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs  :12, sm  :6, md  :1}}
                container
                justifyContent="space-evenly"
              >
                {/* <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Remark  :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="remark"
                    size="small"
                    value={remark}
                    onChange={this.changedHandler}
                    fullWidth
                    error={remarkErr ? true : false}
                    helperText={remarkErr ? remarkErr : " "}
                  />
                </Grid> */}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={12}></Grid>

            <Grid item xs={12} sm={6} md={1} />

            <Grid
              rowSpacing={2}
              // columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              columnSpacing={1}
              justifyContent="right"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={1.1}
                lg={1.1}
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Cancel"
                  onClick={onCancel}
                  fullWidth={true}
                />
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={2.5}
                lg={2.5}
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Save and Proceed"
                  fullWidth={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  religionMaster: state.religion,
  categoryList: state.category,
  student: state.student,
});

const mapDispatchToProps = {
  getParentContactValidation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalDetailsForm);
