import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import commonSlice from "../CommonActions/common.slice";
import { localStorageMiddleware } from "../middleware/storage";
import academicYearSlice from "../Pages/AcademicYearMaster/academicYear.slice";
import applicantSlice from "../Pages/ApplicationForm/applicant.slice";
import branchSlice from "../Pages/BranchMaster/branch.slice";
import categorySlice from "../Pages/CategoryMaster/category.slice";
import catSeatIntakeDetailSlice from "../Pages/CategorySeatIntakeDetail/catSeatIntakeDetail.slice";
import classSlice from "../Pages/Class/class.slice";
import classDetailsSlice from "../Pages/ClassDetail/classDetail.slice";
import classIntakeDetailSlice from "../Pages/ClassIntakeDetail/claasIntakeDetail.slice";
import documentSlice from "../Pages/DocumentMaster/document.slice";
import studentUserSlice from "../Pages/ForgotUserId/forgotUserId.slice";
import userPasswordSlice from "../Pages/ForgotUserPassword/forgotPassword.slice";
import generatemeritlistSlice from "../Pages/GenerateMeritList/generatemeritlist.slice";
import instituteSlice from "../Pages/Institute/institute.slice";
import instituteCourseSlice from "../Pages/InstituteCourse/instituteCourse.slice";
import LandingSlice from "../Pages/Landing/Landing.slice";
import loginSlice from "../Pages/Login/login.slice";
import NewsDocNotificationSlice from "../Pages/NewsDocNotification/newsDocNotification.slice";
import declarationSlice from "../Pages/onlineAppProcess/declaration.slice";
import programSlice from "../Pages/ProgramMaster/program.slice";
import programTypeSlice from "../Pages/ProgramType/programType.slice";
import qualificationSlice from "../Pages/QualificationMaster/qualification.slice";
import religionSlice from "../Pages/Religion/religion.slice";
import reservationStructureSlice from "../Pages/ReservationStructure/reservationStructure.slice";
import roundDetailSlice from "../Pages/RoundDetails/roundDetail.slice";
import scheduleformslice from "../Pages/ScheduleDetails/scheduleform.slice";
import studentRegisterSlice from "../Pages/Student Register/studentRegister.slice";
import studentSlice from "../Pages/Student/student.slice";
import universitySlice from "../Pages/University/university.slice";
import fileSlice from "../Pages/UploadFile/file.slice";
import AlertSlice from "../Alert/alert.slice";
import seatAcceptLetterSlice from "../Pages/SeatAcceptanceLetter/seatAcceptLetter.slice";
import directAdmissionSlice from "../Pages/AdminDirectAdmission/directAdmission.slice";
import studentDirectAdmissionSlice from "../Pages/StudentDirectAdmission/studentDirectAdmission.slice";
import { UserActionTypes } from "../CommonActions/User.actions";

const reducer = combineReducers({
  user: loginSlice,
  common: commonSlice,
  programType: programTypeSlice,
  religion: religionSlice,
  category: categorySlice,
  document: documentSlice,
  branch: branchSlice,
  university: universitySlice,
  academicYear: academicYearSlice,
  program: programSlice,
  institute: instituteSlice,
  class: classSlice,
  classDetails: classDetailsSlice,
  ClassIntakeDetail: classIntakeDetailSlice,
  qualification: qualificationSlice,
  instituteCourse: instituteCourseSlice,
  file: fileSlice,
  appli: declarationSlice,
  reservCat: reservationStructureSlice,
  applicant: applicantSlice,
  studentRegister: studentRegisterSlice,
  catSeatIntakeDetail: catSeatIntakeDetailSlice,
  newsDocNotification: NewsDocNotificationSlice,
  student: studentSlice,
  meritlist: generatemeritlistSlice,
  roundDetails: roundDetailSlice,
  userPassword: userPasswordSlice,
  studentUser: studentUserSlice,
  scheduleFormList: scheduleformslice,
  landing: LandingSlice,
  alertInfo: AlertSlice,
  seatAcceptLetter: seatAcceptLetterSlice,
  directAdmission: directAdmissionSlice,
  studentDirectAdmission: studentDirectAdmissionSlice,
});

const rootReducer = (state, action) => {
  if (action.type === UserActionTypes.LOGOUT) {
    console.log("user logging out");
    state = undefined;
  }
  return reducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(localStorageMiddleware),
});

export default store;
