import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import * as React from "react";
import { LabelCompo } from "../../components/Comman/Label";

const styles = (theme) => ({
  MuiTable: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
  },
  MuiTableHeadRoot: {
    textAlign: "left",
    padding: "8px",
    border: "1px solid #ddd",
  },
});

const BasicTable = ({ data, classes }) => {
  return (
    <>
      <LabelCompo
        className="text-black"
        style={{ fontSize: 20 }}
        label="Reservation Details"
      />
      <Grid>&nbsp;&nbsp;</Grid>
      <div style={{ overflow: "auto" }}>
        <table className={classes.MuiTable}>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Person with Disability?
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.handicapCategory === 1 ? "yes" : "no"}
            </td>
          </tr>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Is parents is defence personel?{" "}
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.defenceType === 1 ? "yes" : "no"}
            </td>
          </tr>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Orphan?{" "}
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.isOrphan === 1 ? "yes" : "no"}
            </td>
          </tr>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Do You Belong to minority?{" "}
            </td>
            <td className={classes.MuiTableHeadRoot}>&nbsp;{data.minority}</td>
          </tr>
        </table>
      </div>
    </>
  );
};
export default withStyles(styles)(BasicTable);
