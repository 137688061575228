import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid, Paper } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import Alert from "../../Alert/Alert";

import {
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "../../components/Comman/IconButton";
import { DirectionSnackbar } from "../../components/Comman/SnakBarCompo";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
import Search from "../../components/Search";
import * as myConstClass from "../../config/messageconstant";
import {
  deleteReligionById,
  getReligion,
  getReligionById,
  saveReligion,
  resetDelete,
} from "./religion.slice";
import ReligionExcelData from "./ReligionExcel";
import ReligionForm from "./Religionform";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  { title: "Religion Name", name: "name" },
  { title: "Status", name: "isActive" },
];

const ReligionMaster = ({
  getReligion,
  deleteReligionById,
  saveReligion,
  religionMaster,
  resetDelete,
}) => {
  const [isEdit, setEdit] = useState(false);
  const [snakMsg, setSnakMsg] = useState("");
  const [editRow, setEditRow] = useState([]);
  const [viewRow, setViewRow] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteropen, setFilterOpen] = useState(false);
  const [openSnak, setOpenSnak] = useState(false);
  const [filteredList, setfilterList] = React.useState([
    "Religion Name",
    "Status",
  ]);

  const [religionList, setReligionList] = React.useState([
    [{ title: "Sr. No.", name: "index" }],
    [{ title: "Religion Name", name: "name" }],
    [{ title: "Status", name: "isActive" }],
  ]);

  const rowStatus = async (id) => {
    swal(myConstClass.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        const viewRow = religionMaster?.religion.filter((row) => row.id === id);

        let religionData = {
          id: viewRow[0].id,
          name: viewRow[0].name,
          isActive: viewRow[0].isActive === "Active" ? "0" : "1",
          delStatus: 1,
        };

        saveReligion({ religionData: religionData }).then(() => {
          setOpenSnak(true);
          setSnakMsg(myConstClass.activeStatus);
          getReligion();
        });
      }
    });
  };

  const getRowID = async (id) => {
    setViewRow([]);
    const editedRow = religionMaster?.religion.filter((row) => row.id === id);
    if (editedRow.length > 0) {
      setEdit(true);
      setEditRow([]);
      return await setEditRow(editedRow);
    }
    return setEditRow([]);
  };

  const data = (instData) => {
    saveReligion({ religionData: instData }).then(() => {
      setOpenSnak(true);
      if (instData.id != null) {
        setSnakMsg(myConstClass.updateMsg);
      } else {
        setSnakMsg(myConstClass.saveMsg);
      }
      getReligion();
    });

    setEdit(false);
    setEditRow([]);
  };
  useEffect(async () => {
    getReligion();
  }, []);

  const startEdit = () => {
    setEdit(true);
  };

  const saveAndNextData = (data) => {
    saveReligion({ religionData: data }).then(() => {
      setOpenSnak(true);
      if (data.id != null) {
        setSnakMsg(myConstClass.updateMsg);
      } else {
        setSnakMsg(myConstClass.saveMsg);
      }
      getReligion();
    });

    setEdit(true);
    setEditRow([]);
  };

  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be retrived,Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteReligionById({ religionId: id }).then((res) => {
          setOpenSnak(true);
          setSnakMsg(myConstClass.deleteMsg);
          getReligion();
        });
      }
    });
  };
  const filteredRows = (searchvalue) => {
    const { value } = searchvalue.target;
    setSearchValue(value);
  };

  const stopEdit = () => {
    setEditRow([]);
    setEdit(false);
  };

  const closeFilter = () => {
    setReligionList([
      [{ title: "Sr. No.", name: "index" }],
      [{ title: "Religion Name", name: "name" }],
      [{ title: "Status", name: "isActive" }],
    ]);
    setfilterList(["Religion Name", "Active Status"]);
    setFilterOpen(false);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value.length > filteredList.length) {
    } else {
      let difference = filteredList.filter((x) => !value.includes(x));
      const religionListNew = religionList.filter((data) => {
        return data[0].title !== difference[0];
      });
      setReligionList(religionListNew);
    }

    setfilterList(typeof value === "string" ? value.split(",") : value);

    if (value.length === 0) {
      setFilterOpen(false);
    }
  };

  return (
    <>
      <div>
        <Paper sx={{ p: 1, borderRadius: 2 }} elevation={2}>
          <TitleAndBreadCrum title="Religion" />
        </Paper>
        {religionMaster.isServerFail && (
          <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />
        )}
        &nbsp;&nbsp;
        {
          <DirectionSnackbar
            open={openSnak}
            severity="success"
            msg={snakMsg}
            closeSnak={setOpenSnak}
          />
        }
        <Paper sx={{ p: 4, borderRadius: 4 }} elevation={2}>
          <div>
            {viewRow.length >= 0 && editRow.length >= 0 && isEdit && (
              <ReligionForm
                OnSave={data}
                isEdit={editRow}
                onCancel={stopEdit}
                saveAndNext={saveAndNextData}
              />
            )}
          </div>

          <Grid>
            {!isEdit && (
              <div>
                <Box display="flex" justifyContent="space-between">
                  <Box>
                    {!filteropen && (
                      <Search
                        filteredRows={filteredRows}
                        searchValue={searchValue}
                      />
                    )}
                  </Box>

                  <Box>
                    <FormControl sx={{ m: -1, width: 250 }}>
                      <InputLabel id="demo-multiple-checkbox-label">
                        Filter
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={filteredList}
                        name="sdf"
                        size="small"
                        onChange={handleChange}
                        input={<OutlinedInput label="Filter" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => {
                          return (
                            <MenuItem key={name.name} value={name.title}>
                              <Checkbox
                                checked={filteredList.indexOf(name.title) > -1}
                              />
                              <ListItemText primary={name.title} />
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>

                    {filteropen && (
                      <>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <ReligionExcelData
                          csvData={religionMaster?.religion}
                          relList={religionList}
                          fileName="Religion"
                          type="pdf"
                        />
                      </>
                    )}

                    {filteropen && (
                      <>
                        &nbsp;&nbsp;
                        <ReligionExcelData
                          csvData={religionMaster?.religion}
                          relList={religionList}
                          fileName="Religion"
                          type="excel"
                        />
                      </>
                    )}

                    {filteropen && (
                      <>
                        &nbsp;&nbsp;
                        <FontAwesomeIconCompo
                          className="fa-times-circle"
                          color="primary"
                          title="Cancel"
                          baseClassName="fas"
                          onClick={closeFilter}
                        />
                      </>
                    )}

                    {!filteropen && filteredList.length >= 0 && (
                      <>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <ReligionExcelData
                          csvData={religionMaster?.religion}
                          relList={religionList}
                          fileName="Religion"
                          type="pdf"
                        />
                      </>
                    )}

                    {!filteropen && filteredList.length >= 0 && (
                      <>
                        &nbsp;&nbsp;
                        <ReligionExcelData
                          csvData={religionMaster?.religion}
                          relList={religionList}
                          fileName="religion"
                          type="excel"
                        />
                      </>
                    )}

                    {!filteropen && filteredList.length >= 0 && (
                      <>
                        &nbsp;&nbsp;&nbsp;
                        <MyComponentWithIconProps
                          statusImage={AddCircleIcon}
                          color="primary"
                          fontSize="large"
                          sx={{ m: -1 }}
                          onClick={startEdit}
                        />
                      </>
                    )}
                  </Box>
                </Box>
                {/* 
                <ReligionTable
                  filteredColumns={religionList}
                  data={religionMaster?.religion}
                  rowEdit={getRowID}
                  rowDelete={deleteData}
                  rowStatus={rowStatus}
                /> */}
              </div>
            )}
          </Grid>
        </Paper>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  religionMaster: state.religion,
  isFetch: state.religion,
});

const mapDispatchToProps = {
  getReligion,
  deleteReligionById,
  getReligionById,
  saveReligion,
  resetDelete,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReligionMaster);
