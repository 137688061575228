import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid, Paper } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import Alert from "../../Alert/Alert";
import { getAlert } from "../../Alert/alert.slice";
import {
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "../../components/Comman/IconButton";
import DynamicTable from "../../components/Comman/MaterialUIRTTable";
import { DirectionSnackbar } from "../../components/Comman/SnakBarCompo";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
import { Loading1 } from "../../components/Loading1";
import Search from "../../components/Search";
import * as myConstClass from "../../config/messageconstant";
import { logOut } from "../Login/login.slice";
import ProgExcelData from "./ProgExcelData";
import ProgMView from "./ProgMView";
import {
  deleteProgramById,
  filterProgramData,
  getProgram,
  getProgramById,
  resetDelete,
  resetSave,
  saveProgram,
} from "./program.slice";
import ProgramMForm from "./ProgramMForm";
const breakpoint = 600;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  { title: "Sr. No.", name: "index" },
  { title: "Program", name: "progName" },
  { title: "University Name", name: "uniName" },
  { title: "Program Type Name", name: "programType" },
  { title: "Program Duration (In years)", name: "progDuration" },
  { title: "Status", name: "isActive" },
];

const ProgramMaster = ({
  getProgram,
  getProgramById,
  deleteProgramById,
  saveProgram,
  programList,
  filterProgramData,
  resetSave,
  resetDelete,
  loginUser,
  logOut,
  getAlert,
}) => {
  const navigate = useNavigate();
  const [snakMsg, setSnakMsg] = useState("");
  const [openSnak, setOpenSnak] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [status, setStatus] = useState(false);
  const [del, setDel] = useState(false);
  const [save, setSave] = useState(false);
  const [editRow, setEditRow] = useState([]);
  const [viewRow, setViewRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [filteropen, setFilterOpen] = useState(false);
  const [filteredList, setfilterList] = React.useState([
    "Sr. No.",
    "Program",
    "University Name",
    "Program Duration (In years)",
    "Program Type Name",
    "Status",
  ]);
  const [progList, setProgList] = React.useState([
    [{ title: "Sr. No.", name: "index" }],
    [{ title: "Program", name: "progName" }],
    [{ title: "University Name", name: "uniName" }],
    [{ title: "Program Type Name", name: "programType" }],
    [{ title: "Program Duration (In years)", name: "progDuration" }],
    [{ title: "Status", name: "isActive" }],
  ]);
  const [searchValue, setSearchValue] = useState("");
  const [programTypeList, setProgramTypeList] = React.useState([
    {
      title: "Sr. No.",
      name: "index",
      positionCenter: true,
      alignCenter: "center",

      showInscreen: true,
    },
    {
      title: "Program",
      name: "progName",
      positionCenter: false,
      showInscreen: true,
      width: 50,
    },
    {
      title: "University Name",
      name: "uniName",
      positionCenter: false,
      showInscreen: true,
      width: 200,
    },
    {
      title: "Program Duration (In years)",
      name: "progDuration",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
      width: 50,
    },

    {
      title: "Program Type Name",
      name: "programType",
      positionCenter: false,
      showInscreen: true,
      width: 250,
    },
    {
      title: "Status",
      name: "isActive",
      positionCenter: true,
      alignCenter: "center",
      width: 50,
      showInscreen: true,
    },
    {
      title: "Action",
      name: "action",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
      width: 50,
    },
  ]);

  const [breadCrumName, setBreadCrum] = useState(
    "   >   Master   >  Program Type Master"
  );
  const [width, setWidth] = React.useState(window.innerWidth);
  const closeFilter = () => {
    setProgramTypeList([
      {
        title: "Sr. No.",
        name: "index",
        positionCenter: true,
        alignCenter: "center",

        showInscreen: true,
      },
      {
        title: "Program",
        name: "progName",
        positionCenter: false,
        showInscreen: true,
      },
      {
        title: "University Name",
        name: "uniName",
        positionCenter: false,
        showInscreen: true,
      },
      {
        title: "Program Duration (In years)",
        name: "progDuration",
        positionCenter: false,
        showInscreen: true,
      },

      {
        title: "Program Type Name",
        name: "programType",
        positionCenter: false,
        showInscreen: true,
      },
      {
        title: "Status",
        name: "isActive",
        positionCenter: true,
        alignCenter: "center",

        showInscreen: true,
      },
      {
        title: "Action",
        name: "action",
        positionCenter: false,
        showInscreen: true,
        alignCenter: "right",
      },
    ]);
    setfilterList([
      "Sr. No.",
      "Program",
      "University Name",
      "Program Duration (In years)",
      "First Year Program Title",
      "Program Type Name",
      "Status",
    ]);
    setFilterOpen(false);
  };
  const getRowID = async (id) => {
    setViewRow([]);
    const editedRow = programList?.program.filter((row) => row.id === id);
    if (editedRow.length > 0) {
      setEdit(true);
      setEditRow([]);
      return await setEditRow(editedRow);
    }

    return setEditRow([]);
  };
  useEffect(() => {
    getProgram().then((respo) => {
      if (!respo) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  useEffect(() => {
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
  });

  const getRowData = async (id) => {
    setOpen(true);
    const viewRow = programList?.program.filter((row) => row.id === id);
    if (viewRow.length > 0) {
      return await setViewRow(viewRow);
    }
    return setViewRow([]);
  };

  const programTypeFilteredData = programList?.program.filter(
    (item) =>
      item.programType.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.uniName.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.progName.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.progDuration === parseInt(searchValue) ||
      item.isActive.toLowerCase() === searchValue
  );

  const filteredRows = (searchvalue) => {
    setSearchValue(searchvalue);
    const filteredRow = programList?.program.filter((row) => {
      let name = row?.programType;
      let uniName = row?.uniName;
      let progName = row?.progName;
      let progDuration = row?.progDuration;
      let isActive = row?.isActive;
      return (
        name.toLowerCase().includes(searchvalue.toLowerCase()) ||
        uniName.toLowerCase().includes(searchvalue.toLowerCase()) ||
        progName.toLowerCase().includes(searchvalue.toLowerCase()) ||
        progDuration === parseInt(searchvalue) ||
        isActive.toLowerCase() === searchvalue
      );
    });

    if (programList?.program.length === filteredRows.length) {
      filterProgramData({ row: filteredRow, flag: false });
    } else {
      filterProgramData({ row: filteredRow, flag: true });
    }
  };
  const data = (instData) => {
    resetSave();
    setSave(true);
    saveProgram({ programData: instData }).then((respo) => {
      if (!respo) {
        getAlert({ message: myConstClass.serverMsg });
      } else {
        setOpenSnak(true);
        if (instData.id != null) {
          setSnakMsg(myConstClass.updateMsg);
        } else {
          setSnakMsg(myConstClass.saveMsg);
        }
        setSave(false);
        getProgram().then((respo) => {
          if (!respo) {
            getAlert({ message: myConstClass.serverMsg });
          }
        });
      }
    });

    setEdit(false);
    setEditRow([]);
  };

  const saveAndNextData = (data) => {
    setSave(true);
    saveProgram({ programData: data }).then(() => {
      setOpenSnak(true);
      if (data.id != null) {
        setSnakMsg(myConstClass.updateMsg);
      } else {
        setSnakMsg(myConstClass.saveMsg);
      }
      setSave(false);
      getProgram().then((respo) => {
        if (!respo) {
          getAlert({ message: myConstClass.serverMsg });
        }
      });
    });
    setEdit(true);
    setEditRow([]);
  };

  const startEdit = () => {
    setEdit(true);
  };
  const breadCrum = (prevBreadCrum) => {
    setBreadCrum(breadCrumName + prevBreadCrum);
  };
  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setDel(true);
        deleteProgramById({ programId: id }).then((respo) => {
          if (!respo) {
            getAlert({ message: myConstClass.serverMsg });
          } else {
            setOpenSnak(true);
            setSnakMsg(myConstClass.deleteMsg);
            setDel(false);
            getProgram().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
          }
        });
      }
    });
  };
  const stopEdit = () => {
    setBreadCrum("   >   Master   >   Program Type Master  ");
    setEditRow([]);
    setEdit(false);
  };

  const rowStatus = async (id) => {
    swal(myConstClass.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        const viewRow = programList?.program.filter((row) => row.id === id);

        let trustId = localStorage.getItem("trustId");
        let programData = {
          university: {
            id: viewRow[0].uniId,
          },
          program: {
            id: viewRow[0].progTypeId,
          },
          id: viewRow[0].id,
          name: viewRow[0].programType,
          duration: viewRow[0].progDuration,
          isActive: viewRow[0].isActive === "Active" ? "0" : "1",
          trust: {
            id: trustId,
          },
          delStatus: 1,
        };
        resetSave();
        setStatus(true);
        saveProgram({ programData: programData }).then(() => {
          setStatus(false);
          setOpenSnak(true);
          setSnakMsg(myConstClass.activeStatus);
          getProgram().then((respo) => {
            if (!respo) {
              getAlert({ message: myConstClass.serverMsg });
            }
          });
        });
      }
    });
  };

  const handleChange = (event) => {
    setFilterOpen(true);
    const {
      target: { value },
    } = event;
    if (value.length > filteredList.length) {
      let row = [];
      const a = programTypeList.map((qualiData) => {
        let trust = value.filter((namesRow) => namesRow === qualiData.title);

        if (trust.length !== 0) {
          let qData = {
            ...qualiData,
            showInscreen: true,
          };
          row.push(qData);
        } else {
          let qData = {
            ...qualiData,
            showInscreen: false,
          };
          row.push(qualiData);
        }

        return setProgramTypeList(row);
      });
      console.log(row);
    } else {
      let row = [];
      let difference = filteredList.filter((x) => !value.includes(x));
      const qualificationList = programTypeList.filter((data) => {
        if (data.title === difference[0]) {
          let QualiData = {
            ...data,
            showInscreen: false,
          };
          row.push(QualiData);
        } else {
          row.push(data);
        }
      });
      console.log("row");
      console.log(row);
      setProgramTypeList(row);
    }

    setfilterList(typeof value === "string" ? value.split(",") : value);

    if (value.length === 0) {
      setFilterOpen(false);
    }
  };
  return (
    <>
      <div>
        <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
          <TitleAndBreadCrum
            title="Program Type"
            breadCrumName={breadCrumName}
          />
        </Paper>
        <br />
        {/* {programList.isDelete && programList.error == true && (
          <Alert msg={myConstClass.deleteFailMsg} closeAlert={resetDelete} />
        )}
        {programList.isServerFail && (
          <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />
        )} */}

        {/* < ErrorHand error={"error"} msg={'This is an error alert — check it out!'} flag={true}/>  */}

        {programList.isDelete && programList.error == false ? (
          <DirectionSnackbar
            open={openSnak}
            severity="success"
            msg={myConstClass.deleteMsg}
            closeSnak={setOpenSnak}
          />
        ) : programList.isSave ? (
          <DirectionSnackbar
            open={openSnak}
            severity="success"
            msg={snakMsg}
            closeSnak={setOpenSnak}
          />
        ) : (
          ""
        )}
        <Paper sx={{ p: 2, pt: 4, borderRadius: 2 }} elevation={0}>
          <div>
            {viewRow.length >= 0 && editRow.length >= 0 && isEdit && (
              <ProgramMForm
                OnSave={data}
                isEdit={editRow}
                onCancel={stopEdit}
                breadCrum={breadCrum}
                saveAndNext={saveAndNextData}
              />
            )}
          </div>
          {open && (
            <ProgMView data={viewRow} openparam={open} closeView={setOpen} />
          )}
          <Grid>
            {!isEdit && (
              <div>
                {width > breakpoint ? (
                  <>
                    <Box
                      sx={{ mr: 2 }}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Box sx={{ m: -1, width: 300 }}>
                        <Search
                          filteredRows={filteredRows}
                          searchValue={searchValue}
                        />{" "}
                      </Box>
                      <Box>
                        <FormControl sx={{ m: -1, width: 250 }}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={filteredList}
                            name="sdf"
                            size="small"
                            onChange={handleChange}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {names.map((name) => {
                              return (
                                <MenuItem key={name.name} value={name.title}>
                                  <Checkbox
                                    checked={
                                      filteredList.indexOf(name.title) > -1
                                    }
                                  />
                                  <ListItemText primary={name.title} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>

                        {filteropen && (
                          <>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <ProgExcelData
                              csvData={programTypeFilteredData}
                              programList={programTypeList}
                              fileName="Program Type"
                              type="pdf"
                            />
                          </>
                        )}
                        {filteropen && (
                          <>
                            &nbsp;&nbsp;
                            <ProgExcelData
                              csvData={programTypeFilteredData}
                              programList={programTypeList}
                              fileName="Program Type"
                              type="excel"
                            />
                          </>
                        )}

                        {filteropen && (
                          <>
                            &nbsp;&nbsp;
                            <FontAwesomeIconCompo
                              className="fa-times-circle"
                              color="primary"
                              title="Cancel"
                              baseClassName="fas"
                              onClick={closeFilter}
                            />
                          </>
                        )}
                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <ProgExcelData
                              csvData={programTypeFilteredData}
                              programList={programTypeList}
                              fileName="Program Type"
                              type="pdf"
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;
                            <ProgExcelData
                              csvData={programTypeFilteredData}
                              programList={programTypeList}
                              fileName="Program Type"
                              type="excel"
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;&nbsp;
                            <MyComponentWithIconProps
                              statusImage={AddCircleIcon}
                              color="primary"
                              title=""
                              fontSize="large"
                              sx={{ m: -1 }}
                              onClick={startEdit}
                            />
                          </>
                        )}
                      </Box>
                    </Box>

                    <Box sx={{ mt: 2, p: 1.5 }}>
                      {/* <ProgramMtable
                    filteredColumns={programTypeList}
                    data={programTypeFilteredData}
                    rowEdit={getRowID}
                    rowView={getRowData}
                    rowDelete={deleteData}
                    rowStatus={rowStatus}
                  />   */}
                      <DynamicTable
                        data={programTypeFilteredData}
                        tableHead={programTypeList}
                        rowEdit={getRowID}
                        rowDelete={deleteData}
                        rowStatus={rowStatus}
                        showHeadDelete={true}
                        showHeadEdit={true}
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      rowSpacing={2}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid xs={12} sm={12} md={12} lg={12} rowSpacing={2}>
                        <Search
                          filteredRows={filteredRows}
                          searchValue={searchValue}
                        />{" "}
                      </Grid>
                      <Grid xs={12} sm={12} md={12} lg={12} sx={{ m: 1 }}>
                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={filteredList}
                            name="sdf"
                            size="small"
                            onChange={handleChange}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {names.map((name) => {
                              return (
                                <MenuItem key={name.name} value={name.title}>
                                  <Checkbox
                                    checked={
                                      filteredList.indexOf(name.title) > -1
                                    }
                                  />
                                  <ListItemText primary={name.title} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <Grid
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          sx={{ m: 1, textAlign: "right" }}
                        >
                          {filteropen && (
                            <>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <ProgExcelData
                                csvData={programTypeFilteredData}
                                programList={programTypeList}
                                fileName="Program Type"
                                type="pdf"
                              />
                            </>
                          )}
                          {filteropen && (
                            <>
                              &nbsp;&nbsp;
                              <ProgExcelData
                                csvData={programTypeFilteredData}
                                programList={programTypeList}
                                fileName="Program Type"
                                type="excel"
                              />
                            </>
                          )}

                          {filteropen && (
                            <>
                              &nbsp;&nbsp;
                              <FontAwesomeIconCompo
                                className="fa-times-circle"
                                color="primary"
                                title="Cancel"
                                baseClassName="fas"
                                onClick={closeFilter}
                              />
                            </>
                          )}
                          {!filteropen && filteredList.length >= 0 && (
                            <>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <ProgExcelData
                                csvData={programTypeFilteredData}
                                programList={programTypeList}
                                fileName="Program Type"
                                type="pdf"
                              />
                            </>
                          )}

                          {!filteropen && filteredList.length >= 0 && (
                            <>
                              &nbsp;&nbsp;
                              <ProgExcelData
                                csvData={programTypeFilteredData}
                                programList={programTypeList}
                                fileName="Program Type"
                                type="excel"
                              />
                            </>
                          )}

                          {!filteropen && filteredList.length >= 0 && (
                            <>
                              &nbsp;&nbsp;&nbsp;
                              <MyComponentWithIconProps
                                statusImage={AddCircleIcon}
                                color="primary"
                                title=""
                                fontSize="large"
                                sx={{ m: -1 }}
                                onClick={startEdit}
                              />
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Box sx={{ mt: 2, p: 1.5 }}>
                      {/* <ProgramMtable
                    filteredColumns={programTypeList}
                    data={programTypeFilteredData}
                    rowEdit={getRowID}
                    rowView={getRowData}
                    rowDelete={deleteData}
                    rowStatus={rowStatus}
                  />   */}

                      <DynamicTable
                        data={programTypeFilteredData}
                        tableHead={programTypeList}
                        rowEdit={getRowID}
                        rowDelete={deleteData}
                        rowStatus={rowStatus}
                        showHeadDelete={true}
                        showHeadEdit={true}
                      />
                    </Box>
                  </>
                )}
              </div>
            )}
          </Grid>
          {!programList.isServerFail &&
            (!programList?.isProgFetch ? (
              <Loading1 size={50} />
            ) : status && !programList?.isSave ? (
              <Loading1 size={50} />
            ) : save && !programList?.isSave ? (
              <Loading1 size={50} />
            ) : del ? (
              <Loading1 size={50} />
            ) : (
              ""
            ))}
        </Paper>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  programList: state.program,
  common: state.common,
  loginUser: state.user,
});

const mapDispatchToProps = {
  getProgram,
  getProgramById,
  deleteProgramById,
  saveProgram,
  filterProgramData,
  getAlert,
  resetSave,
  resetDelete,
  logOut,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProgramMaster);
