import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Grid } from "@mui/material";
import React, { Component } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ButtonCompo } from "../../components/Comman/Buttton";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  ...(isDragging && {
    background: "rgb(235,235,235)",
  }),
});

export default class DndTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.data,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items,
    });
  }

  SubmitHandler = (e) => {
    e.preventDefault();
    this.props.resetData(this.state.items);
    this.props.priorityProcessPreference();
  };
  render() {
    return (
      <>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Sr. No.</TableCell>
                <TableCell>Course</TableCell>
                <TableCell>Institute</TableCell>
              </TableRow>
            </TableHead>
            <TableBody component={DroppableComponent(this.onDragEnd)}>
              {this.state.items.map((item, index) => (
                <TableRow
                  component={DraggableComponent(item.id, index)}
                  key={item.id}
                >
                  <TableCell scope="row">{index + 1}</TableCell>
                  <TableCell>{item.courseName}</TableCell>
                  <TableCell>{item.institute}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={2}
          container
          justifyContent="right"
        >
          <Grid
            item
            sm={12}
            xs={12}
            md={1}
            lg={1}
            container
            justifyContent="space-evenly"
          >
            <ButtonCompo
              size="medium"
              type="button"
              variant="outlined"
              name="Back"
              fullWidth={true}
              onClick={this.props.onCancel}
            />
          </Grid>
          &nbsp;&nbsp;
          <Grid
            item
            sm={12}
            xs={12}
            md={2.5}
            lg={2.5}
            container
            justifyContent="space-evenly"
          >
            <ButtonCompo
              size="medium"
              type="button"
              variant="contained"
              name="Save and Proceed"
              fullWidth={true}
              onClick={this.SubmitHandler}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

const DraggableComponent = (id, index) => (props) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <TableRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
          {...props}
        >
          {props.children}
        </TableRow>
      )}
    </Draggable>
  );
};

const DroppableComponent = (onDragEnd) => (props) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={"1"} direction="vertical">
        {(provided) => {
          return (
            <TableBody
              ref={provided.innerRef}
              {...provided.droppableProps}
              {...props}
            >
              {props.children}
              {provided.placeholder}
            </TableBody>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};
