

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import * as React from "react";
import { ChipCompo, FontAwesomeIconCompo } from "../../components/Comman/IconButton";

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Dessert (100g serving)",
  },
  {
    id: "calories",
    numeric: true,
    disablePadding: false,
    label: "Calories",
  },
  {
    id: "fat",
    numeric: true,
    disablePadding: false,
    label: "Fat (g)",
  },
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    label: "Carbs (g)",
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "Protein (g)",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    tableHead,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {tableHead.map((headCell) => {
          if (headCell.showInscreen) {
            return (
              <TableCell
                key={headCell.name}
                align={headCell.alignCenter}
                // padding={headCell.disablePadding ? 'none' : 'normal'}
             
              >
             
                  {headCell.title}
                  {orderBy === headCell.name ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
          
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
}
function Row({
  row,
  tableHead,
  rowStatus,
  showPdfDetails,
  pdfDetailsId,
  isActionColActive,
}) {
  const [open, setOpen] = React.useState(false);

  const changeStatus = (e, row) => {
    e.stopPropagation();
    rowStatus(row.id);
  };


    const pdfDetails=(e,id) => {
      e.stopPropagation();
      pdfDetailsId(id);
  }

  React.useEffect(() => {});
  return (
    <TableRow
      key={row.name}
      
    >
      {Object.keys(row).map(function (key, index) {
        const filteredList = tableHead.filter(
          (row) => row.name === key && row.showInscreen
        );
        if (filteredList.length !== 0) {
          if (filteredList[0].positionCenter && key !== "isActive")
            return (
              <TableCell style={{ width: "10%" }}>
                <center>{row[key]}</center>
              </TableCell>
            );
          else if (key === "isActive") {
            return (
              <TableCell style={{ width: "15%" }}>
                {row[key] === "Active" ? (
                  <center>
                    <ChipCompo
                      label="Active"
                      variant="outlined"
                      size="small"
                      color="success"
                      onClick={(e) => changeStatus(e, row)}
                    />
                  </center>
                ) : (
                  <center>
                    <ChipCompo
                      label="Inactive"
                      variant="outlined"
                      size="small"
                      color="error"
                      onClick={(e) => changeStatus(e, row)}
                    />
                  </center>
                )}
              </TableCell>
            );
          } else
            return (
              <TableCell align={filteredList[0].alignCenter}>
                {" "}
                {row[key]}
              </TableCell>
            );
        }
      })}
      {isActionColActive && (
        <>
          <TableCell align={"right"} style={{ width: "12%" }}>
            &nbsp;&nbsp;
            {showPdfDetails && (
              <FontAwesomeIconCompo
                className="fa-file-pdf"
                color="error"
                title="PDF"
                baseClassName="fas"
                onClick={(e) => pdfDetails(e, row.id)}
              />
            )}
          </TableCell>
        </>
      )}
    </TableRow>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  tableHead,
  rowStatus,
 
  data,
  showPdfDetails,
  pdfDetailsId,
  isActionColActive = true,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
        >
          <EnhancedTableHead
            tableHead={tableHead}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
          />
          <TableBody>
            {stableSort(data, getComparator(order, orderBy))
              .map((row, index) => {
                return (
                  <Row
                    key={row.id}
                    row={row}
                    tableHead={tableHead}
                    rowStatus={rowStatus}
                    showPdfDetails={showPdfDetails}
                    pdfDetailsId={pdfDetailsId}
                    isActionColActive={isActionColActive}
                  />
                 
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
 
    </Box>
  );
}
