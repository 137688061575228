import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import {
  default as endpoint,
  default as endpoints,
} from "../../config/endpoints";
const initialState = {
  studentRegister: [],
  studentRegisterData: [],
  isFetch: false,
  msg: "",
  isMailUnique: false,
  GetOTPmsg: "",
  isGetOTP: false,
  error: "",
  isValOtp: false,
  getValidateOtpmsg: "",
  isValidateOtp: false,
  isServerFail: false,
  isSaveFailed: false,
};
let URL = endpoints.studentRegister;
let generateOtp = endpoints.generateOTP;
let emailUrl = endpoints.emailEmail;
let validateOtp = endpoint.validateOtp;
const studentRegisterSlice = createSlice({
  name: "studentRegister",
  initialState,
  reducers: {
    studentRegisterSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        studentRegister: row,
        isFetch: true,
      };
    },
    studentMailSuccess: (state = cloneDeep(initialState), action) => {
      const { message } = action.payload;

      return {
        ...state,
        msg: message,
        isMailUnique: true,
        error: "",
        isValOtp: false,
      };
    },
    studentMailFailure: (state = cloneDeep(initialState), action) => {
      const { message } = action.payload;
      return {
        ...state,
        msg: message,
        isMailUnique: false,
      };
    },
    personValidateOTPFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error: "Otp Invalid",
        isValOtp: true,
      };
    },
    studentGenrateOTPSuccess: (state = cloneDeep(initialState), action) => {
      const { message } = action.payload;
      return {
        ...state,
        GetOTPmsg: message,
        isGetOTP: true,
        error: "",
        isValOtp: false,
      };
    },
    studentRegisterFailure: (state = cloneDeep(initialState), action) => {
      console.log("hi");
      return {
        ...state,
        studentRegister: [],
        isFetch: false,
      };
    },
    personValidateOTPSuccess: (state = cloneDeep(initialState), action) => {
      const { message } = action.payload;
      return {
        ...state,
        getValidateOtpmsg: message,
        isValidateOtp: true,
        error: "",
        isValOtp: false,
      };
    },
    personRegistrationSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        studentRegister: row,
        isFetch: true,
      };
    },
    studentRegisterFailure: (state = cloneDeep(initialState), action) => {
      console.log("hi");
      return {
        ...state,
        studentRegister: [],
        isFetch: false,
      };
    },
    resetErrorSucces: (state = cloneDeep(initialState), action) => {
      console.log("hi");
      return {
        ...state,
        studentRegisterData: [],
        isFetch: false,
        msg: "",
        isMailUnique: false,
        GetOTPmsg: "",
        isGetOTP: false,
        error: "",
        isValOtp: false,
        getValidateOtpmsg: "",
        isValidateOtp: false,
      };
    },
    resetFlagSucces: (state = cloneDeep(initialState), action) => {
      console.log("hi");
      return {
        ...state,
        // msg: "",
        // isMailUnique: false,
        GetOTPmsg: "",
        isGetOTP: false,
      };
    },

    serverFailed: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: true,
      };
    },
    resetDeleteSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: false,
      };
    },
    saveFailed: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isSaveFailed: true,
      };
    },
  },
});

export const {
  studentGenrateOTPSuccess,
  studentMailSuccess,
  personValidateOTPFailure,
  studentRegisterSuccess,
  personValidateOTPSuccess,
  studentRegisterFailure,
  studentMailFailure,
  resetErrorSucces,
  serverFailed,
  resetDeleteSuccess,
  resetFlagSucces,
  saveFailed,
} = studentRegisterSlice.actions;

export default studentRegisterSlice.reducer;

export const getStudentRegister = () => async (dispatch) => {
  console.log(URL);
  try {
    console.log("get getStudentRegister ");

    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    await axios
      .get(URL)
      .then((response) => {
        console.log(
          "Success program getStudentRegister========>",
          response.data.data
        );
        const { content } = response.data.data;
        console.log(content);
        let row = [];

        console.log(row);
        dispatch(studentRegisterSuccess({ row }));
      })
      .catch((error) => {
        console.log("Error getStudentRegister ========>", error);
      });
  } catch (e) {
    studentRegisterFailure();
    return console.error(e.message);
  }
};

export const saveStudentRegister =
  ({ studentRegisterData }) =>
  async (dispatch) => {
    try {
      let data = JSON.stringify(studentRegisterData);
      console.log(studentRegisterData);
      console.log(data);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      };
      const response = await axios
        .post(URL + "?baseUrl=" + window.location.host, data, { headers })
        .then((response) => {
          console.log("Success With saveReservCat ========>", response.data);
          const { data } = response.data;
          return true;
        })
        .catch((error) => {
          console.log("Error saveReser vCat ========>", error.response.data);
          dispatch(saveFailed());

          return false;
        });
      return response;
    } catch (e) {
      dispatch(serverFailed());

      return console.error(e.message);
    }
  };

export const checkEmailById =
  ({ emailId }) =>
  async (dispatch) => {
    try {
      console.log("emailId" + emailId);
      const success = await axios
        .get(
          emailUrl +
            "check-unique-email-id?emailId=" +
            emailId +
            "&baseUrl=" +
            window.location.host
        )
        .then((response) => {
          console.log("Success With id checkEmailById ========>", response);
          if (response.data.data.error !== true) {
            const { message } = response.data.data;
            dispatch(studentMailSuccess({ message }));
          } else {
            const { message } = response.data.data;

            dispatch(studentMailFailure({ message }));
          }

          return response;
        })
        .catch((error) => {
          console.log("Error checkEmailById ========>", error.response.data);
          dispatch(serverFailed());
        });
      return success;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getGenerateOTP =
  ({ emailId, contactNo }) =>
  async (dispatch) => {
    try {
      console.log("emailId" + emailId);

      const response = await axios
        .get(
          generateOtp +
            emailId +
            "&mobileNo=" +
            contactNo +
            "&baseUrl=" +
            window.location.host
        )
        .then((response) => {
          console.log("Success With OTP Send ========>", response.data);
          const { message } = response.data.data;
          dispatch(studentGenrateOTPSuccess({ message }));
          return true;
        })
        .catch((error) => {
          console.log("Error With OTP Send ========>", error.response.data);
          return false;
        });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getValidateOtp =
  ({ otp }) =>
  async (dispatch) => {
    try {
      console.log("otp" + otp);

      const response = await axios
        .get(validateOtp + otp)
        .then((response) => {
          console.log("Success With id validate OTP ========>", response);
          const { message } = response.data.data;
          dispatch(personValidateOTPSuccess({ message }));
          return true;
        })
        .catch((error) => {
          console.log("Error With Validate OTP ========>", error.response.data);
          dispatch(personValidateOTPFailure());
          return false;
        });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const resetError = () => async (dispatch) => {
  try {
    dispatch(resetErrorSucces());
  } catch (e) {
    return console.error(e.message);
  }
};

export const resetFlag = () => async (dispatch) => {
  try {
    dispatch(resetFlagSucces());
  } catch (e) {
    return console.error(e.message);
  }
};

export const resetDelete = () => async (dispatch) => {
  try {
    dispatch(resetDeleteSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
