import {
  Card,
  CardContent,
  Divider,
  Grid,
  Paper,
  Typography
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import PriorityProcessPreferenceForm from "./PriorityProcessPreferenceForm";

const PriorityProcessPreferenceMaster = ({
  data,
  toChoiceCode,
  toPriorityProcess,
  resetData,
  priorityProcessPreference,
  onCancel,
}) => {
  useEffect(() => {});

  return (
    <>
      <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
        <Grid
          container
          justifyContent="space-evenly"
          direction="row"
          spacing={2}
        >

        
          <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              spacing={2}
              columnSpacing={1}
              container
              sx={{ m: 0.5 }}
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                {/* <Grid item xs={12} sm={12} md={0.5}></Grid> */}
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 20 }}
                    label="Preference List"
                  />
                </Grid>
              </Grid>
            </Grid>
          
          <Grid item sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={1}
              columnSpacing={{xs:12, sm:6, md:1}}
               container justifyContent="space-evenly">
          <Grid item sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={1}
              // columnSpacing={{xs:12, sm:6, md:1}}
               container justifyContent="space-evenly">
            
          </Grid>
        </Grid>
        <Grid item sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{xs:12, sm:6, md:1}}
               container justifyContent="right">
          <Grid item sm={12}
              xs={12}
              md={2.5}
              lg={2.5}
              container
             justifyContent="space-evenly">
            <ButtonCompo
              size="medium"
              type="button"
              variant="contained"
              fullWidth={true}
              name="Select Course"
              onClick={toChoiceCode}
            />{" "}
            </Grid>
            <Grid item sm={12}
              xs={12}
              md={2.5}
              lg={2.5}
              container
             justifyContent="space-evenly">
            <ButtonCompo
              size="medium"
              type="button"
              variant="contained"
              fullWidth={true}
              name="Add Choice Code"
              onClick={toPriorityProcess}
            />
            </Grid>
        </Grid>
</Grid>
        <Box sx={{ width: "100%" }}>
          <br />
          <PriorityProcessPreferenceForm data={data} priorityProcessPreference={priorityProcessPreference}onCancel={onCancel}resetData={resetData} />
          <br />
        </Box>

       
      </Paper>
    </>
  );
};
export default PriorityProcessPreferenceMaster;
