export const schemeHeadNameMsg = "*Scheme head name required";
export const schemeNameMsg = "*Scheme name required";
export const shortNameMsg = "*Short name required";
export const courseCodeMsg = "*Course code required";
export const firstNameMsg = "*First name required";
export const middleNameMsg = "*Middle name required";
export const lastNameMsg = "*Last name required";
export const genderEmptyMsg = "*Gender required";
export const qualiMsg = "Please select atleast one qualification";
export const instCourseMsg = "Please select atleast one institute and course";
export const instituteErr = "Please select institute";
export const DocMsg = "Please select atleast one document";
export const dobEmptyMsg = "*Date of birth required";
export const contactNoMsg = "*Contact number required";
export const contactNumberMsg = "*Contact number must be number";
export const SelectInstType = "*Please select atleast one type";
export const emailIdMsg = "*Email id required";
export const enterPasswordMsg = "Password required";
export const confirmPasswordMsg = "Confirm Password required";
export const serverMsg = "Something went wrong";
export const confirmPasswordIncorrectMsg = "*Confirm Password does not match";
export const captchaMsg = "*Captcha required";
export const otpEmptyMsg = "OTP required";
export const contactNoLengthMsg = "*Contact number should have 10 digits";
export const namePasswordMsg = "*Password should not contain your name";
export const specialPasswordMsg =
  "*Password should be more than 8 character and contain 1 Uppercase, 1 Lowercase, 1 Special Character and 1 Numeric Digit";
export const invalidEmailIdMsg = "*Email id is invalid";
export const userIdMsg = "*Username required";
export const activeStatus = "*Status Updated";
export const deleteMsg = "Data Deleted Successfully";
export const deleteFailMsg = "Failed to Delete";
export const saveFailMsg = "Failed to Save";
export const saveMsg = " Data Save Successfully";
export const updateMsg = " Data Updated Successfully";
export const emailValMsg = " *Email Validate Successfully";
export const emailInvalMsg = " *Email Id Is Already Registered.. ";
export const otpGenerateMsg = " OTP Generate Successfully";
export const otpNotGenerateMsg = " *Fail To Generate OTP";
export const otpValidMsg = " OTP Validate Successfully";
export const otpInvalidMsg = "OTP Invalid";
export const changeActive = "*Do you want to change Status?";
export const instNameMsg = "*Institute name required";
export const instShortNameUniMsg = "*Institute short name must Be Unique";
export const instNameUniMsg = "*Institute name must Be Unique";
export const instShortNameMsg = "*Institute short name required";
export const instAffiMsg = "*Institute affiliation required";
export const EPFNoMsg = "*EPF number required";
export const taxNoMsg = "*TAX number required";
export const tanNoMsg = "*TAN number required";
export const PANNoMsg = "*PAN number required";
export const uniCodeMsg = "*university code required";
export const AICTE = "AICTE code required";
export const DTE = "*DTE code required";
export const instAddr = "*Institute address required";
export const trustNameMsg = "*Trust name required";
export const remarkMsg = "*Remark required";
export const mandatoryOptionalMsg = "Please select mandatory or optional";
export const classDetailNameMsg = "*Class detail name required ";
export const patternNameMsg = "*Pattern name required";
export const semisterNameMsg = "*Semister name required";
export const subjectCodeNameMsg = "*Subject code name required";
export const subjectNameMsg = "*Subject name required";
export const subjectShortNameMsg = "*Subject short name required";
export const subjectTypeMsg = "*Subject type name required";
export const classNameMsg = "*Year name is required";
export const labelTextMsg = "*Label name required";
export const minorityReligionMsg = "*Minority Religion required";
export const progNameMsg = "*Program name required";
export const sortOrderNoMsg = "*Sort order no required";
export const sortOrderNumberMsg = "*Sort order no must be a Number";
export const feBrNameMsg = "*First year Program Title required";
export const shiftNameMsg = "*Shift name required";
export const branchShortNameMsg = "*Short name required";
export const branchNameMsg = "*Program Title name required";
export const docNameMsg = "*Document name required";
export const financeYearNameMsg = "*Finance year name required";
export const fromDateMsg = "*From date required";
export const toDateMsg = "*To date required";
export const dateMsg = "*Date required";
export const progDurationMsg = "*Program duration required";
export const uniNameMsg = "*University name required";
export const classUniMsg = "*Class name must be unique";
export const academicYearNameMsg = "*Academic year name required";
export const profTaxIdMsg = "*prof tax required";
export const panNoMsg = "*PAN number required";
export const trustAddMsg = "*Trust add required";
export const qualificationMsg = "*Qualification name required";
export const trustShortNameMsg = "*Trust short name required";
export const scholarshipTypeMsg = "*Scholarship type required";
export const clientNameMsg = "*Client name required";
export const receiptNameMsg = "*Receipt name required";
export const feesTypeNameMsg = "*Fees type name required";
export const ClassDetEmptyMsg = "*Class detail name required";
export const divEmptyMsg = "*Division name required";
export const divInvalidMsg = "*Valid division name required";
export const educationNameMsg = "*Education name required";
export const matrixNameMsg = "*Matrix name required";
export const classIntakeNameMsg = "*Class intake name required";
export const sortOrdNoMsg = "*Sort order number required";
export const ClassIntDetEmptyMsg = "*Class detail name required";
export const acYearEmptyMsg = "*Academic year required";
export const subjectNameEmptyMsg = "*Subject name required";
export const subjectHeadNameMsg = "*Subject head name required";
export const valueEmptyMsg = "*value";
export const valueMsg = "*value";
export const religionNameMsg = "*Religion name required";
export const catNameMsg = "*Category name required";
export const typeEmptyMsg = "*Category type required";
export const sanctionIntakeVaildMsg = "*Sanction intake should be equal to";
export const sanctionIntakeMsg = "*Sanction intake required";
export const sanctionIntakeNoMsg = "*Sanction intake must be a number";
export const lateralIntakeMsg = "*Lateral intake required";
export const lateralIntakeNoMsg = "*Lateral intake must be a number";
export const valueNumberMsg = "*Value must be a number";
export const openingAcYearMsg = "*Opening academic year required";
export const closingAcYearMsg = "*closing academic year required";
export const headingNameMsg = "*Heading name";
export const contentValidMsg = "*Please content";
export const logoMsg = "*Please select logo";
export const motherNameMsg = "*Mother's name required";
export const profilePicMsg = "*Profile pic required";
export const genderMsg = "*Gender required";
export const dobMsg = "*Date of birth required";
export const birthplaceMsg = "*Birth place required";
export const bloodGroupMsg = "*Blood group required";
export const religionMsg = "*Religion required ";
export const studMobileMsg = "*Student mobile number required";
export const studEmailMsg = "*Student email required";
export const nationalityMsg = "*Nationality required";
export const admCategoryMsg = "*admission category required";
export const studCategoryMsg = "*Student category required";
export const subCastMsg = "*Sub Cast required";
export const validityCdNoMsg = " *Validity certificate number required";
export const validityCdDateMsg = "*Validity certificate date required";
export const castCertNoMsg = "*Cast certificate number required";
export const castCertDateMsg = "*Cast certificate date required";
export const bankNameMsg = "*Bank name required";
export const bankBranchMsg = "*Bank branch required";
export const ifscCodeMsg = "*IFSC code required";
export const micrCodeMsg = "*MICR code required";
export const bankAccNoMsg = "*Bank account number required";
export const seatNoMsg = "*Seat number required";
export const eligibilityNoMsg = "*Eligibility number required";
export const prnNoMsg = "*Prn no required";
export const admissionTypeMsg = " *Admission type required";
export const caproundMsg = "*Capround required";
export const allotmentNoMsg = "*Allotment Number required";
export const meritNoMsg = "*Merit Number required";
export const prevInstituteMsg = " *Previous institute required";
export const prevInstAddressMsg = "*Previous institute address required";
export const prevInstAreaMsg = "*Previous institute area required";
export const prevInstMediumMsg = "*Previous institute medioum required";
export const passoutMsg = "*Passout year required";
export const classDetailNameValidMsg = "*Class detail name required";
export const classIntakeName = "*Class name required";
export const fatherOccupationMsg = "*Father's occupation required";
export const fatherNameMsg = "*Father's name required";
export const parAddressMsg = "*Annual income required";
export const annualIncomeMsg = "*Annual income required";
export const parentEmailMsg = "*Parent's Email Id required";
export const parentMobileMsg = "*Parent's Contact No required";
export const perAddressMsg = "*Permanent Address required";
export const vilageMsg = "*Village required";
export const talukaMsg = "*Taluka required";
export const districtMsg = "*District required";
export const stateMsg = "*State required";
export const pinMsg = "*Pincode required";
export const localAddMsg = "*Local Address required";
export const admissionStatusMsg = "*Admission status name required";
export const feesTypeMsg = "*Fees type name";
export const feesTitleNameMsg = "*Fees title name";
export const formFeesNameMsg = "*form fees name";
export const programTypeMsg = "*Program type name required";
export const feesNameMsg = "*fees name";
export const feesTitleMsg = "*fees title name";
export const receiptTypeMsg = "*Receipt type name required";
export const userNameMsg = "*User name required";
export const semEndDateMsg = "*Select sem end date";
export const semStartDate = "*Select sem start date";
export const divisionNameMsg = "*Select division";
export const markingSystemMsg = "*Marking system required";
export const maxSubMsg = "*Maximum subject required";
export const minSubInvalidMsg = "*Please valid minimum subject";
export const minSubMsg = "*Minimum subject required";
export const minMaxSubMsg =
  "*Minimum subject must be less than maximum subject";
export const showNameMsg = "*Show name required";
export const subjectTypeNameMsg = "*Subject type name required";
export const minSubNoMsg = "*Minimum subject must be a number";
export const maxSubNoMsg = "*Maximum subject must be a number";
export const onlyContactNoMsg = "*Contact Number must only be number";
export const cityMsg = "*City/Village required";
export const titleMsg = "*Title required";
export const titleErr = "*Title required";
export const shortSummMsg = "*Short Summary required";
export const detailsOfDescriptionMsg = "*Form Description required";
export const typeMsg = "*Type required";
export const labelNameMsg = "*Label name required";
export const feesMsg = "*Fees required";
export const feesValidMsg = "*Fees must be in number";
export const typeOfProgramMsg = "*Program type required";
export const programMsg = "*Program required";
export const displaytoMsg = "*Date Must be Greater Than From Date";
export const displayFromMsg = "*Display From Date required";
export const launchToMsg = "*Date Must be Greater Than From Date";
export const launchFromMsg = "*Launch From Date required";
export const instructionsMsg = "*Instructions are required";
export const docUniMsg = "*Document name must be unique";
export const disabilityNameMsg = "*Disability name required";
export const defenceNameMsg = "*Defence name required ";
export const labelMsg = "*Label required";
export const roundFromMsg = "*Round From Date must not be equal to To Date";
export const roundToMsg = "*Round To Date must not be equal to From Date";
export const seatAllotmentFromMsg =
  "*Seat Accept From Date must be less than To Date";
export const seatAcceptFromMsg =
  "*Seat Accept From Date must not be equal to To Date";
export const seatAcceptToMsg =
  "*Seat Accept To Date must not be equal to From Date";
export const progTypeUniqueMsg = "*Program Type must be unique";
export const catUniMsg = "*Category Must be Unique";
export const religionUniMsg = "*Religion name must Be Unique";
export const academicYearUniMsg = "*Acadamic year must Be Unique";
export const academicYearMsg = "*Acadamic year required";
export const branchShortNameUniMsg = "*Branch short name must Be Unique";
export const branchUniMsg = "*Branch name must Be Unique";
export const CourseMsgg = "*Program Title required";
export const generalPercentageMsg = "*General Percentage required";
export const otherPercentageMsg = "*Other Percentage required";
export const malePercentageMsg = "*Male Percentage required";
export const femalePercentageMsg = "*Female Percentage required";
export const marksobtMsg = "*Mark obtained required";
export const scheduleMsg = "*Schedule required";
export const descriptionMsg = "*Description required";
export const ordNoMsg = "*Order number required";
export const categoryPercentage =
  "*Sum of all percentage should be equal to 100";
export const casteMsg = "*Caste required";
export const subcasteMsg = "*Subcaste required";
export const candidateNameMsg = "*Candidate name required";
export const fatherHusbandNameMsg = "*Father/Husband name required";
export const domicileOfMsg = "*Domicile name required";
export const prefrenceMsg = "*Preference required";
export const mothertoungeMsg = "*Mother tounge required";
export const aadhaarMsg = "*Aadhar No required";
export const martialMsg = "*Martial Status required";
export const isStudentNriForeignNationalMsg = "*This field required";
export const InvalidadharMsg = "*Aadhar no Invalid";
export const instTypeMsg = "*Institute type required";
export const autonomousStatusMsg = "*Autonomous Status required";
export const minorityMsg = "*Minority Status required";
export const reservStuctureMsg = "*Reservation stucture required";
export const omsMsg = "*Other Than Maharashtra field required";
export const isScholarShipMsg = "*Scholarship field required";
export const invalidEmail = "*Invalid Email Id or Date of birth";
export const invalidUserName = "*Invalid User Name or Date of birth";
export const otpMobileSend =
  "OTP has been sent successfully on your registered Mobile number ";
export const otpEmailSend =
  "OTP has been sent successfully on your registered Email Id ";
