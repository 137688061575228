import ArticleIcon from "@mui/icons-material/Article";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import SchoolIcon from "@mui/icons-material/School";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Grid, Paper } from "@mui/material";
import Link from "@mui/material/Link";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { logOut } from "../Login/login.slice";
import {
  getCourseDataById,
  getDeclrationById,
} from "../AdminDirectAdmission/directAdmission.slice";
import { downloadFile } from "../UploadFile/file.slice";
import { getAlert } from "../../Alert/alert.slice";
import * as myConstClass from "../../config/messageconstant";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";

const programSelection = [
  {
    typeofprog: "Program1",
    program: "Subprogram1",
  },
];

const AdminDirectAdmissionView = ({
  appliData,
  backToForm,
  appliId,
  getDeclrationById,
  downloadFile,
  logOut,
  loginUser,
  getAlert,
  getCourseDataById,
}) => {
  const [value, setValue] = React.useState("1");
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
  });
  const editHandler = () => {
    getDeclrationById({ appliId: appliData?.applicationData.id }).then(
      (resp) => {
        if (resp) {
          backToForm();
        } else {
          getAlert({ message: myConstClass.serverMsg });
        }
      }
    );
  };

  useEffect(() => {
    const appliId = localStorage.getItem("appliId");

    console.log(appliId);
    if (appliId !== null) {
      getDeclrationById({ appliId: appliId }).then((resp) => {
        if (resp) {
          getCourseDataById({ ids: resp?.programTitleIds }).then((response) => {
            if (!response) {
              getAlert({ message: myConstClass.serverMsg });
            }
          });
        } else {
          getAlert({ message: myConstClass.serverMsg });
        }
      });
    }
  }, []);
  const changeHandler = () => {
    const roleAccess = localStorage.getItem("role");
    if (roleAccess === "applicant") {
      navigate("/applicationForm");
    } else {
      navigate("/list-of-application-delcare");
    }
  };
  console.log(appliData);
  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <Grid container justifyContent="right">
            <ButtonCompo
              size="medium"
              type="button"
              variant="outlined"
              name="Back to List"
              onClick={changeHandler}
            />
          </Grid>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  icon={<InfoIcon />}
                  textColor="secondary"
                  indicatorColor="secondary"
                  label="Basic Details"
                  value="1"
                />
                <Tab
                  icon={<HomeIcon />}
                  textColor="secondary"
                  indicatorColor="secondary"
                  label="Courses"
                  value="2"
                />
                <Tab
                  icon={<SchoolIcon />}
                  textColor="secondary"
                  indicatorColor="secondary"
                  label="Qualification Criteria"
                  value="3"
                />
                <Tab
                  icon={<ArticleIcon />}
                  textColor="secondary"
                  indicatorColor="secondary"
                  label="Required Documents"
                  value="4"
                />
                <Tab
                  icon={<AttachFileIcon />}
                  textColor="secondary"
                  indicatorColor="secondary"
                  label="Attachments"
                  value="5"
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Step1 data={appliData?.applicationData} />
            </TabPanel>
            <TabPanel value="2">
              <Step2
                data={appliData?.applicationData}
                typeprog={programSelection}
              />
            </TabPanel>
            <TabPanel value="3">
              <Step3 data={appliData?.applicationData} />
            </TabPanel>
            <TabPanel value="4">
              <Step4 data={appliData?.applicationData} />
            </TabPanel>
            <TabPanel value="5">
              <Step5 data={appliData?.applicationData} />
            </TabPanel>
          </TabContext>
        </Box>
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({
  appliData: state.directAdmission,
  loginUser: state.user,
});

const mapDispatchToProps = {
  getDeclrationById,
  getCourseDataById,
  downloadFile,
  logOut,
  getAlert,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDirectAdmissionView);
