import { Divider, Grid, MenuItem, Select, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import * as React from "react";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import * as myConstClass from "../../config/messageconstant";

let docs;

class GenerateMeritListForm extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    id: 0,
    academicYearName: "",
    display: "",
    formErrors: [],
  };

  changeHandler = (event) => {
    const { name, value } = event.target;

    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  componentDidMount() {
    this.setState({});

    const { isEdit } = this.props;
    if (isEdit.length > 0) {
      this.setState({
        id: isEdit[0].id,
        academicYearName: isEdit[0].academicYearName,
        display: isEdit[0].display,
      });
    }
  }

  handleFormValidation() {
    const { academicYearName } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (academicYearName === "") {
      formIsValid = false;
      formErrors["academicYearNameErr"] = myConstClass.academicYearNameMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const { academicYearName, display } = this.state;
    docs = {
      name: academicYearName,
      type: display,
    };
    if (this.handleFormValidation()) {
      this.props.OnSave(docs);
      this.setState({
        academicYearName: "",
        display: "",
      });
    }
  };
  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  render() {
    const { academicYearName, display } = this.state;
    const { academicYearNameErr } = this.state.formErrors;

    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
      
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              xs={12}
              sm={12}
              md={12}
            >
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                columnSpacing={3}
                xs={12}
                sm={5.5}
                md={5.5}
                sx={{ marginTop: 2 }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Name: "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    size="small"
                    color="primary"
                    name="academicYearName"
                    id="academicYearName"
                    value={academicYearName}
                    onChange={this.changeHandler}
                    fullWidth
                    {...(academicYearNameErr
                      ? { error: true, helperText: academicYearNameErr }
                      : "")}
                  ></TextField>
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                xs={12}
                sm={5.5}
                md={5.5}
                sx={{ marginTop: 2 }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Type:"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      className="text-black"
                      name="display"
                      id="display"
                      value={display}
                      onChange={this.changedHandler}
                      size="small"
                      fullWidth={true}
                      {...(academicYearNameErr && { error: true })}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="0">Provisional</MenuItem>
                      <MenuItem value="1">Final Merit List</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                xs={12}
                sm={0.3}
                md={0.3}
                sx={{ marginTop: 5 }}
              />
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                xs={12}
                sm={0.7}
                md={0.7}
                sx={{ marginTop: 5 }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name="Add"
                    onClick={this.searchData}
                  />
                </Grid>
              </Grid>
            </Grid>

           
        </form>
      </>
    );
  }
}
export default GenerateMeritListForm;
