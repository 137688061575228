import { Grid, Typography } from "@mui/material";
import * as React from "react";
import { ChipCompo } from "../../components/Comman/IconButton";
import "../../CSS/View.css";
const ViewPage = ({ data }) => {
  if (data.length > 0) {
    return (
      <>
        <Grid className="parent">
          <Grid className="LeftText">
            <Typography id="content1"> Programe Name: </Typography>

            <Typography id="content1"> Class Name: </Typography>

            <Typography id="content1"> Institute Name: </Typography>

            <Typography id="content1">Sort Order No: </Typography>
          </Grid>

          {data.map((data) => {
            return (
              <Grid className="RightText">
                <Typography id="content1">{data.progName}</Typography>
                <Typography id="content1">{data.className}</Typography>
                <Typography id="content1">{data.tenantName}</Typography>
                <Typography id="content1">{data.sortOrderNo}</Typography>
                <Grid className="statuslogo">
                  <Typography id="content1">
                    {data.isActive == "1" ? (
                      <ChipCompo
                        label="Active"
                        variant="outlined"
                        size="small"
                        color="success"
                      />
                    ) : (
                      <ChipCompo
                        label="Inactive"
                        variant="outlined"
                        size="small"
                        color="error"
                      />
                    )}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </>
    );
  } else {
    return <label>Error in view</label>;
  }
};
export default ViewPage;
