import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { decodeData } from "../../components/Comman/Util/Base64";
import endpoints from "../../config/endpoints";
import { tokenExp } from "../Login/login.slice";
const initialState = {
  file: "",
  fileData: [],
  isFetch: false,
  filePath: "",
  isDownload: false,
  fileDataView: [],
  isFetchFile: false,
};
let URL = endpoints.fileUpload;
let URLFile = endpoints.fileUploadPreLogin;
let DownloadURL = endpoints.fileDownload;
let DeleteURL = endpoints.fileDelete;
let userAppReportURL = endpoints.userAppReport;

const fileSlice = createSlice({
  name: "file",
  initialState,
  reducers: {
    fileSaveSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        file: data,
        isFetch: true,
      };
    },
    fileMulitipleSaveSuccess: (state = cloneDeep(initialState), action) => {
      const { fileData } = action.payload;
      return {
        ...state,
        fileData: [...state.fileData, fileData],
        isFetch: true,
      };
    },
    fileMulitipleSaveResetSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { fileData } = action.payload;
      return {
        ...state,
        fileData: fileData,
        isFetch: true,
      };
    },
    MultifileDownloadSuccess: (state = cloneDeep(initialState), action) => {
      const { fileData } = action.payload;
      return {
        ...state,
        fileDataView: [...state.fileDataView, fileData],
        isFetchFile: true,
      };
    },
    fileFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        fileData: [],
        fileDataView: [],
        file: "",
        isFetch: false,
      };
    },
    fileDownloadSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        filePath: data,
        isDownload: true,
      };
    },
    fileDownloadFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        filePath: "",
        isDownload: false,
      };
    },
    serverFailed: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: true,
        error: false,
        isDelete: false,
      };
    },
    resetServerFailedSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: false,
        
      };
    },
    
  },
});

export const {
  serverFailed,
  fileDownloadFailure,
  fileDownloadSuccess,
  fileSaveSuccess,
  MultifileDownloadSuccess,
  fileMulitipleSaveResetSuccess,
  fileMulitipleSaveSuccess,
  fileFailure,
  resetServerFailedSuccess
} = fileSlice.actions;

export default fileSlice.reducer;

export const saveFile =
  ({ file }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);

      const formData = new FormData();
      formData.append("file", file);
      const headers = {
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .post(URL, formData, { headers })
        .then((response) => {
          console.log("Success Save file  ========>", response);
          const { data } = response;
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            dispatch(fileSaveSuccess({ data }));
            return data;
          }
        })
        .catch((error) => {
          console.log("Error get Save File========>", error);
          dispatch(serverFailed());
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return console.error(e.message);
    }
  };

export const savePreLoginFile =
  ({ file }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);

      const formData = new FormData();
      formData.append("file", file);
      const headers = {
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .post(URLFile, formData, { headers })
        .then((response) => {
          console.log("Success Save file  ========>", response);
          const { data } = response;
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            dispatch(fileSaveSuccess({ data }));
            return data;
          }
        })
        .catch((error) => {
          console.log("Error get Save File========>", error);
          dispatch(serverFailed());
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return console.error(e.message);
    }
  };

export const saveMultipleFile =
  ({ file }) =>
  async (dispatch) => {
    try {
      console.log(file);
      const name = localStorage.getItem("token");
      const token = decodeData(name);

      const formData = new FormData();
      formData.append("file", file.fileName);
      const headers = {
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .post(URL, formData, { headers })
        .then((response) => {
          console.log("Success Save file  ========>", response);

          const { data } = response;
          let fileData = {
            fileName: data,
            name: file.name,
          };
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            dispatch(fileMulitipleSaveSuccess({ fileData }));
            return !data.error;
          }
        })
        .catch((error) => {
          console.log("Error get Save File========>", error);
          dispatch(serverFailed());
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return console.error(e.message);
    }
  };

export const saveStatusUpdate = () => async (dispatch) => {
  try {
    dispatch(fileFailure());
  } catch (e) {
    return console.error(e.message);
  }
};
export const resetFileData =
  ({ fileData }) =>
  async (dispatch) => {
    try {
      dispatch(fileMulitipleSaveResetSuccess({ fileData }));
    } catch (e) {
      return console.error(e.message);
    }
  };

export const downloadFile =
  ({ file }) =>
  async (dispatch) => {
    try {
      console.log("hi-1");
      const name = localStorage.getItem("token");
      const token = decodeData(name);

      const headers = {
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      console.log("url");
      console.log(DownloadURL + "/" + file);
      const doc = await axios
        .get(DownloadURL + "/" + file, { headers })
        .then((response) => {
          console.log("Success Save file  ========>", response);
          const { data } = response.data;
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            dispatch(fileDownloadSuccess({ data }));
          }
          // const url = window.URL.createObjectURL(
          //   new Blob([response.data]),
          // );
          // const link = document.createElement('a');
          // link.href = url;
          // link.setAttribute(
          //   'download',
          //   `FileName.png`,
          // );

          // // Append to html link element page
          // document.body.appendChild(link);

          // // Start download
          // link.click();

          // // Clean up and remove the link
          // link.parentNode.removeChild(link);
          // // const {data}=response
          // // const file = new Blob([response.data], {type:'image/png'})
          // // console.log(file)
          // // dispatch(fileSaveSuccess({ data }));
          return data;
        })
        .catch((error) => {
          console.log("file");
          console.log(file);
          console.log("token");
          console.log(token);
          console.log("Error get program========>", error);
          dispatch(serverFailed());
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return console.error(e.message);
    }
  };

export const openFileInNewTab =
  ({ file }) =>
  async (dispatch) => {
    try {
      console.log("hi-1");
      const name = localStorage.getItem("token");
      const token = decodeData(name);

      const headers = {
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      console.log("url");
      console.log(DownloadURL + "/" + file);
      const doc = await axios
        .get(DownloadURL + "/" + file, { headers })
        .then((response) => {
          console.log("Success Save file  ========>", response);
          const { data } = response.data;
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            window.open(data, "_blank");
          }

          return data;
        })
        .catch((error) => {
          console.log("Error get program========>", error);
          dispatch(serverFailed());
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return console.error(e.message);
    }
  };
export const deleteFile =
  ({ file }) =>
  async (dispatch) => {
    try {
      console.log(file);
      console.log("URL" + URL);
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      // console.log(token)

      const headers = {
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .get(DeleteURL + "/" + file, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            console.log("Success Save file  ========>", response);
          }
          // const {data}=response.data
          // dispatch(fileDownloadSuccess({ data }));
          // const url = window.URL.createObjectURL(
          //   new Blob([response.data]),
          // );
          // const link = document.createElement('a');
          // link.href = url;
          // link.setAttribute(
          //   'download',
          //   `FileName.png`,
          // );

          // // Append to html link element page
          // document.body.appendChild(link);

          // // Start download
          // link.click();

          // // Clean up and remove the link
          // link.parentNode.removeChild(link);
          // // const {data}=response
          // // const file = new Blob([response.data], {type:'image/png'})
          // // console.log(file)
          // // dispatch(fileSaveSuccess({ data }));
          // // return data;
        })
        .catch((error) => {
          console.log("Error get program========>", error);
          dispatch(serverFailed());
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return console.error(e.message);
    }
  };

export const viewMultipleFile =
  ({ file }) =>
  async (dispatch) => {
    try {
      let filename = file.fileName;
      console.log(file);
      console.log(DownloadURL + "/" + filename);
      const name = localStorage.getItem("token");
      const token = decodeData(name);

      const headers = {
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .get(DownloadURL + "/" + filename, { headers })
        .then((response) => {
          console.log("Success Save file  ========>", response);
          const { data } = response.data;
          let fileData = {
            fileName: data,
            name: file.name,
          };
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            dispatch(MultifileDownloadSuccess({ fileData }));
            return fileData
          }
          return response
        })
        .catch((error) => {
          console.log("Error get program========>", error);
          dispatch(serverFailed());
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return console.error(e.message);
    }
  };

export const deleteFileData = () => async (dispatch) => {
  try {
    dispatch(fileFailure());
  } catch (e) {
    return console.error(e.message);
  }
};

export const downloadF = () => async (dispatch) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get(
        userAppReportURL,
        //+"/1d87bbd2-17f3-4658-a6ae-be4918ebee1d"
        { headers }
      )
      .then((response) => {
        console.log(response);
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          // const blobFile = new Blob([response.data], { type: "application/pdf" });
          // const anchor = document.getElementById('invisible-link')
          // console.log(blobFile)
          // const objectUrl = window.URL.createObjectURL(blobFile)
          // anchor.href = objectUrl;
          // anchor.download = "testReport.pdf"
          // anchor.click()
          // window.URL.revokeObjectURL(objectUrl)

          let byteArray = new Uint8Array(response.data);

          let file = new Blob([byteArray], { type: "application/pdf" });
          console.log(file);
          const url = window.URL.createObjectURL(file);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `FileName.pdf`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      })

      .catch((error) => {
        console.log("Error ========>", error);
        dispatch(serverFailed());
      });
  } catch (e) {
    dispatch(serverFailed());
    return console.error(e.message);
  }
};


export const resetServerFailed = () => async (dispatch) => {
  try {
    dispatch(resetServerFailedSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};