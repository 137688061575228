import { FormHelperText, Grid, TextField } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import * as myConstClass from "../../config/messageconstant";
import { getProgramType } from "../ProgramType/programType.slice";
import { getUniversity } from "../University/university.slice";
import { getAlert } from "../../Alert/alert.slice";


let docs;
class ProgramMForm extends React.Component
{
  state = {
    id: 0,
    trustId: "",
    uniId: "",
    progTypeId: "",
    progName: "",
    progDuration: "",
    isActive: "1",
    delStatus: "1",
    breadCrum: "",
    formErrors: [],
  };

  changeHandler = ( event ) =>
  {
    const { name, value } = event.target;

    this.setState( {
      ...this.state,
      [ name ]: value,
    } );
  };
  componentDidMount()
  {
    const { isEdit, breadCrum, getUniversity, getProgramType } = this.props;
    getUniversity().then( ( respo ) =>
    {
      if ( !respo )
      {
        this.props.getAlert( { message: myConstClass.serverMsg } );
      }
    } );

    getProgramType().then( ( respo ) =>
    {
      if ( !respo )
      {
        this.props.getAlert( { message: myConstClass.serverMsg } );
      }
    } );
    let trustId = localStorage.getItem( "trustId" );
    this.setState( {
      trustId: trustId,
    } );
    breadCrum( ">  Add" );
    if ( isEdit.length > 0 )
    {
      this.setState( {
        id: isEdit[ 0 ].id,
        progTypeName: isEdit[ 0 ].progTypeName,
        progTypeId: isEdit[ 0 ].progTypeId,
        uniId: isEdit[ 0 ].uniId,
        uniName: isEdit[ 0 ].uniName,
        progName: isEdit[ 0 ].programType,
        progDuration: isEdit[ 0 ].progDuration,
        isActive: isEdit[ 0 ].isActive === "Active" ? 1 : 0,
      } );
    }
  }
  handleFormValidation( name )
  {
    const { uniId, progTypeId, progName, progDuration } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if ( uniId === "" || uniId === null )
    {
      formIsValid = false;
      formErrors[ "uniIdErr" ] = myConstClass.uniNameMsg;
    }

    if ( progTypeId === "" || progTypeId === null )
    {
      formIsValid = false;
      formErrors[ "progTypeIdErr" ] = myConstClass.progNameMsg;
    }
    if ( progName.trim() === "" )
    {
      formIsValid = false;
      formErrors[ "progNameErr" ] = myConstClass.programTypeMsg;
    }
    if ( progDuration === "" )
    {
      formIsValid = false;
      formErrors[ "progDurationErr" ] = myConstClass.progDurationMsg;
    }

    this.setState( { formErrors: formErrors } );
    return formIsValid;
  }

  submitHandler = ( e ) =>
  {
    e.preventDefault();
    const {
      id,
      uniId,
      trustId,
      progTypeId,
      progName,
      progDuration,
      isActive,
    } = this.state;
    docs = {
      university: {
        id: uniId,
      },
      program: {
        id: progTypeId,
      },
      name: progName,
      duration: progDuration,
      isActive: isActive,
      delStatus: "1",
      trust: {
        id: trustId,
      },
    };

    if ( id !== 0 )
    {
      docs = {
        ...docs,
        id: id,
      };
    }
    if ( this.handleFormValidation() )
    {
      this.props.OnSave( docs );
    }
  };
  saveHandler = ( e ) =>
  {
    e.preventDefault();
    const {
      id,
      uniId,
      trustId,
      progTypeId,
      progName,
      progDuration,
      isActive,
    } = this.state;
    docs = {
      university: {
        id: uniId,
      },
      program: {
        id: progTypeId,
      },
      name: progName,
      duration: progDuration,
      isActive: isActive,
      delStatus: "1",
      trust: {
        id: trustId,
      },
    };

    if ( id !== 0 )
    {
      docs = {
        ...docs,
        id: id,
      };
    }

    if ( this.handleFormValidation() )
    {
      this.props.saveAndNext( docs );
      this.setState( {
        id: 0,
        progTypeId: "",
        uniId: "",
        progName: "",
        progDuration: "",
        isActive: 1,
        formErrors: [],
      } );
    }
  };
  ChangeHandlerSearch = ( name, newValue ) =>
  {
    if ( newValue !== null )
    {
      this.setState( { [ name ]: newValue } );
    }
    else
    {
      this.setState( { [ name ]: '' } );
    }
  };
  render()
  {
    const { uniId, progTypeId, progName, progDuration } = this.state;
    const { onCancel, universityList, programTypeList } = this.props;

    const { progNameErr, progDurationErr, uniIdErr, progTypeIdErr } =
      this.state.formErrors;
    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            <Grid
              item
              xs={12}
              md={12}
              size="small"
              container
              justifyContent="flex-end"
            >
              <ButtonCompo
                size="small"
                type="button"
                variant="outlined"
                name=" Back to List"
                onClick={onCancel}
              />
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={1}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*University Name : "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} mt={-2}>
                  <AutoComplete
                    keyColName={"id"}
                    value={uniId}
                    name={"uniId"}
                    options={universityList?.university}
                    onChange={this.ChangeHandlerSearch}
                  />
                  <FormHelperText error>
                    {uniIdErr ? uniIdErr : " "}{" "}
                  </FormHelperText>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Select Program :"
                  />
                </Grid>

                {/* {...(sanctionIntakeErr
                  ? { error: true, helperText: sanctionIntakeErr }
                  : "")} */}

                <Grid item xs={12} sm={12} md={12} mt={-2}>
                  <AutoComplete
                    keyColName={"id"}
                    value={progTypeId}
                    name={"progTypeId"}
                    options={programTypeList?.programType}
                    onChange={this.ChangeHandlerSearch}
                  />

                  <FormHelperText error>
                    {progTypeIdErr ? progTypeIdErr : " "}{" "}
                  </FormHelperText>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Program Type : "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="progName"
                    size="small"
                    value={progName}
                    onChange={this.changeHandler}
                    fullWidth={true}
                    error={progNameErr ? true : false}
                    helperText={progNameErr ? progNameErr : " "}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Program Duration (In years) :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="progDuration"
                    value={progDuration}
                    type='number'
                    onChange={this.changeHandler}
                    fullWidth={true}
                    size="small"
                    error={progDurationErr ? true : false}
                    helperText={progDurationErr ? progDurationErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}></Grid>

              <Grid item xs={12} sm={12} md={12}></Grid>
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={1.2}
                lg={1.2}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Save"
                  fullWidth={true}
                />
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={1.8}
                lg={1.8}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Save and Next"
                  onClick={this.saveHandler}
                  fullWidth={true}
                />
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={1.2}
                lg={1.2}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Cancel"
                  onClick={onCancel}
                  fullWidth={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}

const mapStateToProps = ( state ) => ( {
  universityList: state.university,
  programTypeList: state.programType,
  common: state.common,
} );

const mapDispatchToProps = {
  getUniversity,
  getProgramType,
  getAlert
};
export default connect( mapStateToProps, mapDispatchToProps )( ProgramMForm );
