import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Lock from "@mui/icons-material/Lock";
import LockOpen from "@mui/icons-material/LockOpen";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import MilitaryTech from "@mui/icons-material/MilitaryTech";
import MoreIcon from "@mui/icons-material/MoreVert";
import InfoIcon from "@mui/icons-material/Info";
import { Link, Menu } from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { connect } from "react-redux";
import {
  ChipCompo,
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "../../components/Comman/IconButton";

function Row({
  row,
  rowViewData,
  rowCapDelete,
  setLock,
  pdfDetailsId,
  getAddCap,
  rowAddSchedule,
  applicantList,
  getMeritForm,
  rowClassDetailStatus,
  rowStatus,
  rowDelete,
  rowEdit,
}) {
  const [open, setOpen] = React.useState(false);
  const [flag, setFlag] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const changeStatus = (e, id) => {
    e.stopPropagation();
    rowStatus(id);
  };
  const onClickDelete = (e, id) => {
    e.stopPropagation();
    rowDelete(id);
  };
  const onClickView = (e, id) => {
    e.stopPropagation();
    rowViewData(id);
  };

  const onClickEdit = (e, id) => {
    e.stopPropagation();
    rowEdit(id);
  };
  const onClickCapDelete = (e, id) => {
    e.stopPropagation();
    rowCapDelete(id);
  };
  const onclickAddSchdule = (e, id, capId) => {
    e.stopPropagation();
    rowAddSchedule(id, capId);
  };
  const getApplicant = (e, id) => {
    applicantList(id);
  };
  const changeClassDetailStatus = (e, id, status) => {
    e.stopPropagation();
    rowClassDetailStatus(id, status);
  };

  const onMerit = (e, id) => {
    e.stopPropagation();
    getMeritForm(id);
    setFlag(0);
  };
  const onAddCap = (e, id) => {
    e.stopPropagation();
    getAddCap(id);
    setFlag(0);
  };
  const onLock = (e, id) => {
    e.stopPropagation();
    setLock(id);
  };
  const pdfDetails = (e, row) => {
    e.stopPropagation();
    pdfDetailsId(row);
  };
  React.useEffect(() => {});
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="td" scope="row">
          <center> {row.index}</center>
        </TableCell>
        <TableCell>{row.title}</TableCell>
        <TableCell>
          <center>
            {row.isActive === 1 ? (
              <ChipCompo
                label="Active"
                variant="outlined"
                size="small"
                color="success"
                onClick={(e) => changeStatus(e, row.id)}
              />
            ) : (
              <ChipCompo
                label="Inactive"
                variant="outlined"
                size="small"
                color="error"
                onClick={(e) => changeStatus(e, row.id)}
              />
            )}
          </center>
        </TableCell>
        <TableCell>
          <center>
            {row.isFreez == 0 && (
              <MyComponentWithIconProps
                statusImage={LockOpen}
                color="primary"
                fontSize="medium"
                title="Click To Lock"
                sx={{ m: -1, mr: 2 }}
                onClick={(e) => onLock(e, row.id)}
              />
            )}
            {row.isFreez == 1 && (
              <MyComponentWithIconProps
                statusImage={Lock}
                color="primary"
                fontSize="medium"
                title=" Lock"
                sx={{ m: -1, mr: 1 }}
              />
            )}
            &nbsp;&nbsp;&nbsp;
            <MyComponentWithIconProps
              statusImage={InfoIcon}
              color="primary"
              fontSize="medium"
              title="Applied Applicants"
              sx={{ m: -1 }}
              onClick={(e) => getApplicant(e, row.id)}
            />
            <IconButton
              onClick={handleClick}
              sx={{ ml: 1 }}
              aria-controls={openMenu ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? "true" : undefined}
            >
              <MoreIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openMenu}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={(e) => onClickEdit(e, row.id)}>
                <MyComponentWithIconProps
                  statusImage={EditIcon}
                  color="primary"
                  fontSize="medium"
                  title="Edit"
                  onClick={(e) => onClickEdit(e, row.id)}
                />
                &nbsp; Edit
              </MenuItem>
              <MenuItem onClick={(e) => onClickDelete(e, row.id)}>
                <MyComponentWithIconProps
                  statusImage={DeleteIcon}
                  color="error"
                  fontSize="medium"
                  title="Delete"
                />
                &nbsp; Delete
              </MenuItem>
              <MenuItem onClick={(e) => onClickView(e, row.id)}>
                <MyComponentWithIconProps
                  statusImage={VisibilityIcon}
                  color="primary"
                  fontSize="medium"
                  title="View"
                />
                &nbsp; View
              </MenuItem>
            </Menu>
          </center>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function CollapsibleTable({
  getAddCap,
  setLock,
  pdfDetailsId,
  getMeritForm,
  rowAddSchedule,
  rowClassDetailStatus,
  rowCapDelete,
  applicantList,
  rowStatus,
  rowDelete,
  rowViewData,
  rowEdit,
  appliData,
  data,
}) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>
              <center>Sr. No.</center>
            </TableCell>
            <TableCell>Application Name</TableCell>
            <TableCell>
              <center>Status</center>
            </TableCell>
            <TableCell>
              <center>Action</center>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <Row
              key={row.id}
              appliData={appliData}
              row={row}
              rowEdit={rowEdit}
              rowClassDetailStatus={rowClassDetailStatus}
              rowStatus={rowStatus}
              rowDelete={rowDelete}
              rowViewData={rowViewData}
              applicantList={applicantList}
              rowAddSchedule={rowAddSchedule}
              getMeritForm={getMeritForm}
              setLock={setLock}
              pdfDetailsId={pdfDetailsId}
              getAddCap={getAddCap}
              rowCapDelete={rowCapDelete}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const mapStateToProps = (state) => ({
  appliData: state.directAdmission,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CollapsibleTable);
