import { Box} from "@mui/material";

export const TitleAndBreadCrum = ({title}) => {
  return (
    <Box display="flex" justifyContent="flex-start">
      
      <Box  style={{ fontSize: 16 ,paddingLeft:10}}>{title}</Box>
      
    </Box>
  );
};