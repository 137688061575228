import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { decodeData } from "../../components/Comman/Util/Base64";
import endpoints from "../../config/endpoints";
import { tokenExp } from "../Login/login.slice";

const initialState = {
  user: [],
  isFetch: false,
  isServerFail: false,


};

let URL = endpoints.seatAcceptLetter;
let URL2 = endpoints.seatAcceptLetterURL;
const seatAcceptLetterSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    seatAcceptLetterSuccess: (state = cloneDeep(initialState), action) => {
      console.log(action.payload);
      const { row } = action.payload;
      return {
        ...state,
        isFetch: true,
        user: row,
      };
    },
    seatAcceptLetterFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isFetch: false,
        user: [],
      };
    },

    serverReset: (state = cloneDeep(initialState), action) => {

      return {
        ...state,
        isServerFail: false,

      };
    },
    serverFailed: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: true,
    
      };
    },

  },
});

export const {
    seatAcceptLetterSuccess,
    serverFailed,
    serverReset

} = seatAcceptLetterSlice.actions;

export default seatAcceptLetterSlice.reducer;

export const getseatAcceptLetter = () => async (dispatch) => {
  try {
    console.log("get seatAcceptLetters /////");
    console.log("URL" + URL);
    console.log(Headers);
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get(URL , { headers })
      .then((response) => {
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          console.log(
            "Success getseatAcceptLetter ========>",
            response
          );
          let data = response.data.data;
          console.log("abc");
          let row = [];
          let index = 0;
          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              applicationName: data1.roundSchedule.applicationRound.applicationDeclare.title,
              roundDetails: data1.roundSchedule.applicationRound.name,
              institute: data1.institute.tenantName,
              seatAllotedQuota: data1.allocatedGenderId +"-"+ data1.category.name,
              program: data1.userRoundPrefrence.programType.program.name,
              acceptanceStatus: data1.acceptanceStatus,
              applicantStatus: data1.applicantStatus
            };
            row.push(bData);
            console.log(row);
            return bData;
          });
          dispatch(seatAcceptLetterSuccess({ row }));
          return row;
        }
      })
      .catch((error) => {
        console.log("Error getseatAcceptLetter========>", error);
        dispatch(serverFailed());
      });
    return doc;
  } catch (e) {
    dispatch(serverFailed());
    return console.error(e.message);
  }
};
export const getseatAcceptLetterStatus = () => async (dispatch) => {
  try {
    console.log("get seatAcceptLetters /////");
    console.log("URL" + URL);
    console.log(Headers);
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      // .get(URL , { headers })
      .then((response) => {
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          console.log(
            "Success getseatAcceptLetter ========>",
            response
          );
          // let data = response.data.data;
          // console.log("abc");
          let row = [];
          // let index = 0;
          // data.map((data1) => {
          //   index = index + 1;
          //   let bData = {
          //     index: index,
          //     id: data1.id,
          //     applicationName: data1.roundSchedule.applicationRound.applicationDeclare.title,
          //     roundDetails: data1.roundSchedule.applicationRound.name,
          //     institute: data1.institute.tenantName,
          //     seatAllotedQuota: data1.allocatedGenderId +"-"+ data1.category.name,
          //     program: data1.userRoundPrefrence.programType.program.name,
          //   };
          //   row.push(bData);
          //   console.log(row);
          //   return bData;
          // });
          dispatch(seatAcceptLetterSuccess({ row }));
          return row;
        }
      })
      .catch((error) => {
        console.log("Error getseatAcceptLetter========>", error);
        dispatch(serverFailed());
      });
    return doc;
  } catch (e) {
    dispatch(serverFailed());
    return console.error(e.message);
  }
};

export const getseatAcceptLetterApproved = ({roundId}) => async (dispatch) => {
  try {
 
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get(URL2+"/"+ roundId + "/update-acceptance-status", { headers })
      .then((response) => {
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          console.log(
            "Success getseatAcceptLetter ========>",
            response
          );

          let row = [];
       
          // dispatch(seatAcceptLetterSuccess({ row }));
          return row;
        }
      })
      .catch((error) => {
        console.log("Error getseatAcceptLetter========>", error);
        dispatch(serverFailed());
      });
    return doc;
  } catch (e) {
    dispatch(serverFailed());
    return console.error(e.message);
  }
};

export const getseatRejectLetter = ({roundId}) => async (dispatch) => {
  try {
 
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get("api/application/v1/reject-seat-process/"+roundId, { headers })
      .then((response) => {
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          console.log(
            "Success getseatAcceptLetter ========>",
            response
          );

          let row = [];
       
          // dispatch(seatAcceptLetterSuccess({ row }));
          return row;
        }
      })
      .catch((error) => {
        console.log("Error getseatAcceptLetter========>", error);
        dispatch(serverFailed());
      });
    return doc;
  } catch (e) {
    dispatch(serverFailed());
    return console.error(e.message);
  }
};


export const resetDelete = () => async (dispatch) => {
  try {
    dispatch(serverReset());
  } catch (e) {
    return console.error(e.message);
  }
};
