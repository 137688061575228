import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import Alert from "../../Alert/Alert";
import { getAlert } from "../../Alert/alert.slice";
import {
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "../../components/Comman/IconButton";
import { DirectionSnackbar } from "../../components/Comman/SnakBarCompo";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
import { Loading1 } from "../../components/Loading1";
import Search from "../../components/Search";
import * as myConstClass from "../../config/messageconstant";
import { logOut } from "../Login/login.slice";
import CategorySeatIntakeDetailMainTable from "./CategorySeatIntakeDetailMainTable";
import CategorySeatIntakeExcel from "./CategorySeatIntakeExcel";
import CategorySeatIntakeForm from "./CategorySeatIntakeForm";
import ViewInst from "./CategorySeatView";
import {
  deleteCatSeatIntakeDetailById,
  filterCatSeatIntakeData,
  getCatSeatIntakeDetail,
  resetDelete,
  resetReserv,
  resetSave,
  saveCatSeatIntakeDetail,
} from "./catSeatIntakeDetail.slice";

const breakpoint = 600;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const names = [
  { title: "Institute Name", name: "instituteName" },
  { title: "Reservation Structure Header", name: "reservationStructHeader" },
  { title: "Class Intake Detail", name: "classIntakeDetaill" },
  { title: "Status", name: "isActive" },
];

const gender = [
  { gender: "Gender All ", key: "genderAll", total: 0 },
  { gender: "Male", key: "genderM", total: 0 },
  { gender: "Female", key: "genderF", total: 0 },
  { gender: "Transgender", key: "genderT", total: 0 },
  { gender: "Priority", key: "allotmentPriority", total: 0 },
];

let newInstData;
const CategorySeatIntakeDetail = ({
  getCatSeatIntakeDetail,
  catSeatIntakeDetailList,
  saveCatSeatIntakeDetail,
  deleteCatSeatIntakeDetailById,
  resetReserv,
  resetDelete,
  resetSave,
  loginUser,
  logOut,
  getAlert,
  filterCatSeatIntakeData,
}) => {
  const [isEdit, setEdit] = useState(false);
  const [snakMsg, setSnakMsg] = useState("");
  const [editRow, setEditRow] = useState([]);
  const [status, setStatus] = useState(false);
  const [del, setDel] = useState(false);
  const [save, setSave] = useState(false);
  const [viewRow, setViewRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [filteropen, setFilterOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [openSnak, setOpenSnak] = useState(false);
  const navigate = useNavigate();

  const [filteredList, setfilterList] = React.useState([
    "Institute Name",
    "Reservation Structure Header",
    "Status",
  ]);
  const [trustList, setTrustList] = React.useState([
    [{ title: "Sr. No.", name: "id" }],
    [{ title: "Institute Name", name: "instituteName" }],
    [
      {
        title: "Reservation Structure Header",
        name: "reservationStructHeader",
      },
    ],
    [{ title: "Class Intake Detail", name: "classIntakeDetaill" }],
    [{ title: "Status", name: "isActive" }],
  ]);

  const [width, setWidth] = React.useState(window.innerWidth);
  const [breadCrumName, setBreadCrum] = useState(
    "   >   Master   >   Category Seat Intake "
  );

  const getRowID = async (id) => {
    setViewRow([]);
    const editedRow = catSeatIntakeDetailList?.catSeatIntakeDetail.filter(
      (row) => row.id === id
    );
    if (editedRow.length > 0) {
      setEdit(true);
      resetReserv();
      setEditRow([]);
      return await setEditRow(editedRow);
    }

    return setEditRow([]);
  };
  const getRowData = async (id) => {
    setOpen(true);
    const viewRow = catSeatIntakeDetailList?.catSeatIntakeDetail.filter(
      (row) => row.id === id
    );
    if (viewRow.length > 0) {
      return await setViewRow(viewRow);
    }
    return setViewRow([]);
  };

  const rowStatus = async (id) => {
    swal(myConstClass.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        const viewRow = catSeatIntakeDetailList?.catSeatIntakeDetail.filter(
          (row) => row.id === id
        );

        console.log(viewRow);
        let catSeatIntakeDetailData = {
          id: viewRow[0].id,
          reservationStructure: {
            id: viewRow[0].reservationStructHeaderId,
          },
          tenant: {
            id: viewRow[0].instNameId,
          },
          classIntakeDetail: {
            id: viewRow[0].classIntakeDetaillId,
          },
          categorySeatIntakeDetailList: viewRow[0].categorySeatIntakeDetailList,
          isActive: viewRow[0].isActive === "Active" ? "0" : "1",
        };
        console.log(catSeatIntakeDetailData);
        resetSave();
        setStatus(true);
        saveCatSeatIntakeDetail({
          catSeatIntakeDetailData: catSeatIntakeDetailData,
        }).then((respo) => {
          if (!respo) {
            getAlert({ message: myConstClass.serverMsg });
          } else {
            setOpenSnak(true);
            setSnakMsg(myConstClass.activeStatus);
            setStatus(false);
            getCatSeatIntakeDetail().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
          }
        });
        console.log(viewRow);
      }
    });
  };

  const data = (instData) => {
    console.log("save api" + JSON.stringify(instData));
    resetSave();
    saveCatSeatIntakeDetail({ catSeatIntakeDetailData: instData }).then(() => {
      setSave(true);
      setOpenSnak(true);
      if (instData.id != null) {
        setSnakMsg(myConstClass.updateMsg);
      } else {
        setSnakMsg(myConstClass.saveMsg);
      }
      setSave(false);
      getCatSeatIntakeDetail().then((respo) => {
        if (!respo) {
          getAlert({ message: myConstClass.serverMsg });
        }
      });
    });

    console.log("save after" + JSON.stringify(instData));

    setEdit(false);
    setEditRow([]);
  };

  useEffect(() => {
    getCatSeatIntakeDetail().then((respo) => {
      if (!respo) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  useEffect(() => {
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
  });
  function compId(a, b) {
    if (a.id < b.id) {
      return -1;
    } else if (a.id > b.id) {
      return 1;
    } else {
      return 0;
    }
  }

  const startEdit = () => {
    resetReserv();
    setEdit(true);
  };

  const saveAndNextData = (data) => {
    console.log(data);
    resetSave();
    saveCatSeatIntakeDetail({ catSeatIntakeDetailData: data }).then(() => {
      resetReserv();
      setSave(true);
      setOpenSnak(true);
      if (data.id != null) {
        setSnakMsg(myConstClass.updateMsg);
      } else {
        setSnakMsg(myConstClass.saveMsg);
      }
      setSave(false);
      getCatSeatIntakeDetail().then((respo) => {
        if (!respo) {
          getAlert({ message: myConstClass.serverMsg });
        }
      });
    });
    setEdit(true);
    setEditRow([]);
  };
  const breadCrum = (prevBreadCrum) => {
    setBreadCrum(breadCrumName + prevBreadCrum);
  };

  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      deleteCatSeatIntakeDetailById({ catSeatIntakeDetailId: id }).then(
        (resp) => {
          if (!resp) {
            getAlert({ message: myConstClass.serverMsg });
          } else {
            setDel(true);
            setOpenSnak(true);
            setSnakMsg(myConstClass.deleteMsg);
            getCatSeatIntakeDetail().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
            setDel(false);
          }
        }
      );
    });
  };

  const catSeatIntakeDetailFilteredData =
    catSeatIntakeDetailList?.catSeatIntakeDetail.filter(
      (item) =>
        item.instituteName.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.reservationStructHeader
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        item.classIntakeDetaill
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        item.isActive.toLowerCase() === searchValue
    );

  const filteredRows = (searchvalue) => {
    setSearchValue(searchvalue);
    const filteredRow = catSeatIntakeDetailList?.catSeatIntakeDetail.filter(
      (row) => {
        let instituteName = row?.instituteName;
        let reservationStructHeader = row?.reservationStructHeader;
        let classIntakeDetaill = row?.classIntakeDetaill;

        let isActive = row?.isActive;
        return (
          instituteName.toLowerCase().includes(searchvalue.toLowerCase()) ||
          reservationStructHeader
            .toLowerCase()
            .includes(searchvalue.toLowerCase()) ||
          classIntakeDetaill
            .toLowerCase()
            .includes(searchvalue.toLowerCase()) ||
          isActive.toLowerCase() === searchvalue
        );
      }
    );
    console.log(filteredRow);
    if (
      catSeatIntakeDetailList?.catSeatIntakeDetail.length == filteredRows.length
    ) {
      filterCatSeatIntakeData({ row: filteredRow, flag: false });
    } else {
      filterCatSeatIntakeData({ row: filteredRow, flag: true });
    }
  };

  const goToList = () => {
    setEdit(false);
  };

  const stopEdit = () => {
    setBreadCrum("   >   Master   >   Category Seat Intake  ");
    setEditRow([]);
    setEdit(false);
  };

  const closeFilter = () => {
    setTrustList([
      [{ title: "  Sr. No.", name: "id" }],
      [{ title: "Institute Name", name: "instituteName" }],
      [
        {
          title: "Reservation Structure Header",
          name: "reservationStructHeader",
        },
      ],

      [{ title: "Class Intake Detail", name: "classIntakeDetaill" }],

      [{ title: "Status", name: "isActive" }],
    ]);
    setfilterList(["Institute Name", "Reservation Structure Header", "Status"]);
    setFilterOpen(false);
  };
  const handleChange = (event) => {
    setFilterOpen(true);
    const {
      target: { value },
    } = event;

    if (value.length > filteredList.length) {
      const a = value.map((value) => {
        const trust = names.filter((row) => row.title === value);
        const trust1 = [...trustList, trust];

        return setTrustList(trust1);
      });
    } else {
      let difference = filteredList.filter((x) => !value.includes(x));
      const instList = trustList.filter((data) => {
        return data[0].title !== difference[0];
      });
      setTrustList(instList);
    }

    setfilterList(typeof value === "string" ? value.split(",") : value);

    if (value.length === 0) {
      setFilterOpen(false);
    }
  };
  //console.log(catSeatIntakeDetailList?.catSeatIntakeDetail);
  return (
    <>
      <div>
        <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
          <TitleAndBreadCrum
            title="Category Seat Intake"
            breadCrumName={breadCrumName}
          />
        </Paper>
        <br />

        {/* {catSeatIntakeDetailList.isDelete && catSeatIntakeDetailList.error == true && (
          <Alert msg={myConstClass.deleteFailMsg} closeAlert={resetDelete} />
        )}
        {catSeatIntakeDetailList.isServerFail && <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />} */}

        {/* < ErrorHand error={"error"} msg={'This is an error alert — check it out!'} flag={true}/>  */}

        {catSeatIntakeDetailList.isDelete &&
        catSeatIntakeDetailList.error == false ? (
          <DirectionSnackbar
            open={openSnak}
            severity="success"
            msg={myConstClass.deleteMsg}
            closeSnak={setOpenSnak}
          />
        ) : catSeatIntakeDetailList.isSave ? (
          <DirectionSnackbar
            open={openSnak}
            severity="success"
            msg={snakMsg}
            closeSnak={setOpenSnak}
          />
        ) : (
          ""
        )}
        <Paper sx={{ p: 2, pt: 4, borderRadius: 2 }} elevation={0}>
          <div>
            {viewRow.length >= 0 && editRow.length >= 0 && isEdit && (
              <CategorySeatIntakeForm
                OnSave={data}
                isEdit={editRow}
                onCancel={stopEdit}
                saveAndNext={saveAndNextData}
                gender={gender}
                onClear={goToList}
              />
            )}
          </div>
          {open && (
            <ViewInst data={viewRow} openparam={open} closeView={setOpen} />
          )}
          <Grid>
            {!isEdit && (
              <div>
                {width > breakpoint ? (
                  <>
                    <Box
                      sx={{ mr: 2 }}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Box sx={{ m: -1, width: 300 }}>
                        <Search
                          filteredRows={filteredRows}
                          rows={catSeatIntakeDetailList?.catSeatIntakeDetail}
                          searchValue={searchValue}
                        />
                      </Box>
                      <Box>
                        {/* <FormControl sx={{ m: -1, width: 250 }}>
                      <InputLabel id="demo-multiple-checkbox-label">
                        Column Filter
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={filteredList}
                        name="sdf"
                        size="small"
                        onChange={handleChange}
                        // input={<OutlinedInput label="Column Filter" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => {
                          return (
                            <MenuItem key={name.name} value={name.title}>
                              <Checkbox
                                checked={filteredList.indexOf(name.title) > -1}
                              />
                              <ListItemText primary={name.title} />
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl> */}
                        {filteropen && (
                          <>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <CategorySeatIntakeExcel
                              csvData={catSeatIntakeDetailFilteredData}
                              instList={trustList}
                              fileName="Category Seat Intake"
                              type="pdf"
                            />
                          </>
                        )}

                        {filteropen && (
                          <>
                            &nbsp;&nbsp;
                            <CategorySeatIntakeExcel
                              csvData={catSeatIntakeDetailFilteredData}
                              instList={trustList}
                              fileName="Category Seat Intake"
                              type="excel"
                            />
                          </>
                        )}

                        {filteropen && (
                          <>
                            &nbsp;&nbsp;
                            <FontAwesomeIconCompo
                              className="fa-times-circle"
                              color="primary"
                              title="Cancel"
                              baseClassName="fas"
                              onClick={closeFilter}
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <CategorySeatIntakeExcel
                              csvData={catSeatIntakeDetailFilteredData}
                              instList={trustList}
                              fileName="Category Seat Intake"
                              type="pdf"
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;
                            <CategorySeatIntakeExcel
                              //academicYearList?.isFilter?academicYearList?.documentFilter:academicYearList?.academicYear
                              //catSeatIntakeDetailList?.isFilter?catSeatIntakeDetailList?.catSeatIntakeFilter:catSeatIntakeDetailFilteredData

                              csvData={catSeatIntakeDetailFilteredData}
                              instList={trustList}
                              fileName="Category Seat Intake"
                              type="excel"
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;&nbsp;
                            <MyComponentWithIconProps
                              statusImage={AddCircleIcon}
                              color="primary"
                              title=""
                              fontSize="large"
                              sx={{ m: -1 }}
                              onClick={startEdit}
                            />
                          </>
                        )}
                      </Box>
                    </Box>
                    <Box sx={{ mt: 2, p: 1.5 }}>
                      <Grid item md={12} sx={1}></Grid>
                      <CategorySeatIntakeDetailMainTable
                        filteredColumns={trustList}
                        data={catSeatIntakeDetailFilteredData}
                        rowEdit={getRowID}
                        rowView={getRowData}
                        rowDelete={deleteData}
                        rowStatus={rowStatus}
                        /*   rowClassDetailStatus={rowClassDetailStatus}
                        rowClassDetailEdit={rowClassDetailEdit}
                        rowClassDetailDelete={rowClassDetailDelete} */
                        // PageInfo={PageInfo}
                        // totalPages={branchMaster.pages}
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      rowSpacing={2}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid xs={12} sm={12} md={12} lg={12} rowSpacing={2}>
                        <Search
                          filteredRows={filteredRows}
                          rows={catSeatIntakeDetailList?.catSeatIntakeDetail}
                          searchValue={searchValue}
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{ m: 1, textAlign: "right" }}
                      >
                        {/* <FormControl sx={{ m: -1, width: 250 }}>
                      <InputLabel id="demo-multiple-checkbox-label">
                        Column Filter
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={filteredList}
                        name="sdf"
                        size="small"
                        onChange={handleChange}
                        // input={<OutlinedInput label="Column Filter" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => {
                          return (
                            <MenuItem key={name.name} value={name.title}>
                              <Checkbox
                                checked={filteredList.indexOf(name.title) > -1}
                              />
                              <ListItemText primary={name.title} />
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl> */}
                        {filteropen && (
                          <>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <CategorySeatIntakeExcel
                              csvData={catSeatIntakeDetailFilteredData}
                              instList={trustList}
                              fileName="Category Seat Intake"
                              type="pdf"
                            />
                          </>
                        )}

                        {filteropen && (
                          <>
                            &nbsp;&nbsp;
                            <CategorySeatIntakeExcel
                              csvData={catSeatIntakeDetailFilteredData}
                              instList={trustList}
                              fileName="Category Seat Intake"
                              type="excel"
                            />
                          </>
                        )}

                        {filteropen && (
                          <>
                            &nbsp;&nbsp;
                            <FontAwesomeIconCompo
                              className="fa-times-circle"
                              color="primary"
                              title="Cancel"
                              baseClassName="fas"
                              onClick={closeFilter}
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <CategorySeatIntakeExcel
                              csvData={catSeatIntakeDetailFilteredData}
                              instList={trustList}
                              fileName="Category Seat Intake"
                              type="pdf"
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;
                            <CategorySeatIntakeExcel
                              //academicYearList?.isFilter?academicYearList?.documentFilter:academicYearList?.academicYear
                              //catSeatIntakeDetailList?.isFilter?catSeatIntakeDetailList?.catSeatIntakeFilter:catSeatIntakeDetailFilteredData

                              csvData={catSeatIntakeDetailFilteredData}
                              instList={trustList}
                              fileName="Category Seat Intake"
                              type="excel"
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;&nbsp;
                            <MyComponentWithIconProps
                              statusImage={AddCircleIcon}
                              color="primary"
                              title=""
                              fontSize="large"
                              sx={{ m: -1 }}
                              onClick={startEdit}
                            />
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Box sx={{ mt: 2, p: 1.5 }}>
                      <Grid item md={12} sx={1}></Grid>
                      <CategorySeatIntakeDetailMainTable
                        filteredColumns={trustList}
                        data={catSeatIntakeDetailFilteredData}
                        rowEdit={getRowID}
                        rowView={getRowData}
                        rowDelete={deleteData}
                        rowStatus={rowStatus}
                        /*   rowClassDetailStatus={rowClassDetailStatus}
                          rowClassDetailEdit={rowClassDetailEdit}
                          rowClassDetailDelete={rowClassDetailDelete} */
                        // PageInfo={PageInfo}
                        // totalPages={branchMaster.pages}
                      />
                    </Box>
                  </>
                )}
              </div>
            )}
          </Grid>
          {/* {!catSeatIntakeDetailList?.isFetchCat && (
              <Loading1 size={50} />
            ) }  */}
          {!catSeatIntakeDetailList?.isServerFail &&
          !catSeatIntakeDetailList?.isFetchCat ? (
            <Loading1 size={50} />
          ) : status && !catSeatIntakeDetailList?.isSave ? (
            <Loading1 size={50} />
          ) : save && !catSeatIntakeDetailList?.isSave ? (
            <Loading1 size={50} />
          ) : del ? (
            <Loading1 size={50} />
          ) : (
            ""
          )}
        </Paper>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  catSeatIntakeDetailList: state.catSeatIntakeDetail,
  loginUser: state.user,
  common: state.common,
});

const mapDispatchToProps = {
  getCatSeatIntakeDetail,
  saveCatSeatIntakeDetail,
  deleteCatSeatIntakeDetailById,
  resetReserv,
  resetSave,
  resetDelete,
  filterCatSeatIntakeData,
  getAlert,
  resetDelete,
  logOut,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategorySeatIntakeDetail);
