/*
 A JavaScript implementation of the SHA family of hashes, as
 defined in FIPS PUB 180-2 as well as the corresponding HMAC implementation
 as defined in FIPS PUB 198a

 Copyright Brian Turek 2008-2015
 Distributed under the BSD License
 See http://caligatio.github.com/jsSHA/ for more information

 Several functions taken from Paul Johnston
*/
"use strict";
(function (T) {
  function y(c, a, d) {
    var b = 0,
      f = [],
      k = 0,
      g,
      e,
      n,
      h,
      m,
      u,
      r,
      p = !1,
      q = !1,
      t = [],
      v = [],
      x,
      w = !1;
    d = d || {};
    g = d.encoding || "UTF8";
    x = d.numRounds || 1;
    n = J(a, g);
    if (x !== parseInt(x, 10) || 1 > x)
      throw Error("numRounds must a integer >= 1");
    if ("SHA-1" === c) (m = 512), (u = K), (r = U), (h = 160);
    else if (
      ((u = function (a, d) {
        return L(a, d, c);
      }),
      (r = function (a, d, b, f) {
        var k, e;
        if ("SHA-224" === c || "SHA-256" === c)
          (k = (((d + 65) >>> 9) << 4) + 15), (e = 16);
        else if ("SHA-384" === c || "SHA-512" === c)
          (k = (((d + 129) >>> 10) << 5) + 31), (e = 32);
        else throw Error("Unexpected error in SHA-2 implementation");
        for (; a.length <= k; ) a.push(0);
        a[d >>> 5] |= 128 << (24 - (d % 32));
        a[k] = d + b;
        b = a.length;
        for (d = 0; d < b; d += e) f = L(a.slice(d, d + e), f, c);
        if ("SHA-224" === c) a = [f[0], f[1], f[2], f[3], f[4], f[5], f[6]];
        else if ("SHA-256" === c) a = f;
        else if ("SHA-384" === c)
          a = [
            f[0].a,
            f[0].b,
            f[1].a,
            f[1].b,
            f[2].a,
            f[2].b,
            f[3].a,
            f[3].b,
            f[4].a,
            f[4].b,
            f[5].a,
            f[5].b,
          ];
        else if ("SHA-512" === c)
          a = [
            f[0].a,
            f[0].b,
            f[1].a,
            f[1].b,
            f[2].a,
            f[2].b,
            f[3].a,
            f[3].b,
            f[4].a,
            f[4].b,
            f[5].a,
            f[5].b,
            f[6].a,
            f[6].b,
            f[7].a,
            f[7].b,
          ];
        else throw Error("Unexpected error in SHA-2 implementation");
        return a;
      }),
      "SHA-224" === c)
    )
      (m = 512), (h = 224);
    else if ("SHA-256" === c) (m = 512), (h = 256);
    else if ("SHA-384" === c) (m = 1024), (h = 384);
    else if ("SHA-512" === c) (m = 1024), (h = 512);
    else throw Error("Chosen SHA variant is not supported");
    e = z(c);
    this.setHMACKey = function (a, d, f) {
      var k;
      if (!0 === q) throw Error("HMAC key already set");
      if (!0 === p) throw Error("Cannot set HMAC key after finalizing hash");
      if (!0 === w) throw Error("Cannot set HMAC key after calling update");
      g = (f || {}).encoding || "UTF8";
      d = J(d, g)(a);
      a = d.binLen;
      d = d.value;
      k = m >>> 3;
      f = k / 4 - 1;
      if (k < a / 8) {
        for (d = r(d, a, 0, z(c)); d.length <= f; ) d.push(0);
        d[f] &= 4294967040;
      } else if (k > a / 8) {
        for (; d.length <= f; ) d.push(0);
        d[f] &= 4294967040;
      }
      for (a = 0; a <= f; a += 1)
        (t[a] = d[a] ^ 909522486), (v[a] = d[a] ^ 1549556828);
      e = u(t, e);
      b = m;
      q = !0;
    };
    this.update = function (a) {
      var c,
        d,
        g,
        h = 0,
        p = m >>> 5;
      c = n(a, f, k);
      a = c.binLen;
      d = c.value;
      c = a >>> 5;
      for (g = 0; g < c; g += p)
        h + m <= a && ((e = u(d.slice(g, g + p), e)), (h += m));
      b += h;
      f = d.slice(h >>> 5);
      k = a % m;
      w = !0;
    };
    this.getHash = function (a, d) {
      var g, m, n;
      if (!0 === q) throw Error("Cannot call getHash after setting HMAC key");
      n = M(d);
      switch (a) {
        case "HEX":
          g = function (a) {
            return N(a, n);
          };
          break;
        case "B64":
          g = function (a) {
            return O(a, n);
          };
          break;
        case "BYTES":
          g = P;
          break;
        default:
          throw Error("format must be HEX, B64, or BYTES");
      }
      if (!1 === p)
        for (e = r(f, k, b, e), m = 1; m < x; m += 1) e = r(e, h, 0, z(c));
      p = !0;
      return g(e);
    };
    this.getHMAC = function (a, d) {
      var g, n, t;
      if (!1 === q)
        throw Error("Cannot call getHMAC without first setting HMAC key");
      t = M(d);
      switch (a) {
        case "HEX":
          g = function (a) {
            return N(a, t);
          };
          break;
        case "B64":
          g = function (a) {
            return O(a, t);
          };
          break;
        case "BYTES":
          g = P;
          break;
        default:
          throw Error("outputFormat must be HEX, B64, or BYTES");
      }
      !1 === p && ((n = r(f, k, b, e)), (e = u(v, z(c))), (e = r(n, h, m, e)));
      p = !0;
      return g(e);
    };
  }
  function b(c, a) {
    this.a = c;
    this.b = a;
  }
  function V(c, a, d) {
    var b = c.length,
      f,
      k,
      e,
      l,
      n;
    a = a || [0];
    d = d || 0;
    n = d >>> 3;
    if (0 !== b % 2)
      throw Error("String of HEX type must be in byte increments");
    for (f = 0; f < b; f += 2) {
      k = parseInt(c.substr(f, 2), 16);
      if (isNaN(k))
        throw Error("String of HEX type contains invalid characters");
      l = (f >>> 1) + n;
      for (e = l >>> 2; a.length <= e; ) a.push(0);
      a[e] |= k << (8 * (3 - (l % 4)));
    }
    return { value: a, binLen: 4 * b + d };
  }
  function W(c, a, d) {
    var b = [],
      f,
      k,
      e,
      l,
      b = a || [0];
    d = d || 0;
    k = d >>> 3;
    for (f = 0; f < c.length; f += 1)
      (a = c.charCodeAt(f)),
        (l = f + k),
        (e = l >>> 2),
        b.length <= e && b.push(0),
        (b[e] |= a << (8 * (3 - (l % 4))));
    return { value: b, binLen: 8 * c.length + d };
  }
  function X(c, a, d) {
    var b = [],
      f = 0,
      e,
      g,
      l,
      n,
      h,
      m,
      b = a || [0];
    d = d || 0;
    a = d >>> 3;
    if (-1 === c.search(/^[a-zA-Z0-9=+\/]+$/))
      throw Error("Invalid character in base-64 string");
    g = c.indexOf("=");
    c = c.replace(/\=/g, "");
    if (-1 !== g && g < c.length)
      throw Error("Invalid '=' found in base-64 string");
    for (g = 0; g < c.length; g += 4) {
      h = c.substr(g, 4);
      for (l = n = 0; l < h.length; l += 1)
        (e =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/".indexOf(
            h[l]
          )),
          (n |= e << (18 - 6 * l));
      for (l = 0; l < h.length - 1; l += 1) {
        m = f + a;
        for (e = m >>> 2; b.length <= e; ) b.push(0);
        b[e] |= ((n >>> (16 - 8 * l)) & 255) << (8 * (3 - (m % 4)));
        f += 1;
      }
    }
    return { value: b, binLen: 8 * f + d };
  }
  function N(c, a) {
    var d = "",
      b = 4 * c.length,
      f,
      e;
    for (f = 0; f < b; f += 1)
      (e = c[f >>> 2] >>> (8 * (3 - (f % 4)))),
        (d +=
          "0123456789abcdef".charAt((e >>> 4) & 15) +
          "0123456789abcdef".charAt(e & 15));
    return a.outputUpper ? d.toUpperCase() : d;
  }
  function O(c, a) {
    var d = "",
      b = 4 * c.length,
      f,
      e,
      g;
    for (f = 0; f < b; f += 3)
      for (
        g = (f + 1) >>> 2,
          e = c.length <= g ? 0 : c[g],
          g = (f + 2) >>> 2,
          g = c.length <= g ? 0 : c[g],
          g =
            (((c[f >>> 2] >>> (8 * (3 - (f % 4)))) & 255) << 16) |
            (((e >>> (8 * (3 - ((f + 1) % 4)))) & 255) << 8) |
            ((g >>> (8 * (3 - ((f + 2) % 4)))) & 255),
          e = 0;
        4 > e;
        e += 1
      )
        8 * f + 6 * e <= 32 * c.length
          ? (d +=
              "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/".charAt(
                (g >>> (6 * (3 - e))) & 63
              ))
          : (d += a.b64Pad);
    return d;
  }
  function P(c) {
    var a = "",
      d = 4 * c.length,
      b,
      f;
    for (b = 0; b < d; b += 1)
      (f = (c[b >>> 2] >>> (8 * (3 - (b % 4)))) & 255),
        (a += String.fromCharCode(f));
    return a;
  }
  function M(c) {
    var a = { outputUpper: !1, b64Pad: "=" };
    c = c || {};
    a.outputUpper = c.outputUpper || !1;
    a.b64Pad = c.b64Pad || "=";
    if ("boolean" !== typeof a.outputUpper)
      throw Error("Invalid outputUpper formatting option");
    if ("string" !== typeof a.b64Pad)
      throw Error("Invalid b64Pad formatting option");
    return a;
  }
  function J(c, a) {
    var d;
    switch (a) {
      case "UTF8":
      case "UTF16BE":
      case "UTF16LE":
        break;
      default:
        throw Error("encoding must be UTF8, UTF16BE, or UTF16LE");
    }
    switch (c) {
      case "HEX":
        d = V;
        break;
      case "TEXT":
        d = function (c, d, b) {
          var e = [],
            l = [],
            n = 0,
            h,
            m,
            u,
            r,
            p,
            e = d || [0];
          d = b || 0;
          u = d >>> 3;
          if ("UTF8" === a)
            for (h = 0; h < c.length; h += 1)
              for (
                b = c.charCodeAt(h),
                  l = [],
                  128 > b
                    ? l.push(b)
                    : 2048 > b
                    ? (l.push(192 | (b >>> 6)), l.push(128 | (b & 63)))
                    : 55296 > b || 57344 <= b
                    ? l.push(
                        224 | (b >>> 12),
                        128 | ((b >>> 6) & 63),
                        128 | (b & 63)
                      )
                    : ((h += 1),
                      (b =
                        65536 +
                        (((b & 1023) << 10) | (c.charCodeAt(h) & 1023))),
                      l.push(
                        240 | (b >>> 18),
                        128 | ((b >>> 12) & 63),
                        128 | ((b >>> 6) & 63),
                        128 | (b & 63)
                      )),
                  m = 0;
                m < l.length;
                m += 1
              ) {
                p = n + u;
                for (r = p >>> 2; e.length <= r; ) e.push(0);
                e[r] |= l[m] << (8 * (3 - (p % 4)));
                n += 1;
              }
          else if ("UTF16BE" === a || "UTF16LE" === a)
            for (h = 0; h < c.length; h += 1) {
              b = c.charCodeAt(h);
              "UTF16LE" === a && ((m = b & 255), (b = (m << 8) | (b >>> 8)));
              p = n + u;
              for (r = p >>> 2; e.length <= r; ) e.push(0);
              e[r] |= b << (8 * (2 - (p % 4)));
              n += 2;
            }
          return { value: e, binLen: 8 * n + d };
        };
        break;
      case "B64":
        d = X;
        break;
      case "BYTES":
        d = W;
        break;
      default:
        throw Error("format must be HEX, TEXT, B64, or BYTES");
    }
    return d;
  }
  function w(c, a) {
    return (c << a) | (c >>> (32 - a));
  }
  function q(c, a) {
    return (c >>> a) | (c << (32 - a));
  }
  function v(c, a) {
    var d = null,
      d = new b(c.a, c.b);
    return (d =
      32 >= a
        ? new b(
            (d.a >>> a) | ((d.b << (32 - a)) & 4294967295),
            (d.b >>> a) | ((d.a << (32 - a)) & 4294967295)
          )
        : new b(
            (d.b >>> (a - 32)) | ((d.a << (64 - a)) & 4294967295),
            (d.a >>> (a - 32)) | ((d.b << (64 - a)) & 4294967295)
          ));
  }
  function Q(c, a) {
    var d = null;
    return (d =
      32 >= a
        ? new b(c.a >>> a, (c.b >>> a) | ((c.a << (32 - a)) & 4294967295))
        : new b(0, c.a >>> (a - 32)));
  }
  function Y(c, a, d) {
    return (c & a) ^ (~c & d);
  }
  function Z(c, a, d) {
    return new b((c.a & a.a) ^ (~c.a & d.a), (c.b & a.b) ^ (~c.b & d.b));
  }
  function R(c, a, d) {
    return (c & a) ^ (c & d) ^ (a & d);
  }
  function aa(c, a, d) {
    return new b(
      (c.a & a.a) ^ (c.a & d.a) ^ (a.a & d.a),
      (c.b & a.b) ^ (c.b & d.b) ^ (a.b & d.b)
    );
  }
  function ba(c) {
    return q(c, 2) ^ q(c, 13) ^ q(c, 22);
  }
  function ca(c) {
    var a = v(c, 28),
      d = v(c, 34);
    c = v(c, 39);
    return new b(a.a ^ d.a ^ c.a, a.b ^ d.b ^ c.b);
  }
  function da(c) {
    return q(c, 6) ^ q(c, 11) ^ q(c, 25);
  }
  function ea(c) {
    var a = v(c, 14),
      d = v(c, 18);
    c = v(c, 41);
    return new b(a.a ^ d.a ^ c.a, a.b ^ d.b ^ c.b);
  }
  function fa(c) {
    return q(c, 7) ^ q(c, 18) ^ (c >>> 3);
  }
  function ga(c) {
    var a = v(c, 1),
      d = v(c, 8);
    c = Q(c, 7);
    return new b(a.a ^ d.a ^ c.a, a.b ^ d.b ^ c.b);
  }
  function ha(c) {
    return q(c, 17) ^ q(c, 19) ^ (c >>> 10);
  }
  function ia(c) {
    var a = v(c, 19),
      d = v(c, 61);
    c = Q(c, 6);
    return new b(a.a ^ d.a ^ c.a, a.b ^ d.b ^ c.b);
  }
  function B(c, a) {
    var d = (c & 65535) + (a & 65535);
    return (
      ((((c >>> 16) + (a >>> 16) + (d >>> 16)) & 65535) << 16) | (d & 65535)
    );
  }
  function ja(c, a, d, b) {
    var f = (c & 65535) + (a & 65535) + (d & 65535) + (b & 65535);
    return (
      ((((c >>> 16) + (a >>> 16) + (d >>> 16) + (b >>> 16) + (f >>> 16)) &
        65535) <<
        16) |
      (f & 65535)
    );
  }
  function C(c, a, d, b, f) {
    var e = (c & 65535) + (a & 65535) + (d & 65535) + (b & 65535) + (f & 65535);
    return (
      ((((c >>> 16) +
        (a >>> 16) +
        (d >>> 16) +
        (b >>> 16) +
        (f >>> 16) +
        (e >>> 16)) &
        65535) <<
        16) |
      (e & 65535)
    );
  }
  function ka(c, a) {
    var d, e, f;
    d = (c.b & 65535) + (a.b & 65535);
    e = (c.b >>> 16) + (a.b >>> 16) + (d >>> 16);
    f = ((e & 65535) << 16) | (d & 65535);
    d = (c.a & 65535) + (a.a & 65535) + (e >>> 16);
    e = (c.a >>> 16) + (a.a >>> 16) + (d >>> 16);
    return new b(((e & 65535) << 16) | (d & 65535), f);
  }
  function la(c, a, d, e) {
    var f, k, g;
    f = (c.b & 65535) + (a.b & 65535) + (d.b & 65535) + (e.b & 65535);
    k = (c.b >>> 16) + (a.b >>> 16) + (d.b >>> 16) + (e.b >>> 16) + (f >>> 16);
    g = ((k & 65535) << 16) | (f & 65535);
    f =
      (c.a & 65535) +
      (a.a & 65535) +
      (d.a & 65535) +
      (e.a & 65535) +
      (k >>> 16);
    k = (c.a >>> 16) + (a.a >>> 16) + (d.a >>> 16) + (e.a >>> 16) + (f >>> 16);
    return new b(((k & 65535) << 16) | (f & 65535), g);
  }
  function ma(c, a, d, e, f) {
    var k, g, l;
    k =
      (c.b & 65535) +
      (a.b & 65535) +
      (d.b & 65535) +
      (e.b & 65535) +
      (f.b & 65535);
    g =
      (c.b >>> 16) +
      (a.b >>> 16) +
      (d.b >>> 16) +
      (e.b >>> 16) +
      (f.b >>> 16) +
      (k >>> 16);
    l = ((g & 65535) << 16) | (k & 65535);
    k =
      (c.a & 65535) +
      (a.a & 65535) +
      (d.a & 65535) +
      (e.a & 65535) +
      (f.a & 65535) +
      (g >>> 16);
    g =
      (c.a >>> 16) +
      (a.a >>> 16) +
      (d.a >>> 16) +
      (e.a >>> 16) +
      (f.a >>> 16) +
      (k >>> 16);
    return new b(((g & 65535) << 16) | (k & 65535), l);
  }
  function z(c) {
    var a, d;
    if ("SHA-1" === c)
      c = [1732584193, 4023233417, 2562383102, 271733878, 3285377520];
    else
      switch (
        ((a = [
          3238371032, 914150663, 812702999, 4144912697, 4290775857, 1750603025,
          1694076839, 3204075428,
        ]),
        (d = [
          1779033703, 3144134277, 1013904242, 2773480762, 1359893119,
          2600822924, 528734635, 1541459225,
        ]),
        c)
      ) {
        case "SHA-224":
          c = a;
          break;
        case "SHA-256":
          c = d;
          break;
        case "SHA-384":
          c = [
            new b(3418070365, a[0]),
            new b(1654270250, a[1]),
            new b(2438529370, a[2]),
            new b(355462360, a[3]),
            new b(1731405415, a[4]),
            new b(41048885895, a[5]),
            new b(3675008525, a[6]),
            new b(1203062813, a[7]),
          ];
          break;
        case "SHA-512":
          c = [
            new b(d[0], 4089235720),
            new b(d[1], 2227873595),
            new b(d[2], 4271175723),
            new b(d[3], 1595750129),
            new b(d[4], 2917565137),
            new b(d[5], 725511199),
            new b(d[6], 4215389547),
            new b(d[7], 327033209),
          ];
          break;
        default:
          throw Error("Unknown SHA variant");
      }
    return c;
  }
  function K(c, a) {
    var d = [],
      b,
      e,
      k,
      g,
      l,
      n,
      h;
    b = a[0];
    e = a[1];
    k = a[2];
    g = a[3];
    l = a[4];
    for (h = 0; 80 > h; h += 1)
      (d[h] =
        16 > h ? c[h] : w(d[h - 3] ^ d[h - 8] ^ d[h - 14] ^ d[h - 16], 1)),
        (n =
          20 > h
            ? C(w(b, 5), (e & k) ^ (~e & g), l, 1518500249, d[h])
            : 40 > h
            ? C(w(b, 5), e ^ k ^ g, l, 1859775393, d[h])
            : 60 > h
            ? C(w(b, 5), R(e, k, g), l, 2400959708, d[h])
            : C(w(b, 5), e ^ k ^ g, l, 3395469782, d[h])),
        (l = g),
        (g = k),
        (k = w(e, 30)),
        (e = b),
        (b = n);
    a[0] = B(b, a[0]);
    a[1] = B(e, a[1]);
    a[2] = B(k, a[2]);
    a[3] = B(g, a[3]);
    a[4] = B(l, a[4]);
    return a;
  }
  function U(c, a, b, e) {
    var f;
    for (f = (((a + 65) >>> 9) << 4) + 15; c.length <= f; ) c.push(0);
    c[a >>> 5] |= 128 << (24 - (a % 32));
    c[f] = a + b;
    b = c.length;
    for (a = 0; a < b; a += 16) e = K(c.slice(a, a + 16), e);
    return e;
  }
  function L(c, a, d) {
    var q,
      f,
      k,
      g,
      l,
      n,
      h,
      m,
      u,
      r,
      p,
      v,
      t,
      w,
      x,
      y,
      z,
      D,
      E,
      F,
      G,
      H,
      A = [],
      I;
    if ("SHA-224" === d || "SHA-256" === d)
      (r = 64),
        (v = 1),
        (H = Number),
        (t = B),
        (w = ja),
        (x = C),
        (y = fa),
        (z = ha),
        (D = ba),
        (E = da),
        (G = R),
        (F = Y),
        (I = e);
    else if ("SHA-384" === d || "SHA-512" === d)
      (r = 80),
        (v = 2),
        (H = b),
        (t = ka),
        (w = la),
        (x = ma),
        (y = ga),
        (z = ia),
        (D = ca),
        (E = ea),
        (G = aa),
        (F = Z),
        (I = S);
    else throw Error("Unexpected error in SHA-2 implementation");
    d = a[0];
    q = a[1];
    f = a[2];
    k = a[3];
    g = a[4];
    l = a[5];
    n = a[6];
    h = a[7];
    for (p = 0; p < r; p += 1)
      16 > p
        ? ((u = p * v),
          (m = c.length <= u ? 0 : c[u]),
          (u = c.length <= u + 1 ? 0 : c[u + 1]),
          (A[p] = new H(m, u)))
        : (A[p] = w(z(A[p - 2]), A[p - 7], y(A[p - 15]), A[p - 16])),
        (m = x(h, E(g), F(g, l, n), I[p], A[p])),
        (u = t(D(d), G(d, q, f))),
        (h = n),
        (n = l),
        (l = g),
        (g = t(k, m)),
        (k = f),
        (f = q),
        (q = d),
        (d = t(m, u));
    a[0] = t(d, a[0]);
    a[1] = t(q, a[1]);
    a[2] = t(f, a[2]);
    a[3] = t(k, a[3]);
    a[4] = t(g, a[4]);
    a[5] = t(l, a[5]);
    a[6] = t(n, a[6]);
    a[7] = t(h, a[7]);
    return a;
  }
  var e, S;
  e = [
    1116352408, 1899447441, 3049323471, 3921009573, 961987163, 1508970993,
    2453635748, 2870763221, 3624381080, 310598401, 607225278, 1426881987,
    1925078388, 2162078206, 2614888103, 3248222580, 3835390401, 4022224774,
    264347078, 604807628, 770255983, 1249150122, 1555081692, 1996064986,
    2554220882, 2821834349, 2952996808, 3210313671, 3336571891, 3584528711,
    113926993, 338241895, 666307205, 773529912, 1294757372, 1396182291,
    1695183700, 1986661051, 2177026350, 2456956037, 2730485921, 2820302411,
    3259730800, 3345764771, 3516065817, 3600352804, 4094571909, 275423344,
    430227734, 506948616, 659060556, 883997877, 958139571, 1322822218,
    1537002063, 1747873779, 1955562222, 2024104815, 2227730452, 2361852424,
    2428436474, 2756734187, 3204031479, 3329325298,
  ];
  S = [
    new b(e[0], 3609767458),
    new b(e[1], 602891725),
    new b(e[2], 3964484399),
    new b(e[3], 2173295548),
    new b(e[4], 4081628472),
    new b(e[5], 3053834265),
    new b(e[6], 2937671579),
    new b(e[7], 3664609560),
    new b(e[8], 2734883394),
    new b(e[9], 1164996542),
    new b(e[10], 1323610764),
    new b(e[11], 3590304994),
    new b(e[12], 4068182383),
    new b(e[13], 991336113),
    new b(e[14], 633803317),
    new b(e[15], 3479774868),
    new b(e[16], 2666613458),
    new b(e[17], 944711139),
    new b(e[18], 2341262773),
    new b(e[19], 2007800933),
    new b(e[20], 1495990901),
    new b(e[21], 1856431235),
    new b(e[22], 3175218132),
    new b(e[23], 2198950837),
    new b(e[24], 3999719339),
    new b(e[25], 766784016),
    new b(e[26], 2566594879),
    new b(e[27], 3203337956),
    new b(e[28], 1034457026),
    new b(e[29], 2466948901),
    new b(e[30], 3758326383),
    new b(e[31], 168717936),
    new b(e[32], 1188179964),
    new b(e[33], 1546045734),
    new b(e[34], 1522805485),
    new b(e[35], 2643833823),
    new b(e[36], 2343527390),
    new b(e[37], 1014477480),
    new b(e[38], 1206759142),
    new b(e[39], 344077627),
    new b(e[40], 1290863460),
    new b(e[41], 3158454273),
    new b(e[42], 3505952657),
    new b(e[43], 106217008),
    new b(e[44], 3606008344),
    new b(e[45], 1432725776),
    new b(e[46], 1467031594),
    new b(e[47], 851169720),
    new b(e[48], 3100823752),
    new b(e[49], 1363258195),
    new b(e[50], 3750685593),
    new b(e[51], 3785050280),
    new b(e[52], 3318307427),
    new b(e[53], 3812723403),
    new b(e[54], 2003034995),
    new b(e[55], 3602036899),
    new b(e[56], 1575990012),
    new b(e[57], 1125592928),
    new b(e[58], 2716904306),
    new b(e[59], 442776044),
    new b(e[60], 593698344),
    new b(e[61], 3733110249),
    new b(e[62], 2999351573),
    new b(e[63], 3815920427),
    new b(3391569614, 3928383900),
    new b(3515267271, 566280711),
    new b(3940187606, 3454069534),
    new b(4118630271, 4000239992),
    new b(116418474, 1914138554),
    new b(174292421, 2731055270),
    new b(289380356, 3203993006),
    new b(460393269, 320620315),
    new b(685471733, 587496836),
    new b(852142971, 1086792851),
    new b(1017036298, 365543100),
    new b(1126000580, 2618297676),
    new b(1288033470, 3409855158),
    new b(1501505948, 4234509866),
    new b(1607167915, 987167468),
    new b(1816402316, 1246189591),
  ];
  "function" === typeof define && define.amd
    ? define(function () {
        return y;
      })
    : "undefined" !== typeof exports
    ? "undefined" !== typeof module && module.exports
      ? (module.exports = exports = y)
      : (exports = y)
    : (T.jsSHA = y);
})(this);
