import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { decodeData } from "../../components/Comman/Util/Base64";
import endpoints from "../../config/endpoints";
import { tokenExp } from "../Login/login.slice";
const initialState = {
  classDetails: [],
  classDetailsData: {},
  isSave: false,
  isServerFail:false
};
let URL = endpoints.classDetails;
const classDetailsSlice = createSlice({
  name: "classDetails",
  initialState,
  reducers: {
    classDetailsSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        classDetails: row,
        isServerFail:false
      };
    },
    classDetailsDataSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        classDetailsData: data,
        isSave: true,
        isServerFail:false
      };
    },
    classDetailsDataFail: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        classDetailsData: {},
        isSave: false,
        isServerFail:false
      };
    },
    classDetailsDataFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        classDetails: [],
        isServerFail:false
      };
    },
    serverFailed: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: true,
      };
    },
    classDetailServerFailedReset: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: false,
      };
    },
  },
});

export const {
  classDetailsSuccess,
  classDetailsDataFail,
  classDetailsDataSuccess,
  classDetailsFailure,
  serverFailed,
  classDetailServerFailedReset
} = classDetailsSlice.actions;

export default classDetailsSlice.reducer;

export const getClassDetails = () => async (dispatch) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get(URL, { headers })
      .then((response) => {
        console.log(
          "Success program getClassDetails========>",
          response.data.data
        );
        const { error } = response.data.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          const { content } = response.data.data;
          let row = [];
          content.map((data) => {
            let classData = {
              id: data.id,

              name:
                data.year.tenant.tenantName +
                "-" +
                data.year.programType.name +
                "-" +
                data.programTitle.brName +
                "-" +
                data.year.className,

              branchName: data.programTitle.brName,
              classId: data.year.id,
              isActive: data.isActive === 1 ? "Active" : "Inactive",
              tenantId: data.year.tenant.id
            };
            row.push(classData);
            return row;
          });
          dispatch(classDetailsSuccess({ row }));
        }
      })
      .catch((error) => {
        dispatch(serverFailed());
        console.log("Error getClassDetails ========>", error);
      });
    return doc;
  } catch (e) {
    dispatch(serverFailed());
    classDetailsFailure();
    return console.error(e.message);
  }
};

export const getClassDetailsByInstituteAndProgramTitle = ({instituteId,programTitleId}) => async (dispatch) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get(URL+'/get-year-detail-by-institute-and-program-title?instituteId='+instituteId+'&programTitleId='+programTitleId, { headers })
      .then((response) => {
        console.log(
          "Success program getClassDetails========>",
          response.data.data
        );
        const { error } = response.data.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          const  content  = response.data.data;
          let row = [];
          content.map((data) => {
            let classData = {
              id: data.id,

              name:
                data.year.tenant.tenantName +
                "-" +
                data.year.programType.name +
                "-" +
                data.programTitle.brName +
                "-" +
                data.year.className,

              branchName: data.programTitle.brName,
              classId: data.year.id,
              isActive: data.isActive === 1 ? "Active" : "Inactive",
              tenantId: data.year.tenant.id
            };
            row.push(classData);
            return row;
          });
          dispatch(classDetailsSuccess({ row }));
        }
      })
      .catch((error) => {
        dispatch(serverFailed());
        console.log("Error getClassDetails ========>", error);
      });
    return doc;
  } catch (e) {
    classDetailsFailure();
    dispatch(serverFailed());
    return console.error(e.message);
  }
};
export const resetDetailsSave = () => async (dispatch) => {
  try {
    dispatch(classDetailsDataFail());
  } catch (e) {
    return console.error(e.message);
  }
};
export const resetClassDetailServerFailed = () => async (dispatch) => {
  try {
    dispatch(classDetailServerFailedReset());
  } catch (e) {
    return console.error(e.message);
  }
};
export const saveClassDetails =
  ({ classDetail }) =>
  async (dispatch) => {
    try {
      let data = JSON.stringify(classDetail);
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .post(URL, data, { headers })
        .then((response) => {
          console.log("Success with save class Details========>", response);
          const { error } = response.data.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            const { data } = response.data.data;
            dispatch(classDetailsDataSuccess({ data }));
            return data;
          }
        })
        .catch((error) => {
          dispatch(serverFailed());
          console.log("Error save class Details========>", error.response.data);
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return console.error(e.message);
    }
  };

export const deleteClassDetailById =
  ({ classDetailsId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      await axios
        .delete(URL + "/" + classDetailsId, { headers })
        .then((response) => {
          console.log("Success with delete class by id ========>", response);
        })
        .catch((error) => {
          dispatch(serverFailed());
          console.log(
            "Error delete  class details by id========>",
            error.response.data
          );
        });
    } catch (e) {
      dispatch(serverFailed());
      return console.error(e.message);
    }
  };
