import AddCircleIcon from "@mui/icons-material/AddCircle";
import { LinearProgress, Paper, Grid } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import Alert from "../../Alert/Alert";
import { getAlert } from "../../Alert/alert.slice";
import {
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "../../components/Comman/IconButton";
import DynamicTable from "../../components/Comman/MaterialUIRTTable";
import { DirectionSnackbar } from "../../components/Comman/SnakBarCompo";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
import { Loading1 } from "../../components/Loading1";
import Search from "../../components/Search";
import * as myConstClass from "../../config/messageconstant";
import { logOut } from "../Login/login.slice";
import AcademicExcelData from "./AcademicExcelData";
import {
  deleteAcademicYearById,
  filterAcademicYearData,
  getAcademicYear,
  resetDelete,
  resetSave,
  saveAcademicYear,
  updateAcademicYearById,
} from "./academicYear.slice";
import AcademicYearForm from "./AcademicYearForm";
import ViewInst from "./AcademicYearView";

const breakpoint = 600;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    title: "Academic Year Name",
    name: "name",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    title: "From Date",
    name: "fromDate",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    title: "To Date",
    name: "toDate",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    title: "Status",
    name: "isActive",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "left",
  },
];

const AcademicYearMaster = ({
  getAcademicYear,
  saveAcademicYear,
  deleteAcademicYearById,
  isFetch,
  resetSave,
  academicYearList,
  filterAcademicYearData,
  loginUser,
  logOut,
  getAlert,
  resetDelete,
  updateAcademicYearById,
}) => {
  const [isEdit, setEdit] = useState(false);
  const [status, setStatus] = useState(false);
  const [del, setDel] = useState(false);
  const [save, setSave] = useState(false);
  const [snakMsg, setSnakMsg] = useState("");
  const [editRow, setEditRow] = useState([]);
  const [viewRow, setViewRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [filteropen, setFilterOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openSnak, setOpenSnak] = useState(false);

  const [filteredList, setfilterList] = React.useState([
    "Sr. No.",
    "Academic Year Name",
    "From Date",
    "To Date",
    "Status",
  ]);
  const [academicList, setAcademicList] = React.useState([
    {
      title: "Sr. No.",
      name: "index",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      title: "Academic Year Name",
      name: "name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      title: "Institute Name",
      name: "instName",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      title: "From Date",
      name: "fromDate",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      title: "To Date",
      name: "toDate",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      title: "Admission Open",
      name: "admissionOpen",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      title: "Status",
      name: "isActive",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      title: "Action",
      name: "action",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
  ]);

  const [breadCrumName, setBreadCrum] = useState(
    "   >   Master   >   Academic Year "
  );
  const [width, setWidth] = React.useState(window.innerWidth);

  const rowStatusAdmission = async (id) => {
    swal(myConstClass.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        const viewRow = academicYearList?.academicYear.filter(
          (row) => row.id === id
        );
        const admiOpen = viewRow[0].admissionOpen == "Yes" ? "0" : "1";
        let academicYearData = {
          academicYearId: viewRow[0].id,
          status: admiOpen,
          instNameId: viewRow[0].instNameId,
        };

        resetSave();
        setStatus(true);
        updateAcademicYearById({
          academicYearData: academicYearData,
        }).then((doc) => {
          if (!doc) {
            getAlert({ message: myConstClass.serverMsg });
          } else {
            setOpenSnak(true);
            setStatus(false);
            setSnakMsg(myConstClass.activeStatus);
            getAcademicYear().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
          }
        });
      }
    });
  };

  const rowStatus = async (id) => {
    swal(myConstClass.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        const viewRow = academicYearList?.academicYear.filter(
          (row) => row.id === id
        );
        let academicYearData = {
          id: viewRow[0].id,
          tenant: {
            id: viewRow[0].instNameId,
          },
          name: viewRow[0].name,
          fromDate: viewRow[0].fromDate,
          toDate: viewRow[0].toDate,
          isActive: viewRow[0].isActive === "Active" ? "0" : "1",
          delStatus: 1,
        };
        resetSave();
        setStatus(true);
        saveAcademicYear({ academicYearData: academicYearData }).then(
          (respo) => {
            if (!respo) {
              getAlert({ message: myConstClass.serverMsg });
            } else {
              setOpenSnak(true);
              setSnakMsg(myConstClass.activeStatus);
              getAcademicYear().then((respo) => {
                if (!respo) {
                  getAlert({ message: myConstClass.serverMsg });
                }
              });
            }
          }
        );
      }
    });
  };
  const getRowID = async (id) => {
    setViewRow([]);
    const editedRow = academicYearList?.academicYear.filter(
      (row) => row.id === id
    );
    if (editedRow.length > 0) {
      setEdit(true);
      setEditRow([]);
      return await setEditRow(editedRow);
    }

    return setEditRow([]);
  };
  const getRowData = async (id) => {
    setOpen(true);
    const viewRow = academicYearList?.academicYear.filter(
      (row) => row.id === id
    );
    if (viewRow.length > 0) {
      return await setViewRow(viewRow);
    }
    return setViewRow([]);
  };
  const data = (instData) => {
    resetSave();
    setSave(true);
    saveAcademicYear({ academicYearData: instData }).then((respo) => {
      if (!respo) {
        getAlert({ message: myConstClass.serverMsg });
      } else {
        setOpenSnak(true);
        if (instData.id != null) {
          setSnakMsg(myConstClass.updateMsg);
        } else {
          setSnakMsg(myConstClass.saveMsg);
        }
        setSave(false);

        getAcademicYear().then((respo) => {
          if (!respo) {
            getAlert({ message: myConstClass.serverMsg });
          }
        });
      }
    });
    setEdit(false);
    setEditRow([]);
  };

  useEffect(() => {
    getAcademicYear().then((respo) => {
      if (!respo) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
  });

  const saveAndNextData = (data) => {
    resetSave();
    setSave(true);
    saveAcademicYear({ academicYearData: data }).then((respo) => {
      if (!respo) {
        getAlert({ message: myConstClass.serverMsg });
      } else {
        setOpenSnak(true);
        if (data.id != null) {
          setSnakMsg(myConstClass.updateMsg);
        } else {
          setSnakMsg(myConstClass.saveMsg);
        }
        setSave(false);

        getAcademicYear().then((respo) => {
          if (!respo) {
            getAlert({ message: myConstClass.serverMsg });
          }
        });
      }
    });
    setEdit(true);
    setEditRow([]);
  };

  const startEdit = () => {
    setEdit(true);
    let t = localStorage.getItem("token");
  };

  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setDel(true);
        deleteAcademicYearById({ academicYearId: id }).then((respo) => {
          if (!respo) {
            getAlert({ message: myConstClass.serverMsg });
          } else {
            setOpenSnak(true);
            setSnakMsg(myConstClass.deleteMsg);
            setDel(false);
            getAcademicYear().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
          }
        });
      }
    });
  };

  const academicYearFilteredData = academicYearList?.academicYear.filter(
    (item) =>
      item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.fromDate.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.toDate.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.isActive.toLowerCase() === searchValue
  );

  const filteredRows = (searchvalue) => {
    setSearchValue(searchvalue);
    const filteredRow = academicYearList?.academicYear.filter((row) => {
      let name = row?.name;
      let fromDate = row?.fromDate;
      let toDate = row?.toDate;
      let isActive = row?.isActive;
      return (
        name.toLowerCase().includes(searchvalue.toLowerCase()) ||
        fromDate.toLowerCase().includes(searchvalue.toLowerCase()) ||
        toDate.toLowerCase().includes(searchvalue.toLowerCase()) ||
        isActive.toLowerCase() === searchvalue
      );
    });
    if (academicYearList?.academicYear.length == filteredRows.length) {
      filterAcademicYearData({ row: filteredRow, flag: false });
    } else {
      filterAcademicYearData({ row: filteredRow, flag: true });
    }
  };

  const stopEdit = () => {
    setEditRow([]);
    setEdit(false);
  };
  const addFile = (file) => {};

  const closeFilter = () => {
    setAcademicList([
      {
        title: "Sr. No.",
        name: "index",
        positionCenter: true,
        showInscreen: true,
        alignCenter: "center",
      },
      {
        title: "Academic Year Name",
        name: "name",
        positionCenter: false,
        showInscreen: true,
        alignCenter: "left",
      },
      {
        title: "From Date",
        name: "fromDate",
        positionCenter: false,
        showInscreen: true,
        alignCenter: "center",
      },
      {
        title: "To Date",
        name: "toDate",
        positionCenter: false,
        showInscreen: true,
        alignCenter: "center",
      },
      {
        title: "Status",
        name: "isActive",
        positionCenter: true,
        showInscreen: true,
        alignCenter: "left",
      },
      {
        title: "Action",
        name: "action",
        positionCenter: false,
        showInscreen: true,
        alignCenter: "right",
      },
    ]);
    setfilterList(["Academic Year Name", "From Date", "To Date", "Status"]);
    setFilterOpen(false);
  };

  const handleChange = (event) => {
    setFilterOpen(true);
    const {
      target: { value },
    } = event;
    if (value.length > filteredList.length) {
      let row = [];
      const a = academicList.map((qualiData) => {
        let trust = value.filter((namesRow) => namesRow === qualiData.title);

        if (trust.length !== 0) {
          console.log("shraddha");
          let qData = {
            ...qualiData,
            showInscreen: true,
          };
          row.push(qData);
        } else {
          let qData = {
            ...qualiData,
            showInscreen: false,
          };
          row.push(qualiData);
        }

        return setAcademicList(row);
      });
      console.log(row);
    } else {
      let row = [];
      let difference = filteredList.filter((x) => !value.includes(x));
      const qualificationList = academicList.filter((data) => {
        if (data.title === difference[0]) {
          let QualiData = {
            ...data,
            showInscreen: false,
          };
          row.push(QualiData);
        } else {
          row.push(data);
        }
      });
      console.log("row");
      console.log(row);
      setAcademicList(row);
    }

    setfilterList(typeof value === "string" ? value.split(",") : value);

    if (value.length === 0) {
      setFilterOpen(false);
    }
  };

  // const handleChange = (event) => {
  //   setFilterOpen(true);
  //   const {
  //     target: { value },
  //   } = event;

  //   if (value.length > filteredList.length) {
  //     const a = value.map((value) => {
  //       const academic = names.filter((row) => row.title === value);
  //       const academic1 = [...academicList, academic];

  //       return setAcademicList(academic1);
  //     });
  //   } else {
  //     let difference = filteredList.filter((x) => !value.includes(x));
  //     const academicYearList = academicList.filter((data) => {
  //       return data[0].title !== difference[0];
  //     });
  //     setAcademicList(academicYearList);
  //   }

  //   setfilterList(typeof value === "string" ? value.split(",") : value);

  //   if (value.length === 0) {
  //     setFilterOpen(false);
  //   }
  // };
  if (isFetch) {
    return (
      <>
        <div>
          <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
            <TitleAndBreadCrum
              title="Academic Year"
              breadCrumName={breadCrumName}
            />
          </Paper>
          <br />
          {/* {academicYearList.isDelete && academicYearList.error == true && (
            <Alert msg={myConstClass.deleteFailMsg} closeAlert={resetDelete} />
          )}
          {academicYearList.isServerFail && (
            <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />
          )} */}

          {academicYearList.isDelete && academicYearList.error == false ? (
            <DirectionSnackbar
              open={openSnak}
              severity="success"
              msg={myConstClass.deleteMsg}
              closeSnak={setOpenSnak}
            />
          ) : academicYearList.isSave ? (
            <DirectionSnackbar
              open={openSnak}
              severity="success"
              msg={snakMsg}
              closeSnak={setOpenSnak}
            />
          ) : (
            ""
          )}
          <Paper sx={{ p: 2, pt: 4, borderRadius: 2 }} elevation={0}>
            <div>
              {viewRow.length >= 0 && editRow.length >= 0 && isEdit && (
                <AcademicYearForm
                  OnSave={data}
                  isEdit={editRow}
                  onCancel={stopEdit}
                  saveAndNext={saveAndNextData}
                  handleFile={addFile}
                />
              )}
            </div>

            {open && (
              <ViewInst data={viewRow} openparam={open} closeView={setOpen} />
            )}

            {!isEdit && (
              <div>
                {width > breakpoint ? (
                  <>
                    <Box
                      sx={{ mr: 2 }}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Box sx={{ m: -1, width: 300 }}>
                        <Search
                          filteredRows={filteredRows}
                          searchValue={searchValue}
                        />
                      </Box>
                      <Box>
                        <FormControl sx={{ m: -1, width: 250 }}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={filteredList}
                            name="sdf"
                            size="small"
                            onChange={handleChange}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {names.map((name) => {
                              return (
                                <MenuItem key={name.name} value={name.title}>
                                  <Checkbox
                                    checked={
                                      filteredList.indexOf(name.title) > -1
                                    }
                                  />
                                  <ListItemText primary={name.title} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>

                        {filteropen && (
                          <>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <AcademicExcelData
                              csvData={academicYearFilteredData}
                              instList={academicList}
                              fileName="Academic Year"
                              type="pdf"
                            />
                          </>
                        )}
                        {filteropen && (
                          <>
                            &nbsp;&nbsp;
                            <AcademicExcelData
                              csvData={academicYearFilteredData}
                              instList={academicList}
                              fileName="Academic Year"
                              type="excel"
                            />
                          </>
                        )}

                        {filteropen && (
                          <>
                            &nbsp;&nbsp;
                            <FontAwesomeIconCompo
                              className="fa-times-circle"
                              color="primary"
                              title="Cancel"
                              baseClassName="fas"
                              onClick={closeFilter}
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <AcademicExcelData
                              csvData={academicYearFilteredData}
                              instList={academicList}
                              fileName="Academic Year"
                              type="pdf"
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;
                            <AcademicExcelData
                              csvData={academicYearFilteredData}
                              instList={academicList}
                              fileName="Academic Year"
                              type="excel"
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;&nbsp;
                            <MyComponentWithIconProps
                              statusImage={AddCircleIcon}
                              color="primary"
                              title=""
                              fontSize="large"
                              sx={{ m: -1 }}
                              onClick={startEdit}
                            />
                          </>
                        )}
                      </Box>
                    </Box>
                    <Box sx={{ mt: 2, p: 1.5 }}>
                      <DynamicTable
                        data={academicYearFilteredData}
                        tableHead={academicList}
                        rowEdit={getRowID}
                        rowDelete={deleteData}
                        rowStatus={rowStatus}
                        showHeadDelete={true}
                        showHeadEdit={true}
                        rowStatusAdmission={rowStatusAdmission}
                      />
                      {/* <AcademicYearTable
                    filteredColumns={academicList}
                    data={academicYearFilteredData}
                    rowEdit={getRowID}
                    rowView={getRowData}
                    rowDelete={deleteData}
                    rowStatus={rowStatus}
                  /> */}
                    </Box>
                  </>
                ) : (
                  <>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      rowSpacing={2}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid xs={12} sm={12} md={12} lg={12} rowSpacing={2}>
                        <Search
                          filteredRows={filteredRows}
                          searchValue={searchValue}
                        />
                      </Grid>
                      <Grid xs={12} sm={12} md={12} lg={12} sx={{ m: 1 }}>
                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={filteredList}
                            name="sdf"
                            size="small"
                            onChange={handleChange}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {names.map((name) => {
                              return (
                                <MenuItem key={name.name} value={name.title}>
                                  <Checkbox
                                    checked={
                                      filteredList.indexOf(name.title) > -1
                                    }
                                  />
                                  <ListItemText primary={name.title} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <Grid
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          sx={{ m: 1, textAlign: "right" }}
                        >
                          {filteropen && (
                            <>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <AcademicExcelData
                                csvData={academicYearFilteredData}
                                instList={academicList}
                                fileName="Academic Year"
                                type="pdf"
                              />
                            </>
                          )}
                          {filteropen && (
                            <>
                              &nbsp;&nbsp;
                              <AcademicExcelData
                                csvData={academicYearFilteredData}
                                instList={academicList}
                                fileName="Academic Year"
                                type="excel"
                              />
                            </>
                          )}

                          {filteropen && (
                            <>
                              &nbsp;&nbsp;
                              <FontAwesomeIconCompo
                                className="fa-times-circle"
                                color="primary"
                                title="Cancel"
                                baseClassName="fas"
                                onClick={closeFilter}
                              />
                            </>
                          )}

                          {!filteropen && filteredList.length >= 0 && (
                            <>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <AcademicExcelData
                                csvData={academicYearFilteredData}
                                instList={academicList}
                                fileName="Academic Year"
                                type="pdf"
                              />
                            </>
                          )}

                          {!filteropen && filteredList.length >= 0 && (
                            <>
                              &nbsp;&nbsp;
                              <AcademicExcelData
                                csvData={academicYearFilteredData}
                                instList={academicList}
                                fileName="Academic Year"
                                type="excel"
                              />
                            </>
                          )}

                          {!filteropen && filteredList.length >= 0 && (
                            <>
                              &nbsp;&nbsp;&nbsp;
                              <MyComponentWithIconProps
                                statusImage={AddCircleIcon}
                                color="primary"
                                title=""
                                fontSize="large"
                                sx={{ m: -1 }}
                                onClick={startEdit}
                              />
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Box sx={{ mt: 2, p: 1.5 }}>
                      <DynamicTable
                        data={academicYearFilteredData}
                        tableHead={academicList}
                        rowEdit={getRowID}
                        rowDelete={deleteData}
                        rowStatus={rowStatus}
                        showHeadDelete={true}
                        showHeadEdit={true}
                        rowStatusAdmission={rowStatusAdmission}
                      />
                      {/* <AcademicYearTable
                    filteredColumns={academicList}
                    data={academicYearFilteredData}
                    rowEdit={getRowID}
                    rowView={getRowData}
                    rowDelete={deleteData}
                    rowStatus={rowStatus}
                  /> */}
                    </Box>
                  </>
                )}
              </div>
            )}

            {!academicYearList.isServerFail &&
            !academicYearList?.isFetchAcYear ? (
              <Loading1 size={50} />
            ) : status && !academicYearList?.isSave ? (
              <Loading1 size={50} />
            ) : save && !academicYearList?.isSave ? (
              <Loading1 size={50} />
            ) : del ? (
              <Loading1 size={50} />
            ) : (
              ""
            )}
          </Paper>
        </div>
      </>
    );
  } else {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }
};
const mapStateToProps = (state) => ({
  academicYearList: state.academicYear,
  isFetch: state.academicYear,
  common: state.common,
  loginUser: state.user,
});

const mapDispatchToProps = {
  getAcademicYear,
  resetSave,
  deleteAcademicYearById,
  saveAcademicYear,
  filterAcademicYearData,
  getAlert,
  logOut,
  resetDelete,
  updateAcademicYearById,
};
export default connect(mapStateToProps, mapDispatchToProps)(AcademicYearMaster);
