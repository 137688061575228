import { Grid, TextField } from "@mui/material";
import { withStyles } from "@mui/styles";
import Header from "../../components/NewHeader";
import NewLanding from "../../CSS/NewLanding.css";
//import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { getOnlineAdmission } from "../Landing/Landing.slice";
import Navbar from '../../components/Navbar'
const styles = (theme) => ({
  hello: {
    position: "absolute",
    left: "15.17%",
    right: "15.08%",
    top: "63.4%",
    bottom: "32.6%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: "normal",
    fontsize: "36px",
    lineheight: "40px",
    color: "#000000",
  },
  buttonC: {
    position: "absolute",

    left: "39.17%",
    right: "39.17%",
    top: "79.7%",
    bottom: "14.8%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: 500,
    fontsize: "18px",
    lineheight: "40px",
    color: "#FFFFFF",
  },
  helper: {
    position: "absolute",
    left: "9.58%",
    right: "9.5%",
    top: "70%",
    bottom: "22%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: "normal",
    fontsize: "20px",
    lineheight: "40px",

    textAlign: "center",

    color: "#666666",
  },
  title: {
    position: "absolute",
    left: "43.42%",
    right: "43.33%",
    top: "57%",
    bottom: "39%",

    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: 500,
    fontsize: "55px",
    lineheight: "40px",
    color: "#000000",
  },
})


const ImportantDates = ({ getOnlineAdmission, onlineadmission,classes }) => {
    useEffect(() => {
      getOnlineAdmission().then(() => {
        console.log(onlineadmission);
      });
    }, []);

  return (
    <>
    <div align="center">
      {/* <Header/> */}
      <Header />
<Navbar/>
      <div className="new_content">
        {/* Online Admission */}
        <h3 className="event_title1">Online Admission List</h3>
        {onlineadmission?.admission.map((data) => {
          return (
            <div className="admission_detail admisdetail">
             
              <div className="admission_det_date date">
                End Date <span>{data.lastDate}</span>
              </div>
              
              <div className="admission_cont detil">
                <h6 className="event_sub_nm">
                  {data.title}
                  {/* <span>Am Univercity 7 April in, Mumbai.</span> */}
                </h6>
              </div>


              <p className="admission_txt">
                {/* Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur. */}
                {ReactHtmlParser(data.detailedDescription)}{" "}{data.startDate}
              </p>


              <a href="/login" className="admission_apply">
                Apply Now
              </a>
            </div>
          );
        })}
      </div>
    </div>
  </>
  );
};

const mapStateToProps = (state) => ({
    onlineadmission: state.landing,
  });
  
  const mapDispatchToProps = {
    getOnlineAdmission,
  };
  

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ImportantDates));
