const endpoint = {
  auth: "/api/auth/loginonline",
  religion: "/api/application/v1/religion",
  category: "/api/application/v1/category",
  university: "/api/application/v1/university",
  document: "/api/application/v1/document",
  academicYear: "/api/application/v1/academic-year",
  newAcademicYear: "/api/application/v1/next-year-admission-academic-year",
  institute: "/api/config/v1/tenant",
  class: "/api/application/v1/year",
  classDetails: "/api/application/v1/year-detail",
  course: "/api/application/v1/program-title",
  programType: "/api/application/v1/program",
  program: "/api/application/v1/program-type",
  ClassIntakeDetail: "/api/application/v1/class-intake-detail",
  qualification: "/api/application/v1/qualification",
  instituteCourse: "/api/application/v1/institute-program-title",
  fileUpload: "/api/document/v1/file/upload",
  fileUploadPreLogin: "/api/document/v1/file/uploadPublicFile",
  fileDownload: "/api/document/v1/file/getFileUrl",
  applicationCap: "/api/application/v1/application-declare-application-round",
  fileDelete: "/api/document/v1/file/deleteFile",
  application: "/api/application/v1/application-declare",
  directAdmission: "/api/application/v1/application-declare-direct-admission",
  catSeatIntakeDetail: "/api/application/v1/category-seat-intake-header",
  newsDocNotification: "/api/application/v1/news-document-notification",
  reservCategory: "/api/application/v1/reservation-structure",
  applicant: "/api/application/v1/user-application",
  userapplicant: "/api/application/v1/user-registration",
  profileInfo: "/api/application/v1/profile-info",
  userRegistration: "/api/application/v1/user-registration",
  generateOTP: "/api/config/prelogin/otp/generate-otp?emailId=",
  studentRegister: "/api/config/prelogin/applicant-user-registration",
  emailEmail: "/api/config/prelogin/otp/",
  validateOtp: "/api/config/prelogin/otp/validate-otp?otp=",
  studentUser: "/api/config/prelogin/forgot-user-id?",
  validateUserIdOtp: "/api/config/prelogin/forgot-user-id/validate-otp?",
  userPassword: "/api/config/prelogin/forgot-password?",
  passwordOtp: "/api/config/prelogin/forgot-password/validate-otp?",
  resetPassword: "/api/config/prelogin/forgot-password/reset-password?",
  bloodGroup: "/api/application/v1/blood-group",
  annualIncome: "/api/application/v1/annual-income",
  state: "/api/application/v1/state",
  district: "/api/application/v1/district",
  generatemeritlist: "/api/application/v1/application-merit-header",
  addcapround: "/api/application/v1/application-round",
  instituteList:
    "/api/application/v1/application-declare/distinct-institute-by-applicationid-and-program-typeid-and-program-titleid",
  programTitleList:
    "/api/application/v1/application-declare/distinct-program-title-by-applicationid-and-program-typeid-and-instituteid",
  roundDetail: "/api/application/v1/application-declare",
  confirmPassword: "/api/config/v1/user/validatePassword",
  preferenceSave: "/api/application/v1/user-application-round-detail",
  scheduleform: "/api/application/v1/round-schedule",
  taluka: "/api/application/v1/taluka",
  getUserApplication:
    "/api/application/v1/user-application/get-user-application-by-applicationid-and-user-id",
  getRoundDetails:
    "/api/application/v1/user-application-round-detail/get-user-application-round-detail-by-user-application-id?userApplicationId=",
  getCaproundDetail:
    "/api/application/v1/user-application-round-detail/get-user-application-round-detail-by-round-id",
  getNewsDocs: "/api/application/prelogin/news-document-notifications",
  getOnlineAdmission: "/api/application/prelogin/live-applications",
  getAllApplication:
    "/api/application/v1/application-declare-application-round",
  getApplicationRoundByAppliId:
    "/api/application/v1/user-application-round-detail/get-user-application-round-detail-by-user-application-id",
  generateSeatAllotURL: "/api/application/v1/generateseatallotmentprocess",
  seatAcceptLetter:
    "/api/application/v1/user-round-allocation/get-seat-alloted",
  seatAcceptLetterURL: "/api/application/v1/user-round-allocation",
  userAppReport: "/api/report/v1/trail-image/generate-image",
  meritListPdf: "/api/report/v1/merit-list-report",
  seatAllotmentListPdf: "/api/report/v1/seat-allotment-report",
  waitingListPdf: "/api/report/v1/waiting-list-report",
  userSeatAllotedPdf: "/api/report/v1/user-seat-alloted-report",
  userApplicationFormPdf: "/api/report/v1/user-application-report",
  parentMobile: "/api/config/v1/user/check-parent-no",
  paymentUrl: "/api/payment/v1/payment",
  userDirectAdmission: "/api/application/v1/user-application-direct-admission",
  paymentTransaction: "/api/application/v1/payment-transaction",
};
export default endpoint;
