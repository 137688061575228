import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { decodeData } from "../../components/Comman/Util/Base64";
import endpoints from '../../config/endpoints';
import { tokenExp } from "../Login/login.slice";
const initialState = {
  reservCat: [],
  isFetch: false,
  reservCatFilter: [],
  isFilter: false,
  reservCatDetails: {},
  isSave: false,
  error: false,
  isServerFail:false,
  isDelete:false,
};
let URL = endpoints.reservCategory;
const reservationSlice = createSlice({
  name: "reservCat",
  initialState,
  reducers: {
    resetDeleteSuccess : (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error:false,
        isDelete: false,
        isSave: false,
        isServerFail:false,

      };
    },

    reservSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload
      return {
        ...state,
        reservCat: row,
        isFetch: true,
        isDelete:false,
        error:false,
      };
    },
    reservationDetailsSuccess: (state = cloneDeep(initialState), action) => {

      const { data } = action.payload
      return {
        ...state,
        reservCatDetails: data,
        isSave: true,
        isDelete:false,
        error:false,
      };
    },
    reservCatFilterSuccess: (state = cloneDeep(initialState), action) => {

      const { row } = action.payload
      return {
        ...state,
        reservCatFilter: row,
        isFilter: true,
        isDelete:false,
        error:false,
      };
    },
    reservCatFilterFailure: (state = cloneDeep(initialState), action) => {

      return {
        ...state,
        reservCatFilter: [],
        isFilter: false,
        isDelete:false,
        error:false,
      };
    },
    reservationDetailsFail: (state = cloneDeep(initialState), action) => {

      return {
        ...state,
        reservCatDetails: {},
        isSave: false,
        isDelete:false,
        error:false,
      };
    },
    reservFailure: (state = cloneDeep(initialState), action) => {

      return {
        ...state,
        reservCat: [],
        isFetch: false,
        isDelete:false,
        error:false,
      };
    },
    serverFailed: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: true,
        error: false,
        isDelete:false,
      };
    },
  },
});

export const {serverFailed,resetDeleteSuccess, reservSuccess, reservationDetailsFail, reservCatFilterSuccess, reservationDetailsSuccess, reservFailure } = reservationSlice.actions;

export default reservationSlice.reducer;

export const getReservDetails = () => async (dispatch) => {
  try {

    const name = localStorage.getItem("token");
    const token = decodeData(name);

    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get(URL+ '?sort={"insertdatetime": "DESC"}', { headers })
      .then((response) => {
        console.log(response);
        const {error}=response.data
        if(error==true){
          dispatch(tokenExp());
          
        }
        else{
          const { content } = response.data.data;
          let row = [];
          let index = 0

          content.map((data1) => {
            index = index + 1
            let bData = {
              index: index,
              id: data1.id,
              malePer: data1.malePer,
              isActive: data1.isActive === 1 ? "Active" : "Inactive",
              famalePer: data1.famalePer,
              generalPer: data1.generalPer,
              otherPer: data1.otherPer,
              reservationStructureDetail: data1.reservationStructureDetail,
              name: data1.name,

            };
            row.push(bData);
          });
          dispatch(reservSuccess({ row }));
          return row;
        }
      })

      .catch((error) => {
        dispatch(serverFailed());
        console.log("Error getbranch========>", error);
        return false
      });
      return doc;
  } catch (e) {
    dispatch(serverFailed());
    return false
  }
};

export const deleteReservCatData = () => async (dispatch) => {
  try {


  }
  catch (e) {
    dispatch(serverFailed());
    return console.error(e.message);
  }
};
export const resetSave = () => async (dispatch) => {
  try {

    dispatch(reservationDetailsFail());

  }
  catch (e) {
    return console.error(e.message);
  }
};

export const getReservCatById = ({ catId }) => async (dispatch) => {
  try {

    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
   const doc = await axios
      .get(URL + "/" + catId, { headers })
      .then((response) => {
        console.log("Success With id catId ========>", response);
        const {error} = response.data
        if(error==true){
          dispatch(tokenExp());
          
        }
      })
      .catch((error) => {
        dispatch(serverFailed());
        return false
      });
      return doc;
  } catch (e) {
    dispatch(serverFailed());
    return false
  }
};

export const saveReservCat =
  ({ reservCat }) =>
    async (dispatch) => {
      try {
        let data = JSON.stringify(reservCat);


        const name = localStorage.getItem("token");
        const token = decodeData(name);
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
          shouldrefresh: "true",
        };
       const doc = await axios
          .post(URL, data, { headers })
          .then((response) => {
            console.log("Success With saveReservCat ========>", response.data);
            const {error}=response.data
            if(error==true){
              dispatch(tokenExp());
              
            }
            else{
            const { data } = response.data
            dispatch(reservationDetailsSuccess({ data }));
            return data
            }
          })
          .catch((error) => {
            dispatch(serverFailed());
            return false
          });
          return doc;
      } catch (e) {
        dispatch(serverFailed());
        return false
      }
    };

export const deleteReservCatById =
  ({ reservCatId }) =>
    async (dispatch) => {
      try {

        const name = localStorage.getItem("token");
        const token = decodeData(name);
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
          shouldrefresh: "true",
        };
       const doc = await axios
          .delete(URL + "/" + reservCatId, { headers })
          .then((response) => {
            console.log(
              "Success With deleteReservCatById ========>",
              response
            );
            const {error} = response.data
            if(error==true){
              dispatch(tokenExp());
              
            }
          })
          .catch((error) => {
            console.log("Error deleteReservCatById ========>", error.response.data);
            dispatch(serverFailed());
            return false
          });
          return doc;
      } catch (e) {
        dispatch(serverFailed());
        return false
      }
    };
export const filterReservCatData = ({ row, flag }) => async (dispatch) => {
  try {

    if (flag === true) {
      dispatch(reservCatFilterSuccess({ row }));
    }
    else {
      dispatch(reservSuccess({ row }));
    }
  }
  catch (e) {
  }
};

export const resetDelete = () => async (dispatch) => {
  try {
    dispatch(resetDeleteSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};