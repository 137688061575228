import { FormHelperText, Grid, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import { isEmpty } from "lodash-es";
import React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import MultipleSelect from "../../components/Comman/MultipleSelect";
import * as myConstClass from "../../config/messageconstant";
import {
  getbranch,
  getProgramTitleByProgramIdAndInstId,
} from "../BranchMaster/branch.slice";
import {
  deleteBranchList,
  getBranchByProgId,
  getProgram,
} from "../ProgramMaster/program.slice";
import {
  deleteProgList,
  getProgramByProgType,
  getProgramType,
} from "../ProgramType/programType.slice";
import { deleteDeclrationData } from "./directAdmission.slice";
import { getClassByProgTypeId } from "../Class/class.slice";
import { getInstituteByTrustId } from "../Institute/institute.slice";
import { getAlert } from "../../Alert/alert.slice";

let docs;
let cList = [];
class SelectionOfInstitute extends React.Component {
  state = {
    id: 0,
    typeOfProgram: "",
    institute: "",
    courses: [],
    program: "",
    formErrors: [],
    className: "",
  };

  changeHandler = (name, newValue) => {
    if (newValue !== null) {
      this.setState({
        ...this.state,
        [name]: newValue,
      });
      console.log(name);

      const {
        getProgramByProgType,
        getBranchByProgId,
        getAlert,
        getProgramTitleByProgramIdAndInstId,
      } = this.props;
      if (name === "typeOfProgram") {
        getProgramByProgType({ progType: newValue }).then((response) => {
          if (!response) {
            getAlert({ message: myConstClass.serverMsg });
          }
        });
      }
      if (name === "program") {
        this.props
          .getClassByProgTypeId({ progTypeId: newValue })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: myConstClass.serverMsg });
            }
          });
      }

      if (name === "className") {
        if (this.state.institute !== "" && this.state.program !== "") {
          getProgramTitleByProgramIdAndInstId({
            instId: this.state.institute,
            programId: this.state.program,
            yearId: newValue,
          }).then((response) => {
            if (!response) {
              getAlert({ message: myConstClass.serverMsg });
            }
          });
        }
      }
    } else {
      this.setState({
        ...this.state,
        [name]: "",
      });
    }
  };

  componentDidMount() {
    const {
      getProgramType,
      getProgram,
      getbranch,
      getClassByProgTypeId,
      appliData,
      getAlert,
      getInstituteByTrustId,
      getProgramTitleByProgramIdAndInstId,
    } = this.props;
    this.setState({
      id: appliData.applicationData.id,
    });
    if (
      appliData.applicationData !== "" &&
      appliData.applicationData.type >= 2
    ) {
      this.setState({
        id: appliData.applicationData.id,
        typeOfProgram: appliData.applicationData.programId,
        institute: appliData.applicationData.tenantId,
        courses: appliData.applicationData.programTitleIds.split(","),
        program: appliData.applicationData.programTypeId,
        className: appliData.applicationData.yearId,
      });
      getClassByProgTypeId({
        progTypeId: appliData.applicationData.programTypeId,
      }).then((response) => {
        if (!response) {
          getAlert({ message: myConstClass.serverMsg });
        }
      });

      if (
        appliData.applicationData.programId !== "" &&
        appliData.applicationData.tenantId !== ""
      ) {
        getProgramTitleByProgramIdAndInstId({
          instId: appliData.applicationData.tenantId,
          programId: appliData.applicationData.programTypeId,
          yearId: appliData.applicationData.yearId,
        }).then((response) => {
          if (!response) {
            getAlert({ message: myConstClass.serverMsg });
          }
        });
      }
    }

    getbranch().then((response) => {
      if (!response) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });

    getInstituteByTrustId().then((doc) => {
      if (!doc) {
        // getAlert({ message: myConstClass.serverMsg });
      }
    });

    getProgram().then((response) => {
      if (!response) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
    getProgramType().then((response) => {
      if (!response) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
  }
  multiSelectHandler = (value, name) => {
    this.setState({
      ...this.state,
      [name]: typeof value === "string" ? value.split(",") : value,
    });
  };
  handleFormValidation(name) {
    const { typeOfProgram, program, courses, className, institute } =
      this.state;
    let formErrors = {};
    let formIsValid = true;

    if (typeOfProgram === "" || typeOfProgram === null) {
      formIsValid = false;
      formErrors["typeOfProgramErr"] = myConstClass.typeOfProgramMsg;
    }

    if (institute === "" || institute === null) {
      formIsValid = false;
      formErrors["instituteErr"] = myConstClass.instituteErr;
    }

    if (isEmpty(program)) {
      formIsValid = false;
      formErrors["programErr"] = myConstClass.progNameMsg;
    }
    if (isEmpty(courses)) {
      formIsValid = false;
      formErrors["courseErr"] = myConstClass.CourseMsgg;
    }

    if (className === "" || className === null) {
      formIsValid = false;
      formErrors["classErr"] = myConstClass.classNameMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const { id, typeOfProgram, program, courses, className, institute } =
      this.state;

    var coursesData = courses.join();

    docs = {
      id: id === 0 ? 0 : id,
      programId: typeOfProgram,
      tenantId: institute,
      programTypeId: program,
      programTitleIds: coursesData,
      yearId: className,
      type: 2,
    };

    if (this.handleFormValidation()) {
      this.props.OnSave(docs);
    }
  };

  componentDidUpdate() {
    const { progList, deleteBranchList } = this.props;

    if (progList.isFetchCourse === true) {
      cList = [];
      progList.course.map((br) => {
        let data = {
          name: br.brName,
          id: br.id,
        };
        cList.push(data);
        return data;
      });

      deleteBranchList();
    }
  }
  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
      if (name === "program") {
        this.setState({
          courses: [],
        });
        this.props
          .getClassByProgTypeId({ progTypeId: newValue })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: myConstClass.serverMsg });
            }
          });
      }
    } else {
      this.setState({ [name]: "" });
    }
  };
  render() {
    const { typeOfProgram, program, courses, className, institute } =
      this.state;
    const { typeOfProgramErr, programErr, courseErr, classErr, instituteErr } =
      this.state.formErrors;
    const {
      onCancel,
      progList,
      branchList,
      progTypeList,
      classList,
      instituteList,
    } = this.props;
    progList.program.map((data) => {
      console.log(data.progTypeId);
      console.log(data.id);
    });
    console.log(typeOfProgram);
    console.log(program);
    console.log(classList);
    return (
      <>
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <form
            onSubmit={this.submitHandler}
            autoComplete="off"
            noValidate={true}
          >
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={2}
            >
              <Grid
                item
                sm={6}
                xs={12}
                md={6}
                lg={6}
                // rowSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Select Institute : "
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={institute}
                    name={"institute"}
                    options={instituteList?.instituteByTrustId}
                    onChange={this.changeHandler}
                  />
                  {instituteErr && (
                    <FormHelperText error>{instituteErr} </FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid
                item
                sm={6}
                xs={12}
                md={6}
                lg={6}
                // rowSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Select program : "
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={typeOfProgram}
                    name={"typeOfProgram"}
                    options={progTypeList.programType}
                    onChange={this.changeHandler}
                  />
                  {programErr && (
                    <FormHelperText error>{programErr} </FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid
                item
                sm={6}
                xs={12}
                md={6}
                lg={6}
                // rowSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Select program type : "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={program}
                    name={"program"}
                    options={
                      typeOfProgram !== ""
                        ? progList.program.filter(
                            (prog) => prog.progTypeId === typeOfProgram
                          )
                        : progList.program
                    }
                    onChange={this.changeHandler}
                  />
                  {typeOfProgramErr && (
                    <FormHelperText error>{typeOfProgramErr} </FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid
                item
                sm={6}
                xs={12}
                md={6}
                lg={6}
                // rowSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Select Year :"
                    sx={{ m: 1 }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={className}
                    name={"className"}
                    options={classList?.getClassByProgType}
                    onChange={this.changeHandler}
                  />
                  <FormHelperText error>{classErr}</FormHelperText>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Select program title : "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <MultipleSelect
                    er={courseErr}
                    name1="courses"
                    value1={courses}
                    names={branchList.programTitleByProgramIdAndInstId}
                    onChange={this.multiSelectHandler}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              m={2}
              rowSpacing={1}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={1}
                lg={1}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Back"
                  fullWidth={true}
                  onClick={onCancel}
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={2.5}
                lg={2.5}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  fullWidth={true}
                  name="Save and Proceed"
                />
              </Grid>
            </Grid>
          </form>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  branchList: state.branch,
  progList: state.program,
  progTypeList: state.programType,
  appliData: state.directAdmission,
  classList: state.class,
  instituteList: state.institute,
});

const mapDispatchToProps = {
  getProgramByProgType,
  getProgram,
  getProgramType,
  deleteProgList,
  getbranch,
  deleteBranchList,
  deleteDeclrationData,
  getBranchByProgId,
  getClassByProgTypeId,
  getAlert,
  getInstituteByTrustId,
  getProgramTitleByProgramIdAndInstId,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectionOfInstitute);
