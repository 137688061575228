import React from "react";
import ExportCSV from "../../components/Comman/ExportCSV";
import { generatePDF } from "../../components/Comman/GeneratePDF";
import { FontAwesomeIconCompo } from "../../components/Comman/IconButton";

const ClassExcelData = ({ csvData, classesList, fileName, type }) => {
  const tablecol = classesList.map((title) => {
    return title[0];
  });

  let index = 0;
  const ClassData = csvData.map((csvData) => {
    index = index + 1;
    let inst = {
      "Sr. No.": index,
    };

    if (classesList.length != 4) {
      classesList.map((title) => {
        if (title[0].name == "progName") {
          inst = {
            ...inst,
            "Program Type": csvData.progName,
          };
        }

        if (title[0].name == "name") {
          inst = {
            ...inst,
            "Program Name": csvData.name,
          };
        }
        if (title[0].name == "tenantName") {
          inst = {
            ...inst,
            "Institute Name": csvData.tenantName,
          };
        }
        if (title[0].name == "sortOrderNo") {
          inst = {
            ...inst,
            "Sort Order No": csvData.sortOrderNo,
          };
        }

        if (title[0].name == "isActive") {
          inst = {
            ...inst,
            Status: csvData.isActive,
          };
        }
      });
    } else {
      inst = {
        ...inst,
        "Programe Type Name": csvData.progName,
        "Class Name": csvData.name,
        "Institute Name": csvData.tenantName,
        "Sort Order No": csvData.sortOrderNo,
        "Active Status": csvData.isActive,
      };
    }
    return inst;
  });

  if (type === "pdf") {
    return (
      <FontAwesomeIconCompo
        color="error"
        fontSize="small"
        title="PDF"
        baseClassName="fas"
        className="fa-file-pdf"
        onClick={() => generatePDF(ClassData, tablecol, "Class Master")}
      />
    );
  }

  return <ExportCSV csvData={ClassData} fileName={fileName} />;
};
export default ClassExcelData;
