import { Grid, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import * as React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import { Loading1 } from "../../components/Loading1";
import * as myConstClass from "../../config/messageconstant";
import { getProgram } from "../ProgramMaster/program.slice";
import { getAlert } from "../../Alert/alert.slice";

import {
  deleteBranchData,
  getbranchByName,
  getbranchByShortName,
} from "./branch.slice";
let docs;
class BranchForm extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    id: 0,
    progId: "",
    trustId: "",
    branchName: "",
    branchShortName: "",
    courseCode: "",
    flag: 0,
    dsAdmmission: "1",
    isActive: "1",
    formErrors: [],
  };
  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  componentDidMount() {
    const { isEdit, getProgram, programList, branch } = this.props;
    getProgram().then((respo) => {
      if (!respo) {
        this.props.getAlert({ message: myConstClass.serverMsg });
      }
    });
    let trustId = localStorage.getItem("trustId");
    this.setState({
      trustId: trustId,
    });

    if (isEdit !== undefined && branch?.isFetchBranchById === true) {
      this.setState({
        id: isEdit.id,
        progId: isEdit.programType.id,
        programName: isEdit.programType.name,
        branchName: isEdit.brName,
        branchShortName: isEdit.brShortName,
        courseCode: isEdit.courseCode,
        dsAdmmission: isEdit.dsAdmission,
        isActive: isEdit.isActive,
      });
    }
  }

  componentDidUpdate() {
    if (this.props.branch.isFetch && this.props.branch.isFetchShortName) {
      const {
        id,
        progId,
        trustId,
        branchName,
        branchShortName,
        dsAdmmission,
        isActive,
        flag,
        courseCode,
      } = this.state;

      docs = {
        programType: {
          id: progId,
        },
        brName: branchName,
        brShortName: branchShortName,
        courseCode: courseCode,
        dsAdmission: 1,
        isActive: isActive,
        delStatus: 1,
        trust: {
          id: trustId,
        },
      };

      if (id != 0) {
        docs = {
          ...docs,
          id: id,
        };
      }

      if (
        this.props.branch.branchData.length == 0 &&
        this.props.branch.ShortNameData.length == 0
      ) {
        if (flag == 1) {
          this.props.saveAndNext(docs);
          this.setState({
            id: 0,
            progId: "",
            branchName: "",
            branchShortName: "",
            courseCode: "",
            dsAdmmission: "1",
            isActive: "0",
            formErrors: [],
            flag: 0,
          });
        } else if (flag == 2) {
          this.props.OnSave(docs);
        }
      } else {
        let formErrors = {};
        if (this.props.branch.ShortNameData.length != 0) {
          formErrors["branchShortNameErr"] = myConstClass.branchShortNameUniMsg;
        }
        if (this.props.branch.branchData.length != 0) {
          formErrors["branchNameErr"] = myConstClass.branchUniMsg;
        }

        this.setState({ formErrors: formErrors });
      }
      this.props.deleteBranchData();
    }
  }
  handleFormValidation(name) {
    const { progId, branchName, branchShortName, courseCode } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (progId === "" || progId === null) {
      formIsValid = false;
      formErrors["progNameErr"] = myConstClass.programTypeMsg;
    }

    if (branchName.trim() === "") {
      formIsValid = false;
      formErrors["branchNameErr"] = myConstClass.branchNameMsg;
    }

    if (branchShortName.trim() === "" && name != "branchShortName") {
      formIsValid = false;
      formErrors["branchShortNameErr"] = myConstClass.branchShortNameMsg;
    }

    if (courseCode.trim() === "") {
      formIsValid = false;
      formErrors["courseCodeErr"] = myConstClass.courseCodeMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  saveHandler = (e) => {
    e.preventDefault();
    this.setState({
      flag: 1,
    });
    const {
      id,
      dsAdmmission,
      isActive,
      progId,
      branchName,
      trustId,
      branchShortName,
      courseCode,
    } = this.state;

    docs = {
      id: id,
      programType: {
        id: progId,
      },
      brName: branchName,
      brShortName: branchShortName,
      courseCode: courseCode,
      dsAdmission: 1,
      isActive: isActive,
      delStatus: 1,
      trust: {
        id: trustId,
      },
    };

    if (this.handleFormValidation()) {
      this.setState({
        flag: 1,
      });
      this.props
        .getbranchByName({ id: id, branchName: branchName.trim() })
        .then((respo) => {
          if (!respo) {
            this.props.getAlert({ message: myConstClass.serverMsg });
          }
        });
      this.props
        .getbranchByShortName({
          id: id,
          shortName: branchShortName.trim(),
        })
        .then((respo) => {
          if (!respo) {
            this.props.getAlert({ message: myConstClass.serverMsg });
          }
        });
    }
  };

  submitHandler = (e) => {
    e.preventDefault();
    this.setState({
      flag: 2,
    });
    const { id, branchName, branchShortName, courseCode } = this.state;

    if (this.handleFormValidation()) {
      this.props
        .getbranchByName({ id: id, branchName: branchName.trim() })
        .then((respo) => {
          if (!respo) {
            this.props.getAlert({ message: myConstClass.serverMsg });
          }
        });
      this.props
        .getbranchByShortName({
          id: id,
          shortName: branchShortName.trim(),
        })
        .then((respo) => {
          if (!respo) {
            this.props.getAlert({ message: myConstClass.serverMsg });
          }
        });
    }
  };
  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };
  render() {
    const {
      progId,
      branchName,
      branchShortName,
      dsAdmmission,
      flag,
      courseCode,
    } = this.state;

    const { progNameErr, branchNameErr, branchShortNameErr, courseCodeErr } =
      this.state.formErrors;
    const { onCancel, programList, branch } = this.props;
    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            <Grid
              item
              xs={12}
              md={12}
              size="small"
              container
              justifyContent="flex-end"
            >
              <ButtonCompo
                size="small"
                type="button"
                variant="outlined"
                name=" Back to List"
                onClick={onCancel}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Select Program Type : "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} mt={-2}>
                  <AutoComplete
                    keyColName={"id"}
                    value={progId}
                    name={"progId"}
                    options={programList?.program}
                    onChange={this.ChangeHandlerSearch}
                  />
                  <FormHelperText error>
                    {progNameErr ? progNameErr : " "}{" "}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Program Title Name :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    size="small"
                    color="primary"
                    name="branchName"
                    id="branchName"
                    value={branchName}
                    onChange={this.changeHandler}
                    fullWidth
                    error={branchNameErr ? true : false}
                    helperText={branchNameErr ? branchNameErr : " "}
                  ></TextField>
                </Grid>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={12}></Grid> */}
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Short Name : "
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    size="small"
                    color="primary"
                    name="branchShortName"
                    id="branchShortName"
                    value={branchShortName}
                    onChange={this.changeHandler}
                    fullWidth
                    error={branchShortNameErr ? true : false}
                    helperText={branchShortNameErr ? branchShortNameErr : " "}
                  ></TextField>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Course Code : "
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    size="small"
                    color="primary"
                    name="courseCode"
                    id="courseCode"
                    value={courseCode}
                    onChange={this.changeHandler}
                    fullWidth
                    error={courseCodeErr ? true : false}
                    helperText={courseCodeErr ? courseCodeErr : " "}
                  ></TextField>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                {/*<Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="*Direct Admission :"
              />
            </Grid>
            

            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="Direct Admission"
                  id="dsAdmmission"
                  name="dsAdmmission"
                  value={dsAdmmission}
                  onChange={this.changeHandler}
                  size="small"
                >
                  <FormControlLabel value="1" control={<Radio />} label="Yes" />
                  <FormControlLabel value="0" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>*/}
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={1.2}
                lg={1.2}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Save"
                  fullWidth={true}
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={1.8}
                lg={1.8}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Save and Next"
                  fullWidth={true}
                  onClick={this.saveHandler}
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={1.2}
                lg={1.2}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Cancel"
                  fullWidth={true}
                  onClick={onCancel}
                />
              </Grid>
            </Grid>
          </Grid>
          {flag != 0 &&
            !branch.isFetchBranch &&
            !branchNameErr &&
            !branchShortNameErr && <Loading1 size={50} />}
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  programList: state.program,
  common: state.common,
  branch: state.branch,
});

const mapDispatchToProps = {
  getProgram,
  getbranchByName,
  deleteBranchData,
  getbranchByShortName,
  getAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(BranchForm);
