import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { decodeData } from "../../components/Comman/Util/Base64";
import endpoints from "../../config/endpoints";
import { tokenExp } from "../Login/login.slice";
const initialState = {
  appli: [],
  applicant: [],
  applicationData: {},
  appliData: {},
  ShortNameData: [],
  LiveFormsList: [],
  scheduleFormsList: [],
  closedFormsList: [],
  documentData: [],
  courseData: [],
  isFetch: false,
  isFetchSave: false,
  isFetchLiveForm: false,
  isFetchScheduleForm: false,
  isFetchClosedForm: false,
  isFetchApplicant: false,
  isFetchShortName: false,
  isFetchApplication: false,
  error: false,
  isServerFail: false,
  isDelete: false,
  isDataFetch: false,
  isSave: false,
  isFetchRoundList: false,
  roundDetailsList: [],
  isFetchProgramTitleByApplicationId: false,
  programTitleByApplicationId: [],
  isFetchApplicantDetailsList: false,
  applicantDetailsList: [],
  isFetchApplicationDeclrationStudentSide: false,
  applicationDeclrationStudentSide: [],
  isFecthDirectAdmissionDeclrationById: false,
  directAdmissionDeclrationById: {},
};
let URL = endpoints.directAdmission;
let URLAppCap = endpoints.applicationCap;
let URL1 = endpoints.addcapround;
let URL8 = endpoints.getRoundDetails;
let URL9 = endpoints.userDirectAdmission;

const directAdmissionSlice = createSlice({
  name: "directAdmissionSlice",
  initialState,
  reducers: {
    appliSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        isFetch: true,
        appli: row,
      };
    },
    appliLiveSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        isFetchLiveForm: true,
        LiveFormsList: row,
      };
    },

    appliScheduleSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        isFetchScheduleForm: true,
        scheduleFormsList: row,
      };
    },
    appliClosedSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        isFetchClosedForm: true,
        closedFormsList: row,
      };
    },
    applicatonSuccess: (state = cloneDeep(initialState), action) => {
      const { data1 } = action.payload;
      return {
        ...state,
        isFetchApplication: true,
        applicationData: data1,
      };
    },
    resetSave: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isFetchSave: false,
        appliData: {},
        isFetchApplication: false,
        applicationData: {},
      };
    },
    appliFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isFetch: false,
        appli: [],
      };
    },
    applicationDocumentDetails: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        isFetchApplication: true,
        documentData: row,
      };
    },
    applicantSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        isFetchApplicant: true,
        applicant: row,
      };
    },
    applicantFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isFetchApplicant: false,
        applicant: [],
      };
    },
    appliSaveSuccess: (state = cloneDeep(initialState), action) => {
      const { applicationDeclareResult } = action.payload;
      return {
        ...state,
        isFetchSave: true,
        appliData: applicationDeclareResult,
      };
    },
    appliSaveFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isFetchSave: false,
        appliData: {},
      };
    },
    appliSave: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isFetch: false,
      };
    },

    courseDetails: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        courseData: data,
      };
    },
    resetServerSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: false,
        error: false,
        isDelete: false,
      };
    },
    resetDeleteSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error: false,
        isDelete: false,
        isSave: false,
      };
    },
    serverFailed: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: true,
        error: false,
        isDelete: false,
      };
    },
    applicantRoundDetailsListSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        isFetchRoundList: true,
        roundDetailsList: row,
      };
    },
    programTitleByApplicationIdSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        isFetchProgramTitleByApplicationId: true,
        programTitleByApplicationId: row,
      };
    },

    applicantDetailsListSuccess: (state = cloneDeep(initialState), action) => {
      const { row1 } = action.payload;
      return {
        ...state,
        isFetchApplicantDetailsList: true,
        applicantDetailsList: row1,
      };
    },

    naviLogin: (state = cloneDeep(initialState), action) => {
      return {
        ...state,

        appli: [],
      };
    },
    applicationDeclrationStudentSideSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        isFetchApplicationDeclrationStudentSide: true,
        applicationDeclrationStudentSide: row,
      };
    },

    directAdmissionDeclrationByIdSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { data1 } = action.payload;
      return {
        ...state,
        isFecthDirectAdmissionDeclrationById: true,
        directAdmissionDeclrationById: data1,
      };
    },
  },
});

export const {
  appliSuccess,
  appliLiveSuccess,
  appliScheduleSuccess,
  appliClosedSuccess,
  appliSave,
  appliFailure,
  resetSave,
  appliSaveSuccess,
  applicantSuccess,
  applicantFailure,
  appliSaveFailure,
  applicationDocumentDetails,
  appliListByShortNameFailure,
  appliListByShortNameSuccess,
  applicantRoundDetailsListSuccess,
  appliListByNameFailure,
  appliListByNameSuccess,
  applicatonSuccess,
  courseDetails,
  resetServerSuccess,
  resetDeleteSuccess,
  serverFailed,
  programTitleByApplicationIdSuccess,
  applicantDetailsListSuccess,
  applicationDeclrationStudentSideSuccess,
  directAdmissionDeclrationByIdSuccess,
} = directAdmissionSlice.actions;

export default directAdmissionSlice.reducer;

export const getDeclration = () => async (dispatch) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);

    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get(URL + "/by-user-registration-id", { headers })
      .then((response) => {
        console.log(response);
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          let data = response.data.data;
          let row = [];
          let index = 0;
          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              title: data1.title,
              startDate: data1.startDate,
              isActive: data1.isActive === 1 ? "Active" : "Inactive",
              tsId: data1.tsId,
              shortSummary: data1.shortSummary,
              paymentType: data1.paymentType,
              program: data1.program,
              programTitleIds: data.programTitleIds,
              year: data1.year,
              undertaking: data1.undertaking,
              showNotificationOnscreen: data1.showNotificationOnscreen,
              //     programType: data1.programType,
              programTypeId: data1.programTypId,
              //     programId: data1.programId,
              isFreez: data1.isFreez,
              paymentInstruction: data1.paymentInstruction,
              paymentApplicable: data1.paymentApplicable,
              lableText: data1.lableText,
              intrestedPrograms:
                data1.program.name + " - " + data1.programTypeId.name,
              lastDate: data1.lastDate,
              highlightLable: data1.highlightLable,
              instructionPrintOnChallan: data1.instructionPrintOnChallan,
              meritListPublishedDate: data1.meritListPublishedDate,
              //     classInfoId: data1.classInfoId,
              //     className: data1.className,
              detailedDescription: data1.detailedDescription,
              undertaking: data1.undertaking,
              applicationDate: data1.applicationDate,
              amount: data1.amount,
              //  applicationRound: data1.applicationRound.name,
            };
            row.push(bData);
          });

          dispatch(appliSuccess({ row }));
          return row;
        }
      })

      .catch((error) => {
        console.log("Error getDeclration========>", error);
        dispatch(serverFailed());
        return false;
      });
    return doc;
  } catch (e) {
    dispatch(serverFailed());
    return false;
  }
};

export const getDeclrationStudentSide = () => async (dispatch) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);

    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get(URL9 + "/by-user-registration-id", { headers })
      .then((response) => {
        console.log(response);
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          let row = [];
          let index = 0;
          response.data.data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              applicationDeclareDirectAdmission:
                data1.applicationDeclareDirectAdmission,
              title: data1.applicationDeclareDirectAdmission.title,
              programTitle: data1.programTitle.brName,
              applicationNumber: data1.applicationNumber,
              appliedDate:
                data1.applicationDeclareDirectAdmission.applicationDate,
              userDocumentDetailDirectAdmissions:
                data1.userDocumentDetailDirectAdmissions,
              userQualificationDetailDirectAdmissions:
                data1.userQualificationDetailDirectAdmissions,
              userRegistration: data1.userRegistration,
            };
            row.push(bData);
          });

          dispatch(applicationDeclrationStudentSideSuccess({ row }));
          return row;
        }
      })

      .catch((error) => {
        console.log("Error getDeclration========>", error);
        dispatch(serverFailed());
        return false;
      });
    return doc;
  } catch (e) {
    dispatch(serverFailed());
    return false;
  }
};

export const getDeclrationLiveForm = () => async (dispatch) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);

    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get(URL + "/live-applications" + '?sort={"insertdatetime": "DESC"}', {
        headers,
      })
      .then((response) => {
        console.log(response);
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          // if (response.data.error === false) {
          let data = response.data.data;
          let row = [];
          let index = 0;
          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              amount: data1.amount,
              applicationDate: data1.applicationDate,
              applicationDocumentDetailDirectAdmission:
                data1.applicationDocumentDetailDirectAdmission,
              applicationEligibilityDetailDirectAdmission:
                data1.applicationEligibilityDetailDirectAdmission,
              applicationRefDocumentDirectAdmission:
                data1.applicationRefDocumentDirectAdmission,
              byMinority: data1.byMinority,
              delStatus: data1.delStatus,
              detailedDescription: data1.detailedDescription,
              highlightLable: data1.highlightLable,
              id: data1.id,
              instructionPrintOnChallan: data1.instructionPrintOnChallan,
              isActive: data1.isActive,
              isFreez: data1.isFreez,
              isMeritListGenerated: data1.isMeritListGenerated,
              lableText: data1.lableText,
              title: data1.title,
              lastDate: data1.lastDate,
              meritListPublishedDate: data1.meritListPublishedDate,
              meritListType: data1.meritListType,
              minority: data1.minority,
              paymentApplicable: data1.paymentApplicable,
              paymentInstruction: data1.paymentInstruction,
              paymentType: data1.paymentType,
              program: data1.program,
              programTitleIds: data1.programTitleIds,
              programTypeId: data1.programTypeId,
              shortSummary: data1.shortSummary,
              showNotificationOnscreen: data1.showNotificationOnscreen,
              startDate: data1.startDate,
              tenantId: data1.tenantId,
              tsId: data1.tsId,
              type: data1.type,
              undertaking: data1.undertaking,
              year: data1.year,
            };
            row.push(bData);
          });

          dispatch(appliLiveSuccess({ row }));
          return row;
        }
      })

      .catch((error) => {
        console.log("Error getDeclration========>", error);
        dispatch(serverFailed());
        return false;
      });
    return doc;
  } catch (e) {
    dispatch(serverFailed());
    return false;
  }
};

export const getDeclrationScheduleForm = () => async (dispatch) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get(
        URL + "/scheduled-applications" + '?sort={"insertdatetime": "DESC"}',
        { headers }
      )
      .then((response) => {
        console.log(response);
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          let data = response.data.data;
          let row = [];
          let index = 0;
          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              amount: data1.amount,
              applicationDate: data1.applicationDate,
              applicationDocumentDetailDirectAdmission:
                data1.applicationDocumentDetailDirectAdmission,
              applicationEligibilityDetailDirectAdmission:
                data1.applicationEligibilityDetailDirectAdmission,
              applicationRefDocumentDirectAdmission:
                data1.applicationRefDocumentDirectAdmission,
              byMinority: data1.byMinority,
              delStatus: data1.delStatus,
              detailedDescription: data1.detailedDescription,
              highlightLable: data1.highlightLable,
              id: data1.id,
              instructionPrintOnChallan: data1.instructionPrintOnChallan,
              isActive: data1.isActive,
              isFreez: data1.isFreez,
              isMeritListGenerated: data1.isMeritListGenerated,
              lableText: data1.lableText,
              lastDate: data1.lastDate,
              meritListPublishedDate: data1.meritListPublishedDate,
              meritListType: data1.meritListType,
              minority: data1.minority,
              paymentApplicable: data1.paymentApplicable,
              paymentInstruction: data1.paymentInstruction,
              paymentType: data1.paymentType,
              program: data1.program,
              programTitleIds: data1.programTitleIds,
              programTypeId: data1.programTypeId,
              shortSummary: data1.shortSummary,
              showNotificationOnscreen: data1.showNotificationOnscreen,
              startDate: data1.startDate,
              tenantId: data1.tenantId,
              title: data1.title,
              // tsId: data1.tsId,
              type: data1.type,
              undertaking: data1.undertaking,
              year: data1.year,
            };
            row.push(bData);
          });
          dispatch(appliScheduleSuccess({ row }));
          return row;
        }
      })
      .catch((error) => {
        dispatch(serverFailed());
        return false;
      });
    return doc;
  } catch (e) {
    dispatch(serverFailed());
    return false;
  }
};
//

export const getDeclrationClosedFormByDate =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);

      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .get(
          URL +
            "/closed-applications-by-date?fromDate=" +
            fromDate +
            "&toDate=" +
            toDate +
            '?sort={"insertdatetime": "DESC"}',
          { headers }
        )
        .then((response) => {
          console.log(response);
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            let data = response.data.data;
            let row = [];
            let index = 0;
            data.map((data1) => {
              index = index + 1;
              let bData = {
                index: index,
                amount: data1.amount,
                applicationDate: data1.applicationDate,
                applicationDocumentDetailDirectAdmission:
                  data1.applicationDocumentDetailDirectAdmission,
                applicationEligibilityDetailDirectAdmission:
                  data1.applicationEligibilityDetailDirectAdmission,
                applicationRefDocumentDirectAdmission:
                  data1.applicationRefDocumentDirectAdmission,
                byMinority: data1.byMinority,
                delStatus: data1.delStatus,
                detailedDescription: data1.detailedDescription,
                highlightLable: data1.highlightLable,
                id: data1.id,
                instructionPrintOnChallan: data1.instructionPrintOnChallan,
                isActive: data1.isActive,
                isFreez: data1.isFreez,
                isMeritListGenerated: data1.isMeritListGenerated,
                lableText: data1.lableText,
                lastDate: data1.lastDate,
                meritListPublishedDate: data1.meritListPublishedDate,
                meritListType: data1.meritListType,
                minority: data1.minority,
                paymentApplicable: data1.paymentApplicable,
                paymentInstruction: data1.paymentInstruction,
                paymentType: data1.paymentType,
                program: data1.program,
                programTitleIds: data1.programTitleIds,
                programTypeId: data1.programTypeId,
                shortSummary: data1.shortSummary,
                showNotificationOnscreen: data1.showNotificationOnscreen,
                startDate: data1.startDate,
                tenantId: data1.tenantId,
                title: data1.title,
                tsId: data1.tsId,
                type: data1.type,
                undertaking: data1.undertaking,
                year: data1.year,
              };
              row.push(bData);
            });

            dispatch(appliClosedSuccess({ row }));
            return row;
          }
        })

        .catch((error) => {
          console.log("Error getDeclration========>", error);
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

//
export const getDeclrationClosedForm = () => async (dispatch) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);

    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get(URL + "/closed-applications" + '?sort={"insertdatetime": "DESC"}', {
        headers,
      })
      .then((response) => {
        console.log(response);
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          let data = response.data.data;
          let row = [];
          let index = 0;
          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              amount: data1.amount,
              applicationDate: data1.applicationDate,
              applicationDocumentDetailDirectAdmission:
                data1.applicationDocumentDetailDirectAdmission,
              applicationEligibilityDetailDirectAdmission:
                data1.applicationEligibilityDetailDirectAdmission,
              applicationRefDocumentDirectAdmission:
                data1.applicationRefDocumentDirectAdmission,
              byMinority: data1.byMinority,
              delStatus: data1.delStatus,
              detailedDescription: data1.detailedDescription,
              highlightLable: data1.highlightLable,
              id: data1.id,
              instructionPrintOnChallan: data1.instructionPrintOnChallan,
              isActive: data1.isActive,
              isFreez: data1.isFreez,
              isMeritListGenerated: data1.isMeritListGenerated,
              lableText: data1.lableText,
              lastDate: data1.lastDate,
              meritListPublishedDate: data1.meritListPublishedDate,
              meritListType: data1.meritListType,
              minority: data1.minority,
              paymentApplicable: data1.paymentApplicable,
              paymentInstruction: data1.paymentInstruction,
              paymentType: data1.paymentType,
              program: data1.program,
              programTitleIds: data1.programTitleIds,
              programTypeId: data1.programTypeId,
              shortSummary: data1.shortSummary,
              showNotificationOnscreen: data1.showNotificationOnscreen,
              startDate: data1.startDate,
              tenantId: data1.tenantId,
              title: data1.title,
              tsId: data1.tsId,
              type: data1.type,
              undertaking: data1.undertaking,
              year: data1.year,
            };
            row.push(bData);
          });

          dispatch(appliClosedSuccess({ row }));
          return row;
        }
      })

      .catch((error) => {
        console.log("Error getDeclration========>", error);
        dispatch(serverFailed());
        return false;
      });
    return doc;
  } catch (e) {
    dispatch(serverFailed());
    return false;
  }
};

export const getDeclrationById =
  ({ appliId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const courseData = await axios
        .get(URL + "/" + appliId, { headers })
        .then((response) => {
          console.log("Success With id getDeclrationById ========>", response);
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            const { data } = response.data;
            let row = [];
            let index = 0;
            let data1 = {
              id: data.id,
              title: data.title,
              lastDate: data.lastDate,
              startDate: data.startDate,
              applicationDate: data.applicationDate,
              shortSummary: data.shortSummary,
              detailedDescription: data.detailedDescription,
              paymentApplicable: data.paymentApplicable,
              paymentType: data.paymentApplicable === 0 ? "" : data.paymentType,
              amount: data.paymentApplicable === 0 ? "" : data.amount,
              instructionPrintOnChallan: data.instructionPrintOnChallan,
              paymentInstruction:
                data.instructionPrintOnChallan == 1
                  ? data.paymentInstruction
                  : "",
              highlightLable: data.highlightLable,
              lableText: data.lableText,
              showNotificationOnscreen: data.showNotificationOnscreen,
              meritListPublishedDate:
                data.meritListPublishedDate === null
                  ? ""
                  : data1.meritListPublishedDate,
              yearId: data.year === null ? "" : data.year.id,
              tenantId: data.tenantId === null ? "" : data.tenantId,
              tenantName: data.year === null ? "" : data.year.tenant.tenantName,
              undertaking: data.undertaking,
              programTypeId:
                data.programType === null ? "" : data.programType.id,
              progName: data.program === null ? "" : data.program.name,
              programTitleIds: data.programTitleIds,
              programId: data.program === null ? "" : data.program.id,
              programTypeName:
                data.programType === null ? "" : data.programType.name,
              byMinority: data.byMinority,
              minority: data.minority,
              applicationElegibilityDetails:
                data.applicationEligibilityDetailDirectAdmission.map(
                  (elegibilityDetails) => {
                    let progTitleInst = {
                      id: elegibilityDetails.id,
                      qualificationId: elegibilityDetails.qualification.id,
                      qualification: elegibilityDetails.qualification.name,
                      marksSequenceOrderNo:
                        elegibilityDetails.marksSequenceOrderNo,
                      weightagePer: elegibilityDetails.weightagePer,
                      type: elegibilityDetails.qualification.type,
                    };
                    return progTitleInst;
                  }
                ),
              applicationRefDocuments:
                data.applicationRefDocumentDirectAdmission.map((refDoc) => {
                  let refDocumentData = {
                    id: refDoc.id,
                    applicationDeclareId: refDoc.applicationDeclareId,
                    docDesc: refDoc.docDesc,
                    documentPath: refDoc.documentPath,
                  };
                  return refDocumentData;
                }),

              applicationDocumentDetails:
                data.applicationDocumentDetailDirectAdmission.map(
                  (elegibilityDetails) => {
                    let progTitleInst = {
                      id: elegibilityDetails.id,
                      documentId: elegibilityDetails.document.id,
                      documentName: elegibilityDetails.document.name,
                      marksSequenceOrderNo:
                        elegibilityDetails.marksSequenceOrderNo,
                      remark: elegibilityDetails.remark,
                      type: elegibilityDetails.type,
                    };
                    return progTitleInst;
                  }
                ),
              type: data.type,
            };
            data?.applicationEligibilityDetailDirectAdmission.map(
              (applicationElegibilityData) => {
                index = index + 1;
                console.log(applicationElegibilityData);

                let bData = {
                  index: index,
                  eligibility: applicationElegibilityData.id,
                  name: applicationElegibilityData.qualification.name,
                  type: applicationElegibilityData.qualification.type,
                  id: applicationElegibilityData.qualification.id,
                  school: "",
                  certificateNo: "",
                  examSeatNo: "",
                  year: "",
                  marksObt: "",
                  marksOutOff: "",
                  percentage: "",
                  university: "",
                  uniValid: 0,
                  country: "",
                  countryValid: "",
                  board: "",
                  boardValid: 0,
                  examSeatNoValid: 0,
                  schoolValid: 0,
                  certificateNoValid: 0,
                  marksObtValid: 0,
                  marksOutOffValid: 0,
                  percentageValid: 0,
                  yearValid: 0,
                  isActive: "Active",
                };

                row.push(bData);
                return bData;
              }
            );
            dispatch(applicationDocumentDetails({ row }));
            dispatch(applicatonSuccess({ data1 }));
            return data1;
          }
        })
        .catch((error) => {
          console.log("ErrorgetDeclrationById ========>", error.response.data);
          dispatch(serverFailed());
          return false;
        });
      return courseData;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const getDirectAdmissionDeclrationById =
  ({ appliId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const courseData = await axios
        .get(URL9 + "/" + appliId, { headers })
        .then((response) => {
          console.log(
            "Success With id getDirectAdmissionDeclrationById ========>",
            response
          );
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            const { data } = response.data;
            let row = [];

            let data1 = {
              id: data.id,
              fatherName: data.userRegistration.fatherName,
              aadhaarNo: data.userRegistration.aadhaarNo,
              annualIncomeName: data.userRegistration.annualIncome.name,
              birthPlace: data.userRegistration.birthPlace,
              bloodGroup: data.userRegistration.bloodGroup.name,
              cast: data.userRegistration.cast,
              castCertificateDate: data.userRegistration.castCertificateDate,
              castCertificateNo: data.userRegistration.castCertificateNo,
              category: data.userRegistration.category.name,
              dateOfBirth: data.userRegistration.dateOfBirth,
              defenceType: data.userRegistration.defenceType,
              domicileof: data.userRegistration.domicileof,
              fatherName: data.userRegistration.fatherName,
              fatherOccupation: data.userRegistration.fatherOccupation,
              firstName: data.userRegistration.firstName,
              gender: data.userRegistration.gender,
              handicapCategory: data.userRegistration.handicapCategory,
              isOrphan: data.userRegistration.isOrphan,
              isStudentNriForeignNational:
                data.userRegistration.isStudentNriForeignNational,
              landlineNo: data.userRegistration.landlineNo,
              lastName:
                data.userRegistration.lastName !== null
                  ? data.userRegistration.lastName
                  : "",
              martialStatus: data.userRegistration.martialStatus,
              middleName:
                data.userRegistration.middleName !== null
                  ? data.userRegistration.middleName
                  : "",
              minority: data.userRegistration.minority,
              motherName: data.userRegistration.motherName,
              motherTongue: data.userRegistration.motherTongue,
              nationality: data.userRegistration.nationality,
              oms: data.userRegistration.oms,
              parentEmail: data.userRegistration.parentEmail,
              parentMobile: data.userRegistration.parentMobile,
              permanentAddressLine1:
                data.userRegistration.permanentAddressLine1,
              permanentAddressLine2:
                data.userRegistration.permanentAddressLine2,
              permanentAddressLine3:
                data.userRegistration.permanentAddressLine3,
              permanentCity: data.userRegistration.permanentCity,
              permanentDistrictName:
                data.userRegistration.permanentDistrict.name,
              permanentPin: data.userRegistration.permanentPin,
              permanentStateName: data.userRegistration.permanentState.name,
              permanentTalukaName: data.userRegistration.permanentTaluka.name,
              profilePic: data.userRegistration.profilePic,
              religion: data.userRegistration.religion.name,
              remark: data.userRegistration.remark,
              ruralUrban: data.userRegistration.ruralUrban,
              scholarship: data.userRegistration.scholarship,
              signature: data.userRegistration.signature,
              studentEmail: data.userRegistration.studentEmail,
              studentMobile: data.userRegistration.studentMobile,
              subCast: data.userRegistration.subCast,
              temporaryAddressLine1:
                data.userRegistration.temporaryAddressLine1,
              temporaryAddressLine2:
                data.userRegistration.temporaryAddressLine2,
              temporaryAddressLine3:
                data.userRegistration.temporaryAddressLine3,
              temporaryCity: data.userRegistration.temporaryCity,
              temporaryDistrictName:
                data.userRegistration.temporaryDistrict.name,
              temporaryPin: data.userRegistration.temporaryPin,
              temporaryStateName: data.userRegistration.temporaryState.name,
              temporaryTalukaName: data.userRegistration.temporaryTaluka.name,
              validityCdDate: data.userRegistration.validityCdDate,
              validityCdNo: data.userRegistration.validityCdNo,
              paidStatus: data.paidStatus,

              userQualificationDetails:
                data.userQualificationDetailDirectAdmissions.map(
                  (userQualificationData) => {
                    let docs = {
                      type: userQualificationData.qualification.type,
                      qualification: userQualificationData.qualification.name,
                      yearOfPass: userQualificationData.yearOfPass,
                      percentage: userQualificationData.percentage,
                      marksObtained: userQualificationData.marksObtained,
                      marksOutof: userQualificationData.marksOutof,
                      university: userQualificationData.university,
                      school: userQualificationData.nameOfSchoolCollege,
                      examSeatNo: userQualificationData.examSeatNo,
                      certificateNo: userQualificationData.certificateNo,
                      country: userQualificationData.country,
                      state: userQualificationData.state,
                      board: userQualificationData.board,
                    };
                    return docs;
                  }
                ),

              userDocumentDetails: data.userDocumentDetailDirectAdmissions.map(
                (userDocumentData) => {
                  let docss = {
                    document: userDocumentData.document.name,
                    documentFileName: userDocumentData.documentFileName,
                  };
                  return docss;
                }
              ),
            };
            dispatch(directAdmissionDeclrationByIdSuccess({ data1 }));
            return data1;
          }
        })
        .catch((error) => {
          console.log("ErrorgetDeclrationById ========>", error.response.data);
          dispatch(serverFailed());
          return false;
        });
      return courseData;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const UpdateLockDeclration =
  ({ appliId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .get(URL + "/" + appliId + "/lock-application", { headers })
        .then((response) => {
          console.log("Success With id getDeclrationById ========>", response);
          const { data } = response.data;
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          return data;
        })
        .catch((error) => {
          console.log("ErrorgetDeclrationById ========>", error.response.data);
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };
export const getApplicantByDeclrationId =
  ({ appliId }) =>
  async (dispatch) => {

    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .get(URL + "/" + appliId + "/user-application-direct-admission", {
          headers,
        })
        .then((response) => {
          console.log(
            "Success With id getDeclrationById ========>",
            response.data.data
          );
          const { data } = response.data;
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            let row = [];
            let index = 0;
            let row1 = [];
            let index1 = 0;
            data.map((data1) => {
              index = index + 1;
              let bData = {
                index: index,
                id: data1.id,
                userName:
                  data1.userRegistration.firstName +
                  " " +
                  data1.userRegistration.middleName +
                  " " +
                  data1.userRegistration.lastName,
                userId: data1.userRegistration.id,
                programTitle: data1.programTitle.brName==null?"":data1.programTitle.brName,
                applicationNo: data1.applicationNumber==null?"":data1.applicationNumber,
                declareId: data1.applicationDeclareDirectAdmission.id,
                isActive:data1.userRegistration.isActive === 1 ? "Active" : "Inactive",
                applicationElegibilityDetails:data1?.userQualificationDetailDirectAdmissions.map(
                    (applicationElegibilityData, elegibilityDetailsIndex) => {
                      console.log(applicationElegibilityData);

                      let bData = {
                        index: elegibilityDetailsIndex + 1,
                        id: applicationElegibilityData.id,
                        qualification:applicationElegibilityData.qualification==null?"":applicationElegibilityData.qualification.name,
                        type: applicationElegibilityData.qualification==null?"":applicationElegibilityData.qualification.type,
                        qualificationId:applicationElegibilityData.qualification.id,
                        year: applicationElegibilityData.yearOfPass==null?"":applicationElegibilityData.yearOfPass,
                        marksObt: applicationElegibilityData.marksObtained==null?"":applicationElegibilityData.marksObtained,
                        marksOutOff: applicationElegibilityData.marksOutof==null?"":applicationElegibilityData.marksOutof,
                        percentage: applicationElegibilityData.percentage==null?"":applicationElegibilityData.percentage,
                        university: applicationElegibilityData.university==null?"":applicationElegibilityData.university,
                        school: applicationElegibilityData.nameOfSchoolCollege==null?"":applicationElegibilityData.nameOfSchoolCollege,
                        certificateNo: applicationElegibilityData.certificateNo==null?"":applicationElegibilityData.certificateNo,
                        examSeatNo: applicationElegibilityData.examSeatNo==null?"":applicationElegibilityData.examSeatNo,
                        uniValid: 0,
                        examSeatNoValid: 0,
                        schoolValid: 0,
                        certificateNoValid: 0,
                        marksObtValid: 0,
                        marksOutOffValid: 0,
                        percentageValid: 0,
                        yearValid: 0,
                      };

                      // row.push(bData);
                      return bData;
                    }
                  ),
              };
              row.push(bData);
              return bData;
            });
            data.map((data1) => {
              index = index1 + 1;
              let userData = {
                id: data1.id,
                fatherName: data1.userRegistration.fatherName == null ? "" : data1.userRegistration.fatherName,
                aadhaarNo: data1.userRegistration.aadhaarNo == null ? "" : data1.userRegistration.aadhaarNo,
                annualIncomeName: data1.userRegistration.annualIncome == null ? "" : data1.userRegistration.annualIncome.name,
                birthPlace: data1.userRegistration.birthPlace == null ? "" : data1.userRegistration.birthPlace,
                bloodGroup: data1.userRegistration.bloodGroup == null ? "" : data1.userRegistration.bloodGroup.name,
                cast: data1.userRegistration.cast == null ? "" : data1.userRegistration.cast,
                castCertificateDate: data1.userRegistration.castCertificateDate == null ? "" : data1.userRegistration.castCertificateDate,
                castCertificateNo: data1.userRegistration.castCertificateNo == null ? "" : data1.userRegistration.castCertificateNo,
                category: data1.userRegistration.category == null ? "" : data1.userRegistration.category.name,
                dateOfBirth: data1.userRegistration.dateOfBirth == null ? "" : data1.userRegistration.dateOfBirth,
                defenceType: data1.userRegistration.defenceType == null ? "" : data1.userRegistration.defenceType,
                domicileof: data1.userRegistration.domicileof == null ? "" : data1.userRegistration.domicileof,
                fatherName: data1.userRegistration.fatherName == null ? "" : data1.userRegistration.fatherName,
                fatherOccupation: data1.userRegistration.fatherOccupation == null ? "" : data1.userRegistration.fatherOccupation,
                firstName: data1.userRegistration.firstName == null ? "" : data1.userRegistration.firstName,
                gender: data1.userRegistration.gender == null ? "" : data1.userRegistration.gender,
                handicapCategory: data1.userRegistration.handicapCategory == null ? "" : data1.userRegistration.handicapCategory,
                isOrphan: data1.userRegistration.isOrphan == null ? "" : data1.userRegistration.isOrphan,
                isStudentNriForeignNational: data1.userRegistration.isStudentNriForeignNational == null?"":data1.userRegistration.isStudentNriForeignNational,
                landlineNo: data1.userRegistration.landlineNo == null ?"":data1.userRegistration.landlineNo,
                lastName:data1.userRegistration.lastName !== null? data1.userRegistration.lastName : "",
                martialStatus: data1.userRegistration.martialStatus==null?"":data1.userRegistration.martialStatus,
                middleName:data1.userRegistration.middleName !== null? data1.userRegistration.middleName: "",
                minority: data1.userRegistration.minority==null?"":data1.userRegistration.minority,
                motherName: data1.userRegistration.motherName==null?"":data1.userRegistration.motherName,
                motherTongue: data1.userRegistration.motherTongue==null?"":data1.userRegistration.motherTongue,
                nationality: data1.userRegistration.nationality==null?"":data1.userRegistration.nationality,
                oms: data1.userRegistration.oms==null?"":data1.userRegistration.oms,
                parentEmail: data1.userRegistration.parentEmail==null?"":data1.userRegistration.parentEmail,
                parentMobile: data1.userRegistration.parentMobile==null?"":data1.userRegistration.parentMobile,
                permanentAddressLine1:
                  data1.userRegistration.permanentAddressLine1==null?"":data1.userRegistration.permanentAddressLine1,
                permanentAddressLine2:
                  data1.userRegistration.permanentAddressLine2==null?"":data1.userRegistration.permanentAddressLine2,
                permanentAddressLine3:
                  data1.userRegistration.permanentAddressLine3 == null?"":data1.userRegistration.permanentAddressLine3,
                permanentCity: data1.userRegistration.permanentCity ==null ?"":data1.userRegistration.permanentCity,
                permanentDistrictName:data1.userRegistration.permanentDistrict==null?"":data1.userRegistration.permanentDistrict.name,
                permanentPin: data1.userRegistration.permanentPin==null?"":data1.userRegistration.permanentPin,
                permanentStateName: data1.userRegistration.permanentState==null?"":data1.userRegistration.permanentState.name,
                permanentTalukaName:data1.userRegistration.permanentTaluka==null?"":data1.userRegistration.permanentTaluka.name,
                profilePic: data1.userRegistration.profilePic==null?"":data1.userRegistration.profilePic,
                religion: data1.userRegistration.religion==null?"":data1.userRegistration.religion.name,
                remark: data1.userRegistration.remark==null?"":data1.userRegistration.remark,
                ruralUrban: data1.userRegistration.ruralUrban==null?"":data1.userRegistration.ruralUrban,
                scholarship: data1.userRegistration.scholarship==null?"":data1.userRegistration.scholarship,
                signature: data1.userRegistration.signature==null?"":data1.userRegistration.signature,
                studentEmail: data1.userRegistration.studentEmail==null?"":data1.userRegistration.studentEmail,
                studentMobile: data1.userRegistration.studentMobile==null?"":data1.userRegistration.studentMobile,
                subCast: data1.userRegistration.subCast==null?"":data1.userRegistration.subCast,
                temporaryAddressLine1:
                  data1.userRegistration.temporaryAddressLine1==null?"":data1.userRegistration.temporaryAddressLine1,
                temporaryAddressLine2:
                  data1.userRegistration.temporaryAddressLine2==null?"":data1.userRegistration.temporaryAddressLine2,
                temporaryAddressLine3:
                  data1.userRegistration.temporaryAddressLine3==null?"":data1.userRegistration.temporaryAddressLine3,
                temporaryCity: data1.userRegistration.temporaryCity==null?"":data1.userRegistration.temporaryCity,
                temporaryDistrictName:data1.userRegistration.temporaryDistrict==null?"":data1.userRegistration.temporaryDistrict.name,
                temporaryPin: data1.userRegistration.temporaryPin==null?"":data1.userRegistration.temporaryPin,
                temporaryStateName: data1.userRegistration.temporaryState==null?"":data1.userRegistration.temporaryState.name,
                temporaryTalukaName:data1.userRegistration.temporaryTaluka==null?"":data1.userRegistration.temporaryTaluka.name,
                validityCdDate: data1.userRegistration.validityCdDate==null?"":data1.userRegistration.validityCdDate,
                validityCdNo: data1.userRegistration.validityCdNo==null?"":data1.userRegistration.validityCdNo,
                paidStatus: data1.paidStatus==null?"":data1.paidStatus,

                userQualificationDetails:
                  data1.userQualificationDetailDirectAdmissions.map(
                    (userQualificationData) => {
                      let docs = {
                        type: userQualificationData.qualification==null?"":userQualificationData.qualification.type,
                        qualification: userQualificationData.qualification==null?"":userQualificationData.qualification.name,
                        yearOfPass: userQualificationData.yearOfPass==null?"":userQualificationData.yearOfPass,
                        percentage: userQualificationData.percentage==null?"":userQualificationData.percentage,
                        marksObtained: userQualificationData.marksObtained==null?"":userQualificationData.marksObtained,
                        marksOutof: userQualificationData.marksOutof==null?"":userQualificationData.marksOutof,
                        university: userQualificationData.university==null?"":userQualificationData.university,
                        school: userQualificationData.nameOfSchoolCollege==null?"":userQualificationData.nameOfSchoolCollege,
                        examSeatNo: userQualificationData.examSeatNo==null?"":userQualificationData.examSeatNo,
                        certificateNo: userQualificationData.certificateNo==null?"":userQualificationData.certificateNo,
                        country: userQualificationData.country==null?"":userQualificationData.country,
                        state: userQualificationData.state==null?"":userQualificationData.state,
                        board: userQualificationData.board==null?" ":userQualificationData.board,
                      };
                      return docs;
                    } 
                  ),

                userDocumentDetails:
                  data1.userDocumentDetailDirectAdmissions.map(
                    (userDocumentData) => {
                      let docss = {
                        document: userDocumentData.document==null?"":userDocumentData.document.name,
                        documentFileName: userDocumentData.documentFileName==null?"":userDocumentData.documentFileName,
                      };
                      return docss;
                    }
                  ),
              };

              row1.push(userData);
              return userData;
            });
            dispatch(applicantSuccess({ row }));
            dispatch(applicantDetailsListSuccess({ row1 }));
          }
        })
        .catch((error) => {
          console.log("ErrorgetDeclrationById ========>", error.response.data);
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const changeStatus =
  ({ appliId, status }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .get(
          URL + "/change-active-status?id=" + appliId + "&status=" + status,
          { headers }
        )
        .then((response) => {
          console.log("Success With saveappli ========>", response.data.data);
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          return true;
        })
        .catch((error) => {
          console.log("Errorsaveappli ========>", error.response.data);
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const changeRoundStatus =
  ({ roundId, status }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .get(
          URL1 + "/change-active-status?id=" + roundId + "&status=" + status,
          { headers }
        )
        .then((response) => {
          console.log("Success With saveappli ========>", response.data.data);
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          return true;
        })
        .catch((error) => {
          console.log("Errorsaveappli ========>", error.response.data);
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const saveappli =
  ({ appliData }) =>
  async (dispatch) => {
    try {
      let data = JSON.stringify(appliData);

      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .post(URL, data, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            console.log("Success With saveappli ========>", response.data.data);
            const { applicationDeclareResult } = response.data.data.data;

            dispatch(applicatonSuccess({ data1: applicationDeclareResult }));
            localStorage.setItem("appId", applicationDeclareResult.id);
            return data;
          }
        })
        .catch((error) => {
          console.log("Errorsaveappli ========>", error.response.data);
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const saveQualificationDetails =
  ({ qualiData }) =>
  async (dispatch) => {
    try {
      let data = JSON.stringify(qualiData);

      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .post(URL, data, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            console.log("Success With saveappli ========>", response.data.data);
            const { applicationDeclareResult } = response.data.data.data;

            dispatch(appliSaveSuccess({ applicationDeclareResult }));
            localStorage.setItem("appId", applicationDeclareResult.id);
            return data;
          }
        })
        .catch((error) => {
          console.log("Errorsaveappli ========>", error.response.data);
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const deleteappliById =
  ({ appliId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .delete(URL + "/" + appliId, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            return true;
          }
          console.log("Success With deletedeleteappliById ========>", response);
        })
        .catch((error) => {
          console.log("Error deleteappliById ========>", error.response.data);
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const deleteRoundById =
  ({ appliId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .delete(URL1 + "/" + appliId, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          console.log("Success With deletedeleteappliById ========>", response);
        })
        .catch((error) => {
          console.log("Error deleteappliById ========>", error.response.data);
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const deleteDeclrationData = () => async (dispatch) => {
  try {
    dispatch(appliSave());
  } catch (e) {
    return console.error(e.message);
  }
};
export const resetDeclare = () => async (dispatch) => {
  try {
    dispatch(appliSave());
  } catch (e) {
    return console.error(e.message);
  }
};
export const getCourseDataById =
  ({ ids }) =>
  async (dispatch) => {
    try {
      let URLs = endpoints.course;
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };

      const doc = await axios
        .get(URLs + "/all-program-title?ids=" + ids, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            console.log(
              "Success With id getCourseDataById ========>",
              response
            );
            const { data } = response.data;

            let index = 0;

            dispatch(courseDetails({ data }));
          }
          return response;
        })
        .catch((error) => {
          console.log("ErrorgetCourseDataById ========>", error.response.data);
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const getRoundDetailsById =
  ({ capId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      let doc = await axios
        .get(URL8 + capId, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          console.log(
            "Success With id getRoundDetailsById ========>",
            response
          );
          const { data } = response.data;
          let row = [];
          let index = 0;
          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.applicationRound.name,
              isActive:
                data1.applicationRound.isActive === 1 ? "Active" : "Inactive",
            };
            row.push(bData);
          });
          dispatch(applicantRoundDetailsListSuccess({ row }));
          return row;
        })
        .catch((error) => {
          dispatch(serverFailed());
          console.log("ErrorgetDeclrationById ========>", error.response.data);
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const resetSaveDeclare = () => async (dispatch) => {
  dispatch(resetSave());
};
export const resetServer = () => async (dispatch) => {
  try {
    dispatch(resetServerSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

export const resetDelete = () => async (dispatch) => {
  try {
    dispatch(resetDeleteSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

//http://localhost:8088/api/application/v1/application-declare-direct-admission/get-program-title-by-application-id?applicationId=400e1b70-e6fb-4e49-9618-840a6ad07728

export const getProgramTitleByApplicationId =
  ({ applicationId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      let doc = await axios
        .get(
          URL +
            "/get-program-title-by-application-id?applicationId=" +
            applicationId,
          { headers }
        )
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          console.log(
            "Success With id getRoundDetailsById ========>",
            response
          );
          const { data } = response.data;
          let row = [];
          let index = 0;
          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.brName + " - " + data1.brShortName,
              isActive: data1.isActive === 1 ? "Active" : "Inactive",
            };
            row.push(bData);
          });
          dispatch(programTitleByApplicationIdSuccess({ row }));
          return row;
        })
        .catch((error) => {
          dispatch(serverFailed());
          console.log("ErrorgetDeclrationById ========>", error.response.data);
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };
