import { Box, Grid, Paper, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import MultipleSelect from "../../components/Comman/MultipleSelect";
import "../../CSS/View.css";
import { getCategory } from "../CategoryMaster/category.slice";
import QualificationDetails from "../QualificationDetails/Index";
import AutoComplete from "../../components/Comman/AutoComplete";
import { getAlert } from "../../Alert/alert.slice";
import * as myConstClass from "../../config/messageconstant";
let docs;
class ApplicationInstruction extends React.Component
{
  constructor( props )
  {
    super( props );
  }

  styles = {
    preview: {
      marginTop: 50,
      display: "flex",
      flexDirection: "column",
    },
    image: { maxWidth: "100%", maxHeight: 320 },
    delete: {
      cursor: "pointer",
      padding: 15,
      background: "red",
      color: "white",
      border: "none",
    },
  };

  state = {
    id: 0,
    file: "",
    category: [],
    minority: "",
    fileName: "",
    signfile: "",
    imagefile: "",
    formErrors: [],
    flag: false,
  };
  multiSelectHandler = ( value, name ) =>
  {
    this.setState( {
      ...this.state,
      [ name ]: typeof value === "string" ? value.split( "," ) : value,
    } );
  };
  ChangeHandlerSearch = ( name, newValue ) =>
  {
    if(newValue!==null){
      this.setState({ [name]: newValue });
    }
    else{
      this.setState({ [name]: '' });
    }
  };
  submitHandler = ( e ) =>
  {
    const { openDocUpload } = this.props;
    const { category, minority } = this.state;
    var categoryData = category.join();

    docs = {
      programTitleIds: categoryData === "" ? 0 : categoryData,
      minority: minority === "" ? 0 : minority,
    };

    // this.props.OnSave(docs);

    openDocUpload( docs );
  };
  getQualiDetails = ( data ) => { };
  componentDidMount()
  {
    const { getCategory } = this.props;
    getCategory().then(
      (respo) => {
        if (!respo) {
          getAlert({ message: myConstClass.serverMsg });
        }
      }
    );
  }
  render()
  {
    const { category, minority } = this.state;
    console.log( category );

    const { onCancel, courseErr, catList, appliData } = this.props;
    const categoryList = catList.category.filter(
      ( row ) => row.type === "handicap"
    );
    return (
      <>
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>

          <Grid>
            <LabelCompo
              className="text-black"
              style={{
                fontSize: 18,
                color: "black",
                fontFamily: "Poppins",
              }}
              label="Instructions"
            />

            <Box display="flex" justifyContent="space-between ">
              <Box style={{ width: 1100 }}>
                <Card
                  variant="outlined"
                  sx={{
                    color: "#ed1c24",
                    fontFamily: "poppins",
                    mt: 1,
                    p: 1,
                  }}
                >
                  <CardContent style={{ lineHeight: "34px" }}>
                    <Grid>
                      <Typography variant="span">a)</Typography>
                      &emsp;
                      <Typography variant="span">
                        The Photo should be Colorful. Black and White Photo should
                        not be Accepted.
                      </Typography>
                    </Grid>

                    <Grid>
                      <Typography variant="span">b)</Typography>
                      &emsp;
                      <Typography variant="span">
                        Size of the Photograph should be in between 20KB to 100KB.
                      </Typography>
                    </Grid>

                    <Grid>
                      <Typography variant="span">c)</Typography>
                      &emsp;
                      <Typography variant="span">
                        The photograph and Signature Should be Scanned.
                      </Typography>
                    </Grid>

                    <Grid>
                      <Typography variant="span">d)</Typography>
                      &emsp;
                      <Typography variant="span">
                        The Photograph Dimension should be of 4.5 cm * 3.5 cm
                      </Typography>
                    </Grid>

                    <Grid>
                      <Typography variant="span">e)</Typography>
                      &emsp;
                      <Typography variant="span">
                        Photo Should be without CAP, Googles and both ears should
                        be visible.
                      </Typography>
                    </Grid>

                    <Grid>
                      <Typography variant="span">f)</Typography>
                      &emsp;
                      <Typography variant="span">
                        The Signature should be of black and blue ink.
                      </Typography>
                    </Grid>

                    <Grid>
                      <Typography variant="span">g)</Typography>
                      &emsp;
                      <Typography variant="span">
                        The Signature should be in the box only. Outside of the
                        box or on the box will not be accepted.
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography variant="span">h)</Typography>
                      &emsp;
                      <Typography variant="span">
                        The Signature should be on blank page.
                      </Typography>
                    </Grid>

                    <Grid>
                      <Typography variant="span">i)</Typography>
                      &emsp;
                      <Typography variant="span">
                        Size of the Signature Should be in between 20 KB to 50 KB.
                      </Typography>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Box>
            <br />
          </Grid>

          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
           
                <Grid
                  item
                  sm={6}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                 {categoryList.length !== 0 && (
              <>
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Select Quota (If applicable)"
                    />
                  </Grid>
                  <Grid sx={{mb:2}} item xs={12} sm={12} md={12}>
                    <MultipleSelect
                      er={courseErr}
                      name1="category"
                      value1={category}
                      names={catList.category.filter(
                        ( row ) => row.type === "handicap"
                      )}
                      onChange={this.multiSelectHandler}
                    />
                  </Grid>
                  </>

)}
                </Grid>
                {/* <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                ></Grid> */}
            
          

          <Grid
            item
            sm={6}
            xs={12}
            md={6}
            lg={6}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            {appliData.applicationData.byMinority == 1 && (
              <>
                {/* <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                > */}
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Select Minority Status (if applicable)"
                    />
                  </Grid>
                  <Grid sx={{mt:-3}} item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={minority}
                      name={"minority"}
                      options={catList?.categoryList}
                      onChange={this.ChangeHandlerSearch}
                    />
                  </Grid>
                {/* </Grid> */}
              </>
            )}
          </Grid>
</Grid>

          <br />


          <Grid
            rowSpacing={2}
            container
            columnSpacing={1}
            justifyContent="right"
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={1.1}
              lg={1.1}
              justifyContent="space-evenly"
            >
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name="Cancel"
                onClick={onCancel}
                fullWidth={true}
              />
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={2.5}
              lg={2.5}
              justifyContent="space-evenly"
            >
              <ButtonCompo
                size="medium"
                type="button"
                variant="contained"
                name="Save and Proceed"
                fullWidth={true}
                onClick={this.submitHandler}
              />
            </Grid>
          </Grid>
          {/*  */}
          {/* <Grid item columnSpacing={1} container justifyContent="right">
            <Grid
              rowSpacing={2}
              container
              columnSpacing={1}
              justifyContent="right"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={1.1}
                lg={1.1}
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="outlined"
                  name="Cancel"
                  onClick={onCancel}
                />
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={2.5}
                lg={2.5}
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Save and Proceed"
                  onClick={this.submitHandler}
                />
              </Grid>
            </Grid> */}
          {/* </Grid> */}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = ( state ) => ( {
  catList: state.category,
  appliData: state.appli,
} );

const mapDispatchToProps = {
  getCategory,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)( ApplicationInstruction );
