import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { decodeData } from "../../components/Comman/Util/Base64";
import endpoints from "../../config/endpoints";
import { tokenExp } from "../Login/login.slice";
// import ErrorHand from "../../components/ErrorHand";
const initialState = {
  document: [],
  documentDetails: {},
  documentData: [],
  isFetch: false,
  isDelete: false,
  isDataFetch: false,
  documentFilter: [],
  isFilter: false,
  isSave: false,
  error: false,
  isServerFail: false,
};
let URL = endpoints.document;
const documentSlice = createSlice( {
  name: "document",
  initialState,
  reducers: {
    documentSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        document: row,
        isDataFetch: true,
      };
    },
    documentDeleteFail: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        error: true,
        isDelete: true,
        isSave: false,
      };
    },
    documentFilterSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        documentFilter: row,
        isFilter: true,
      };
    },
    documentFilterFailure: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        documentFilter: [],
        isFilter: false,
      };
    },
    documentListRefresh: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        isDataFetch: false,
      };
    },
    documentDetailsSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { data } = action.payload;
      return {
        ...state,
        documentDetails: data,
        isSave: true,
        error: false,
        isDelete: false,
      };
    },
    documentDetailsFail: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        documentDetails: {},
        isSave: false,
        error: false,
        isDelete: false,
      };
    },
    documentDeleteSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        error: false,
        isDelete: true,
      };
    },
    documentListByNameSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { data } = action.payload;
      return {
        ...state,
        documentData: data,
        isFetch: true,
        error: false,
        isDelete: false,
      };
    },
    documentListByNameFailure: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        documentData: [],
        isFetch: false,
        error: false,
        isDelete: false,
      };
    },
    resetDeleteSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        isServerFail: false,
        error: false,
        isDelete: false,
        isSave: false,
        isServerFail: false,
      };
    },
    serverFailed: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        isServerFail: true,
        error: false,
        isDelete: false,
      };
    },
  },
} );

export const {
  documentFilterSuccess,
  serverFailed,
  resetDeleteSuccess,
  documentFilterFailure,
  documentDeleteFail,
  documentDeleteSuccess,
  documentListRefresh,
  documentSuccess,
  documentDetailsFail,
  documentDetailsSuccess,
  documentFailure,
  documentListByNameSuccess,
  documentListByNameFailure,
} = documentSlice.actions;

export default documentSlice.reducer;

export const getDocument = () => async ( dispatch ) =>
{
  try
  {
    dispatch( documentListRefresh() );
    const name = localStorage.getItem( "token" );
    const token = decodeData( name );
    console.log( "token" );
    console.log( token );
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldRefresh: "true",
    };
    const doc = await axios
      .get( URL + '?sort={"sortOrderNo": "ASC"}', { headers } )
      .then( ( response ) =>
      {
        const { error } = response.data;
        if ( error == true )
        {
          dispatch( tokenExp() );
        } else
        {
          let data = response.data.data.content;
          let row = [];
          let index = 0;
          data.map( ( data1 ) =>
          {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              docName: data1.name,
              sortOrderNo: data1.sortOrderNo,
              mandatoryOptional: "",
              remarks: "",
              seqNO: "",
              remarksValid: 0,
              seqNoValid: 0,
              mandatoryOptionalValid: 0,
              isActive: data1.isActive === 1 ? "Active" : "Inactive",
            };
            row.push( bData );
          } );
          // ErrorHand({error:"error",msg:'This is an error alert — check it out!',flag:true})
          dispatch( documentSuccess( { row } ) );

          return response;
        }
      } )
      .catch( ( error ) =>
      {
        console.log( "Error getDocument========>", error );
        dispatch( serverFailed() );
        return false;
      } );
    return doc;
  } catch ( e )
  {
    dispatch( serverFailed() );
    // return console.error( e.message );
    return false;
  }
};

export const getDocumentByName =
  ( { id, docName } ) =>
    async ( dispatch ) =>
    {
      try
      {
        const name = localStorage.getItem( "token" );
        const token = decodeData( name );
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
          shouldrefresh: "true",
        };
        if ( id == 0 )
        {
          const doc = await axios
            .get( URL + '?filters={"name":"' + docName + '","_mode" : "EXACT"}', {
              headers,
            } )
            .then( ( response ) =>
            {
              console.log( "Success getDoc  ========>", response );
              const { error } = response.data;
              if ( error == true )
              {
                dispatch( tokenExp() );
              } else
              {
                let data = response.data.data.content;

                dispatch( documentListByNameSuccess( { data } ) );
                return data;
              }
            } )
            .catch( ( error ) =>
            {
              dispatch( serverFailed() );
              // console.log( "Error getDoc========>", error );
              return false;
            } );
          return doc;
        } else
        {
          const doc = await axios
            .get(
              URL +
              '?filters={"id":"' +
              id +
              '","_mode" : "NIN"},{"name":"' +
              docName +
              '","_mode" : "EXACT"}',
              { headers }
            )
            .then( ( response ) =>
            {
              console.log( "Success getDoc  ========>", response );
              const { error } = response.data;
              if ( error == true )
              {
                dispatch( tokenExp() );
              } else
              {
                let data = response.data.data.content;

                dispatch( documentListByNameSuccess( { data } ) );
                return data;
              }
            } )
            .catch( ( error ) =>
            {
              console.log( "Error getDoc========>", error );
              dispatch( serverFailed() );
              return false;
            } );
          return doc;
        }
      } catch ( e )
      {
        dispatch( serverFailed() );
        return false;
        // return console.error( e.message );
      }
    };

export const resetfilterData = () => async ( dispatch ) =>
{
  try
  {
    dispatch( documentFilterFailure() );
  } catch ( e )
  {
    return console.error( e.message );
  }
};
export const filterDocumentData =
  ( { row, flag } ) =>
    async ( dispatch ) =>
    {
      try
      {
        if ( flag == true )
        {
          dispatch( documentFilterSuccess( { row } ) );
        } else
        {
          dispatch( documentFilterFailure() );
          dispatch( documentSuccess( { row } ) );
        }
      } catch ( e )
      {
        dispatch( serverFailed() );
        return false;
        // return console.error( e.message );
      }
    };
export const deleteDocumentData = () => async ( dispatch ) =>
{
  try
  {
    dispatch( documentListByNameFailure() );
  } catch ( e )
  {
    return console.error( e.message );
  }
};
export const resetSave = () => async ( dispatch ) =>
{
  try
  {
    dispatch( documentDetailsFail() );
  } catch ( e )
  {
    return console.error( e.message );
  }
};
export const getDocumentById = () => async ( dispatch ) =>
{
  try
  {
    const name = localStorage.getItem( "token" );
    const token = decodeData( name );
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get( URL + "/1", { headers } )
      .then( ( response ) =>
      {
        console.log( "Success With id getDocumentById ========>", response );
        const { error } = response.data;
        if ( error == true )
        {
          dispatch( tokenExp() );
        }
      } )
      .catch( ( error ) =>
      {
        dispatch( serverFailed() );
        return false;
        console.log( "Error getDocumentById ========>", error.response.data );
      } );
    return doc;
  } catch ( e )
  {
    dispatch( serverFailed() );
    return false;
    // return console.error( e.message );
  }
};

export const saveDocument =
  ( { documentData } ) =>
    async ( dispatch ) =>
    {
      try
      {
        let data = JSON.stringify( documentData );
        const name = localStorage.getItem( "token" );
        const token = decodeData( name );
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
          shouldrefresh: "true",
        };
        const doc = await axios
          .post( URL, data, { headers } )
          .then( ( response ) =>
          {
            console.log( "Success With saveDocument ========>", response );
            const { error } = response.data;
            if ( error == true )
            {
              dispatch( tokenExp() );
            } else
            {
              const { data } = response.data;
              dispatch( documentDetailsSuccess( { data } ) );

              return data;
            }
          } )
          .catch( ( error ) =>
          {
            if (
              error == true &&
              error.response.data.errorCode !== "INTERNAL_SERVER_ERROR"
            )
            {
              dispatch( serverFailed() );
              return false;
            }

            console.log( "Error saveDocument ========>", error.response.data );
            const { data } = error.response;

            return data;
          } );
        return doc;
      } catch ( e )
      {
        dispatch( serverFailed() );
        // return console.error( e.message );
        return false;
      }
    };

export const deleteDocumentById =
  ( { documentId } ) =>
    async ( dispatch ) =>
    {
      try
      {
        const name = localStorage.getItem( "token" );
        const token = decodeData( name );
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
          shouldrefresh: "true",
        };
        let response = await axios
          .delete( URL + "/" + documentId, { headers } )
          .then( ( response ) =>
          {
            console.log( "Success With deleteDocumentById ========>", response );
            dispatch( documentDeleteSuccess() );
            return true;
          } )
          .catch( ( error ) =>
          {
            if ( error.response )
            {
              console.log( "error.response.status" );
              console.log( error.response.status );
              console.log( "error.response.data" );
              console.log( error.response.data );
            }
            dispatch( documentDeleteFail() );
            return false;
            // return error.response.data;
          } );
        return response;
      } catch ( e )
      {
        dispatch( serverFailed() );
        return false;
        // return console.error( e.message );
      }
    };

export const resetDelete = () => async ( dispatch ) =>
{
  try
  {
    dispatch( resetDeleteSuccess() );
  } catch ( e )
  {
    return console.error( e.message );
  }
};
