import { Button } from "@mui/material";

export const ButtonCompo = ({ disable,onClick,sx,className ,type,variant,name ,fullWidth,size}) => {
  return (
    <Button type={type}  style={{textTransform: 'none'}} disabled={disable} size={size} sx={sx}  className={className} fullWidth={fullWidth} variant={variant} onClick={onClick}>
      {name}
    </Button>
  );
};
ButtonCompo.defaultProps = {
  variant: "contained",
  type: 'button',
}
