import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import PaymentTwoToneIcon from "@mui/icons-material/PaymentTwoTone";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Grid, Paper } from "@mui/material";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import { logOut } from "../Login/login.slice";
import AttachPhotoView from "../onlineAppProcess/AttachPhotoView";
import { downloadF } from "../PDF/PDFDownload";
import { getProfile } from "../Student/student.slice";
import { downloadFile, viewMultipleFile } from "../UploadFile/file.slice";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
const DeclarationPreview = ({
  data,
  student,
  getProfile,
  viewMultipleFile,
  flag,
  prefCapList,
  downloadFile,
  backToCap,
  backtoList,
  logOut,
  loginUser,
  goToStudentForm,
  studFlag,
}) => {
  const [open, setOpen] = useState(false);
  let navigate = useNavigate();
  useEffect(() => {
    if (data !== undefined) {
      let sign = {
        fileName: data.signature,
        name: "signature",
      };
      let profile = {
        fileName: data.profilePic,
        name: "profilePic",
      };
      viewMultipleFile({
        file: sign,
      });
      viewMultipleFile({
        file: profile,
      });
    } else {
      getProfile().then((resp) => {
        if (resp) {
          let sign = {
            fileName: resp.signature,
            name: "signature",
          };
          let profile = {
            fileName: resp.profilePic,
            name: "profilePic",
          };
          viewMultipleFile({
            file: sign,
          });
          viewMultipleFile({
            file: profile,
          });
        }
      });
    }
  }, []);
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const UserApplicationFormPdf = () =>{
  //   let userAppReportURL = endpoints.userApplicationFormPdf;
  // downloadF({url:userAppReportURL+"/"+prefCapList.id})
  // };
  const GoBack = () => {
    let role = localStorage.getItem("role");
    console.log(role);
    if (role == "trust") {
      backToCap();
    } else {
      if (flag == true) {
        backtoList();
      } else if (studFlag === true) {
        goToStudentForm();
      } else {
        navigate("/applicationForm");
      }
    }
  };
  useEffect(() => {
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
  });

  return (
    <>
      <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={2}
          spacing={2}
          columnSpacing={1}
          container
          sx={{ m: 0.5 }}
          justifyContent="space-evenly"
        >
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={12} md={11}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 20 }}
                label="View Profile"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={1}>
              <ButtonCompo
                size="medium"
                type="button"
                variant="contained"
                name="Back"
                onClick={GoBack}
              />
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  icon={<InfoIcon />}
                  textColor="secondary"
                  indicatorColor="secondary"
                  label="Basic Details"
                  value="1"
                />
                <Tab
                  icon={<HomeIcon />}
                  textColor="secondary"
                  indicatorColor="secondary"
                  label="Address Details"
                  value="2"
                />
                {/* <Tab
                  icon={<AccessibleIcon />}
                  textColor="secondary"
                  indicatorColor="secondary"
                  label="Special Reservation Details"
                  value="3"
                /> */}

                {/* {prefCapList !== undefined && (
                  <Tab
                    icon={<DocumentScannerIcon />}
                    textColor="secondary"
                    indicatorColor="secondary"
                    label="Qualification & Document Details"
                    value="4"
                  />
                )} */}
                {flag == true && (
                  <Tab
                    icon={<DocumentScannerIcon />}
                    textColor="secondary"
                    indicatorColor="secondary"
                    label="Qualification & Document Details"
                    value="4"
                  />
                )}
                <Tab
                  icon={<PhotoSizeSelectActualIcon />}
                  textColor="secondary"
                  indicatorColor="secondary"
                  label="Photo and Sign"
                  value="5"
                />

                {prefCapList !== undefined && (
                  <Tab
                    icon={<PaymentTwoToneIcon />}
                    textColor="secondary"
                    indicatorColor="secondary"
                    label="Payment Details"
                    value="6"
                  />
                )}
              </TabList>
            </Box>
            <TabPanel value="1">
              <Step1 data={data !== undefined ? data : student.applicant} />
            </TabPanel>
            <TabPanel value="2">
              <Step2 data={data !== undefined ? data : student.applicant} />
            </TabPanel>
            <TabPanel value="3">
              <Step3 data={data !== undefined ? data : student.applicant} />
            </TabPanel>
            <TabPanel value="5">
              <Step4 data={data !== undefined ? data : student.applicant} />
            </TabPanel>
            <TabPanel value="4">
              <Step5 data={data !== undefined ? data : student.applicant} />
            </TabPanel>

            <TabPanel value="6">
              <Step6 data={prefCapList} />
            </TabPanel>
          </TabContext>

          <AttachPhotoView openparam={open} closeView={setOpen} />
        </Box>
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({
  fileData: state.file,
  student: state.student,
  loginUser: state.user,
});

const mapDispatchToProps = {
  viewMultipleFile,
  downloadFile,
  downloadF,
  getProfile,
  logOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeclarationPreview);
