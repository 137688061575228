import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
const initialState = {
    alertMsg:'',
    show:false,
  };
export const AlertSlice = createSlice({
  name: "alertInfo",
  initialState,
  reducers: {
    createAlert: (state = cloneDeep(initialState), action) => {
        const {message}= action.payload
        return {
          ...state,
          show: true,
          alertMsg:message,
        };
      },
      resetAlert: (state = cloneDeep(initialState), action) => {
        return {
          ...state,
          show: false,
          alertMsg:'',
        };
      },
  },
});
export const {
    createAlert,
    resetAlert
  } = AlertSlice.actions;

export default AlertSlice.reducer

export const getAlert = ({message,type}) => async (dispatch) => {
    dispatch(createAlert({message: message}));
}
export const resetGenratedAlert = () => async (dispatch) => {
    dispatch(resetAlert());
}