import { Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicTable from "../../components/Comman/MaterialUIRTTable";
let columns;

const ListOfApplicationTable = ({
  data,
  onConfirm,
  selectCourse,
  addChoiceCode,
  selectPreferences,
}) => {
  const [password, setpassword] = useState("");
  const columnExtensions = [
    { columnName: "id", width: 100 },
    { columnName: "courseType", width: 300 },
    { columnName: "instituteName" },
  ];
  columns = [
    { name: "id", title: "Sr. No." },
    { name: "courseName", title: "Course" },
    { name: "institute", title: "Institute" },
  ];

  const submitHandler = (e) => {
    e.preventDefault();
    onConfirm(password);
  };

  return (
    <>
      <Grid container justifyContent="space-evenly" direction="row" spacing={1}>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={2}
          spacing={2}
          columnSpacing={1}
          container
          sx={{ m: 0.5 }}
          justifyContent="space-evenly"
        >
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            {/* <Grid item xs={12} sm={12} md={0.5}></Grid> */}
            <Grid item xs={12} sm={4} md={4}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 20 }}
                label="Option Form for CAP Round I"
              />
            </Grid>
            <Grid item xs={12} sm={2.5} md={2.5}>
              <ButtonCompo
                size="medium"
                type="button"
                variant="contained"
                name="Select Course"
                fullWidth={true}
                onClick={selectCourse}
              />
            </Grid>
            <Grid item xs={12} sm={2.8} md={2.8}>
              <ButtonCompo
                size="medium"
                type="button"
                variant="contained"
                name="Add Choice Code"
                fullWidth={true}
                onClick={addChoiceCode}
              />
            </Grid>
            <Grid item xs={12} sm={2.7} md={2.7}>
              <ButtonCompo
                size="medium"
                type="button"
                variant="contained"
                name="Select Prefrences"
                fullWidth={true}
                onClick={selectPreferences}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          container
          justifyContent="space-evenly"
        >
          <Grid item sm={12} xs={12} md={12} lg={12}>
            <DynamicTable
              tableHead={[
                {
                  title: "Course",
                  name: "courseName",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "left",
                },
                {
                  title: "Institute",
                  name: "institute",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "left",
                },
              ]}
              data={data}
              isActionColActive={false}
            />
            {/* <ConfirmCapTable
              rows={data}
              columns={columns}
              columnExtensions={columnExtensions}
            /> */}
          </Grid>
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          container
          justifyContent="space-evenly"
        >
          <Grid
            item
            sm={12}
            xs={12}
            md={9.5}
            lg={9.5}
            rowSpacing={1}
            // columnSpacing={{xs:6, sm:6, md:1}}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Enter Password :"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <TextField
                size="small"
                color="primary"
                name="password"
                id="password"
                type="password"
                onChange={(e) => {
                  setpassword(e.target.value);
                }}
                value={password}
              ></TextField>
            </Grid>
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={2.5}
            lg={2.5}
            rowSpacing={4}
            // columnSpacing={{xs:6, sm:6, md:1}}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label=""
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name="Confirm Button"
                fullWidth={true}
                onClick={submitHandler}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 12, color: "#808080" }}
              label="Note: The password should be same as your Login Password"
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default ListOfApplicationTable;
