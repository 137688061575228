import { Navigate, useLocation } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
    let location = useLocation();
    const token=localStorage.getItem('token');
    // const { isAuthenticated } = useSelector(state => state.user);
console.log(token)
if (token==null||location.pathname==="/")  {
        return <Navigate to="/login" state={{ from: location }} />;
    }

    return children;
};

export default PrivateRoute;