import React from "react";
import ExportCSV from "../../components/Comman/ExportCSV";
import { generatePDF } from "../../components/Comman/GeneratePDF";
import { FontAwesomeIconCompo } from "../../components/Comman/IconButton";
const InstExcelData = ({ csvData, instituteList, fileName, type }) => {
  const tablecol = instituteList.map((title) => {
    if (title.title !== "Action") {
      return title.title;
    }
  });
  let index = 0;
  const instituteData = csvData.map((csvData) => {
    index = index + 1;
    let inst = {
      "Sr. No.": index,
    };

    instituteList.map((title) => {
      if (title.name === "trustName") {
        inst = {
          ...inst,
          "Trust Name": csvData.trustName,
        };
      }
      if (title.name === "name") {
        inst = {
          ...inst,
          "Institute Name": csvData.name,
        };
      }
      if (title.name === "instShortName") {
        inst = {
          ...inst,
          "Institute Short Name": csvData.instShortName,
        };
      }
      if (title.name === "instAffi") {
        inst = {
          ...inst,
          "Institute Affiliation": csvData.instAffi,
        };
      }
      if (title.name === "instAddr") {
        inst = {
          ...inst,
          "Institute Address": csvData.instAddr,
        };
      }
      if (title.name === "isEducational") {
        inst = {
          ...inst,
          "Educational Status": csvData.isEducational,
        };
      }
      if (title.name === "epfNo") {
        inst = {
          ...inst,
          "EPF Number": csvData.epfNo,
        };
      }
      if (title.name === "profTaxNo") {
        inst = {
          ...inst,
          "Tax Number": csvData.profTaxNo,
        };
      }
      if (title.name === "tanNo") {
        inst = {
          ...inst,
          "TAN Number": csvData.tanNo,
        };
      }
      if (title.name === "panNo") {
        inst = {
          ...inst,
          "PAN Number": csvData.panNo,
        };
      }
      if (title.name === "uniCode") {
        inst = {
          ...inst,
          "University Code": csvData.uniCode,
        };
      }

      if (title.name === "aicteCode") {
        inst = {
          ...inst,
          "AICTE Code": csvData.aicteCode,
        };
      }
      if (title.name === "dteCode") {
        inst = {
          ...inst,
          "DTE Code": csvData.dteCode,
        };
      }
      if (title.name === "minority") {
        inst = {
          ...inst,
          Minority: csvData.minority,
        };
      }
      if (title.name === "autonomousStatus") {
        inst = {
          ...inst,
          "Autonomous Status": csvData.autonomousStatus,
        };
      }
      if (title.name === "minorityReligion") {
        inst = {
          ...inst,
          "Minority Religion": csvData.minorityReligion,
        };
      }
      if (title.name === "instituteType") {
        inst = {
          ...inst,
          "Institute Type": csvData.instituteType,
        };
      }
      if (title.name === "isActive") {
        inst = {
          ...inst,
          Status: csvData.isActive,
        };
      }
    });

    return inst;
  });

  if (type === "pdf") {
    return (
      <FontAwesomeIconCompo
        color="error"
        fontSize="small"
        title="PDF"
        baseClassName="fas"
        className="fa-file-pdf"
        onClick={() => generatePDF(instituteData, tablecol, "Institute Master")}
      />
    );
  }
  return <ExportCSV csvData={instituteData} fileName={fileName} />;
};
export default InstExcelData;
