import { FormHelperText, Grid, TextField } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import * as myConstClass from "../../config/messageconstant";
import { deleteClassData, getclassByName } from "../Class/class.slice";
import { getInstitute } from "../Institute/institute.slice";
import { getProgram } from "../ProgramMaster/program.slice";
import { getAlert } from "../../Alert/alert.slice";

let docs;

class ClassForm extends React.Component
{
  constructor( props )
  {
    super( props );
  }

  state = {
    id: 0,
    progId: "",
    trustId: "",
    instId: "",
    name: "",
    sortOrderNo: "",
    isActive: 1,
    formErrors: [],
    breadCrum: "",
    flag: 0,
  };

  handleChange = ( event ) =>
  {
    const fileUploaded = event.target.files[ 0 ];
    let reader = new FileReader();
    reader.onload = ( e ) =>
    {
      this.setState( { logo: e.target.result } );
    };
    reader.readAsDataURL( event.target.files[ 0 ] );
    this.props.handleFile( fileUploaded );
  };
  handleClick = ( event ) =>
  {
    this._name.click();
  };

  changeHandler = ( event ) =>
  {
    const { name, value } = event.target;

    this.setState( {
      ...this.state,
      [ name ]: value,
    } );
  };

  componentDidMount()
  {
    const { isEdit, getProgram, getInstitute } = this.props;
    getProgram().then( ( respo ) =>
    {
      if ( !respo )
      {
        this.props.getAlert( { message: myConstClass.serverMsg } );
      }
    } );

    getInstitute().then( ( respo ) =>
    {
      if ( !respo )
      {
        this.props.getAlert( { message: myConstClass.serverMsg } );
      }
    } );

    if ( isEdit.length > 0 )
    {
      this.setState( {
        id: isEdit[ 0 ].id,
        progId: isEdit[ 0 ].progNameId,
        instId: isEdit[ 0 ].instNameId,
        name: isEdit[ 0 ].name,
        sortOrderNo: isEdit[ 0 ].sortOrderNo,
        isActive: isEdit[ 0 ].isActive === "Active" ? 1 : 0,
      } );
    }
  }
  componentDidUpdate()
  {
    if ( this.props.class.isFetch )
    {
      const { id, progId, instId, name, sortOrderNo, isActive, flag } =
        this.state;

      docs = {
        programType: {
          id: progId,
        },
        tenant: {
          id: instId,
        },
        className: name,
        sortOrderNo: sortOrderNo,
        isActive: isActive,
        delStatus: 1,
      };

      if ( id != 0 )
      {
        docs = {
          ...docs,
          id: id,
        };
      }
      if ( this.props.class.classData.length == 0 )
      {
        if ( flag == 1 )
        {
          this.props.saveAndNext( docs );
          this.setState( {
            id: 0,
            progId: "",
            instId: "",
            name: "",
            sortOrderNo: "",
            isActive: "0",
            formErrors: [],
            flag: 0,
          } );
        } else if ( flag == 2 )
        {
          this.props.OnSave( docs );
        }
      }
      this.props.deleteClassData();
    }
  }

  handleFormValidation( nameValue )
  {
    const { progId, instId, name, sortOrderNo } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if ( progId === "" || progId === null )
    {
      formIsValid = false;
      formErrors[ "progNameErr" ] = myConstClass.programTypeMsg;
    }

    if ( instId === "" || instId === null )
    {
      formIsValid = false;
      formErrors[ "instNameErr" ] = myConstClass.instNameMsg;
    }

    if ( name.trim() === "" )
    {
      formIsValid = false;
      formErrors[ "classNameErr" ] = myConstClass.classNameMsg;
    }

    if ( sortOrderNo === "" && nameValue != "sortOrderNo" )
    {
      formIsValid = false;
      formErrors[ "sortOrderNoErr" ] = myConstClass.sortOrdNoMsg;
    }

    this.setState( { formErrors: formErrors } );
    return formIsValid;
  }

  submitHandler = ( e ) =>
  {
    e.preventDefault();
    this.setState( {
      flag: 2,
    } );
    const { id, progId, instId, name, sortOrderNo, isActive, flag } =
      this.state;

    docs = {
      programType: {
        id: progId,
      },
      tenant: {
        id: instId,
      },
      className: name,
      sortOrderNo: sortOrderNo,
      isActive: isActive,
      delStatus: 1,
    };

    if ( id != 0 )
    {
      docs = {
        ...docs,
        id: id,
      };
    }

    if ( this.handleFormValidation() )
    {
      this.props.OnSave( docs );
    }
  };
  saveHandler = ( e ) =>
  {
    e.preventDefault();
    this.setState( {
      flag: 1,
    } );
    const { id, progId, instId, name, sortOrderNo, isActive, flag } =
      this.state;

    docs = {
      programType: {
        id: progId,
      },
      tenant: {
        id: instId,
      },
      className: name,
      sortOrderNo: sortOrderNo,
      isActive: isActive,
      delStatus: 1,
    };

    if ( id != 0 )
    {
      docs = {
        ...docs,
        id: id,
      };
    }

    if ( this.handleFormValidation() )
    {
      this.props.saveAndNext( docs );
      this.setState( {
        id: 0,
        progId: "",
        instId: "",
        name: "",
        sortOrderNo: "",
        isActive: "0",
        formErrors: [],
        flag: 0,
      } );
    }
  };
  ChangeHandlerSearch = ( name, newValue ) =>
  {
    if ( newValue !== null )
    {
      this.setState( { [ name ]: newValue } );
    }
    else
    {
      this.setState( { [ name ]: '' } );
    }
  };
  render()
  {
    const { instId, name, progId, sortOrderNo, flag } = this.state;
    const { progNameErr, instNameErr, classNameErr, sortOrderNoErr } =
      this.state.formErrors;
    const { onCancel, programMaster, instituteList } = this.props;
    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            <Grid
              item
              xs={12}
              md={12}
              size="small"
              container
              justifyContent="flex-end"
            >
              <ButtonCompo
                size="small"
                type="button"
                variant="outlined"
                name=" Back to List"
                onClick={onCancel}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={1}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    name="text-black"
                    style={{ fontSize: 16 }}
                    label="*Institute Name : "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} mt={-2}>
                  <AutoComplete
                    keyColName={"id"}
                    value={instId}
                    name={"instId"}
                    options={instituteList?.institute}
                    onChange={this.ChangeHandlerSearch}
                  />
                  <FormHelperText error>
                    {instNameErr ? instNameErr : " "}{" "}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    name="text-black"
                    style={{ fontSize: 16 }}
                    label="*Select Program Type : "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} mt={-2}>
                  <AutoComplete
                    keyColName={"id"}
                    value={progId}
                    name={"progId"}
                    options={programMaster?.program}
                    onChange={this.ChangeHandlerSearch}
                  />
                  <FormHelperText error>
                    {progNameErr ? progNameErr : " "}{" "}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    name="text-black"
                    style={{ fontSize: 16 }}
                    label="*Year Name : "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="name"
                    size="small"
                    value={name}
                    fullWidth
                    onChange={this.changeHandler}
                    error={classNameErr ? true : false}
                    helperText={classNameErr ? classNameErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    name="text-black"
                    style={{ fontSize: 16 }}
                    label="*Sort Order No : "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="sortOrderNo"
                    size="small"
                    value={sortOrderNo}
                    fullWidth
                    onChange={this.changeHandler}
                    error={sortOrderNoErr ? true : false}
                    helperText={sortOrderNoErr ? sortOrderNoErr : " "}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12}></Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={1}
                lg={1}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Save"
                  fullWidth={true}
                />
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={1.7}
                lg={1.7}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Save and Next"
                  onClick={this.saveHandler}
                  fullWidth={true}
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={1.7}
                lg={1.7}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Cancel"
                  onClick={onCancel}
                  fullWidth={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}

const mapStateToProps = ( state ) => ( {
  programMaster: state.program,
  instituteList: state.institute,
  common: state.common,
  class: state.class,
} );

const mapDispatchToProps = {
  getProgram,
  getInstitute,
  getclassByName,
  deleteClassData,
  getAlert
};

export default connect( mapStateToProps, mapDispatchToProps )( ClassForm );
