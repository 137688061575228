import { FormHelperText, Grid, Paper } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import Alert from "../../Alert/Alert";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicTable from "../../components/Comman/MaterialUIRTTable";
import { DirectionSnackbar } from "../../components/Comman/SnakBarCompo";
import { decodeData } from "../../components/Comman/Util/Base64";
import { Loading1 } from "../../components/Loading1";
import endpoints from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import SearchExternalPlacedStudList from "../../components/SearchTable";
import {
  getApplicant,
  getApplicantDetailsById,
  getApplicantPrefById,
  getCapRoundById,
  resetDelete,
  saveapplicant,
  saveConfirmPassword,
  saveQualificationDetails,
  saveRoundPreference,
} from "../ApplicationForm/applicant.slice";
import { getCategoryType } from "../CategoryMaster/category.slice";
import { logOut } from "../Login/login.slice";
import {
  getCourseDataById,
  getDeclrationById,
} from "../onlineAppProcess/declaration.slice";
import { getApplicationsByPrgTypeId } from "../ProgramMaster/program.slice";
import QualificationDetails from "../QualificationDetails/Index";
import { getProfile } from "../Student/student.slice";
import StudentPreview1 from "../StudentPreview1/index";
import { deleteFileData } from "../UploadFile/file.slice";
import ApplicationInstructions from "./ApplicationInstruc";
import ChallanForm from "./ChallanForm";
import ChoiceCourseInstitudeForm from "./ChoiceCourseInstitudeForm";
import ConfirmCapRound from "./ConfirmCapRound";
import DocumentUpload from "./DocumentUpload";
import FeePayment from "./FeePayment";
import PriorityProcess from "./PriorityProcess";
import PriorityProcessPreferenceForm from "./PriorityProcessInformation";
import { getAlert } from "../../Alert/alert.slice";
import StudentApplyApplicationDelcare from "../StudentDirectAdmission/index";

const InstituteMaster = ({
  getProfile,
  userApplication,
  studentInfo,
  selectPreference,
  appliData,
  getDeclrationById,
  getCourseDataById,
  applicationList,
  getCapRoundById,
  getApplicationsByPrgTypeId,
  saveapplicant,
  getApplicant,
  getApplicantPrefById,
  saveRoundPreference,
  deleteFileData,
  saveConfirmPassword,
  resetDelete,
  loginUser,
  logOut,
  getAlert,
  getCategoryType,
  files,
  gotoBack,
}) => {
  const [row, setRows] = useState([]);
  const [id1, setId] = useState(4);
  const [choiceCodeData, setChoiceCodeData] = useState({});
  const [flag, setFlag] = React.useState(false);
  const [filteredList, setfilterList] = React.useState([]);
  const [trustList, setTrustList] = React.useState([]);
  const [saveAllData, setSaveAllData] = React.useState({});
  const [openDocUpload, setopenDocUpload] = React.useState(false);
  const [openQua, setOpenQua] = React.useState(false);
  const [openPerference, setOpenPerference] = React.useState(false);
  const [choiseInstitute, setChoiseInstitute] = React.useState(false);
  const [priorityProcess, setpriorityProcess] = React.useState(false);
  const [fess, setFees] = React.useState(false);
  const [chalanForm, setChalanForm] = React.useState(false);
  const [savepriorityProcess, setSavepriorityProcess] = React.useState(false);
  const [ConfimCap, setConfimCap] = React.useState(false);
  const [feePay, setFeePay] = React.useState(false);
  const [openSnak, setOpenSnak] = useState(false);
  const [openMsg, setOpenMsg] = useState(false);
  const [listErrorMsg, setListErrorMsg] = useState(false);
  const [instructionFlag, setInstructionFlag] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const applicationformId = localStorage.getItem("applicationformId");
    console.log(applicationformId);
    if (applicationformId) {
      getDeclrationById({ appliId: applicationformId }).then((response) => {
        if (response) {
          setFlag(true);
        } else {
          getAlert({ message: myConstClass.serverMsg });
        }
      });
    }
    deleteFileData();
    getProfile().then((response) => {
      console.log(response);
      if (response) {
        getApplicationsByPrgTypeId({ intrestedIn: response.intrestIn }).then(
          (resp) => {
            if (!resp) {
              getAlert({ message: myConstClass.serverMsg });
            }
          }
        );
        if (selectPreference !== undefined) {
          setOpenPerference(true);
          setFlag(false);
        } else if (selectPreference === undefined) {
          selectPreference = true;
        }
      } else {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
    getCategoryType();
  }, []);

  useEffect(() => {
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
  });

  const getRowData = async (id) => {
    getDeclrationById({ appliId: id }).then((response) => {
      if (response) {
        setFlag(true);
      } else {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
  };

  const getQualiDetails = (data) => {
    let QualiData = [];

    data.map((data1) => {
      let bData = {
        university: data1.university,
        yearOfPass: data1.passingYear,
        marksObtained: data1.marksObtain,
        marksOutof: data1.marksOutOf,
        percentage: data1.percentage,
        isActive: 1,
        delStatus: 1,
        qualification: {
          id: data1.id,
        },
        examSeatNo: data1.examSeat,
        certificateNo: data1.certificate,
        nameOfSchoolCollege: data1.school,
        country: data1.country,
        state: data1.board,
        board: data1.state, //board column in database is qualificationStatus => (Values are Completed & Pursuing)
      };
      QualiData.push(bData);
    });
    let data1 = {
      ...saveAllData,
      userQualificationDetails: QualiData,
    };
    console.log(data1);
    setSaveAllData(data1);
    setopenDocUpload(true);
  };

  const docData = (data) => {
    setOpenPerference(true);
    let data1 = {
      ...saveAllData,
      userDocumentDetails: data,
    };
    setSaveAllData(data1);
  };
  const PriorityProcessData = (data) => {
    setChoiceCodeData(data);
    setChoiseInstitute(true);
  };
  const ChoiceCourseInstitudeData = (data) => {
    setRows((prevDocs) => {
      return [...prevDocs, data];
    });
  };
  const priorityProcessPreference = (data) => {
    setSavepriorityProcess(true);
  };
  const getUserRowData = (id) => {
    getDeclrationById({ appliId: id }).then((resp) => {
      if (!resp) {
        getAlert({ message: myConstClass.serverMsg });
      } else {
        getCourseDataById({ ids: resp?.programTitleIds }).then((response) => {
          if (!response) {
            getAlert({ message: myConstClass.serverMsg });
          } else {
            navigate("/userdeclarationpreview");
            localStorage.setItem("listLocation", "/applicationForm");
          }
        });
      }
    });
  };
  const ConfirmCapRoundDetails = (data) => {
    try {
      swal("Do you want to confirm", {
        buttons: ["Cancel", true],
      }).then((willDelete) => {
        if (willDelete) {
          let URL = endpoints.confirmPassword;
          let resp = "";
          let preferenceData = "";
          let roundDetails = [];
          const capdata = userApplication.capRoundData.filter(
            (capData) => capData.id === selectPreference
          );
          const name = localStorage.getItem("token");
          const token = decodeData(name);
          const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: token,
            shouldrefresh: "true",
          };
          resp = axios
            .get(URL + "?password=" + data, { headers })
            .then((response) => {
              const { message } = response.data.data;
              if (
                message === "Correct Password." &&
                selectPreference !== undefined
              ) {
                row.map((rowData) => {
                  let roundPrefeData = {
                    programType: {
                      id: appliData.applicationData.programTypeId,
                    },
                    programTitle: {
                      id: rowData.courseId,
                    },
                    tenant: {
                      id: rowData.instituteId,
                    },
                    isAllocated: 0,
                    isActive: 1,
                    delStatus: 1,
                    prefNo: rowData.id,
                    applicantStatus: 0,
                    instituteStatus: 0,
                  };
                  roundDetails.push(roundPrefeData);
                });

                preferenceData = {
                  userApplication: {
                    id: userApplication.userApplicationData.id,
                  },
                  applicationRound: {
                    id: capdata[0].id,
                  },
                  applyDateTime: new Date(),
                  paymentApplicable: capdata[0].paymentApplicable,
                  paidStatus: 1,
                  amount: capdata[0].amount,
                  paymentRef: 1,
                  payMod: "Cash",
                  isActive: 1,
                  delStatus: 1,
                  userRoundPrefrences: roundDetails,
                };

                console.log(preferenceData);

                saveRoundPreference({ roundData: preferenceData }).then(() => {
                  if (
                    appliData.applicationData.paymentApplicable === 1 &&
                    appliData.applicationData.paymentType === 0
                  ) {
                    setChalanForm(true);
                  } else if (
                    appliData.applicationData.paymentApplicable === 1 &&
                    appliData.applicationData.paymentType === 1
                  ) {
                    setChalanForm(true);
                    setFeePay(true);
                  } else if (
                    appliData.applicationData.paymentApplicable === 0
                  ) {
                    getApplicant({
                      appliId: appliData.applicationData.id,
                    }).then((response) => {
                      if (selectPreference == undefined) {
                        getApplicantPrefById({
                          capId: userApplication.capRoundData[0].id,
                        }).then(() => {
                          setFees(true);
                        });
                      } else {
                        const capdata = userApplication.capRoundData.filter(
                          (capData) => capData.id === selectPreference
                        );
                        getApplicantPrefById({ capId: capdata[0].id }).then(
                          () => {
                            setFees(true);
                          }
                        );
                      }
                    });
                  }
                });
              } else if (selectPreference !== undefined) {
                setOpenSnak(true);
                setOpenMsg("Please enter valid password.");
              }
              if (
                message === "Correct Password." &&
                selectPreference === undefined
              ) {
                row.map((rowData) => {
                  let roundPrefeData = {
                    programType: {
                      id: appliData.applicationData.programTypeId,
                    },
                    programTitle: {
                      id: rowData.courseId,
                    },
                    tenant: {
                      id: rowData.instituteId,
                    },
                    isAllocated: 0,
                    isActive: 1,
                    delStatus: 1,
                    prefNo: rowData.id,
                    applicantStatus: 0,
                    instituteStatus: 0,
                  };
                  roundDetails.push(roundPrefeData);
                });

                preferenceData = {
                  userApplication: {
                    ...saveAllData,
                  },
                  userApplicationRoundDetail: {
                    userApplication: {
                      id: "",
                    },
                    applicationRound: {
                      id: userApplication.capRoundData[0].id,
                    },
                    applyDateTime: new Date(),
                    paymentApplicable:
                      userApplication.capRoundData[0].paymentApplicable,
                    paidStatus: 1,
                    amount: userApplication.capRoundData[0].amount,
                    paymentRef: 1,
                    payMod: "Cash",
                    isActive: 1,
                    delStatus: 1,
                    userRoundPrefrences: roundDetails,
                  },
                };
                console.log(preferenceData);

                saveapplicant({ appliData: preferenceData }).then(
                  (response) => {
                    if (!response) {
                      getAlert({ message: myConstClass.serverMsg });
                    } else if (
                      appliData.applicationData.paymentApplicable === 1 &&
                      appliData.applicationData.paymentType === 0
                    ) {
                      setChalanForm(true);
                    } else if (
                      appliData.applicationData.paymentApplicable === 1 &&
                      appliData.applicationData.paymentType === 1
                    ) {
                      setChalanForm(true);
                      setFeePay(true);
                    } else if (
                      appliData.applicationData.paymentApplicable === 0
                    ) {
                      getApplicant({
                        appliId: appliData.applicationData.id,
                      }).then((response) => {
                        if (!response) {
                          getAlert({ message: myConstClass.serverMsg });
                        } else if (selectPreference == undefined) {
                          getApplicantPrefById({
                            capId: userApplication.capRoundData[0].id,
                          }).then((response) => {
                            if (!response) {
                              getAlert({ message: myConstClass.serverMsg });
                            } else {
                              setChalanForm(true);
                              setFeePay(true);
                              setFees(true);
                            }
                          });
                        } else {
                          const capdata = userApplication.capRoundData.filter(
                            (capData) => capData.id === selectPreference
                          );
                          getApplicantPrefById({ capId: capdata[0].id }).then(
                            (response) => {
                              if (!response) {
                                getAlert({ message: myConstClass.serverMsg });
                              } else {
                                setChalanForm(true);
                                setFeePay(true);
                                setFees(true);
                              }
                            }
                          );
                        }
                      });
                    }
                  }
                );
              } else if (selectPreference === undefined) {
                setOpenSnak(true);
                setOpenMsg("Please enter valid Password.");
              }
              return message;
            })
            .catch((error) => {});
        }
      });
    } catch (e) {}
  };
  const ChallanDetails = () => {
    getApplicant({
      appliId: appliData.applicationData.id,
    }).then((response) => {
      if (!response) {
        getAlert({ message: myConstClass.serverMsg });
      } else if (selectPreference == undefined) {
        getApplicantPrefById({
          capId: userApplication.capRoundData[0].id,
        }).then((response) => {
          if (!response) {
            getAlert({ message: myConstClass.serverMsg });
          } else {
            setFees(true);
            setFeePay(true);
          }
        });
      } else {
        const capdata = userApplication.capRoundData.filter(
          (capData) => capData.id === selectPreference
        );
        getApplicantPrefById({ capId: capdata[0].id }).then((response) => {
          if (!response) {
            getAlert({ message: myConstClass.serverMsg });
          } else {
            setFees(true);
            setFeePay(true);
          }
        });
      }
    });
  };
  const getInstruct = (data) => {
    getCapRoundById({ appliId: appliData.applicationData.id }).then(
      (response) => {
        if (response) {
          setOpenQua(true);
        } else {
          getAlert({ message: myConstClass.serverMsg });
        }
      }
    );
    let data1 = {
      applicableFor: data.programTitleIds,
      minority: data.minority,
      seatAccepted: 0,
      status: 0,
      category: {
        id: studentInfo?.applicant.studCategory,
      },
      isSeatAllocatedByInstitute: 0,
      isSeatAcceptedByStudent: 0,
      paymentApplicable: appliData.applicationData.paymentApplicable,
      paidStatus: 0,
      amount: appliData.applicationData.amount,
      paymentRef: 0,
      paymentMode: "Cash",
      isActive: 1,
      delStatus: 1,
      applicationDeclare: {
        id: appliData.applicationData.id,
      },
      userRegistration: {
        id: studentInfo.applicant.id,
      },
    };
    setSaveAllData(data1);
    // setOpenQua(true);
  };
  const choiceCourse = () => {
    if (row.length !== 0) {
      setpriorityProcess(true);
      setListErrorMsg(false);
    } else {
      setListErrorMsg(true);
    }
  };

  const FeePaymentDetails = (data) => {
    getApplicant({ appliId: appliData.applicationData.id }).then((response) => {
      if (!response) {
        getAlert({ message: myConstClass.serverMsg });
      } else if (selectPreference == undefined) {
        getApplicantPrefById({
          capId: userApplication.capRoundData[0].id,
        }).then((response) => {
          if (!response) {
            getAlert({ message: myConstClass.serverMsg });
          } else {
            setFees(true);
          }
        });
      } else {
        const capdata = userApplication.capRoundData.filter(
          (capData) => capData.id === selectPreference
        );
        getApplicantPrefById({ capId: capdata[0].id }).then((response) => {
          if (!response) {
            getAlert({ message: myConstClass.serverMsg });
          } else {
            setFees(true);
          }
        });
      }
    });
  };
  const categoryData = (data) => {
    const { categoryIds } = data;
    console.log(categoryIds);
    console.log(data.programTitleIds);

    let data1 = {
      applicableFor: data.programTitleIds,
      minority: "abc",
    };
    console.log(data1);
    setSaveAllData(data1);
  };
  const inst = () => {
    setFlag(false);
    deleteFileData();
    localStorage.removeItem("applicationformId");
  };
  const backToInstructions = () => {
    setOpenQua(false);
  };
  const goToQualiCriteria = () => {
    setopenDocUpload(false);
  };
  const goToDocUpload = () => {
    if (selectPreference === undefined) {
      setOpenPerference(false);
      setopenDocUpload(true);
    } else if (selectPreference !== undefined) {
      gotoBack();
      navigate("/listofapplication");
    }
  };
  const goToPriorityProcess = () => {
    setChoiseInstitute(false);
  };
  const goToChoiceCourse = () => {
    setpriorityProcess(false);
    setChoiseInstitute(true);
  };
  const goToChallanForm = () => {
    window.location.replace("/applicationForm");
  };
  const goToPriorityProcessData = () => {
    setChoiseInstitute(false);
    setpriorityProcess(false);
    setOpenPerference(true);
  };
  const goToChoiceCourseData = () => {
    setpriorityProcess(false);
  };
  const goToPrioPro = () => {
    setSavepriorityProcess(false);
    setChoiseInstitute(false);
    setpriorityProcess(false);
    setOpenPerference(true);
  };
  const goToCourseChoice = () => {
    setSavepriorityProcess(false);
    setChoiseInstitute(true);
    setpriorityProcess(false);
  };
  const goToPref = () => {
    setSavepriorityProcess(false);
    setpriorityProcess(true);
  };
  const rowDelete = (id) => {
    swal("Do you want to delete", {
      buttons: ["Cancel", true],
    }).then((willDelete) => {
      if (willDelete) {
        const courseInstData = row.filter((rowData) => rowData.id !== id);
        console.log(courseInstData);
        setRows(courseInstData);
      }
    });
  };

  const resetPrefrance = (rowdata) => {
    let prefranceList = rowdata.map((listData, index) => {
      listData = {
        ...listData,
        id: index + 1,
      };
      return listData;
    });
    setRows(prefranceList);
  };

  const backtoList = () => {
    setChalanForm(false);
    setFlag(false);
    setOpenQua(false);
    setopenDocUpload(false);
    setOpenPerference(false);
    setChoiseInstitute(false);
    setpriorityProcess(false);
    setSavepriorityProcess(false);
    setFeePay(false);
    setFees(false);
    let intrestIn = localStorage.getItem("intrestIn");
    getApplicationsByPrgTypeId({ intrestedIn: intrestIn }).then((response) => {
      if (!response) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
  };
  const getFilteredTableData = () => {
    return applicationList?.application.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["applicationName"] &&
          currentRow["applicationName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["index"] &&
          currentRow["index"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["intrestedPrograms"] &&
          currentRow["intrestedPrograms"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["lastDate"] &&
          currentRow["lastDate"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  const onSearch = (value) => {
    setSearchValue(value);
  };
  const instFlag = () => {
    console.log("instructionFlag");
    setInstructionFlag(!instructionFlag);
  };
  return (
    <>
      <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
        {/* {applicationList.isServerFail && (
          <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />
        )}
        {userApplication.isServerFail && (
          <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />
        )}
        {studentInfo.isServerFail && (
          <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />
        )}
        {appliData.isServerFail && (
          <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />
        )}
        {files.isServerFail && (
          <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />
        )} */}

        <DirectionSnackbar
          open={openSnak}
          severity="error"
          msg={openMsg}
          closeSnak={setOpenSnak}
        />

        {!selectPreference && (
          <>
            {!flag && (
              <>
                {!instructionFlag && (
                  <>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      rowSpacing={2}
                      spacing={2}
                      columnSpacing={1}
                      container
                      sx={{ m: 0.5 }}
                      justifyContent="space-evenly"
                    >
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        rowSpacing={2}
                        columnSpacing={1}
                        container
                        justifyContent="space-evenly"
                      >
                        {/* <Grid item xs={12} sm={12} md={0.5}></Grid> */}
                        <Grid item xs={12} sm={9} md={9}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 20 }}
                            label="New Application Form"
                          />
                        </Grid>
                        <Grid
                          sx={{ marginBottom: 2 }}
                          item
                          xs={12}
                          sm={3}
                          md={3}
                        >
                          <SearchExternalPlacedStudList onSearch={onSearch} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <DynamicTable
                      tableHead={[
                        {
                          title: "Sr. No.",
                          name: "index",
                          positionCenter: true,
                          showInscreen: true,
                          alignCenter: "center",
                        },
                        {
                          title: "Applications",
                          name: "applicationName",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                        },
                        {
                          title: "Intrested Program",
                          name: "intrestedPrograms",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                        },
                        {
                          title: "Last Date",
                          name: "lastDate",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                        },
                        {
                          title: "Action",
                          name: "action",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "right",
                        },
                      ]}
                      data={getFilteredTableData()}
                      rowView={getRowData}
                      rowViewData={getUserRowData}
                      showHeadDelete={false}
                      showHeadEdit={false}
                      showApply={true}
                      showView={true}
                      showPegination={false}
                    />
                    {/* <InstitudeTable
                  data={applicationList?.application}
                  rowView={getRowData}
                  rowViewData={getUserRowData}
                /> */}
                    {!applicationList.isServerFail &&
                    !applicationList?.isAppliFetch ? (
                      <Loading1 size={50} />
                    ) : (
                      ""
                    )}
                  </>
                )}
                <br />
                <br />
                <StudentApplyApplicationDelcare instFlag={instFlag} />
              </>
            )}
            {flag &&
              !openQua &&
              !chalanForm &&
              !openDocUpload &&
              !openPerference &&
              !choiseInstitute &&
              !priorityProcess &&
              !savepriorityProcess &&
              !userApplication?.isFetchConfirm &&
              !feePay &&
              !fess && (
                <ApplicationInstructions
                  openDocUpload={getInstruct}
                  onCancel={inst}
                />
              )}
            {openQua &&
              !openDocUpload &&
              !chalanForm &&
              !openPerference &&
              !choiseInstitute &&
              !priorityProcess &&
              !savepriorityProcess &&
              !feePay &&
              !fess && (
                <QualificationDetails
                  appliId={id1}
                  getQualiDetails={getQualiDetails}
                  backToInstructions={backToInstructions}
                  saveAllData={saveAllData}
                />
              )}
            {openDocUpload &&
              !chalanForm &&
              !openPerference &&
              !choiseInstitute &&
              !priorityProcess &&
              !savepriorityProcess &&
              !userApplication?.isFetchConfirm &&
              !feePay &&
              !fess && (
                <DocumentUpload
                  saveAllData={saveAllData}
                  docData={docData}
                  onCancel={goToQualiCriteria}
                />
              )}
          </>
        )}
        {openPerference &&
          !chalanForm &&
          !choiseInstitute &&
          !priorityProcess &&
          !savepriorityProcess &&
          !userApplication?.isFetchConfirm &&
          !feePay &&
          !fess && (
            <PriorityProcess
              choiceCodeData={choiceCodeData}
              saveAllData={saveAllData}
              PriorityProcessData={PriorityProcessData}
              onCancel={goToDocUpload}
            />
          )}
        {choiseInstitute &&
          !chalanForm &&
          !priorityProcess &&
          !savepriorityProcess &&
          !userApplication?.isFetchConfirm &&
          !fess && (
            <>
              <ChoiceCourseInstitudeForm
                saveAllData={saveAllData}
                onSave={ChoiceCourseInstitudeData}
                data={choiceCodeData}
                rowData={row}
              />

              {listErrorMsg && (
                <FormHelperText error>
                  {"*Please select minimum 1 preference"}{" "}
                </FormHelperText>
              )}
              <DynamicTable
                tableHead={[
                  // {
                  //   title: "Sr. No.",
                  //   name: "index",
                  //   positionCenter: true,
                  //   showInscreen: true,
                  //   alignCenter: "left",
                  // },
                  {
                    title: "Course",
                    name: "courseName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                  {
                    title: "Institute",
                    name: "institute",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                  {
                    title: "Action",
                    name: "action",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                ]}
                data={row}
                rowView={getRowData}
                rowViewData={getUserRowData}
                rowDelete={rowDelete}
                showHeadDelete={true}
                showHeadEdit={false}
                showApply={false}
                showView={false}
              />

              {/* <ChoiseCourseInstituteTable rowDelete={rowDelete} data={row} /> */}
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="right"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1}
                  lg={1}
                  container
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Back"
                    fullWidth={true}
                    onClick={goToPriorityProcess}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={2.5}
                  lg={2.5}
                  container
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="contained"
                    name="Save and Proceed"
                    fullWidth={true}
                    onClick={choiceCourse}
                  />
                </Grid>
              </Grid>
            </>
          )}
        {priorityProcess &&
          !chalanForm &&
          !savepriorityProcess &&
          !userApplication?.isFetchConfirm &&
          !feePay &&
          !fess && (
            <PriorityProcessPreferenceForm
              data={row}
              onCancel={goToChoiceCourse}
              toChoiceCode={goToChoiceCourseData}
              toPriorityProcess={goToPriorityProcessData}
              resetData={resetPrefrance}
              priorityProcessPreference={priorityProcessPreference}
            />
          )}
        {savepriorityProcess &&
          !chalanForm &&
          !userApplication?.isFetchConfirm &&
          !feePay &&
          !fess && (
            <ConfirmCapRound
              data={row}
              onConfirm={ConfirmCapRoundDetails}
              selectCourse={goToPrioPro}
              addChoiceCode={goToCourseChoice}
              selectPreferences={goToPref}
            />
          )}
        {chalanForm &&
          !feePay &&
          !fess &&
          appliData.applicationData.paymentApplicable === 1 &&
          appliData.applicationData.paymentType === 0 && (
            <ChallanForm onSave={ChallanDetails} />
          )}
        {fess &&
          userApplication?.isFetchUserAppli &&
          selectPreference === undefined && (
            <StudentPreview1
              prefCapList={userApplication?.prefCapList}
              data={userApplication?.userApplicationData}
              flag={true}
              backtoList={backtoList}
            />
          )}
        {fess &&
          userApplication?.isFetchUserAppli &&
          selectPreference != undefined && (
            <StudentPreview1
              prefCapList={userApplication?.prefCapList}
              data={userApplication?.userApplicationData}
              backtoList={backtoList}
            />
          )}
        {feePay &&
          !fess &&
          appliData.applicationData.paymentApplicable === 1 &&
          appliData.applicationData.paymentType === 1 && (
            <FeePayment OnSave={FeePaymentDetails} onCancle={goToChallanForm} />
          )}
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({
  userApplication: state.applicant,
  studentInfo: state.student,
  appliData: state.appli,
  files: state.file,
  loginUser: state.user,
  applicationList: state.program,
});

const mapDispatchToProps = {
  getApplicationsByPrgTypeId,
  getDeclrationById,
  saveQualificationDetails,
  saveRoundPreference,
  getApplicantDetailsById,
  saveConfirmPassword,
  saveapplicant,
  getCapRoundById,
  getApplicant,
  getApplicantPrefById,
  deleteFileData,
  resetDelete,
  logOut,
  getAlert,
  getCategoryType,
  getProfile,
  getCourseDataById,
};
export default connect(mapStateToProps, mapDispatchToProps)(InstituteMaster);
