import VisibilityIcon from "@mui/icons-material/Visibility";

import React from "react";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import { LabelCompo } from "../../components/Comman/Label";
import { connect } from "react-redux";
import { openFileInNewTab } from "../UploadFile/file.slice";
import { withStyles } from "@mui/styles";
const styles = (theme) => ({
  MuiTable: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
  },
  MuiTableHeadRoot: {
    textAlign: "left",
    padding: "8px",
    border: "1px solid #ddd",
  },

  MuiTableHeadRootDetail: {
    textAlign: "left",
    padding: "8px",
    border: "1px solid #ddd",
    width: 400,
  },

  MuiTableHeadRootMark: {
    textAlign: "left",
    padding: "8px",
    border: "1px solid #ddd",
    width: 100,
  },
});

const Step5 = ({ data, openFileInNewTab, classes }) => {
  const viewDocument = (fileName, e) => {
    e.stopPropagation();
    if (fileName !== "") {
      openFileInNewTab({ file: fileName });
    }
  };

  return (
    <>
      <LabelCompo
        className="text-black"
        style={{ fontSize: 20 }}
        label="Qualification Details"
      />
      <div style={{ overflow: "auto" }}>
        <br></br>
        <table className={classes.MuiTable}>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRootDetail}
              rowSpan={2}
            >
              Details
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRootDetail}
              rowSpan={2}
            >
              Qualification status
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              rowSpan={2}
            >
              Country
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              rowSpan={2}
            >
              State
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              rowSpan={2}
            >
              University
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              rowSpan={2}
            >
              School/Collage
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              rowSpan={2}
            >
              Exam Seat Number
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              rowSpan={2}
            >
              Certificate Number
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              rowSpan={2}
            >
              Passing Year
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRootMark}
              colSpan={2}
            >
              Aggregate Marks
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRootMark}
              rowSpan={2}
            >
              Percentage
            </td>
          </tr>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRootMark}
            >
              Marks Obtained
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRootMark}
            >
              Marks OutOff
            </td>
          </tr>
          {data.userQualificationDetails.map((detail) => (
            <tr>
              <td className={classes.MuiTableHeadRootDetail}>
                &nbsp;&nbsp;{detail.qualification}
              </td>

              <td className={classes.MuiTableHeadRootDetail}>
                &nbsp;&nbsp;{detail.board}
              </td>

              <td className={classes.MuiTableHeadRootDetail}>
                &nbsp;&nbsp;{detail.country}
              </td>

              <td className={classes.MuiTableHeadRootDetail}>
                &nbsp;&nbsp;{detail.state}
              </td>

              <td className={classes.MuiTableHeadRootMark}>
                &nbsp;&nbsp;{detail.university}
              </td>

              <td className={classes.MuiTableHeadRootMark}>
                &nbsp;&nbsp;{detail.school}
              </td>
              <td className={classes.MuiTableHeadRootMark}>
                &nbsp;&nbsp;{detail.examSeatNo}
              </td>
              <td className={classes.MuiTableHeadRootMark}>
                &nbsp;&nbsp;{detail.certificateNo}
              </td>
              <td className={classes.MuiTableHeadRootMark}>
                &nbsp;&nbsp;{detail.yearOfPass}
              </td>
              <td className={classes.MuiTableHeadRootMark}>
                &nbsp;&nbsp;{detail.marksObtained}
              </td>

              <td className={classes.MuiTableHeadRootMark}>
                &nbsp;&nbsp;{detail.marksOutof}
              </td>

              <td className={classes.MuiTableHeadRootMark}>
                &nbsp;&nbsp;{detail.percentage}
              </td>
            </tr>
          ))}
        </table>
      </div>
      <br></br>

      <LabelCompo
        className="text-black"
        style={{ fontSize: 20 }}
        label="Document Details"
      />
      <br></br>
      <br></br>

      <table className={classes.MuiTable}>
        <tr>
          <td style={{ color: "#5a5a5a" }} className={classes.MuiTableHeadRoot}>
            &nbsp;&nbsp;Sr. No.
          </td>
          <td style={{ color: "#5a5a5a" }} className={classes.MuiTableHeadRoot}>
            &nbsp;&nbsp;Document Name
          </td>
          <td style={{ color: "#5a5a5a" }} className={classes.MuiTableHeadRoot}>
            &nbsp;&nbsp;Action{" "}
          </td>
        </tr>

        {data.userDocumentDetails.map((detail, index) => (
          <tr>
            <td className={classes.MuiTableHeadRootMark}>
              &nbsp;&nbsp;{index + 1}
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;&nbsp;{detail.document}
            </td>
            <td className={classes.MuiTableHeadRootMark}>
              <MyComponentWithIconProps
                statusImage={VisibilityIcon}
                color="primary"
                fontSize="medium"
                title="View"
                onClick={(event) =>
                  viewDocument(detail.documentFileName, event)
                }
                sx={{ ml: 3.2 }}
              />
            </td>
          </tr>
        ))}
      </table>

      <br></br>
    </>
  );
};

const mapStateToProps = (state) => ({
  fileData: state.file,
});

const mapDispatchToProps = {
  openFileInNewTab,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Step5));
