import { Grid, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { makeStyles } from "@mui/styles";
import moment from "moment";
//import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import React from "react";
import { connect } from "react-redux";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import Header from "../../components/NewHeader";
import * as myConstClass from "../../config/messageconstant";
import {
  onlyNumber,
  validEmail,
} from "./../../components/Comman/Util/Validations";
import Navbar from "../../components/Navbar";
const useStyles = makeStyles((Theme) => ({
  hello: {
    position: "absolute",
    left: "15.17%",
    right: "15.08%",
    top: "63.4%",
    bottom: "32.6%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: "normal",
    fontsize: "36px",
    lineheight: "40px",
    color: "#000000",
  },
  buttonC: {
    position: "absolute",

    left: "39.17%",
    right: "39.17%",
    top: "79.7%",
    bottom: "14.8%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: 500,
    fontsize: "18px",
    lineheight: "40px",
    color: "#FFFFFF",
  },
  helper: {
    position: "absolute",
    left: "9.58%",
    right: "9.5%",
    top: "70%",
    bottom: "22%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: "normal",
    fontsize: "20px",
    lineheight: "40px",

    textAlign: "center",

    color: "#666666",
  },
  title: {
    position: "absolute",
    left: "43.42%",
    right: "43.33%",
    top: "57%",
    bottom: "39%",

    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: 500,
    fontsize: "55px",
    lineheight: "40px",
    color: "#000000",
  },
}));
// const ErrorPage = ({
//   getNewsDocNotification,
//   newsDocNotificationDocs,
//   latestnews,
//   getLatestNews,
//   latestnotification,
//   getNotification,
// }) => {
//   const classes = useStyles();
let docs;
class ForgotUserIdForm extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    id: 0,
    emailId: "",
    isActive: "1",
    otpCheck: "0",
    contactNo: "",
    enterOtp: "",
    dob: "03-10-2022",
    formErrors: [],
    checkedValues: [],
    breadCrum: "",
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    if (event.target.value === "" || onlyNumber.test(event.target.value)) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  componentDidMount() {
    let date = new Date();

    let frmDate = moment(date).format("DD/MM/YYYY");
    let fromYear = new Date(date).getFullYear();
    let month = new Date(date).getMonth();
    let day = new Date(date).getDate();
    let changedToDate = new Date(fromYear + 1, month, day - 1);
    let toYear = new Date(changedToDate).getFullYear();

    this.setState({
      dob: date,
    });
  }
  changeFromDate = (date) => {
    let frmDate = moment(date).format("DD/MM/YYYY");
    let fromYear = new Date(date).getFullYear();
    let month = new Date(date).getMonth();
    let day = new Date(date).getDate();
    let changedToDate = new Date(fromYear + 1, month, day - 1);
    let toYear = new Date(changedToDate).getFullYear();

    this.setState({
      dob: date,
    });
  };

  validateOtp = () => {
    const { enterOtp, emailId } = this.state;

    if (this.state.enterOtp != null) {
      this.props.saveOtp(enterOtp, emailId);
    } else {
      this.setState({
        formErrors: {},
      });
    }
  };

  generateOtp = () => {
    let formErrors = {};

    const { emailId, dob, otpCheck } = this.state;
    let birthdate = moment(dob).format("YYYY-MM-DD");
    if (this.handleValidation()) {
      if (this.state.emailId != null && this.state.dob != null) {
        this.props.submitOtp(emailId, birthdate, otpCheck);
      }
    }
  };
  handleValidation(name) {
    const { emailId } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (emailId === "") {
      formIsValid = false;
      formErrors["emailIdErr"] = myConstClass.emailIdMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  handleFormValidation(name) {
    const { dob, emailId, enterOtp } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (dob === "") {
      formIsValid = false;
      formErrors["dobErr"] = myConstClass.dobEmptyMsg;
    }

    if (emailId === "") {
      formIsValid = false;
      formErrors["emailIdErr"] = myConstClass.emailIdMsg;
    }

    if (enterOtp === "") {
      formIsValid = false;
      formErrors["otpEmptyErr"] = myConstClass.otpEmptyMsg;
    }

    if (!validEmail.test(emailId)) {
      formIsValid = false;
      formErrors["emailIdErr"] = myConstClass.invalidEmailIdMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const { id, dob, emailId, enterOtp, contactNo, isActive, otpCheck } =
      this.state;
    let dateOF = moment(dob).format("YYYY-MM-DD");

    docs = {
      id: id === 0 ? 0 : id,
      dob: dateOF,
      emailId: emailId,
      enterOtp: enterOtp,
      isActive: isActive,
      otpCheck: otpCheck,
    };
    if (this.handleFormValidation()) {
      this.props.OnSave(docs);
    }
  };
  render() {
    const {
      dob,
      emailId,
      enterOtp,
      contactNo,
      isActive,
      otpCheck,
      latestnews,
    } = this.state;

    const { dobErr, emailIdErr, invalidEmailIdErr, otpEmptyErr } =
      this.state.formErrors;

    const { onCancel, userData } = this.props;

    return (
      <>
        <div align="center">
          {/* <Header/> */}
          <Header />
          <Navbar />
          <div className="new_content">
            <div className="dash_login">
              <Grid item container justifyContent="space-between">
                <Grid item md={4}></Grid>
                <Grid item md={4}>
                  <div className="lgn_bx">
                    <h3 className="lgn_title">Forgot Username</h3>

                    <div className="lgn_container">
                      <div className="user_lgn">
                        <div className="user_label">
                          Enter Your Registered Email Id :
                        </div>
                        <div className="user_field">
                          <TextField
                            placeholder="abcd@gmail.com"
                            color="primary"
                            name="emailId"
                            size="small"
                            value={emailId}
                            onChange={this.changedHandler}
                            fullWidth
                            error={emailIdErr ? true : false}
                            helperText={emailIdErr ? emailIdErr : " "}
                          />
                        </div>
                      </div>

                      <div className="user_lgn">
                        <div className="user_label">
                          Enter Your Registered Date of Birth :
                        </div>
                        <div className="user_field">
                          <BasicDatePicker
                            name="dob"
                            id="dob"
                            value={dob}
                            maxDate={new Date()}
                            onChange={this.changeFromDate}
                            error={" "}
                          />
                        </div>
                      </div>

                      <div className="user_lgn">
                        <div className="user_label">
                          Select Contact Number or Email id For OTP Verification
                        </div>
                        <div className="user_field">
                          <FormControl component="fieldset">
                            <RadioGroup
                              row
                              aria-label="Is Active"
                              id="otpCheck"
                              name="otpCheck"
                              value={otpCheck}
                              onChange={this.changeHandler}
                              size="small"
                            >
                              <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label="Email id"
                              />
                              &nbsp;&nbsp;&nbsp;
                              <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label="Mobile No."
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                      {userData.isUserGetOTP && userData.GetUserOTPmsg != "" ? (
                        <></>
                      ) : (
                        <>
                          <div className="lgn_btn">
                            <button
                              type="button"
                              className="register_btn sendOtp"
                              onClick={this.generateOtp}
                            >
                              Send OTP
                            </button>

                            <button
                              type="button"
                              className="register_btn sendOtp"
                              onClick={onCancel}
                            >
                              Cancel
                            </button>
                          </div>
                        </>
                      )}

                      {userData.isUserGetOTP &&
                        userData.GetUserOTPmsg != "" && (
                          <>
                            <div className="user_lgn">
                              <div className="user_label">*Enter OTP :</div>
                              <div className="user_field">
                                <TextField
                                  color="primary"
                                  name="enterOtp"
                                  size="small"
                                  value={enterOtp}
                                  onChange={this.changeHandler}
                                  fullWidth
                                  error={otpEmptyErr ? true : false}
                                  helperText={otpEmptyErr ? otpEmptyErr : " "}
                                />
                              </div>
                            </div>
                            <button
                              type="button"
                              className="register_btn sendOtp"
                              onClick={this.validateOtp}
                            >
                              Submit
                            </button>
                            <button
                              type="button"
                              className="register_btn sendOtp"
                              onClick={this.generateOtp}
                            >
                              Resend OTP
                            </button>
                            <button
                              type="button"
                              className="register_btn sendOtp"
                              onClick={onCancel}
                            >
                              Cancel
                            </button>
                          </>
                        )}
                    </div>
                  </div>
                </Grid>
                <Grid item md={4}></Grid>
              </Grid>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  userData: state.studentUser,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotUserIdForm);
