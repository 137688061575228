import { FormHelperText, Grid, TextField } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import { Loading1 } from "../../components/Loading1";
import * as myConstClass from "../../config/messageconstant";
import {
  getAcademicYearByInstituteId,
  resetAcadamicList,
} from "../AcademicYearMaster/academicYear.slice";
import { getClassDetails } from "../ClassDetail/classDetail.slice";
import {
  deleteClassIntakeDetailById,
  getClassIntakeDetail,
  saveClassIntakeDetail,
  saveClassIntakeDetailFail,
} from "./claasIntakeDetail.slice";
let docs;
var hasNumber = /\d/;
class ClassIntakeDetailForm extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    id: 0,
    classDetailId: "",
    academicYearId: "",
    classDetailName: "",
    acYearName: "",
    flag: 0,
    lateralIntake: "",
    sanctionIntake: "",
    isActive: "1",
    formErrors: [],
    breadCrum: "",
  };
  handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({ logo: e.target.result });
    };
    reader.readAsDataURL(event.target.files[0]);
    this.props.handleFile(fileUploaded);
  };
  handleClick = (event) => {
    this._name.click();
  };

  changeHandler = (event) => {
    const { name, value } = event.target;

    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  inputChangeHandler = (event) => {
    const { newInputValue, inputValue } = event.target;
    this.setState({
      ...this.state,
      [newInputValue]: inputValue,
    });
  };
  componentWillReceiveProps(next) {
    if (this.props.classDetList !== next.classDetList) {
      if (
        this.props.classDetList.classDetails !== next.classDetList.classDetails
      ) {
        const classDetailList = next.classDetList?.classDetails.filter(
          (row) => row.id === this.state.classDetailId
        );
        if (classDetailList.length !== 0) {
          this.props.getAcademicYearByInstituteId({
            instId: classDetailList[0].tenantId,
          });
        }
      }
    }
  }
  componentDidMount() {
    const { isEdit, getClassDetails, resetAcadamicList } = this.props;
    getClassDetails();
    resetAcadamicList();
    if (isEdit.length > 0) {
      this.setState({
        id: isEdit[0].id,
        classDetailId: isEdit[0].classDetailId,
        academicYearId: isEdit[0].acYearId,
        classDetailName: isEdit[0].classDetailName,
        acYearName: isEdit[0].acYearName,
        lateralIntake: isEdit[0].lateralIntake,
        sanctionIntake: isEdit[0].sanctionIntake,
        isActive: isEdit[0].isActive === "Active" ? 1 : 0,
      });
    }
  }

  componentDidUpdate() {
    const {
      ClassIntakeDetail,
      getClassIntakeDetail,
      OnSave,
      saveClassIntakeDetailFail,
    } = this.props;
    const { flag } = this.state;
    if (
      ClassIntakeDetail.isSave &&
      ClassIntakeDetail.error == "" &&
      flag == 1
    ) {
      OnSave();
    }
    if (
      ClassIntakeDetail.isSave &&
      ClassIntakeDetail.error == "" &&
      flag == 2
    ) {
      saveClassIntakeDetailFail();
      getClassIntakeDetail();
      this.setState({
        id: 0,
        classDetailId: "",
        academicYearId: "",
        classDetailName: "",
        acYearName: "",
        lateralIntake: "",
        sanctionIntake: "",
        flag: 0,
        isActive: 1,
        formErrors: [],
      });
    }
  }
  handleFormValidation(name) {
    const { classDetailId, academicYearId, lateralIntake, sanctionIntake } =
      this.state;
    let formErrors = {};
    let formIsValid = true;
    console.log(this.state);
    if (classDetailId === "" || classDetailId === null) {
      formIsValid = false;
      formErrors["classDetailNameErr"] = myConstClass.ClassDetEmptyMsg;
    }

    if (academicYearId === "" || academicYearId === null) {
      formIsValid = false;
      formErrors["acYearNameErr"] = myConstClass.acYearEmptyMsg;
    }

    if (lateralIntake === "") {
      formIsValid = false;
      formErrors["lateralIntakeErr"] = myConstClass.lateralIntakeMsg;
    } else if (!hasNumber.test(lateralIntake)) {
      formIsValid = false;
      formErrors["lateralIntakeErr"] = myConstClass.lateralIntakeNoMsg;
    }

    if (sanctionIntake === "") {
      formIsValid = false;
      formErrors["sanctionIntakeErr"] = myConstClass.sanctionIntakeMsg;
    } else if (!hasNumber.test(sanctionIntake)) {
      formIsValid = false;
      formErrors["sanctionIntakeErr"] = myConstClass.sanctionIntakeNoMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const {
      id,
      classDetailId,
      academicYearId,
      lateralIntake,
      sanctionIntake,
      isActive,
    } = this.state;
    docs = {
      yearDetail: {
        id: classDetailId,
      },
      academicYear: {
        id: academicYearId,
      },
      sanctionIntake: lateralIntake,
      actualSanctionIntake: sanctionIntake,
      isActive: isActive,
      delStatus: 1,
    };
    this.setState({
      flag: 1,
    });
    if (id != 0) {
      docs = {
        ...docs,
        id: id,
      };
    }

    if (this.handleFormValidation()) {
      this.props.OnSave(docs);
    }
  };

  saveHandler = (e) => {
    e.preventDefault();
    const {
      id,
      classDetailId,
      academicYearId,
      lateralIntake,
      sanctionIntake,
      isActive,
    } = this.state;
    docs = {
      yearDetail: {
        id: classDetailId,
      },
      academicYear: {
        id: academicYearId,
      },
      sanctionIntake: lateralIntake,
      actualSanctionIntake: sanctionIntake,
      isActive: isActive,
      delStatus: 1,
    };
    this.setState({
      flag: 2,
    });
    if (id != 0) {
      docs = {
        ...docs,
        id: id,
      };
    }
    if (this.handleFormValidation()) {
      this.props.saveAndnext(docs);
    }
  };
  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      if (name === "classDetailId" && newValue != "") {
        const { classDetList, getAcademicYearByInstituteId } = this.props;
        const classDetailList = classDetList?.classDetails.filter(
          (row) => row.id === newValue
        );
        if (classDetailList.length !== 0) {
          getAcademicYearByInstituteId({ instId: classDetailList[0].tenantId });
        }
      }
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };
  render() {
    const {
      flag,
      classDetailId,
      academicYearId,
      classDetailName,
      acYearName,
      lateralIntake,
      sanctionIntake,
      isActive,
    } = this.state;
    const {
      classDetailNameErr,
      branchNameErr,
      acYearNameErr,
      lateralIntakeErr,
      lateralIntakeNoErr,
      sanctionIntakeErr,
      sanctionIntakeNoErr,
    } = this.state.formErrors;
    const {
      onCancel,
      classDetList,
      ClassIntakeDetail,
      academicYearList,
      inputValue,
    } = this.props;
    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            <Grid
              item
              xs={12}
              md={12}
              size="small"
              container
              justifyContent="right"
            >
              {ClassIntakeDetail.isSave && ClassIntakeDetail.error != "" && (
                <FormHelperText error>{ClassIntakeDetail.error}</FormHelperText>
              )}
              {!ClassIntakeDetail.isSave && ClassIntakeDetail.error == "" && (
                <FormHelperText error></FormHelperText>
              )}
              <ButtonCompo
                size="small"
                type="button"
                variant="outlined"
                name=" Back to List"
                onClick={onCancel}
              />
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Year Name : "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} mt={-1}>
                  <AutoComplete
                    keyColName={"id"}
                    value={classDetailId}
                    name={"classDetailId"}
                    options={classDetList?.classDetails}
                    onChange={this.ChangeHandlerSearch}
                  />
                  <FormHelperText error>
                    {classDetailNameErr ? classDetailNameErr : " "}{" "}
                  </FormHelperText>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Academic Year Name : "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} mt={-1}>
                  <AutoComplete
                    keyColName={"id"}
                    value={academicYearId}
                    name={"academicYearId"}
                    options={academicYearList?.academicYear}
                    onChange={this.ChangeHandlerSearch}
                  />
                  <FormHelperText error>
                    {acYearNameErr ? acYearNameErr : " "}{" "}
                  </FormHelperText>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Sanction Intake : "
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="lateralIntake"
                    size="small"
                    value={lateralIntake}
                    fullWidth
                    onChange={this.changeHandler}
                    error={sanctionIntakeErr ? true : false}
                    helperText={sanctionIntakeErr ? sanctionIntakeErr : " "}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Lateral Intake : "
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="sanctionIntake"
                    size="small"
                    value={sanctionIntake}
                    fullWidth
                    onChange={this.changeHandler}
                    error={lateralIntakeErr ? true : false}
                    helperText={lateralIntakeErr ? lateralIntakeErr : " "}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={1.2}
                lg={1.2}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Save"
                  fullWidth={true}
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={1.8}
                lg={1.8}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Save and Next"
                  onClick={this.saveHandler}
                  fullWidth={true}
                />
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={1.2}
                lg={1.2}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Cancel"
                  onClick={onCancel}
                  fullWidth={true}
                />
              </Grid>
            </Grid>
          </Grid>
          {flag != 0 && !ClassIntakeDetail.isFetch && <Loading1 size={50} />}
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  classDetList: state.classDetails,
  academicYearList: state.academicYear,
  ClassIntakeDetail: state.ClassIntakeDetail,
  common: state.common,
});

const mapDispatchToProps = {
  getClassDetails,
  getAcademicYearByInstituteId,
  deleteClassIntakeDetailById,
  saveClassIntakeDetail,
  saveClassIntakeDetailFail,
  getClassIntakeDetail,
  resetAcadamicList,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClassIntakeDetailForm);
