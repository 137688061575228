import { Grid, TextField } from "@mui/material";
import moment from "moment";
import * as React from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import { connect } from "react-redux";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import {
  onlyNumber,
  validPassword,
} from "../../components/Comman/Util/Validations";
import Header from "../../components/NewHeader";
import * as myConstClass from "../../config/messageconstant";
import Navbar from "../../components/Navbar";
let docs;
class ForgotUserPasswordForm extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    id: 0,
    userName: "",
    isActive: "1",
    otpCheck: "0",
    dob: "",
    enterOtp: "",
    confirmPassword: "",
    newPassword: "",
    formErrors: [],
    checkedValues: [],
    breadCrum: "",
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    if (event.target.value === "" || onlyNumber.test(event.target.value)) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  componentDidMount() {
    let date = new Date();
    let frmDate = moment(date).format("DD/MM/YYYY");
    let fromYear = new Date(date).getFullYear();
    let month = new Date(date).getMonth();
    let day = new Date(date).getDate();
    let changedToDate = new Date(fromYear + 1, month, day - 1);
    let toYear = new Date(changedToDate).getFullYear();

    this.setState({
      dob: date,
    });
  }

  generateOtp = () => {
    const { userName, dob } = this.state;
    let birthdate = moment(dob).format("YYYY-MM-DD");
    if (this.handleFormValidation()) {
      if (this.state.userName != null && this.state.dob != null) {
        this.props.submitOtp(userName, birthdate);
      } else {
        this.setState({
          formErrors: {},
        });
      }
    }
  };
  validateOtp = () => {
    const { enterOtp, userName } = this.state;
    if (this.handleFormValidationOtp()) {
      if (this.state.enterOtp != null) {
        this.props.saveOtp(enterOtp, userName);
      }
    }
  };

  changeFromDate = (date) => {
    let frmDate = moment(date).format("DD/MM/YYYY");
    let fromYear = new Date(date).getFullYear();
    let month = new Date(date).getMonth();
    let day = new Date(date).getDate();
    let changedToDate = new Date(fromYear + 1, month, day - 1);
    let toYear = new Date(changedToDate).getFullYear();

    this.setState({
      dob: date,
    });
  };

  handleFormValidationOtp(name) {
    const { enterOtp } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (enterOtp === "") {
      formIsValid = false;
      formErrors["otpEmptyErr"] = myConstClass.otpEmptyMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  handleFormValidation(name) {
    const { dob, userName, newPassword, confirmPassword, enterOtp } =
      this.state;

    let formErrors = {};
    let formIsValid = true;

    if (dob === "") {
      formIsValid = false;
      formErrors["dobErr"] = myConstClass.dobEmptyMsg;
    }

    if (userName === "") {
      formIsValid = false;
      formErrors["userNameErr"] = myConstClass.userNameMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  passwordFormValidation(name) {
    const { newPassword, confirmPassword } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (confirmPassword != newPassword) {
      formIsValid = false;
      formErrors["confirmPasswordErr"] =
        myConstClass.confirmPasswordIncorrectMsg;
    }

    if (newPassword === "") {
      formIsValid = false;
      formErrors["enterPasswordErr"] = myConstClass.enterPasswordMsg;
    }

    if (!validPassword.test(newPassword)) {
      formIsValid = false;
      formErrors["enterPasswordErr"] = myConstClass.specialPasswordMsg;
    }

    if (confirmPassword === "") {
      formIsValid = false;
      formErrors["confirmPasswordErr"] = myConstClass.confirmPasswordMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  sendHandler = (e) => {
    e.preventDefault();
    const { id, userName, dob, enterOtp } = this.state;
    let birthdate = moment(dob).format("YYYY-MM-DD");

    docs = {
      id: id === 0 ? 0 : id,
      dob: birthdate,
      userName: userName,
      enterOtp: enterOtp,
    };
    if (this.handleFormValidation()) {
      this.props.OnSubmitOtp(docs);
    }
  };

  submitHandler = (e) => {
    e.preventDefault();
    const { id, userName, newPassword, confirmPassword, dob } = this.state;
    docs = {
      id: id === 0 ? 0 : id,
      userName: userName,
      dob: dob,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    };
    if (this.passwordFormValidation()) {
      this.props.OnSave(docs);
    }
  };

  render() {
    const {
      dob,
      userName,
      enterOtp,
      contactNo,
      newPassword,
      confirmPassword,
      otpCheck,
    } = this.state;

    const {
      dobErr,
      enterPasswordErr,
      newPasswordErr,
      confirmPasswordErr,
      userNameErr,
      incorrectPasswordErr,
      otpEmptyErr,
    } = this.state.formErrors;

    const { onCancel, passwordData } = this.props;

    return (
      <>
        <div align="center">
          <Header />
          <Navbar />
          <div className="new_content">
            <div className="dash_login">
              <Grid item container justifyContent="space-between">
                <Grid item md={4}></Grid>
                <Grid item md={4}>
                  <div className="lgn_bx">
                    <h3 className="lgn_title">Forgot Password</h3>

                    <div className="lgn_container">
                      <div className="user_lgn">
                        <div className="user_label">Enter Your Username :</div>
                        <div className="user_field">
                          <TextField
                            placeholder=""
                            color="primary"
                            name="userName"
                            size="small"
                            value={userName}
                            onChange={this.changedHandler}
                            fullWidth
                            error={userNameErr ? true : false}
                            helperText={userNameErr ? userNameErr : " "}
                          />
                        </div>
                      </div>

                      <div className="user_lgn">
                        <div className="user_label">
                          Enter Your Registered Date of Birth :
                        </div>
                        <div className="user_field">
                          <BasicDatePicker
                            name="dob"
                            id="dob"
                            value={dob}
                            maxDate={new Date()}
                            onChange={this.changeFromDate}
                            error={dobErr?dobErr:' '}
                          />
                        </div>
                      </div>
                      {passwordData.ispassGetOTP &&
                      passwordData.GetpassOTPmsg ==
                        "Otp has been send successfully.." ? (
                        <></>
                      ) : (
                        <>
                          <div className="lgn_btn">
                            <button
                              type="button"
                              className="register_btn sendOtp"
                              onClick={this.generateOtp}
                            >
                              Send OTP
                            </button>

                            <button
                              type="button"
                              className="register_btn sendOtp"
                              onClick={onCancel}
                            >
                              Cancel
                            </button>
                          </div>
                        </>
                      )}
                      {passwordData.ispassGetOTP &&
                        passwordData.GetpassOTPmsg ==
                          "Otp has been send successfully.." && (
                          <>
                            <div className="user_lgn">
                              <div className="user_label">*Enter OTP :</div>
                              <div className="user_field">
                                <TextField
                                  color="primary"
                                  name="enterOtp"
                                  size="small"
                                  value={enterOtp}
                                  onChange={this.changeHandler}
                                  fullWidth
                                  error={otpEmptyErr ? true : false}
                                  helperText={otpEmptyErr ? otpEmptyErr : " "}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      {passwordData.ispassGetOTP &&
                        passwordData.GetpassOTPmsg ==
                          "Otp has been send successfully.." &&
                        !passwordData.isValidateOtp && (
                          <>
                            <button
                              type="button"
                              className="register_btn sendOtp"
                              onClick={this.validateOtp}
                            >
                              Submit
                            </button>
                            <button
                              type="button"
                              className="register_btn sendOtp"
                              onClick={this.generateOtp}
                            >
                              Resend OTP
                            </button>
                            <button
                              type="button"
                              className="register_btn sendOtp"
                              onClick={onCancel}
                            >
                              Cancel
                            </button>
                          </>
                        )}
                      {passwordData.ispassGetOTP &&
                        passwordData.GetpassOTPmsg ==
                          "Otp has been send successfully.." &&
                        passwordData.isValidateOtp &&
                        passwordData.getValidateOtpmsg == "Otp verified.." && (
                          <></>
                        )}

                      {passwordData.isValidateOtp &&
                        passwordData.getValidateOtpmsg == "Otp verified.." && (
                          <>
                            <div className="user_lgn">
                              <div className="user_label">
                                *Enter Password :
                              </div>
                              <div className="user_field">
                                <TextField
                                  color="primary"
                                  name="newPassword"
                                  type="password"
                                  size="small"
                                  value={newPassword}
                                  onChange={this.changedHandler}
                                  onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                  }}
                                  onCopy={(e) => {
                                    e.preventDefault();
                                    return false;
                                  }}
                                  fullWidth
                                  error={enterPasswordErr ? true : false}
                                  helperText={
                                    enterPasswordErr ? enterPasswordErr : " "
                                  }
                                />
                                <PasswordStrengthBar password={newPassword} />
                              </div>
                            </div>
                            <div className="user_lgn">
                              <div className="user_label">
                                *Confirm Password :
                              </div>
                              <div className="user_field">
                                <TextField
                                  color="primary"
                                  name="confirmPassword"
                                  size="small"
                                  type="password"
                                  value={confirmPassword}
                                  onChange={this.changedHandler}
                                  fullWidth
                                  onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                  }}
                                  onCopy={(e) => {
                                    e.preventDefault();
                                    return false;
                                  }}
                                  {...(confirmPasswordErr
                                    ? {
                                        error: true,
                                        helperText: confirmPasswordErr,
                                      }
                                    : "")}
                                  {...(incorrectPasswordErr
                                    ? {
                                        error: true,
                                        helperText: incorrectPasswordErr,
                                      }
                                    : "")}
                                />
                                <PasswordStrengthBar
                                  password={confirmPassword}
                                />
                              </div>
                            </div>
                            <button
                              type="button"
                              className="register_btn sendOtp"
                              onClick={this.submitHandler}
                            >
                              Save
                            </button>
                          </>
                        )}
                    </div>
                  </div>
                </Grid>
                <Grid item md={4}></Grid>
              </Grid>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  passwordData: state.userPassword,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotUserPasswordForm);
