import { Grid, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import * as React from "react";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import { hasNumber } from "../../components/Comman/Util/Validations";
import { Loading1 } from "../../components/Loading1";
import * as myConstClass from "../../config/messageconstant";
let docs;
class QualificationMasterForm extends React.Component
{
  state = {
    id: 0,
    qualificationName: "",
    trustId: "",
    qualificationType: "1",
    sortOrderNo: "",
    isActive: "1",
    delStatus: "1",
    formErrors: [],
    breadCrum: "",
    flag: 0,
  };

  saveHandler = ( e ) =>
  {
    e.preventDefault();
    const {
      id,
      qualificationName,
      qualificationType,
      sortOrderNo,
      trustId,
      isActive,
    } = this.state;
    docs = {
      name: qualificationName,
      trust: {
        id: trustId,
      },
      type: qualificationType,
      sortOrderNo: sortOrderNo,
      isActive: isActive,
      delStatus: "1",
    };

    if ( id !== 0 )
    {
      docs = {
        ...docs,
        id: id,
      };
    }
    if ( this.handleFormValidation() )
    {
      this.props.saveAndNext( docs );
      this.setState( {
        id: 0,
        qualificationName: "",
        qualificationType: "1",
        sortOrderNo: "",
        isActive: 1,
        formErrors: [],
      } );
    }
  };

  changeHandler = ( event ) =>
  {
    const { name, value } = event.target;

    this.setState( {
      ...this.state,
      [ name ]: value,
    } );
  };
  componentDidMount()
  {
    const { isEdit, breadCrum } = this.props;
    let trustId = localStorage.getItem( "trustId" );
    this.setState( {
      trustId: trustId,
    } );
    breadCrum( ">  Add" );
    if ( isEdit.length > 0 )
    {
      this.setState( {
        id: isEdit[ 0 ].id,
        qualificationName: isEdit[ 0 ].qualificationName,
        qualificationType: isEdit[ 0 ].qualificationType === "UserFill" ? 1 : 0,
        sortOrderNo: isEdit[ 0 ].sortOrderNo,
        isActive: isEdit[ 0 ].isActive === "Active" ? 1 : 0,
      } );
    }
  }

  handleFormValidation( name )
  {
    const { qualificationName, sortOrderNo } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if ( qualificationName.trim() === "" )
    {
      formIsValid = false;
      formErrors[ "qualificationNameErr" ] = myConstClass.qualificationMsg;
    }

    if ( !hasNumber.test( sortOrderNo ) )
    {
      formIsValid = false;
      formErrors[ "sortOrderNoErr" ] = myConstClass.sortOrderNumberMsg;
    }

    if ( sortOrderNo === "" )
    {
      formIsValid = false;
      formErrors[ "sortOrderNoErr" ] = myConstClass.sortOrdNoMsg;
    }

    this.setState( { formErrors: formErrors } );
    return formIsValid;
  }

  submitHandler = ( e ) =>
  {
    e.preventDefault();
    const {
      id,
      qualificationName,
      trustId,
      qualificationType,
      sortOrderNo,
      isActive,
    } = this.state;
    docs = {
      name: qualificationName,
      trust: {
        id: trustId,
      },
      type: qualificationType,
      sortOrderNo: sortOrderNo,
      isActive: isActive,
      delStatus: "1",
    };

    if ( id !== 0 )
    {
      docs = {
        ...docs,
        id: id,
      };
    }
    if ( this.handleFormValidation() )
    {
      this.props.OnSave( docs );
    }
  };
  render()
  {
    const { flag, qualificationName, qualificationType, sortOrderNo } =
      this.state;
    const { qualificationNameErr, sortOrderNoErr, sortOrderNumErr } =
      this.state.formErrors;
    const { onCancel, qualificationList } = this.props;
    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            <Grid
              sx={{ m: -1 }}
              item
              xs={12}
              md={12}
              size="small"
              container
              justifyContent="flex-end"
            >
              <ButtonCompo
                size="small"
                type="button"
                variant="outlined"
                name=" Back to List"
                onClick={onCancel}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={1}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Qualification name : "
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    size="small"
                    color="primary"
                    name="qualificationName"
                    id="qualificationName"
                    value={qualificationName}
                    onChange={this.changeHandler}
                    fullWidth
                    error={qualificationNameErr ? true : false}
                    helperText={
                      qualificationNameErr ? qualificationNameErr : " "
                    }
                  ></TextField>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Sort Order No :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    size="small"
                    color="primary"
                    name="sortOrderNo"
                    id="sortOrderNo"
                    value={sortOrderNo}
                    onChange={this.changeHandler}
                    fullWidth
                    error={sortOrderNoErr ? true : false}
                    helperText={sortOrderNoErr ? sortOrderNoErr : " "}
                  ></TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={6} md={6}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="*Qualification Type :"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}></Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="Is Active"
                    id="qualificationType"
                    name="qualificationType"
                    value={qualificationType}
                    onChange={this.changeHandler}
                    size="small"
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="UserFill"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="TrustFill"
                    />
                  </RadioGroup>
                </FormControl>

              </Grid>
              <Grid item xs={12} sm={6} md={6}></Grid>

            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={1}
              container
              justifyContent="left"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 12, color: '#808080' }}
                  color='#808080'
                  label="Note: Userfill->The Qualification required from applicant"
                />
                <br />
                &emsp;&emsp;
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 12, color: '#808080' }}

                  label="Trustfill->The Qualification entries from the Institute"
                />
              </Grid>


            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={1.2}
                lg={1.2}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Save"
                  fullWidth={true}
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={1.8}
                lg={1.8}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  fullWidth={true}
                  type="button"
                  variant="contained"
                  name="Save and Next"
                  onClick={this.saveHandler}
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={1.2}
                lg={1.2}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  fullWidth={true}
                  type="button"
                  variant="outlined"
                  name="Cancel"
                  onClick={onCancel}
                />
              </Grid>
            </Grid>
          </Grid>
          {flag !== 0 && !qualificationList.isFetch && <Loading1 size={50} />}
        </form>
      </>
    );
  }
}

export default QualificationMasterForm;
