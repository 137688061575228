import { Paper } from "@mui/material";
import { withStyles } from "@mui/styles";
//import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getNotification } from "../Landing/Landing.slice";
import {getNewsDocNotification} from "../NewsDocNotification/newsDocNotification.slice"
import { getAlert } from "../../Alert/alert.slice";
import * as myConstClass from "../../config/messageconstant";
// event_title
const styles = (theme) => ({
  hello: {
    position: "absolute",
    left: "15.17%",
    right: "15.08%",
    top: "63.4%",
    bottom: "32.6%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: "normal",
    fontsize: "36px",
    lineheight: "40px",
    color: "#000000",
  },
  buttonC: {
    position: "absolute",

    left: "39.17%",
    right: "39.17%",
    top: "79.7%",
    bottom: "14.8%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: 500,
    fontsize: "18px",
    lineheight: "40px",
    color: "#FFFFFF",
  },
  helper: {
    position: "absolute",
    left: "9.58%",
    right: "9.5%",
    top: "70%",
    bottom: "22%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: "normal",
    fontsize: "20px",
    lineheight: "40px",

    textAlign: "center",

    color: "#666666",
  },
  title: {
    position: "absolute",
    left: "43.42%",
    right: "43.33%",
    top: "57%",
    bottom: "39%",

    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: 500,
    fontsize: "55px",
    lineheight: "40px",
    color: "#000000",
  },
})
const ErrorPage = ({ getNewsDocNotification, latestnotification,getAlert }) => {
  useEffect(() => {
    getNewsDocNotification().then((response) => {
      if(!response){
        getAlert( { message: myConstClass.serverMsg } );
      }
      console.log(latestnotification);
    });
  }, []);

  return (
    <>
     <Paper sx={{ p: 0, borderRadius: 2 }} elevation={0}><div align="center">
        {/* <Header/> */}
     

        <div className="new_content">
          {/* Online Admission */}
          <h3 className="event_title1"> <span>All</span> Notification</h3>
          {(latestnotification?.newsDocNotification.filter((row) => row.type == "Notification"&&row.isActive=="Active")).map((data) => {
            return (
              <div className="admission_detail noti">
                {/* <div className="admission_det_date">End Date <span>dsdsd</span></div> */}
                <div className="admission_cont detil">
                  <h6 className="event_sub_nm">
                    {data.title}
                    {/* <span>Am Univercity 7 April in, Mumbai</span> */}
                  </h6>
                  <h6 className="news_time"><span class="pink">Date :</span> {data.date}</h6>
                </div>
                <p className="admission_txt detail">
                  {/* Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur. */}
                  {data.description}
                </p>
                
              </div>
            );
          })}
        </div>
      </div>
      </Paper> 
    </>
  );
};

const mapStateToProps = (state) => ({
  newsDocNotificationDocs: state.landing,
  latestnews: state.landing,
  latestnotification: state.newsDocNotification,
});

const mapDispatchToProps = {
  getNewsDocNotification,
  getAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ErrorPage));
