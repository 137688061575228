import {
  FormControl,
  MenuItem,
  Paper,
  Select,
  TextField,
  Grid,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import { LabelCompo } from "../../components/Comman/Label";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Buttton";
import * as myConstClass from "../../config/messageconstant";
import ReqDocumentsTable from "./DirectAdmissionReqDocumentTable";

let columns;

const ReqDoc = ({ eduRows, appliData, data, onCancel }) => {
  const [selection, setSelection] = useState([]);
  const [values, setValues] = React.useState([]);
  const [list, setList] = React.useState([]);

  const [isEmptyList, setIsEmptyList] = useState(false);
  useEffect(() => {
    if (
      appliData.applicationData !== "" &&
      appliData.applicationData.type >= 5
    ) {
      let qualiRows = eduRows.map((data, rowIndex) => {
        let rowData = "";
        let docData =
          appliData.applicationData.applicationDocumentDetails.filter(
            (row) => row?.documentId === data.id
          );
        if (docData.length !== 0) {
          rowData = {
            docName: data.docName,
            id: data.id,
            index: rowIndex + 1,
            isActive: data.isActive,
            mandatoryOptional: docData[0].type,
            mandatoryOptionalValid: data.mandatoryOptionalValid,
            remarks: docData[0].remark,
            remarksValid: data.remarksValid,
            seqNO: docData[0].marksSequenceOrderNo,
            seqNoValid: data.seqNoValid,
          };
        }

        if (rowData !== "") {
          return rowData;
        }
        return data;
      });

      qualiRows.map((user, index) => {
        let userdata =
          appliData.applicationData.applicationDocumentDetails.filter(
            (row) => row?.documentId === user.id
          );
        if (userdata.length != 0) {
          selection.push(user.id);
          list.push(user);
        }
      });

      setValues(qualiRows);
    } else {
      setValues(eduRows);
    }
  }, []);
  const columnExtensions = [
    { columnName: "index", width: 100, allign: "center" },
    { columnName: "docName", width: 200 },
    { columnName: "mandatoryOptional", width: 200 },
    { columnName: "remarks", width: 200 },
  ];
  const changemandatoryOptionalHandler = (id, e) => {
    const { value } = e.target;
    setValues((data) =>
      data?.map((list, index) =>
        list.id === id ? { ...list, mandatoryOptional: value } : list
      )
    );
  };
  const changeseqNOHandler = (id, e) => {
    const { value } = e.target;
    setValues((user) =>
      user?.map((list, index) =>
        list.id === id ? { ...list, seqNO: value } : list
      )
    );
  };
  const changeremarksHandler = (id, e) => {
    const { value } = e.target;
    setValues((data) =>
      data?.map((list, index) =>
        list.id === id ? { ...list, remarks: value } : list
      )
    );
  };
  columns = [
    { field: "index", headerName: "Sr. No.", minWidth: 90 },
    { field: "docName", headerName: "Documents", minWidth: 250 },
    {
      headerName: "Mandatory / Optional",
      field: "mandatoryOptional",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <div>
            <FormControl color={"primary"} fullWidth={true}>
              <Select
                className="text-black"
                name={"mandatoryOptional" + params.row.index}
                value={params.row.mandatoryOptional}
                // onChange={(e) => changeHandler(params.row.id, e)}
                size="small"
                fullWidth={true}
                {...(params.row.mandatoryOptionalValid === 1
                  ? {
                      error: true,
                      helperText: myConstClass.mandatoryOptionalMsg,
                    }
                  : "")}
              >
                <MenuItem value="">
                  <em>NA</em>
                </MenuItem>

                <MenuItem value="0">Optional</MenuItem>

                <MenuItem value="1">Mandatory</MenuItem>
              </Select>
              {params.row.mandatoryOptionalValid === 1 && (
                <FormHelperText error>
                  {myConstClass.mandatoryOptionalMsg}
                </FormHelperText>
              )}
            </FormControl>
          </div>
        );
      },
    },
    {
      field: "seqNO",
      minWidth: 200,
      headerName: "Sequence No",
      renderCell: (params) => {
        return (
          <div>
            <TextField
              color="primary"
              name={"seqNO" + params.row.id}
              size="small"
              autoComplete="off"
              value={params.row.seqNO}
              {...(params.row.seqNOValid === 1
                ? { error: true, helperText: "please enter sequence number" }
                : params.row.seqNOValid === 2
                ? {
                    error: true,
                    helperText: "please enter Valid sequence number",
                  }
                : "")}
              // onChange={(e) => changeHandler(params.row.id, e)}
            />
          </div>
        );
      },
    },
    {
      field: "remarks",
      headerName: "Remarks",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <div>
            <TextField
              color="primary"
              name={"remarks" + params.row.index}
              size="small"
              value={params.row.remarks}
              //  onChange={(e) => changeHandler(params.row.id, e)}
            />
          </div>
        );
      },
    },
  ];

  const getRow = (e) => {
    setSelection(e);
    let selectedList = [];
    e.forEach((data) => {
      const selRow = eduRows.filter((row) => {
        return row.id === data;
      });
      selectedList = [...selectedList, selRow[0]];
    });

    setList(selectedList);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let selData = [];
    list.map((selectedRow, selIndex) => {
      const a = values.filter((item, index) => item.id === selectedRow.id);
      let remarks = a[0].remarks;
      let mandatoryOptional = a[0].mandatoryOptional;
      let seqNumber = a[0].seqNO;
      if (seqNumber === "" || mandatoryOptional === "") {
        // if (remarks === "") {
        //   setValues((user) =>
        //     user?.map((list, index) =>
        //       list.id === selectedRow[0].id
        //         ? { ...list, remarksValid: 1 }
        //         : list
        //     )
        //   );
        // } else {
        //   setValues((user) =>
        //     user?.map((list, index) =>
        //       list.id === selectedRow[0].id
        //         ? { ...list, remarksValid: 0 }
        //         : list
        //     )
        //   );
        // }
        if (seqNumber === "") {
          setValues((user) =>
            user?.map((list, index) =>
              list.id === selectedRow.id ? { ...list, seqNOValid: 1 } : list
            )
          );
        } else {
          setValues((user) =>
            user?.map((list, index) =>
              list.id === selectedRow.id ? { ...list, seqNOValid: 0 } : list
            )
          );
        }
        if (mandatoryOptional === "") {
          setValues((user) =>
            user?.map((list, index) =>
              list.id === selectedRow.id
                ? { ...list, mandatoryOptionalValid: 1 }
                : list
            )
          );
        } else {
          setValues((user) =>
            user?.map((list, index) =>
              list.id === selectedRow.id
                ? { ...list, mandatoryOptionalValid: 0 }
                : list
            )
          );
        }
      } else {
        setValues((user) =>
          user?.map((list, index) =>
            list.id === selectedRow.id
              ? { ...list, mandatoryOptionalValid: 0, seqNOValid: 0 }
              : list
          )
        );
        let reqDoc = {
          documentId: selectedRow.id,
          type: mandatoryOptional,
          remark: remarks,
          marksSequenceOrderNo: seqNumber,
        };

        selData = [...selData, reqDoc];
      }
      return selData;
    });
    if (selData.length === list.length) {
      if (selData.length !== 0) {
        data(selData);
      } else {
        setIsEmptyList(true);
      }
    }
  };

  return (
    <>
      <LabelCompo
        className="text-black"
        style={{ fontSize: 12, color: "#808080" }}
        label="Note: Sequence number: Sequence number indicates document sequences to be  displayed in application form and it's priority of comparision while making merit list eg: Priority one to be displayed on top and priortiy 2 after that. "
      />
      <br />
      <br />
      {isEmptyList === true && (
        <FormHelperText error>{myConstClass.DocMsg}</FormHelperText>
      )}
      <div align="center">
        <ReqDocumentsTable
          rows={values}
          columns={columns}
          changeseqNOHandler={changeseqNOHandler}
          changemandatoryOptionalHandler={changemandatoryOptionalHandler}
          changeremarksHandler={changeremarksHandler}
          selectedIndexData={getRow}
          selection={selection}
        />
        <br />
        <br />
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          m={2}
          rowSpacing={1}
          columnSpacing={{ xs: 12, sm: 6, md: 1 }}
          container
          justifyContent="right"
        >
          <Grid
            item
            sm={12}
            xs={12}
            md={1}
            lg={1}
            container
            justifyContent="space-evenly"
          >
            <ButtonCompo
              size="medium"
              type="button"
              variant="outlined"
              name=" Back"
              fullWidth={true}
              onClick={onCancel}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={2.5}
            lg={2.5}
            container
            justifyContent="space-evenly"
          >
            <ButtonCompo
              size="medium"
              type="submit"
              variant="contained"
              name="Save and Proceed"
              fullWidth={true}
              onClick={submitHandler}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  appliData: state.directAdmission,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ReqDoc);
