import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import * as React from "react";
import { LabelCompo } from "../../components/Comman/Label";

const styles = (theme) => ({
  MuiTable: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
  },
  MuiTableHeadRoot: {
    textAlign: "left",
    padding: "8px",
    border: "1px solid #ddd",
  },
});

const BasicTable = ({ data, classes }) => {
  return (
    <>
      <LabelCompo
        className="text-black"
        style={{ fontSize: 20 }}
        label="Permanent Address"
      />
      <Grid>&nbsp;&nbsp;</Grid>
      <div style={{ overflow: "auto" }}>
        <table className={classes.MuiTable}>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Address{" "}
            </td>
            <td className={classes.MuiTableHeadRoot} colSpan={3}>
              &nbsp;
              {data.permanentAddressLine1 +
                " " +
                data.permanentAddressLine2 +
                " " +
                data.permanentAddressLine3}
            </td>
          </tr>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Pin Code{" "}
            </td>
            <td className={classes.MuiTableHeadRoot} colSpan={3}>
              &nbsp;{data.permanentPin}
            </td>
          </tr>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Taluka{" "}
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.permanentTalukaName}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; District{" "}
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.permanentDistrictName}
            </td>
          </tr>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp;City / Village{" "}
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.permanentCity}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; State{" "}
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.permanentStateName}
            </td>
          </tr>
        </table>
        <Grid>&nbsp;&nbsp;</Grid>
        <Grid>&nbsp;&nbsp;</Grid>
        <LabelCompo
          className="text-black"
          style={{ fontSize: 20 }}
          label="Corresponding Address"
        />
        <Grid>&nbsp;&nbsp;</Grid>

        <table className={classes.MuiTable}>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Address{" "}
            </td>
            <td className={classes.MuiTableHeadRoot} colSpan={3}>
              &nbsp;
              {data.temporaryAddressLine1 +
                " " +
                data.temporaryAddressLine2 +
                " " +
                data.temporaryAddressLine3}
            </td>
          </tr>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Pin Code{" "}
            </td>
            <td className={classes.MuiTableHeadRoot} colSpan={3}>
              &nbsp;{data.temporaryPin}
            </td>
          </tr>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Taluka{" "}
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.temporaryTalukaName}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; District{" "}
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.temporaryDistrictName}
            </td>
          </tr>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp;City / Village{" "}
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.temporaryCity}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; State{" "}
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.temporaryStateName}
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};
export default withStyles(styles)(BasicTable);
