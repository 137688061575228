import React from "react";
import ExportCSV from "../../components/Comman/ExportCSV";
import { generatePDF } from "../../components/Comman/GeneratePDF";
import { FontAwesomeIconCompo } from "../../components/Comman/IconButton";

const ReservationStructureExcel = ({
  csvData,
  reservStructList,
  fileName,
  type,
}) => {
  const tablecol = reservStructList.map((title) => {
    return title[0].title;
  });
  let index = 0;
  const schemeData = csvData.map((csvData) => {
    index = index + 1;
    let inst = {
      "Sr. No.": index,
    };

    if (reservStructList.length !== 7) {
      reservStructList.map((title) => {
        if (title[0].name === "name") {
          inst = {
            ...inst,
            "Reservation Name": csvData.name,
          };
        }
        if (title[0].name === "generalPer") {
          inst = {
            ...inst,
            "General Percentage": csvData.generalPer,
          };
        }
        if (title[0].name === "otherPer") {
          inst = {
            ...inst,
            "Other Percentage": csvData.otherPer,
          };
        }
        if (title[0].name === "malePer") {
          inst = {
            ...inst,
            "Male Percentage": csvData.malePer,
          };
        }
        if (title[0].name === "famalePer") {
          inst = {
            ...inst,
            "Female Percentage": csvData.famalePer,
          };
        }

        if (title[0].name === "isActive") {
          inst = {
            ...inst,
            Status: csvData.isActive,
          };
        }
        return title;
      });
    } else {
      inst = {
        ...inst,
        "Reservation Name": csvData.name,
        "General Percentage": csvData.generalPer,
        "Other Percentage": csvData.otherPer,
        "Male Percentage": csvData.malePer,
        "Female Percentage": csvData.famalePer,
        Status: csvData.isActive,
      };
    }

    return inst;
  });

  if (type === "pdf") {
    return (
      <FontAwesomeIconCompo
        color="error"
        fontSize="small"
        title="PDF"
        baseClassName="fas"
        className="fa-file-pdf"
        onClick={() =>
          generatePDF(schemeData, tablecol, "Reservation Structure")
        }
      />
    );
  }

  return <ExportCSV csvData={schemeData} fileName={fileName} />;
};
export default ReservationStructureExcel;
