import { FormHelperText, Grid, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Buttton";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../components/Comman/Label";
import { Loading1 } from "../../components/Loading1";
import * as myConstClass from "../../config/messageconstant";
import { getInstitute } from "../Institute/institute.slice";
import { deleteAcademicYearData } from "./academicYear.slice";
import { getAlert } from "../../Alert/alert.slice";

let docs;
class AcademicYearForm extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    id: 0,
    instId: "",
    academicYearName: "",
    fromDate: "",
    toDate: "",
    isActive: "1",
    isLock: "1",
    formErrors: [],
    flag: 0,
  };

  saveHandler = (e) => {
    e.preventDefault();
    const {
      id,
      instId,
      academicYearName,
      fromDate,
      toDate,
      isActive,
      isLock,
      flag,
    } = this.state;

    let fd = moment(fromDate).format("DD-MM-YYYY");
    let td = moment(toDate).format("DD-MM-YYYY");

    docs = {
      tenant: {
        id: instId,
      },
      name: academicYearName,
      fromDate: fd,
      toDate: td,
      isActive: isActive,
      isLocked: isLock,
      delStatus: 1,
    };

    if (id != 0) {
      docs = {
        ...docs,
        id: id,
      };
    }
    if (this.handleFormValidation()) {
      this.props.saveAndNext(docs);
      this.setState({
        id: 0,
        instId: "",
        academicYearName: "",
        fromDate: "",
        toDate: "",
        isActive: "1",
        isLocked: "",
        formErrors: [],
        flag: 0,
      });
    }
  };

  changeHandler = (event) => {
    const { name, value } = event.target;

    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  componentDidMount() {
    let date = new Date();
    let frmDate = moment(date).format("DD/MM/YYYY");
    let fromYear = new Date(date).getFullYear();
    let month = new Date(date).getMonth();
    let day = new Date(date).getDate();
    let changedToDate = new Date(fromYear + 1, month, day - 1);
    let toYear = new Date(changedToDate).getFullYear();
    this.setState({
      fromDate: date,
      toDate: changedToDate,
    });

    const { isEdit, getAlert, getInstitute, instituteList } = this.props;
    getInstitute().then((respo) => {
      if (!respo) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
    if (isEdit.length > 0) {
      const fromDateArr = isEdit[0].fromDate.split("-");
      const fromDate = new Date(
        fromDateArr[2],
        fromDateArr[1] - 1,
        fromDateArr[0]
      );
      const toDateArr = isEdit[0].toDate.split("-");
      const toDate = new Date(toDateArr[2], toDateArr[1] - 1, toDateArr[0]);
      this.setState({
        id: isEdit[0].id,
        instId: isEdit[0].instNameId,
        instName: isEdit[0].instName,
        academicYearName: isEdit[0].name,
        fromDate: fromDate,
        toDate: toDate,
        isActive: isEdit[0].isActive === "Active" ? 1 : 0,
        isLock: isEdit[0].isLocked,
      });
    }
  }

  handleFormValidation(name) {
    const { academicYearName, instId, fromDate, toDate } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (instId === "" || instId === null) {
      formIsValid = false;
      formErrors["instituteNameErr"] = myConstClass.instNameMsg;
    }

    if (academicYearName.trim() === "") {
      formIsValid = false;
      formErrors["academicYearNameErr"] = myConstClass.academicYearNameMsg;
    }

    if (fromDate === "") {
      formIsValid = false;
      formErrors["fromDateErr"] = myConstClass.fromDateMsg;
    }

    if (fromDate !== "" && toDate !== "" && fromDate > toDate) {
      formIsValid = false;
      formErrors["toDateErr"] = myConstClass.displaytoMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  changeFromDate = (date) => {
    let frmDate = moment(date).format("DD/MM/YYYY");
    let fromYear = new Date(date).getFullYear();
    let month = new Date(date).getMonth();
    let day = new Date(date).getDate();
    let changedToDate = new Date(fromYear + 1, month, day - 1);
    let toYear = new Date(changedToDate).getFullYear();

    this.setState({
      fromDate: date,
      toDate: changedToDate,
    });
  };

  changeToDate = (date) => {
    this.setState({
      toDate: date,
    });
  };

  submitHandler = (e) => {
    e.preventDefault();
    const {
      id,
      instId,
      academicYearName,
      fromDate,
      toDate,
      isActive,
      isLock,
      flag,
    } = this.state;

    let fd = moment(fromDate).format("DD-MM-YYYY");
    let td = moment(toDate).format("DD-MM-YYYY");

    docs = {
      tenant: {
        id: instId,
      },
      name: academicYearName,
      fromDate: fd,
      toDate: td,
      isActive: isActive,
      isLocked: isLock,
      delStatus: 1,
    };

    if (id != 0) {
      docs = {
        ...docs,
        id: id,
      };
    }
    if (this.handleFormValidation()) {
      this.props.OnSave(docs);
    }
  };

  componentDidUpdate() {
    if (this.props.academicYear.isFetch) {
      const {
        id,
        instId,
        academicYearName,
        fromDate,
        toDate,
        isActive,
        isLock,
        flag,
      } = this.state;

      let fd = moment(fromDate).format("DD-MM-YYYY");
      let td = moment(toDate).format("DD-MM-YYYY");

      docs = {
        tenant: {
          id: instId,
        },
        name: academicYearName,
        fromDate: fd,
        toDate: td,
        isActive: isActive,
        isLocked: isLock,
        delStatus: 1,
      };

      if (id != 0) {
        docs = {
          ...docs,
          id: id,
        };
      }
      if (flag == 1) {
        this.props.saveAndNext(docs);
        this.setState({
          id: 0,
          instId: "",
          academicYearName: "",
          fromDate: "",
          toDate: "",
          isActive: "1",
          isLocked: "",
          formErrors: [],
          flag: 0,
        });
      } else if (flag == 2) {
        this.props.OnSave(docs);
      }
      this.props.deleteAcademicYearData();
    }
  }
  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };
  render() {
    const {
      flag,
      instId,
      academicYearName,
      fromDate,
      toDate,
      isActive,
      isLock,
    } = this.state;
    const { academicYearNameErr, instituteNameErr, fromDateErr, toDateErr } =
      this.state.formErrors;
    const { onCancel, instituteList } = this.props;
    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            <Grid
              item
              xs={12}
              md={12}
              size="small"
              container
              justifyContent="flex-end"
            >
              <ButtonCompo
                size="small"
                type="button"
                variant="outlined"
                name=" Back to List"
                onClick={onCancel}
              />
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="space-evenly"
            >
              {/*  */}
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*From Date : "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <BasicDatePicker
                    color="primary"
                    name="fromDate"
                    size="small"
                    value={fromDate}
                    fullWidth
                    onChange={this.changeFromDate}
                    error={fromDateErr ? fromDateErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*To Date : "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <BasicDatePicker
                    color="primary"
                    name="toDate"
                    size="small"
                    value={toDate}
                    fullWidth
                    onChange={this.changeToDate}
                    error={toDateErr ? toDateErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Select Institute : "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} mt={-2}>
                  <AutoComplete
                    keyColName="id"
                    value={instId}
                    name={"instId"}
                    options={instituteList?.institute}
                    onChange={this.ChangeHandlerSearch}
                  />
                  <FormHelperText error>
                    {instituteNameErr ? instituteNameErr : " "}{" "}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={2}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Academic Year Name : "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} mt={-1}>
                  <TextField
                    color="primary"
                    name="academicYearName"
                    size="small"
                    value={academicYearName}
                    fullWidth
                    onChange={this.changeHandler}
                    error={academicYearNameErr ? true : false}
                    helperText={academicYearNameErr ? academicYearNameErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} mt={-1}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="*Is This Current Academic Year? "
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} mt={-2.5}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="Is Lock"
                    id="isLock"
                    name="isLock"
                    value={isLock}
                    onChange={this.changeHandler}
                    size="small"
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Yes"
                      tabindex="-1"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="No"
                      tabindex="0"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 12, color: "#808080" }}
                  color="#808080"
                  label="Note: Yes->The Entered Academic Year record will be the current running academic year of the selected institute."
                />
                <br />
                &emsp;&emsp;
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 12, color: "#808080" }}
                  label=" No->To discontinue the current academic year."
                />
              </Grid>
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={1.2}
                lg={1.2}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Save"
                  fullWidth={true}
                />
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={1.8}
                lg={1.8}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Save and Next"
                  onClick={this.saveHandler}
                  fullWidth={true}
                />
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={1.2}
                lg={1.2}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Cancel"
                  onClick={onCancel}
                  fullWidth={true}
                />
              </Grid>
            </Grid>
          </Grid>
          {flag != 0 && !instituteList.isFetch && <Loading1 size={50} />}
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  instituteList: state.institute,
  academicYear: state.academicYear,
  common: state.common,
});

const mapDispatchToProps = {
  getInstitute,
  deleteAcademicYearData,
  getAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(AcademicYearForm);
