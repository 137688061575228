import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Container, Grid, Paper, TextField } from "@mui/material";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import moment from "moment";
import * as React from "react";
import { ButtonCompo } from "../../components/Comman/Buttton";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { ChipCompo } from "../../components/Comman/IconButton";
import { LabelCompo } from "../../components/Comman/Label";
import { onlyNumber } from "../../components/Comman/Util/Validations";
import * as myConstClass from "../../config/messageconstant";

let docs;
class AddingCapRoundForm extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    id: 0,
    title: "",
    label: "",
    display: "1",
    contactNo: "",
    maxLimit: "",
    seatAllotmentDate: "",
    feesType: "1",
    feesApplicable: "0",
    challanInstructions: "0",
    fees: "",
    toDate: "",
    fromDate: "",
    seatAcceptFromDate: "",
    seatAcceptToDate: "",
    isActive: "1",
    formErrors: [],
    highlightLabel: "0",
    remark: "",
    challanInst: "",
    applicationDecId: "",
  };

  changeFeesHandler = (event) => {
    const { name, value } = event.target;
    if (event.target.value === "" || onlyNumber.test(event.target.value)) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  changeHandler = (event) => {
    const { name, value } = event.target;

    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  componentDidMount() {
    const { capId } = this.props;
    let date = new Date();
    this.setState({
      applicationDecId: capId,
      fromDate: date,
      toDate: date,
      seatAllotmentDate: date,
      seatAcceptFromDate: date,
      seatAcceptToDate: date,
    });
  }

  changeFromDate = (date) => {
    let fromYear = new Date(date).getFullYear();
    let month = new Date(date).getMonth();
    let day = new Date(date).getDate();
    let changedToDate = new Date(fromYear, month, day + 1);
    this.setState({
      fromDate: date,
      toDate: changedToDate,
      seatAllotmentDate: changedToDate,
      seatAcceptFromDate: changedToDate,
      seatAcceptToDate: changedToDate,
    });
  };

  changeSeatAllotmentDate = (date) => {
    let fromYear = new Date(date).getFullYear();
    let month = new Date(date).getMonth();
    let day = new Date(date).getDate();
    let changedToDate = new Date(fromYear, month, day + 1);
    this.setState({
      seatAllotmentDate: date,
      seatAcceptToDate: changedToDate,
      seatAcceptFromDate: changedToDate,
    });
  };

  changeToDate = (date) => {
    let fromYear = new Date(date).getFullYear();
    let month = new Date(date).getMonth();
    let day = new Date(date).getDate();
    let changedToDate = new Date(fromYear, month, day + 1);
    this.setState({
      toDate: date,
      seatAllotmentDate: changedToDate,
      seatAcceptFromDate: changedToDate,
      seatAcceptToDate: changedToDate,
    });
  };

  changeSeatAcceptFromDate = (date) => {
    let fromYear = new Date(date).getFullYear();
    let month = new Date(date).getMonth();
    let day = new Date(date).getDate();
    let changedToDate = new Date(fromYear, month, day + 1);
    this.setState({
      seatAcceptFromDate: date,
      seatAcceptToDate: changedToDate,
    });
  };

  changeSeatAcceptToDate = (date) => {
    this.setState({
      seatAcceptToDate: date,
    });
  };

  onChangeDescription = (event, editor) => {
    const data = editor.getData();
    this.setState({ remark: data });
  };
  onChangeInEditor = (event, editor) => {
    const data = editor.getData();
    this.setState({ challanInst: data });
  };
  handleFormValidation(name) {
    const {
      title,
      label,
      display,
      highlightLabel,
      fromDate,
      toDate,
      seatAcceptFromDate,
      seatAcceptToDate,
      remark,
      maxLimit,
    } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (title === "") {
      formIsValid = false;
      formErrors["titleErr"] = myConstClass.titleMsg;
    }

    if (maxLimit === "") {
      formIsValid = false;
      formErrors["preferenceErr"] = myConstClass.prefrenceMsg;
    }

    if (fromDate != "" && toDate != "" && fromDate > toDate) {
      formIsValid = false;
      formErrors["displayToErr"] = myConstClass.displaytoMsg;
    }

    if (display === "") {
      formIsValid = false;
      formErrors["typeErr"] = myConstClass.typeMsg;
    }

    if (remark === "") {
      formIsValid = false;
      formErrors["shortErr"] = myConstClass.detailsOfDescriptionMsg;
    }

    if (label === "" && highlightLabel == "1") {
      formIsValid = false;
      formErrors["labelErr"] = myConstClass.labelMsg;
    }

    if (fromDate == toDate) {
      formIsValid = false;
      formErrors["roundFromDateErr"] = myConstClass.roundFromMsg;
      formErrors["roundToDateErr"] = myConstClass.roundFromMsg;
    }

    if (seatAcceptFromDate == seatAcceptToDate) {
      formIsValid = false;
      formErrors["seatAcceptFromErr"] = myConstClass.seatAcceptFromMsg;
      formErrors["seatAcceptToErr"] = myConstClass.seatAcceptToMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const {
      id,
      title,
      label,
      display,
      highlightLabel,
      fromDate,
      toDate,
      feesType,
      feesApplicable,
      challanInstructions,
      fees,
      seatAllotmentDate,
      seatAcceptFromDate,
      seatAcceptToDate,
      isActive,
      remark,
      maxLimit,
      challanInst,
      applicationDecId,
    } = this.state;
    let frmDt = moment(fromDate).format("DD-MM-YYYY");
    let toDt = moment(toDate).format("DD-MM-YYYY");
    let seatAllotmentDt = moment(seatAllotmentDate).format("DD-MM-YYYY");
    let seatAcceptFromDt = moment(seatAcceptFromDate).format("DD-MM-YYYY");
    let seatAcceptToDt = moment(seatAcceptToDate).format("DD-MM-YYYY");
    docs = {
      applicationDeclare: {
        id: applicationDecId,
      },
      name: title,
      lableText: label,
      highlightLable: highlightLabel,
      seatAllocationType: display,
      roundStartDate: frmDt,
      roundEndDate: toDt,
      seatAllotmentDate: seatAllotmentDt,
      seatAcceptFromDate: seatAcceptFromDt,
      seatAcceptToDate: seatAcceptToDt,
      paymentType: feesType,
      paymentApplicable: feesApplicable,
      instructionPrintOnChallan: challanInstructions,
      amount: fees,
      description: remark,
      paymentInstruction: challanInst,
      isActive: isActive,
      delStatus: 0,
      roundStatus: 0,
      maxLimit: maxLimit,
    };
    if (this.handleFormValidation()) {
      this.props.OnSave(docs);
    }
  };
  changeActiveStatus = () => {
    if (this.state.isActive === "1") {
      this.setState({
        ...this.state,
        isActive: "0",
      });
    } else {
      this.setState({
        ...this.state,
        isActive: "1",
      });
    }
  };
  render() {
    const {
      title,
      label,
      display,
      fromDate,
      toDate,
      seatAllotmentDate,
      seatAcceptFromDate,
      seatAcceptToDate,
      feesType,
      fees,
      remark,
      challanInst,
      feesApplicable,
      challanInstructions,
      highlightLabel,
      maxLimit,
    } = this.state;

    const {
      roundFromDateErr,
      roundToDateErr,
      titleErr,
      labelErr,
      shortErr,
      seatAcceptToErr,
      seatAcceptFromErr,
      preferenceErr,
      typeErr,
    } = this.state.formErrors;

    const { onCancel } = this.props;
    return (
      <>
        <Paper sx={{ p: 4, borderRadius: 2 }} elevation={0}>
          <form
            onSubmit={this.submitHandler}
            autoComplete="off"
            noValidate={true}
          >
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={2}
            >
              <Grid
                item
                sx={{ p: 2 }}
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={4}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 20 }}
                    label="CAP Round Details"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={7} />

                <Grid item xs={12} sm={12} md={1}>
                  {this.state.isActive == "1" ? (
                    <ChipCompo
                      label="Active"
                      variant="outlined"
                      size="medium"
                      title="Click to InActive"
                      color="success"
                      onClick={this.changeActiveStatus}
                    />
                  ) : (
                    <ChipCompo
                      label="Inactive"
                      variant="outlined"
                      size="medium"
                      title="Click to active"
                      color="error"
                      onClick={this.changeActiveStatus}
                    />
                  )}
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Divider textAlign="left"></Divider>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Cap Round Name :"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="title"
                    size="small"
                    value={title}
                    onChange={this.changedHandler}
                    fullWidth
                    error={titleErr ? true : false}
                    helperText={titleErr ? titleErr : " "}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={12} />

                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Description :"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={remark}
                    onInit={(editor) => {}}
                    onChange={(event, editor) =>
                      this.onChangeDescription(event, editor)
                    }
                    onBlur={(editor) => {}}
                    onFocus={(editor) => {}}
                  />
                  <FormHelperText error>{shortErr} </FormHelperText>
                </Grid>

                <Grid item xs={12} sm={6} md={12} />

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  rowSpacing={1}
                  // columnSpacing={{xs  :12, sm  :6, md  :1}}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Highlight Label :"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="highlightLabel"
                        id="highlightLabel"
                        name="highlightLabel"
                        value={highlightLabel}
                        defaultChecked
                        onChange={this.changeHandler}
                        size="small"
                      >
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="No"
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Yes"
                          defaultChecked
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  rowSpacing={3}
                  // columnSpacing={{xs  :12, sm  :6, md  :1}}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    {highlightLabel === "1" ? (
                      <>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={12} md={12}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label="*Label Text :"
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <TextField
                              color="primary"
                              name="label"
                              size="small"
                              value={label}
                              onChange={this.changedHandler}
                              fullWidth
                              error={labelErr ? true : false}
                              helperText={labelErr ? labelErr : " "}
                            />
                          </Grid>
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={12} />

                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Round Date :"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <BasicDatePicker
                    label="Starting Date"
                    name="fromDate"
                    id="fromDate"
                    value={fromDate}
                    minDate={new Date()}
                    onChange={this.changeFromDate}
                    error={roundFromDateErr ? myConstClass.roundFromMsg : " "}
                  />
                  {/* {roundFromDateErr && (
                    <FormHelperText error>
                      {myConstClass.roundFromMsg}
                    </FormHelperText>
                  )} */}
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <BasicDatePicker
                    label="Closing Date"
                    name="toDate"
                    id="toDate"
                    value={toDate}
                    minDate={toDate}
                    onChange={this.changeToDate}
                    error={roundToDateErr ? myConstClass.roundToMsg : " "}
                  />

                  {/* {roundToDateErr && (
                    <FormHelperText error>
                      {myConstClass.roundToMsg}
                    </FormHelperText>
                  )} */}
                </Grid>

                {/* <Grid item xs={12} sm={6} md={12} />

                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Seat Allocation Type :"
                  />
                </Grid> */}

                {/* <Grid m={-1} item xs={12} sm={6} md={12}>
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className="text-black"
                      name="display"
                      value={display}
                      onChange={this.changedHandler}
                      size="small"
                      fullWidth={true}
                      {...(typeErr ? { error: true, helperText: typeErr } : "")}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="0">FIFA</MenuItem>
                      <MenuItem value="1">PRIORITY</MenuItem>
                      <MenuItem value="2">INTEREST</MenuItem>
                    </Select>
                    {typeErr ? { error: true, helperText: typeErr } : ""}
                  </FormControl>
                </Grid> */}

                {/* <Grid item xs={12} sm={6} md={12} /> */}
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Seat Allotment Date :"
                  />
                </Grid>

                <Grid sx={{ m: -1 }} item xs={12} sm={12} md={6}>
                  <BasicDatePicker
                    name="seatAllotmentDate"
                    id="seatAllotmentDate"
                    value={seatAllotmentDate}
                    minDate={toDate}
                    onChange={this.changeSeatAllotmentDate}
                    error={" "}
                  />
                </Grid>

                <Grid sx={{ m: -1 }} item xs={12} sm={4} md={6}></Grid>
                {/* <Grid item xs={12} sm={6} md={12} /> */}

                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Seat Acceptance Duration :"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <BasicDatePicker
                    label="From Date"
                    name="seatAcceptFromDate"
                    id="seatAcceptFromDate"
                    value={seatAcceptFromDate}
                    minDate={seatAllotmentDate}
                    onChange={this.changeSeatAcceptFromDate}
                    error={
                      seatAcceptFromErr ? myConstClass.seatAcceptFromMsg : " "
                    }
                  />
                  {/* {seatAcceptFromErr && (
                    <FormHelperText error>
                      {myConstClass.seatAcceptFromMsg}
                    </FormHelperText>
                  )} */}
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <BasicDatePicker
                    label="To Date"
                    name="seatAcceptToDate"
                    id="seatAcceptToDate"
                    value={seatAcceptToDate}
                    minDate={seatAcceptToDate}
                    onChange={this.changeSeatAcceptToDate}
                    error={seatAcceptToErr ? myConstClass.seatAcceptToMsg : " "}
                  />
                  {/* {seatAcceptToErr && (
                    <FormHelperText error>
                      {myConstClass.seatAcceptToMsg}
                    </FormHelperText>
                  )} */}
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Max Preference Limit :"
                  />
                  <br />{" "}
                  <LabelCompo
                    className="text-black"
                    style={{
                      fontSize: 12,
                      color: "#808080",
                      float: "left",
                      display: "block",
                    }}
                    label="  Note: Enter the number of maximum preferences student can enter"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="maxLimit"
                    size="small"
                    value={maxLimit}
                    onChange={this.changeFeesHandler}
                    fullWidth
                    error={preferenceErr ? true : false}
                    helperText={preferenceErr ? preferenceErr : " "}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 20 }}
                    label="Payment Details"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Fees Applicable :"
                  />
                </Grid>

                <Grid sx={{ m: -1 }} item xs={12} sm={12} md={5}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="feesApplicable"
                      id="feesApplicable"
                      name="feesApplicable"
                      value={feesApplicable}
                      defaultChecked
                      onChange={this.changeHandler}
                      size="small"
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="No"
                        defaultChecked
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Fix-price"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label="Per-preference"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={2} sm={4} md={5} />

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  rowSpacing={1}
                  // columnSpacing={{xs  :12, sm  :6, md  :1}}
                  container
                  justifyContent="space-evenly"
                >
                  {feesApplicable === "1" || feesApplicable === "2" ? (
                    <>
                      <Grid sx={{ ml: 0.7 }} item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Fees Type :"
                        />
                      </Grid>

                      <Grid
                        m={-1}
                        sx={{ ml: 0.7 }}
                        item
                        xs={12}
                        sm={12}
                        md={12}
                      >
                        <FormControl component="fieldset">
                          <RadioGroup
                            row
                            aria-label="feesType"
                            id="feesType"
                            name="feesType"
                            value={feesType}
                            defaultChecked
                            onChange={this.changeHandler}
                            size="small"
                          >
                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label="Manual"
                              defaultChecked
                            />
                            <FormControlLabel
                              value="0"
                              control={<Radio />}
                              label="Online"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  rowSpacing={1}
                  // columnSpacing={{xs  :12, sm  :6, md  :1}}
                  container
                  justifyContent="space-evenly"
                >
                  {feesApplicable === "1" || feesApplicable === "2" ? (
                    <>
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Fees :"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          color="primary"
                          name="fees"
                          size="small"
                          value={fees}
                          onChange={this.changeFeesHandler}
                          fullWidth
                        />
                      </Grid>
                    </>
                  ) : null}
                </Grid>

                <Grid item xs={12} sm={12} md={3}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Insturction to be printed on challan :"
                  />
                </Grid>
                <Grid sx={{ m: -1 }} item xs={12} sm={12} md={3}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="challanInstructions"
                      id="challanInstructions"
                      name="challanInstructions"
                      value={challanInstructions}
                      defaultChecked
                      onChange={this.changeHandler}
                      size="small"
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="No"
                        defaultChecked
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Yes"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6}></Grid>

                <Grid item xs={2} sm={4} md={6} />

                <Grid item xs={12} sm={12} md={12}>
                  {challanInstructions === "1" ? (
                    <div>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="*Fees payment instructions :"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <CKEditor
                            editor={ClassicEditor}
                            data={challanInst}
                            onInit={(editor) => {}}
                            onChange={(event, editor) =>
                              this.onChangeInEditor(event, editor)
                            }
                            onBlur={(editor) => {}}
                            onFocus={(editor) => {}}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}></Grid>
                      </Grid>
                    </div>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item xs={2} sm={4} md={12}></Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="flex-end"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1.1}
                  lg={1.1}
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Cancel"
                    fullWidth={true}
                    onClick={onCancel}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1.1}
                  lg={1.1}
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name="Save"
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </>
    );
  }
}

export default AddingCapRoundForm;
