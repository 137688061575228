import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import { logout } from '../../CommonActions/User.actions';
import endpoints from '../../config/endpoints';

let URL =  endpoints.auth;
// const checkLocalStorage = () => {
//     if (localStorage.getItem('applicationState') !== null) {
//         const { user } = JSON.parse(localStorage.getItem('applicationState'))
//         return user?.isAuthenticated
//     }
//     return false
// }

const initialState = {
    isAuthenticated: false,
    user: {},
    token:false
}

const loginSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loginSuccess: (state = cloneDeep(initialState), action) => {
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload
            }
        },
        loginFailure: (state = cloneDeep(initialState), action) => {

            return {
                ...state,
                isAuthenticated: false,
                user: {},
                token:false
            }

        },
        tokenExpSuccess: (state = cloneDeep(initialState), action) => {

            return {
                ...state,
                token:true
            }

        }
    }
})

export const { loginSuccess,tokenExpSuccess, loginFailure } = loginSlice.actions

export default loginSlice.reducer

//Actions


export const login = ({ name }) => async dispatch => {

    try {

 //dispatch(loginSuccess({ name }));
     //localStorage.setItem('trustId', '1');
        const headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization':name,
        };
      const response=  await axios.post(
            URL,
            {

            },
            { headers }
        ).then(response => {

            console.log("Success ========>", response);
            console.log("Success ========>", response.data.data.trustId);
            const { externalToken ,trustId,role,username} = response.data.data
            const name= Buffer.from(externalToken).toString('base64');
            localStorage.setItem('token', name);
            localStorage.setItem('trustId', trustId);
            localStorage.setItem('role', role);
            localStorage.setItem('person', username);
            dispatch(loginSuccess({ name }));
return true
        })
            .catch(error => {
                console.log("Error ========>", error);
                return false
            }
            )
return response
    } catch (e) {
        dispatch(loginFailure())

        return console.error(e.message);
    }
}


export const logOut = () => async dispatch => {

    try {

        dispatch(loginFailure())
        dispatch(logout()); 
    } catch (e) {
        dispatch(loginFailure())

        return console.error(e.message);
    }
}


export const tokenExp = () => async dispatch => {

    try {

        
        dispatch(tokenExpSuccess())
     //localStorage.setItem('trustId', '1');

    } catch (e) {
      
    }
}
