import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { decodeData } from "../../components/Comman/Util/Base64";
import endpoints from "../../config/endpoints";
import { tokenExp } from "../Login/login.slice";

const initialState = {
  academicYear: [],
  isFetch: false,
  isFetchAcYear: false,
  academicYearDetails: {},
  isSave: false,
  academicYearFilter: [],
  isFilter: false,
  //
  error: false,
  isServerFail: false,
  isDelete: false,
};
let URL = endpoints.academicYear;
let URL1 = endpoints.newAcademicYear;
const academicYearSlice = createSlice({
  name: "academicYear",
  initialState,
  reducers: {
    academicYearSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        academicYear: row,
        isFetchAcYear: true,
      };
    },

    academicYearFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        academicYear: [],
        isFetchAcYear: false,
      };
    },
    academicYearDetailsSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        academicYearDetails: data,
        isSave: true,
        error: false,
        isDelete: false,
      };
    },
    academicYearDetailsFail: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        academicYearDetails: {},
        isSave: false,
        error: false,
        isDelete: false,
      };
    },
    academicYearFilterSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        academicYearFilter: row,
        isFilter: true,
      };
    },
    academicYearFilterFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        academicYearFilter: [],
        isFilter: false,
      };
    },
    academicYearListByNameSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { data } = action.payload;
      return {
        ...state,
        academicYearData: data,
        isFetch: true,
        error: false,
        isDelete: false,
      };
    },
    academicYearListByNameFailure: (
      state = cloneDeep(initialState),
      action
    ) => {
      return {
        ...state,
        academicYearData: [],
        isFetch: false,
        error: false,
        isDelete: false,
      };
    },

    serverFailed: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: true,
        error: false,
        isDelete: false,
      };
    },
    acadamicServerFailedReset: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: false,
        error: false,
        isDelete: false,
      };
    },
    academicYearDeleteSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error: false,
        isDelete: true,
      };
    },

    academicDeleteFail: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error: true,
        isDelete: true,
        isSave: false,
      };
    },

    resetDeleteSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error: false,
        isDelete: false,
        isSave: false,
        isServerFail: false,
      };
    },
  },
});

export const {
  academicYearDeleteSuccess,
  academicDeleteFail,
  academicYearFilterFailure,
  academicYearFilterSuccess,
  academicYearSuccess,
  academicYearDetailsSuccess,
  academicYearDetailsFail,
  academicYearFailure,
  academicYearListByNameSuccess,
  academicYearListByNameFailure,
  serverFailed,
  acadamicServerFailedReset,
  resetDeleteSuccess,
} = academicYearSlice.actions;

export default academicYearSlice.reducer;

export const getAcademicYear = () => async (dispatch) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get(URL1 + '?sort={"insertdatetime": "DESC"}', { headers })
      .then((response) => {
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          console.log("Success getAcademicYear ========>", response);
          let data = response.data.data.content;
          let row = [];
          let index = 0;
          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              instName: data1.tenant.tenantName,
              fromDate: data1.fromDate,
              toDate: data1.toDate,
              isLocked: data1.isLocked,
              admissionOpen: data1.admissionOpen === 1 ? "Yes" : "No",
              isActive: data1.isActive === 1 ? "Active" : "Inactive",
              instNameId: data1.tenant.id,
            };
            row.push(bData);
          });
          dispatch(academicYearSuccess({ row }));
          return row;
        }
      })
      .catch((error) => {
        console.log("Error getAcademicYear========>", error);
        dispatch(serverFailed());
      });
    return doc;
  } catch (e) {
    dispatch(serverFailed());
    return console.error(e.message);
  }
};

export const deleteAcademicYearData = () => async (dispatch) => {
  try {
    dispatch(academicYearListByNameFailure());
  } catch (e) {
    return console.error(e.message);
  }
};

export const resetAcadamicList = () => async (dispatch) => {
  try {
    dispatch(academicYearFailure());
  } catch (e) {
    return console.error(e.message);
  }
};
export const resetSave = () => async (dispatch) => {
  try {
    dispatch(academicYearDetailsFail());
  } catch (e) {
    return console.error(e.message);
  }
};
export const getAcademicYearById = () => async (dispatch) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get(URL + "/1", { headers })
      .then((response) => {
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          console.log(
            "Success With id getAcademicYearById ========>",
            response
          );
        }
      })
      .catch((error) => {
        dispatch(serverFailed());
        console.log("Error getAcademicYearById ========>", error.response.data);
      });
    return doc;
  } catch (e) {
    dispatch(serverFailed());
    return console.error(e.message);
  }
};

export const updateAcademicYearById =
  ({ academicYearData }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .get(
          URL1 +
            "/update-admission-open-status?id=" +
            academicYearData.academicYearId +
            "&status=" +
            academicYearData.status +
            "&instituteId=" +
            academicYearData.instNameId,
          { headers }
        )
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            console.log(
              "Success With id getAcademicYearById ========>",
              response
            );
          }
          return true;
        })
        .catch((error) => {
          dispatch(serverFailed());
          console.log(
            "Error getAcademicYearById ========>",
            error.response.data
          );
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return console.error(e.message);
    }
  };

export const saveAcademicYear =
  ({ academicYearData }) =>
  async (dispatch) => {
    try {
      let data = JSON.stringify(academicYearData);
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .post(URL, data, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            console.log(
              "Success With saveAcademicYear ========>",
              response.data
            );
            const { data } = response.data;
            dispatch(academicYearDetailsSuccess({ data }));
            return data;
          }
        })
        .catch((error) => {
          dispatch(serverFailed());
          console.log("Error saveAcademicYear ========>", error.response.data);
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return console.error(e.message);
    }
  };

export const deleteAcademicYearById =
  ({ academicYearId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      let response = await axios
        .delete(URL + "/" + academicYearId, { headers })
        .then((response) => {
          console.log(
            "Success With deleteAcademicYearById ========>",
            response
          );
          dispatch(academicYearDeleteSuccess());
          return true;
        })
        .catch((error) => {
          if (error.response) {
            console.log("error.response.status");
            console.log(error.response.status);
            console.log("error.response.data");
            console.log(error.response.data);
          }
          dispatch(academicDeleteFail());
          return error.response.data;
        });
      return response;
    } catch (e) {
      dispatch(serverFailed());
      return console.error(e.message);
    }
  };
export const filterAcademicYearData =
  ({ row, flag }) =>
  async (dispatch) => {
    try {
      if (flag == true) {
        dispatch(academicYearFilterSuccess({ row }));
      } else {
        dispatch(academicYearSuccess({ row }));
      }
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetDelete = () => async (dispatch) => {
  try {
    dispatch(resetDeleteSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
export const resetAcadamicServerFailed = () => async (dispatch) => {
  try {
    dispatch(acadamicServerFailedReset());
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAcademicYearByInstituteId =
  ({ instId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .get(
          URL + "/get-academic-year-list-by-institute-id?instituteId=" + instId,
          { headers }
        )
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            console.log("Success getAcademicYear ========>", response);
            let data = response.data.data;
            let row = [];
            let index = 0;
            data.map((data1) => {
              index = index + 1;
              let bData = {
                index: index,
                id: data1.id,
                name: data1.name,
                fromDate: data1.fromDate,
                toDate: data1.toDate,
                isLocked: data1.isLocked,
                isActive: data1.isActive === 1 ? "Active" : "Inactive",
                instNameId: data1.tenant.id,
                instName: data1.tenant.tenantName,
              };
              row.push(bData);
            });
            dispatch(academicYearSuccess({ row }));
            return row;
          }
        })
        .catch((error) => {
          console.log("Error getAcademicYear========>", error);
          dispatch(serverFailed());
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return console.error(e.message);
    }
  };
