import { Grid, TextField } from "@mui/material";
import { withStyles } from "@mui/styles";
import Header from "../../components/NewHeader";
import NewLanding from "../../CSS/NewLanding.css";
import React, { useState, useEffect } from "react";
import { login } from "./login.slice";
import { encodeData } from "../../components/Comman/Util/Base64";
import { getProfile } from "../Student/student.slice";
import { useNavigate } from "react-router-dom";
import FormHelperText from "@mui/material/FormHelperText";
import { connect } from "react-redux";
import Navbar from "../../components/Navbar";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
const styles = (theme) => ({
  hello: {
    position: "absolute",
    left: "15.17%",
    right: "15.08%",
    top: "63.4%",
    bottom: "32.6%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: "normal",
    fontsize: "36px",
    lineheight: "40px",
    color: "#000000",
  },
  buttonC: {
    position: "absolute",

    left: "39.17%",
    right: "39.17%",
    top: "79.7%",
    bottom: "14.8%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: 500,
    fontsize: "18px",
    lineheight: "40px",
    color: "#FFFFFF",
  },
  helper: {
    position: "absolute",
    left: "9.58%",
    right: "9.5%",
    top: "70%",
    bottom: "22%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: "normal",
    fontsize: "20px",
    lineheight: "40px",

    textAlign: "center",

    color: "#666666",
  },
  title: {
    position: "absolute",
    left: "43.42%",
    right: "43.33%",
    top: "57%",
    bottom: "39%",

    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: 500,
    fontsize: "55px",
    lineheight: "40px",
    color: "#000000",
  },
});
const ErrorPage = ({ getProfile, login, classes }) => {
  const initState = { name: "", password: "" };
  const [auth, setAuth] = useState(initState);
  const [loginErr, setloginErr] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const navigate = useNavigate();

  const Login = () => {
    localStorage.clear();

    const name = `${auth.name}:${auth.password}`;

    const userName = encodeData(name);

    login({ name: userName }).then((response) => {
      if (response) {
        let role = localStorage.getItem("role");
        if (role == "trust") {
          navigate("/master");
        } else {
          getProfile().then(() => {
            let role = localStorage.getItem("role");
            let type = localStorage.getItem("type");
            if (role === "applicant" && type != 4) {
              navigate("/interestedprogram");
            } else {
              navigate("/student-dashboard");
            }
          });
        }
      } else {
        setloginErr("Invalid credentials");
      }
    });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setAuth({
      ...auth,
      [name]: value,
    });
  };

  return (
    <>
      <div align="center">
        {/* <Header/> */}
        <Header />
        <Navbar />
        <div className="new_content">
          <div className="dash_login">
            <Grid item container justifyContent="space-between">
              <Grid item md={4}></Grid>
              <Grid item md={4}>
                <div className="lgn_bx">
                  <h3 className="lgn_title">Login Form</h3>

                  <div className="lgn_container">
                    <div className="user_lgn">
                      <div className="user_label">Username</div>
                      <div className="user_field">
                        <TextField
                          color="primary"
                          name="name"
                          size="small"
                          value={auth.name}
                          onChange={onChange}
                          fullWidth
                        />
                      </div>
                    </div>

                    <div className="user_lgn">
                      <div className="user_label">Password</div>
                      <div className="user_field">
                        <OutlinedInput
                          color="primary"
                          name="password"
                          size="small"
                          type={showPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          value={auth.password}
                          onChange={onChange}
                          fullWidth
                          onKeyPress={(e) => e.key === "Enter" && Login()}
                        />
                      </div>
                      {/* <div className="frgt_nm">
                        <a href="forgotuserpassword">Forgot Password</a>
                      </div> */}
                      <div className="frgt_main">
                        <div className="frgt_nm left">
                          <a href="forgotuserid">Forgot Username</a>
                        </div>
                        <div className="frgt_nm">
                          <a href="forgotuserpassword">Forgot Password</a>
                        </div>
                      </div>
                    </div>
                    <div className="lgn_btn">
                      <button
                        type="button"
                        onClick={Login}
                        className="register_btn"
                      >
                        Login
                      </button>
                      <div className="register_nm">
                        New User<a href="registerstudent">Register Here</a>
                        {loginErr !== "" && (
                          <FormHelperText error>{loginErr}</FormHelperText>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item md={4}></Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  newsDocNotificationDocs: state.landing,
  latestnews: state.landing,
  latestnotification: state.landing,
});

const mapDispatchToProps = {
  login,
  getProfile,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ErrorPage));
