import React from "react";
import ExportCSV from "../../components/Comman/ExportCSV";
import { generatePDF } from "../../components/Comman/GeneratePDF";
import { FontAwesomeIconCompo } from "../../components/Comman/IconButton";
const AcademicExcelData = ({ csvData, instList, fileName, type }) => {
  const tablecol = instList.map((title) => {
    if (title.title !== "Action") {
      return title.title;
    }
  });
  let index = 0;
  const academicData = csvData.map((csvData) => {
    index = index + 1;
    let inst = {
      "Sr. No.": index,
    };

    instList.map((title) => {
      if (title.name == "name") {
        inst = {
          ...inst,
          "Academic Year Name": csvData.name,
        };
      }
      if (title.name == "fromDate") {
        inst = {
          ...inst,
          "From Date": csvData.fromDate,
        };
      }
      if (title.name == "toDate") {
        inst = {
          ...inst,
          "To Date": csvData.toDate,
        };
      }

      if (title.name == "isActive") {
        inst = {
          ...inst,
          Status: csvData.isActive,
        };
      }
    });
    return inst;
  });

  if (type === "pdf") {
    return (
      <FontAwesomeIconCompo
        color="error"
        fontSize="small"
        title="PDF"
        baseClassName="fas"
        className="fa-file-pdf"
        onClick={() =>
          generatePDF(academicData, tablecol, "Academic Year Master")
        }
      />
    );
  }

  return <ExportCSV csvData={academicData} fileName={fileName} />;
};
export default AcademicExcelData;
