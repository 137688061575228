import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { decodeData } from "../../components/Comman/Util/Base64";
import endpoints from "../../config/endpoints";
import { tokenExp } from "../Login/login.slice";

const initialState = {
  getClassByProgType: [],
  class: [],
  classData: [],
  isFetchClass: false,
  isFetch: false,
  classById: [],
  isFetchById: false,
  isFetchClassDetails: false,
  classDetails: {},
  isSave: false,
  classFilter: [],
  isFilter: false,
  isServerFail: false,
  error: false,
  isFetchClassByProgType: false,
};
let URL = endpoints.class;
const classSlice = createSlice( {
  name: "class",
  initialState,
  reducers: {
    classSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        class: row,
        isFetchClass: true,
      };
    },
    classByProgTypeSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        getClassByProgType: row,
        isFetchClassByProgType: true,
      };
    },
    classDeleteFail: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        error: true,
        isDelete: true,
        isSave: false,
      };
    },
    classFilterSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        classFilter: row,
        isFilter: true,
      };
    },
    classFilterFailure: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        classFilter: [],
        isFilter: false,
      };
    },
    classDeleteSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        error: false,
        isDelete: true,
      };
    },
    classByIdSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        classById: row,
        isFetchById: true,
      };
    },
    classDetailsSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { data } = action.payload;
      return {
        ...state,
        classDetails: data,
        isFetchClassDetails: true,
        isSave: true,
      };
    },
    classDetailsFail: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        classDetails: {},
        isFetchClassDetails: false,
        isSave: false,
      };
    },
    classFailure: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        class: [],
      };
    },
    classListByNameSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { data } = action.payload;
      return {
        ...state,
        classData: data,
        isFetch: true,
      };
    },
    classListByNameFailure: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        classData: [],
        isFetch: false,
      };
    },
    serverFailed: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        isServerFail: true,
        error: false,
        isDelete: false
      };
    },
    resetDeleteSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        error: false,
        isDelete: false,
        isSave: false,
      };
    },
  },
} );

export const {
  classFilterSuccess,
  classSuccess,
  resetDeleteSuccess,
  classByIdSuccess,
  classDeleteSuccess,
  classDetailsSuccess,
  classDetailsFail,
  classFailure,
  classDeleteFail,
  serverFailed,
  classListByNameSuccess,
  classListByNameFailure,
  classByProgTypeSuccess,
} = classSlice.actions;

export default classSlice.reducer;

export const getClass = () => async ( dispatch ) =>
{
  try
  {
    const name = localStorage.getItem( "token" );
    const token = decodeData( name );
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get( URL + "/year-detail" + '?sort={"sortOrderNo": "ASC"}', { headers } )
      .then( ( response ) =>
      {
        const { error } = response.data
        if ( error == true )
        {
          dispatch( tokenExp() );

        }
        let data = response.data.data.content;
        let row = [];
        let index = 0;
        data.map( ( data1 ) =>
        {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.className,
            progName: data1.programType.name + " - " + data1.programType.program.name,
            sortOrderNo: data1.sortOrderNo,
            progNameId: data1.programType.id,
            instNameId: data1.tenant.id,
            tenantName: data1.tenant.tenantName,
            isActive: data1.isActive === 1 ? "Active" : "Inactive",

            classDetails: data1.yearHeaderDetailList.map( ( yearDetail ) =>
            {
              let classData = {
                classId: data1.id,
                id: yearDetail.id,
                courseName: yearDetail.programTitle.brName,
                courseId: yearDetail.programTitle.id,
                isActive: yearDetail.isActive === 1 ? "Active" : "Inactive",
              };
              return classData;
            } ),
          };
          row.push( bData );
        } );
        dispatch( classSuccess( { row } ) );
        return row;
      } )
      .catch( ( error ) =>
      {
        console.log( "Error getDocument========>", error );
        dispatch( serverFailed() );
        return false;
      } );
    return doc;
  } catch ( e )
  {
    dispatch( serverFailed() );
    // return console.error(e.message);
    return false;

  }
};

export const getClassByProgTypeId = ( { progTypeId } ) => async ( dispatch ) =>
{
  try
  {
    const name = localStorage.getItem( "token" );
    const token = decodeData( name );
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get( URL + "/get-all-year-by-program-type-id?programTypeId=" + progTypeId, { headers } )
      .then( ( response ) =>
      {
        const { error } = response.data
        if ( error == true )
        {
          dispatch( tokenExp() );

        }
        console.log( response )
        let { data } = response.data;
        console.log( data )
        let row = [];
        let index = 0;
        data.map( ( data1 ) =>
        {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.className,
            progName: data1.programType.name,
            sortOrderNo: data1.sortOrderNo,
            progNameId: data1.programType.id,
            instNameId: data1.tenant.id,
            tenantName: data1.tenant.tenantName,
            isActive: data1.isActive === 1 ? "Active" : "Inactive",

            classDetails: data1.yearHeaderDetailList.map( ( yearDetail ) =>
            {
              let classData = {
                classId: data1.id,
                id: yearDetail.id,
                courseName: yearDetail.programTitle.brName,
                courseId: yearDetail.programTitle.id,
                isActive: yearDetail.isActive === 1 ? "Active" : "Inactive",
              };
              return classData;
            } ),
          };
          row.push( bData );
        } );
        dispatch( classByProgTypeSuccess( { row } ) );
        return row;
      } )
      .catch( ( error ) =>
      {
        console.log( "Error getDocument========>", error );
        dispatch( serverFailed() );
        return false;
      } );
    return doc;
  } catch ( e )
  {
    dispatch( serverFailed() );
    return false;
    // return console.error( e.message );
  }
};


export const resetSave = () => async ( dispatch ) =>
{
  try
  {
    dispatch( classDetailsFail() );
  } catch ( e )
  {
    return console.error( e.message );
  }
};
export const getclassByName =
  ( { id, name } ) =>
    async ( dispatch ) =>
    {
      try
      {
        const name1 = localStorage.getItem( "token" );
        const token = decodeData( name1 );
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
          shouldrefresh: "true",
        };
        if ( id == 0 )
        {
          const doc = await axios
            .get(
              URL + '?filters={"className":"' + name + '","_mode" : "EXACT"}',
              { headers }
            )
            .then( ( response ) =>
            {
              console.log(
                "Success getDoc  ========>",
                response.data.data.content
              );
              const { error } = response.data.data.content
              if ( error == true )
              {
                dispatch( tokenExp() );

              }
              else
              {
                let data = response.data.data.content;

                console.log( data );
                dispatch( classListByNameSuccess( { data } ) );
                return data;
              }
            } )
            .catch( ( error ) =>
            {
              console.log( "Error getDoc========>", error );
              return false;
            } );
          return doc;
        } else
        {
          const doc = await axios
            .get(
              URL +
              '?filters={"id":"' +
              id +
              '","_mode" : "NIN"},{"className":"' +
              name +
              '","_mode" : "EXACT"}',
              { headers }
            )
            .then( ( response ) =>
            {
              console.log(
                "Success getDoc  ========>",
                response.data.data.content
              );
              const { error } = response.data.data.content
              if ( error == true )
              {
                dispatch( tokenExp() );

              }
              else
              {
                let data = response.data.data.content;

                dispatch( classListByNameSuccess( { data } ) );
                return data;
              }
            } )
            .catch( ( error ) =>
            {
              console.log( "Error getDoc========>", error );
              dispatch( serverFailed() );
              return false;
            } );
          return doc;
        }
      } catch ( e )
      {
        dispatch( serverFailed() );
        // return console.error( e.message );
        return false;

      }
    };

export const deleteClassData = () => async ( dispatch ) =>
{
  try
  {
    dispatch( classListByNameFailure() );
  } catch ( e )
  {
    return console.error( e.message );
  }
};
export const saveClass =
  ( { classData } ) =>
    async ( dispatch ) =>
    {
      try
      {
        let data = JSON.stringify( classData );
        const name = localStorage.getItem( "token" );
        const token = decodeData( name );
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
          shouldrefresh: "true",
        };
        const doc = await axios
          .post( URL, data, { headers } )
          .then( ( response ) =>
          {
            console.log( "Success with save class ========>", response );
            const { error } = response.data
            if ( error == true )
            {
              dispatch( tokenExp() );

            }
            else
            {
              const { data } = response.data;
              dispatch( classDetailsSuccess( { data } ) );
              return data;
            }
          } )
          .catch( ( error ) =>
          {
            dispatch( serverFailed() );
            return false;
            // console.log( "Error save class ========>", error.response.data );
          } );
        return doc;
      } catch ( e )
      {
        dispatch( serverFailed() );
        return false;
        // return console.error( e.message );
      }
    };

export const deleteClassById =
  ( { classId } ) =>
    async ( dispatch ) =>
    {
      try
      {
        const name = localStorage.getItem( "token" );
        const token = decodeData( name );
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
          shouldrefresh: "true",
        };
        const response = await axios
          .delete( URL + "/" + classId, { headers } )
          .then( ( response ) =>
          {
            console.log( "Success with delete class by id ========>", response );
            dispatch( classDeleteSuccess() );
            return true;

          } )
          .catch( ( error ) =>
          {
            if ( error.response )
            {
              console.log( 'error.response.status' )
              console.log( error.response.status )
              console.log( 'error.response.data' )
              console.log( error.response.data )
            }
            dispatch( classDeleteFail() );
            return false;
            // return error.response.data;
          } );
          return response;
      } catch ( e )
      {
        dispatch( serverFailed() );
        return false;
        // return console.error( e.message );
      }
    };

export const getClassById =
  ( { classId } ) =>
    async ( dispatch ) =>
    {
      try
      {
        const name = localStorage.getItem( "token" );
        const token = decodeData( name );
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
          shouldrefresh: "true",
        };
        const doc = await axios
          .get( URL + "/" + classId + "/class-detail", { headers } )
          .then( ( response ) =>
          {
            console.log( "Success program getClass========>", response.data.data );
            const { error } = response.data
            if ( error == true )
            {
              dispatch( tokenExp() );

            }
            else
            {
              const { data } = response.data;
              let row = [];
              let index = 0;
              data.map( ( data1 ) =>
              {
                index = index + 1;
                let bData = {
                  index: index,
                  id: data1.id,
                  courseId: data1.programTitle.id,
                  courseName:
                    data1.programTitle.brName +
                    " ( " +
                    data1.programTitle.brShortName +
                    " )",
                  isActive: data1.isActive === 1 ? "Active" : "Inactive",
                  classId: data1.year.id,
                  className: data1.year.className,
                };
                row.push( bData );
              } );
              dispatch( classByIdSuccess( { row } ) );
              return row;
            }
          } )
          .catch( ( error ) =>
          {
            return false;
            // console.log( "Error getClass ========>", error );
          } );
        return doc;
      } catch ( e )
      {
        classFailure();
        return false;
        // return console.error( e.message );
      }
    };
export const filterClassData =
  ( { row, flag } ) =>
    async ( dispatch ) =>
    {
      try
      {
        if ( flag == true )
        {
          dispatch( classFilterSuccess( { row } ) );
        } else
        {
          dispatch( classSuccess( { row } ) );
        }
      } catch ( e )
      {
        return console.error( e.message );
      }
    };
export const resetDelete = () => async ( dispatch ) =>
{
  try
  {
    dispatch( resetDeleteSuccess() );
  } catch ( e )
  {
    return console.error( e.message );
  }
};