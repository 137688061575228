import React from "react";
import ExportCSV from "../../components/Comman/ExportCSV";
import { generatePDF } from "../../components/Comman/GeneratePDF";
import { FontAwesomeIconCompo } from "../../components/Comman/IconButton";
const DocumentExcelData = ({ csvData, docList, fileName, type }) => {
  const tablecol = docList.map((title) => {
    if (title.title !== "Action") {
      return title.title;
    }
  });
  let index = 0;
  const docData = csvData.map((csvData) => {
    index = index + 1;
    let document = {
      "Sr. No.": index,
    };

    if (docList.length !== 3) {
      docList.map((title) => {
        if (title.name == "docName") {
          document = {
            ...document,
            " Document Name": csvData.docName,
          };
        }

        if (title.name == "sortOrderNo") {
          document = {
            ...document,
            " Sort Order No": csvData.sortOrderNo,
          };
        }

        if (title.name == "isActive") {
          document = {
            ...document,
            Status: csvData.isActive,
          };
        }
      });
    } else {
      document = {
        ...document,
        "Document  Name": csvData.docName,
        "Sort Order No": csvData.sortOrderNo,
        Status: csvData.isActive,
      };
    }

    return document;
  });
  if (type === "pdf") {
    return (
      <FontAwesomeIconCompo
        color="error"
        fontSize="small"
        title="PDF"
        baseClassName="fas"
        className="fa-file-pdf"
        onClick={() => generatePDF(docData, tablecol, "Document Master")}
      />
    );
  }

  return <ExportCSV csvData={docData} fileName={fileName} />;
};
export default DocumentExcelData;
