import React from "react";
import ExportCSV from "../../components/Comman/ExportCSV";
import { generatePDF } from "../../components/Comman/GeneratePDF";
import { FontAwesomeIconCompo } from "../../components/Comman/IconButton";
const ClassIntakeDetExcelData = ({
  csvData,
  classIntakeDetList,
  fileName,
  type,
}) => {
  const tablecol = classIntakeDetList.map((title) => {
    if (title.title !== "Action") {
      return title.title;
    }
  });
  let index = 0;
  const classIntakeData = csvData.map((csvData) => {
    index = index + 1;
    let classIntake = {
      "Sr. No.": index,
    };

    if (classIntakeDetList.length != 4) {
      classIntakeDetList.map((title) => {
        if (title.name == "name") {
          classIntake = {
            ...classIntake,
            " Class Detail Name": csvData.name,
          };
        }
        if (title.name == "acYearName") {
          classIntake = {
            ...classIntake,
            " Academic Year Name": csvData.acYearName,
          };
        }

        if (title.name == "sanctionIntake") {
          classIntake = {
            ...classIntake,
            "Sanction Intake": csvData.sanctionIntake,
          };
        }

        if (title.name == "isActive") {
          classIntake = {
            ...classIntake,
            Status: csvData.isActive,
          };
        }
      });
    } else {
      classIntake = {
        ...classIntake,
        "Class Detail Name": csvData.name,
        "Academic Year Name": csvData.acYearName,
        "Sanction Intake": csvData.sanctionIntake,
        Status: csvData.isActive,
      };
    }

    return classIntake;
  });

  if (type === "pdf") {
    return (
      <FontAwesomeIconCompo
        color="error"
        fontSize="small"
        title="PDF"
        baseClassName="fas"
        className="fa-file-pdf"
        onClick={() =>
          generatePDF(classIntakeData, tablecol, "Class Intake Detail")
        }
      />
    );
  }

  return <ExportCSV csvData={classIntakeData} fileName={fileName} />;
};
export default ClassIntakeDetExcelData;
