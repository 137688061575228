import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { decodeData } from "../../components/Comman/Util/Base64";
import endpoints from "../../config/endpoints";
import { tokenExp } from "../Login/login.slice";
const initialState = {
  capRoundData: [],
  isFetchList: false,
  error: false,
  isServerFail: false,
  isDelete: false,
  isDataFetch: false,
};
let URL =  endpoints.addcapround;
const addingCapRoundSlice = createSlice({
  name: "meritlist",
  initialState,
  reducers: {
    capRoundDataSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        capRoundData: row,
        isFetchData: true,
      };
    },
    capRoundDataFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isFetchData: false,
        capRoundData: [],
      };
    },
    serverFailed: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: true,
        error: false,
        isDelete: false,
      };
    },
  },
});

export const { capRoundDataSuccess, capRoundDataFailure, serverFailed } =
  addingCapRoundSlice.actions;

export default addingCapRoundSlice.reducer;

export const saveCapRound =
  ({ capRoundData }) =>
  async (dispatch) => {
    try {
      let data = JSON.stringify(capRoundData);

      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .post(URL, data, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          console.log("Success With saveCapRound ========>", response.data);
          const { data } = response.data;
          return data;
        })
        .catch((error) => {
          console.log("Error saveCapRound ========>", error.response.data);
          dispatch(serverFailed());
          return false
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false
    }
  };
