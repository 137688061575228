import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { decodeData } from "../../components/Comman/Util/Base64";
import endpoints from "../../config/endpoints";
import { tokenExp } from "../Login/login.slice";

const initialState = {
  catSeatIntakeDetail: [],
  catSeatIntakeDetailData: [],
  reservListDetails: {},
  catSeatIntakeFilter: [],
  isFilter: false,
  isSave: false,
  isFetch: false,
  isFetchCat: false,
  isReservCat: false,
  isServerFail: false,
  isDelete: false,
  ReserveList: [],
};
let URL = endpoints.catSeatIntakeDetail;
const catSeatIntakeDetailSlice = createSlice( {
  name: "catSeatIntakeDetail",
  initialState,
  reducers: {
    catSeatIntakeDetailSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        catSeatIntakeDetail: row,
        isFetchCat: true,
      };
    },
    catSeatIntakeFilterSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        catSeatIntakeFilter: row,
        isFilter: true,
      };
    },
    catSeatIntakeFilterFailure: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        catSeatIntakeFilter: [],
        isFilter: false,
      };
    },

    catSeatIntakeDetailFailure: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        catSeatIntakeDetail: [],
        isFetchCat: false,
      };
    },
    reservListSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { reservData } = action.payload;
      return {
        ...state,
        ReserveList: reservData,
        isReservCat: true,
      };
    },

    catSeatDeleteFail: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        error: true,
        isDelete: true,
        isSave: false,
      };
    },
    reservListFailure: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        ReserveList: [],
        isReservCat: false,
      };
    },
    catSeatDeleteSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        error: false,
        isDelete: true,
      };
    },
    reservListDetailsSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { data } = action.payload;
      return {
        ...state,
        reservListDetails: data,
        isDelete: false,
        isSave: true,
      };
    },
    reservListDetailsFail: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        reservListDetails: {},
        isSave: false,
      };
    },
    catSeatIntakeDetailListByNameSuccess: (
      state = cloneDeep( initialState ),
      action
    ) =>
    {
      const { data } = action.payload;
      return {
        ...state,
        catSeatIntakeDetailData: data,
        isFetch: true,
      };
    },
    catSeatIntakeDetailListByNameFailure: (
      state = cloneDeep( initialState ),
      action
    ) =>
    {
      return {
        ...state,
        catSeatIntakeDetailData: [],
        isFetch: false,
      };
    },
    resetDeleteSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        error: false,
        isDelete: false,
        isSave: false,
      };
    },
    serverFailed: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        isServerFail: true,
        error: false,
        isDelete: false,
      };
    },
  },
} );

export const {
  catSeatIntakeDetailSuccess,
  catSeatIntakeDetailFailure,
  catSeatIntakeFilterSuccess,
  reservListDetailsSuccess,
  reservListDetailsFail,
  catSeatDeleteFail,
  reservListSuccess,
  resetDeleteSuccess,
  reservListFailure,
  serverFailed,
  catSeatDeleteSuccess,
  catSeatIntakeDetailListByNameSuccess,
  catSeatIntakeDetailListByNameFailure,
} = catSeatIntakeDetailSlice.actions;

export default catSeatIntakeDetailSlice.reducer;

export const getCatSeatIntakeDetail = () => async ( dispatch ) =>
{
  try
  {
    const name = localStorage.getItem( "token" );
    const token = decodeData( name );
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const docs = await axios
      .get( URL + '?sort={"insertdatetime": "DESC"}', { headers } )
      .then( ( response ) =>
      {
        const { error } = response.data;
        if ( error == true )
        {
          dispatch( tokenExp() );
        } else
        {
          let data = response.data.data.content;
          let row = [];
          let index = 0;
          data.map( ( data1 ) =>
          {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              docName: data1.name,
              instituteName: data1.tenant.tenantName,
              instituteNameId: data1.tenant.id,
              sanctionIntake: data1.classIntakeDetail.sanctionIntake,
              reservationStructHeader: data1.reservationStructure.name,
              reservationStructHeaderId: data1.reservationStructure.id,
              //classIntakeDetaill: data1.classIntakeDetail.yearDetail.year.className,
              instNameId: data1.tenant.id,
              categorySeatIntakeDetailList: data1.categorySeatIntakeDetailList,
              classIntakeDetaill:

                data1.classIntakeDetail.yearDetail.year.tenant.tenantName +
                " - " +
                data1.classIntakeDetail.yearDetail.year.programType.name +
                "-" +
                data1.classIntakeDetail.yearDetail.programTitle.brName +
                " - " +
                data1.classIntakeDetail.yearDetail.year.className +
                " - " + data1.classIntakeDetail.academicYear.name,

              classIntakeDetaillId: data1.classIntakeDetail.id,
              //+" ( "+data1.classIntakeDetail.yearDetail.yearprogramType.name+ " ) "
              isActive: data1.isActive === 1 ? "Active" : "Inactive",
            };
            row.push( bData );
          } );
          dispatch( catSeatIntakeDetailSuccess( { row } ) );
          return row;
        }
      } )
      .catch( ( error ) =>
      {
        console.log( "Error getcatSeatIntakeDetail========>", error );
        dispatch( serverFailed() );
        return false;
      } );
    return docs;
  } catch ( e )
  {
    dispatch( serverFailed() );
    return false;

    // return console.error(e.message);
  }
};
export const resetSave = () => async ( dispatch ) =>
{
  try
  {
    dispatch( reservListDetailsFail() );
  } catch ( e )
  {
    return console.error( e.message );
  }
};
export const getCatSeatIntakeDetailByName =
  ( { docName } ) =>
    async ( dispatch ) =>
    {
      try
      {
        const name = localStorage.getItem( "token" );
        const token = decodeData( name );
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
          shouldrefresh: "true",
        };
        let doc = await axios
          .get(
            URL +
            '?filters={"delStatus": "1","_mode" : "EXACT"},{"name":"' +
            docName +
            '","_mode" : "EXACT"}',
            { headers }
          )
          .then( ( response ) =>
          {
            const { error } = response.data;
            if ( error == true )
            {
              dispatch( tokenExp() );
            }
            console.log(
              "Success getcatSeatIntakeDetailByName  ========>",
              response.data.data.content
            );
            let data = response.data.data.content;
            dispatch( catSeatIntakeDetailListByNameSuccess( { data } ) );
            return data;
          } )
          .catch( ( error ) =>
          {
            console.log( "Error getcatSeatIntakeDetail========>", error );
            dispatch( serverFailed() );
            return false;
          } );
        return doc;
      } catch ( e )
      {
        dispatch( serverFailed() );
        return false;

      }
    };

export const deleteCatSeatIntakeDetailData = () => async ( dispatch ) =>
{
  try
  {
    dispatch( catSeatIntakeDetailListByNameFailure() );
  } catch ( e )
  {
    return console.error( e.message );
  }
};
export const getcatSeatIntakeDetailById = () => async ( dispatch ) =>
{
  try
  {
    const name = localStorage.getItem( "token" );
    const token = decodeData( name );
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    let doc = await axios
      .get( URL + "/1", { headers } )
      .then( ( response ) =>
      {
        const { error } = response.data;
        if ( error == true )
        {
          dispatch( tokenExp() );
        }
        console.log(
          "Success With id getcatSeatIntakeDetailById ========>",
          response
        );
      } )
      .catch( ( error ) =>
      {
        dispatch( serverFailed() );
        return false;

      } );
    return doc;
  } catch ( e )
  {
    dispatch( serverFailed() );
    return false;
  }
};

export const saveCatSeatIntakeDetail =
  ( { catSeatIntakeDetailData } ) =>
    async ( dispatch ) =>
    {
      try
      {
        let data = JSON.stringify( catSeatIntakeDetailData );
        const name = localStorage.getItem( "token" );
        const token = decodeData( name );
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
          shouldrefresh: "true",
        };
        let doc = await axios
          .post( URL, data, { headers } )
          .then( ( response ) =>
          {
            const { error } = response.data;
            if ( error == true )
            {
              dispatch( tokenExp() );
            }
            console.log(
              "Success With savecatSeatIntakeDetail ========>",
              response.data
            );
            const { data } = response.data;
            dispatch( reservListDetailsSuccess( { data } ) );
            return data;
          } )
          .catch( ( error ) =>
          {
            dispatch( serverFailed() );
            return false;
            console.log(
              "Error savecatSeatIntakeDetail ========>",
              error.response.data
            );
          } );
        return doc;
      } catch ( e )
      {
        dispatch( serverFailed() );
        return false;
      }
    };

export const deleteCatSeatIntakeDetailById =
  ( { catSeatIntakeDetailId } ) =>
    async ( dispatch ) =>
    {
      try
      {
        const name = localStorage.getItem( "token" );
        const token = decodeData( name );
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
          shouldrefresh: "true",
        };
        let response = await axios
          .delete( URL + "/" + catSeatIntakeDetailId, { headers } )
          .then( ( response ) =>
          {
            console.log(
              "Success With deletecatSeatIntakeDetailById ========>",
              response
            );

            dispatch( catSeatDeleteSuccess() );
            return true;
          } )
          .catch( ( error ) =>
          {
            if ( error.response )
            {
              console.log( "error.response.status" );
              console.log( error.response.status );
              console.log( "error.response.data" );
              console.log( error.response.data );
            }
            dispatch( catSeatDeleteFail() );
            return error.response.data;
          } );
        return response;
      } catch ( e )
      {
        dispatch( serverFailed() );
        return false;
      }
    };

export const categorySeatIntakeDetailListReserveList =
  ( academicYearId, value ) => async ( dispatch ) =>
  {
    try
    {
      var URL1 = endpoints.reservCategory;
      const name = localStorage.getItem( "token" );
      const token = decodeData( name );
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      await axios
        .get(
          URL1 +
          "/" +
          academicYearId +
          "/" +
          "class-intake-detail" +
          "/" +
          value,
          { headers }
        )
        /* .get(URL1 + "/" +  "2cfddab7-7add-4bd9-9c5b-38fb0e5c227f" + "/"
        + "class-intake-detail" + "/" + "023d2e37-9043-46c8-9b9a-1f120e6d912a", { headers }) */
        .then( ( response ) =>
        {
          console.log(
            "Success categorySeatIntakeDetailListReserveList ========>",
            response
          );
          let data = response.data.data;

          const reservData = data?.categorySeatIntakeDetailList;
          const rows = reservData.map( ( data1 ) =>
          {

            data1 = {
              ...data1,
              total: data1.genderAll + data1.genderF + data1.genderM + data1.genderT,
            };
            return data1
          } );
          dispatch( reservListSuccess( { reservData: rows } ) );
          return rows;
        } )
        .catch( ( error ) =>
        {
          console.log(
            "Error categorySeatIntakeDetailListReserveList========>",
            error
          );
          dispatch( serverFailed() );
          return false;
        } );
    } catch ( e )
    {
      dispatch( serverFailed() );
      return false;
    }
  };
export const resetReserv = () => async ( dispatch ) =>
{
  try
  {
    dispatch( reservListFailure() );
  } catch ( e )
  {
    return console.error( e.message );
  }
};
export const setReserv =
  ( { reservData } ) =>
    async ( dispatch ) =>
    {
      try
      {
        const rows = reservData.map( ( data1 ) =>
        {

          data1 = {
            ...data1,
            total: data1.genderAll + data1.genderF + data1.genderM + data1.genderT,
          };
          return data1
        } );
        dispatch( reservListSuccess( { reservData: rows } ) );
      } catch ( e )
      {
        return console.error( e.message );
      }
    };

export const filterCatSeatIntakeData =
  ( { row, flag } ) =>
    async ( dispatch ) =>
    {
      try
      {
        if ( flag == true )
        {
          dispatch( catSeatIntakeFilterSuccess( { row } ) );
        } else
        {
          dispatch( catSeatIntakeDetailSuccess( { row } ) );
        }
      } catch ( e )
      {
        return console.error( e.message );
      }
    };
export const resetDelete = () => async ( dispatch ) =>
{
  try
  {
    dispatch( resetDeleteSuccess() );
  } catch ( e )
  {
    return console.error( e.message );
  }
};
