import { Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import Alert from "../../Alert/Alert";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../components/Comman/Label";
import * as myConstClass from "../../config/messageconstant";
import DynamicTable from "../../components/Comman/MaterialUIRTTable";
import {
  resetDelete,
  saveQualificationByTrust,
} from "../StudentDirectAdmission/studentDirectAdmission.slice";
import { logOut } from "../Login/login.slice";
import InstitudeTable from "./DirectAdmissionEditAppliedStudentTable";

const columns = [
  {
    title: "qualification",
    name: "qualification",
    positionCenter: false,
    alignCenter: "left",
    showInscreen: true,
  },
  {
    title: "Country",
    name: "country",
    positionCenter: true,
    alignCenter: "left",
    showInscreen: true,
  },
  {
    title: "Board",
    name: "board",
    positionCenter: true,
    alignCenter: "left",
    showInscreen: true,
  },
  {
    title: "University",
    name: "university",
    positionCenter: true,
    alignCenter: "left",
    showInscreen: true,
  },
  {
    title: "School / Collage",
    name: "school",
    positionCenter: true,
    alignCenter: "left",
    showInscreen: true,
  },
  {
    title: "Exam Seat Number",
    name: "examSeat",
    positionCenter: true,
    alignCenter: "left",
    showInscreen: true,
  },
  {
    title: "Certificate",
    name: "certificate",
    positionCenter: true,
    alignCenter: "center",
    showInscreen: true,
  },
  {
    title: "Passing Year",
    name: "passingYear",
    positionCenter: true,
    alignCenter: "center",
    showInscreen: true,
  },
  {
    title: "Marks Obtain",
    name: "marksObtain",
    positionCenter: true,
    alignCenter: "right",
    showInscreen: true,
  },
  {
    title: "Marks Out Of",
    name: "marksOutOf",
    positionCenter: true,
    alignCenter: "right",
    showInscreen: true,
  },

  {
    title: "Percentage",
    name: "percentage",
    positionCenter: true,
    alignCenter: "right",
    showInscreen: true,
  },

  {
    title: "Action",
    name: "action",
    positionCenter: true,
    alignCenter: "center",
    showInscreen: true,
  },
];

const QualificationDetails = ({
  elegibilityData,
  loginUser,
  resetDelete,
  saveQualificationByTrust,
  logOut,
  applicationList,
  application,
  getQualiDetails,
  backToList,
}) => {
  const [row, setRow] = useState([]);

  const toDate = applicationList?.applicationData?.startDate.split("-");
  const appToDate =
    toDate === "" ? "" : new Date(toDate[2], toDate[1] - 1, toDate[0]);
  const fromDate = applicationList?.applicationData?.lastDate.split("-");
  const appFromDate =
    fromDate === "" ? "" : new Date(fromDate[2], fromDate[1] - 1, fromDate[0]);
  const [appdet, setAppDet] = useState(
    applicationList?.applicationData !== ""
      ? applicationList?.applicationData?.title
      : ""
  );
  const [applicant, setApplicant] = useState(application.userName);
  const [appdate, setAppDate] = useState(
    applicationList?.applicationData !== "" ? appToDate : ""
  );
  const [apptodate, setAppToDate] = useState(
    applicationList?.applicationData !== "" ? appFromDate : ""
  );
  const navigate = useNavigate();

  useEffect(() => {
    console.log(application);
    setRow([]);
  }, []);

  useEffect(() => {
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
  });

  const QualiDetails = (data) => {
    console.log(applicationList.applicationData.id);
    let QualiData = [];
    console.log(data);
    data.map((data1) => {
      let bData = {
        userApplicationId: application.id,
        university: data1.university,
        yearOfPass: data1.year,
        marksObtained: data1.marksObt,
        marksOutof: data1.marksOutOff,
        percentage: data1.percentage,
        isActive: 1,
        delStatus: 1,
        qualificationId: data1.qualificationId,
        examSeatNo: data1.examSeatNo,
        certificateNo: data1.certificateNo,
        nameOfSchoolCollege: data1.school,
      };
      if (elegibilityData.length !== 0) {
        bData = {
          ...bData,
          id: data1.id,
        };
      }
      QualiData.push(bData);
    });
    console.log(data);
    saveQualificationByTrust({ appliData: QualiData }).then(() => {
      backToList();
    });
  };
  console.log(applicationList);
  return (
    <>
      <div>
        <br />
        {applicationList.isServerFail && (
          <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />
        )}
        <Grid>
          <div>
            <Box sx={{ mt: 1 }}>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                spacing={2}
                columnSpacing={1}
                container
                sx={{ m: 0.5 }}
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  {/* <Grid item xs={12} sm={12} md={0.5}></Grid> */}
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 20 }}
                      label="Application Form"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                spacing={2}
                columnSpacing={1}
                container
                sx={{ m: 0.5 }}
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  {/* <Grid item xs={12} sm={12} md={0.5}></Grid> */}
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Application Details :"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="appdet"
                      size="small"
                      value={appdet}
                      onChange={(e) => {
                        setAppDet(e.target.value);
                      }}
                      fullWidth
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                spacing={2}
                columnSpacing={1}
                container
                sx={{ m: 0.5 }}
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  {/* <Grid item xs={12} sm={12} md={0.5}></Grid> */}
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Applicant Details :"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="applicant"
                      size="small"
                      value={applicant}
                      fullWidth
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                spacing={2}
                columnSpacing={1}
                container
                sx={{ m: 0.5 }}
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  {/* <Grid item xs={12} sm={12} md={0.5}></Grid> */}
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Application Date :"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <BasicDatePicker
                      name="displayFrom"
                      value={appdate}
                      onChange={(date) => setAppDate(date)}
                      selected={appdate}
                      disabled={true}
                      error={" "}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <BasicDatePicker
                      name="displayTo"
                      value={apptodate}
                      onChange={(date) => setAppToDate(date)}
                      disabled={true}
                      error={" "}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                spacing={2}
                columnSpacing={1}
                container
                sx={{ m: 0.5 }}
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  {/* <Grid item xs={12} sm={12} md={0.5}></Grid> */}
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 20 }}
                      label="Qualification Details"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Box sx={{ mt: 3 }}>
                <InstitudeTable
                  appdet={appdet}
                  apptodate={apptodate}
                  appdate={appdate}
                  data={QualiDetails}
                  Rows={applicationList?.documentData.filter(
                    (row) => row.type === 0
                  )}
                  onCancle={backToList}
                />
              </Box>
            </Box>
          </div>
        </Grid>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  common: state.common,
  applicationList: state.directAdmission,
  loginUser: state.user,
});

const mapDispatchToProps = {
  logOut,
  resetDelete,
  saveQualificationByTrust,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QualificationDetails);
