import { Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";

let docs;

class ChallanForm extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    id: 0,
    formErrors: [],
    flag: false,
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  componentDidMount() {
    this.setState({});
  }

  handleFormValidation(name) {
    const {} = this.state;
    let formErrors = {};
    let formIsValid = true;
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const { onSave } = this.props;
    onSave();
  };

  saveHandler = (e) => {
    e.preventDefault();
    const { id } = this.state;
    docs = {
      id: id === 0 ? 0 : id,
    };

    if (this.handleFormValidation()) {
      this.props.saveAndNext(docs);
      this.setState({
        id: 0,
      });
    }
  };

  render() {
    const { id } = this.state;
    const {} = this.state.formErrors;
    const { onCancel } = this.props;

    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              spacing={2}
              columnSpacing={1}
              container
              sx={{ m: 0.5 }}
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                {/* <Grid item xs={12} sm={12} md={0.5}></Grid> */}
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 20 }}
                    label="Challan Form"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={12} />
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={1}
              // columnSpacing={{xs:12, sm:6, md:1}}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{
                    fontSize: 18,
                    color: "#fff",
                    fontFamily: "Poppins",
                  }}
                  label="Instructions:"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Box display="flex" justifyContent="space-between ">
                  <Box>
                    <Card
                      variant="outlined"
                      sx={{
                        color: "#ed1c24",
                        fontFamily: "poppins",
                        mt: 1,
                        p: 1,
                      }}
                    >
                      <CardContent style={{ lineHeight: "34px" }}>
                        <Grid>
                          <Typography variant="span">a)</Typography>
                          &emsp;
                          <Typography variant="span">
                            The Photo should be Colorful. Black and White Photo
                            should not be Accepted.
                          </Typography>
                        </Grid>

                        <Grid>
                          <Typography variant="span">b)</Typography>
                          &emsp;
                          <Typography variant="span">
                            Size of the Photograph should be in between 20KB to
                            100KB.
                          </Typography>
                        </Grid>

                        <Grid>
                          <Typography variant="span">c)</Typography>
                          &emsp;
                          <Typography variant="span">
                            The photograph and Signature Should be Scanned.
                          </Typography>
                        </Grid>

                        <Grid>
                          <Typography variant="span">d)</Typography>
                          &emsp;
                          <Typography variant="span">
                            The Photograph Dimension should be of 4.5 cm * 3.5
                            cm
                          </Typography>
                        </Grid>

                        <Grid>
                          <Typography variant="span">e)</Typography>
                          &emsp;
                          <Typography variant="span">
                            Photo Should be without CAP, Googles and both ears
                            should be visible.
                          </Typography>
                        </Grid>

                        <Grid>
                          <Typography variant="span">f)</Typography>
                          &emsp;
                          <Typography variant="span">
                            The Signature should be of black and blue ink.
                          </Typography>
                        </Grid>

                        <Grid>
                          <Typography variant="span">g)</Typography>
                          &emsp;
                          <Typography variant="span">
                            The Signature should be in the box only. Outside of
                            the box or on the box will not be accepted.
                          </Typography>
                        </Grid>
                        <Grid>
                          <Typography variant="span">h)</Typography>
                          &emsp;
                          <Typography variant="span">
                            The Signature should be on blank page.
                          </Typography>
                        </Grid>

                        <Grid>
                          <Typography variant="span">i)</Typography>
                          &emsp;
                          <Typography variant="span">
                            Size of the Signature Should be in between 20 KB to
                            50 KB.
                          </Typography>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                </Box>
              </Grid>
              <br />
            </Grid>
            <Grid item xs={12} sm={6} md={12} />

            <Grid item container justifyContent="right">
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Download"
              />
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}
export default ChallanForm;
