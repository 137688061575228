import { Grid, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import AttachFile from "../../components/Comman/AttachFile";
import { ButtonCompo } from "../../components/Comman/Buttton";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../components/Comman/Label";
import * as myConstClass from "../../config/messageconstant";
import DammyLogo from "../../Images/DammyLogo.png";
import { downloadFile, savePreLoginFile } from "../UploadFile/file.slice";

let docs;
class NewsDocNotificationMasterForm extends React.Component
{
  state = {
    id: 0,
    description: "",
    trustId: "",
    logoName: "",
    type: "",
    showLabel: "0",
    labelName: "",
    orderNo: "",
    title: "",
    showOnScreen: "1",
    fileName: "",
    fromDate: new Date(),
    isActive: "1",
    delStatus: "1",
    formErrors: [],
    breadCrum: "",
    documentName: "",
    flag: 0,
  };

  handleChange = ( event ) =>
  {
    const fileUploaded = event.target.files[ 0 ];
    const { savePreLoginFile } = this.props;
    savePreLoginFile( { file: fileUploaded } );
    let reader = new FileReader();
    reader.onload = ( e ) =>
    {
      this.setState( { documentName: e.target.result } );
    };
    this.setState( { logoName: fileUploaded } );
    reader.readAsDataURL( event.target.files[ 0 ] );
  };

  handleClick = ( event ) =>
  {
    this._name.click();
  };

  componentDidUpdate()
  {
    const { fileData, isEdit } = this.props;

    const {
      id,
      description,
      type,
      showLabel,
      labelName,
      orderNo,
      title,
      trustId,
      fromDate,
      showOnScreen,
      isActive,
      flag,
    } = this.state;
    let dateOF = moment( fromDate ).format( "DD-MM-YYYY" );

    docs = {
      description: description,
      showLable: showLabel,
      lableName: labelName,
      trust: {
        id: trustId,
      },
      type: type,
      showOnScreen: showOnScreen,
      orderNo: orderNo,
      title: title,
      date: dateOF,
      isActive: isActive,
      delStatus: "1",
    };

    if ( id !== 0 )
    {
      docs = {
        ...docs,
        id: id,
      };
    }
    if ( flag === 1 )
    {
      if (
        this.state.documentName !== "" &&
        isEdit.length !== 0 &&
        this.state.documentName === isEdit[ 0 ].documentName
      )
      {
        docs = {
          ...docs,
          documentName: isEdit[ 0 ].documentName,
        };
        this.props.saveAndNext( docs );
      } else
      {
        if ( fileData.isFetch )
        {
          docs = {
            ...docs,
            documentName: fileData.file,
          };
          this.props.saveAndNext( docs );
        }
      }
      this.setState( {
        id: 0,
        description: "",
        documentName: "",
        type: "",
        showLabel: "0",
        labelName: "",
        orderNo: "",
        title: "",
        fromDate: "",
        isActive: 1,
        formErrors: [],
        flag: 0,
      } );
    } else if ( flag === 2 )
    {
      if (
        this.state.documentName !== "" &&
        isEdit.length !== 0 &&
        this.state.documentName === isEdit[ 0 ].documentName
      )
      {
        docs = {
          ...docs,
          documentName: isEdit[ 0 ].documentName,
        };
        this.props.OnSave( docs );
      } else
      {
        if ( fileData.isFetch )
        {
          docs = {
            ...docs,
            documentName: fileData.file,
          };
          this.props.OnSave( docs );
        }
      }
    }
  }

  changeH = ( fileName, name, index ) =>
  {
    console.log( fileName.name );
    this.setState( {
      ...this.state,
      documentName: fileName,
      fileName: fileName.name,
    } );
  };

  changeHandler = ( event ) =>
  {
    const { name, value } = event.target;
    this.setState( {
      ...this.state,
      [ name ]: value,
    } );
  };
  changeFromDate = ( date ) =>
  {
    this.setState( {
      fromDate: date,
    } );
  };
  componentDidMount()
  {
    const { isEdit, breadCrum } = this.props;
    let trustId = localStorage.getItem( "trustId" );
    this.setState( {
      trustId: trustId,
    } );

    breadCrum( ">  Add" );
    if ( isEdit.length > 0 )
    {
      const { fileData } = this.props;
      console.log( isEdit );
      this.setState( {
        id: isEdit[ 0 ].id,
        description: isEdit[ 0 ].description,
        type:
          isEdit[ 0 ].type == "Online Admission"
            ? 0
            : isEdit[ 0 ].type == "Latest News"
              ? 1
              : isEdit[ 0 ].type == "Notification"
                ? 2
                : "",
        showLabel: isEdit[ 0 ].showLabel === "Yes" ? "1" : "0",
        labelName: isEdit[ 0 ].labelName,
        orderNo: isEdit[ 0 ].orderNo,
        title: isEdit[ 0 ].title,
        date: isEdit[ 0 ].date,
        isActive: isEdit[ 0 ].isActive === "Active" ? 1 : 0,
        documentName: fileData.filePath,
      } );
    }
  }

  handleFormValidation( name )
  {
    const {
      description,
      orderNo,
      type,
      labelName,
      showLabel,
      title,
      fromDate,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if ( description.trim() === "" )
    {
      formIsValid = false;
      formErrors[ "descriptionErr" ] = myConstClass.descriptionMsg;
    }
    if ( title === "" )
    {
      formIsValid = false;
      formErrors[ "titleErr" ] = myConstClass.titleMsg;
    }
    if ( fromDate === "" )
    {
      formIsValid = false;
      formErrors[ "fromDateErr" ] = myConstClass.dateMsg;
    }
    if ( type === "" )
    {
      formIsValid = false;
      formErrors[ "typeErr" ] = myConstClass.typeMsg;
    }
    if ( labelName === "" && showLabel == "1" )
    {
      formIsValid = false;
      formErrors[ "labelNameErr" ] = myConstClass.labelNameMsg;
    }

    if ( orderNo === "" )
    {
      formIsValid = false;
      formErrors[ "orderNoErr" ] = myConstClass.ordNoMsg;
    }

    this.setState( { formErrors: formErrors } );
    return formIsValid;
  }
  saveHandler = ( e ) =>
  {
    e.preventDefault();
    this.setState( {
      flag: 1,
    } );
    const { savePreLoginFile, fileData } = this.props;

    const {
      id,
      description,
      documentName,
      type,
      showLabel,
      labelName,
      orderNo,
      title,
      fromDate,
      trustId,
      showOnScreen,
      isActive,
    } = this.state;
    let dateOF = moment( fromDate ).format( "DD-MM-YYYY" );

    docs = {
      description: description,
      showLable: showLabel,
      lableName: labelName,
      trust: {
        id: trustId,
      },
      type: type,
      showOnScreen: showOnScreen,
      documentName: documentName,
      orderNo: orderNo,
      title: title,
      date: dateOF,
      isActive: isActive,
      delStatus: "1",
    };

    if ( id !== 0 )
    {
      docs = {
        ...docs,
        id: id,
      };
    }

    if ( documentName !== "" && documentName !== fileData.filePath )
    {
      savePreLoginFile( { file: documentName } );
    } else if ( this.handleFormValidation() )
    {
      this.props.saveAndNext( docs );
      this.setState( {
        id: 0,
        description: "",
        documentName: "",
        type: "",
        showLabel: "0",
        labelName: "",
        orderNo: "",
        title: "",
        fromDate: "",
        isActive: 1,
        formErrors: [],
        flag: 0,
      } );
    }
  };
  submitHandler = ( e ) =>
  {
    e.preventDefault();
    this.setState( {
      flag: 2,
    } );
    const { savePreLoginFile, fileData } = this.props;

    const {
      id,
      description,
      documentName,
      type,
      showLabel,
      labelName,
      orderNo,
      title,
      fromDate,
      trustId,
      showOnScreen,
      isActive,
    } = this.state;
    let dateOF = moment( fromDate ).format( "DD-MM-YYYY" );

    docs = {
      description: description,
      showLable: showLabel,
      lableName: labelName,
      trust: {
        id: trustId,
      },
      type: type,
      showOnScreen: showOnScreen,
      documentName: documentName,
      orderNo: orderNo,
      title: title,
      date: dateOF,
      isActive: isActive,
      delStatus: "1",
    };

    if ( id !== 0 )
    {
      docs = {
        ...docs,
        id: id,
      };
    }

    if ( documentName !== "" && documentName !== fileData.filePath )
    {
      savePreLoginFile( { file: documentName } );
    } else if ( this.handleFormValidation() )
    {
      this.props.OnSave( docs );
    }
  };
  render()
  {
    const {
      description,
      showLabel,
      labelName,
      orderNo,
      type,
      title,
      fromDate,
      fileName,
    } = this.state;
    const {
      descriptionErr,
      orderNoErr,
      sortOrderNumErr,
      typeErr,
      labelNameErr,
      fromDateErr,
      titleErr,
    } = this.state.formErrors;
    const { onCancel } = this.props;
    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            <Grid
              item
              xs={12}
              md={12}
              size="small"
              container
              justifyContent="flex-end"
            >
              <ButtonCompo
                size="small"
                type="button"
                variant="outlined"
                name=" Back to List"
                onClick={onCancel}
              />
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={1}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="*Title : "
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  size="small"
                  color="primary"
                  name="title"
                  id="title"
                  value={title}
                  onChange={this.changeHandler}
                  fullWidth
                  error={titleErr ? true : false}
                  helperText={titleErr ? titleErr : " "}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={12}></Grid>
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="*Description : "
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  size="small"
                  color="primary"
                  name="description"
                  id="description"
                  value={description}
                  onChange={this.changeHandler}
                  fullWidth
                  error={descriptionErr ? true : false}
                  helperText={descriptionErr ? descriptionErr : " "}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Date : "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <BasicDatePicker
                    color="primary"
                    name="fromDate"
                    size="small"
                    value={fromDate}
                    fullWidth
                    onChange={this.changeFromDate}
                    error={fromDateErr?fromDateErr:' '}
                  />
                  {/* <FormHelperText error>
                    {fromDateErr ? fromDateErr : " "}
                  </FormHelperText> */}
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
              rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Order No. : "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    size="small"
                    color="primary"
                    name="orderNo"
                    id="orderNo"
                    type='number'
                    value={orderNo}
                    onChange={this.changeHandler}
                    fullWidth
                    error={orderNoErr ? true : false}
                    helperText={orderNoErr ? orderNoErr : " "}
                  ></TextField>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Type :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      labelId="type"
                      id="type"
                      className="text-black"
                      name="type"
                      value={type}
                      onChange={this.changeHandler}
                      size="small"
                      fullWidth
                      {...( typeErr && { error: true } )}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {/* <MenuItem value={0}>
                        <em>Online Admission</em>
                      </MenuItem> */}
                      <MenuItem value={1}>
                        <em>Latest News</em>
                      </MenuItem>
                      <MenuItem value={2}>
                        <em>Notification</em>
                      </MenuItem>
                    </Select>

                    <FormHelperText error>
                      {typeErr ? typeErr : " "}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}></Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Select Document :"
                  />
                  <AttachFile
                    name="documentName"
                    fileName={this.changeH}
                  ></AttachFile>
                  &nbsp; {fileName}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}></Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  rowSpacing={1}
                  // columnSpacing={{xs:12, sm:6, md:1}}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Show Label :"
                    />
                  </Grid>

                  <Grid sx={{ mt: 1 }} item xs={12} sm={12} md={12} mt={-2}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="Is Educatoinal"
                        id="showLabel"
                        name="showLabel"
                        value={showLabel}
                        onChange={this.changeHandler}
                        size="small"
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  rowSpacing={1}
                  // columnSpacing={{xs:12, sm:6, md:1}}
                  container
                  justifyContent="space-evenly"
                >
                  {showLabel === "1" ? (
                    <>
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Label Name :"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          size="small"
                          color="primary"
                          name="labelName"
                          id="labelName"
                          value={labelName}
                          onChange={this.changeHandler}
                          fullWidth
                          error={labelNameErr ? true : false}
                          helperText={labelNameErr ? labelNameErr : " "}
                        ></TextField>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 12, color: '#808080' }}
                    label="Note: on click Yes the label will blink on landing portal home page"
                  />
                </Grid>
              </Grid>

            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={1.2}
                lg={1.2}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  ssx={{ m: -1 }}
                  ize="medium"
                  type="submit"
                  variant="contained"
                  name="Save"
                  fullWidth={true}
                />
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={1.8}
                lg={1.8}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Save and Next"
                  onClick={this.saveHandler}
                  fullWidth={true}
                />{" "}
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={1.2}
                lg={1.2}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Cancel"
                  onClick={onCancel}
                  fullWidth={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}

const mapStateToProps = ( state ) => ( {
  fileData: state.file,
} );

const mapDispatchToProps = {
  savePreLoginFile,
  downloadFile,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)( NewsDocNotificationMasterForm );
