import {
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import Alert from "../../Alert/Alert";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import { DirectionSnackbar } from "../../components/Comman/SnakBarCompo";
import endpoints from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import { logOut } from "../Login/login.slice";
import { getAlert } from "../../Alert/alert.slice";
import { downloadF } from "../PDF/PDFDownload";
import {
  deleteScheduleById,
  generateSeatAllotment,
  getscheduleFormList,
  resetDelete,
  saveScheduleForm,
} from "./scheduleform.slice";
import ScheduleTable from "./ScheduleTable";

const ScheduleDetailsForm = ({
  capId,
  onClose,
  getscheduleFormList,
  scheduleFormList,
  saveScheduleForm,
  deleteScheduleById,
  generateSeatAllotment,
  loginUser,
  logOut,
  getAlert,
  resetDelete,
}) => {
  const [trustList, setTrustList] = React.useState([]);

  const [openSnak, setOpenSnak] = useState(false);
  const [snakMsg, setSnakMsg] = useState("");
  const [schedule, setSchedule] = useState("");
  const [waitingListCount, setWaitingListCount] = useState("");
  const [reservationApplicable, setReservationApplicable] = useState("1");
  const [errorMsg, setErrorMsg] = useState(false);
  const [id, setId] = useState(0);
  const navigate = useNavigate();

  const getRowID = async (id) => {
    const editedRow = scheduleFormList?.scheduleFormList.filter(
      (row) => row.id === id
    );
    if (editedRow.length > 0) {
      setWaitingListCount(editedRow[0].waitingListCount);
      setReservationApplicable(editedRow[0].reservationApplicable);
      setSchedule(editedRow[0].name);
      setId(editedRow[0].id);
    }
  };
  useEffect(() => {
    getscheduleFormList({ capId: capId }).then((response) => {
      if (!response) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
  }, []);

  const generateScheduleListPdf = async (id) => {
    console.log(id);
    let userAppReportURL = endpoints.seatAllotmentListPdf;
    downloadF({ url: userAppReportURL + "/" + id });
  };

  const generateWaitingListPdf = async (id) => {
    console.log(id);
    let userAppReportURL = endpoints.waitingListPdf;
    downloadF({ url: userAppReportURL + "/" + id });
  };

  useEffect(() => {
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
  });

  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteScheduleById({ scheduleId: id }).then((response) => {
          if (!response) {
            getAlert({ message: myConstClass.serverMsg });
          } else {
            setOpenSnak(true);
            setSnakMsg(myConstClass.deleteMsg);
            getscheduleFormList({ capId: capId }).then((resp) => {
              if (!resp) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
          }
        });
      }
    });
  };
  const SeatAllot = (id) => {
    swal({
      title: "Seat Allotment List",
      text: "Once Submitted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        console.log(id);
        generateSeatAllotment({ scheduleId: id }).then((resp) => {
          if (resp) {
            setOpenSnak(true);
            setSnakMsg("Seat alloatment successful");
            getscheduleFormList({ capId: capId }).then((response) => {
              if (!response) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
          } else {
            getAlert({ message: myConstClass.serverMsg });
          }
        });
      }
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let data1 = {
      applicationRound: {
        id: capId,
      },
      name: schedule,
      waitingListCount: waitingListCount,
      reservationApplicable: reservationApplicable,
      seatAllotmentStatus: 0,
      isActive: 1,
    };
    if (id !== 0) {
      data1 = {
        ...data1,
        id: id,
      };
    }
    if (schedule !== "" && waitingListCount !== "") {
      setErrorMsg(false);

      saveScheduleForm({ scheduleData: data1 }).then((response) => {
        if (!response) {
          getAlert({ message: myConstClass.serverMsg });
        } else {
          getscheduleFormList({ capId: capId }).then((resp) => {
            if (!resp) {
              getAlert({ message: myConstClass.serverMsg });
            } else {
              setWaitingListCount("");
              setReservationApplicable("");
              setSchedule("");
              setId(0);
            }
          });
        }
      });
    } else {
      setErrorMsg(true);
    }
  };
  return (
    <>
      {/* <br /> */}
      {/* {scheduleFormList.isDelete && scheduleFormList.error == true && (
        <Alert msg={myConstClass.deleteFailMsg} closeAlert={resetDelete} />
      )}
      {scheduleFormList.isServerFail && (
        <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />
      )} */}

      {scheduleFormList.isDelete && scheduleFormList.error == false ? (
        <DirectionSnackbar
          open={openSnak}
          severity="success"
          msg={myConstClass.deleteMsg}
          closeSnak={setOpenSnak}
        />
      ) : scheduleFormList.isSave ? (
        <DirectionSnackbar
          open={openSnak}
          severity="success"
          msg={snakMsg}
          closeSnak={setOpenSnak}
        />
      ) : (
        ""
      )}

      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        {/* <Grid
          container
          justifyContent="space-evenly"
          direction="row"
          spacing={2}
        > */}

        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={2}
          spacing={2}
          columnSpacing={1}
          container
          sx={{ m: 0.5 }}
          justifyContent="space-evenly"
        >
          <Grid item xs={12} sm={6} md={11}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 22 }}
              label="Schedule Details "
            />
          </Grid>
          <Grid item xs={12} sm={6} md={1}>
            <ButtonCompo
              size="medium"
              type="button"
              variant="outlined"
              name="Back"
              onClick={onClose}
            />
          </Grid>
        </Grid>

        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={2}
          spacing={1}
          columnSpacing={1}
          container
          justifyContent="space-evenly"
        >
          <Grid
            item
            sm={12}
            xs={12}
            md={4}
            lg={4}
            rowSpacing={1}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Schedule Name "
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <TextField
                size="small"
                color="primary"
                name="schedule"
                id="schedule"
                value={schedule}
                autoComplete="off"
                onChange={(e) => {
                  setSchedule(e.target.value);
                }}
                fullWidth
              ></TextField>
              {errorMsg && (
                <FormHelperText error>
                  {"*Please enter schedule name"}{" "}
                </FormHelperText>
              )}
            </Grid>
          </Grid>

          <Grid
            item
            sm={12}
            xs={12}
            md={4}
            lg={4}
            rowSpacing={1}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Waiting List Count "
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <TextField
                size="small"
                color="primary"
                name="waitingListCount"
                id="waitingListCount"
                value={waitingListCount}
                autoComplete="off"
                onChange={(e) => {
                  setWaitingListCount(e.target.value);
                }}
                fullWidth
              ></TextField>
              {errorMsg && (
                <FormHelperText error>
                  {"*Please enter waiting list count"}{" "}
                </FormHelperText>
              )}
            </Grid>
          </Grid>

          <Grid
            item
            sm={12}
            xs={12}
            md={4}
            lg={4}
            rowSpacing={1}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Reservation Applicable "
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="Is Active"
                  id="reservationApplicable"
                  name="reservationApplicable"
                  value={reservationApplicable}
                  onChange={(e) => {
                    setReservationApplicable(e.target.value);
                  }}
                  size="small"
                >
                  <FormControlLabel value="0" control={<Radio />} label="No" />
                  <FormControlLabel value="1" control={<Radio />} label="Yes" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container justifyContent="flex-end">
          <ButtonCompo
            size="medium"
            type="button"
            variant="contained"
            name="Add"
            onClick={submitHandler}
          />
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={1}
          // columnSpacing={{xs:12, sm:6, md:1}}
          container
          justifyContent="space-evenly"
        >
          <Grid item xs={12} sm={12} md={12}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 12, color: "#808080" }}
              label="Note :"
            />
            <br />
            <LabelCompo
              className="text-black"
              style={{ fontSize: 12, color: "#808080" }}
              label=" Your seat allotment process against schedule"
            />
            <br />
            <LabelCompo
              className="text-black"
              style={{ fontSize: 12, color: "#808080" }}
              label="Schedule facility is given to run seat allotment process without change in preferences in respective cap round."
            />
            <br />
            <LabelCompo
              className="text-black"
              style={{ fontSize: 12, color: "#808080" }}
              label=" Waiting list count : waiting list count will defined no of students to be taking in waiting list."
            />
            <br />
            <LabelCompo
              className="text-black"
              style={{ fontSize: 12, color: "#808080" }}
              label="Reservation applicable : If you select no then reservation criteria will not be considered and seats will be alloted on priority merit basis"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={6} />

        <Box sx={{ mt: 1, mb: 5 }}>
          <Grid item xs={2} md={12} container justifyContent="flex-end" />
          <ScheduleTable
            tableHead={[
              {
                title: "Sr. No.",
                name: "index",
                positionCenter: true,
                alignCenter: "center",
                showInscreen: true,
              },
              {
                title: "Schedule",
                name: "name",
                positionCenter: false,
                alignCenter: "left",
                showInscreen: true,
              },
              {
                title: "Action",
                name: "action",
                positionCenter: false,
                alignCenter: "left",
                showInscreen: true,
              },
            ]}
            data={scheduleFormList?.scheduleFormList}
            rowEdit={getRowID}
            rowDelete={deleteData}
            rowSeatAllot={SeatAllot}
            rowSchedulePdf={generateScheduleListPdf}
            rowWaitingListPdf={generateWaitingListPdf}
          />
        </Box>
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({
  scheduleFormList: state.scheduleFormList,
  loginUser: state.user,
  // common: state.common,
});

const mapDispatchToProps = {
  getscheduleFormList,
  saveScheduleForm,
  deleteScheduleById,
  generateSeatAllotment,
  logOut,
  resetDelete,
  downloadF,
  getAlert,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleDetailsForm);
