import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { decodeData } from "../../components/Comman/Util/Base64";
import endpoints from "../../config/endpoints";
import { tokenExp } from "../Login/login.slice";
const initialState = {
  program: [],
  application: [],
  course: [],
  programData: [],
  programDetails: {},
  isFetch: false,
  isProgFetch: false,
  isFetchCourse: false,
  isAppliFetch: false,
  programFilter: [],
  isFilter: false,
  isSave: false,
  isDelete: false,
  error: false,
  isServerFail: false,
  programTitleData: [],
  isFetchProgramTitle: false,
};
let URL = endpoints.program;
const programSlice = createSlice({
  name: "program",
  initialState,
  reducers: {
    programSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        program: row,
        isProgFetch: true,
      };
    },
    resetDeleteSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error: false,
        isDelete: false,
        isSave: false,
        isServerFail: false,
      };
    },
    saveProgramSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        programDetails: data,
        error: false,
        isDelete: false,
        isSave: true,
      };
    },
    programTypeDeleteFail: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error: true,
        isDelete: true,
        isSave: false,
      };
    },
    programTypeDeleteSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error: false,
        isDelete: true,
        isSave: false,
      };
    },
    serverFailed: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: true,
        error: false,
        isDelete: false,
      };
    },
    programServerFailedReset: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: false,
        error: false,
        isDelete: false,
      };
    },
    applicationSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        application: row,
        isAppliFetch: true,
        isDelete: false,
        error: false,
      };
    },
    programDetailsSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        programDetails: data,
        isSave: true,
        error: false,
        isDelete: false,
      };
    },
    programDetailsFail: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        programDetails: {},
        isSave: false,
        error: false,
        isDelete: false,
      };
    },
    applicationFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        application: [],
        isAppliFetch: false,
        error: false,
        isDelete: false,
      };
    },

    programFilterSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        programFilter: row,
        isFilter: true,
      };
    },
    programFilterFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        programFilter: [],
        isFilter: false,
      };
    },

    getCourseByProgIdSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        course: data,
        isFetchCourse: true,
      };
    },
    branchFetched: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isFetchCourse: false,
      };
    },
    programFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        program: [],
        isProgFetch: false,
      };
    },
    programListByNameSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        programData: data,
        isFetch: true,
      };
    },

    getprogramTitleSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        programTitleData: row,
        isFetchProgramTitle: true,
      };
    },

    programListByNameFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        programData: [],
        isFetch: false,
      };
    },
  },
});

export const {
  saveProgramSuccess,
  programDetailsFail,
  programServerFailedReset,
  resetDeleteSuccess,
  programFilterSuccess,
  branchFetched,
  applicationSuccess,
  programTypeDeleteSuccess,
  serverFailed,
  programTypeDeleteFail,
  programSuccess,
  getCourseByProgIdSuccess,
  programFailure,
  progFetched,
  programListByNameSuccess,
  programListByNameFailure,
  getprogramTitleSuccess,
} = programSlice.actions;

export default programSlice.reducer;

export const getProgram = () => async (dispatch) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };

    const doc = await axios
      .get(URL + '?sort={"insertdatetime": "DESC"}', { headers })
      .then((response) => {
        console.log("Success get program  ========>", response.data.data);

        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          let data = response.data.data.content;
          let row = [];
          let index = 0;

          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              progName: data1.program.name,
              uniName: data1.university.name,
              progDuration: data1.duration,
              feBrName: data1.feBrName,
              name: data1.program.name+" - "+data1.name,
              programType: data1.name,
              progTypeId: data1.program.id,
              uniId: data1.university.id,
              isActive: data1.isActive === 1 ? "Active" : "Inactive",
            };
            row.push(bData);
            return data1;
          });
          dispatch(programSuccess({ row }));
          return row;
        }
      })
      .catch((error) => {
        dispatch(serverFailed());
        return false;
      });
    return doc;
  } catch (e) {
    dispatch(serverFailed());
    return false;
  }
};

export const getProgramTitleById =
  ({ programTypeId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .get(
          URL +
            "/" +
            programTypeId +
            "/program-title" +
            '?filters={"isActive": "Active","_mode" : "EXACT"}',
          { headers }
        )
        .then((response) => {
          console.log(
            "Success With id getProgramTitleById ========>",
            response
          );
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            const { data } = response.data;
            let index = 0;
            let row = [];

            data.map((data1) => {
              index = index + 1;
              let bData = {
                index: index,
                id: data1.id,
                name: data1.brName + " ( " + data1.brShortName + " ) ",
                dsAdmission: data1.dsAdmission === 1 ? "Yes" : "No",
                isActive: data1.isActive === 1 ? "Active" : "Inactive",
                progName: data1.programType.name,
                progNameId: data1.programType.id,
                progTypeId: data1.programType.id,
                branchShortName: data1.brShortName,
                branchName: data1.brName,
              };
              row.push(bData);
            });
            dispatch(getprogramTitleSuccess({ row }));
            return row;
          }
        })
        .catch((error) => {
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      return false;
    }
  };

export const deleteBranchList = () => async (dispatch) => {
  try {
    dispatch(branchFetched());
  } catch (e) {
    return console.error(e.message);
  }
};
export const getProgramByName =
  ({ programName }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .get(
          URL +
            '?filters={"delStatus": "1","_mode" : "EXACT"},{"brName":"' +
            programName +
            '","_mode" : "EXACT"}',
          { headers }
        )
        .then((response) => {
          console.log(
            "Success getprogram  ========>",
            response.data.data.content
          );
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            let data = response.data.data.content;

            dispatch(programListByNameSuccess({ data }));
            return data;
          }
        })
        .catch((error) => {
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const deleteProgList = () => async (dispatch) => {
  try {
    dispatch(progFetched());
  } catch (e) {
    return console.error(e.message);
  }
};
export const deleteProgramData = () => async (dispatch) => {
  try {
    dispatch(programListByNameFailure());
  } catch (e) {
    return console.error(e.message);
  }
};
export const saveProgram =
  ({ programData }) =>
  async (dispatch) => {
    try {
      let data = JSON.stringify(programData);

      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .post(URL, data, { headers })
        .then((response) => {
          console.log("Success with save program ========>", response);
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            const { data } = response.data;
            dispatch(saveProgramSuccess({ data }));
            return data;
          }
        })
        .catch((error) => {
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };
export const resetSave = () => async (dispatch) => {
  try {
    dispatch(programDetailsFail());
  } catch (e) {
    return console.error(e.message);
  }
};
export const resetDelete = () => async (dispatch) => {
  try {
    dispatch(resetDeleteSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
// export const resetSave = () => async (dispatch) => {
//   try {
//     dispatch(programTypeDeleteFail());
//   } catch (e) {
//     return console.error(e.message);
//   }
// };
export const deleteProgramById =
  ({ programId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      let response = await axios
        .delete(URL + "/" + programId, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
          console.log("Success with delete program by id ========>", response);
          dispatch(programTypeDeleteSuccess());
          return true
          }
        })
        .catch((error) => {
          console.log(
            "Error delete  program by id========>",
            error.response.data
          );
          
          dispatch(programTypeDeleteFail());
          return false;
        });
      return response;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const getProgramById =
  ({ programId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .get(URL + "/" + programId, { headers })
        .then((response) => {
          console.log(
            "Success program getprogram========>",
            response.data.data
          );
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            let data = response.data.data;
            dispatch(programSuccess({ data }));
          }
        })
        .catch((error) => {
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      programFailure();
      return false;
    }
  };
export const getBranchByProgId =
  ({ programId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .get(URL + "/" + programId + "/program-title", { headers })
        .then((response) => {
          console.log(
            "Success program getprogram========>",
            response.data.data
          );
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            let data = response.data.data;
            dispatch(getCourseByProgIdSuccess({ data }));
          }
        })
        .catch((error) => {
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      programFailure();
      return false;
    }
  };
export const getApplicationsByPrgTypeId =
  ({ intrestedIn }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .get(
          URL +
            "/" +
            intrestedIn +
            "/application-declare" +
            '?filters={"isActive":"1","_mode" : "EXACT"},{"isFreez":"1","_mode" : "EXACT"}&sort={"insertdatetime": "DESC"}',
          { headers }
        )

        .then((response) => {
          console.log("Success With saveStudent ========>", response);
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            const { data } = response.data;
            let row = [];
            let index = 0;

            data.map((data1) => {
              index = index + 1;
              let bData = {
                index: index,
                id: data1.id,
                applicationName: data1.title,
                progTypeId: data1.programTypeId.id,
                intrestedPrograms:
                  data1.program.name + " - " + data1.programTypeId.name,
                lastDate: data1.lastDate,
                shortSummary: data1.shortSummary,
                labelName: data1.lableText,
                startDate: data1.startDate,
                showLabel: data1.highlightLable,
                detailedDescription: data1.detailedDescription,
                isActive: data1.isActive === 1 ? "Active" : "Inactive",
              };
              row.push(bData);
              return data1;
            });
            dispatch(applicationSuccess({ row }));
            return row;
          }
        })
        .catch((error) => {
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const filterProgramData =
  ({ row, flag }) =>
  async (dispatch) => {
    try {
      if (flag === true) {
        dispatch(programFilterSuccess({ row }));
      } else {
        dispatch(programSuccess({ row }));
      }
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetProgramServerFailed = () => async (dispatch) => {
  try {
    dispatch(programServerFailedReset());
  } catch (e) {
    return console.error(e.message);
  }
};
