import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid, LinearProgress, Paper } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import {
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "../../components/Comman/IconButton";
import { useNavigate } from "react-router";
import Alert from "../../Alert/Alert";
import { logOut } from "../Login/login.slice";
import { DirectionSnackbar } from "../../components/Comman/SnakBarCompo";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
import { Loading1 } from "../../components/Loading1";
import Search from "../../components/Search";
import { getAlert } from "../../Alert/alert.slice";

import * as myConstClass from "../../config/messageconstant";
import {
  deleteProgramTypeById,
  getProgramType,
  getProgramTypeById,
  saveProgramType,
  resetDelete,
} from "./programType.slice";
import ProgramTypeExcelData from "./ProgramTypeExcelData";
import ProgramTypeForm from "./ProgramTypeForm";
import ViewInst from "./ProgramTypeView";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const names = [
  { title: "Program Type", name: "name" },
  { title: "Status", name: "isActive" },
];
const ProgramTypeMaster = ({
  getProgramType,
  saveProgramType,
  deleteProgramTypeById,
  logOut,
  loginUser,
  isFetch,
  resetDelete,
  getAlert,
  programTypeList,
}) => {
  const navigate = useNavigate();
  const [isEdit, setEdit] = useState(false);
  const [snakMsg, setSnakMsg] = useState("");
  const [editRow, setEditRow] = useState([]);
  const [viewRow, setViewRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteropen, setFilterOpen] = useState(false);
  const [openSnak, setOpenSnak] = useState(false);
  const [filteredList, setfilterList] = React.useState([
    "Program Type",
    "Status",
  ]);
  const [progTypeList, setProgTypeList] = React.useState([
    [{ title: "Sr. No.", name: "index" }],
    [{ title: "Program Type", name: "name" }],
    [{ title: "Status", name: "isActive" }],
  ]);

  const rowStatus = async (id) => {
    swal(myConstClass.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        const viewRow = programTypeList?.programType.filter(
          (row) => row.id === id
        );

        let programTypeData = {
          id: viewRow[0].id,
          name: viewRow[0].programType,
          isActive: viewRow[0].isActive === "Active" ? "0" : "1",
          delStatus: 1,
        };

        saveProgramType({ programTypeData: programTypeData }).then(() => {
          setOpenSnak(true);
          setSnakMsg(myConstClass.activeStatus);
          getProgramType().then((respo) => {
            if (!respo) {
              getAlert({ message: myConstClass.serverMsg });
            }
          });
        });
      }
    });
  };
  const getRowID = async (id) => {
    setViewRow([]);
    const editedRow = programTypeList?.programType.filter(
      (row) => row.id === id
    );
    if (editedRow.length > 0) {
      setEdit(true);
      setEditRow([]);
      return await setEditRow(editedRow);
    }

    return setEditRow([]);
  };
  const getRowData = async (id) => {
    setOpen(true);
    const viewRow = programTypeList?.programType.filter((row) => row.id === id);
    if (viewRow.length > 0) {
      return await setViewRow(viewRow);
    }
    return setViewRow([]);
  };
  useEffect(() => {
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
  });

  const data = (instData) => {
    saveProgramType({ programTypeData: instData }).then(() => {
      setOpenSnak(true);
      if (instData.id != null) {
        setSnakMsg(myConstClass.updateMsg);
      } else {
        setSnakMsg(myConstClass.saveMsg);
      }
      getProgramType().then((respo) => {
        if (!respo) {
          getAlert({ message: myConstClass.serverMsg });
        }
      });
    });
    setEdit(false);
    setEditRow([]);
  };

  useEffect(() => {
    getProgramType().then((respo) => {
      if (!respo) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
  }, []);

  const saveAndNextData = (data) => {
    saveProgramType({ programTypeData: data }).then(() => {
      setOpenSnak(true);
      if (data.id != null) {
        setSnakMsg(myConstClass.updateMsg);
      } else {
        setSnakMsg(myConstClass.saveMsg);
      }
      getProgramType().then((respo) => {
        if (!respo) {
          getAlert({ message: myConstClass.serverMsg });
        }
      });
    });
    setEdit(true);
    setEditRow([]);
  };

  const startEdit = () => {
    setEdit(true);
  };

  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be retrived,Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteProgramTypeById({ programTypeId: id }).then(() => {
          setOpenSnak(true);
          setSnakMsg(myConstClass.deleteMsg);
          getProgramType().then((respo) => {
            if (!respo) {
              getAlert({ message: myConstClass.serverMsg });
            }
          });
        });
      }
    });
  };
  const filteredRows = (searchvalue) => {
    const { value } = searchvalue.target;
    setSearchValue(value);
  };
  const stopEdit = () => {
    setEditRow([]);
    setEdit(false);
  };

  const addFile = (file) => {};
  const closeFilter = () => {
    setProgTypeList([
      [{ title: "Sr. No.", name: "index" }],
      [{ title: "Program Type", name: "name" }],
      [{ title: "Status", name: "isActive" }],
    ]);
    setfilterList(["Program Type", "Status"]);
    setFilterOpen(false);
  };
  const handleChange = (event) => {
    setFilterOpen(true);
    const {
      target: { value },
    } = event;
    if (value.length > filteredList.length) {
      const a = value.map((value) => {
        const trust = names.filter((row) => row.title === value);
        const trust1 = [...progTypeList, trust];

        return setProgTypeList(trust1);
      });
    } else {
      let difference = filteredList.filter((x) => !value.includes(x));
      const programTypeList = progTypeList.filter((data) => {
        return data[0].title !== difference[0];
      });
      setProgTypeList(programTypeList);
    }

    setfilterList(typeof value === "string" ? value.split(",") : value);

    if (value.length === 0) {
      setFilterOpen(false);
    }
  };
  if (isFetch) {
    return (
      <>
        <div>
          <Paper sx={{ p: 1, borderRadius: 2 }} elevation={2}>
            <TitleAndBreadCrum title="Program Type Master" />
          </Paper>
          {/* {programTypeList.isServerFail && <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete}/>} */}
          &nbsp;&nbsp;
          {
            <DirectionSnackbar
              open={openSnak}
              severity="success"
              msg={snakMsg}
              closeSnak={setOpenSnak}
            />
          }
          <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
            <div>
              {viewRow.length >= 0 && editRow.length >= 0 && isEdit && (
                <ProgramTypeForm
                  OnSave={data}
                  isEdit={editRow}
                  onCancel={stopEdit}
                  handleFile={addFile}
                  saveAndNext={saveAndNextData}
                />
              )}
            </div>
            {open && (
              <ViewInst data={viewRow} openparam={open} closeView={setOpen} />
            )}
            <Grid>
              {!isEdit && (
                <div>
                  <Box display="flex" justifyContent="space-between">
                    <Box>
                      <Search
                        filteredRows={filteredRows}
                        searchValue={searchValue}
                      />
                    </Box>
                    <Box>
                      <FormControl sx={{ m: -1, width: 250 }}>
                        <InputLabel id="demo-multiple-checkbox-label">
                          Filter
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={filteredList}
                          name="sdf"
                          size="small"
                          onChange={handleChange}
                          input={<OutlinedInput label="Filter" />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {names.map((name) => {
                            return (
                              <MenuItem key={name.name} value={name.title}>
                                <Checkbox
                                  checked={
                                    filteredList.indexOf(name.title) > -1
                                  }
                                />
                                <ListItemText primary={name.title} />
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>

                      {filteropen && (
                        <>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <ProgramTypeExcelData
                            csvData={programTypeList?.programType}
                            programTypeList={progTypeList}
                            fileName="Program Type Master"
                            type="pdf"
                          />
                        </>
                      )}
                      {filteropen && (
                        <>
                          &nbsp;&nbsp;
                          <ProgramTypeExcelData
                            csvData={programTypeList?.programType}
                            programTypeList={progTypeList}
                            fileName="Program Type Master"
                            type="excel"
                          />
                        </>
                      )}

                      {filteropen && (
                        <>
                          &nbsp;&nbsp;
                          <FontAwesomeIconCompo
                            className="fa-times-circle"
                            color="primary"
                            title="Cancel"
                            baseClassName="fas"
                            onClick={closeFilter}
                          />
                        </>
                      )}
                      {!filteropen && filteredList.length >= 0 && (
                        <>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <ProgramTypeExcelData
                            csvData={programTypeList?.programType}
                            programTypeList={progTypeList}
                            fileName="Program Type Master"
                            type="pdf"
                          />
                        </>
                      )}

                      {!filteropen && filteredList.length >= 0 && (
                        <>
                          &nbsp;&nbsp;
                          <ProgramTypeExcelData
                            csvData={programTypeList?.programType}
                            programTypeList={progTypeList}
                            fileName="Program Type Master"
                            type="excel"
                          />
                        </>
                      )}

                      {!filteropen && filteredList.length >= 0 && (
                        <>
                          &nbsp;&nbsp;&nbsp;
                          <MyComponentWithIconProps
                            statusImage={AddCircleIcon}
                            color="primary"
                            fontSize="large"
                            onClick={startEdit}
                          />
                        </>
                      )}
                    </Box>
                  </Box>

                  {/* <ProgramTypeTable
                    filteredColumns={progTypeList}
                    data={programTypeList?.programType}
                    rowEdit={getRowID}
                    rowView={getRowData}
                    rowDelete={deleteData}
                    rowStatus={rowStatus}
                  /> */}
                </div>
              )}
            </Grid>
            {programTypeList?.programType.length === 0 && (
              <Loading1 size={50} />
            )}
          </Paper>
        </div>
      </>
    );
  } else {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }
};

const mapStateToProps = (state) => ({
  programTypeList: state.programType,
  isFetch: state.programType,
  common: state.common,
  loginUser: state.user,
});

const mapDispatchToProps = {
  getProgramType,
  deleteProgramTypeById,
  getProgramTypeById,
  saveProgramType,
  resetDelete,
  logOut,
  getAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProgramTypeMaster);
