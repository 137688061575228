import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { decodeData } from "../../components/Comman/Util/Base64";
import endpoints from "../../config/endpoints";
import { tokenExp } from "../Login/login.slice";

const initialState = {
  category: [],
  categoryData: [],
  isFetch: false,
  isFetchCat: false,
  categoryList: [],
  isFetchCatList: false,
  categoryFilterData: [],
  isFetchCategoryFilterData: false,
};

let URL = endpoints.category;
const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    categorySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        category: row,
        isFetchCat: true,
      };
    },
    categoryListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        categoryList: row,
        isFetchCatList: true,
      };
    },
    categoryFilterDataSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        categoryFilterData: row,
        isFetchCategoryFilterData: true,
      };
    },
    categoryFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        category: [],
        isFetchCat: false,
      };
    },
    categoryListByNameSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        categoryData: data,
        isFetch: true,
      };
    },
    categoryListByNameFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        categoryData: [],
        isFetch: false,
      };
    },
  },
});

export const {
  categorySuccess,
  categoryListSuccess,
  categoryFilterDataSuccess,
  categoryFailure,
  categoryListByNameSuccess,
  categoryListByNameFailure,
} = categorySlice.actions;

export default categorySlice.reducer;

export const getCategory = () => async (dispatch) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get(URL + '?filters={"delStatus": "1","_mode" : "EXACT"}', { headers })
      .then((response) => {
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          let data = response.data.data.content;
          let row = [];
          let index = 0;
          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              type:
                data1.type === 0
                  ? "Original Category"
                  : data1.type === 1
                  ? "defence"
                  : data1.type === 2
                  ? "handicap"
                  : data1.type === 3
                  ? "TFWS"
                  : data1.type === 4
                  ? "J&K"
                  : "EWS",
              isActive: data1.isActive === 1 ? "Active" : "Inactive",
            };
            row.push(bData);
          });
          dispatch(categorySuccess({ row }));
          return row;
        }
      })

      .catch((error) => {
        return false;
      });
    return doc;
  } catch (e) {
    return false;
  }
};

export const getCategoryType = () => async (dispatch) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    await axios
      .get(URL + '?filters={"type": "1","_mode" : "EXACT"}', { headers })
      .then((response) => {
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          let data = response.data.data.content;
          let row = [];
          let index = 0;
          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              type: data1.type,
              isActive: data1.isActive === 1 ? "Active" : "Inactive",
            };
            row.push(bData);
          });
          dispatch(categoryListSuccess({ row }));
          return row;
        }
      })
      .catch((error) => {
        return false;
      });
  } catch (e) {
    return false;
  }
};

export const getCategoryFilterData = () => async (dispatch) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const response = await axios
      .get(URL + '?filters={"type": "1","_mode" : "NIN"}', { headers })
      .then((response) => {
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          let data = response.data.data.content;
          let row = [];
          let index = 0;
          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              type: data1.type,
              isActive: data1.isActive === 1 ? "Active" : "Inactive",
            };
            row.push(bData);
          });
          dispatch(categoryFilterDataSuccess({ row }));
          return row;
        }
      })
      .catch((error) => {
        return false;
      });
    return response;
  } catch (e) {
    return false;
  }
};

export const getCat =
  ({ row }) =>
  async (dispatch) => {
    try {
      dispatch(categorySuccess({ row }));
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getCategoryByName =
  ({ categoryName }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      await axios
        .get(
          URL +
            '?filters={"delStatus": "1","_mode" : "EXACT"},{"name":"' +
            categoryName +
            '","_mode" : "EXACT"}',
          { headers }
        )
        .then((response) => {
          console.log(
            "Success getCategoryByName  ========>",
            response.data.data.content
          );
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            let data = response.data.data.content;
            dispatch(categoryListByNameSuccess({ data }));
            return data;
          }
        })
        .catch((error) => {
          return false;
        });
    } catch (e) {
      return false;
    }
  };

export const deleteCategoryData = () => async (dispatch) => {
  try {
    dispatch(categoryListByNameFailure());
  } catch (e) {
    return console.error(e.message);
  }
};

export const saveCategory =
  ({ categoryData }) =>
  async (dispatch) => {
    try {
      let data = JSON.stringify(categoryData);
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      await axios
        .post(URL, data, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            const { data } = response.data;
            return data;
          }
        })
        .catch((error) => {
          return false;
        });
    } catch (e) {
      return false;
    }
  };

export const deleteCategoryById =
  ({ categoryId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      await axios
        .delete(URL + "/" + categoryId, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            return true;
          }
        })
        .catch((error) => {
          return false;
        });
    } catch (e) {
      return false;
    }
  };
