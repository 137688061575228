// import { Grid } from "@mui/material";
// import FormControl from "@mui/material/FormControl";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Radio from "@mui/material/Radio";
// import RadioGroup from "@mui/material/RadioGroup";
// import * as React from "react";
// import { connect } from "react-redux";
// import { ButtonCompo } from "../../components/Comman/Buttton";
// import { LabelCompo } from "../../components/Comman/Label";
// let docs;
// class SpecialReservationDetails extends React.Component {
//   constructor(props) {
//     super(props);
//   }
//   state = {
//     id: 0,
//     flag: 0,
//     breadCrum: "",
//     disability: 0,
//     defence: 0,
//     orphan: 0,
//     minority: "",
//     formErrors: [],
//   };
//   changeHandler = (event) => {
//     const { name, value } = event.target;
//     this.setState({
//       ...this.state,
//       [name]: value,
//     });
//   };
//   componentDidMount() {
//     const { onCancel, religionMaster, categoryList, student } = this.props;

//     this.setState({
//       id: student.applicant.id,
//     });
//     if (Object.keys(student.applicant).length != 0) {
//       this.setState({
//         id: student.applicant.id,
//         disability: student.applicant.handicapCategory,
//         defence: student.applicant.defenceType,
//         orphan: student.applicant.isOrphan,
//         minority: student.applicant.minority,
//       });
//     }
//   }
//   handleFormValidation() {
//     const { disabilityName, defenceName } = this.state;
//     let formErrors = {};
//     let formIsValid = true;
//     this.setState({ formErrors: formErrors });
//     return formIsValid;
//   }

//   submitHandler = (e) => {
//     e.preventDefault();
//     const { id, disability, defence, orphan, minority, isActive } = this.state;
//     docs = {
//       id: id === 0 ? 0 : id,
//       handicapCategory: disability,
//       defenceType: defence,
//       isOrphan: orphan,
//       minority: minority,
//       type: 3,
//     };

//     if (this.handleFormValidation()) {
//       this.props.specialReservationDetailsData(docs);
//       this.setState({ flag: 1 });
//     }
//   };

//   render() {
//     const { id, disability, defence, orphan, minority, isActive } = this.state;
//     const { districtErr, disabilityNameErr, defenceNameErr } =
//       this.state.formErrors;

//     const { onCancel, student } = this.props;

//     return (
//       <>
//         <form
//           onSubmit={this.submitHandler}
//           autoComplete="off"
//           noValidate={true}
//         >
//           <Grid
//             container
//             justifyContent="space-evenly"
//             direction="row"
//             spacing={2}
//           >

//             <Grid item xs={12} sm={12} md={4}>
//               <LabelCompo
//                 className="text-black"
//                 style={{ fontSize: 16 }}
//                 label="*Person With Disability?"
//               />
//             </Grid>


//             <Grid sx={{ m: -1 }} item xs={12} sm={12} md={8}>
//               <FormControl component="fieldset">
//                 <RadioGroup
//                   row
//                   aria-label="Is Active"
//                   id="disability"
//                   name="disability"
//                   value={disability}
//                   onChange={this.changeHandler}
//                   size="small"
//                 >
//                   <FormControlLabel value={1} control={<Radio />} label="Yes" />
//                   <FormControlLabel value={0} control={<Radio />} label="No" />
//                 </RadioGroup>
//               </FormControl>
//             </Grid>
//             {/* <Grid item xs={12} sm={6} md={12} /> */}

//             <Grid item xs={12} sm={12} md={4}>
//               <LabelCompo
//                 className="text-black"
//                 style={{ fontSize: 16 }}
//                 label="*Is Parent a Defence Personnel?"
//               />
//             </Grid>

//             <Grid sx={{ m: -1 }} item xs={12} sm={12} md={8}>
//               <FormControl component="fieldset">
//                 <RadioGroup
//                   row
//                   aria-label="Is Active"
//                   id="defence"
//                   name="defence"
//                   value={defence}
//                   onChange={this.changeHandler}
//                   size="small"
//                 >
//                   <FormControlLabel value={1} control={<Radio />} label="Yes" />
//                   <FormControlLabel value={0} control={<Radio />} label="No" />
//                 </RadioGroup>
//               </FormControl>
//             </Grid>

//             <Grid item xs={12} sm={12} md={4}>
//               <LabelCompo
//                 className="text-black"
//                 style={{ fontSize: 16 }}
//                 label="*Orphan?"
//               />
//             </Grid>

//             <Grid sx={{ m: -1 }} item xs={12} sm={12} md={8}>
//               <FormControl component="fieldset">
//                 <RadioGroup
//                   row
//                   aria-label="Is Active"
//                   id="orphan"
//                   name="orphan"
//                   value={orphan}
//                   onChange={this.changeHandler}
//                   size="small"
//                 >
//                   <FormControlLabel value={1} control={<Radio />} label="Yes" />
//                   <FormControlLabel value={0} control={<Radio />} label="No" />
//                 </RadioGroup>
//               </FormControl>
//             </Grid>

//             <Grid item xs={12} sm={12} md={4}>
//               <LabelCompo
//                 className="text-black"
//                 style={{ fontSize: 16 }}
//                 label="*Did You Belong To Minority?"
//               />
//             </Grid>

//             <Grid sx={{ m: -1 }} item xs={12} sm={12} md={8}>
//               <FormControl component="fieldset">
//                 <RadioGroup
//                   row
//                   aria-label="Is Active"
//                   id="minority"
//                   name="minority"
//                   value={minority}
//                   onChange={this.changeHandler}
//                   size="small"
//                 >
//                   <FormControlLabel
//                     value="yes"
//                     control={<Radio />}
//                     label="Yes"
//                   />
//                   <FormControlLabel value="no" control={<Radio />} label="No" />
//                 </RadioGroup>
//               </FormControl>
//             </Grid>
//             <Grid item xs={12} sm={6} md={12} />

//             <Grid
//               rowSpacing={2}
//               // columnSpacing={{ xs: 12, sm: 6, md: 1 }}
//               container
//               columnSpacing={1}
//               justifyContent="right"
//             >
//                 <Grid
//                 item
//                 sm={12}
//                 xs={12}
//                 md={1.1}
//                 lg={1.1}
//                 justifyContent="space-evenly"
//               >
//                 <ButtonCompo
//                   size="medium"
//                   type="button"
//                   variant="outlined"
//                   name="Cancel"
//                   onClick={onCancel}
//                   fullWidth={true}
//                 />
//               </Grid>

//               <Grid
//                 item
//                 sm={12}
//                 xs={12}
//                 md={2}
//                 lg={2}
//                 justifyContent="space-evenly"
//               >
//                 <ButtonCompo
//                   size="medium"
//                   type="submit"
//                   variant="contained"
//                   name="Save and Proceed"
//                   fullWidth={true}
//                 />
//               </Grid>
//             </Grid>
//           </Grid>
//         </form>
//       </>
//     );
//   }
// }

// const mapStateToProps = (state) => ({
//   student: state.student,
// });
// const mapDispatchToProps = {};

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(SpecialReservationDetails);
