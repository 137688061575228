import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Alert from "../../Alert/Alert";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { DirectionSnackbar } from "../../components/Comman/SnakBarCompo";
import endpoints from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import { logOut } from "../Login/login.slice";
import { downloadF } from "../PDF/PDFDownload";
import {
  getMeritList,
  resetDelete,
  saveMeritList,
} from "./generatemeritlist.slice";
import GenerateMeritListForm from "./GenerateMeritListForm";
import GenerateMeritListTable from "./GenerateMeritListTable";
import { useNavigate } from "react-router";
import { LabelCompo } from "../../components/Comman/Label";
import { getAlert } from "../../Alert/alert.slice";
const GenerateMeritListMaster = ({
  OnCancle,
  resetDelete,
  getMeritList,
  saveMeritList,
  appDecId,
  generateMeritList,
  loginUser,
  logOut,
  getAlert,
}) => {
  const navigate = useNavigate();

  const [snakMsg, setSnakMsg] = useState("");
  const [editRow, setEditRow] = useState([]);

  const [openSnak, setOpenSnak] = useState(false);
  useEffect(() => {
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
  });
  const getRowID = async (id) => {
    const editedRow = generateMeritList?.meritlist.filter(
      (row) => row.id === id
    );
    if (editedRow.length > 0) {
      setEditRow([]);
      return await setEditRow(editedRow);
    }

    return setEditRow([]);
  };

  const generateMeritListPDF = async (id) => {
    console.log(id);
    let userAppReportURL = endpoints.meritListPdf;
    downloadF({ url: userAppReportURL + "/" + id });
  };

  const data = (instData) => {
    saveMeritList({
      id: appDecId,
      meritname: instData.name,
      type: instData.type,
    }).then((response) => {
      if (response) {
        setOpenSnak(true);
        setSnakMsg("Data save successfully");
        getMeritList({ appDecId: appDecId }).then((response) => {
          if (!response) {
            getAlert({ message: myConstClass.serverMsg });
          }
        });
      } else {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
  };
  useEffect(() => {
    getMeritList({ appDecId: appDecId }).then((response) => {
      if (!response) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
  }, []);

  return (
    <>
      <div>
        {/* {generateMeritList.isServerFail && (
          <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />
        )} */}

        {
          <DirectionSnackbar
            open={openSnak}
            severity="success"
            msg={snakMsg}
            closeSnak={setOpenSnak}
          />
        }
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            spacing={2}
            columnSpacing={1}
            container
            sx={{ m: 0.5 }}
            justifyContent="space-evenly"
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={11} md={11}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 20 }}
                  label="Generate Merit List"
                />
              </Grid>
              <Grid item xs={12} sm={1} md={1}>
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Back"
                  onClick={OnCancle}
                />
              </Grid>
            </Grid>
          </Grid>

          <GenerateMeritListForm OnSave={data} isEdit={editRow} />

          <Grid item sx={{ p: 1 }} xs={12} sm={12} md={12} />
          <GenerateMeritListTable
            data={generateMeritList?.meritList}
            tableHead={[
              {
                title: "Sr. No.",
                name: "index",
                positionCenter: true,
                alignCenter: "center",
                showInscreen: true,
              },
              {
                title: "Merit List Generated",
                name: "name",
                positionCenter: false,
                showInscreen: true,
              },
              {
                title: "Type",
                name: "type",
                positionCenter: false,
                showInscreen: true,
              },
              {
                title: "Action",
                name: "action",
                positionCenter: true,
                alignCenter: "right",
                showInscreen: true,
              },
            ]}
            showPdfDetails={true}
            pdfDetailsId={generateMeritListPDF}
          />
        </Paper>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  generateMeritList: state.meritlist,
  loginUser: state.user,
});

const mapDispatchToProps = {
  getMeritList,
  saveMeritList,
  resetDelete,
  downloadF,
  logOut,
  getAlert,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenerateMeritListMaster);
