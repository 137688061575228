import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import news from "../Images/news.png";
import {
  getLatestNews,
  getNewsDocNotification,
  getNotification,
  getOnlineAdmission
} from "../Pages/Landing/Landing.slice";
import { viewMultipleFile } from "../Pages/UploadFile/file.slice";
import Navbar from "./Navbar";
import Header from "./NewHeader";
const styles = (theme) => ({
  hello: {
    position: "absolute",
    left: "15.17%",
    right: "15.08%",
    top: "63.4%",
    bottom: "32.6%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: "normal",
    fontsize: "36px",
    lineheight: "40px",
    color: "#000000",
  },
  buttonC: {
    position: "absolute",

    left: "39.17%",
    right: "39.17%",
    top: "79.7%",
    bottom: "14.8%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: 500,
    fontsize: "18px",
    lineheight: "40px",
    color: "#FFFFFF",
  },
  helper: {
    position: "absolute",
    left: "9.58%",
    right: "9.5%",
    top: "70%",
    bottom: "22%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: "normal",
    fontsize: "20px",
    lineheight: "40px",

    textAlign: "center",

    color: "#666666",
  },
  title: {
    position: "absolute",
    left: "43.42%",
    right: "43.33%",
    top: "57%",
    bottom: "39%",

    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: 500,
    fontsize: "55px",
    lineheight: "40px",
    color: "#000000",
  },
});
const ErrorPage = ({
  getNewsDocNotification,
  newsDocNotificationDocs,
  getLatestNews,
  viewMultipleFile,
  getNotification,
  getOnlineAdmission,
  classes,
}) => {
  useEffect(() => {
    getNewsDocNotification().then(() => {
      console.log(newsDocNotificationDocs);
    });

    getLatestNews().then((response) => {
      console.log(response);
      // response.map((data)=>{
      //   if(data.documentName!=''){
      //     let documentName = {
      //       fileName: data.documentName,
      //       name: data.documentName,
      //     };
      //     viewMultipleFile({
      //       file: documentName,
      //     });
      //   }

      // })
    });

    getNotification().then(() => {});

    getOnlineAdmission().then(() => {});
  }, []);

  let Flag = 0;
  // const date=new Date()
  return (
    <div align="center">
      <Header />
      <Navbar />
      <div className="new_content">
        <div className="marquee_bx">
          <div className="marquee_left">Latest News</div>
          <div className="morquee_right">
            {newsDocNotificationDocs?.newsNotification
              .slice(0, 1)
              .map((data) => {
                return (
                  <marquee
                    behavior="scroll"
                    direction="left"
                    scrollamount="3"
                    key={data.id}
                  >
                    <div className="marquee_one">
                      {data.description}
                      {data.showLabel === 1 && (
                        <div className="blink_me">{data.labelName}</div>
                      )}
                    </div>
                  </marquee>
                );
              })}
          </div>
          <div className="clr"></div>
        </div>

        <Grid item container justifyContent="space-between">
          {/* 1st grid */}
          <Grid item md={4}>
            <div className="box_one">
              <h3 className="event_title">Online Admission</h3>

              <div className="event_cont">
                {newsDocNotificationDocs?.admission.slice(0, 3).map((data) => {
                  return (
                    <div className="event_cont_new" key={data.id}>
                      {/*admission-one*/}

                      <div className="admission_one">
                        <div className="admission_title">
                          <div className="admission_date">
                            Date <span> {data.lastDate}</span>
                          </div>
                          <div className="admission_cont">
                            <h6 className="event_sub_nm">
                              {data.title}
                              {/* <span>Am Univercity 7 April in, Mumbai.</span> */}
                            </h6>
                          </div>
                        </div>

                        <div className="noti_title">
                          <span>
                            {data.title}
                            {data.showLabel === 1 && (
                              <div className="blink_me">{data.labelName}</div>
                            )}
                          </span>
                        </div>
                        <p className="admission_txt">
                          {data.shortSummary === data.shortSummary.slice(0, 70)
                            ? data.shortSummary.slice(0, 70)
                            : data.shortSummary.slice(0, 70) + " ... "}
                          {data.shortSummary !==
                            data.shortSummary.slice(0, 70) && (
                            <a href="/allNotifications2" className="news">
                              Read more
                            </a>
                          )}
                        </p>
                        <div className="news_time home">
                          <span>Date :</span> {data.lastDate}
                        </div>

                        <a href="/login" className="admission_apply">
                          Apply Now
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="event_cont button">
                <div className="more_noti">
                  <a href="/allNotifications" className="more">
                    See all ongoing admissions
                  </a>
                </div>
              </div>
            </div>

            {/* </div> */}
          </Grid>

          {/* 2nd grid */}
          <Grid item md={4}>
            <div className="box_one">
              <h3 className="event_title pink">Latest News</h3>

              <div className="event_cont">
                {newsDocNotificationDocs?.newsNotification
                  .slice(0, 3)
                  .map((data) => {
                    return (
                      <div className="event_cont_new" key={data.id}>
                        <div className="news_one">
                          <div className="news_pic">
                            <img
                              src={
                                data.documentName === ""
                                  ? news
                                  : data.documentName
                              }
                            ></img>
                          </div>
                          <div className="news_cont">
                            <h4 className="noti_title">
                              <span>
                                {data.title}
                                {data.showLabel === 1 && (
                                  <div className="blink_me">
                                    {data.labelName}
                                  </div>
                                )}
                              </span>
                            </h4>
                            <p className="admission_txt">
                              {data.description ===
                              data.description.slice(0, 70)
                                ? data.description.slice(0, 70)
                                : data.description.slice(0, 70) + " ... "}
                              {data.description !==
                                data.description.slice(0, 70) && (
                                <a href="/allNotifications2" className="news">
                                  Read more
                                </a>
                              )}
                            </p>
                            {/* <h3 className="news_title">{'data.documentName'+data.documentName} </h3> */}
                            <h6 className="news_time">
                              <span className="blue">Date :</span> {data.date}
                            </h6>
                          </div>
                          <div className="clr"></div>
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div className="event_cont button">
                <div className="more_noti">
                  <a href="/allNotifications2" className="news">
                    See all latest news
                  </a>
                </div>
              </div>
            </div>
          </Grid>

          {/* 3rd grid */}
          <Grid item md={4}>
            <div className="box_one">
              <h3 className="event_title purple">Notifications</h3>
              <div className="event_cont">
                {newsDocNotificationDocs?.newsLatestNotification
                  .slice(0, 3)
                  .map((data) => {
                    return (
                      <div className="event_cont_new" key={data.id}>
                        <div className="notification_one">
                          <h4 className="noti_title">
                            <span>{data.title} 
                            {data.showLabel === 1 && (
                        <div className="blink_me">{data.labelName}</div>
                      )}
                      </span>
                          </h4>
                          <p className="admission_txt">
                            {" "}
                            {data.description === data.description.slice(0, 100)
                              ? data.description.slice(0, 100)
                              : data.description.slice(0, 100) + " ... "}
                            {data.description !==
                              data.description.slice(0, 100) && (
                              <a href="/allNotifications3" className="news">
                                Read more
                              </a>
                            )}{" "}
                          </p>
                          <h6 className="news_time">
                            <span className="pink">Date :</span> {data.date}
                          </h6>
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div className="event_cont button">
                <div className="more_noti padd_top">
                  <a href="/allNotifications3">See all recent notifications</a>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  newsDocNotificationDocs: state.landing,
});

const mapDispatchToProps = {
  getNewsDocNotification,
  getLatestNews,
  viewMultipleFile,
  getNotification,
  getOnlineAdmission,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ErrorPage));
