import { Box, FormHelperText, Grid, Paper, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import MultipleSelect from "../../components/Comman/MultipleSelect";
import "../../CSS/View.css";
import { getCategory } from "../CategoryMaster/category.slice";
import QualificationDetails from "../QualificationDetails/Index";
import AutoComplete from "../../components/Comman/AutoComplete";
import { getAlert } from "../../Alert/alert.slice";
import * as myConstClass from "../../config/messageconstant";
import { getPaymentHashcode } from "./studentDirectAdmission.slice";
import { Loading1 } from "../../components/Loading1";
import Swal from "sweetalert2";
let docs;
class ApplicationInstruction extends React.Component {
  constructor(props) {
    super(props);
  }

  styles = {
    preview: {
      marginTop: 50,
      display: "flex",
      flexDirection: "column",
    },
    image: { maxWidth: "100%", maxHeight: 320 },
    delete: {
      cursor: "pointer",
      padding: 15,
      background: "red",
      color: "white",
      border: "none",
    },
  };

  state = {
    id: 0,
    file: "",
    category: [],
    programTitle: "",
    minority: "",
    fileName: "",
    signfile: "",
    imagefile: "",
    formErrors: [],
    flag: false,
    showLoader: false,
    showDetails: false,
  };
  multiSelectHandler = (value, name) => {
    this.setState({
      ...this.state,
      [name]: typeof value === "string" ? value.split(",") : value,
    });
  };
  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };
  submitHandler = (e) => {
    const { openDocUpload } = this.props;
    const { category, minority, programTitle } = this.state;
    if (this.handleFormValidation()) {
      var categoryData = category.join();

      docs = {
        programTitleIds: categoryData === "" ? 0 : categoryData,
        minority: minority === "" ? 0 : minority,
        programTitle: programTitle,
      };

      // this.props.OnSave(docs);

      openDocUpload(docs);
    }
  };
  getQualiDetails = (data) => {};
  componentDidMount() {
    const { getCategory, appliData } = this.props;
    if (appliData?.programTitleByApplicationId.length !== 0) {
      this.setState({
        showLoader: true,
      });
      getCategory().then((respo) => {
        if (!respo) {
          this.setState({
            showLoader: false,
          });
          getAlert({ message: myConstClass.serverMsg });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
    } else {
      this.setState({ showDetails: true });
      Swal.fire({
        icon: "success",
        title: "No Program Title",
        text: " You have already applied  for all the course listed in the respective application ",
        showConfirmButton: true,
        confirmButtonColor: "#1f89f4",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      }).then((okLogin) => {
        if (okLogin.isConfirmed) {
          window.location.replace("/applicationForm");
        }
      });
    }
  }

  handleFormValidation() {
    const { programTitle } = this.state;
    let formErrors = {};
    let formIsValid = true;
    if (programTitle === "" || programTitle === null) {
      formIsValid = false;
      formErrors["programTitleErr"] = myConstClass.feBrNameMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  render() {
    const { category, minority, programTitle, showLoader, showDetails } =
      this.state;
    const { programTitleErr } = this.state.formErrors;
    const { onCancel, courseErr, catList, appliData } = this.props;
    const categoryList = catList.category.filter(
      (row) => row.type === "handicap"
    );
    return (
      <>
        {!showDetails && (
          <>
            {showLoader && <Loading1 size={50} />}
            <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
              <Grid>
                <LabelCompo
                  className="text-black"
                  style={{
                    fontSize: 18,
                    color: "black",
                    fontFamily: "Poppins",
                  }}
                  label="Instructions"
                />

                <Box display="flex" justifyContent="space-between ">
                  <Box style={{ width: 1100 }}>
                    <Card
                      variant="outlined"
                      sx={{
                        color: "#ed1c24",
                        fontFamily: "poppins",
                        mt: 1,
                        p: 1,
                      }}
                    >
                      <CardContent style={{ lineHeight: "34px" }}>
                        <Grid>
                          <Typography variant="span">a)</Typography>
                          &emsp;
                          <Typography variant="span">
                            The Photo should be Colorful. Black and White Photo
                            should not be Accepted.
                          </Typography>
                        </Grid>

                        <Grid>
                          <Typography variant="span">b)</Typography>
                          &emsp;
                          <Typography variant="span">
                            Size of the Photograph should be in between 20KB to
                            100KB.
                          </Typography>
                        </Grid>

                        <Grid>
                          <Typography variant="span">c)</Typography>
                          &emsp;
                          <Typography variant="span">
                            The photograph and Signature Should be Scanned.
                          </Typography>
                        </Grid>

                        <Grid>
                          <Typography variant="span">d)</Typography>
                          &emsp;
                          <Typography variant="span">
                            The Photograph Dimension should be of 4.5 cm * 3.5
                            cm
                          </Typography>
                        </Grid>

                        <Grid>
                          <Typography variant="span">e)</Typography>
                          &emsp;
                          <Typography variant="span">
                            Photo Should be without CAP, Googles and both ears
                            should be visible.
                          </Typography>
                        </Grid>

                        <Grid>
                          <Typography variant="span">f)</Typography>
                          &emsp;
                          <Typography variant="span">
                            The Signature should be of black and blue ink.
                          </Typography>
                        </Grid>

                        <Grid>
                          <Typography variant="span">g)</Typography>
                          &emsp;
                          <Typography variant="span">
                            The Signature should be in the box only. Outside of
                            the box or on the box will not be accepted.
                          </Typography>
                        </Grid>
                        <Grid>
                          <Typography variant="span">h)</Typography>
                          &emsp;
                          <Typography variant="span">
                            The Signature should be on blank page.
                          </Typography>
                        </Grid>

                        <Grid>
                          <Typography variant="span">i)</Typography>
                          &emsp;
                          <Typography variant="span">
                            Size of the Signature Should be in between 20 KB to
                            50 KB.
                          </Typography>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                </Box>
                <br />
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={6}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  {categoryList.length !== 0 && (
                    <>
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Select Quota (If applicable)"
                        />
                      </Grid>
                      <Grid sx={{ mb: 2 }} item xs={12} sm={12} md={12}>
                        <MultipleSelect
                          er={courseErr}
                          name1="category"
                          value1={category}
                          names={catList.category.filter(
                            (row) => row.type === "handicap"
                          )}
                          onChange={this.multiSelectHandler}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>

                <Grid
                  item
                  sm={6}
                  xs={12}
                  md={6}
                  lg={6}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  {appliData.applicationData.byMinority == 1 && (
                    <>
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Select Minority Status (if applicable)"
                        />
                      </Grid>
                      <Grid sx={{ mt: -3 }} item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={minority}
                          name={"minority"}
                          options={catList?.categoryList}
                          onChange={this.ChangeHandlerSearch}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={6}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Select Program Title"
                    />
                  </Grid>
                  <Grid sx={{ mb: 2 }} item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={programTitle}
                      name={"programTitle"}
                      options={appliData?.programTitleByApplicationId}
                      onChange={this.ChangeHandlerSearch}
                    />
                    <FormHelperText error>{programTitleErr}</FormHelperText>
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={6}
                  xs={12}
                  md={6}
                  lg={6}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                ></Grid>
              </Grid>

              <br />

              <Grid
                rowSpacing={2}
                container
                columnSpacing={1}
                justifyContent="right"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1.1}
                  lg={1.1}
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Cancel"
                    onClick={onCancel}
                    fullWidth={true}
                  />
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={2.5}
                  lg={2.5}
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="contained"
                    name="Save and Proceed"
                    fullWidth={true}
                    onClick={this.submitHandler}
                  />
                </Grid>
              </Grid>
            </Paper>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  catList: state.category,
  appliData: state.directAdmission,
});

const mapDispatchToProps = {
  getCategory,
  getPaymentHashcode,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationInstruction);
