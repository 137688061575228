import { Divider, Grid, Paper, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import React from "react";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
let docs;
class FeePayment extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    id: 0,
    regfees: 0,
    formErrors: [],
    paymentselection: "",
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  componentDidMount() {
    this.setState({});
  }

  handleFormValidation(name) {
    const { paymentselection } = this.state;
    let formErrors = {};
    let formIsValid = true;
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const { id, paymentselection, regfees } = this.state;
    docs = {
      id: id === 0 ? 0 : id,
      regfees: regfees,
      paymentselection: paymentselection,
    };
    if (this.handleFormValidation()) {
      this.props.OnSave(docs);
    }
  };

  saveHandler = (e) => {
    e.preventDefault();
    const { id, paymentselection, regfees } = this.state;
    docs = {
      id: id === 0 ? 0 : id,
      regfees: regfees,
      paymentselection: paymentselection,
    };

    if (this.handleFormValidation()) {
      this.props.saveAndNext(docs);
      this.setState({
        id: 0,
        regfees: regfees,
        paymentselection: paymentselection,
      });
    }
  };

  render() {
    const { paymentselection } = this.state;
    const { } = this.state.formErrors;
    const { onCancle } = this.props;
    return (
      <>
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <form
            onSubmit={this.submitHandler}
            autoComplete="off"
            noValidate={true}
          >
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={2}
            >
            
              <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              spacing={2}
              columnSpacing={1}
              container
              sx={{ m: 0.5 }}
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                {/* <Grid item xs={12} sm={12} md={0.5}></Grid> */}
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 20 }}
                    label="Fees Payment Page"
                  />
                </Grid>
              </Grid>
            </Grid>
              <Grid item xs={12} sm={6} md={12} />
              <Grid item xs={12} sm={6} md={3}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Online Registration Fees :"
                />
              </Grid>
              <Grid m={-1} item xs={12} sm={6} md={6}>
                <TextField
                  color="primary"
                  name="registrationfees"
                  size="small"
                  disabled
                  onChange={this.changedHandler}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} />
              <Grid item xs={12} sm={6} md={12} />
              <Grid item xs={12} sm={6} md={3}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Paying Options :"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="upi"
                    name="paymentselection"
                    value={paymentselection}
                    onChange={this.changeHandler}
                    row
                  >
                    <FormControlLabel
                      value="Cardpayment"
                      control={<Radio />}
                      label="CARD PAYMENT"
                    />
                    <FormControlLabel
                      value="upi"
                      control={<Radio />}
                      label="UPI"
                    />
                    <FormControlLabel
                      value="netbanking"
                      control={<Radio />}
                      label="NET BANKING"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label="OTHER"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={3} />
              {/* <Grid item xs={12} sm={12} md={12} /> */}
              <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
<Grid
                item
                sm={12}
                xs={12}
                md={1}
                lg={1}
                container
                justifyContent="space-evenly"
              >
              <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Back"
                  fullWidth={true}
                  onClick={onCancle}
                />
</Grid>
    <Grid
                item
                sm={12}
                xs={12}
                md={2.7}
                lg={2.7}
                container
                justifyContent="space-evenly"
              >      
              <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  fullWidth={true}
                  name="Save and Proceed"
                />
            </Grid>
            </Grid>
          </Grid>
          </form>
        </Paper>
      </>
    );
  }
}
export default FeePayment;
