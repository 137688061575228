import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { decodeData } from "../../components/Comman/Util/Base64";
import endpoints from "../../config/endpoints";
import { tokenExp } from "../Login/login.slice";

const initialState = {
  roundDetails: [],
  roundDetailByApllication:[],
  isFetch: false,
  isFetchById: false,
  error: false,
  isDelete: false,
  isSave: false,
  isServerFail: false,
};
let URL = endpoints.application;
const roundDetailSlice = createSlice({
  name: "roundDetails",
  initialState,
  reducers: {
    roundDetailSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        isFetch: true,
        roundDetails: row,
      };
    },
    roundDetailFailure: (state = cloneDeep(initialState), action) => {
      
      return {
        ...state,
        isFetch: false,
        roundDetails: [],
      };
    },

    roundDetailByAppIdSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        isFetchById: true,
        roundDetailByApllication: data,
      };
    },
    roundDetailByAppIdFailure: (state = cloneDeep(initialState), action) => {
      
      return {
        ...state,
        isFetchById: false,
        roundDetailByApllication: [],
      };
    },
    serverFailed: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: true,
        error: false,
        isDelete: false,
      };
    },
    resetDeleteSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error: false,
        isDelete: false,
        isSave: false,
        isServerFail: false,
      };
    },
   
  },
});

export const {
  roundDetailSuccess,
  roundDetailFailure,
  roundDetailByAppIdSuccess,
  roundDetailByAppIdFailure,
  resetDeleteSuccess,
  serverFailed
} = roundDetailSlice.actions;

export default roundDetailSlice.reducer;

export const getRoundDetailById = ({ apllicationId }) => async (dispatch) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    let docs=await axios
      .get(
        URL + "/" +apllicationId +"/" +"application-round",
        { headers }
      )
      .then((response) => {
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        }
        console.log(response);
        if (response.data.error === false) {
          let {data} = response.data;
          let row = [];
          let index = 0;
          
          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              isActive: data1.isActive === 1 ? "Active" : "Inactive",      
            };
            row.push(bData);
          });
          dispatch(roundDetailSuccess({ row }));
          return row;
        } else {
          
          // dispatch(naviLogin({ row }));
        }
      })

      .catch((error) => {
        dispatch(serverFailed());

        console.log("Error getDeclration========>", error);
        return false;
      });
      return docs;
  } catch (e) {
    dispatch(serverFailed());

    return false;
  }
};


export const getRoundDetailByApplicationId = ({ apllicationId }) => async (dispatch) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    let docs=await axios
      .get(
        URL + "/" +apllicationId +"/" +"application-round-ids",
        { headers }
      )
      .then((response) => {
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        }
        console.log(response);
        if (response.data.error === false) {
          let {data} = response.data;
          let row = [];
          let index = 0;
          
          /* data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              isActive: data1.isActive === 1 ? "Active" : "Inactive",      
            };
            row.push(bData);
          }); */
          dispatch(roundDetailByAppIdSuccess({ data }));
          return row;
        } else {
          
          // dispatch(naviLogin({ row }));
        }
      })

      .catch((error) => {
        dispatch(serverFailed());

        console.log("Error getRoundDetailByApplicationId========>", error);
        return false;
      });
      return docs;
  } catch (e) {
    dispatch(serverFailed());

    return false;
  }
};
export const resetDelete = () => async (dispatch) => {
  try {
    dispatch(resetDeleteSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};


  


