import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { decodeData } from "../../components/Comman/Util/Base64";
import endpoints from '../../config/endpoints';
import { tokenExp } from "../Login/login.slice";

const initialState = {
  programType: [],
  program: [],
  programTypeData: [],
  isFetch: false,
  isFetchProg: false,
  error: false,
  isDelete: false,
  isSave: false,
  isServerFail: false,

};
let URL = endpoints.programType;
const programTypeSlice = createSlice( {
  name: "programType",
  initialState,
  reducers: {
    programTypeSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload
      return {
        ...state,
        programType: row,
      };
    },
    getprogramByProgIdSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload
      return {
        ...state,
        program: row,
        isFetchProg: true
      };
    },
    progFetched: ( state = cloneDeep( initialState ), action ) =>
    {

      return {
        ...state,
        isFetchProg: false,
      };
    },
    programTypeFailure: ( state = cloneDeep( initialState ), action ) =>
    {

      return {
        ...state,
        programType: [],
      };
    },
    programTypeListByNameSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { data } = action.payload
      return {
        ...state,
        programTypeData: data,
        isFetch: true,
      };
    },
    programTypeListByNameFailure: ( state = cloneDeep( initialState ), action ) =>
    {

      return {
        ...state,
        programTypeData: [],
        isFetch: false,

      };
    },
    serverFailed: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        isServerFail: true,
        error: false,
        isDelete: false
      };
    },
    resetDeleteSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        error: false,
        isDelete: false,
        isSave: false,
        isServerFail: false,

      };
    },

  },
} );

export const { programTypeSuccess, resetDeleteSuccess, serverFailed, progFetched, getprogramByProgIdSuccess, programTypeFailure, programTypeListByNameSuccess, programTypeListByNameFailure } = programTypeSlice.actions;

export default programTypeSlice.reducer;

export const getProgramType = () => async ( dispatch ) =>
{
  try
  {
    const name = localStorage.getItem( "token" );
    const token = decodeData( name );
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    let docs = await axios
      .get( URL + '?filters={"delStatus": "1","_mode" : "EXACT"}', { headers } )
      .then( ( response ) =>
      {
        const { error } = response.data
        if ( error == true )
        {
          dispatch( tokenExp() );

        } else
        {
          console.log( "Success getProgramType  ========>", response.data.data.content );
          let data = response.data.data.content;

          let row = [];
          let index = 0
          data.map( ( data1 ) =>
          {
            index = index + 1
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              isActive: data1.isActive === 1 ? "Active" : "Inactive",

            };
            row.push( bData );
            return data1;
          } );
          dispatch( programTypeSuccess( { row } ) );
          return row;
        }
      } )
      .catch( ( error ) =>
      {
        dispatch( serverFailed() );
        return false;
        // console.log( "Error getProgramType========>", error );
      } );
    return docs;
  } catch ( e )
  {
    dispatch( serverFailed() );
    return false;
    // return console.error( e.message );
  }
};
export const getProgramByProgType = ( { progType } ) => async ( dispatch ) =>
{
  try
  {
    const name = localStorage.getItem( "token" );
    const token = decodeData( name );
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    let docs = await axios
      .get( URL + "/" + progType + "/program-type" + '?filters={"delStatus": "1","_mode" : "EXACT"}', { headers } )
      .then( ( response ) =>
      {
        const { error } = response.data
        if ( error == true )
        {
          dispatch( tokenExp() );

        } else
        {
          console.log( "Success getProgramType  ========>", response );
          let data = response.data.data;
          let row = [];
          let index = 0
          data.map( ( data1 ) =>
          {
            index = index + 1
            let bData = {
              index: index,
              id: data1.id,
              program: data1.name,
            };
            row.push( bData );
            return data1;
          } );

          dispatch( getprogramByProgIdSuccess( { row } ) );
          return row;
        }
      } )
      .catch( ( error ) =>
      {
        dispatch( serverFailed() );
        return false;
        // console.log( "Error getProgramType========>", error );
      } );
    return docs;
  } catch ( e )
  {
    dispatch( serverFailed() );
    return false;
    // return console.error( e.message );
  }
};
export const getProgramTypeByName = ( { programType } ) => async ( dispatch ) =>
{
  try
  {
    const name = localStorage.getItem( "token" );
    const token = decodeData( name );
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    let docs = await axios
      .get( URL + '?filters={"delStatus": "1","_mode" : "EXACT"},{"name":"' + programType + '","_mode" : "EXACT"}', { headers } )
      .then( ( response ) =>
      {
        const { error } = response.data
        if ( error == true )
        {
          dispatch( tokenExp() );

        }
        console.log( "Success getProgramTypeByName  ========>", response.data.data.content );
        let data = response.data.data.content;
        dispatch( programTypeListByNameSuccess( { data } ) );
        return data;
      } )
      .catch( ( error ) =>
      {
        dispatch( serverFailed() );
        return false;
        // console.log( "Error getProgramType========>", error );
      } );
    return docs;
  } catch ( e )
  {
    dispatch( serverFailed() );
    return false;
    // return console.error( e.message );
  }
};

export const deleteProgramTypeData = () => async ( dispatch ) =>
{
  try
  {

    dispatch( programTypeListByNameFailure() );
  }
  catch ( e )
  {
    return console.error( e.message );
  }
};
export const getProgramTypeById = () => async ( dispatch ) =>
{
  try
  {

    const name = localStorage.getItem( "token" );
    const token = decodeData( name );
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    let docs = await axios
      .get( URL + "/1", { headers } )
      .then( ( response ) =>
      {
        const { error } = response.data
        if ( error == true )
        {
          dispatch( tokenExp() );

        }
        console.log( "Success With id getProgramTypeById ========>", response );
      } )
      .catch( ( error ) =>
      {
        dispatch( serverFailed() );
        return false;
        // console.log( "Error getProgramTypeById ========>", error.response.data );
      } );
    return docs;
  } catch ( e )
  {
    dispatch( serverFailed() );
    return false;
    // return console.error( e.message );
  }
};

export const saveProgramType =
  ( { programTypeData } ) =>
    async ( dispatch ) =>
    {
      try
      {
        let data = JSON.stringify( programTypeData );
        const name = localStorage.getItem( "token" );
        const token = decodeData( name );
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
          shouldrefresh: "true",
        };
        let docs = await axios
          .post( URL, data, { headers } )
          .then( ( response ) =>
          {
            const { error } = response.data
            if ( error == true )
            {
              dispatch( tokenExp() );

            }
            console.log( "Success With saveProgramType ========>", response.data );
            const { data } = response.data
            return data

          } )
          .catch( ( error ) =>
          {
            dispatch( serverFailed() );
            return false;
            console.log( "ErrorsaveProgramType ========>", error.response.data );
          } );
        return docs;
      } catch ( e )
      {
        dispatch( serverFailed() );
        return false;
        // return console.error( e.message );
      }
    };

export const deleteProgramTypeById =
  ( { programTypeId } ) =>
    async ( dispatch ) =>
    {
      try
      {
        const name = localStorage.getItem( "token" );
        const token = decodeData( name );
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
          shouldrefresh: "true",
        };
        await axios
          .delete( URL + "/" + programTypeId, { headers } )
          .then( ( response ) =>
          {
            console.log(
              "Success With deleteProgramTypeById ========>",
              response
            );
          } )
          .catch( ( error ) =>
          {
            return false;
            console.log( "Error deleteProgramTypeById ========>", error.response.data );
          } );
      } catch ( e )
      {
        return false;
        return console.error( e.message );

      }
    };
export const deleteProgList = () => async ( dispatch ) =>
{
  try
  {
    dispatch( progFetched() );
  }
  catch ( e )
  {
    return console.error( e.message );
  }
};
export const resetDelete = () => async ( dispatch ) =>
{
  try
  {
    dispatch( resetDeleteSuccess() );
  } catch ( e )
  {
    return console.error( e.message );
  }
};