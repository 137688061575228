import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { decodeData } from "../../components/Comman/Util/Base64";
import endpoints from "../../config/endpoints";
import { tokenExp } from "../Login/login.slice";

const initialState = {
  instituteCourse: [],
  instituteCourseData: [],
  instituteCourseDetails: {},
  isSave: false,
  isServerFail: false,
};
let URL = endpoints.instituteCourse;
const instituteCourseSlice = createSlice({
  name: "instituteCourse",
  initialState,
  reducers: {
    instituteCourseSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        instituteCourse: row,
      };
    },
    instituteCourseDetailsSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { data } = action.payload;
      return {
        ...state,
        instituteCourseDetails: data,
        isSave: true,
      };
    },
    instituteCourseDetailsFail: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        instituteCourseDetails: {},
        isSave: false,
      };
    },
    instituteCourseFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,

        instituteCourse: [],
      };
    },
    serverFailed: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: true,
      };
    },
    resetSaveSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: false,
      };
    },
  },
});

export const {
  instituteCourseSuccess,
  instituteCourseDetailsSuccess,
  instituteCourseDetailsFail,
  instituteCourseFailure,
  serverFailed,
  resetSaveSuccess,
} = instituteCourseSlice.actions;

export default instituteCourseSlice.reducer;

export const getInstituteCourse = () => async (dispatch) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);

    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };

    let docs = await axios
      .get(URL + '?filters={"delStatus": "1","_mode" : "EXACT"}', { headers })
      .then((response) => {
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        }
        console.log("Success get InstituteCourse  ========>", response);
        let data = response.data.data;

        let row = [];
        // let index=0
        // data.map((data1) => {
        //   index=index+1
        //   let bData = {
        //     index:index,
        //     id: data1.id,
        //     categoryName: data1.name,
        //     type:data1.type===0?"Original Category" :data1.type===1?"defence":data1.type===2?"handicap":data1.type===3?"TFWS":data1.type===4?"J&K":"EWS",
        //     isActive: data1.isActive === 1 ? "Active" : "Inactive",

        //   };
        //   row.push(bData);
        //
        // });
        dispatch(instituteCourseSuccess({ row }));
        return row;
      })
      .catch((error) => {
        dispatch(serverFailed());

        console.log("Error get InstituteCourse========>", error);
      });
    return docs;
  } catch (e) {
    dispatch(serverFailed());

    return console.error(e.message);
  }
};
export const resetInstituteCourseSave = () => async (dispatch) => {
  try {
    dispatch(instituteCourseDetailsFail());
  } catch (e) {
    return console.error(e.message);
  }
};
export const saveInstituteCourse =
  ({ instituteCourseData }) =>
  async (dispatch) => {
    try {
      let data = JSON.stringify(instituteCourseData);

      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      let docs = await axios
        .post(URL, data, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          console.log(
            "Success With saveInstituteCourse ========>",
            response.data.data
          );
          const data = response.data.data;
          dispatch(instituteCourseDetailsSuccess({ data }));
          return data;
        })
        .catch((error) => {
          dispatch(serverFailed());

          console.log(
            "Error saveInstituteCourse ========>",
            error.response.data
          );
        });
      return docs;
    } catch (e) {
      dispatch(serverFailed());

      return console.error(e.message);
    }
  };

//

export const deleteInstituteCourseById =
  ({ institutecourseId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      let docs = await axios
        .delete(URL + "/" + institutecourseId, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          console.log(
            "Success With deleteInstituteCourseById ========>",
            response
          );
          return response
        })
        .catch((error) => {
          dispatch(serverFailed());

          console.log(
            "Error deleteInstituteCourseById ========>",
            error.response.data
          );
        });
      return docs;
    } catch (e) {
      dispatch(serverFailed());

      return console.error(e.message);
    }
  };

export const resetSave = () => async (dispatch) => {
  try {
    dispatch(resetSaveSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
