import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import endpoints from "../../config/endpoints";
const initialState = {
  studentUser: [],
  isFetch: false,
  GetUserOTPmsg: "",
  getValidOtpMsg: "",
  isValidOtp: false,
  isUserGetOTP: false,
  errorMsg: "",
  isValidateOtpFail: false,
  error: "",
  isFail: false,
};
let URL = endpoints.studentUser;
let validateUserIdOtp = endpoints.validateUserIdOtp;

const studentUserSlice = createSlice({
  name: "studentUser",
  initialState,
  reducers: {
    studentUserSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        studentUser: row,
        isFetch: true,
      };
    },
    userGenrateOTPSuccess: (state = cloneDeep(initialState), action) => {
      const { message } = action.payload;
      return {
        ...state,
        GetUserOTPmsg: message,
        isUserGetOTP: true,
        errorMsg: "",
        isValidateOtpFail: false,
      };
    },
    studentUserFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        studentUser: [],
        isFetch: false,
      };
    },
    userValidateOTPFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        errorMsg: "Invalid otp",
        isValidateOtpFail: true,
      };
    },
    userValidateOTPSuccess: (state = cloneDeep(initialState), action) => {
      const { message } = action.payload;

      return {
        ...state,
        getValidOtpMsg: message,
        isValidOtp: true,
        isValidateOtpFail: false,
        errorMsg: "",
      };
    },
    userGenerateOTPFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error: "Inavlid Email Id Or Date Of birth",
        isFail: true,
      };
    },
    userValidateOtpDetailsFail: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        errorMsg: "",
        isValidateOtpFail: false,
      };
    },
    userGenerateOtpDetailsFail: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error: "",
        isFail: false,
      };
    },
    personRegistrationSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        studentUser: row,
        isFetch: true,
      };
    },
    personRegistrationSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        studentUser: [],
        isFetch: false,
      };
    },
  },
});

export const {
  studentUserSuccess,
  userGenrateOTPSuccess,
  userGenerateOTPFailure,
  studentUserFailure,
  userValidateOTPFailure,
  userValidateOTPSuccess,
  userGenerateOtpDetailsFail,
  userValidateOtpDetailsFail,
} = studentUserSlice.actions;

export default studentUserSlice.reducer;

export const savestudentUser =
  ({ studentUserData }) =>
  async (dispatch) => {
    try {
      let data = JSON.stringify(studentUserData);

      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      };
      await axios
        .post(URL, data, { headers })
        .then((response) => {
          console.log("Success With saveReservCat ========>", response.data);
          const { data } = response.data;
          return data;
        })
        .catch((error) => {
          console.log("ErrorsaveReservCat ========>", error.response.data);
        });
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getGenerateOTP =
  ({ emailId, dob, type }) =>
  async (dispatch) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      };
      const response = await axios
        .get(
          URL +
            "emailId=" +
            emailId +
            "&dob=" +
            dob +
            "&type=" +
            type +
            "&baseUrl=" +
            window.location.host
        )
        .then((response) => {
          console.log("Success With OTP Send ========>", response.data);
          const { message } = response.data.data;
          dispatch(userGenrateOTPSuccess({ message }));
          return true;
        })
        .catch((error) => {
          console.log("Error With OTP Send ========>", error.response.data);
          dispatch(userGenerateOTPFailure());
          return false;
        });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getValidateOTP =
  ({ emailId, enterOtp }) =>
  async (dispatch) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      };
      const response = await axios
        .get(validateUserIdOtp + "emailId=" + emailId + "&otp=" + enterOtp)
        .then((response) => {
          console.log("Success With id validate OTP ========>", response);
          const { message } = response.data.data;
          dispatch(userValidateOTPSuccess({ message }));
          return true;
        })
        .catch((error) => {
          console.log("Error With Validate OTP ========>", error.response.data);
          dispatch(userValidateOTPFailure());
          return false;
        });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const resetError = () => async (dispatch) => {
  try {
    dispatch(userGenerateOtpDetailsFail());
  } catch (e) {
    return console.error(e.message);
  }
};

export const resetOtpError = () => async (dispatch) => {
  try {
    dispatch(userValidateOtpDetailsFail());
  } catch (e) {
    return console.error(e.message);
  }
};
