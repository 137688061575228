import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { decodeData } from "../../components/Comman/Util/Base64";
import endpoints from "../../config/endpoints";
import { tokenExp } from "../Login/login.slice";
const initialState = {
  scheduleFormList: [],
  scheduleFormData: [],
  isFetchList: false,
  isFetchData: false,
  error:false,
  isDelete: false,
  isServerFail:false,
};
let URL = endpoints.addcapround;
let URL1 = endpoints.scheduleform;
let URL2 = endpoints.generateSeatAllotURL;

const scheduleFormSlice = createSlice({
  name: "meritlist",
  initialState,
  reducers: {
    scheduleFormListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        scheduleFormList: row,
        isFetchList: true,
      };
    },
    scheduleFormDataSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        scheduleFormData: row,
        isFetchData: true,
      };
    },
    scheduleFormListFailure: (state = cloneDeep(initialState), action) => {
      
      return {
        ...state,
        isFetchList: false,
        scheduleFormList: [],
      };
    },

    serverFailed: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: true,
        error: false,
        isDelete:false,
        isSave:false,
      };
    },
    resetDeleteSuccess : (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error:false,
        isDelete: false,
        isSave: false,
        isServerFail: false,

      };
    },

    scheduleFormDeleteSuccess:  (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error:false,
        isDelete: true,
      };
    },
  },
});

export const {
    scheduleFormListSuccess,
    scheduleFormListFailure,
    scheduleFormDataSuccess,
    serverFailed,
    resetDeleteSuccess,scheduleFormDeleteSuccess
} = scheduleFormSlice.actions;

export default scheduleFormSlice.reducer;

export const getscheduleFormList = ({capId}) => async (dispatch) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get(URL + "/"+capId+"/round-schedule", { headers })
      .then((response) => {
        
        const {error}=response.data
        if(error==true){
          dispatch(tokenExp());
          
        }
        else{
          let {data} = response.data;
          console.log(data)
          let row = [];
          let index = 0;
             data.map((data1) => {
               index=index+1
               let bData = {
                 index: index,
                 applicationRoundId: data1.applicationRound.id,
                 id: data1.id,
                 name: data1.name,
                 reservationApplicable:data1.reservationApplicable,
                 seatAllotmentStatus:data1.seatAllotmentStatus,
                 waitingListCount:data1.waitingListCount,
               };
              row.push(bData);
             });
          dispatch(scheduleFormListSuccess({ row }));
          return row;
        } 
      })

      .catch((error) => {
        console.log("Error getbranch========>", error);
        dispatch(serverFailed());
        return false;
      });
      return doc;
  } catch (e) {
    dispatch(serverFailed());
    return false;
  }
};

export const saveScheduleForm =
  ({  scheduleData }) =>
  async (dispatch) => {
    try {
      let data = JSON.stringify(scheduleData);
      
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .post(URL1, data, { headers })
        .then((response) => {
          const {error}=response.data
          if(error==true){
            dispatch(tokenExp());
            
          }
          else{
          console.log("Success With saveappli ========>", response);
         // const { row } = response.data.data;
          //dispatch(scheduleFormDataSuccess({ row }));
          }
          return data;
        })
        .catch((error) => {
          dispatch(serverFailed());
          console.log("Errorsaveappli ========>", error.response.data);
          return false;
        });
        return doc
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

  export const deleteScheduleById =
  ({ scheduleId }) =>
    async (dispatch) => {
      try {
        
        const name = localStorage.getItem("token");
        const token = decodeData(name);
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
          shouldrefresh: "true",
        };
        await axios
          .delete(URL1 + "/" + scheduleId, { headers })
          .then((response) => {
            const {error}=response.data
            if(error==true){
              dispatch(tokenExp());
              
            }else{
            dispatch(scheduleFormDeleteSuccess());
          }
            console.log("Success With deleteScheduleById ========>", response);
          })
          .catch((error) => {
            console.log("Error deleteScheduleById ========>", error.response.data);
            dispatch(serverFailed());
            return false;
          });
          
      } catch (e) {
        dispatch(serverFailed());
        return false;
      }
    };

    export const generateSeatAllotment =
  ({ scheduleId }) =>
    async (dispatch) => {
      try {
        
        const name = localStorage.getItem("token");
        const token = decodeData(name);
      
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
          shouldrefresh: "true",
        };
        console.log(URL2 + "?scheduleId=" + scheduleId)
       const doc= await axios
          .post(URL2 + "?scheduleId=" + scheduleId,{}, { headers })
          .then((response) => {
            const {error}=response.data
            if(error==true){
              dispatch(tokenExp());
              
            }
            else{
              return response
            }
            console.log("Success With generateSeatAllotment ========>", response);
         
          })
          .catch((error) => {
            console.log("Error generateSeatAllotment ========>", error.response.data);
            dispatch(serverFailed());
            return false;
          });
          return doc;
      } catch (e) {
        dispatch(serverFailed());
        return false;
      }
    };

    export const resetDelete = () => async (dispatch) => {
      try {
        dispatch(resetDeleteSuccess());
      } catch (e) {
        return console.error(e.message);
      }
    };