export const UserActionTypes = {
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGOUT: 'USERS_LOGOUT',
};

export function login(user) { 
    return { type: UserActionTypes.LOGIN_SUCCESS, user } 
}

export function logout() { 
    console.log('reset redux store')
    return { type: UserActionTypes.LOGOUT } 
}