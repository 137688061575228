import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid, Paper } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import Alert from "../../Alert/Alert";
import { getAlert } from "../../Alert/alert.slice";
import {
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "../../components/Comman/IconButton";
import DynamicTable from "../../components/Comman/MaterialUIRTTable";
import { DirectionSnackbar } from "../../components/Comman/SnakBarCompo";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
import { Loading1 } from "../../components/Loading1";
import Search from "../../components/Search";
import * as myConstClass from "../../config/messageconstant";
import { logOut } from "../Login/login.slice";
import { downloadFile } from "../UploadFile/file.slice";
import {
  deleteNewsDocNotificationById,
  filterNewsDocNotificationData,
  getNewsDocNotification,
  getNewsDocNotificationById,
  resetDelete,
  resetSave,
  saveNewsDocNotification,
} from "./newsDocNotification.slice";
import NewsDocNotificationExcelData from "./NewsDocNotificationExcelData";
import NewsDocNotificationMasterForm from "./NewsDocNotificationMasterForm";
import ViewNewsDocNotification from "./ViewNewsDocNotification";
const breakpoint = 600;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    title: "Description",
    name: "description",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    title: "Type",
    name: "type",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    title: "Show Label",
    name: "showLabel",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    title: "Label Name",
    name: "labelName",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    title: "Order No",
    name: "orderNo",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "right",
  },
  {
    title: "Status",
    name: "isActive",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    title: "Action",
    name: "action",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "right",
    width: 50,
  },
];

const NewsDocNotification = ({
  getNewsDocNotification,
  downloadFile,
  resetSave,
  saveNewsDocNotification,
  deleteNewsDocNotificationById,
  newsDocNotificationList,
  filterNewsDocNotificationData,
  loginUser,
  logOut,
  getAlert,
  resetDelete,
  files,
}) => {
  const [isEdit, setEdit] = useState(false);
  const [status, setStatus] = useState(false);
  const [del, setDel] = useState(false);
  const [save, setSave] = useState(false);
  const [snakMsg, setSnakMsg] = useState("");
  const [editRow, setEditRow] = useState([]);
  const [viewRow, setViewRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteropen, setFilterOpen] = useState(false);
  const [openSnak, setOpenSnak] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filteredList, setfilterList] = React.useState([
    "Sr. No.",
    "Description",
    "Type",
    "Show Label",
    "Label Name",
    "Order No",
    "Status",
  ]);
  const [newsDocList, setNewsDocList] = React.useState([
    {
      title: "Sr. No.",
      name: "index",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      title: "Description",
      name: "description",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      title: "Type",
      name: "type",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: 50,
    },
    {
      title: "Show Label",
      name: "showLabel",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      title: "Label Name",
      name: "labelName",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: 200,
    },
    {
      title: "Order No",
      name: "orderNo",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      title: "Status",
      name: "isActive",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      title: "Action",
      name: "action",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
  ]);

  const [breadCrumName, setBreadCrum] = useState(
    " News Doc Notification Master "
  );
  const [width, setWidth] = React.useState(window.innerWidth);
  const rowStatus = async (id) => {
    swal(myConstClass.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        const viewRow = newsDocNotificationList?.newsDocNotification.filter(
          (row) => row.id === id
        );
        let trustId = localStorage.getItem("trustId");

        let newsNotificationData = {
          id: viewRow[0].id,
          documentName: viewRow[0].documentName,
          showOnScreen: viewRow[0].showOnScreen,
          showLable: viewRow[0].showLabel === "Yes" ? "1" : "0",
          lableName: viewRow[0].labelName,
          description: viewRow[0].description,
          type:
            viewRow[0].type == "Online Admission"
              ? 0
              : viewRow[0].type == "Latest News"
              ? 1
              : viewRow[0].type == "Notification"
              ? 2
              : "",
          orderNo: viewRow[0].orderNo,
          isActive: viewRow[0].isActive === "Active" ? "0" : "1",
          trust: {
            id: trustId,
          },
          delStatus: 1,
        };

        resetSave();

        setStatus(true);
        saveNewsDocNotification({
          newsDocNotificationData: newsNotificationData,
        }).then((response) => {
          if (!response) {
            getAlert({ message: myConstClass.serverMsg });
          } else {
            setStatus(false);
            setOpenSnak(true);
            setSnakMsg(myConstClass.activeStatus);
            getNewsDocNotification().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
          }
        });
      }
    });
  };
  const getRowID = async (id) => {
    setViewRow([]);
    const editedRow = newsDocNotificationList?.newsDocNotification.filter(
      (row) => row.id === id
    );

    if (editedRow.length > 0) {
      // downloadFile({ file: editedRow[0].documentName }).then(() => {
      //   setEdit(true);
      // });
      setEdit(true);
      setEditRow([]);
      return await setEditRow(editedRow);
    }

    return setEditRow([]);
  };
  const getRowData = async (id) => {
    setOpen(true);
    const viewRow = newsDocNotificationList?.newsDocNotification.filter(
      (row) => row.id === id
    );
    if (viewRow.length > 0) {
      return await setViewRow(viewRow);
    }
  };
  const data = (instData) => {
    resetSave();
    setSave(true);
    saveNewsDocNotification({ newsDocNotificationData: instData }).then(() => {
      setOpenSnak(true);
      if (instData.id != null) {
        setSnakMsg(myConstClass.updateMsg);
      } else {
        setSnakMsg(myConstClass.saveMsg);
      }
      setSave(false);
      getNewsDocNotification();
    });

    setEdit(false);
    setEditRow([]);
  };

  const saveAndNextData = (data) => {
    resetSave();
    setSave(true);
    saveNewsDocNotification({ newsDocNotificationData: data }).then(() => {
      setOpenSnak(true);
      if (data.id != null) {
        setSnakMsg(myConstClass.updateMsg);
      } else {
        setSnakMsg(myConstClass.saveMsg);
      }
      setSave(false);
      getNewsDocNotification();
    });

    setEdit(true);
    setEditRow([]);
  };
  useEffect(() => {
    getNewsDocNotification().then((respo) => {
      if (!respo) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
  });
  const startEdit = () => {
    setEdit(true);
    let t = localStorage.getItem("token");
  };
  const breadCrum = (prevBreadCrum) => {
    setBreadCrum(breadCrumName + prevBreadCrum);
  };
  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setDel(true);
        deleteNewsDocNotificationById({ newsDocNotificationId: id }).then(
          (response) => {
            if (!response) {
              getAlert({ message: myConstClass.serverMsg });
            } else {
              setOpenSnak(true);
              setSnakMsg(myConstClass.deleteMsg);
              setDel(false);
              getNewsDocNotification().then((respo) => {
                if (!respo) {
                  getAlert({ message: myConstClass.serverMsg });
                }
              });
            }
          }
        );
      }
    });
  };

  const newsDocNotificationFilteredData =
    newsDocNotificationList?.newsDocNotification.filter(
      (item) =>
        item.description.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.type === parseInt(searchValue) ||
        item.showLabel === parseInt(searchValue) ||
        item.orderNo === parseInt(searchValue) ||
        item.labelName === parseInt(searchValue) ||
        item.isActive.toLowerCase() === searchValue
    );
  const filteredRows = (searchvalue) => {
    setSearchValue(searchvalue);
    const filteredRow = newsDocNotificationList?.newsDocNotification.filter(
      (row) => {
        let description = row?.description;
        let type = row?.type;
        let showLabel = row?.showLabel;
        let orderNo = row?.orderNo;
        let labelName = row?.labelName;
        let isActive = row?.isActive;
        return (
          description.toLowerCase().includes(searchvalue.toLowerCase()) ||
          type === parseInt(searchvalue) ||
          showLabel === parseInt(searchvalue) ||
          orderNo === parseInt(searchvalue) ||
          labelName === parseInt(searchvalue) ||
          isActive.toLowerCase() === searchvalue
        );
      }
    );

    if (
      newsDocNotificationList?.newsDocNotification.length ===
      filteredRows.length
    ) {
      filterNewsDocNotificationData({ row: filteredRow, flag: false });
    } else {
      filterNewsDocNotificationData({ row: filteredRow, flag: true });
    }
  };
  const stopEdit = () => {
    setBreadCrum("   >   Master   >  News Doc Notification Master  ");
    setEditRow([]);
    setEdit(false);
  };

  const addFile = (file) => {};
  const closeFilter = () => {
    setNewsDocList([
      {
        title: "Sr. No.",
        name: "index",
        positionCenter: true,
        showInscreen: true,
        alignCenter: "center",
      },
      {
        title: "Description",
        name: "description",
        positionCenter: false,
        showInscreen: true,
        alignCenter: "left",
      },
      {
        title: "Type",
        name: "type",
        positionCenter: false,
        showInscreen: true,
        alignCenter: "left",
      },
      {
        title: "Show Label",
        name: "showLabel",
        positionCenter: false,
        showInscreen: true,
        alignCenter: "left",
      },
      {
        title: "Label Name",
        name: "labelName",
        positionCenter: false,
        showInscreen: true,
        alignCenter: "left",
      },
      {
        title: "Order No",
        name: "orderNo",
        positionCenter: false,
        showInscreen: true,
        alignCenter: "right",
      },
      {
        title: "Status",
        name: "isActive",
        positionCenter: true,
        showInscreen: true,
        alignCenter: "left",
      },
      {
        title: "Action",
        name: "action",
        positionCenter: false,
        showInscreen: true,
        alignCenter: "right",
      },
    ]);
    setfilterList([
      "Description",
      "Type",
      "Show Label",
      "Label Name",
      "Order No",
      "Status",
      // "Action"
    ]);
    setFilterOpen(false);
  };
  // const handleChange = ( event ) =>
  // {
  //   setFilterOpen( true );
  //   const {
  //     target: { value },
  //   } = event;
  //   if ( value.length > filteredList.length )
  //   {
  //     const a = value.map( ( value ) =>
  //     {
  //       const trust = names.filter( ( row ) => row.title === value );
  //       const trust1 = [ ...newsDocList, trust ];

  //       return setNewsDocList( trust1 );
  //     } );
  //   } else
  //   {
  //     let difference = filteredList.filter( ( x ) => !value.includes( x ) );
  //     const newsList = newsDocList.filter( ( data ) =>
  //     {
  //       return data[ 0 ].title !== difference[ 0 ];
  //     } );
  //     setNewsDocList( newsList );
  //   }

  //   setfilterList( typeof value === "string" ? value.split( "," ) : value );

  //   if ( value.length === 0 )
  //   {
  //     setFilterOpen( false );
  //   }
  // };

  const handleChange = (event) => {
    setFilterOpen(true);
    const {
      target: { value },
    } = event;
    if (value.length > filteredList.length) {
      let row = [];
      const a = newsDocList.map((qualiData) => {
        let trust = value.filter((namesRow) => namesRow === qualiData.title);

        if (trust.length !== 0) {
          console.log("shraddha");
          let qData = {
            ...qualiData,
            showInscreen: true,
          };
          row.push(qData);
        } else {
          let qData = {
            ...qualiData,
            showInscreen: false,
          };
          row.push(qualiData);
        }

        return setNewsDocList(row);
      });
      console.log(row);
    } else {
      let row = [];
      let difference = filteredList.filter((x) => !value.includes(x));
      const qualificationList = newsDocList.filter((data) => {
        if (data.title === difference[0]) {
          let QualiData = {
            ...data,
            showInscreen: false,
          };
          row.push(QualiData);
        } else {
          row.push(data);
        }
      });
      console.log("row");
      console.log(row);
      setNewsDocList(row);
    }

    setfilterList(typeof value === "string" ? value.split(",") : value);

    if (value.length === 0) {
      setFilterOpen(false);
    }
  };

  return (
    <>
      <div>
        <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
          <TitleAndBreadCrum
            title="News and Notification"
            breadCrumName={breadCrumName}
          />
        </Paper>
        <br />
        {/* {
          <DirectionSnackbar
            open={openSnak}
            severity="success"
            msg={snakMsg}
            closeSnak={setOpenSnak}
          />
        } */}

        {/* {newsDocNotificationList.isDelete &&
          newsDocNotificationList.error == true && (
            <Alert msg={myConstClass.deleteFailMsg} closeAlert={resetDelete} />
          )}
        {newsDocNotificationList.isServerFail && (
          <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />
        )}
        {files.isServerFail && (
          <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />
        )} */}

        {newsDocNotificationList.isDelete &&
        newsDocNotificationList.error == false ? (
          <DirectionSnackbar
            open={openSnak}
            severity="success"
            msg={myConstClass.deleteMsg}
            closeSnak={setOpenSnak}
          />
        ) : newsDocNotificationList.isSave ? (
          <DirectionSnackbar
            open={openSnak}
            severity="success"
            msg={snakMsg}
            closeSnak={setOpenSnak}
          />
        ) : (
          ""
        )}
        <Paper sx={{ p: 2, pt: 4, borderRadius: 2 }} elevation={0}>
          <div>
            {viewRow.length >= 0 && editRow.length >= 0 && isEdit && (
              <NewsDocNotificationMasterForm
                OnSave={data}
                isEdit={editRow}
                onCancel={stopEdit}
                breadCrum={breadCrum}
                handleFile={addFile}
                saveAndNext={saveAndNextData}
              />
            )}
          </div>
          {open && (
            <ViewNewsDocNotification
              data={viewRow}
              openparam={open}
              closeView={setOpen}
            />
          )}
          <Grid>
            {!isEdit && (
              <div>
                {width > breakpoint ? (
                  <>
                    <Box
                      sx={{ mr: 2 }}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Box sx={{ m: -1, width: 300 }}>
                        <Search
                          filteredRows={filteredRows}
                          searchValue={searchValue}
                        />
                      </Box>
                      <Box>
                        <FormControl sx={{ m: -1, width: 250 }}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={filteredList}
                            name="sdf"
                            size="small"
                            onChange={handleChange}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {names.map((name) => {
                              return (
                                <MenuItem key={name.name} value={name.title}>
                                  <Checkbox
                                    checked={
                                      filteredList.indexOf(name.title) > -1
                                    }
                                  />
                                  <ListItemText primary={name.title} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>

                        {filteropen && (
                          <>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <NewsDocNotificationExcelData
                              csvData={newsDocNotificationFilteredData}
                              newsList={newsDocList}
                              fileName="News Doc Notification Master"
                              type="pdf"
                            />
                          </>
                        )}
                        {filteropen && (
                          <>
                            &nbsp;&nbsp;
                            <NewsDocNotificationExcelData
                              csvData={newsDocNotificationFilteredData}
                              newsList={newsDocList}
                              fileName="News Doc Notification Master"
                              type="excel"
                            />
                          </>
                        )}

                        {filteropen && (
                          <>
                            &nbsp;&nbsp;
                            <FontAwesomeIconCompo
                              className="fa-times-circle"
                              color="primary"
                              title="Cancel"
                              baseClassName="fas"
                              onClick={closeFilter}
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <NewsDocNotificationExcelData
                              csvData={newsDocNotificationFilteredData}
                              newsList={newsDocList}
                              fileName="News Doc Notification Master"
                              type="pdf"
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;
                            <NewsDocNotificationExcelData
                              csvData={newsDocNotificationFilteredData}
                              newsList={newsDocList}
                              fileName="News Doc Notification Master"
                              type="excel"
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;&nbsp;
                            <MyComponentWithIconProps
                              statusImage={AddCircleIcon}
                              color="primary"
                              title=""
                              fontSize="large"
                              sx={{ m: -1 }}
                              onClick={startEdit}
                            />
                          </>
                        )}
                      </Box>
                    </Box>
                    {/* { title: "Description", name: "description" },
  { title: "Type", name: "type" },
  { title: "Show Label", name: "showLabel" },
  { title: "Label Name", name: "labelName" },
  { title: "Order No", name: "orderNo" }, 
  { title: "Status", name: "isActive" },*/}
                    <Box sx={{ mt: 2, p: 1.5 }}>
                      <DynamicTable
                        data={newsDocNotificationFilteredData}
                        tableHead={newsDocList}
                        rowEdit={getRowID}
                        rowDelete={deleteData}
                        rowStatus={rowStatus}
                        showHeadDelete={true}
                        showHeadEdit={true}
                      />
                      {/* <NewsDocNotificationTable
                        filteredColumns={newsDocList}
                        data={newsDocNotificationFilteredData}
                        rowEdit={getRowID}
                        rowView={getRowData}
                        rowDelete={deleteData}
                        rowStatus={rowStatus}
                      /> */}
                    </Box>
                  </>
                ) : (
                  <>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      rowSpacing={2}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid xs={12} sm={12} md={12} lg={12} rowSpacing={2}>
                        <Search
                          filteredRows={filteredRows}
                          searchValue={searchValue}
                        />
                      </Grid>
                      <Grid xs={12} sm={12} md={12} lg={12} sx={{ m: 1 }}>
                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={filteredList}
                            name="sdf"
                            size="small"
                            onChange={handleChange}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {names.map((name) => {
                              return (
                                <MenuItem key={name.name} value={name.title}>
                                  <Checkbox
                                    checked={
                                      filteredList.indexOf(name.title) > -1
                                    }
                                  />
                                  <ListItemText primary={name.title} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <Grid
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          sx={{ m: 1, textAlign: "right" }}
                        >
                          {filteropen && (
                            <>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <NewsDocNotificationExcelData
                                csvData={newsDocNotificationFilteredData}
                                newsList={newsDocList}
                                fileName="News Doc Notification Master"
                                type="pdf"
                              />
                            </>
                          )}
                          {filteropen && (
                            <>
                              &nbsp;&nbsp;
                              <NewsDocNotificationExcelData
                                csvData={newsDocNotificationFilteredData}
                                newsList={newsDocList}
                                fileName="News Doc Notification Master"
                                type="excel"
                              />
                            </>
                          )}

                          {filteropen && (
                            <>
                              &nbsp;&nbsp;
                              <FontAwesomeIconCompo
                                className="fa-times-circle"
                                color="primary"
                                title="Cancel"
                                baseClassName="fas"
                                onClick={closeFilter}
                              />
                            </>
                          )}

                          {!filteropen && filteredList.length >= 0 && (
                            <>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <NewsDocNotificationExcelData
                                csvData={newsDocNotificationFilteredData}
                                newsList={newsDocList}
                                fileName="News Doc Notification Master"
                                type="pdf"
                              />
                            </>
                          )}

                          {!filteropen && filteredList.length >= 0 && (
                            <>
                              &nbsp;&nbsp;
                              <NewsDocNotificationExcelData
                                csvData={newsDocNotificationFilteredData}
                                newsList={newsDocList}
                                fileName="News Doc Notification Master"
                                type="excel"
                              />
                            </>
                          )}

                          {!filteropen && filteredList.length >= 0 && (
                            <>
                              &nbsp;&nbsp;&nbsp;
                              <MyComponentWithIconProps
                                statusImage={AddCircleIcon}
                                color="primary"
                                title=""
                                fontSize="large"
                                sx={{ m: -1 }}
                                onClick={startEdit}
                              />
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Box sx={{ mt: 2, p: 1.5 }}>
                      <DynamicTable
                        data={newsDocNotificationFilteredData}
                        tableHead={newsDocList}
                        rowEdit={getRowID}
                        rowDelete={deleteData}
                        rowStatus={rowStatus}
                        showHeadDelete={true}
                        showHeadEdit={true}
                      />
                      {/* <NewsDocNotificationTable
                        filteredColumns={newsDocList}
                        data={newsDocNotificationFilteredData}
                        rowEdit={getRowID}
                        rowView={getRowData}
                        rowDelete={deleteData}
                        rowStatus={rowStatus}
                      /> */}
                    </Box>
                  </>
                )}
              </div>
            )}
          </Grid>

          {!newsDocNotificationList?.isServerFail &&
          !newsDocNotificationList?.isFetch ? (
            <Loading1 size={50} />
          ) : status && !newsDocNotificationList?.isSave ? (
            <Loading1 size={50} />
          ) : save && !newsDocNotificationList?.isSave ? (
            <Loading1 size={50} />
          ) : del ? (
            <Loading1 size={50} />
          ) : (
            ""
          )}
        </Paper>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  newsDocNotificationList: state.newsDocNotification,
  isFetch: state.newsDocNotification,
  common: state.common,
  loginUser: state.user,
  files: state.file,
});

const mapDispatchToProps = {
  getNewsDocNotification,
  getNewsDocNotificationById,
  saveNewsDocNotification,
  deleteNewsDocNotificationById,
  filterNewsDocNotificationData,
  downloadFile,
  resetSave,
  getAlert,
  logOut,
  resetDelete,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsDocNotification);
