import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Container } from "@material-ui/core";
import { Divider, FormHelperText, Grid, Paper, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Buttton";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../components/Comman/Label";
import { onlyNumber } from "../../components/Comman/Util/Validations";
import * as myConstClass from "../../config/messageconstant";
import { getClass } from "../Class/class.slice";
import { getCategoryType } from "../CategoryMaster/category.slice";
import { getAlert } from "../../Alert/alert.slice";

let docs;
class OnlineApplicationForm extends React.Component {
  state = {
    id: 0,
    title: "",
    displayTo: "",
    displayFrom: new Date(),
    launchFrom: "",
    meritListDate: "",
    meritListType:'',
    shortSumm: "",
    detailsOfDescription: "",
    isApplicable: 1,
    type: "",
    fees: "",
    instructionPrinted: 1,
    instructions: "",
    highlightlabel: 1,
    labelText: "",
    showNotificationOnScreen: 1,
    byMinority: 1,
    minority: "",
    formErrors: [],
  };
  changeHandler = (event) => {
    const { name, value } = event.target;
    console.log(value);
    if (event.target.value === "" || onlyNumber.test(event.target.value)) {
      this.setState({
        ...this.state,
        [name]: +value,
      });
    }
  };
  changedHandler = (event) => {
    const { name, value } = event.target;

    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  componentDidMount() {
    const { getClass, getCategoryType, appliData, getAlert } = this.props;
    getClass();
    getCategoryType();
    let date = new Date();
    if (
      appliData.applicationData !== "" &&
      appliData.applicationData.type >= 1
    ) {
      const lastDateArr = appliData.applicationData.lastDate.split("-");
      const lastDate = new Date(
        lastDateArr[2],
        lastDateArr[1] - 1,
        lastDateArr[0]
      );
      const startDateArr = appliData.applicationData.startDate.split("-");
      const startDate = new Date(
        startDateArr[2],
        startDateArr[1] - 1,
        startDateArr[0]
      );

      const meritListPublishedDateArr =
        appliData.applicationData.meritListPublishedDate.split("-");
      const meritListPublishedDate = new Date(
        meritListPublishedDateArr[2],
        meritListPublishedDateArr[1] - 1,
        meritListPublishedDateArr[0]
      );

      const applicationDateArr =
        appliData.applicationData.applicationDate.split("-");
      const applicationDate = new Date(
        applicationDateArr[2],
        applicationDateArr[1] - 1,
        applicationDateArr[0]
      );
      console.log(appliData.applicationData);
      this.setState({
        id: appliData.applicationData.id,
        title: appliData.applicationData.title,
        displayTo: lastDate,
        displayFrom: startDate,
        launchFrom: applicationDate,
        meritListDate: meritListPublishedDate,
        shortSumm: appliData.applicationData.shortSummary,
        detailsOfDescription: appliData.applicationData.detailedDescription,
        isApplicable: appliData.applicationData.paymentApplicable,
        type:
          appliData.applicationData.paymentApplicable === 0
            ? ""
            : appliData.applicationData.paymentType,
        fees:
          appliData.applicationData.paymentApplicable === 0
            ? ""
            : appliData.applicationData.amount,
        instructionPrinted: appliData.applicationData.instructionPrintOnChallan,
        instructions:
          appliData.applicationData.instructionPrintOnChallan === 1
            ? appliData.applicationData.paymentInstruction
            : "",
        highlightlabel: appliData.applicationData.highlightLable,
        labelText: appliData.applicationData.lableText,
        showNotificationOnScreen:
          appliData.applicationData.showNotificationOnscreen,
        // className: appliData.applicationData.yearId,
        meritListType: appliData.applicationData.meritListType,
        byMinority: appliData.applicationData.byMinority,
        minority: appliData.applicationData.minority,
      });
    } else {
      this.setState({
        displayTo: date,
        displayFrom: date,
        meritListDate: date,
        launchFrom: date,
        launchTo: date,
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.appliData.applicationData !==
      nextProps.appliData.applicationData
    ) {
      const lastDateArr =
        nextProps.appliData.applicationData.lastDate.split("-");
      const lastDate = new Date(
        lastDateArr[2],
        lastDateArr[1] - 1,
        lastDateArr[0]
      );
      const startDateArr =
        nextProps.appliData.applicationData.startDate.split("-");
      const startDate = new Date(
        startDateArr[2],
        startDateArr[1] - 1,
        startDateArr[0]
      );

      const meritListPublishedDateArr =
        nextProps.appliData.applicationData.meritListPublishedDate.split("-");
      const meritListPublishedDate = new Date(
        meritListPublishedDateArr[2],
        meritListPublishedDateArr[1] - 1,
        meritListPublishedDateArr[0]
      );

      const applicationDateArr =
        nextProps.appliData.applicationData.applicationDate.split("-");
      const applicationDate = new Date(
        applicationDateArr[2],
        applicationDateArr[1] - 1,
        applicationDateArr[0]
      );
      console.log(nextProps.appliData.applicationData);
      this.setState({
        id: nextProps.appliData.applicationData.id,
        title: nextProps.appliData.applicationData.title,
        displayTo: lastDate,
        displayFrom: startDate,
        launchFrom: applicationDate,
        meritListDate: meritListPublishedDate,
        shortSumm: nextProps.appliData.applicationData.shortSummary,
        detailsOfDescription:
          nextProps.appliData.applicationData.detailedDescription,
        isApplicable: nextProps.appliData.applicationData.paymentApplicable,
        type:
          nextProps.appliData.applicationData.paymentApplicable === 0
            ? ""
            : nextProps.appliData.applicationData.paymentType,
        fees:
          nextProps.appliData.applicationData.paymentApplicable === 0
            ? ""
            : nextProps.appliData.applicationData.amount,
        instructionPrinted:
          nextProps.appliData.applicationData.instructionPrintOnChallan,
        instructions:
          nextProps.appliData.applicationData.instructionPrintOnChallan === 1
            ? nextProps.appliData.applicationData.paymentInstruction
            : "",
        highlightlabel: nextProps.appliData.applicationData.highlightLable,
        labelText: nextProps.appliData.applicationData.lableText,
        showNotificationOnScreen:
          nextProps.appliData.applicationData.showNotificationOnscreen,
        // className: nextProps.appliData.applicationData.yearId,

        byMinority: nextProps.appliData.applicationData.byMinority,
        minority: nextProps.appliData.applicationData.minority,
      });
    }
  }
  handleFormValidation() {
    const {
      title,
      displayTo,
      displayFrom,
      shortSumm,
      isApplicable,
      type,
      labelText,
      highlightlabel,
      fees,
      instructionPrinted,
      launchFrom,
      instructions,
      // className,
      minority,
      byMinority,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (title === "") {
      formIsValid = false;
      formErrors["titleErr"] = myConstClass.titleMsg;
    }

    if (shortSumm === "") {
      formIsValid = false;
      formErrors["shortErr"] = myConstClass.shortSummMsg;
    }

    if (instructions === "" && instructionPrinted === 1) {
      formIsValid = false;
      formErrors["InstructionErr"] = myConstClass.instructionsMsg;
    }

    if (launchFrom !== "" && displayTo !== "" && launchFrom > displayTo) {
      formIsValid = false;
      formErrors["displayToErr"] = myConstClass.displaytoMsg;
    }
    if (!onlyNumber.test(fees) || fees === "") {
    }
    if (fees === "" && isApplicable === 1) {
      formIsValid = false;
      formErrors["feesErr"] = myConstClass.feesMsg;
    } else if (!onlyNumber.test(fees) && fees !== "" && isApplicable === 1) {
      formIsValid = false;
      formErrors["feesErr"] = myConstClass.feesValidMsg;
    }

    if (type === "" && isApplicable === 1) {
      formIsValid = false;
      formErrors["typeErr"] = myConstClass.typeMsg;
    }
    // if (className === "") {
    //   formIsValid = false;
    //   formErrors["classErr"] = myConstClass.classNameMsg;
    // }

    if ((minority === "" || minority === null) && byMinority === 1) {
      formIsValid = false;
      formErrors["minorityReligionErr"] = myConstClass.minorityReligionMsg;
    }

    if (labelText === "" && highlightlabel === 1) {
      formIsValid = false;
      formErrors["labelTextErr"] = myConstClass.labelTextMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  changeFromDate = (date) => {
    this.setState({
      displayFrom: date,
    });
  };

  changeToDate = (date) => {
    this.setState({
      displayTo: date,
    });
  };

  changeMeritFromDate = (mdate) => {
    this.setState({
      meritListDate: mdate,
    });
  };

  changeLaunchFromDate = (lfdate) => {
    this.setState({
      launchFrom: lfdate,
    });
  };

  changeLaunchToDate = (ltdate) => {
    this.setState({
      launchTo: ltdate,
    });
  };

  onChangedetails = (event, editor) => {
    const data = editor.getData();

    this.setState({ detailsOfDescription: data });
  };
  onChangeInstruc = (event, editor) => {
    const data = editor.getData();

    this.setState({ instructions: data });
  };
  submitHandler = (e) => {
    e.preventDefault();
    const {
      id,
      title,
      displayTo,
      displayFrom,
      launchFrom,
      meritListDate,
      shortSumm,
      detailsOfDescription,
      isApplicable,
      type,
      fees,
      instructionPrinted,
      instructions,
      highlightlabel,
      labelText,
      showNotificationOnScreen,
      // className,
      meritListType,
      byMinority,
      minority,
    } = this.state;
    console.log(showNotificationOnScreen);
    docs = {
      title: title,
      lastDate: moment(displayTo).format("DD-MM-YYYY"),
      startDate: moment(displayFrom).format("DD-MM-YYYY"),
      applicationDate: moment(launchFrom).format("DD-MM-YYYY"),
      meritListType:meritListType,
      meritListPublishedDate: moment(meritListDate).format("DD-MM-YYYY"),
      shortSummary: shortSumm,
      detailedDescription: detailsOfDescription,
      paymentApplicable: isApplicable,
      paymentType: type,
      amount: fees,
      isMeritListGenerated: "0",
      instructionPrintOnChallan: instructionPrinted,
      paymentInstruction: instructions,
      highlightLable: highlightlabel,
      lableText: labelText,
      showNotificationOnscreen: showNotificationOnScreen,
      // yearId: className,
      byMinority: byMinority,
      minority: minority,
      isActive: 1,
      type: 1,
    };
    if (id !== 0) {
      docs = {
        ...docs,
        id: id,
      };
    }

    if (this.handleFormValidation()) {
      this.props.OnSave(docs);
    }
  };
  ChangeHandlerSearch = (name, newValue) => {
    if(newValue!==null){
      this.setState({ [name]: newValue });
    }
    else{
      this.setState({ [name]: '' });
    }
  };
  render() {
    const {
      title,
      displayTo,
      displayFrom,
      launchFrom,
      meritListDate,
      meritListType,
      shortSumm,
      detailsOfDescription,
      isApplicable,
      type,
      fees,
      instructionPrinted,
      instructions,
      highlightlabel,
      labelText,
      showNotificationOnScreen,
      // className,
      byMinority,
      minority,
    } = this.state;
    const {
      titleErr,
      displayToErr,
      // classErr,
      typeErr,
      InstructionErr,
      feesErr,
      shortErr,
      labelTextErr,
      minorityReligionErr,
    } = this.state.formErrors;
    const { onCancel, classData, categoryList } = this.props;
    return (
      <>
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <form
            onSubmit={this.submitHandler}
            autoComplete="off"
            noValidate={true}
          >
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={2}
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Application Title : "
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="title"
                    size="small"
                    value={title}
                    onChange={this.changedHandler}
                    fullWidth
                    error={titleErr ? true : false}
                    helperText={titleErr ? titleErr : " "}
                  />
                </Grid>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={12}></Grid> */}
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={2}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={6}
                  xs={12}
                  md={6}
                  lg={6}
                  rowSpacing={1}
                  // columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Opening Date :"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <BasicDatePicker
                      value={launchFrom}
                      minDate={new Date()}
                      onChange={this.changeLaunchFromDate}
                      error={' '}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                  md={6}
                  lg={6}
                  rowSpacing={1}
                  // columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Closing Date :"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <BasicDatePicker
                      value={displayTo}
                      onChange={this.changeToDate}
                      minDate={new Date()}
                      error={displayToErr?myConstClass.displaytoMsg:' '}
                    />
                   
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={12}></Grid> */}
              
              <Grid
                item
                sm={6}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Merit List Date :"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <BasicDatePicker
                    value={meritListDate}
                    minDate={new Date()}
                    onChange={this.changeMeritFromDate}
                    error={' '} 
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
                md={6}
                lg={6}
                // rowSpacing={1}
                // columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Merit List Type :"
                  />
                </Grid>
                <Grid sx={{marginTop:-2}}item xs={12} sm={12} md={12}>
                <Select
                        labelId="type"
                        id="meritListType"
                        className="text-black"
                        name="meritListType"
                        value={meritListType}
                        onChange={this.changedHandler}
                        size="small"
                        fullWidth
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>

                        <MenuItem value="1">First In First Admission Wise</MenuItem>
                        <MenuItem value="0">Marks Wise</MenuItem>
                      </Select>
                </Grid>
              </Grid>
              {/* <Grid m={-1} item xs={12} sm={6} md={6}></Grid>
              <Grid item xs={12} sm={6} md={12}></Grid> */}
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={1}
                columnSpacing={2}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Short Summary : "
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="shortSumm"
                    size="small"
                    value={shortSumm}
                    onChange={this.changedHandler}
                    fullWidth
                    error={shortErr ? true : false}
                    helperText={shortErr ? shortErr : " "}
                  />
                </Grid>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={12} /> */}
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={1}
                columnSpacing={2}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Detailed Description :"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={detailsOfDescription}
                    onReady={(editor) => {
                      editor && editor.setData(detailsOfDescription);
                    }}
                    onChange={(event, editor) =>
                      this.onChangedetails(event, editor)
                    }
                    onBlur={(editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(editor) => {
                      console.log("Focus.", editor);
                    }}
                  />
                </Grid>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={12} />
              <Grid item xs={12} sm={6} md={12} /> */}
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 20 }}
                  label="Payment Details"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Divider textAlign="left"></Divider>
              </Grid>
              <Grid item xs={12} sm={6} md={12} />
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={1}
                columnSpacing={2}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Payment Details Below :"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="isApplicable"
                      id="isApplicable"
                      name="isApplicable"
                      value={isApplicable}
                      defaultChecked
                      onChange={this.changeHandler}
                      size="small"
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              {isApplicable === 1 && (
                <>
                  <Grid
                    item
                    sm={6}
                    xs={12}
                    md={6}
                    lg={6}
                    rowSpacing={1}
                    // columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Fees :"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        disabled={isApplicable === 0}
                        color="primary"
                        name="fees"
                        size="small"
                        value={fees}
                        onChange={this.changedHandler}
                        fullWidth
                        error={feesErr ? true : false}
                        helperText={feesErr ? feesErr : " "}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    xs={12}
                    md={6}
                    lg={6}
                    // rowSpacing={1}
                    // columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Type :"
                      />
                    </Grid>
                    <Grid sx={{marginTop:-2}} item xs={12} sm={12} md={12}>
                      <Select
                        labelId="type"
                        id="type"
                        className="text-black"
                        name="type"
                        value={type}
                        onChange={this.changedHandler}
                        size="small"
                        fullWidth
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>

                        <MenuItem value="1">Online</MenuItem>
                        <MenuItem value="0">Manual</MenuItem>
                      </Select>
                      {typeErr && (
                        <FormHelperText error>{typeErr} </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
              {/* <Grid item xs={12} sm={6} md={12}></Grid> */}
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={1}
                columnSpacing={2}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Insturction To Be Printed On Challan  :"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="instructionPrinted"
                      id="instructionPrinted"
                      name="instructionPrinted"
                      value={instructionPrinted}
                      defaultChecked
                      onChange={this.changeHandler}
                      size="small"
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="Yes"
                        defaultChecked
                      />
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={12} />
              <Container> */}

              {instructionPrinted === 1 ? (
                <>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    rowSpacing={1}
                    columnSpacing={2}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Instructions :"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <CKEditor
                        editor={ClassicEditor}
                        data={instructions}
                        onReady={(editor) => {
                          editor && editor.setData(instructions);
                        }}
                        onChange={(event, editor) =>
                          this.onChangeInstruc(event, editor)
                        }
                        onBlur={(editor) => {
                          console.log("Blur.", editor);
                        }}
                        onFocus={(editor) => {
                          console.log("Focus.", editor);
                        }}
                      />
                      <FormHelperText error>{InstructionErr} </FormHelperText>
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={12} /> */}
                </>
              ) : null}
              {/* </Container> */}
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={1}
                columnSpacing={2}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={4} md={4}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Highlight Label :"
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="highlightlabel"
                      id="highlightlabel"
                      name="highlightlabel"
                      value={highlightlabel}
                      defaultChecked
                      onChange={this.changeHandler}
                      size="small"
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 12, color: "#808080" }}
                  label=" Note: After clicking yes entered label will displayed on home page."
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={1}
                columnSpacing={2}
                container
                justifyContent="space-evenly"
              >
                {highlightlabel == 1 ? (
                  <>
                    <Grid item xs={12} sm={4} md={4}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Label Text :"
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} md={8}>
                      <TextField
                        disabled={highlightlabel == 0}
                        color="primary"
                        name="labelText"
                        size="small"
                        value={labelText}
                        onChange={this.changedHandler}
                        fullWidth
                        error={labelTextErr ? true : false}
                        helperText={labelTextErr ? labelTextErr : " "}
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={1}
                columnSpacing={2}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={4} md={4}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Displayed On Home Page:"
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="showNotificationOnScreen"
                      id="showNotificationOnScreen"
                      name="showNotificationOnScreen"
                      value={showNotificationOnScreen}
                      defaultChecked
                      onChange={this.changeHandler}
                      size="small"
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 12, color: "#808080" }}
                  label=" Note: After clicking yes application form will be displayed on home page."
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={1}
                columnSpacing={2}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={4} md={4}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Do you want to declare form with minority :"
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="byMinority"
                      id="byMinority"
                      name="byMinority"
                      value={byMinority}
                      defaultChecked
                      onChange={this.changeHandler}
                      size="small"
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={1}
                columnSpacing={2}
                container
                justifyContent="space-evenly"
              >
                {byMinority === 1 ? (
                  <>
                    <Grid item xs={12} sm={4} md={4}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Minority Religion :"
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} md={8}>
                      <AutoComplete
                        keyColName={"id"}
                        value={minority}
                        name={"minority"}
                        options={categoryList?.categoryList}
                        onChange={this.ChangeHandlerSearch}
                      />
                      <FormHelperText error>
                        {minorityReligionErr}
                      </FormHelperText>
                    </Grid>
                  </>
                ) : null}
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
                md={6}
                lg={6}
                // rowSpacing={1}
                // columnSpacing={2}
                container
                justifyContent="space-evenly"
              ></Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={2}
              container
              justifyContent="right"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={1}
                lg={1}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name=" Cancel"
                  fullWidth={true}
                  onClick={onCancel}
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={2.5}
                lg={2.5}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  fullWidth={true}
                  variant="contained"
                  name="Save and Proceed"
                />
              </Grid>
            </Grid>
          </form>
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  classData: state.class,
  appliData: state.appli,
  categoryList: state.category,
});

const mapDispatchToProps = {
  getClass,
  getCategoryType,
  getAlert
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnlineApplicationForm);
