import { Divider, Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import Alert from "../../Alert/Alert";
import * as myConstClass from "../../config/messageconstant";
import { LabelCompo } from "../../components/Comman/Label";
import {
  getApplicant,
  getCapRoundById,
} from "../ApplicationForm/applicant.slice";
import InstituteMaster from "../ApplicationForm/index";
import { getDeclrationById } from "../onlineAppProcess/declaration.slice";
import {
  getRoundDetailByApplicationId,
  getRoundDetailById,
  resetDelete,
} from "./roundDetail.slice";
import RoundDetailsTable from "./RoundDetailsTable";
import { downloadF } from "../PDF/PDFDownload";
import endpoints from "../../config/endpoints";
import { logOut } from "../Login/login.slice";
import { useNavigate } from "react-router";
import { ButtonCompo } from "../../components/Comman/Buttton";
import SearchExternalPlacedStudList from "../../components/SearchTable";
import { getAlert } from "../../Alert/alert.slice";
import axios from "axios";
import { decodeData } from "../../components/Comman/Util/Base64";
const rows = [
  {
    id: 1,
    name: "1st ",
  },
  {
    id: 2,
    name: "2nd ",
  },
];

const RoundDetails = ({
  loginUser,
  logOut,
  downloadF,
  goTolistOfApp,
  resetDelete,
  getRoundDetailById,
  getCapRoundById,
  getApplicant,
  getDeclrationById,
  getRoundDetailByApplicationId,
  apllicationId,
  RoundDetailList,
  getAlert,
}) => {
  const navigate = useNavigate();
  const [isEdit, setEdit] = useState(false);
  const [row, setRows] = useState(rows);
  const [id1, setId] = useState(4);
  const [snakMsg, setSnakMsg] = useState("");
  const [editRow, setEditRow] = useState([]);
  const [viewRow, setViewRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [seatAllot, setSeatAllot] = useState(false);
  const [selectedPreference, setSelectPreference] = useState("");
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
  });
  const getRowID = async (id) => {
    setViewRow([]);
    const editedRow = row.filter((row) => row.id === id);
    if (editedRow.length > 0) {
      setEdit(true);
      setEditRow([]);
      return await setEditRow(editedRow);
    }
    return setEditRow([]);
  };

  const getRowData = async (id) => {
    setOpen(true);
    const viewRow = row.filter((row) => row.id === id);
    if (viewRow.length > 0) {
      return await setViewRow(viewRow);
    }
    return setViewRow([]);
  };

  const rowStatus = async (id) => {
    const viewRow = row.filter((row) => row.id === id);
    const v = [
      {
        id: viewRow[0].id,
        roundname: viewRow[0].roundname,
        fromDate: viewRow[0].fromDate,
        toDate: viewRow[0].toDate,
        isActive: viewRow[0].isActive === "1" ? "0" : "1",
      },
    ];
    if (viewRow.length > 0) {
      return await setRows(v);
    }
  };
  useEffect(() => {
    getRoundDetailById({ apllicationId }).then((response) => {
      if (!response) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });

    getRoundDetailByApplicationId({ apllicationId }).then((response) => {
      if (!response) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
  }, []);

  const seatAlloatment = (id) => {
    getDeclrationById({ appliId: apllicationId });
    getCapRoundById({ appliId: apllicationId });
    getApplicant({ appliId: apllicationId });
    setSeatAllot(true);
    setSelectPreference(id);
  };

  const gotoBack = () => {
    setSeatAllot(false);
  };

  const printUserApplicationFormReport = (id) => {
    console.log("id = " + id);
    let userAppReportURL = endpoints.userApplicationFormPdf;

    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      axios
        .get(userAppReportURL + "/" + id, {
          headers: {
            Authorization: token,
            shouldrefresh: "true",
          },
          responseType: "blob",
        })
        .then((response) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          const pdfWindow = window.open();
          pdfWindow.location.href = fileURL;
        })
        .catch((error) => {
          console.log("Error ========>", error);
        });
    } catch (e) {
      return console.error(e.message);
    }
  };
  const getFilteredTableData = () => {
    return RoundDetailList?.roundDetails.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["name"] &&
          currentRow["name"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["index"] &&
          currentRow["index"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  const onSearch = (value) => {
    setSearchValue(value);
  };
  return (
    <>
      {/* {RoundDetailList.isServerFail && (
        <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />
      )} */}

      {/* <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}> */}
      {!seatAllot && RoundDetailList.isFetch && RoundDetailList.isFetchById && (
        <>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            spacing={2}
            columnSpacing={1}
            container
            sx={{ m: 0.5 }}
            justifyContent="space-evenly"
          >
            {/* <Grid item xs={12} sm={12} md={0.5}></Grid> */}
            <Grid item xs={12} sm={11} md={11}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 20 }}
                label="Round Details"
              />
            </Grid>
            <Grid item xs={12} sm={1} md={1}>
              <ButtonCompo
                size="medium"
                type="button"
                variant="contained"
                name="Back"
                onClick={goTolistOfApp}
              />
            </Grid>
          </Grid>
          <br />
        </>
      )}
      {!seatAllot && RoundDetailList.isFetch && RoundDetailList.isFetchById && (
        <>
          <Grid sx={{ marginBottom: 2 }} item xs={12} sm={12} md={12}>
            <SearchExternalPlacedStudList onSearch={onSearch} />
          </Grid>
          <RoundDetailsTable
            tableHead={[
              {
                title: "Sr. No.",
                name: "index",
                positionCenter: true,
                alignCenter: "center",
                showInscreen: true,
              },
              {
                title: "Round Details",
                name: "name",
                positionCenter: false,
                alignCenter: "left",
                showInscreen: true,
              },
              {
                title: "Action",
                name: "action",
                positionCenter: false,
                alignCenter: "left",
                showInscreen: true,
              },
            ]}
            data={getFilteredTableData()}
            data1={RoundDetailList?.roundDetailByApllication}
            forms={seatAlloatment}
            userApplicationFormPrint={printUserApplicationFormReport}
            // onCancel={goTolistOfApp}
          />
        </>
      )}

      {seatAllot && (
        <InstituteMaster
          gotoBack={gotoBack}
          selectPreference={selectedPreference}
        />
      )}
      {/* </Paper> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  RoundDetailList: state.roundDetails,
  userApplication: state.applicant,
  loginUser: state.user,
});

const mapDispatchToProps = {
  getRoundDetailById,
  getRoundDetailByApplicationId,
  getDeclrationById,
  getCapRoundById,
  getApplicant,
  resetDelete,
  downloadF,
  logOut,
  getAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoundDetails);
