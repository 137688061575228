import React from "react";
import ExportCSV from "../../components/Comman/ExportCSV";
import { generatePDF } from "../../components/Comman/GeneratePDF";
import { FontAwesomeIconCompo } from "../../components/Comman/IconButton";

const ReligionExcelData = ({ csvData, relList, fileName, type }) => {
  const tablecol = relList.map((title) => {
    return title[0];
  });

  let index = 0;
  const religionData = csvData.map((csvData) => {
    index = index + 1;
    let religion = {
      "Sr. No.": index,
    };

    if (relList.length !== 2) {
      relList.map((title) => {
        if (title[0].name === "religionName") {
          religion = {
            ...religion,
            "Religion Name": csvData.religionName,
          };
        }

        if (title[0].name === "isActive") {
          religion = {
            ...religion,
            "Active Status": csvData.isActive,
          };
        }
        return religion;
      });
    } else {
      religion = {
        ...religion,
        "Religion Name": csvData.religionName,
        "Active Status": csvData.isActive,
      };
    }
    return religion;
  });

  if (type === "pdf") {
    return (
      <FontAwesomeIconCompo
        color="error"
        fontSize="small"
        title="PDF"
        baseClassName="fas"
        className="fa-file-pdf"
        onClick={() => generatePDF(religionData, tablecol, "Religion Master")}
      />
    );
  }

  return <ExportCSV csvData={religionData} fileName={fileName} />;
};
export default ReligionExcelData;
