import React from "react";
import ExportCSV from "../../components/Comman/ExportCSV";
import { generatePDF } from "../../components/Comman/GeneratePDF";
import { FontAwesomeIconCompo } from "../../components/Comman/IconButton";
const ProramTypeExcelData = ({ csvData, programTypeList, fileName, type }) => {
  const tablecol = programTypeList.map((title) => {
    return title[0].title;
  });
  let index = 0;
  const proramTypeData = csvData.map((csvData) => {
    index = index + 1;
    let inst = {
      "Sr. No.": index,
    };

    if (programTypeList.length !== 2) {
      programTypeList.map((title) => {
        if (title[0].name === "programType") {
          inst = {
            ...inst,
            "Program Type": csvData.programType,
          };
        }

        if (title[0].name === "isActive") {
          inst = {
            ...inst,
            Status: csvData.isActive,
          };
        }
        return 0;
      });
    } else {
      inst = {
        ...inst,
        "Program Type": csvData.programType,
        Status: csvData.isActive,
      };
    }

    return inst;
  });
  if (type === "pdf") {
    return (
      <FontAwesomeIconCompo
        color="error"
        fontSize="small"
        title="PDF"
        baseClassName="fas"
        className="fa-file-pdf"
        onClick={() =>
          generatePDF(proramTypeData, tablecol, "Program Type Master")
        }
      />
    );
  }
  return <ExportCSV csvData={proramTypeData} fileName={fileName} />;
};
export default ProramTypeExcelData;
