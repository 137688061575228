import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { LabelCompo } from "../../components/Comman/Label";
import "../../CSS/View.css";
import { withStyles } from "@mui/styles";
import { connect } from "react-redux";

const styles = (theme) => ({
  MuiTable: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
  },

  MuiTableHead: {
    background: "#6673fd",
    color: "#FFF",
    border: "1px solid #ddd",
    fontFamily: "poppins",
  },
  MuiTableCell: {
    color: "#FFF",
    fontSize: "16px",
    fontFamily: "poppins",
  },
  MuiTableCellRoot: {
    padding: "7px",
    borderRight: "1px solid #ddd",
    fontFamily: "poppins",
  },
  MuiTableHeadRoot: {
    textAlign: "left",
    padding: "8px",
    border: "1px solid #ddd",
  },
});

const Step2 = ({ data, courseData, classes }) => {
  return (
    <>
      {/* <Grid item xs={12} sm={6} md={12} /> */}
      <div style={{ overflow: "auto" }}>
        <table className={classes.MuiTable}>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              align="left"
              width="20%"
            >
              &nbsp;Institute Name{" "}
            </td>
            <td className={classes.MuiTableHeadRoot} align="left">
              &nbsp;&nbsp;{data.tenantName}
            </td>
          </tr>

          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              align="left"
              width="20%"
            >
              &nbsp;Type of Program{" "}
            </td>
            <td className={classes.MuiTableHeadRoot} align="left">
              &nbsp;&nbsp;{data.programTypeName}
            </td>
          </tr>

          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              align="left"
            >
              &nbsp; Program{" "}
            </td>
            <td className={classes.MuiTableHeadRoot} align="left">
              &nbsp;&nbsp;{data.progName}
            </td>
          </tr>

          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              align="left"
            >
              &nbsp; Course{" "}
            </td>
            <td className={classes.MuiTableHeadRoot} align="left">
              {courseData?.courseData.map((data1, index) => {
                return (
                  <>
                    {" "}
                    {data1.brName}
                    {index + 1 !== courseData?.courseData.length && " , "}
                  </>
                );
              })}
            </td>
            {/* <td className={classes.MuiTableHeadRoot} align="left" colSpan={3}>
              &nbsp;&nbsp;{data.programTitleIds}
            </td> */}
          </tr>
        </table>
      </div>
      <br />
      <br />
    </>
  );
};

const mapStateToProps = (state) => ({
  courseData: state.directAdmission,
});

const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Step2));
//export default Step2;
