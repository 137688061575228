import { Grid, TextField } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Buttton";
import * as myConstClass from "../../config/messageconstant";
import {
  deleteProgramTypeData,
  getProgramTypeByName,
} from "./programType.slice";
let docs;
class ProgramTypeForm extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    id: 0,
    programType: "",
    isActive: "1",
    formErrors: [],
    breadCrum: "",
  };

  saveHandler = (e) => {
    e.preventDefault();
    const { programType } = this.state;

    if (this.handleFormValidation()) {
      this.setState({
        flag: true,
      });
      this.props.getProgramTypeByName({ programType: programType });
    }
  };

  handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({ logo: e.target.result });
    };
    reader.readAsDataURL(event.target.files[0]);
    this.props.handleFile(fileUploaded);
  };

  handleClick = (event) => {
    this._name.click();
  };
  changeHandler = (event) => {
    const { name, value } = event.target;

    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  componentDidMount() {
    const { isEdit } = this.props;

    if (isEdit.length > 0) {
      this.setState({
        id: isEdit[0].id,
        programType: isEdit[0].programType,
        isActive: isEdit[0].isActive === "Active" ? 1 : 0,
      });
    }
  }
  componentDidUpdate() {
    if (this.props.programType.isFetch) {
      const { id, programType, isActive, flag } = this.state;

      docs = {
        name: programType,
        isActive: isActive,
        delStatus: 1,
      };

      if (id !== 0) {
        docs = {
          ...docs,
          id: id,
        };
      }

      if (this.props.programType.programTypeData.length === 0) {
        if (flag) {
          this.props.saveAndNext(docs);
          this.setState({
            id: 0,
            programType: "",
            isActive: "0",
            formErrors: [],
            flag: false,
          });
        } else {
          this.props.OnSave(docs);
        }
      } else {
        let formErrors = {};
        if (this.props.programType.programTypeData.length !== 0) {
          formErrors["programTypeErr"] = myConstClass.progTypeUniqueMsg;
        }

        this.setState({ formErrors: formErrors });
      }
      this.props.deleteProgramTypeData();
    }
  }
  handleFormValidation(name) {
    const { programType } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (programType === "") {
      formIsValid = false;
      formErrors["programTypeErr"] = myConstClass.programTypeMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const { programType } = this.state;

    if (this.handleFormValidation()) {
      this.setState({
        flag: true,
      });
      this.props.getProgramTypeByName({ programType: programType });
    }
  };

  render() {
    const { programType } = this.state;
    const { programTypeErr } = this.state.formErrors;
    const { onCancel } = this.props;
    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            <Grid
              sx={{ m: -1 }}
              item
              xs={12}
              md={12}
              size="small"
              container
              justifyContent="flex-end"
            >
              <ButtonCompo
                size="small"
                type="button"
                variant="outlined"
                name=" Back to List"
                onClick={onCancel}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="*Program Type"
                placeholder="Enter  Program Type"
                size="small"
                color="primary"
                name="programType"
                id="programType"
                value={programType}
                onChange={this.changeHandler}
                fullWidth
                {...(programTypeErr
                  ? { error: true, helperText: programTypeErr }
                  : "")}
              ></TextField>
            </Grid>

            <Grid item xs={12} sm={6} md={8}></Grid>

            <Grid item xs={2} md={12} container justifyContent="flex-end">
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Save"
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <ButtonCompo
                size="medium"
                type="button"
                variant="contained"
                name="Save and Next"
                onClick={this.saveHandler}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <ButtonCompo
                size="medium"
                type="button"
                variant="contained"
                name="Cancel"
                onClick={onCancel}
              />
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  programType: state.programType,
});

const mapDispatchToProps = {
  getProgramTypeByName,
  deleteProgramTypeData,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProgramTypeForm);
