import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import * as React from "react";
import "../../CSS/View.css";

const styles = (theme) => ({
  MuiTable: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
  },

  MuiTableHead: {
    background: "#6673fd",
    color: "#FFF",
    border: "1px solid #ddd",
    fontFamily: "poppins",
  },
  MuiTableCell: {
    color: "#FFF",
    fontSize: "16px",
    fontFamily: "poppins",
  },
  MuiTableCellRoot: {
    padding: "7px",
    borderRight: "1px solid #ddd",
    fontFamily: "poppins",
  },
  MuiTableHeadRoot: {
    textAlign: "left",
    padding: "8px",
    border: "1px solid #ddd",
  },
});

const Step3 = ({ data, classes }) => {
  return (
    <>
      <Grid item xs={12} sm={6} md={12} />
      <div style={{ overflow: "auto" }}>
        <table className={classes.MuiTable}>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              align="left"
            >
              &nbsp;Sr. No.
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              align="left"
              width="50%"
            >
              &nbsp;Qualification - User Entered
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              align="left"
            >
              &nbsp;Weightage
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              align="left"
              width="20%"
            >
              &nbsp;Sequence Order No.
            </td>
          </tr>
          {data.applicationElegibilityDetails.map((data1, index) => {
            if (data1.type == 1) {
              return (
                <tr>
                  <td className={classes.MuiTableHeadRoot} align="left">
                    {index + 1}
                  </td>
                  <td className={classes.MuiTableHeadRoot} align="left">
                    {data1.qualification}
                  </td>

                  <td className={classes.MuiTableHeadRoot} align="left">
                    {data1.weightagePer}
                  </td>
                  <td className={classes.MuiTableHeadRoot} align="left">
                    {data1.marksSequenceOrderNo}
                  </td>
                </tr>
              );
            }
          })}
        </table>
      </div>
      <br />
      <div style={{ overflow: "auto" }}>
        <table className={classes.MuiTable}>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              align="left"
            >
              &nbsp;Sr. No.
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              align="left"
              width="50%"
            >
              &nbsp;Qualification - Institute Entered
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              align="left"
            >
              &nbsp;Weightage
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              align="left"
              width="20%"
            >
              &nbsp;Sequence Order No.
            </td>
          </tr>
          {data.applicationElegibilityDetails.map((data1, index) => {
            if (data1.type == 0) {
              var c = 1;
              return (
                <tr>
                  <td className={classes.MuiTableHeadRoot} align="left">
                    {c}
                  </td>
                  <td className={classes.MuiTableHeadRoot} align="left">
                    {data1.qualification}
                  </td>

                  <td className={classes.MuiTableHeadRoot} align="left">
                    {data1.weightagePer}
                  </td>
                  <td className={classes.MuiTableHeadRoot} align="left">
                    {data1.marksSequenceOrderNo}
                  </td>
                </tr>
              );
            }
          })}
        </table>
      </div>
    </>
  );
};
export default withStyles(styles)(Step3);
