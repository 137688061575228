import React from "react";
import ExportCSV from "../../components/Comman/ExportCSV";
import { generatePDF } from "../../components/Comman/GeneratePDF";
import { FontAwesomeIconCompo } from "../../components/Comman/IconButton";
const QualificationExcelData = ({
  csvData,
  qualificationList,
  fileName,
  type,
}) => {
  const tablecol = qualificationList.map((title) => {
    if (title.title != "Action") return title.title;
  });
  let index = 0;
  const admissionStatusData = csvData.map((csvData) => {
    index = index + 1;
    let inst = {
      "Sr. No.": index,
    };

    if (qualificationList.length !== 4) {
      qualificationList.map((title) => {
        if (title.name === "qualificationName") {
          inst = {
            ...inst,
            "Qualification Name": csvData.qualificationName,
          };
        }

        if (title.name === "qualificationType") {
          inst = {
            ...inst,
            "Qualification Type": csvData.qualificationType,
          };
        }

        if (title.name === "sortOrderNo") {
          inst = {
            ...inst,
            "Sort Order No": csvData.sortOrderNo,
          };
        }

        if (title.name === "isActive") {
          inst = {
            ...inst,
            Status: csvData.isActive,
          };
        }
        return title;
      });
    } else {
      inst = {
        ...inst,
        "Qualification Name": csvData.qualificationName,
        "Qualification Type": csvData.qualificationType,
        "Sort Order No": csvData.sortOrderNo,
        Status: csvData.isActive,
      };
    }

    return inst;
  });
  if (type === "pdf") {
    return (
      <FontAwesomeIconCompo
        color="error"
        fontSize="small"
        title="PDF"
        baseClassName="fas"
        className="fa-file-pdf"
        onClick={() =>
          generatePDF(admissionStatusData, tablecol, "Qualification Master")
        }
      />
    );
  }
  return <ExportCSV csvData={admissionStatusData} fileName={fileName} />;
};
export default QualificationExcelData;
