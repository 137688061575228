import { Checkbox, FormGroup, FormHelperText, Grid } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import * as React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Buttton";
import * as myConstClass from "../../config/messageconstant";

class IntrestedProgramForm extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    id: 0,
    progName: [],
    progNameValid: "",
    checkedValues: [],
  };

  changeHandler = (event, column) => {
    const updatedCheckedState = this.state.checkedValues.map((item, index) =>
      index === column ? !item : item
    );
    this.setState({
      checkedValues: updatedCheckedState,
    });
  };

  componentDidMount() {
    const { programList, student } = this.props;

    if (student.applicant.intrestIn != null) {
      let data = student.applicant.intrestIn;
      var array = data.split(",");
      console.log(array);
      const instProg = programList?.program.map((data) => {
        const instrestedProg = array.filter((row) => row === data.id);
        if (instrestedProg.length != 0) {
          return true;
        }
        return false;
      });
      this.setState({
        checkedValues: instProg,
      });
      console.log(instProg);
    } else {
      this.setState({
        checkedValues: new Array(programList?.program.length).fill(false),
      });
    }
  }

  handleFormValidation(name) {
    const { progName } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (progName === "") {
      formIsValid = false;
      formErrors["emailIdErr"] = myConstClass.emailIdMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const { checkedValues } = this.state;

    let data = [];
    const { programList } = this.props;
    let values = checkedValues.map((value, index) => {
      if (value == true) {
        data.push(programList?.program[index].id);
      }
    });
    let intrest = data.toString();

    if (intrest != "") {
      this.props.OnSave(intrest);
    } else {
      this.setState({
        progNameValid: "Atleast one program selection is required",
      });
    }
  };
  render() {
    const { checkedValues, progNameValid } = this.state;

    const { onCancel, programList } = this.props;

    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            {/* <Grid item xs={12} sm={6} md={3}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 20 }}
                label="Interested Program"
              />
            </Grid> */}
            <Grid item xs={12} sm={12} md={12}>
              {" "}
              <FormHelperText error>
                <h2 style={{ fontWeight: "normal" }}> {progNameValid}</h2>
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {/* <Divider textAlign="left"></Divider> */}
            </Grid>

            {programList?.program.map((program, index) => {
              console.log(checkedValues, "checkedValues");
              return (
                <Grid item xs={12} sm={12} md={3}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox name="program" id="program" />}
                      label={program.name}
                      // labelPlacement="right"
                      value={checkedValues[index]}
                      onChange={(e) => this.changeHandler(e, index)}
                      checked={checkedValues.length > 0 && checkedValues[index]}
                    />
                  </FormGroup>
                </Grid>
              );
            })}

            {programList?.program.length % 4 == 1 && (
              <Grid item xs={2} sm={4} md={9} />
            )}
            {programList?.program.length % 4 == 3 && (
              <Grid item xs={2} sm={4} md={3} />
            )}
            {programList?.program.length % 4 == 2 && (
              <Grid item xs={2} sm={4} md={6} />
            )}

            <Grid
              rowSpacing={2}
              // columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              columnSpacing={1}
              justifyContent="right"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={1.1}
                lg={1.1}
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Cancel"
                  onClick={onCancel}
                  fullWidth={true}
                />
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={1.7}
                lg={1.7}
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Save"
                  fullWidth={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  programList: state.program,
  student: state.student,
});

const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntrestedProgramForm);
