import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { decodeData } from "../../components/Comman/Util/Base64";
import endpoints from '../../config/endpoints';
import { tokenExp } from "../Login/login.slice";

const initialState = {
  qualification: [],
  qualificationData: [],
  qualificationDetails: {},
  isFetch: false,
  isSave: false,
  qualificationFilter: [],
  isFilter: false,
  error: false,
  isServerFail: false,
  isDelete: false,
  isDataFetch: false,
};
let URL = endpoints.qualification;
const qualificationSlice = createSlice( {
  name: "qualification",
  initialState,
  reducers: {
    qualificationSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload
      return {
        ...state,
        qualification: row,
        isFetch: true,
        isDataFetch: true,
      };
    },

    qualificationFilterSuccess: ( state = cloneDeep( initialState ), action ) =>
    {

      const { row } = action.payload
      return {
        ...state,
        qualificationFilter: row,
        isFilter: true
      };
    },
    qualificationFilterFailure: ( state = cloneDeep( initialState ), action ) =>
    {

      return {
        ...state,
        qualificationFilter: [],
        isFilter: false
      };
    },

    qualificationDetailsSuccess: ( state = cloneDeep( initialState ), action ) =>
    {

      const { data } = action.payload
      return {
        ...state,
        qualificationDetails: data,
        isSave: true,
        error: false,
        isDelete: false
      };
    },
    qualificationDetailsFail: ( state = cloneDeep( initialState ), action ) =>
    {

      return {
        ...state,
        qualificationDetails: {},
        error: false,
        isDelete: true,
        isSave: false,
      };
    },
    qualificationFailure: ( state = cloneDeep( initialState ), action ) =>
    {

      return {
        ...state,
        qualification: [],
        isFetch: false,
      };
    },

    qualificationDeleteSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        error: false,
        isDelete: true,
      };
    },
    qualificationDeleteFail: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        error: true,
        isDelete: false,
        isSave: false,
      };
    },
    qualificationListRefresh: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        isDataFetch: false,
      };
    },
    serverFailed: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        isServerFail: true,
        error: false,
        isDelete: false
      };
    },
    resetDeleteSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        error: false,
        isDelete: false,
        isSave: false,
        isServerFail: false,

      };
    },
  },
} );

export const { qualificationFilterSuccess, qualificationSuccess, qualificationDetailsSuccess, qualificationDetailsFail, qualificationFailure, serverFailed, qualificationDeleteFail, qualificationDeleteSuccess, qualificationListRefresh, resetDeleteSuccess } = qualificationSlice.actions;

export default qualificationSlice.reducer;

export const getQualification = () => async ( dispatch ) =>
{
  try
  {

    dispatch( qualificationListRefresh() );
    const name = localStorage.getItem( "token" );
    const token = decodeData( name );
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Authorization": token,
      "shouldrefresh": "true",
    };

    let doc = await axios.get( URL + '?sort={"sortOrderNo": "ASC"}', { headers } )
      .then( ( response ) =>
      {
        const { error } = response.data
        if ( error == true )
        {
          dispatch( tokenExp() );
        }
        console.log( "Success get qualification  ========>", response );
        let data = response.data.data.content;

        let row = [];
        let index = 0
        data.map( ( data1 ) =>
        {
          index = index + 1
          let bData = {
            index: index,
            id: data1.id,
            qualificationName: data1.name,
            qualificationType: data1.type === 1 ? "UserFill" : "TrustFill",
            sortOrderNo: data1.sortOrderNo,
            isActive: data1.isActive === 1 ? "Active" : "Inactive",
            seqNO: '',
            seqNOValid: 0,
            weightage: '',
            weightageValid: 0
          };
          row.push( bData );

          return bData
        } );
        dispatch( qualificationSuccess( { row } ) );
        return row;
      } )
      .catch( ( error ) =>
      {
        console.log( "Error get qualification========>", error );
        dispatch( serverFailed() );
        return false;
      } );
    return doc;
  } catch ( e )
  {
    dispatch( serverFailed() );
    return false;
  }
};

export const getQualificationById = () => async ( dispatch ) =>
{
  try
  {

    const name = localStorage.getItem( "token" );
    const token = decodeData( name );
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    let doc = await axios
      .get( URL + "/1", { headers } )
      .then( ( response ) =>
      {
        const { error } = response.data
        if ( error == true )
        {
          dispatch( tokenExp() );

        }
        console.log( "Success with id get qualification by id ========>", response );
      } )
      .catch( ( error ) =>
      {
        dispatch( serverFailed() );
        return false;
        // console.log( "Error get qualification by id ========>", error.response.data );
      } );
    return doc;
  } catch ( e )
  {
    dispatch( serverFailed() );
    return false;
  }
};

export const saveQualification =
  ( { qualificationData } ) =>
    async ( dispatch ) =>
    {
      try
      {
        let data = JSON.stringify( qualificationData );

        const name = localStorage.getItem( "token" );
        const token = decodeData( name );
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
          shouldrefresh: "true",
        };
        let doc = await axios
          .post( URL, data, { headers } )
          .then( ( response ) =>
          {
            const { error } = response.data
            if ( error == true )
            {
              dispatch( tokenExp() );

            }
            console.log( "Success with save qualification ========>", response.data );
            const { data } = response.data
            dispatch( qualificationDetailsSuccess( { data } ) );
            return data
          } )
          .catch( ( error ) =>
          {
            dispatch( serverFailed() );
            return false;
            // console.log( "Error save  qualification=======>", error.response.data );
          } );
        return doc;
      } catch ( e )
      {
        dispatch( serverFailed() );
        // return console.error( e.message );
        return false;

      }
    };

export const resetSave = () => async ( dispatch ) =>
{
  try
  {
    dispatch( qualificationDetailsFail() );
  }
  catch ( e )
  {
    return console.error( e.message );
  }
};
export const deleteQualificationById =
  ( { qualificationId } ) =>
    async ( dispatch ) =>
    {
      try
      {

        const name = localStorage.getItem( "token" );
        const token = decodeData( name );
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
          shouldrefresh: "true",
        };
     const response = await axios
          .delete( URL + "/" + qualificationId, { headers } )
          .then( ( response ) =>
          {
            console.log(
              "Success with delete qualification by id ========>",
              response
            );
            dispatch( qualificationDeleteSuccess() )
            return true;
          } )
          .catch( ( error ) =>
          {
            //console.log("Error delete qualification by id========>", error.response.data);

            if ( error.response )
            {
              console.log( 'error.response.status' )
              console.log( error.response.status )
              console.log( 'error.response.data' )
              console.log( error.response.data )
            }
            dispatch( qualificationDeleteFail() );
            return false;
            // return error.response.data;

          } );
        return response;
      } catch ( e )
      {
        dispatch( serverFailed() );
        return false;
        // return console.error( e.message );
      }
    };

export const filterQualificationData = ( { row, flag } ) => async ( dispatch ) =>
{
  try
  {
    if ( flag === true )
    {
      dispatch( qualificationFilterSuccess( { row } ) );
    }
    else
    {
      dispatch( qualificationSuccess( { row } ) );
    }

  }
  catch ( e )
  {
    return console.error( e.message );
  }
};

export const resetDelete = () => async ( dispatch ) =>
{
  try
  {
    dispatch( resetDeleteSuccess() );
  } catch ( e )
  {
    return console.error( e.message );
  }
};