import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { withStyles } from "@mui/styles";
import * as React from "react";
import { connect } from "react-redux";
import NavbarLanding from "./NavbarLanding";
import NewHeader from "./NewHeader";
const styles = (theme) => ({
  root: {
    // height: "100vh",
    backgroundColor: `${theme.palette.primary.smokeGrey} !important`,
    lineHeight: "24px !important",
  },
  // icon: {
  //     position: 'absolute',
  //     width: '103px',
  //     height: '21px',
  //     left: '270px',
  //     top: '20px',

  //     fontfamily: 'Font Awesome 5 Free',
  //     fontstyle: 'normal',
  //     fontweight: '900',
  //     fontsize: '10px',
  //     lineheight: '21px',

  //     color: '#FFFFFF'
  // },
  // iconbulb: {
  //     position: 'absolute',
  //     width: '103px',
  //     height: '21px',
  //     left: '300px',
  //     top: '21px',

  //     fontfamily: 'Font Awesome 5 Free',
  //     fontstyle: 'normal',
  //     fontweight: '900',
  //     lineheight: '21px',
  //     fontsize: 'small',

  //     color: '#FFFFFF'
  // },
  // line: {
  //     position: 'absolute',
  //     left: '89%',
  //     right: '4%',
  //     top: '12%',
  //     border: '1px solid rgba(255, 255, 255, 0.3)',
  //     transform: 'rotate(90deg)',
  // },
});

const Header = ({ classes }) => {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          elevation={0}
          className={classes.root}
        >
          <NavbarLanding />
        </AppBar>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  common: state.common,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Header));
