import { Box } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { onToggle } from "../../CommonActions/common.slice";
import DrawerNav from "../../components/Drawer";
import Header from "../../components/Header";
import config from "../../config/config";
import PrivateRoute from "../../routes/index";
import AcademicYearMaster from "../AcademicYearMaster/index";
import AcceptRejectAdmForm from "../AcceptRejectAdmForm/index";
import AddingCapRound from "../AddingCapRound/index";
import ApplicationForm from "../ApplicationForm/index";
import Alert from "../../Alert/Alert";
import BranchMaster from "../BranchMaster/index";
import CategoryMaster from "../CategoryMaster/index";
import CategorySeatIntakeDetail from "../CategorySeatIntakeDetail/index";

import ClassMaster from "../Class/index";
import ClassIntakeDetail from "../ClassIntakeDetail/index";
import DeclarationFormView from "../DeclarationForm/index";
import DocumentMaster from "../DocumentMaster/index";

import GenerateMeritList from "../GenerateMeritList/index";

import Institute from "../Institute/index";
import InterestedProgram from "../IntresetedProgram";
import ListingOfOnlineAppForm from "../ListingOfOnlineAppForm/index";
import ListOfApplication from "../ListOfApplications/Index";

import OnlineApplicationForm from "../onlineAppProcess/index";

import ProgramMaster from "../ProgramMaster/index";
import ProgramType from "../ProgramType/index";

import QualificationDetails from "../QualificationDetails/Index";
import QualificationMaster from "../QualificationMaster/index";
import Religion from "../Religion/index";
import ReservationStructure from "../ReservationStructure/index";
import RoundDetails from "../RoundDetails";
import StudentMaster from "../Student";
import StudentRegister from "../Student Register";
import SpecialReservationDetails from "../Student/SpecialReservationDetails";
import DeclarationPreview from "../DeclarationForm/index";
import StudentPreview1 from "../StudentPreview1/index";

import ScheduleDetails from "../ScheduleDetails/Index";
import NewsDocNotification from "../NewsDocNotification/index";

import GenerateMeritListMaster from "../GenerateMeritList/index";
import AddingCapRoundForm from "../AddingCapRound/index";
import SeatAcceptanceLetter from "../SeatAcceptanceLetter/index";
import DashboardLandingPage from "../ApplicantDashboard/DashboardPage";
import UserDeclarationPreview from "../UserDeclarationPreview";
import Notification from "../ApplicantDashboard/Notifications";
import OnlineAdmission from "../ApplicantDashboard/OnlineAdmission";
import LatestNews from "../ApplicantDashboard/LatestNews";
import DynamicReport from "../Reports/OnlineModule";
import ListOfApplicationDelcare from "../AdminDirectAdmission/index";
import StudentApplyApplicationDelcare from "../StudentDirectAdmission/index";
import ApplicationDelcareForm from "../AdminDirectAdmission/DirectAdmissionFormIndex";
import AdminDirectAdmissionView from "../AdminDirectAdmissionView/index";
import StudentPaymentSuccess from "../StudentDirectAdmission/studentPaymentSuccess";
import StudentDirectAdmissionPreview from "../StudentDirectAdmissionPreview";
import ListOfApplicationDirectAdmission from "../StudentDirectAdmission/studentDirectAdmissionAppliedList";
const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(7),
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
    minHeight: "calc(100vh - 64px)",
    padding: theme.spacing(1),
    borderRadius: `${theme.spacing(1.5)} ${theme.spacing(1.5)} 0px 0px`,
  },
  typo: {
    color: theme.palette.primary.contrastText,
  },
});

class Landing extends PureComponent {
  render() {
    const { classes, common, alertInfo } = this.props;
    return (
      <>
        <Header />
        <DrawerNav />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: common.toggleNav
              ? `calc(100% - ${config.drawerWidth}px)`
              : "100%",
            ml: common.toggleNav ? `${config.drawerWidth}px` : "0px",
          }}
          className={classes.root}
        >
          {alertInfo.show && <Alert msg={alertInfo.alertMsg} />}

          <Routes>
            {/* Private Route */}
            {/* <Route
              path="/about-us"
              element={
                <PrivateRoute>
                  <AboutUs />
                </PrivateRoute>
              }
            /> */}
            <Route
              path="/userdeclarationpreview"
              element={
                <PrivateRoute>
                  <UserDeclarationPreview />
                </PrivateRoute>
              }
            />
            <Route
              path="/reports"
              element={
                <PrivateRoute>
                  <DynamicReport />
                </PrivateRoute>
              }
            />
            <Route
              path="/notification"
              element={
                <PrivateRoute>
                  <Notification />
                </PrivateRoute>
              }
            />
            <Route
              path="/admission"
              element={
                <PrivateRoute>
                  <OnlineAdmission />
                </PrivateRoute>
              }
            />
            <Route
              path="/latestNews"
              element={
                <PrivateRoute>
                  <LatestNews />
                </PrivateRoute>
              }
            />
            <Route
              path="/student-dashboard"
              element={
                <PrivateRoute>
                  <SeatAcceptanceLetter />
                </PrivateRoute>
              }
            />
            <Route
              path="/addcapround"
              element={
                <PrivateRoute>
                  <AddingCapRoundForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/generatemeritlist"
              element={
                <PrivateRoute>
                  <GenerateMeritListMaster />
                </PrivateRoute>
              }
            />
            <Route
              path="/newsDocNotification"
              element={
                <PrivateRoute>
                  <NewsDocNotification />
                </PrivateRoute>
              }
            />
            <Route
              path="/scheduleDetailForm"
              element={
                <PrivateRoute>
                  <ScheduleDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/institute"
              element={
                <PrivateRoute>
                  <Institute />
                </PrivateRoute>
              }
            />
            <Route
              path="/class"
              element={
                <PrivateRoute>
                  <ClassMaster />
                </PrivateRoute>
              }
            />
            <Route
              path="/classIntakeDetail"
              element={
                <PrivateRoute>
                  <ClassIntakeDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="/qualification"
              element={
                <PrivateRoute>
                  <QualificationMaster />
                </PrivateRoute>
              }
            />
            <Route
              path="/listofapplication"
              element={
                <PrivateRoute>
                  <ListOfApplication />
                </PrivateRoute>
              }
            />
            <Route
              path="/roundDetails"
              element={
                <PrivateRoute>
                  <RoundDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/academicYear"
              element={
                <PrivateRoute>
                  <AcademicYearMaster />
                </PrivateRoute>
              }
            />
            <Route
              path="/CategorySeatIntakeDetail"
              element={
                <PrivateRoute>
                  <CategorySeatIntakeDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="/programTitle"
              element={
                <PrivateRoute>
                  <BranchMaster />
                </PrivateRoute>
              }
            />{" "}
            <Route
              path="/religion"
              element={
                <PrivateRoute>
                  <Religion />
                </PrivateRoute>
              }
            />{" "}
            <Route
              path="/category"
              element={
                <PrivateRoute>
                  <CategoryMaster />
                </PrivateRoute>
              }
            />
            <Route
              path="/Program"
              element={
                <PrivateRoute>
                  <ProgramMaster />
                </PrivateRoute>
              }
            />
            <Route
              path="/addingCapRound"
              element={
                <PrivateRoute>
                  <AddingCapRound />
                </PrivateRoute>
              }
            />
            <Route
              path="/generateMeritList"
              element={
                <PrivateRoute>
                  <GenerateMeritList />
                </PrivateRoute>
              }
            />
            <Route
              path="/acceptRejectAdmForm"
              element={
                <PrivateRoute>
                  <AcceptRejectAdmForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/studentPreview1"
              element={
                <PrivateRoute>
                  <StudentPreview1 />
                </PrivateRoute>
              }
            />
            <Route
              path="/listingOfOnlineAppForm"
              element={
                <PrivateRoute>
                  <ListingOfOnlineAppForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/qualificationDetails"
              element={
                <PrivateRoute>
                  <QualificationDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/applicationForm"
              element={
                <PrivateRoute>
                  <ApplicationForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/onlineappprocess"
              element={
                <PrivateRoute>
                  <OnlineApplicationForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/document"
              element={
                <PrivateRoute>
                  <DocumentMaster />
                </PrivateRoute>
              }
            />
            <Route
              path="/programType"
              element={
                <PrivateRoute>
                  <ProgramType />
                </PrivateRoute>
              }
            />
            <Route
              path="/registerstudent"
              element={
                <PrivateRoute>
                  <StudentRegister />
                </PrivateRoute>
              }
            />
            <Route
              path="/reservationStructure"
              element={
                <PrivateRoute>
                  <ReservationStructure />
                </PrivateRoute>
              }
            />
            <Route
              path="/declarationFormView"
              element={<DeclarationFormView />}
            />
            <Route
              path="/interestedprogram"
              element={
                <PrivateRoute>
                  <InterestedProgram />
                </PrivateRoute>
              }
            />
            <Route
              path="/student"
              element={
                <PrivateRoute>
                  <StudentMaster />
                </PrivateRoute>
              }
            />
            <Route
              path="/student/studAddress/specialReservationDetails"
              element={
                <PrivateRoute>
                  <SpecialReservationDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/declarationpreview"
              element={
                <PrivateRoute>
                  <DeclarationPreview />
                </PrivateRoute>
              }
            />
            <Route
              path="/applicant-dashboard"
              element={
                <PrivateRoute>
                  <DashboardLandingPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/list-of-application-delcare"
              element={
                <PrivateRoute>
                  <ListOfApplicationDelcare />
                </PrivateRoute>
              }
            />
            <Route
              path="/application-declare-form"
              element={
                <PrivateRoute>
                  <ApplicationDelcareForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/student-apply-direct-admission"
              element={
                <PrivateRoute>
                  <StudentApplyApplicationDelcare />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin-direct-admission-view"
              element={
                <PrivateRoute>
                  <AdminDirectAdmissionView />
                </PrivateRoute>
              }
            />
            <Route
              path="/student-payment-success"
              element={
                <PrivateRoute>
                  <StudentPaymentSuccess />
                </PrivateRoute>
              }
            />
            <Route
              path="/list-of-application-direct-admission"
              element={
                <PrivateRoute>
                  <ListOfApplicationDirectAdmission />
                </PrivateRoute>
              }
            />
            <Route
              path="/student-direct-admission-preview"
              element={
                <PrivateRoute>
                  <StudentDirectAdmissionPreview />
                </PrivateRoute>
              }
            />
            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        </Box>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  common: state.common,
  alertInfo: state.alertInfo,
});

const mapDispatchToProps = { onToggle };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Landing));
