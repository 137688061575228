import InfoIcon from "@mui/icons-material/Info";
import { Box, Grid, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { red } from "@mui/material/colors";
import * as React from "react";
import { ChipCompo } from "../../components/Comman/IconButton";
import "../../CSS/View.css";

const ViewPage = ({ data }) => {
  if (data.length > 0) {
    return (
      <Grid>
        <Grid textAlign="right">
          {data.map((data) => {
            if (data.isActive == "Active") {
              return (
                <ChipCompo
                  label="Active"
                  variant="outlined"
                  size="small"
                  color="success"
                />
              );
            } else {
              return (
                <ChipCompo
                  label="Inactive"
                  variant="outlined"
                  size="small"
                  color="success"
                />
              );
            }
          })}
        </Grid>
        <Box display="flex" justifyContent="space-between ">
          <Box style={{ width: 500 }}>
            <Card variant="outlined">
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                    N
                  </Avatar>
                }
                title={data[0].name}
                subheader={data[0].instShortName}
              />
              <CardContent>
                <Grid display="flex" alignItems="center">
                  &nbsp;
                  <Typography variant="subtitle1" color="primary">
                    Institute Address:
                  </Typography>
                  &emsp;
                  <Typography variant="span" color="text.secondary">
                    {data[0].instAddr}
                  </Typography>
                </Grid>
                <Grid display="flex" alignItems="center">
                  &nbsp;
                  <Typography variant="subtitle1" color="primary">
                    Institute Affiliation:{" "}
                  </Typography>
                  &nbsp;
                  <Typography variant="span" color="text.secondary">
                    {data[0].instAffi}
                  </Typography>
                </Grid>
                <Grid>
                  &nbsp;
                  <Typography variant="span" color="primary">
                    University Code:
                  </Typography>
                  &nbsp; &nbsp; &nbsp; &nbsp;
                  <Typography variant="span" color="text.secondary">
                    {data[0].uniCode}
                  </Typography>
                </Grid>

                <Grid>
                  &nbsp;
                  <Typography variant="span" color="primary">
                    DTE Code:
                  </Typography>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  <Typography variant="span" color="text.secondary">
                    {data[0].dteCode}
                  </Typography>
                </Grid>
              </CardContent>
            </Card>
          </Box>

          <Box style={{ width: 500 }}>
            <Card style={{ height: 245 }} variant="outlined">
              <CardHeader
                avatar={<InfoIcon color="primary" />}
                title="Statutary Information"
              />
              <CardContent>
                <Grid>
                  <Typography variant="span" color="primary">
                    EPF Number:
                  </Typography>
                  &nbsp; &nbsp;
                  <Typography variant="span" color="text.secondary">
                    {data[0].epfNo}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="span" color="primary">
                    Tan Number:
                  </Typography>
                  &nbsp; &nbsp;
                  <Typography variant="span" color="text.secondary">
                    {data[0].tanNo}
                  </Typography>
                </Grid>

                <Grid>
                  <Typography variant="span" color="primary">
                    PAN Number:{" "}
                  </Typography>
                  &nbsp;
                  <Typography variant="span" color="text.secondary">
                    {data[0].panNo}
                  </Typography>
                </Grid>

                <Grid>
                  <Typography variant="span" color="primary">
                    Tax Number:
                  </Typography>
                  &nbsp; &nbsp;
                  <Typography variant="span" color="text.secondary">
                    {data[0].profTaxNo}
                  </Typography>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Grid>
    );
  }
};
export default ViewPage;
