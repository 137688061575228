import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { decodeData } from "../../components/Comman/Util/Base64";
import endpoints from "../../config/endpoints";
import { tokenExp } from "../Login/login.slice";
const initialState = {
  appli: [],
  applicant: [],
  applicationData: {},
  appliData: {},
  ShortNameData: [],
  LiveFormsList: [],
  scheduleFormsList: [],
  closedFormsList: [],
  documentData: [],
  courseData: [],
  isFetch: false,
  isFetchSave: false,
  isFetchLiveForm: false,
  isFetchScheduleForm: false,
  isFetchClosedForm: false,
  isFetchApplicant: false,
  isFetchShortName: false,
  isFetchApplication: false,
  error: false,
  isServerFail: false,
  isDelete: false,
  isDataFetch: false,
  isSave: false,
  isFetchRoundList: false,
  roundDetailsList: [],
};
let URL = endpoints.application;
let URLAppCap = endpoints.applicationCap;
let URL1 = endpoints.addcapround;
let URL8 = endpoints.getRoundDetails;

const appliSlice = createSlice({
  name: "appli",
  initialState,
  reducers: {
    appliSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        isFetch: true,
        appli: row,
      };
    },
    appliLiveSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        isFetchLiveForm: true,
        LiveFormsList: row,
      };
    },

    appliScheduleSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        isFetchScheduleForm: true,
        scheduleFormsList: row,
      };
    },
    appliClosedSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        isFetchClosedForm: true,
        closedFormsList: row,
      };
    },
    applicatonSuccess: (state = cloneDeep(initialState), action) => {
      const { data1 } = action.payload;
      return {
        ...state,
        isFetchApplication: true,
        applicationData: data1,
      };
    },
    resetSave: (state = cloneDeep(initialState), action) => {
      console.log("hi");
      return {
        ...state,
        isFetchSave: false,
        appliData: {},
        isFetchApplication: false,
        applicationData: {},
      };
    },
    appliFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isFetch: false,
        appli: [],
      };
    },
    applicationDocumentDetails: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        isFetchApplication: true,
        documentData: row,
      };
    },
    applicantSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        isFetchApplicant: true,
        applicant: row,
      };
    },
    applicantFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isFetchApplicant: false,
        applicant: [],
      };
    },
    appliSaveSuccess: (state = cloneDeep(initialState), action) => {
      const { applicationDeclareResult } = action.payload;
      return {
        ...state,
        isFetchSave: true,
        appliData: applicationDeclareResult,
      };
    },
    appliSaveFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isFetchSave: false,
        appliData: {},
      };
    },
    appliSave: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isFetch: false,
      };
    },

    courseDetails: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        courseData: data,
      };
    },
    resetServerSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: false,
        error: false,
        isDelete: false,
      };
    },
    resetDeleteSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error: false,
        isDelete: false,
        isSave: false,
      };
    },
    serverFailed: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: true,
        error: false,
        isDelete: false,
      };
    },
    applicantRoundDetailsListSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        isFetchRoundList: true,
        roundDetailsList: row,
      };
    },
    naviLogin: (state = cloneDeep(initialState), action) => {
      return {
        ...state,

        appli: [],
      };
    },
  },
});

export const {
  appliSuccess,
  appliLiveSuccess,
  appliScheduleSuccess,
  appliClosedSuccess,
  appliSave,
  appliFailure,
  resetSave,
  appliSaveSuccess,
  applicantSuccess,
  applicantFailure,
  appliSaveFailure,
  applicationDocumentDetails,
  appliListByShortNameFailure,
  appliListByShortNameSuccess,
  applicantRoundDetailsListSuccess,
  appliListByNameFailure,
  appliListByNameSuccess,
  applicatonSuccess,
  courseDetails,
  resetServerSuccess,
  resetDeleteSuccess,
  serverFailed,
} = appliSlice.actions;

export default appliSlice.reducer;

export const getDeclration = () => async (dispatch) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);

    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get(URLAppCap, { headers })
      .then((response) => {
        console.log(response);
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          let data = response.data.data;
          let row = [];
          let index = 0;
          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              title: data1.title,
              startDate: data1.startDate,
              isActive: data1.isActive === 1 ? "Active" : "Inactive",
              tsId: data1.tsId,
              shortSummary: data1.shortSummary,
              paymentType: data1.paymentType,
              program: data1.program,
              programTitleIds: data.programTitleIds,
              year: data1.year,
              undertaking: data1.undertaking,
              showNotificationOnscreen: data1.showNotificationOnscreen,
              //     programType: data1.programType,
              programTypeId: data1.programTypId,
              //     programId: data1.programId,
              isFreez: data1.isFreez,
              paymentInstruction: data1.paymentInstruction,
              paymentApplicable: data1.paymentApplicable,
              isMeritListGenerated: data1.isMeritListGenerated,
              meritListType: data1.meritListType,
              lableText: data1.lableText,
              intrestedPrograms:
                data1.program.name + " - " + data1.programTypeId.name,
              lastDate: data1.lastDate,
              highlightLable: data1.highlightLable,
              instructionPrintOnChallan: data1.instructionPrintOnChallan,
              meritListPublishedDate: data1.meritListPublishedDate,
              //     classInfoId: data1.classInfoId,
              //     className: data1.className,
              detailedDescription: data1.detailedDescription,
              undertaking: data1.undertaking,
              applicationDate: data1.applicationDate,
              amount: data1.amount,
              //  applicationRound: data1.applicationRound.name,
              applicationRound: data1.applicationRound.map((appliRound) => {
                let applicationRoundData = {
                  applicationDeclareId: data1.id,
                  id: appliRound.id,
                  capRoundname: appliRound.name,
                  isActive: appliRound.isActive === 1 ? "Active" : "Inactive",
                };
                return applicationRoundData;
              }),
            };
            row.push(bData);
          });

          dispatch(appliSuccess({ row }));
          return row;
        }
      })

      .catch((error) => {
        console.log("Error getDeclration========>", error);
        dispatch(serverFailed());
        return false;
      });
    return doc;
  } catch (e) {
    dispatch(serverFailed());
    return false;
  }
};

export const getDeclrationLiveForm = () => async (dispatch) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);

    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get(
        URLAppCap + "/live-applications" + '?sort={"insertdatetime": "DESC"}',
        { headers }
      )
      .then((response) => {
        console.log(response);
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          // if (response.data.error === false) {
          let data = response.data.data;
          let row = [];
          let index = 0;
          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              title: data1.title,
              startDate: data1.startDate,
              isActive: data1.isActive === 1 ? "Active" : "Inactive",
              tsId: data1.tsId,
              shortSummary: data1.shortSummary,
              paymentType: data1.paymentType,
              program: data1.program,
              programTitleIds: data.programTitleIds,
              year: data1.year,
              undertaking: data1.undertaking,
              showNotificationOnscreen: data1.showNotificationOnscreen,
              //     programType: data1.programType,
              programTypeId: data1.programTypId,
              //     programId: data1.programId,
              isFreez: data1.isFreez,
              paymentInstruction: data1.paymentInstruction,
              paymentApplicable: data1.paymentApplicable,
              isMeritListGenerated: data1.isMeritListGenerated,
              meritListType: data1.meritListType,
              lableText: data1.lableText,
              lastDate: data1.lastDate,
              highlightLable: data1.highlightLable,
              instructionPrintOnChallan: data1.instructionPrintOnChallan,
              meritListPublishedDate: data1.meritListPublishedDate,
              //     classInfoId: data1.classInfoId,
              //     className: data1.className,
              detailedDescription: data1.detailedDescription,
              applicationDate: data1.applicationDate,
              amount: data1.amount,
              //  applicationRound: data1.applicationRound.name,
              applicationRound: data1.applicationRound.map((appliRound) => {
                let applicationRoundData = {
                  applicationDeclareId: data1.id,
                  id: appliRound.id,
                  capRoundname: appliRound.name,
                  isActive: appliRound.isActive === 1 ? "Active" : "Inactive",
                };
                return applicationRoundData;
              }),
            };
            row.push(bData);
          });

          dispatch(appliLiveSuccess({ row }));
          return row;
        }
      })

      .catch((error) => {
        console.log("Error getDeclration========>", error);
        dispatch(serverFailed());
        return false;
      });
    return doc;
  } catch (e) {
    dispatch(serverFailed());
    return false;
  }
};

export const getDeclrationScheduleForm = () => async (dispatch) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get(
        URLAppCap +
          "/scheduled-applications" +
          '?sort={"insertdatetime": "DESC"}',
        { headers }
      )
      .then((response) => {
        console.log(response);
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          let data = response.data.data;
          let row = [];
          let index = 0;
          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              title: data1.title,
              startDate: data1.startDate,
              isActive: data1.isActive === 1 ? "Active" : "Inactive",
              tsId: data1.tsId,
              shortSummary: data1.shortSummary,
              paymentType: data1.paymentType,
              program: data1.program,
              programTitleIds: data.programTitleIds,
              year: data1.year,
              undertaking: data1.undertaking,
              minorityReligion: data1.minority,
              minority: data1.byMinority,
              showNotificationOnscreen: data1.showNotificationOnscreen,
              //     programType: data1.programType,
              programTypeId: data1.programTypId,
              //     programId: data1.programId,
              isFreez: data1.isFreez,
              paymentInstruction: data1.paymentInstruction,
              paymentApplicable: data1.paymentApplicable,
              isMeritListGenerated: data1.isMeritListGenerated,
              meritListType: data1.meritListType,
              lableText: data1.lableText,
              lastDate: data1.lastDate,
              highlightLable: data1.highlightLable,
              instructionPrintOnChallan: data1.instructionPrintOnChallan,
              meritListPublishedDate: data1.meritListPublishedDate,
              //     classInfoId: data1.classInfoId,
              //     className: data1.className,
              detailedDescription: data1.detailedDescription,
              applicationDate: data1.applicationDate,
              amount: data1.amount,
              //  applicationRound: data1.applicationRound.name,
              applicationRound: data1.applicationRound.map((appliRound) => {
                let applicationRoundData = {
                  applicationDeclareId: data1.id,
                  id: appliRound.id,
                  capRoundname: appliRound.name,
                  isActive: appliRound.isActive === 1 ? "Active" : "Inactive",
                };
                return applicationRoundData;
              }),
            };
            row.push(bData);
          });

          dispatch(appliScheduleSuccess({ row }));
          return row;
        }
      })

      .catch((error) => {
        console.log("Error getDeclration========>", error);
        dispatch(serverFailed());
        return false;
      });
    return doc;
  } catch (e) {
    dispatch(serverFailed());
    return false;
  }
};
//

export const getDeclrationClosedFormByDate =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);

      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .get(
          URLAppCap +
            "/closed-applications-by-date?fromDate=" +
            fromDate +
            "&toDate=" +
            toDate +
            '?sort={"insertdatetime": "DESC"}',
          { headers }
        )
        .then((response) => {
          console.log(response);
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            let data = response.data.data;
            let row = [];
            let index = 0;
            data.map((data1) => {
              index = index + 1;
              let bData = {
                index: index,
                id: data1.id,
                title: data1.title,
                startDate: data1.startDate,
                isActive: data1.isActive === 1 ? "Active" : "Inactive",
                tsId: data1.tsId,
                shortSummary: data1.shortSummary,
                paymentType: data1.paymentType,
                program: data1.program,
                programTitleIds: data.programTitleIds,
                year: data1.year,
                undertaking: data1.undertaking,
                showNotificationOnscreen: data1.showNotificationOnscreen,
                //     programType: data1.programType,
                programTypeId: data1.programTypId,
                //     programId: data1.programId,
                isFreez: data1.isFreez,
                paymentInstruction: data1.paymentInstruction,
                paymentApplicable: data1.paymentApplicable,
                isMeritListGenerated: data1.isMeritListGenerated,
                meritListType: data1.meritListType,
                lableText: data1.lableText,
                lastDate: data1.lastDate,
                highlightLable: data1.highlightLable,
                instructionPrintOnChallan: data1.instructionPrintOnChallan,
                meritListPublishedDate: data1.meritListPublishedDate,
                //     classInfoId: data1.classInfoId,
                //     className: data1.className,
                detailedDescription: data1.detailedDescription,
                applicationDate: data1.applicationDate,
                amount: data1.amount,
                //  applicationRound: data1.applicationRound.name,
                applicationRound: data1.applicationRound.map((appliRound) => {
                  let applicationRoundData = {
                    applicationDeclareId: data1.id,
                    id: appliRound.id,
                    capRoundname: appliRound.name,
                    isActive: appliRound.isActive === 1 ? "Active" : "Inactive",
                  };
                  return applicationRoundData;
                }),
              };
              row.push(bData);
            });

            dispatch(appliClosedSuccess({ row }));
            return row;
          }
        })

        .catch((error) => {
          console.log("Error getDeclration========>", error);
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

//
export const getDeclrationClosedForm = () => async (dispatch) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);

    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get(
        URLAppCap + "/closed-applications" + '?sort={"insertdatetime": "DESC"}',
        { headers }
      )
      .then((response) => {
        console.log(response);
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          let data = response.data.data;
          let row = [];
          let index = 0;
          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              title: data1.title,
              startDate: data1.startDate,
              isActive: data1.isActive === 1 ? "Active" : "Inactive",
              tsId: data1.tsId,
              shortSummary: data1.shortSummary,
              paymentType: data1.paymentType,
              program: data1.program,
              programTitleIds: data.programTitleIds,
              year: data1.year,
              undertaking: data1.undertaking,
              showNotificationOnscreen: data1.showNotificationOnscreen,
              //     programType: data1.programType,
              programTypeId: data1.programTypId,
              //     programId: data1.programId,
              isFreez: data1.isFreez,
              paymentInstruction: data1.paymentInstruction,
              paymentApplicable: data1.paymentApplicable,
              isMeritListGenerated: data1.isMeritListGenerated,
              meritListType: data1.meritListType,
              lableText: data1.lableText,
              lastDate: data1.lastDate,
              highlightLable: data1.highlightLable,
              instructionPrintOnChallan: data1.instructionPrintOnChallan,
              meritListPublishedDate: data1.meritListPublishedDate,
              //     classInfoId: data1.classInfoId,
              //     className: data1.className,
              detailedDescription: data1.detailedDescription,
              applicationDate: data1.applicationDate,
              amount: data1.amount,
              //  applicationRound: data1.applicationRound.name,
              applicationRound: data1.applicationRound.map((appliRound) => {
                let applicationRoundData = {
                  applicationDeclareId: data1.id,
                  id: appliRound.id,
                  capRoundname: appliRound.name,
                  isActive: appliRound.isActive === 1 ? "Active" : "Inactive",
                };
                return applicationRoundData;
              }),
            };
            row.push(bData);
          });

          dispatch(appliClosedSuccess({ row }));
          return row;
        }
      })

      .catch((error) => {
        console.log("Error getDeclration========>", error);
        dispatch(serverFailed());
        return false;
      });
    return doc;
  } catch (e) {
    dispatch(serverFailed());
    return false;
  }
};

export const getDeclrationById =
  ({ appliId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const courseData = await axios
        .get(URL + "/" + appliId, { headers })
        .then((response) => {
          console.log("Success With id getDeclrationById ========>", response);
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            const { data } = response.data;
            let row = [];
            let index = 0;
            let data1 = {
              id: data.id,
              title: data.title,
              lastDate: data.lastDate,
              startDate: data.startDate,
              applicationDate: data.applicationDate,
              meritListPublishedDate: data.meritListPublishedDate,
              shortSummary: data.shortSummary,
              meritListType: data.meritListType,
              detailedDescription: data.detailedDescription,
              paymentApplicable: data.paymentApplicable,
              paymentType: data.paymentApplicable === 0 ? "" : data.paymentType,
              amount: data.paymentApplicable === 0 ? "" : data.amount,
              instructionPrintOnChallan: data.instructionPrintOnChallan,
              paymentInstruction:
                data.instructionPrintOnChallan == 1
                  ? data.paymentInstruction
                  : "",
              highlightLable: data.highlightLable,
              lableText: data.lableText,
              showNotificationOnscreen: data.showNotificationOnscreen,
              yearId: data.year === null ? "" : data.year.id,
              undertaking: data.undertaking,
              programTypeId:
                data.programTypeId === null ? "" : data.programTypeId.id,
              progName: data.program === null ? "" : data.program.name,
              programTitleIds: data.programTitleIds,
              programId: data.program === null ? "" : data.program.id,
              programTypeName:
                data.programTypeId === null ? "" : data.programTypeId.name,
              byMinority: data.byMinority,
              minority: data.minority,
              applicationProgramTitleInstitutes:
                data.applicationProgramTitleInstitutes == null
                  ? []
                  : data.applicationProgramTitleInstitutes.map(
                      (programTitleInstitutes) => {
                        let progTitleInst = {
                          id: programTitleInstitutes.id,
                          programTitleId:
                            programTitleInstitutes.programTitle.id,
                          programTitleName:
                            programTitleInstitutes.programTitle.brName,
                          programTypeId: programTitleInstitutes.programType.id,
                          programTypeName:
                            programTitleInstitutes.programType.name,
                          tenantId: programTitleInstitutes.tenant.id,
                          tenantName: programTitleInstitutes.tenant.tenantName,
                        };
                        return progTitleInst;
                      }
                    ),
              applicationElegibilityDetails:
                data.applicationProgramTitleInstitutes == null
                  ? []
                  : data.applicationElegibilityDetails.map(
                      (elegibilityDetails) => {
                        let progTitleInst = {
                          id: elegibilityDetails.id,
                          qualificationId: elegibilityDetails.qualification.id,
                          qualification: elegibilityDetails.qualification.name,
                          marksSequenceOrderNo:
                            elegibilityDetails.marksSequenceOrderNo,
                          weightagePer: elegibilityDetails.weightagePer,
                          type: elegibilityDetails.qualification.type,
                        };
                        return progTitleInst;
                      }
                    ),
              applicationRefDocuments: data.applicationRefDocuments.map(
                (refDoc) => {
                  let refDocumentData = {
                    id: refDoc.id,
                    applicationDeclareId: refDoc.applicationDeclareId,
                    docDesc: refDoc.docDesc,
                    documentPath: refDoc.documentPath,
                  };
                  return refDocumentData;
                }
              ),

              applicationDocumentDetails: data.applicationDocumentDetails.map(
                (elegibilityDetails) => {
                  let progTitleInst = {
                    id: elegibilityDetails.id,
                    documentId: elegibilityDetails.document.id,
                    documentName: elegibilityDetails.document.name,
                    marksSequenceOrderNo:
                      elegibilityDetails.marksSequenceOrderNo,
                    remark: elegibilityDetails.remark,
                    type: elegibilityDetails.type,
                  };
                  return progTitleInst;
                }
              ),
              type: data.type,
            };

            data?.applicationElegibilityDetails.map(
              (applicationElegibilityData) => {
                index = index + 1;
                console.log(applicationElegibilityData);

                let bData = {
                  index: index,
                  eligibility: applicationElegibilityData.id,
                  name: applicationElegibilityData.qualification.name,
                  type: applicationElegibilityData.qualification.type,
                  id: applicationElegibilityData.qualification.id,
                  school: "",
                  certificateNo: "",
                  examSeatNo: "",
                  year: "",
                  marksObt: "",
                  marksOutOff: "",
                  percentage: "",
                  university: "",
                  uniValid: 0,
                  country: "",
                  countryValid: "",
                  board: "",
                  boardValid: 0,
                  examSeatNoValid: 0,
                  schoolValid: 0,
                  certificateNoValid: 0,
                  marksObtValid: 0,
                  marksOutOffValid: 0,
                  percentageValid: 0,
                  yearValid: 0,
                  isActive: "Active",
                };

                row.push(bData);
                return bData;
              }
            );
            console.log(data1);
            dispatch(applicationDocumentDetails({ row }));
            dispatch(applicatonSuccess({ data1 }));
            //  dispatch(appliSaveSuccess({applicationDeclareResult: data }));

            return data1;
          }
          return null;
        })
        .catch((error) => {
          console.log("ErrorgetDeclrationById ========>", error.response.data);
          dispatch(serverFailed());
          return false;
        });
      return courseData;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const UpdateLockDeclration =
  ({ appliId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .get(URL + "/" + appliId + "/lock-application", { headers })
        .then((response) => {
          console.log("Success With id getDeclrationById ========>", response);
          const { data } = response.data;
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          return data;
        })
        .catch((error) => {
          console.log("ErrorgetDeclrationById ========>", error.response.data);
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };
export const getApplicantByDeclrationId =
  ({ appliId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .get(URL + "/" + appliId + "/user-application", { headers })
        .then((response) => {
          console.log(
            "Success With id getDeclrationById ========>",
            response.data.data
          );
          const { data } = response.data;
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            let row = [];
            let index = 0;

            data.map((data1) => {
              let midName =
                data1.userRegistration.middleName === null
                  ? ""
                  : data1.userRegistration.middleName;

              let lstName =
                data1.userRegistration.lastName !== null
                  ? data1.userRegistration.lastName
                  : "";
              index = index + 1;
              let bData = {
                index: index,
                id: data1.id,
                userName:
                  data1.userRegistration.firstName +
                  " " +
                  midName +
                  " " +
                  lstName,
                userId: data1.userRegistration.id,
                applicationNo: data1.applicationNumber,
                declareId: data1.applicationDeclare.id,
                isActive:
                  data1.userRegistration.isActive === 1 ? "Active" : "Inactive",
                applicationElegibilityDetails:
                  data1?.userQualificationDetails.map(
                    (applicationElegibilityData, elegibilityDetailsIndex) => {
                      console.log(applicationElegibilityData);

                      let bData = {
                        index: elegibilityDetailsIndex + 1,
                        id: applicationElegibilityData.id,
                        qualification:
                          applicationElegibilityData.qualification.name,
                        type: applicationElegibilityData.qualification.type,
                        qualificationId:
                          applicationElegibilityData.qualification.id,
                        year: applicationElegibilityData.yearOfPass,
                        marksObt: applicationElegibilityData.marksObtained,
                        marksOutOff: applicationElegibilityData.marksOutof,
                        percentage: applicationElegibilityData.percentage,
                        university: applicationElegibilityData.university,
                        school: applicationElegibilityData.nameOfSchoolCollege,
                        certificateNo: applicationElegibilityData.certificateNo,
                        examSeatNo: applicationElegibilityData.examSeatNo,
                        uniValid: 0,
                        examSeatNoValid: 0,
                        schoolValid: 0,
                        certificateNoValid: 0,
                        marksObtValid: 0,
                        marksOutOffValid: 0,
                        percentageValid: 0,
                        yearValid: 0,
                      };

                      // row.push(bData);
                      return bData;
                    }
                  ),
              };
              row.push(bData);
              return bData;
            });
            dispatch(applicantSuccess({ row }));
          }
        })
        .catch((error) => {
          console.log("ErrorgetDeclrationById ========>", error.response.data);
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const changeStatus =
  ({ appliId, status }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .get(
          URL + "/change-active-status?id=" + appliId + "&status=" + status,
          { headers }
        )
        .then((response) => {
          console.log("Success With saveappli ========>", response.data.data);
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          return true;
        })
        .catch((error) => {
          console.log("Errorsaveappli ========>", error.response.data);
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const changeRoundStatus =
  ({ roundId, status }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .get(
          URL1 + "/change-active-status?id=" + roundId + "&status=" + status,
          { headers }
        )
        .then((response) => {
          console.log("Success With saveappli ========>", response.data.data);
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          return true;
        })
        .catch((error) => {
          console.log("Errorsaveappli ========>", error.response.data);
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const saveappli =
  ({ appliData }) =>
  async (dispatch) => {
    try {
      let data = JSON.stringify(appliData);

      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .post(URL, data, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            console.log("Success With saveappli ========>", response.data.data);
            const { applicationDeclareResult } = response.data.data.data;

            dispatch(applicatonSuccess({ data1: applicationDeclareResult }));
            localStorage.setItem("appId", applicationDeclareResult.id);
            return data;
          }
        })
        .catch((error) => {
          console.log("Errorsaveappli ========>", error.response.data);
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const saveQualificationDetails =
  ({ qualiData }) =>
  async (dispatch) => {
    try {
      let data = JSON.stringify(qualiData);

      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .post(URL, data, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            console.log("Success With saveappli ========>", response.data.data);
            const { applicationDeclareResult } = response.data.data.data;

            dispatch(appliSaveSuccess({ applicationDeclareResult }));
            localStorage.setItem("appId", applicationDeclareResult.id);
            return data;
          }
        })
        .catch((error) => {
          console.log("Errorsaveappli ========>", error.response.data);
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const deleteappliById =
  ({ appliId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .delete(URL + "/" + appliId, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          console.log("Success With deletedeleteappliById ========>", response);
        })
        .catch((error) => {
          console.log("Error deleteappliById ========>", error.response.data);
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const deleteRoundById =
  ({ appliId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .delete(URL1 + "/" + appliId, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          console.log("Success With deletedeleteappliById ========>", response);
        })
        .catch((error) => {
          console.log("Error deleteappliById ========>", error.response.data);
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const deleteDeclrationData = () => async (dispatch) => {
  try {
    dispatch(appliSave());
  } catch (e) {
    return console.error(e.message);
  }
};
export const resetDeclare = () => async (dispatch) => {
  try {
    dispatch(appliSave());
  } catch (e) {
    return console.error(e.message);
  }
};
export const getCourseDataById =
  ({ ids }) =>
  async (dispatch) => {
    try {
      let URLs = endpoints.course;
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };

      const doc = await axios
        .get(URLs + "/all-program-title?ids=" + ids, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            console.log(
              "Success With id getCourseDataById ========>",
              response
            );
            const { data } = response.data;

            let index = 0;

            dispatch(courseDetails({ data }));
          }
          return response;
        })
        .catch((error) => {
          console.log("ErrorgetCourseDataById ========>", error.response.data);
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const getRoundDetailsById =
  ({ capId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      let doc = await axios
        .get(URL8 + capId, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          console.log(
            "Success With id getRoundDetailsById ========>",
            response
          );
          const { data } = response.data;
          let row = [];
          let index = 0;
          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.applicationRound.name,
              isActive:
                data1.applicationRound.isActive === 1 ? "Active" : "Inactive",
            };
            row.push(bData);
          });
          dispatch(applicantRoundDetailsListSuccess({ row }));
          return row;
        })
        .catch((error) => {
          dispatch(serverFailed());
          console.log("ErrorgetDeclrationById ========>", error.response.data);
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const resetSaveDeclare = () => async (dispatch) => {
  dispatch(resetSave());
};
export const resetServer = () => async (dispatch) => {
  try {
    dispatch(resetServerSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

export const resetDelete = () => async (dispatch) => {
  try {
    dispatch(resetDeleteSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
