import AccessibleIcon from "@mui/icons-material/Accessible";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { useNavigate } from "react-router-dom";
import TabPanel from "@mui/lab/TabPanel";
import { Grid, Paper } from "@mui/material";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/system";
import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { getCategory } from "../CategoryMaster/category.slice";
import { getProfile } from "../Student/student.slice";
import { getReligion } from "../Religion/religion.slice";
import { logOut } from "../Login/login.slice";
import Alert from "../../Alert/Alert";
import * as myConstClass from "../../config/messageconstant";
import { Loading1 } from "../../components/Loading1";
import { getAlert } from "../../Alert/alert.slice";

import {
  resetServerFailed
} from "../UploadFile/file.slice";
import {
  getAnnualIncome,
  getBloodGroup,
  getState,
  saveStudent,
  getDist,
  getTal,
  getStudentById,
  resetDelete,
  saveUserIntrest,
} from "../Student/student.slice";
import PersonalDetailsForm from "./PersonalDetailsForm";
import StudAddress from "./StudAddress";
import UploadPhotoAndSign from "./UploadPhotoAndSign";
import { getProgram } from "../ProgramMaster/program.slice";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IntrestedProgramForm from "../IntresetedProgram/IntresetedProgramForm";
import StudentPreview1 from "../StudentPreview1/index";
import { LabelCompo } from "../../components/Comman/Label";
import { DirectionSnackbar } from "../../components/Comman/SnakBarCompo";

const StudentMaster = ( {
  getReligion,
  getCategory,
  getBloodGroup,
  getAnnualIncome,
  getProfile,
  saveStudent,
  getState,
  getDist,
  getTal,
  getProgram,
  saveUserIntrest,
  student,
  getStudentById,
  logOut,
  loginUser,
  resetDelete,
  religionMaster,
  categoryList,
  files,
  resetServerFailed,
  getAlert
}) => {
  const [value, setValue] = React.useState("1");
  const [viewDeclr, setViewDeclr] = React.useState(false);
  const [openSnak, setOpenSnak] = React.useState(false);

  let navigate = useNavigate();

  const data = ( instData ) =>
  {
    saveStudent( { studData: instData } ).then( ( respo ) =>
    {
      if ( !respo )
      {
        getAlert( { message: myConstClass.serverMsg } );
      }
      else
      {
        setValue( "2" );
      }
    } );
  };
  const goToForm = () => {
    setValue("1");
    setViewDeclr(false);
  };
  const studAddressData = ( instData ) =>
  {
    saveStudent( { studData: instData } ).then( ( respo ) =>
    {
      if ( !respo )
      {
        getAlert( { message: myConstClass.serverMsg } );
      }
      else
      {
        setValue( "4" );
      }
    } );
  };

  // const specialReservationDetailsData = (specialdata) => {
  //   saveStudent({ studData: specialdata }).then(() => {
  //     setValue("4");
  //   });
  // };

  const uploadPhotoAndSign = ( uploadeddata ) =>
  {
    saveStudent( { studData: uploadeddata } ).then( ( respo ) =>
    {
      if ( !respo )
      {
        getAlert( { message: myConstClass.serverMsg } );
      }
      else
      {
        setValue( "5" );
      }
    } );
  };

  // const instersteddata = (instData) => {
  //   setViewDeclr(true);
  //   saveUserIntrest({intrestedIn:instData}).then(()=>{
  //     navigate("/applicationForm");
  //   })
  //  };

  const instersteddata = ( instData ) =>
  {
    saveUserIntrest( { intrestedIn: instData } ).then( ( respo ) =>
    {
      if ( !respo )
      {
        getAlert( { message: myConstClass.serverMsg } );
      }
      else
      {
        getStudentById( { studentId: student.applicant.id } ).then( ( respo ) =>
        {
          if ( !respo )
          {
            getAlert( { message: myConstClass.serverMsg } );
          }
          else
          {
            setViewDeclr( true );
          }
        } );
      }
    } );
  };

  useEffect( () =>
  {
    getProfile().then( ( respo ) =>
    {
      if ( !respo )
      {
        getAlert( { message: myConstClass.serverMsg } );
      }
    } );
    getReligion().then( ( respo ) =>
    {
      if ( !respo )
      {
        getAlert( { message: myConstClass.serverMsg } );
      }
    } );
    getCategory().then( ( respo ) =>
    {
      if ( !respo )
      {
        getAlert( { message: myConstClass.serverMsg } );
      }
    } );
    getBloodGroup().then( ( respo ) =>
    {
      if ( !respo )
      {
        getAlert( { message: myConstClass.serverMsg } );
      }
    } );
    getAnnualIncome().then( ( respo ) =>
    {
      if ( !respo )
      {
        getAlert( { message: myConstClass.serverMsg } );
      }
    } );
    getState().then( ( respo ) =>
    {
      if ( !respo )
      {
        getAlert( { message: myConstClass.serverMsg } );
      }
    } );
    getProgram().then( ( respo ) =>
    {
      if ( !respo )
      {
        getAlert( { message: myConstClass.serverMsg } );
      }
    } );
    getDist().then( ( respo ) =>
    {
      if ( !respo )
      {
        getAlert( { message: myConstClass.serverMsg } );
      }
    } );
    getTal().then( ( respo ) =>
    {
      if ( !respo )
      {
        getAlert( { message: myConstClass.serverMsg } );
      }
    } );
  }, [] );

  useEffect(() => {
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
  });

  const changeTabs = ( event, newValue ) =>
  {
    setValue( newValue );
  };
  const gotoPersonalDetails = () =>
  {
    setValue( "1" );
  };

  const gotoAddressDetails = () =>
  {
    setValue( "2" );
  };

  const gotoSpecialReservationDetails = () =>
  {
    setValue( "3" );
  };

  const gotoInstrestedProgram = () =>
  {
    setValue( "4" );
  };

  const resetSnak = () => {
    setOpenSnak(true);
  };


  return (
    <>
      {" "}
      <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
        {/* {student.isServerFail && (
          <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />
        )}
        {categoryList.isServerFail && (
          <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />
        )}
        {religionMaster.isServerFail && (
          <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />
        )} */}
        {/* {files.isServerFail && <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete}/>} */}
        {files.isServerFail &&(
          <DirectionSnackbar
            open={true}
            severity="error"
            msg={"Failed to upload"}
            closeSnak={resetServerFailed}
          />
        )}

        {!viewDeclr && (
          <>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              spacing={2}
              columnSpacing={1}
              container
              sx={{ m: 0.5 }}
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                {/* <Grid item xs={12} sm={12} md={0.5}></Grid> */}
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 20 }}
                    label="Update Profile"
                  />
                </Grid>
              </Grid>
            </Grid>
            <br />
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={changeTabs}
                    aria-label="lab API tabs example"
                  >
                    <Tab
                      icon={<InfoIcon />}
                      textColor="secondary"
                      indicatorcolor="secondary"
                      label="Basic Details"
                      value="1"
                    />
                    <Tab
                      icon={<HomeIcon />}
                      textColor="secondary"
                      indicatorcolor="secondary"
                      label="Address Details"
                      value="2"
                      {...(Object.keys(student.applicant).length === 0
                        ? {
                            disabled: true,
                          }
                        : student.applicant.type == 0
                        ? {
                            disabled: true,
                          }
                        : {
                            disabled: false,
                          })}
                    />
                    {/* <Tab
                    icon={<AccessibleIcon />}
                    textColor="secondary"
                    indicatorcolor="secondary"
                    label="Special Reservation Details"
                    value="3"
                    {...(Object.keys(student.applicant).length === 0
                      ? {
                          disabled: true,
                        }
                      : student.applicant.type < 2
                      ? {
                          disabled: true,
                        }
                      : {
                          disabled: false,
                        })}
                  /> */}

                    <Tab
                      icon={<PhotoSizeSelectActualIcon />}
                      textColor="secondary"
                      indicatorcolor="secondary"
                      label="Photo and Sign"
                      value="4"
                      {...(Object.keys(student.applicant).length === 0
                        ? {
                            disabled: true,
                          }
                        : student.applicant.type < 3
                        ? {
                            disabled: true,
                          }
                        : {
                            disabled: false,
                          })}
                    />

                    <Tab
                      icon={<CheckBoxIcon />}
                      textColor="secondary"
                      indicatorcolor="secondary"
                      label="Interested Program"
                      value="5"
                      {...(Object.keys(student.applicant).length === 0
                        ? {
                            disabled: true,
                          }
                        : student.applicant.type < 4
                        ? {
                            disabled: true,
                          }
                        : {
                            disabled: false,
                          })}
                    />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  {<PersonalDetailsForm OnSave={data} />}
                </TabPanel>

                <TabPanel value="2">
                  {
                    <StudAddress
                      onCancel={gotoPersonalDetails}
                      studAddressData={studAddressData}
                    />
                  }
                </TabPanel>

                {/* <TabPanel value="3">
                {
                  <SpecialReservationDetails
                    onCancel={gotoAddressDetails}
                    specialReservationDetailsData={
                      specialReservationDetailsData
                    }
                  />
                }
              </TabPanel> */}

                <TabPanel value="4">
                  {
                    <UploadPhotoAndSign
                      onCancel={gotoAddressDetails}
                      uploadphotoandsign={uploadPhotoAndSign}
                    />
                  }
                </TabPanel>

                <TabPanel value="5">
                  {
                    <IntrestedProgramForm
                      OnSave={instersteddata}
                      gotoInstrestedProgram={gotoInstrestedProgram}
                      onCancel={gotoInstrestedProgram}
                    />
                  }
                </TabPanel>
              </TabContext>
            </Box>
          </>
        )}
        {viewDeclr && (
          <StudentPreview1
            data={student.applicant}
            studFlag={true}
            goToStudentForm={goToForm}
          />
        )}
      </Paper>
      {/* {studAddOpen && !spReservOpen && (
        <StudAddress studAddressData={studAddressData} />
      )}
      {!studAddOpen && spReservOpen && <SpecialReservationDetails />}
      {!studAddOpen && !spReservOpen && <PersonalDetailsForm OnSave={data} />} */}
    </>
  );
};
const mapStateToProps = ( state ) => ( {
  religionMaster: state.religion,
  categoryList: state.category,
  student: state.student,
  loginUser: state.user,
  files: state.file,
});
const mapDispatchToProps = {
  getReligion,
  getCategory,
  getBloodGroup,
  getAnnualIncome,
  getProfile,
  saveStudent,
  getState,
  getDist,
  getTal,
  getProgram,
  saveUserIntrest,
  getStudentById,
  logOut,
  resetDelete,
  resetServerFailed,
  getAlert
};
export default connect( mapStateToProps, mapDispatchToProps )( StudentMaster );




