import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { decodeData } from "../../components/Comman/Util/Base64";
import endpoints from "../../config/endpoints";
import { tokenExp } from "../Login/login.slice";
const initialState = {
  applicant: [],
  appliData: {},
  capRoundData: [],
  distinctInstituteList: [],
  distinctProgramTitleList: [],
  progTitleInstList: [],
  instProgTitleList: [],
  qualiData: [],
  userApplicationData: {},
  preferenceData: [],
  ShortNameData: [],
  branchMaster: [],
  isFetchPref: false,
  prefCapList: [],
  roundDetailsList: [],
  confirmPass: "",
  isFetch: false,
  isDistinctInst: false,
  isDistinctProgTitle: false,
  isFetchPreferenceData: false,
  isFetchConfirm: false,
  isFetchApplicant: false,
  isFetchShortName: false,
  isFetchUserAppli: false,
  isFetchRoundList: false,
  error: false,
  isServerFail: false,
  isDelete: false,
  isFetchApplicantData: false,
  applicantData: {},
  isSave: false,
  paymentHashcode: {},
  paymentHashcodeFetch: false,
  saveApplicantData: {},
  isFetchSaveApplicantData: false,
  savePaymentTransactionPojo: {},
  isFetchSavePaymentTransactionPojo: false,
};
let URL = endpoints.application;
let URL1 = endpoints.applicant;
let URL2 = endpoints.instituteList;
let URL3 = endpoints.programTitleList;
let URL4 = endpoints.confirmPassword;
let URL5 = endpoints.preferenceSave;
let URL6 = endpoints.getUserApplication;
let URL8 = endpoints.getRoundDetails;
let URL7 = endpoints.getApplicationRoundByAppliId;
let URL9 = endpoints.paymentUrl;
let URL10 = endpoints.userDirectAdmission;
let URL11 = endpoints.paymentTransaction;

const studentDirectAdmissionSlice = createSlice({
  name: "studentDirectAdmissionSlice",
  initialState,
  reducers: {
    resetDeleteSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error: false,
        isDelete: false,
        isSave: false,
        isServerFail: false,
      };
    },
    applicantSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        isFetchApplicant: true,
        applicant: row,
      };
    },
    applicantFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isFetchApplicant: false,
        applicant: [],
      };
    },

    confirmPasswordSuccess: (state = cloneDeep(initialState), action) => {
      const { message } = action.payload;
      return {
        ...state,
        isFetchConfirm: true,
        confirmPass: message,
      };
    },

    applicantCapRoundSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        isFetch: true,
        capRoundData: data,
      };
    },
    applicantPrefCapListSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        isFetchPref: true,
        prefCapList: data,
      };
    },
    applicantDetailsSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        isFetchApplicantData: true,
        applicantData: data,
      };
    },
    applicantDataSuccess: (state = cloneDeep(initialState), action) => {
      const { userData } = action.payload;
      return {
        ...state,
        isFetch: true,
        appliData: userData,
      };
    },
    userAppDataSuccess: (state = cloneDeep(initialState), action) => {
      const { userData } = action.payload;
      return {
        ...state,
        isFetchUserAppli: true,
        userApplicationData: userData,
      };
    },
    applicantDataFail: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isFetch: false,
        appliData: {},
      };
    },
    progTitleInstituteListSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        isFetch: true,
        progTitleInstList: row,
      };
    },
    distinctInstituteSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        isDistinctInst: true,
        distinctInstituteList: row,
      };
    },
    distinctProgTitleSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        isDistinctProgTitle: true,
        distinctProgramTitleList: row,
      };
    },
    applicantRoundDetailsListSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        isFetchRoundList: true,
        roundDetailsList: row,
      };
    },

    instituteProgTitleListSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        isFetch: true,
        instProgTitleList: row,
      };
    },

    qualificationDataSuccess: (state = cloneDeep(initialState), action) => {
      const { userApplicationSave } = action.payload;
      return {
        ...state,
        isFetch: true,
        qualiData: userApplicationSave,
      };
    },

    userApplicationDataSuccess: (state = cloneDeep(initialState), action) => {
      const { userApplicationData } = action.payload;
      return {
        ...state,
        isFetchUserAppli: true,
        userApplicationData: userApplicationData,
      };
    },

    preferenceDataSuccess: (state = cloneDeep(initialState), action) => {
      const { userApplicationSave } = action.payload;
      return {
        ...state,
        isFetchPreferenceData: true,
        preferenceData: userApplicationSave,
      };
    },

    applicantDataFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isFetch: false,
        appliData: {},
      };
    },
    serverFailed: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: true,
        error: false,
        isDelete: false,
        isSave: false,
      };
    },

    paymentHashcodeSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        paymentHashcode: data,
        paymentHashcodeFetch: true,
      };
    },
    saveApplicantData: (state = cloneDeep(initialState), action) => {
      const { appliData } = action.payload;
      return {
        ...state,
        saveApplicantData: appliData,
        isFetchSaveApplicantData: true,
      };
    },

    savePaymentTransactionPojoSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { appliData } = action.payload;
      return {
        ...state,
        savePaymentTransactionPojo: appliData,
        isFetchSavePaymentTransactionPojo: true,
      };
    },
  },
});

export const {
  distinctProgTitleSuccess,
  distinctInstituteSuccess,
  resetDeleteSuccess,
  serverFailed,
  appliSuccess,
  appliSave,
  applicantDetailsSuccess,
  appliFailure,
  appliSaveSuccess,
  applicantSuccess,
  applicantFailure,
  appliSaveFailure,
  appliListByShortNameFailure,
  appliListByShortNameSuccess,
  appliListByNameFailure,
  applicantDataFail,
  applicantDataSuccess,
  applicantPrefCapListSuccess,
  applicantCapRoundSuccess,
  userApplicationDataSuccess,
  confirmPasswordSuccess,
  progTitleInstituteListSuccess,
  instituteProgTitleListSuccess,
  applicantDataFailure,
  appliListByNameSuccess,
  qualificationDataSuccess,
  userAppDataSuccess,
  preferenceDataSuccess,
  applicantRoundDetailsListSuccess,
  paymentHashcodeSuccess,
  saveApplicantData,
  savePaymentTransactionPojoSuccess,
} = studentDirectAdmissionSlice.actions;

export default studentDirectAdmissionSlice.reducer;

export const getApplicantById =
  ({ appliId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const docs = await axios
        .get(URL1 + "/" + appliId, {
          headers,
        })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            const { data } = response.data;
            let userData = {
              id: data.id,
              fatherName: data.userRegistration.fatherName,
              // isActive: data.user.isActive === 1 ? "Active" : "Inactive",

              aadhaarNo: data.userRegistration.aadhaarNo,
              annualIncome: data.userRegistration.annualIncome,
              birthPlace: data.userRegistration.birthPlace,
              bloodGroup: data.userRegistration.bloodGroup,
              cast: data.userRegistration.cast,
              castCertificateDate: data.userRegistration.castCertificateDate,
              castCertificateNo: data.userRegistration.castCertificateNo,
              category: data.userRegistration.category.name,
              categoryId: data.userRegistration.category.id,
              dateOfBirth: data.userRegistration.dateOfBirth,
              defenceType: data.userRegistration.defenceType,
              domicileof: data.userRegistration.domicileof,
              fatherName: data.userRegistration.fatherName,
              fatherOccupation: data.userRegistration.fatherOccupation,
              firstName: data.userRegistration.firstName,
              gender: data.userRegistration.gender,
              handicapCategory: data.userRegistration.handicapCategory,
              isOrphan: data.userRegistration.isOrphan,
              isStudentNriForeignNational:
                data.userRegistration.isStudentNriForeignNational,
              landlineNo: data.userRegistration.landlineNo,
              lastName:
                data.userRegistration.lastName !== null
                  ? data.userRegistration.lastName
                  : "",
              martialStatus: data.userRegistration.martialStatus,
              middleName:
                data.userRegistration.middleName !== null
                  ? data.userRegistration.middleName
                  : "",
              minority: data.userRegistration.minority,
              motherName: data.userRegistration.motherName,
              motherTongue: data.userRegistration.motherTongue,
              nationality: data.userRegistration.nationality,
              oms: data.userRegistration.oms,
              parentEmail: data.userRegistration.parentEmail,
              parentMobile: data.userRegistration.parentMobile,
              permanentAddressLine1:
                data.userRegistration.permanentAddressLine1,
              permanentAddressLine2:
                data.userRegistration.permanentAddressLine2,
              permanentAddressLine3:
                data.userRegistration.permanentAddressLine3,
              permanentCity: data.userRegistration.permanentCity,
              permanentDistrictName: data.userRegistration.permanentDistrict,
              permanentPin: data.userRegistration.permanentPin,
              permanentState: data.userRegistration.permanentState,
              permanentTalukaName: data.userRegistration.permanentTaluka,
              profilePic: data.userRegistration.profilePic,
              religion: data.userRegistration.religion,
              remark: data.userRegistration.remark,
              ruralUrban: data.userRegistration.ruralUrban,
              scholarship: data.userRegistration.scholarship,
              signature: data.userRegistration.signature,
              studentEmail: data.userRegistration.studentEmail,
              studentMobile: data.userRegistration.studentMobile,
              subCast: data.userRegistration.subCast,
              temporaryAddressLine1:
                data.userRegistration.temporaryAddressLine1,
              temporaryAddressLine2:
                data.userRegistration.temporaryAddressLine2,
              temporaryAddressLine3:
                data.userRegistration.temporaryAddressLine3,
              temporaryCity: data.userRegistration.temporaryCity,
              temporaryDistrict: data.userRegistration.temporaryDistrict,
              temporaryPin: data.userRegistration.temporaryPin,
              temporaryState: data.userRegistration.temporaryState,
              temporaryTaluka: data.userRegistration.temporaryTaluka,
              validityCdDate: data.userRegistration.validityCdDate,
              validityCdNo: data.userRegistration.validityCdNo,
              userQualificationDetails: data.userQualificationDetails.map(
                (userQualificationData) => {
                  let docs = {
                    qualification: userQualificationData.qualification.name,
                    yearOfPass: userQualificationData.yearOfPass,
                    percentage: userQualificationData.percentage,
                    marksObtained: userQualificationData.marksObtained,
                    marksOutof: userQualificationData.marksOutof,
                    school: userQualificationData.nameOfSchoolCollege,
                    examSeatNo: userQualificationData.examSeatNo,
                    certificateNo: userQualificationData.certificateNo,
                  };
                  return docs;
                }
              ),

              userDocumentDetails: data.userDocumentDetails.map((data) => {
                let docss = {
                  documentFileName:
                    data.userDocumentDetails.documentFileName.name,
                };
                return docss;
              }),
            };
            dispatch(applicantDataSuccess({ userData }));
          }
        })
        .catch((error) => {
          console.log("ErrorgetDeclrationById ========>", error.response.data);
          dispatch(serverFailed());
          return false;
        });
      return docs;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const getApplicantPrefById =
  ({ capId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      let docs = await axios
        .get(
          URL5 +
            "/get-user-application-round-detail-by-round-id?roundId=" +
            capId,
          { headers }
        )
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          console.log("Success With id getDeclrationById ========>", response);
          const { data } = response.data;
          dispatch(applicantPrefCapListSuccess({ data }));
          return response;
        })
        .catch((error) => {
          console.log("ErrorgetDeclrationById ========>", error.response.data);
          dispatch(serverFailed());
          return false;
        });
      return docs;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };
export const getApplicantPrefByAppId =
  ({ appliId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };

      let docs = await axios
        .get(URL5 + "/" + appliId, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          console.log(
            "Success With id getApplicantPrefByAppId ========>",
            response
          );
          const { data } = response.data;
          let userData = {
            id: data.id,
            fatherName: data.userApplication.userRegistration.fatherName,

            aadhaarNo: data.userApplication.userRegistration.aadhaarNo,
            annualIncome: data.userApplication.userRegistration.annualIncome.id,
            annualIncomeName:
              data.userApplication.userRegistration.annualIncome.name,
            birthPlace: data.userApplication.userRegistration.birthPlace,
            bloodGroup: data.userApplication.userRegistration.bloodGroup.id,
            bloodGroupName:
              data.userApplication.userRegistration.bloodGroup.name,
            cast: data.userApplication.userRegistration.cast,
            castCertificateDate:
              data.userApplication.userRegistration.castCertificateDate,
            castCertificateNo:
              data.userApplication.userRegistration.castCertificateNo,
            category: data.userApplication.userRegistration.category.name,
            categoryId: data.userApplication.userRegistration.category.id,
            dateOfBirth: data.userApplication.userRegistration.dateOfBirth,
            defenceType: data.userApplication.userRegistration.defenceType,
            domicileof: data.userApplication.userRegistration.domicileof,
            fatherName: data.userApplication.userRegistration.fatherName,
            fatherOccupation:
              data.userApplication.userRegistration.fatherOccupation,
            firstName: data.userApplication.userRegistration.firstName,
            gender: data.userApplication.userRegistration.gender,
            handicapCategory:
              data.userApplication.userRegistration.handicapCategory,
            isOrphan: data.userApplication.userRegistration.isOrphan,
            isStudentNriForeignNational:
              data.userApplication.userRegistration.isStudentNriForeignNational,
            landlineNo: data.userApplication.userRegistration.landlineNo,
            lastName:
              data.userApplication.userRegistration.lastName !== null
                ? data.userApplication.userRegistration.lastName
                : "",
            martialStatus: data.userApplication.userRegistration.martialStatus,
            middleName:
              data.userApplication.userRegistration.middleName !== null
                ? data.userApplication.userRegistration.middleName
                : "",
            minority: data.userApplication.userRegistration.minority,
            motherName: data.userApplication.userRegistration.motherName,
            motherTongue: data.userApplication.userRegistration.motherTongue,
            nationality: data.userApplication.userRegistration.nationality,
            oms: data.userApplication.userRegistration.oms,
            parentEmail: data.userApplication.userRegistration.parentEmail,
            parentMobile: data.userApplication.userRegistration.parentMobile,
            permanentAddressLine1:
              data.userApplication.userRegistration.permanentAddressLine1,
            permanentAddressLine2:
              data.userApplication.userRegistration.permanentAddressLine2,
            permanentAddressLine3:
              data.userApplication.userRegistration.permanentAddressLine3,
            permanentCity: data.userApplication.userRegistration.permanentCity,
            permanentDistrictName:
              data.userApplication.userRegistration.permanentDistrict.name,
            permanentDistrictId:
              data.userApplication.userRegistration.permanentDistrict.id,
            permanentPin: data.userApplication.userRegistration.permanentPin,
            permanentStateName:
              data.userApplication.userRegistration.permanentState.name,
            permanentStateId:
              data.userApplication.userRegistration.permanentState.id,
            permanentTalukaName:
              data.userApplication.userRegistration.permanentTaluka.name,
            permanentTalukaId:
              data.userApplication.userRegistration.permanentTaluka.id,
            profilePic: data.userApplication.userRegistration.profilePic,
            religionId: data.userApplication.userRegistration.religion.id,
            religion: data.userApplication.userRegistration.religion.name,
            remark: data.userApplication.userRegistration.remark,
            ruralUrban: data.userApplication.userRegistration.ruralUrban,
            scholarship: data.userApplication.userRegistration.scholarship,
            signature: data.userApplication.userRegistration.signature,
            studentEmail: data.userApplication.userRegistration.studentEmail,
            studentMobile: data.userApplication.userRegistration.studentMobile,
            subCast: data.userApplication.userRegistration.subCast,
            temporaryAddressLine1:
              data.userApplication.userRegistration.temporaryAddressLine1,
            temporaryAddressLine2:
              data.userApplication.userRegistration.temporaryAddressLine2,
            temporaryAddressLine3:
              data.userApplication.userRegistration.temporaryAddressLine3,
            temporaryCity: data.userApplication.userRegistration.temporaryCity,
            temporaryDistrictName:
              data.userApplication.userRegistration.temporaryDistrict.name,
            temporaryDistrictId:
              data.userApplication.userRegistration.temporaryDistrict.id,
            temporaryPin: data.userApplication.userRegistration.temporaryPin,
            temporaryStateName:
              data.userApplication.userRegistration.temporaryState.name,
            temporaryStateId:
              data.userApplication.userRegistration.temporaryState.id,
            temporaryTalukaName:
              data.userApplication.userRegistration.temporaryTaluka.name,
            temporaryTalukaId:
              data.userApplication.userRegistration.temporaryTaluka.id,
            validityCdDate:
              data.userApplication.userRegistration.validityCdDate,
            validityCdNo: data.userApplication.userRegistration.validityCdNo,
            userQualificationDetails:
              data.userApplication.userQualificationDetails.map(
                (userQualificationData) => {
                  let docs = {
                    qualification: userQualificationData.qualification.name,
                    university: userQualificationData.university,
                    yearOfPass: userQualificationData.yearOfPass,
                    percentage: userQualificationData.percentage,
                    marksObtained: userQualificationData.marksObtained,
                    marksOutof: userQualificationData.marksOutof,
                    school: userQualificationData.nameOfSchoolCollege,
                    examSeatNo: userQualificationData.examSeatNo,
                    certificateNo: userQualificationData.certificateNo,
                    country: userQualificationData.country,
                    board: userQualificationData.board,
                  };
                  return docs;
                }
              ),

            userDocumentDetails: data.userApplication.userDocumentDetails.map(
              (userDocumentData) => {
                let docss = {
                  documentFileName: userDocumentData.documentFileName,
                  document: userDocumentData.document.name,
                };
                return docss;
              }
            ),
            userRoundPrefrences: data.userRoundPrefrences,
          };
          console.log(userData);
          dispatch(applicantDetailsSuccess({ data: userData }));
        })
        .catch((error) => {
          console.log("ErrorgetDeclrationById ========>", error.response.data);
          dispatch(serverFailed());
          return false;
        });
      return docs;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const getCourseByInst =
  ({ appliId, progTypeId, instId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      console.log(appliId + "--" + progTypeId + "--" + instId);
      let docs = await axios
        .get(
          URL3 +
            "?id=" +
            appliId +
            "&progTypeId=" +
            progTypeId +
            "&instId=" +
            instId,
          { headers }
        )
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          console.log("Success With id getDeclrationById ========>", response);
          const { data } = response.data;
          let row = [];
          let index = 0;
          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.brName,
              isActive: data1.isActive === 1 ? "Active" : "Inactive",
            };
            row.push(bData);
          });
          dispatch(instituteProgTitleListSuccess({ row }));
        })
        .catch((error) => {
          dispatch(serverFailed());
          return false;
        });
      return docs;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const getInstituteCoruseById =
  ({ appliId, progTypeId, progTitleId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      let docs = await axios
        .get(
          URL2 +
            "?id=" +
            appliId +
            "&progTypeId=" +
            progTypeId +
            "&programTitleId=" +
            progTitleId,
          { headers }
        )
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          console.log("Success With id getDeclrationById ========>", response);
          const { data } = response.data;
          let row = [];
          let index = 0;
          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.tenantName,
              instituteType: data1.instituteType,
              autonomousStatus: data1.autonomousStatus,
              minority: data1.minority,
              isActive: data1.isActive === 1 ? "Active" : "Inactive",
            };
            row.push(bData);
          });
          dispatch(progTitleInstituteListSuccess({ row }));
        })
        .catch((error) => {
          dispatch(serverFailed());
          return false;
        });
      return docs;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const getDistinctProgramTitle =
  ({ appliId, progTypeId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      let docs = await axios
        .get(
          URL +
            "/distinct-program-title-by-applicationid-and-program-typeid?id=" +
            appliId +
            "&progTypeId=" +
            progTypeId,
          { headers }
        )
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          console.log(
            "Success With id getDistinctProgramTitle ========>",
            response
          );
          const { data } = response.data;
          let row = [];
          let index = 0;
          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.brName,
              instituteType: data1.instituteType,
              autonomousStatus: data1.autonomousStatus,
              minority: data1.minority,
              isActive: data1.isActive === 1 ? "Active" : "Inactive",
            };
            row.push(bData);
          });
          dispatch(distinctProgTitleSuccess({ row }));
          return response;
        })
        .catch((error) => {
          dispatch(serverFailed());
          return false;
        });
      return docs;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const getDistictInstitute =
  ({ appliId, progTypeId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      let docs = await axios
        .get(
          URL +
            "/distinct-institute-by-applicationid-and-program-typeid?id=" +
            appliId +
            "&progTypeId=" +
            progTypeId,
          { headers }
        )
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          console.log(
            "Success With id getDistinctInstitute ========>",
            response
          );
          const { data } = response.data;
          let row = [];
          let index = 0;
          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.tenantName,
              instituteType: data1.instituteType,
              autonomousStatus: data1.autonomousStatus,
              minority: data1.minority,
              isActive: data1.isActive === 1 ? "Active" : "Inactive",
            };
            row.push(bData);
          });
          dispatch(distinctInstituteSuccess({ row }));
          return response;
        })
        .catch((error) => {
          dispatch(serverFailed());
          return false;
        });
      return docs;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const saveQualificationDetails =
  ({ qualiData }) =>
  async (dispatch) => {
    try {
      let data = JSON.stringify(qualiData);
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      let docs = await axios
        .post(URL1, data, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          console.log("Success With saveappli ========>", response.data.data);
          const { userApplicationSave } = response.data.data.data;
          dispatch(qualificationDataSuccess({ userApplicationSave }));
          return data;
        })
        .catch((error) => {
          dispatch(serverFailed());
          return false;
        });
      return docs;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const saveConfirmPassword =
  ({ qualiData }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      let docs = await axios
        .get(URL4 + "?password=" + qualiData, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          console.log("Success With saveappli ========>", response);
          const { message } = response.data.data;
          dispatch(confirmPasswordSuccess({ message }));
          return message;
        })
        .catch((error) => {
          dispatch(serverFailed());
          return false;
        });
      return docs;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const getCapRoundById =
  ({ appliId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      let docs = await axios
        .get(URL + "/" + appliId + "/application-round", { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          console.log("Success With id getCapRoundById ========>", response);
          const { data } = response.data;
          let row = [];
          dispatch(applicantCapRoundSuccess({ data }));
          return response;
        })
        .catch((error) => {
          dispatch(serverFailed());
          return false;
        });
      return docs;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const saveApplicant =
  ({ appliData }) =>
  async (dispatch) => {
    try {
      let data = JSON.stringify(appliData);
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      let docs = await axios
        .post(URL10, data, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          console.log("Success With saveappli ========>", response.data.data);
          const { userApplicationSave } = response.data.data;
          dispatch(qualificationDataSuccess({ userApplicationSave }));
          return true;
        })
        .catch((error) => {
          dispatch(serverFailed());
          return false;
        });
      return docs;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const saveQualificationByTrust =
  ({ appliData }) =>
  async (dispatch) => {
    try {
      let data = JSON.stringify(appliData);
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      await axios
        .post(URL1 + "/user-qualification-detail", data, { headers })
        .then((response) => {
          console.log("Success With saveappli ========>", response.data.data);
          const userApplicationSave = response.data.data;
          dispatch(qualificationDataSuccess({ userApplicationSave }));
          return data;
        })
        .catch((error) => {
          dispatch(serverFailed());
          return false;
        });
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };
export const getApplicantDetailsById =
  ({ appliId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      let docs = await axios
        .get(URL1 + "/" + appliId, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          console.log("Success With id getCapRoundById ========>", response);
          const { data } = response.data;

          let userData = {
            id: data.id,
            fatherName: data.userRegistration.fatherName,
            aadhaarNo: data.userRegistration.aadhaarNo,
            annualIncomeName: data.userRegistration.annualIncome.name,
            birthPlace: data.userRegistration.birthPlace,
            bloodGroup: data.userRegistration.bloodGroup.name,
            cast: data.userRegistration.cast,
            castCertificateDate: data.userRegistration.castCertificateDate,
            castCertificateNo: data.userRegistration.castCertificateNo,
            category: data.userRegistration.category.name,
            dateOfBirth: data.userRegistration.dateOfBirth,
            defenceType: data.userRegistration.defenceType,
            domicileof: data.userRegistration.domicileof,
            fatherName: data.userRegistration.fatherName,
            fatherOccupation: data.userRegistration.fatherOccupation,
            firstName: data.userRegistration.firstName,
            gender: data.userRegistration.gender,
            handicapCategory: data.userRegistration.handicapCategory,
            isOrphan: data.userRegistration.isOrphan,
            isStudentNriForeignNational:
              data.userRegistration.isStudentNriForeignNational,
            landlineNo: data.userRegistration.landlineNo,
            lastName:
              data.userRegistration.lastName !== null
                ? data.userRegistration.lastName
                : "",
            martialStatus: data.userRegistration.martialStatus,
            middleName:
              data.userRegistration.middleName !== null
                ? data.userRegistration.middleName
                : "",
            minority: data.userRegistration.minority,
            motherName: data.userRegistration.motherName,
            motherTongue: data.userRegistration.motherTongue,
            nationality: data.userRegistration.nationality,
            oms: data.userRegistration.oms,
            parentEmail: data.userRegistration.parentEmail,
            parentMobile: data.userRegistration.parentMobile,
            permanentAddressLine1: data.userRegistration.permanentAddressLine1,
            permanentAddressLine2: data.userRegistration.permanentAddressLine2,
            permanentAddressLine3: data.userRegistration.permanentAddressLine3,
            permanentCity: data.userRegistration.permanentCity,
            permanentDistrictName: data.userRegistration.permanentDistrict.name,
            permanentPin: data.userRegistration.permanentPin,
            permanentStateName: data.userRegistration.permanentState.name,
            permanentTalukaName: data.userRegistration.permanentTaluka.name,
            profilePic: data.userRegistration.profilePic,
            religion: data.userRegistration.religion.name,
            remark: data.userRegistration.remark,
            ruralUrban: data.userRegistration.ruralUrban,
            scholarship: data.userRegistration.scholarship,
            signature: data.userRegistration.signature,
            studentEmail: data.userRegistration.studentEmail,
            studentMobile: data.userRegistration.studentMobile,
            subCast: data.userRegistration.subCast,
            temporaryAddressLine1: data.userRegistration.temporaryAddressLine1,
            temporaryAddressLine2: data.userRegistration.temporaryAddressLine2,
            temporaryAddressLine3: data.userRegistration.temporaryAddressLine3,
            temporaryCity: data.userRegistration.temporaryCity,
            temporaryDistrictName: data.userRegistration.temporaryDistrict.name,
            temporaryPin: data.userRegistration.temporaryPin,
            temporaryStateName: data.userRegistration.temporaryState.name,
            temporaryTalukaName: data.userRegistration.temporaryTaluka.name,
            validityCdDate: data.userRegistration.validityCdDate,
            validityCdNo: data.userRegistration.validityCdNo,
            paidStatus: data.paidStatus,

            userQualificationDetails: data.userQualificationDetails.map(
              (userQualificationData) => {
                let docs = {
                  type: userQualificationData.qualification.type,
                  qualification: userQualificationData.qualification.name,
                  yearOfPass: userQualificationData.yearOfPass,
                  percentage: userQualificationData.percentage,
                  marksObtained: userQualificationData.marksObtained,
                  marksOutof: userQualificationData.marksOutof,
                  university: userQualificationData.university,
                  school: userQualificationData.nameOfSchoolCollege,
                  examSeatNo: userQualificationData.examSeatNo,
                  certificateNo: userQualificationData.certificateNo,
                  country: userQualificationData.country,
                  state: userQualificationData.state,
                  board: userQualificationData.board,
                };
                return docs;
              }
            ),

            userDocumentDetails: data.userDocumentDetails.map(
              (userDocumentData) => {
                let docss = {
                  document: userDocumentData.document.name,
                  documentFileName: userDocumentData.documentFileName,
                };
                return docss;
              }
            ),
          };
          console.log(userData);
          dispatch(applicantDataSuccess({ userData }));
        })
        .catch((error) => {
          dispatch(serverFailed());
          console.log("Error getCapRoundById ========>", error);
          return false;
        });
      return docs;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };
export const saveRoundPreference =
  ({ roundData }) =>
  async (dispatch) => {
    try {
      let data = JSON.stringify(roundData);
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      let docs = await axios
        .post(URL5, data, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          console.log("Success With saveappli ========>", response);
          const { roundPreference } = response.data.data;
          dispatch(preferenceDataSuccess({ roundPreference }));

          return data;
        })
        .catch((error) => {
          dispatch(serverFailed());
          console.log("Errorsaveappli ========>", error.response.data);
          return false;
        });
      return docs;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const getApplicant =
  ({ appliId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      let docs = await axios
        .get(URL6 + "?applicationId=" + appliId, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          console.log("Success With id getDeclrationById ========>", response);
          const { data } = response.data;
          let userData = {
            id: data.id,
            fatherName: data.userRegistration.fatherName,
            // isActive: data.user.isActive === 1 ? "Active" : "Inactive",

            aadhaarNo: data.userRegistration.aadhaarNo,
            annualIncome: data.userRegistration.annualIncome.id,
            annualIncomeName: data.userRegistration.annualIncome.name,
            birthPlace: data.userRegistration.birthPlace,
            bloodGroup: data.userRegistration.bloodGroup.id,
            bloodGroupName: data.userRegistration.bloodGroup.name,
            cast: data.userRegistration.cast,
            castCertificateDate: data.userRegistration.castCertificateDate,
            castCertificateNo: data.userRegistration.castCertificateNo,
            category: data.userRegistration.category.name,
            categoryId: data.userRegistration.category.id,
            dateOfBirth: data.userRegistration.dateOfBirth,
            defenceType: data.userRegistration.defenceType,
            domicileof: data.userRegistration.domicileof,
            fatherName: data.userRegistration.fatherName,
            fatherOccupation: data.userRegistration.fatherOccupation,
            firstName: data.userRegistration.firstName,
            gender: data.userRegistration.gender,
            handicapCategory: data.userRegistration.handicapCategory,
            isOrphan: data.userRegistration.isOrphan,
            isStudentNriForeignNational:
              data.userRegistration.isStudentNriForeignNational,
            landlineNo: data.userRegistration.landlineNo,
            lastName:
              data.userRegistration.lastName !== null
                ? data.userRegistration.lastName
                : "",
            martialStatus: data.userRegistration.martialStatus,
            middleName:
              data.userRegistration.middleName !== null
                ? data.userRegistration.middleName
                : "",
            minority: data.userRegistration.minority,
            motherName: data.userRegistration.motherName,
            motherTongue: data.userRegistration.motherTongue,
            nationality: data.userRegistration.nationality,
            oms: data.userRegistration.oms,
            parentEmail: data.userRegistration.parentEmail,
            parentMobile: data.userRegistration.parentMobile,
            permanentAddressLine1: data.userRegistration.permanentAddressLine1,
            permanentAddressLine2: data.userRegistration.permanentAddressLine2,
            permanentAddressLine3: data.userRegistration.permanentAddressLine3,
            permanentCity: data.userRegistration.permanentCity,
            permanentDistrictName: data.userRegistration.permanentDistrict.name,
            permanentDistrictId: data.userRegistration.permanentDistrict.id,
            permanentPin: data.userRegistration.permanentPin,
            permanentStateName: data.userRegistration.permanentState.name,
            permanentStateId: data.userRegistration.permanentState.id,
            permanentTalukaName: data.userRegistration.permanentTaluka.name,
            permanentTalukaId: data.userRegistration.permanentTaluka.id,
            profilePic: data.userRegistration.profilePic,
            religionId: data.userRegistration.religion.id,
            religion: data.userRegistration.religion.name,
            remark: data.userRegistration.remark,
            ruralUrban: data.userRegistration.ruralUrban,
            scholarship: data.userRegistration.scholarship,
            signature: data.userRegistration.signature,
            studentEmail: data.userRegistration.studentEmail,
            studentMobile: data.userRegistration.studentMobile,
            subCast: data.userRegistration.subCast,
            temporaryAddressLine1: data.userRegistration.temporaryAddressLine1,
            temporaryAddressLine2: data.userRegistration.temporaryAddressLine2,
            temporaryAddressLine3: data.userRegistration.temporaryAddressLine3,
            temporaryCity: data.userRegistration.temporaryCity,
            temporaryDistrictName: data.userRegistration.temporaryDistrict.name,
            temporaryDistrictId: data.userRegistration.temporaryDistrict.id,
            temporaryPin: data.userRegistration.temporaryPin,
            temporaryStateName: data.userRegistration.temporaryState.name,
            temporaryStateId: data.userRegistration.temporaryState.id,
            temporaryTalukaName: data.userRegistration.temporaryTaluka.name,
            temporaryTalukaId: data.userRegistration.temporaryTaluka.id,
            validityCdDate: data.userRegistration.validityCdDate,
            validityCdNo: data.userRegistration.validityCdNo,
            userQualificationDetails: data.userQualificationDetails.map(
              (userQualificationData) => {
                let docs = {
                  qualification: userQualificationData.qualification.name,
                  yearOfPass: userQualificationData.yearOfPass,
                  percentage: userQualificationData.percentage,
                  marksObtained: userQualificationData.marksObtained,
                  marksOutof: userQualificationData.marksOutof,
                  university: userQualificationData.university,
                  state: userQualificationData.state,
                  country: userQualificationData.country,
                  board: userQualificationData.board,
                  school: userQualificationData.nameOfSchoolCollege,
                  examSeatNo: userQualificationData.examSeatNo,
                  certificateNo: userQualificationData.certificateNo,
                };
                return docs;
              }
            ),

            userDocumentDetails: data.userDocumentDetails.map(
              (userDocumentData) => {
                let docss = {
                  documentFileName: userDocumentData.documentFileName,
                  document: userDocumentData.document.name,
                };
                return docss;
              }
            ),
          };
          console.log(userData);
          dispatch(userAppDataSuccess({ userData }));
          return userData;
        })
        .catch((error) => {
          dispatch(serverFailed());
          console.log("ErrorgetDeclrationById ========>", error.response.data);
          return false;
        });
      return docs;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const getRoundDetailsById =
  ({ capId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      let docs = await axios
        .get(URL8 + capId, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          console.log(
            "Success With id getRoundDetailsById ========>",
            response
          );
          const { data } = response.data;
          let row = [];
          let index = 0;
          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.applicationRound.name,
              isActive:
                data1.applicationRound.isActive === 1 ? "Active" : "Inactive",
              declarationId: data1.userApplication.applicationDeclare.id,
            };
            row.push(bData);
          });
          dispatch(applicantRoundDetailsListSuccess({ row }));
          return row;
        })
        .catch((error) => {
          dispatch(serverFailed());
          console.log("ErrorgetDeclrationById ========>", error.response.data);
          return false;
        });
      return docs;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const resetDelete = () => async (dispatch) => {
  try {
    dispatch(resetDeleteSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

export const resetList = () => async (dispatch) => {
  try {
    dispatch(applicantDataFail());
  } catch (e) {
    return console.error(e.message);
  }
};

export const getPaymentHashcode =
  ({ paymentData }) =>
  async (dispatch) => {
    try {
      let data = JSON.stringify(paymentData);
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      let docs = await axios
        .post(URL9 + "/get-hash-for-online", data, { headers })
        .then(({ data, success }) => {
          if (success == true) {
            dispatch(tokenExp());
          }
          console.log("Success With saveappli ========>", data);
          dispatch(paymentHashcodeSuccess({ data }));

          return data;
        })
        .catch((success) => {
          dispatch(serverFailed());
          return false;
        });
      return docs;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const forSaveApplicantData =
  ({ appliData }) =>
  async (dispatch) => {
    try {
      dispatch(saveApplicantData({ appliData }));
    } catch (e) {
      return console.error(e.message);
    }
  };

export const savePaymentTransaction =
  ({ paymentData }) =>
  async (dispatch) => {
    try {
      let data = JSON.stringify(paymentData);
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      await axios
        .post(URL11, data, { headers })
        .then((response) => {
          console.log("Success With saveappli ========>", response);
          localStorage.setItem(
            "savePaymentTransactionNewPojo",
            JSON.stringify(response.data.data)
          );
          return response;
        })
        .catch((error) => {
          dispatch(serverFailed());
          return false;
        });
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const savePaymentTransactionPojo =
  ({ appliData }) =>
  async (dispatch) => {
    try {
      dispatch(savePaymentTransactionPojoSuccess({ appliData }));
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getUpdateTransaction =
  ({ id, resp, product }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      let docs = await axios
        .get(
          URL11 +
            "/update-status?transactionId=" +
            id +
            "&status=" +
            resp +
            "&data=" +
            product +
            "&mihpayId=",
          { headers }
        )
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          if (response.data.data.error) {
            return false;
          } else {
            return true;
          }
        })
        .catch((error) => {
          dispatch(serverFailed());
          console.log("ErrorgetDeclrationById ========>", error.response);
          return false;
        });
      return docs;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };
