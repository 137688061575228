import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { getAlert } from "../../Alert/alert.slice";
import { ButtonCompo } from "../../components/Comman/Buttton";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import { LabelCompo } from "../../components/Comman/Label";
import { DirectionSnackbar } from "../../components/Comman/SnakBarCompo";
import SearchExternalPlacedStudList from "../../components/SearchTable";
import * as myConstClass from "../../config/messageconstant";
import AcceptRejectAdmForm from "./DirectAdmissionAppliedStudents";
import { saveCapRound } from "../AddingCapRound/addingcapround.slice";
import { getCapRoundById } from "../ApplicationForm/applicant.slice";
import { reserMerit } from "../GenerateMeritList/generatemeritlist.slice";
import { logOut } from "../Login/login.slice";
import { downloadF } from "../PDF/PDFDownload";
import {
  changeRoundStatus,
  changeStatus,
  deleteappliById,
  deleteRoundById,
  getCourseDataById,
  getDeclrationById,
  getDeclrationClosedForm,
  getDeclrationClosedFormByDate,
  getDeclrationLiveForm,
  getDeclrationScheduleForm,
  resetSaveDeclare,
  UpdateLockDeclration,
} from "./directAdmission.slice";
import ListingOfOnlineAppTable from "./ListOfApplicationDelcare";
import { Loading1 } from "../../components/LoadorPre";

const names = [
  { title: "Application Name", name: "appName" },
  { title: "Status", name: "isActive" },
];

const ListOfApplicationDelcare = ({
  reserMerit,
  UpdateLockDeclration,
  deleteappliById,
  deleteRoundById,
  getDeclrationById,
  getCourseDataById,
  saveCapRound,
  changeStatus,
  changeRoundStatus,
  getDeclrationScheduleForm,
  getDeclrationClosedForm,
  getDeclrationLiveForm,
  appliData,
  resetSaveDeclare,
  logOut,
  loginUser,
  getDeclrationClosedFormByDate,
  getAlert,
}) => {
  let navigate = useNavigate();
  const [isEdit, setEdit] = useState(false);
  const [merit, setMerit] = useState(true);
  const [cap, setCap] = useState(false);
  const [schedule, setSchedule] = useState(false);
  const [capId, setCapId] = useState();
  const [appDecId, setAppDecId] = useState("");
  const [id1, setId] = useState(4);
  const [snakMsg, setSnakMsg] = useState("");
  const [editRow, setEditRow] = useState([]);
  const [viewRow, setViewRow] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteropen, setFilterOpen] = useState(false);
  const [studentListOpen, setStudentListOpen] = useState(false);
  const [openSnak, setOpenSnak] = useState(false);
  const [filteredList, setfilterList] = React.useState([
    "Application Name",
    "Status",
  ]);
  const [trustList, setTrustList] = React.useState([
    [{ title: "Sr. No.", name: "id" }],
    [{ title: "Application Name", name: "title" }],
    [{ title: "Status", name: "isActive" }],
  ]);

  const [breadCrumName, setBreadCrum] = useState(" SchemeHeadMaster");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [formErrors, setFormErrors] = useState([]);

  const getFilteredTableDataLive = () => {
    console.log(appliData);
    return appliData?.LiveFormsList.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["title"] &&
          currentRow["title"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["index"] &&
          currentRow["index"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  const getFilteredTableDataSchedule = () => {
    return appliData?.scheduleFormsList.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["title"] &&
          currentRow["title"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["index"] &&
          currentRow["index"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  const getFilteredTableDataClosed = () => {
    return appliData?.closedFormsList.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["title"] &&
          currentRow["title"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["index"] &&
          currentRow["index"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  const onSearch = (value) => {
    setSearchValue(value);
  };

  const rowEdit = async (id) => {
    setLoader(true);
    getDeclrationById({ appliId: id }).then((response) => {
      if (!response) {
        setLoader(false);
        getAlert({ message: myConstClass.serverMsg });
      } else {
        setLoader(false);
        localStorage.setItem("appliId", id);
        setEdit(true);
        navigate("/application-declare-form");
      }
    });
  };

  const liveRowStatus = async (id) => {
    swal(myConstClass.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        setLoader(true);

        const viewRow = appliData?.LiveFormsList.filter((row) => row.id === id);

        changeStatus({
          appliId: id,
          status: viewRow[0].isActive === 1 ? 0 : 1,
        }).then((respo) => {
          if (!respo) {
            setLoader(false);

            getAlert({ message: myConstClass.serverMsg });
          } else {
            setLoader(false);

            getDeclrationLiveForm().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
            getDeclrationScheduleForm().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
            getDeclrationClosedForm().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
          }
        });
      }
    });
  };
  const scheduledRowStatus = async (id) => {
    swal(myConstClass.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        setLoader(true);

        const viewRow = appliData?.scheduleFormsList.filter(
          (row) => row.id === id
        );
        changeStatus({
          appliId: id,
          status: viewRow[0].isActive === 1 ? 0 : 1,
        }).then((respo) => {
          if (!respo) {
            setLoader(false);

            getAlert({ message: myConstClass.serverMsg });
          } else {
            setLoader(false);

            getDeclrationLiveForm().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
            getDeclrationScheduleForm().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
            getDeclrationClosedForm().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
          }
        });
      }
    });
  };
  const closedRowStatus = async (id) => {
    swal(myConstClass.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        setLoader(true);

        const viewRow = appliData?.closedFormsList.filter(
          (row) => row.id === id
        );
        changeStatus({
          appliId: id,
          status: viewRow[0].isActive === 1 ? 0 : 1,
        }).then((respo) => {
          if (!respo) {
            setLoader(false);

            getAlert({ message: myConstClass.serverMsg });
          } else {
            setLoader(false);

            getDeclrationLiveForm().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
            getDeclrationClosedForm().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
            getDeclrationScheduleForm().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
          }
        });
      }
    });
  };

  const liveRowClassDetailStatus = async (id, status) => {
    swal(myConstClass.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        setLoader(true);

        changeRoundStatus({
          roundId: id,
          status: status === "Active" ? 0 : 1,
        }).then((respo) => {
          if (!respo) {
            setLoader(false);

            getAlert({ message: myConstClass.serverMsg });
          } else {
            setLoader(false);

            setOpenSnak(true);
            setSnakMsg(myConstClass.activeStatus);
            getDeclrationLiveForm().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
            getDeclrationClosedForm().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
            getDeclrationScheduleForm().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
          }
        });
      }
    });
  };

  const scheduledRowClassDetailStatus = async (id, status) => {
    swal(myConstClass.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        setLoader(true);

        changeRoundStatus({
          roundId: id,
          status: status === "Active" ? 0 : 1,
        }).then((respo) => {
          if (!respo) {
            setLoader(false);

            getAlert({ message: myConstClass.serverMsg });
          } else {
            setLoader(false);

            setOpenSnak(true);
            setSnakMsg(myConstClass.activeStatus);
            getDeclrationLiveForm().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
            getDeclrationClosedForm().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
            getDeclrationScheduleForm().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
          }
        });
      }
    });
  };

  const closedRowClassDetailStatus = async (id, status) => {
    swal(myConstClass.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        setLoader(true);

        changeRoundStatus({
          roundId: id,
          status: status === "Active" ? 0 : 1,
        }).then((respo) => {
          if (!respo) {
            setLoader(false);

            getAlert({ message: myConstClass.serverMsg });
          } else {
            setLoader(false);

            setOpenSnak(true);
            setSnakMsg(myConstClass.activeStatus);
            getDeclrationLiveForm().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
            getDeclrationClosedForm().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
            getDeclrationScheduleForm().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
          }
        });
      }
    });
  };

  const getRowData = (id) => {
    setLoader(true);

    getDeclrationById({ appliId: id }).then((resp) => {
      if (resp) {
        setLoader(false);

        getCourseDataById({ ids: resp?.programTitleIds }).then((respo) => {
          if (!respo) {
            getAlert({ message: myConstClass.serverMsg });
          } else {
            localStorage.setItem("appliId", id);
            navigate("/admin-direct-admission-view");
          }
        });
      } else {
        setLoader(false);

        getAlert({ message: myConstClass.serverMsg });
      }
    });
  };

  useEffect(() => {
    localStorage.removeItem("appliId");
    setAppDecId("");
    setLoader(true);

    getDeclrationScheduleForm().then((respo) => {
      if (!respo) {
        setLoader(false);

        getAlert({ message: myConstClass.serverMsg });
      } else {
        setLoader(false);
      }
    });
    getDeclrationClosedForm().then((respo) => {
      if (!respo) {
        setLoader(false);

        getAlert({ message: myConstClass.serverMsg });
      } else {
        setLoader(false);
      }
    });
    getDeclrationLiveForm().then((respo) => {
      if (!respo) {
        setLoader(false);

        getAlert({ message: myConstClass.serverMsg });
      } else {
        setLoader(false);
      }
    });
  }, []);
  useEffect(() => {
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
  });
  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be retrived,Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoader(true);

        deleteappliById({ appliId: id }).then((respo) => {
          if (!respo) {
            setLoader(false);

            getAlert({ message: myConstClass.serverMsg });
          } else {
            setOpenSnak(true);
            setSnakMsg("Data Successfully Deleted");
            setLoader(false);

            getDeclrationScheduleForm().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
            getDeclrationClosedForm().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
            getDeclrationLiveForm().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
          }
        });
      }
    });
  };

  const rowCapDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be retrived,Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setOpenSnak(true);
        setSnakMsg("Data Successfully Deleted");
        deleteRoundById({ appliId: id }).then((respo) => {
          if (!respo) {
            getAlert({ message: myConstClass.serverMsg });
          } else {
            getDeclrationScheduleForm().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
            getDeclrationClosedForm().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
            getDeclrationLiveForm().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
          }
        });
      }
    });
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handledChange = (event, newValue) => {
    setValue(newValue);
  };

  const MeritForm = (e) => {
    reserMerit();
    setAppDecId(e);
    setMerit(true);
  };
  const AddCapForm = (e) => {
    setCap(true);
    setCapId(e);
  };
  const pdfDetailsId = (row) => {
    // 687ddace-6acc-4705-88c6-97efd42311a0
    downloadF({
      url:
        "/api/report/v1/application-fees-collection-report/" +
        row.id +
        "/get-application-fees-collection?documentType=1",

      ext: "pdf",
      openNewTab: true,
    });
  };
  const setLockStatus = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once Locked, The Information cannot be changed. Would you like to continue.?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((value) => {
      if (value) {
        setLoader(true);
        UpdateLockDeclration({ appliId: id }).then((respo) => {
          if (!respo) {
            setLoader(false);
            getAlert({ message: myConstClass.serverMsg });
          } else {
            setLoader(false);
            getDeclrationScheduleForm().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
            getDeclrationClosedForm().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
            getDeclrationLiveForm().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
          }
        });
      }
    });
  };
  const applicantList = (id) => {
    setStudentListOpen(true);
    setMerit(false);
    setAppDecId(id);
  };
  const capRoundData = (row) => {
    getCapRoundById({ appliId: row.id });
  };
  const rowAddSchedule = (applicationId, capId) => {
    setCapId(capId);
    setSchedule(true);
  };
  const backToList = (e) => {
    console.log("hi");
    setStudentListOpen(false);
    setMerit(true);
    setAppDecId("");
  };
  const openDeclareForm = () => {
    localStorage.removeItem("appliId");
    resetSaveDeclare();
    window.location.replace("/application-declare-form");
  };

  const submitHandler = () => {
    setLoader(true);
    let fromDt = moment(fromDate).format("YYYY-MM-DD");
    let toDt = moment(toDate).format("YYYY-MM-DD");
    if (handleFormValidation()) {
      getDeclrationClosedFormByDate({ fromDate: fromDt, toDate: toDt }).then(
        (respo) => {
          if (!respo) {
            setLoader(false);
            getAlert({ message: myConstClass.serverMsg });
          } else {
            setLoader(false);
          }
        }
      );
    }
  };

  const handleFormValidation = (name) => {
    let formErrors = {};
    let formIsValid = true;
    if (fromDate === "" || fromDate === null) {
      formIsValid = false;
      formErrors["fromDateError"] = myConstClass.instNameMsg;
    }
    if (toDate === "" || toDate === null) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.instNameMsg;
    }
    setFormErrors({ formErrors: formErrors });
    return formIsValid;
  };

  return (
    <>
      {merit && !studentListOpen && (
        <div>
          {
            <DirectionSnackbar
              open={openSnak}
              severity="success"
              msg={snakMsg}
              closeSnak={setOpenSnak}
            />
          }
          <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              spacing={2}
              columnSpacing={1}
              container
              sx={{ m: 0.5 }}
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={11} sm={6} md={11}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 20 }}
                    label="Application form for direct admission"
                  />
                </Grid>{" "}
                <Grid sx={{ mt: 1 }} item xs={2} md={1}>
                  <MyComponentWithIconProps
                    statusImage={AddCircleIcon}
                    color="primary"
                    fontSize="large"
                    title="Create Form"
                    onClick={openDeclareForm}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              justifyContent="space-evenly"
            >
              <Grid sx={{ marginBottom: 2 }} item xs={12} sm={12} md={12}>
                <SearchExternalPlacedStudList onSearch={onSearch} />
              </Grid>
            </Grid>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handledChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label={"Live " + appliData?.LiveFormsList.length}
                  {...a11yProps(0)}
                />
                <Tab
                  label={"Schedule " + appliData?.scheduleFormsList.length}
                  {...a11yProps(1)}
                />
                <Tab
                  label={"Closed " + appliData?.closedFormsList.length}
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <ListingOfOnlineAppTable
                data={getFilteredTableDataLive()}
                RowDetail={capRoundData}
                rowEdit={rowEdit}
                rowViewData={getRowData}
                rowDelete={deleteData}
                rowStatus={liveRowStatus}
                rowClassDetailStatus={liveRowClassDetailStatus}
                getMeritForm={MeritForm}
                getAddCap={AddCapForm}
                setLock={setLockStatus}
                applicantList={applicantList}
                rowAddSchedule={rowAddSchedule}
                rowCapDelete={rowCapDelete}
                pdfDetailsId={pdfDetailsId}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ListingOfOnlineAppTable
                data={getFilteredTableDataSchedule()}
                rowEdit={rowEdit}
                rowViewData={getRowData}
                rowDelete={deleteData}
                rowStatus={scheduledRowStatus}
                rowClassDetailStatus={scheduledRowClassDetailStatus}
                getMeritForm={MeritForm}
                getAddCap={AddCapForm}
                setLock={setLockStatus}
                applicantList={applicantList}
                rowAddSchedule={rowAddSchedule}
                rowCapDelete={rowCapDelete}
                pdfDetailsId={pdfDetailsId}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={2}
                rowSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={6} md={5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="From Date"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="To Date"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={2}></Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <BasicDatePicker
                    color="primary"
                    name="fromDate"
                    size="small"
                    value={fromDate}
                    fullWidth
                    onChange={(date) => setFromDate(date)}
                    error={" "}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <BasicDatePicker
                    color="primary"
                    name="toDate"
                    size="small"
                    value={toDate}
                    fullWidth
                    onChange={(date) => setToDate(date)}
                    error={" "}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="contained"
                    name="Search"
                    onClick={submitHandler}
                  />
                </Grid>
              </Grid>
              <ListingOfOnlineAppTable
                data={getFilteredTableDataClosed()}
                rowEdit={rowEdit}
                rowViewData={getRowData}
                rowDelete={deleteData}
                rowStatus={closedRowStatus}
                rowClassDetailStatus={closedRowClassDetailStatus}
                getMeritForm={MeritForm}
                getAddCap={AddCapForm}
                setLock={setLockStatus}
                pdfDetailsId={pdfDetailsId}
                applicantList={applicantList}
                rowAddSchedule={rowAddSchedule}
                rowCapDelete={rowCapDelete}
              />
            </TabPanel>
          </Paper>
        </div>
      )}

      {studentListOpen && !merit && (
        <AcceptRejectAdmForm DeclrationId={appDecId} onClose={backToList} />
      )}
      {loader && <Loading1 size={50} />}
    </>
  );
};

const mapStateToProps = (state) => ({
  appliData: state.directAdmission,
  branchList: state.branch,
  qualificationList: state.qualification,
  documentList: state.document,
  loginUser: state.user,
});

const mapDispatchToProps = {
  deleteappliById,
  deleteRoundById,
  getDeclrationById,
  getCourseDataById,
  getDeclrationScheduleForm,
  getDeclrationClosedForm,
  getDeclrationLiveForm,
  saveCapRound,
  UpdateLockDeclration,
  reserMerit,
  downloadF,
  changeStatus,
  changeRoundStatus,
  resetSaveDeclare,
  getDeclrationClosedFormByDate,
  logOut,
  getAlert,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListOfApplicationDelcare);
