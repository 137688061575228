
import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { LabelCompo } from "../../components/Comman/Label";
import { DirectionSnackbar } from "../../components/Comman/SnakBarCompo";
import { getProgram } from '../ProgramMaster/program.slice';
import { saveUserIntrest } from '../Student/student.slice';
import IntrestedProgramForm from "./IntresetedProgramForm";
import {logOut} from "../Login/login.slice";

const InterestedProgram = ({programList,saveUserIntrest,loginUser,logOut,getProgram}) => {
  let navigate = useNavigate();
  const [isEdit, setEdit] = useState(false);
  const [flag, setFlag] = useState(4);
  const [snakMsg, setSnakMsg] = useState('');
  const [editRow, setEditRow] = useState([]);
  const [openSnak, setOpenSnak] = useState(false);
 
  const data = (instData) => {
    // setOpenSnak(true)
    // setOpen(true)
   
   saveUserIntrest({intrestedIn:instData})
 navigate("/student");
  };
  useEffect(() => {
    let intrestIn = localStorage.getItem("intrestIn");
    if(intrestIn==''){
      setFlag(true)
      getProgram();
    }
    else{
      navigate("/student");
      setFlag(false)
    }
    
  },[]);
  useEffect(()=>{
    if(loginUser.token){
      localStorage.removeItem("token");
      navigate("/login");
      logOut()
    }
  })

  const stopEdit = () => {
    
    setEditRow([]);
    setEdit(false);
  };
  const saveAndNextData = (data) => {
    setEdit(true);
    setOpenSnak(true)
    setSnakMsg('Data save successfully')
  };

  return (
    <>
     
       
        {<DirectionSnackbar open={openSnak} severity='success' msg={snakMsg}closeSnak={setOpenSnak}/>}
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>

            <Grid item xs={12} sm={6} md={3}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 20 }}
                label="Interested Program"
              />
            </Grid>
            
            <Grid item xs={12} sm={6} md={9}>
              {/* <FormHelperText error>{progNameValid}</FormHelperText> */}
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              {/* <Divider textAlign="left"></Divider> */}
            </Grid> 
         
      { flag &&programList.isProgFetch &&   <IntrestedProgramForm
                OnSave={data}
                isEdit={editRow}
                onCancel={stopEdit}
                saveAndNext={saveAndNextData}
             
              />
      }
        </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({
  programList: state.program,
  common: state.common,
  student: state.student,
  loginUser:state.user
});

const mapDispatchToProps = {
  getProgram,
  saveUserIntrest,
  logOut
};
export default connect(mapStateToProps, mapDispatchToProps)(InterestedProgram);