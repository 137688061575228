import MenuIcon from "@mui/icons-material/Menu";
import { Avatar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import { connect } from "react-redux";
import { onToggle } from "../CommonActions/common.slice";
import { logOut } from '../Pages/Login/login.slice';


const Navbar1 = ({ logOut, onToggle }) => {
  const toggleDrawer = () => {
    onToggle();
  };
  const [name, setName] = React.useState("");
  const [role, setRole] = React.useState("");
  React.useEffect(() => {
    const person = localStorage.getItem("person");
    let role = localStorage.getItem("role");
    setRole(role);
    setName(person !== null ? person : "ADMIN");
    // let username = localStorage.getItem('username')
  }, []);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const getprofile = () => {
    window.location.replace("/studentPreview1");
  };
  const logOutHandler = () => {
    localStorage.removeItem("token");
    localStorage.clear()
    logOut()
    window.location.replace("/login");
  };
  return (
    <>
      <div className="navbar">
        <div className="main_menu">
          <ul>
            <li>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ mr: 2 }}
                onClick={toggleDrawer}
              >
                <MenuIcon color="primary-white" />
              </IconButton>
            </li>
          </ul>
         
        </div>
        {role === "trust" && 
           <p className="welcomelabel">
        BMN Trust Portal
         </p>}
         {role !== "trust" && 
           <p className="welcomelabel">
       Online Application Portal
         </p>}
          
        <div className="login_area">
          <div className="login_r">
            <>
              {role !== "trust" && (
                <>
                 <Avatar
                    sx={{ width: 35, height: 35, mt: 0.9, bgcolor:'red'}}
                    onClick={handleClick}
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    {name.charAt(0)}
                  </Avatar>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={getprofile}>Profile</MenuItem>
                    <MenuItem onClick={logOutHandler}>Logout</MenuItem>
                  </Menu>
                </>
              )}
              {role === "trust" && 
              
                        <a href="/login" onClick={logOutHandler}>Logout</a>
                      }
            </>
          </div>
        </div>

     
       
           <p className="userName">
       Welcome {name}
         </p>
        <div className="clr"></div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  common: state.common,
});

const mapDispatchToProps = { onToggle ,logOut};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar1);
