import { withStyles } from "@mui/styles";
import * as React from "react";
import ReactHtmlParser from "react-html-parser";
const styles = (theme) => ({
  MuiTable: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
  },

  MuiTableHead: {
    background: "#6673fd",
    color: "#FFF",
    border: "1px solid #ddd",
    fontFamily: "poppins",
  },
  MuiTableCell: {
    color: "#FFF",
    fontSize: "16px",
    fontFamily: "poppins",
  },
  MuiTableCellRoot: {
    padding: "7px",
    borderRight: "1px solid #ddd",
    fontFamily: "poppins",
  },
  MuiTableHeadRoot: {
    textAlign: "left",
    padding: "8px",
    border: "1px solid #ddd",
  },
});

const BasicTable = ({ data, classes }) => {
  return (
    <div style={{ overflow: "auto" }}>
      <table className={classes.MuiTable}>
        <tr style={{ background: "#f5f5f5" }}>
          <td
            style={{ color: "#5a5a5a" }}
            className={classes.MuiTableHeadRoot}
            align="left"
            width="20%"
          >
            &nbsp; Title of the form{" "}
          </td>
          <td className={classes.MuiTableHeadRoot} align="left" colSpan={3}>
            &nbsp;&nbsp;{data.title}
          </td>
        </tr>

        <tr>
          <td
            style={{ color: "#5a5a5a" }}
            className={classes.MuiTableHeadRoot}
            align="left"
          >
            &nbsp; Display Date (From){" "}
          </td>
          <td className={classes.MuiTableHeadRoot} align="left">
            &nbsp;&nbsp;{data.startDate}
          </td>
          <td
            style={{ color: "#5a5a5a" }}
            className={classes.MuiTableHeadRoot}
            align="left"
          >
            &nbsp; Display Date (To){" "}
          </td>
          <td className={classes.MuiTableHeadRoot} align="left">
            &nbsp;&nbsp;{data.lastDate}
          </td>
        </tr>

        <tr style={{ background: "#f5f5f5" }}>
          <td
            style={{ color: "#5a5a5a" }}
            className={classes.MuiTableHeadRoot}
            align="left"
          >
            &nbsp; Launch Date{" "}
          </td>
          <td className={classes.MuiTableHeadRoot} align="left">
            &nbsp;&nbsp;{data.applicationDate}
          </td>
          <td
            style={{ color: "#5a5a5a" }}
            className={classes.MuiTableHeadRoot}
            align="left"
          >
            &nbsp; Merit List Date{" "}
          </td>
          <td className={classes.MuiTableHeadRoot} align="left" colSpan={3}>
            &nbsp;&nbsp;{data.meritListPublishedDate}
          </td>
        </tr>

        <tr>
          <td
            style={{ color: "#5a5a5a" }}
            className={classes.MuiTableHeadRoot}
            align="left"
          >
            &nbsp; Short Summary{" "}
          </td>
          <td className={classes.MuiTableHeadRoot} align="left" colSpan={3}>
            {ReactHtmlParser(data.shortSummary)}
          </td>
        </tr>
        <tr style={{ background: "#f5f5f5" }}>
          <td
            style={{ color: "#5a5a5a" }}
            className={classes.MuiTableHeadRoot}
            align="left"
          >
            &nbsp; Detail Description{" "}
          </td>
          <td className={classes.MuiTableHeadRoot} align="left" colSpan={3}>
            {ReactHtmlParser(data.detailedDescription)}
          </td>
        </tr>

        <tr>
          <td
            style={{ color: "#5a5a5a" }}
            className={classes.MuiTableHeadRoot}
            align="left"
          >
            &nbsp; Is Applicable{" "}
          </td>
          <td className={classes.MuiTableHeadRoot} align="left">
            &nbsp;&nbsp;{data.paymentApplicable === 1? "Yes" : "No"}
          </td>
          
          <td
            style={{ color: "#5a5a5a" }}
            className={classes.MuiTableHeadRoot}
            align="left"
          >
            &nbsp; Type{" "}
          </td>
          <td className={classes.MuiTableHeadRoot} align="left">
            &nbsp;&nbsp;{data.paymentType === 1 ? "Online" : "Manual"}
          </td>
        </tr>
        {console.log(data.paymentApplicable)}
        {data.paymentApplicable === 1 && (
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              align="left"
            >
              &nbsp; Fees{" "}
            </td>
            <td className={classes.MuiTableHeadRoot} align="left" colSpan={3}>
              &nbsp;&nbsp;{data.amount}
            </td>
          </tr>
        )}

        <tr>
          <td
            style={{ color: "#5a5a5a" }}
            className={classes.MuiTableHeadRoot}
            align="left"
          >
            &nbsp; Instructions Printed{" "}
          </td>
          <td className={classes.MuiTableHeadRoot} align="left" colSpan={3}>
            &nbsp;&nbsp;{data.instructionPrintOnChallan === 1 ? "Yes" : "No"}
          </td>
        </tr>
        {data.instructionPrintOnChallan === 1 && (

        <tr style={{ background: "#f5f5f5" }}>
          <td
            style={{ color: "#5a5a5a" }}
            className={classes.MuiTableHeadRoot}
            align="left"
          >
            &nbsp; Instructions{" "}
          </td>
          <td className={classes.MuiTableHeadRoot} align="left" colSpan={3}>
            {ReactHtmlParser(data.paymentInstruction)}
          </td>
        </tr>
        )}
      </table>
    </div>
  );
};
export default withStyles(styles)(BasicTable);
