export const DynamicReportJson = {
  // apiBaseURL:
  //   "/api/report/v1/{status}/application-fees-collection-summary-report/{status}/get-application-fees-collection-summary?yearDetail={status}&division={status}&yearDetail={status}",
  apiBaseURL:
    "/api/report/v1/application-fees-collection-summary-report/{status}/get-application-fees-collection-summary",
  screenTitle: "Reports",
  showAddButton: false,
  showPdfDownload: false,
  pdfFileName: "",
  showExcelDownload: false,
  excelFileName: "",
  tableColumnsToFilter: [],
  buttonCenter: false,
  searchButton: false,
  PDFButton: true,
  EXCELButton: true,
  fieldMeta: [
    {
      label: "Type",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "typeMaster",
      dataKey: "type",
      isMandatory: true,
    },
    // {
    //   label: "Teacher",
    //   controlType: "autocomplete",
    //   placeHolder: "",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   masterName: "teacherMaster",
    //   getListFrom: "department",
    //   dataKey: "teacherIds",
    //   isMandatory: true,
    // },
    {
      label: "Academic Year",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "acadamicMaster",
      dataKey: "academicYearId",
      isMandatory: true,
    },
    {
      label: "Program Type",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "programTypeMaster",
      dataKey: "programType",
      getListId:'programType',
      isMandatory: true,
    },
    {
      label: "Program title",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "programTitleMaster",
      getListId:'programTitle,institute',
      getListFrom:'programType',
      dataKey: "programTitle",
      isMandatory: true,
    },
    {
      label: "Institute",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "instituteMaster",
      getListId:'programTitle,institute',
      dataKey: "institute",
      isMandatory: true,
    },
    {
      label: "Year Detail",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "classMaster",
      getListFrom:'programTitle',
      dataKey: "yearDetailIds",
      isMandatory: true,
    },
   
    // {
    //   label: "Semester Details",
    //   controlType: "autocomplete",
    //   placeHolder: "",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   getListId: "semister,division",
    //   getListFrom: "acadamicYear,yearDetail",
    //   masterName: "semisterMaster",
    //   dataKey: "semister",
    //   isMandatory: true,
    // },
    // {
    //   label: "Division (single Selection)",
    //   controlType: "autocomplete",
    //   placeHolder: "",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   masterName: "divisionMaster",
    //   getListId: "semister,division",
    //   getListFrom: "yearDetail",
    //   dataKey: "division",
    //   isMandatory: true,
    // },
    // {
    //   label: "Division (Multi Select)",
    //   controlType: "multiSelect",
    //   placeHolder: "",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   masterName: "divisionMaster",
    //   getListFrom: "yearDetail",
    //   dataKey: "divisionIds",
    //   isMandatory: true,
    // },
    // {
    //   label: "Time Table Header",
    //   controlType: "autocomplete",
    //   placeHolder: "",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   masterName: "timeTableHeaderMaster",
    //   getListFrom: "semister,division",
    //   dataKey: "timeTableHeaderId",
    //   isMandatory: true,
    // },
  ],
};
