import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { DirectionSnackbar } from "../../components/Comman/SnakBarCompo";
import * as myConstClass from "../../config/messageconstant";
import {
  getGenerateOTP,
  getValidateOTP,
  resetError,
  resetOtpError,
} from "./forgotUserId.slice";
import ForgotUserIdForm from "./ForgotUserIdForm";
import ProgMView from "./ProgMView";
const rows = [
  {
    id: 1,
    firstName: "sds",
    middleName: "sad",
    lastName: "sdasd",
    gender: "Male",
    contactNo: 343567891,
    emailId: "Scsxa@gmail.com",
    enterPassword: "sdsd",
    confirmPassword: "sdsd",
    captcha: "dfwdw",
  },
  {
    id: 2,
    firstName: "sds",
    middleName: "sad",
    lastName: "sdasd",
    gender: "Male",
    contactNo: 343567891,
    emailId: "Scsxa@gmail.com",
    enterPassword: "sdsd",
    confirmPassword: "sdsd",
    captcha: "dfwdw",
  },
  {
    id: 3,
    firstName: "sds",
    middleName: "sad",
    lastName: "sdasd",
    gender: "Male",
    contactNo: 343567891,
    emailId: "Scsxa@gmail.com",
    enterPassword: "sdsd",
    confirmPassword: "sdsd",
    captcha: "dfwdw",
  },
];

const names = [
  { title: "Contact Number", name: "contactNo" },
  { title: "Email id", name: "emailId" },
  { title: "Enter Otp", name: "enterOtp" },
  { title: "Status", name: "isActive" },
];

const tableColumn = ["Contact Number", "Email id", "Enter Otp", "Status"];

let newInstData;
const ForgotUserId = ({
  getGenerateOTP,
  getValidateOTP,
  resetOtpError,
  resetError,
  userList,
}) => {
  const navigate = useNavigate();
  const [isEdit, setEdit] = useState(false);
  const [row, setRows] = useState(rows);
  const [id1, setId] = useState(4);
  const [snakMsg, setSnakMsg] = useState("");
  const [editRow, setEditRow] = useState([]);
  const [viewRow, setViewRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteropen, setFilterOpen] = useState(false);
  const [login, setLogin] = useState(false);
  const [openSnak, setOpenSnak] = useState(false);
  const [filteredList, setfilterList] = React.useState([
    "Contact Number",
    "Email id",
    "Enter Otp",
    "Status",
  ]);
  const [trustList, setTrustList] = React.useState([
    [{ title: "Contact Number", name: "contactNo" }],
    [{ title: "Email id", name: "emailId" }],
    [{ title: "Entre Otp", name: "enterOtp" }],
    [{ title: "Status", name: "isActive" }],
  ]);

  const [breadCrumName, setBreadCrum] = useState(" SchemeHeadMaster");

  const data = (instData) => {
    setOpenSnak(true);

    setSnakMsg("Registered Successfully");
    navigate("/login");
    if (instData.id === 0) {
      newInstData = {
        id: id1,
        ...instData,
      };
      setRows((prevInst) => {
        return [...prevInst, newInstData];
      });
      setId(id1 + 1);
      setEdit(true);
      setEditRow([]);
    } else {
      newInstData = {
        ...instData,
      };

      setRows((prevInst) => {
        return [...prevInst, newInstData];
      });
      setEdit(true);
      setEditRow([]);
    }
    setBreadCrum("   >   Master   >   Scheme Head Master  ");
  };
  useEffect(() => {});

  const startEdit = () => {
    setEdit(true);
    let t = localStorage.getItem("token");
  };
  const breadCrum = (prevBreadCrum) => {
    setBreadCrum(breadCrumName + prevBreadCrum);
  };
  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be retrived,Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      setOpenSnak(true);
      setSnakMsg("Data Successfully Deleted");
    });
  };

  const filteredRows = (searchvalue) => {
    const { value } = searchvalue.target;
    setSearchValue(value);
    const filteredRow = rows.filter((row) => {
      let contcatNo = row?.contcatNo;
      let emailId = row?.emailId;
      let enterOtp = row?.enterOtp;
      let isActive = row?.isActive;
      return (
        contcatNo === parseInt(value) ||
        emailId.toLowerCase().includes(value.toLowerCase()) ||
        enterOtp.toLowerCase().includes(value.toLowerCase()) ||
        isActive.toLowerCase() === value
      );
    });
    setRows(filteredRow);
  };
  const stopEdit = () => {
    navigate("/login");
    setBreadCrum("   >   Master   >   Scheme Name  ");
    setEditRow([]);
    setEdit(false);
  };
  const saveAndNextData = (data) => {
    setEdit(true);
    setOpenSnak(true);
    setSnakMsg("Data save successfully");
  };
  const RegisterData = (data) => {
    setEdit(true);
    setOpenSnak(true);
    setSnakMsg("Data save successfully");
  };
  const validateOtpData = (enterOtp, emailId) => {
    var emailString = emailId;
    var name = emailString.substring(0, emailString.lastIndexOf("@"));
    console.log(name);
    var mailArr = emailString.split("@");
    var logn = mailArr[0];
    console.log(logn);
    var mailHost = mailArr[1];
    console.log(mailHost);
    var last3 = logn.slice(-4);
    console.log(last3);
    getValidateOTP({ enterOtp, emailId }).then((success) => {
      if (success) {
        Swal.fire({
          icon: "success",
          title: "Account Validated Successfully",
          text:
            "  Your Username has been sent to your registered Email Id XXXX" +
            last3 +
            "@" +
            mailHost,
          showConfirmButton: true,
          confirmButtonColor: "#1f89f4",
          confirmButtonText: "Login",
          allowOutsideClick: false,
        }).then((okLogin) => {
          if (okLogin.isConfirmed) {
            navigate("/login");
          }
        });
      } else {
        setOpenSnak(true);

        setSnakMsg(myConstClass.otpInvalidMsg);
      }
    });
  };

  const validateData = (emailId, dob, type) => {
    getGenerateOTP({ emailId, dob, type }).then((success) => {
      setOpenSnak(true);
      if (success) {
        if (type == 1) {
          setSnakMsg(myConstClass.otpEmailSend);
        } else {
          setSnakMsg(myConstClass.otpMobileSend);
        }
      } else {
        setSnakMsg(myConstClass.invalidEmail);
      }
    });
    resetError();
    setEdit(true);
    setEditRow([]);
  };

  const addFile = (file) => {};
  const closeFilter = () => {
    setTrustList([
      [{ title: "Contact Number", name: "contactNo" }],
      [{ title: "Email id", name: "emailId" }],
      [{ title: "Enter Otp", name: "enterOtp" }],
      [{ title: "Status", name: "isActive" }],
    ]);
    setfilterList(["Contact Number", "Email id", "Enter Otp", "Status"]);
    setFilterOpen(false);
  };
  const handleChange = (event) => {
    setFilterOpen(true);
    const {
      target: { value },
    } = event;

    if (value.length > filteredList.length) {
      const a = value.map((value) => {
        const trust = names.filter((row) => row.title === value);
        const trust1 = [...trustList, trust];

        return setTrustList(trust1);
      });
    } else {
      let difference = filteredList.filter((x) => !value.includes(x));
      const instList = trustList.filter((data) => {
        return data[0].title !== difference[0];
      });
      setTrustList(instList);
    }

    setfilterList(typeof value === "string" ? value.split(",") : value);

    if (value.length === 0) {
      setFilterOpen(false);
    }
  };

  return (
    <>
      {
        <DirectionSnackbar
          open={openSnak}
          severity="success"
          msg={snakMsg}
          closeSnak={setOpenSnak}
        />
      }
      {userList.isFail && userList.error != "" && (
        <DirectionSnackbar
          open={openSnak}
          severity="error"
          msg={snakMsg}
          closeSnak={setOpenSnak}
        />
      )}
      {userList.isValidateOtpFail && userList.errorMsg != "" && (
        <DirectionSnackbar
          open={openSnak}
          severity="error"
          msg={snakMsg}
          closeSnak={setOpenSnak}
        />
      )}
      <ForgotUserIdForm
        OnSave={data}
        isEdit={editRow}
        submitOtp={validateData}
        saveOtp={validateOtpData}
        onCancel={stopEdit}
        breadCrum={breadCrum}
        saveAndNext={saveAndNextData}
        handleFile={addFile}
      />

      {login && open && <ProgMView openparam={open} closeView={setOpen} />}
    </>
  );
};

const mapStateToProps = (state) => ({
  common: state.common,
  userList: state.studentUser,
});

const mapDispatchToProps = {
  getGenerateOTP,
  getValidateOTP,
  resetOtpError,
  resetError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotUserId);
