import { Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Alert from "../../Alert/Alert";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import { DirectionSnackbar } from "../../components/Comman/SnakBarCompo";
import * as myConstClass from "../../config/messageconstant";
import {
  getApplicantById,
  getApplicantDetailsById,
  getRoundDetailsById,
  resetList,
} from "../ApplicationForm/applicant.slice";
import CapRoundDetailsForm from "../CapRoundDetailsForm/index";
import InstituteDefineTable from "../InstituteDefineTrust/Index";
import {
  getApplicantByDeclrationId,
  getDeclrationById,
  resetServer,
} from "../onlineAppProcess/declaration.slice";
import StudentPreview1 from "../StudentPreview1/index";
import AcceptRejectAdmissionForm from "./AcceptRejectAdmissionForm";
import { logOut } from "../Login/login.slice";

const AcceptRejectAdmission = ({
  loginUser,
  resetServer,
  resetList,
  getApplicantDetailsById,
  applicantData,
  appliData,
  DeclrationId,
  getApplicantByDeclrationId,
  getRoundDetailsById,
  onClose,
  userAppliId,
  getDeclrationById,
  logOut,
}) => {
  let navigate = useNavigate();
  const [isEdit, setEdit] = useState(false);
  const [applicant, setApplicant] = useState(false);

  const [application, setapplication] = useState(4);
  const [snakMsg, setSnakMsg] = useState("");

  const [viewRow, setViewRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [elegibilityData, setElegibilityData] = useState(false);
  const [openSnak, setOpenSnak] = useState(false);
  const [capRndFlag, setCapRndFlag] = useState(false);

  const getRowID = async (id, application, elegibilityData) => {
    setapplication(application);
    setElegibilityData(elegibilityData);
    console.log(elegibilityData);
    console.log(application);
    getDeclrationById({ appliId: id }).then(() => {
      setEdit(true);
    });
  };

  useEffect(() => {
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
  });
  useEffect(() => {
    console.log(DeclrationId);
    resetList();
    setApplicant(false);
    if (DeclrationId != "") {
      getApplicantByDeclrationId({ appliId: DeclrationId });
    }
  }, []);

  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be retrived,Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      setOpenSnak(true);
      setSnakMsg("Data Successfully Deleted");
    });
  };
  const RoundDetailsForm = (id) => {
    console.log(id);
    console.log(DeclrationId);
    getRoundDetailsById({ capId: id }).then(() => {
      setCapRndFlag(true);
    });
  };
  const applicantDetails = (id) => {
    console.log(id);
    setApplicant(true);
    getApplicantDetailsById({ appliId: id });
  };
  const goToList = () => {
    setCapRndFlag(false);
  };
  const backToList = () => {
    setApplicant(false);
  };
  const backToListFormQualiTable = () => {
    setEdit(false);
    if (DeclrationId != "") {
      getApplicantByDeclrationId({ appliId: DeclrationId });
    }
  };
  console.log(appliData?.applicant);
  return (
    <>
      {appliData.isServerFail && (
        <Alert msg={myConstClass.serverMsg} closeAlert={resetServer} />
      )}

      {
        <DirectionSnackbar
          open={openSnak}
          severity="success"
          msg={snakMsg}
          closeSnak={setOpenSnak}
        />
      }
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        {!applicant && !capRndFlag && !isEdit && (
          <>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              spacing={2}
              columnSpacing={1}
              container
              sx={{ m: 0.5 }}
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                {/* <Grid item xs={12} sm={12} md={0.5}></Grid> */}
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 20 }}
                    label="Applicant's Name"
                  />
                </Grid>
              </Grid>
            </Grid>
            <br />
            <Box>
              <AcceptRejectAdmissionForm
                data={appliData?.applicant}
                tableHead={[
                  {
                    title: "Sr. No.",
                    name: "index",
                    positionCenter: true,
                    alignCenter: "center",
                    showInscreen: true,
                  },
                  {
                    title: "Applicant Name",
                    name: "userName",
                    positionCenter: false,
                    alignCenter: "left",
                    showInscreen: true,
                  },
                  {
                    title: "Application No",
                    name: "applicationNo",
                    positionCenter: false,
                    alignCenter: "center",
                    showInscreen: true,
                  },
                  {
                    title: "Action",
                    name: "action",
                    positionCenter: false,
                    alignCenter: "right",
                    showInscreen: true,
                  },
                ]}
                rowEdit={getRowID}
                rowDelete={deleteData}
                applicantDetails={applicantDetails}
                capRound={RoundDetailsForm}
              />
            </Box>
            <br />

            <div align="right">
              <ButtonCompo
                size="medium"
                type="button"
                variant="contained"
                name="Back"
                onClick={onClose}
              />
            </div>
          </>
        )}
        {applicant && applicantData?.isFetch && (
          <StudentPreview1
            backToCap={backToList}
            data={applicantData?.appliData}
            flag={true}
          />
        )}
        {capRndFlag && (
          <CapRoundDetailsForm
            goToApplicant={goToList}
            usrAppId={userAppliId}
            declareId={DeclrationId}
          />
        )}
        {isEdit && (
          <InstituteDefineTable
            backToList={backToListFormQualiTable}
            elegibilityData={elegibilityData}
            application={application}
            goToApplicant={goToList}
          />
        )}
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({
  appliData: state.appli,
  applicantData: state.applicant,
  loginUser: state.user,
});

const mapDispatchToProps = {
  getApplicantByDeclrationId,
  getApplicantById,
  getRoundDetailsById,
  resetList,
  resetServer,
  getApplicantDetailsById,
  getDeclrationById,
  logOut,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcceptRejectAdmission);
