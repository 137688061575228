import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import * as React from "react";
import ReactHtmlParser from "react-html-parser";
import "../../CSS/View.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import { openFileInNewTab } from "../UploadFile/file.slice";
import { connect } from "react-redux";
const styles = (Theme) => ({
  MuiTable: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
  },

  MuiTableHead: {
    background: "#6673fd",
    color: "#FFF",
    border: "1px solid #ddd",
    fontFamily: "poppins",
  },
  MuiTableCell: {
    color: "#FFF",
    fontSize: "16px",
    fontFamily: "poppins",
  },
  MuiTableCellRoot: {
    padding: "7px",
    borderRight: "1px solid #ddd",
    fontFamily: "poppins",
  },
  MuiTableHeadRoot: {
    textAlign: "left",
    padding: "8px",
    border: "1px solid #ddd",
  },
});

const Step5 = ({ data, classes, openFileInNewTab }) => {
  const viewDocument = (fileName, e) => {
    e.stopPropagation();
    if (fileName !== "") {
      openFileInNewTab({ file: fileName });
    }
  };
  return (
    <>
      <Grid item xs={12} sm={6} md={12} />
      <div style={{ overflow: "auto" }}>
        <table className={classes.MuiTable}>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              align="center"
              width="20%"
            >
              &nbsp;Instructions/Remarks{" "}
            </td>
            <td align="left">{ReactHtmlParser(data.detailedDescription)}</td>
          </tr>
        </table>
      </div>
      <br />
      <div style={{ overflow: "auto" }}>
        <table className={classes.MuiTable}>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              align="left"
              width="20%"
            >
              &nbsp;Sr. No.
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              align="left"
            >
              &nbsp;Title
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              align="left"
            >
              &nbsp;Action
            </td>
          </tr>

          {data.applicationRefDocuments.map((data, index) => (
            <tr>
              <td className={classes.MuiTableHeadRoot} align="left">
                &nbsp;{index + 1}
              </td>
              <td className={classes.MuiTableHeadRoot} align="left">
                &nbsp;
                {data.docDesc}
              </td>
              <td className={classes.MuiTableHeadRoot} align="left">
                &nbsp;
                <MyComponentWithIconProps
                  statusImage={VisibilityIcon}
                  color="primary"
                  fontSize="medium"
                  title="View"
                  onClick={(event) => viewDocument(data.documentPath, event)}
                  sx={{ ml: 3.2 }}
                />
              </td>
            </tr>
          ))}
        </table>
      </div>
      <br />
    </>
  );
};

const mapStateToProps = (state) => ({
  fileData: state.file,
});

const mapDispatchToProps = {
  openFileInNewTab,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Step5));
