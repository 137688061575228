import React from "react";
import ExportCSV from "../../components/Comman/ExportCSV";
import { generatePDF } from "../../components/Comman/GeneratePDF";
import { FontAwesomeIconCompo } from "../../components/Comman/IconButton";
const CategoryExcelData = ({ csvData, catList, fileName, type }) => {
  const tablecol = catList.map((title) => {
    return title[0].title;
  });
  let index = 0;
  const categoryData = csvData.map((csvData) => {
    index = index + 1;
    let category = {
      "Sr. No.": index,
    };

    catList.map((title) => {
      if (title[0].name === "categoryName") {
        category = {
          ...category,
          " Category Name": csvData.categoryName,
        };
      }

      if (title[0].name === "type") {
        category = {
          ...category,
          Type: csvData.type,
        };
      }

      if (title[0].name === "isActive") {
        category = {
          ...category,
          "Active Status": csvData.isActive,
        };
      }
    });
    return category;
  });

  if (type === "pdf") {
    return (
      <FontAwesomeIconCompo
        color="error"
        fontSize="small"
        title="PDF"
        baseClassName="fas"
        className="fa-file-pdf"
        onClick={() => generatePDF(categoryData, tablecol, "Category Master")}
      />
    );
  }

  return <ExportCSV csvData={categoryData} fileName={fileName} />;
};
export default CategoryExcelData;
