import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import ViewPage from "./ViewPage";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const ViewInst = ({ openparam, closeView, data }) => {
  const [open, setOpen] = React.useState(openparam);

  return (
    <div>
      <BootstrapDialog
        onClose={() => closeView(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth="true"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => closeView(false)}
        >
          Academic Year Master
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid style={{ display: "flex", alignItems: "baseline" }}>
            <ViewPage data={data} />
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default ViewInst;
