import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import education from "../Images/education.png";
import { ButtonCompo } from "./Comman/Buttton";
const styles = (theme) => ({
  hello: {
    position: "absolute",
    left: "15.17%",
    right: "15.08%",
    top: "63.4%",
    bottom: "32.6%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: "normal",
    fontsize: "36px",
    lineheight: "40px",
    color: "#000000",
  },
  buttonC: {
    position: "absolute",
   
    left: '39.17%',
right: '39.17%',
top: '79.7%',
bottom: '14.8%',
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: 500,
    fontsize: "18px",
    lineheight: "40px",
    color: "#FFFFFF",
  },
  helper: {
    position: "absolute",
    left: "9.58%",
    right: "9.5%",
    top: "70%",
    bottom: "22%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: "normal",
    fontsize: "20px",
    lineheight: "40px",

    textAlign: "center",

    color: "#666666",
  },
  title: {
    position: "absolute",
    left: "43.42%",
    right: "43.33%",
    top: "57%",
    bottom: "39%",

    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: 500,
    fontsize: "55px",
    lineheight: "40px",
    color: "#000000",
  },
});
const ErrorPage = ({ onCancel,classes }) => {
  return (
    <div align="center">
      <br></br>
      <img alt='' src={education} />
      <Grid className={classes.title}>
        <h1>Oh no!</h1>
      </Grid>
      <Grid className={classes.hello}>
        <h3>Something went wrong. We'll clean up and try again.</h3>
      </Grid>
      <Grid className={classes.helper}>
        <p align="center">
          {" "}
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip.
        </p>
      </Grid>
      <Grid
       className={classes.buttonC}
      >  <ButtonCompo
      size="medium"
      type="button"
      variant="contained"
      name="Back to home page"
      onClick={() => onCancel(false)}
    />
       
      </Grid>
    </div>
  );
};
export default withStyles(styles)(ErrorPage);
