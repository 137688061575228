import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { DirectionSnackbar } from "../../components/Comman/SnakBarCompo";
import * as myConstClass from "../../config/messageconstant";
import {
  getGenerateOTP,
  getValidateOTP,
  resetError,
  resetOtpError,
  saveuserPassword,
} from "./forgotPassword.slice";
import ForgotUserPasswordForm from "./ForgotUserPasswordForm";
import ProgMView from "./ProgMView";

const rows = [
  {
    id: 1,
    firstName: "sds",
    middleName: "sad",
    lastName: "sdasd",
    gender: "Male",
    contactNo: 343567891,
    userName: "Scsxa@gmail.com",
    enterPassword: "sdsd",
    confirmPassword: "sdsd",
    captcha: "dfwdw",
  },
  {
    id: 2,
    firstName: "sds",
    middleName: "sad",
    lastName: "sdasd",
    gender: "Male",
    contactNo: 343567891,
    userName: "Scsxa@gmail.com",
    enterPassword: "sdsd",
    confirmPassword: "sdsd",
    captcha: "dfwdw",
  },
  {
    id: 3,
    firstName: "sds",
    middleName: "sad",
    lastName: "sdasd",
    gender: "Male",
    contactNo: 343567891,
    userName: "Scsxa@gmail.com",
    enterPassword: "sdsd",
    confirmPassword: "sdsd",
    captcha: "dfwdw",
  },
];

const names = [
  { title: "User id", name: "userName" },
  { title: "Enter Password", name: "enterPassword" },
  { title: "Confirm Password", name: "confirmPassword" },
  { title: "Enter Otp", name: "enterOtp" },
  { title: "Status", name: "isActive" },
];

const tableColumn = [
  "User id",
  "Enter Password",
  "Confirm Password",
  "Enter Otp",
  "Status",
];

let newInstData;
const ForgotuserName = ({
  getGenerateOTP,
  getValidateOTP,
  saveuserPassword,
  userPasswordList,
  resetError,
  resetOtpError,
}) => {
  const navigate = useNavigate();
  const [isEdit, setEdit] = useState(false);
  const [row, setRows] = useState(rows);
  const [id1, setId] = useState(4);
  const [snakMsg, setSnakMsg] = useState("");
  const [editRow, setEditRow] = useState([]);
  const [viewRow, setViewRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteropen, setFilterOpen] = useState(false);
  const [login, setLogin] = useState(false);
  const [openSnak, setOpenSnak] = useState(false);
  const [filteredList, setfilterList] = React.useState([
    "User id",
    "Enter Password",
    "Confirm Password",
    "Enter Otp",
    "Status",
  ]);
  const [trustList, setTrustList] = React.useState([
    [{ title: "User id", name: "userName" }],
    [{ title: "Enter Password", name: "enterPassword" }],
    [{ title: "Confirm Password", name: "confirmPassword" }],
    [{ title: "Enter Otp", name: "enterOtp" }],
    [{ title: "Status", name: "isActive" }],
  ]);

  const [breadCrumName, setBreadCrum] = useState(" SchemeHeadMaster");

  const getRowID = async (id) => {
    setViewRow([]);
    const editedRow = row.filter((row) => row.id === id);
    if (editedRow.length > 0) {
      setEdit(true);
      setEditRow([]);
      return await setEditRow(editedRow);
    }

    return setEditRow([]);
  };

  const rowStatus = async (id) => {
    const viewRow = row.filter((row) => row.id === id);
    console.log(viewRow[0]);
    const v = [
      {
        id: viewRow[0].id,
        userName: viewRow[0].userName,
        enterPassword: viewRow[0].enterPassword,
        confirmPassword: viewRow[0].confirmPassword,
        enterOtp: viewRow[0].enterOtp,
        isActive: viewRow[0].isActive === "1" ? "0" : "1",
      },
    ];
    console.log(v);
    console.log(viewRow);
    if (viewRow.length > 0) {
      return await setRows(v);
    }
  };

  const getRowData = async (id) => {
    setOpen(true);
    const viewRow = row.filter((row) => row.id === id);
    if (viewRow.length > 0) {
      return await setViewRow(viewRow);
    }
    return setViewRow([]);
  };
  const data = (instData) => {
    console.log(instData);

    saveuserPassword({
      userName: instData.userName,
      newPassword: instData.newPassword,
    }).then(() => {
      if (instData.id != null) {
        Swal.fire({
          icon: "success",
          title: "Password Reset Successfully",
          text: "  Your Password has been reseted successfully",
          showConfirmButton: true,
          confirmButtonColor: "#1f89f4",
          confirmButtonText: "Login",
          allowOutsideClick: false,
        }).then((okLogin) => {
          if (okLogin.isConfirmed) {
            navigate("/login");
          }
        });
      } else {
        setSnakMsg(myConstClass.saveMsg);
      }
    });
  };
  const validateOtpData = (enterOtp, userName) => {
    getValidateOTP({ enterOtp, userName }).then((success) => {
      setOpenSnak(true);
      if (success) {
        setSnakMsg(myConstClass.otpValidMsg);
      } else {
        setSnakMsg(myConstClass.otpInvalidMsg);
      }
    });
    resetOtpError();

    setEdit(true);
    setEditRow([]);
  };
  const validateData = (userName, dob) => {
    console.log(userName);

    getGenerateOTP({ userId: userName, dob: dob }).then((success) => {
      setOpenSnak(true);
      if (success) {
        setSnakMsg(myConstClass.otpGenerateMsg);
      } else {
        setSnakMsg(myConstClass.invalidUserName);
      }
    });
    resetError();

    setEdit(true);
    setEditRow([]);
  };
  useEffect(() => {});

  const startEdit = () => {
    setEdit(true);
    let t = localStorage.getItem("token");
  };
  const breadCrum = (prevBreadCrum) => {
    setBreadCrum(breadCrumName + prevBreadCrum);
  };

  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be retrived,Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      setOpenSnak(true);
      setSnakMsg("Data Successfully Deleted");
    });
  };

  const filteredRows = (searchvalue) => {
    const { value } = searchvalue.target;
    setSearchValue(value);
    const filteredRow = rows.filter((row) => {
      let userName = row?.userName;
      let enterPassword = row?.enterPassword;
      let confirmPassword = row?.confirmPassword;
      let enterOtp = row?.enterOtp;
      let isActive = row?.isActive;
      return (
        userName.toLowerCase().includes(value.toLowerCase()) ||
        enterPassword.toLowerCase().includes(value.toLowerCase()) ||
        confirmPassword.toLowerCase().includes(value.toLowerCase()) ||
        enterOtp.toLowerCase().includes(value.toLowerCase()) ||
        isActive.toLowerCase() === value
      );
    });
    setRows(filteredRow);
  };
  const stopEdit = () => {
    navigate("/login");
    setBreadCrum("   >   Master   >   Scheme Name  ");
    setEditRow([]);
    setEdit(false);
  };
  const saveAndNextData = (data) => {
    console.log(data);
    setEdit(true);
    setOpenSnak(true);
    setSnakMsg("Data save successfully");
  };
  const RegisterData = (data) => {
    console.log("RegisterData");
    setEdit(true);
    setOpenSnak(true);
    setSnakMsg("Data save successfully");
  };
  const addFile = (file) => {
    console.log(file);
  };
  const closeFilter = () => {
    setTrustList([
      [{ title: "User id", name: "userName" }],
      [{ title: "Enter Password", name: "enterPassword" }],
      [{ title: "Confirm Password", name: "confirmPassword" }],
      [{ title: "Enter Otp", name: "enterOtp" }],
      [{ title: "Status", name: "isActive" }],
    ]);
    setfilterList([
      "User id",
      "Enter Password",
      "Confirm Password",
      "Enter Otp",
      "Status",
    ]);
    setFilterOpen(false);
  };
  const handleChange = (event) => {
    setFilterOpen(true);
    const {
      target: { value },
    } = event;

    if (value.length > filteredList.length) {
      const a = value.map((value) => {
        const trust = names.filter((row) => row.title === value);
        const trust1 = [...trustList, trust];

        return setTrustList(trust1);
      });
    } else {
      let difference = filteredList.filter((x) => !value.includes(x));
      const instList = trustList.filter((data) => {
        return data[0].title !== difference[0];
      });
      setTrustList(instList);
    }

    setfilterList(typeof value === "string" ? value.split(",") : value);

    if (value.length === 0) {
      setFilterOpen(false);
    }
  };

  return (
    <>
      {
        <DirectionSnackbar
          open={openSnak}
          severity="success"
          msg={snakMsg}
          closeSnak={setOpenSnak}
        />
      }
      {userPasswordList.isFail && userPasswordList.error !== "" && (
        <DirectionSnackbar
          open={openSnak}
          severity="error"
          msg={snakMsg}
          closeSnak={setOpenSnak}
        />
      )}
      {userPasswordList.isValidateOtpFail &&
        userPasswordList.errorMsg != "" && (
          <DirectionSnackbar
            open={openSnak}
            severity="error"
            msg={snakMsg}
            closeSnak={setOpenSnak}
          />
        )}
      <ForgotUserPasswordForm
        OnSave={data}
        isEdit={editRow}
        saveOtp={validateOtpData}
        submitOtp={validateData}
        onCancel={stopEdit}
        breadCrum={breadCrum}
        OnSubmitOtp={saveAndNextData}
        handleFile={addFile}
      />

      {open &&
        userPasswordList.userPasswordUpdated !== "" &&
        userPasswordList.isFetchPasswordUpdate && (
          <ProgMView openparam={open} closeView={setOpen} />
        )}
    </>
  );
};

const mapStateToProps = (state) => ({
  userPasswordList: state.userPassword,
});

const mapDispatchToProps = {
  getGenerateOTP,
  getValidateOTP,
  saveuserPassword,
  resetError,
  resetOtpError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotuserName);
