import React from "react";
import ExportCSV from "../../components/Comman/ExportCSV";
import { generatePDF } from "../../components/Comman/GeneratePDF";
import { FontAwesomeIconCompo } from "../../components/Comman/IconButton";
const NewsDocNotificationExcelData = ({
  csvData,
  newsList,
  fileName,
  type,
}) => {
  const tablecol = newsList.map((title) => {
    return title.title;
  });
  let index = 0;
  const newsDocNotificationData = csvData.map((csvData) => {
    index = index + 1;
    let inst = {
      "Sr. No.": index,
    };

    if (newsList.length !== 5) {
      newsList.map((title) => {
        if (title.name === "description") {
          inst = {
            ...inst,
            Description: csvData.description,
          };
        }

        if (title.name === "type") {
          inst = {
            ...inst,
            Type: csvData.type,
          };
        }

        if (title.name === "showLabel") {
          inst = {
            ...inst,
            "Show Label": csvData.showLabel,
          };
        }

        if (title.name === "orderNo") {
          inst = {
            ...inst,
            "order No": csvData.orderNo,
          };
        }
        if (title.name === "labelName") {
          inst = {
            ...inst,
            "Label Name": csvData.labelName,
          };
        }

        if (title.name === "isActive") {
          inst = {
            ...inst,
            Status: csvData.isActive,
          };
        }
        return title;
      });
    } else {
      inst = {
        ...inst,
        Description: csvData.description,
        Type: csvData.type,
        "Show Label": csvData.showLabel,
        "Label Name": csvData.labelName,
        "Order No": csvData.orderNo,
        Status: csvData.isActive,
      };
    }

    return inst;
  });
  if (type === "pdf") {
    return (
      <FontAwesomeIconCompo
        color="error"
        fontSize="small"
        title="PDF"
        baseClassName="fas"
        className="fa-file-pdf"
        onClick={() =>
          generatePDF(
            newsDocNotificationData,
            tablecol,
            "News Doc Notification Master"
          )
        }
      />
    );
  }
  return <ExportCSV csvData={newsDocNotificationData} fileName={fileName} />;
};
export default NewsDocNotificationExcelData;
