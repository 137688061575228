import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid, LinearProgress, Paper } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import Alert from "../../Alert/Alert";
import { getAlert } from "../../Alert/alert.slice";
import {
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "../../components/Comman/IconButton";
import DynamicTable from "../../components/Comman/MaterialUIRTTable";
import { DirectionSnackbar } from "../../components/Comman/SnakBarCompo";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
import { Loading1 } from "../../components/Loading1";
import Search from "../../components/Search";
import * as myConstClass from "../../config/messageconstant";
import { logOut } from "../Login/login.slice";
import {
  deleteClassIntakeDetailById,
  filterClassIntakeData,
  getClassIntakeDetail,
  resetDelete,
  resetSave,
  saveClassIntakeDetail,
  saveClassIntakeDetailFail,
} from "./claasIntakeDetail.slice";
import ClassDetIntForm from "./ClassIntakeDetailForm";
import ViewClassDetIntake from "./ClassIntakeDetailView";
import ClassIntDetExcel from "./ClassIntakeExcel";

const breakpoint = 600;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    title: "Year Name",
    name: "name",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    title: "Lateral Intake",
    name: "lateralIntake",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "right",
  },
  {
    title: "Sanction Intake",
    name: "sanctionIntake",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "right",
  },
  {
    title: "Status",
    name: "isActive",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "left",
  },
];

const ClassIntakeDetail = ({
  getClassIntakeDetail,
  filterClassIntakeData,
  resetSave,
  deleteClassIntakeDetailById,
  saveClassIntakeDetailFail,
  saveClassIntakeDetail,
  isFetch,
  classIntakeDetailList,
  loginUser,
  resetDelete,
  logOut,
  getAlert,
}) => {
  const [isEdit, setEdit] = useState(false);
  const [status, setStatus] = useState(false);
  const [del, setDel] = useState(false);
  const [save, setSave] = useState(false);
  const [editRow, setEditRow] = useState([]);
  const [viewRow, setViewRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [snakMsg, setSnakMsg] = useState("");
  const [openSnak, setOpenSnak] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const [filteropen, setFilterOpen] = useState(false);
  const [filteredList, setfilterList] = React.useState([
    "Sr. No.",
    "Year Name",
    "Academic Year Name",
    "Lateral Intake",
    "Sanction Intake",
    "Status",
  ]);
  const [classDetailList, setClassDetailList] = React.useState([
    {
      title: "Sr. No.",
      name: "index",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      title: "Year Name",
      name: "name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      title: "Lateral Intake",
      name: "lateralIntake",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      title: "Sanction Intake",
      name: "sanctionIntake",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      title: "Status",
      name: "isActive",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      title: "Action",
      name: "action",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
  ]);

  const [breadCrumName, setBreadCrum] = useState(
    "   >   Master   > Class Intake Detail "
  );
  const [width, setWidth] = React.useState(window.innerWidth);

  const rowStatus = async (id) => {
    swal(myConstClass.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        const viewRow = classIntakeDetailList?.ClassIntakeDetail.filter(
          (row) => row.id === id
        );

        let classIntakeDetailData = {
          yearDetail: {
            id: viewRow[0].classDetailId,
          },
          academicYear: {
            id: viewRow[0].acYearId,
          },
          id: viewRow[0].id,
          sanctionIntake: viewRow[0].sanctionIntake,
          isActive: viewRow[0].isActive === "Active" ? "0" : "1",
          delStatus: 1,
        };
        resetSave();
        setStatus(true);
        saveClassIntakeDetail({
          classIntakeDetailData: classIntakeDetailData,
        }).then((respo) => {
          if (!respo) {
            getAlert({ message: myConstClass.serverMsg });
          } else {
            setOpenSnak(true);
            setSnakMsg(myConstClass.activeStatus);
            getClassIntakeDetail().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
            setStatus(false);
          }
        });
      }
    });
  };
  const getRowID = async (id) => {
    setViewRow([]);
    const editedRow = classIntakeDetailList?.ClassIntakeDetail.filter(
      (row) => row.id === id
    );
    if (editedRow.length > 0) {
      setEdit(true);
      setEditRow([]);
      return await setEditRow(editedRow);
    }

    return setEditRow([]);
  };
  const getRowData = async (id) => {
    setOpen(true);
    const viewRow = classIntakeDetailList?.ClassIntakeDetail.filter(
      (row) => row.id === id
    );
    if (viewRow.length > 0) {
      return await setViewRow(viewRow);
    }
    return setViewRow([]);
  };

  const data = (instData) => {
    setSave(true);
    saveClassIntakeDetail({ classIntakeDetailData: instData }).then(() => {
      setOpenSnak(true);
      if (instData.id != null) {
        setSnakMsg(myConstClass.updateMsg);
      } else {
        setSnakMsg(myConstClass.saveMsg);
      }
      setSave(false);
      getClassIntakeDetail().then((respo) => {
        if (!respo) {
          getAlert({ message: myConstClass.serverMsg });
        }
      });
    });
    setEdit(false);
    setEditRow([]);
  };
  const saveAndNextData = (data) => {
    setSave(true);
    saveClassIntakeDetail({ classIntakeDetailData: data }).then(() => {
      setOpenSnak(true);
      if (data.id != null) {
        setSnakMsg(myConstClass.updateMsg);
      } else {
        setSnakMsg(myConstClass.saveMsg);
      }
      setSave(false);
      getClassIntakeDetail().then((respo) => {
        if (!respo) {
          getAlert({ message: myConstClass.serverMsg });
        }
      });
    });

    setEdit(true);
    setEditRow([]);
  };
  useEffect(() => {
    getClassIntakeDetail().then((respo) => {
      if (!respo) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  useEffect(() => {
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
  });

  const startEdit = () => {
    setEdit(true);
    let t = localStorage.getItem("token");
  };

  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setDel(true);
        deleteClassIntakeDetailById({ classIntakeDetailId: id }).then(
          (respo) => {
            if (!respo) {
              getAlert({ message: myConstClass.serverMsg });
            } else {
              setOpenSnak(true);
              setSnakMsg(myConstClass.deleteMsg);
              setDel(false);
              getClassIntakeDetail().then((respo) => {
                if (!respo) {
                  getAlert({ message: myConstClass.serverMsg });
                }
              });
            }
          }
        );
      }
    });
  };

  const classIntakeDetailFilteredData =
    classIntakeDetailList?.ClassIntakeDetail.filter(
      (item) =>
        item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.sanctionIntake === parseInt(searchValue) ||
        item.isActive.toLowerCase() === searchValue
    );

  const filteredRows = (searchvalue) => {
    setSearchValue(searchvalue);
    const filteredRow = classIntakeDetailList?.ClassIntakeDetail.filter(
      (row) => {
        let name = row?.name;

        let sanctionIntake = row?.sanctionIntake;
        let isActive = row?.isActive;
        return (
          name.toLowerCase().includes(searchvalue.toLowerCase()) ||
          sanctionIntake === parseInt(searchvalue) ||
          isActive.toLowerCase() === searchvalue
        );
      }
    );
    if (
      classIntakeDetailList?.ClassIntakeDetail.length == filteredRows.length
    ) {
      filterClassIntakeData({ row: filteredRow, flag: false });
    } else {
      filterClassIntakeData({ row: filteredRow, flag: true });
    }
  };

  const stopEdit = () => {
    setEditRow([]);
    setEdit(false);
  };
  const addFile = (file) => {};

  const closeFilter = () => {
    setClassDetailList([
      [
        {
          title: "Sr. No.",
          name: "index",
          positionCenter: true,
          showInscreen: true,
          alignCenter: "left",
        },
      ],
      [
        {
          title: "Year Name",
          name: "name",
          positionCenter: false,
          showInscreen: true,
          alignCenter: "left",
        },
      ],
      [
        {
          title: "Sanction Intake",
          name: "sanctionIntake",
          positionCenter: false,
          showInscreen: true,
          alignCenter: "right",
        },
      ],
      [
        {
          title: "Status",
          name: "isActive",
          positionCenter: true,
          showInscreen: true,
          alignCenter: "center",
        },
      ],
      [
        {
          title: "Action",
          name: "action",
          positionCenter: false,
          showInscreen: true,
          alignCenter: "right",
        },
      ],
    ]);
    setfilterList([
      "Year Name",
      "Academic Year Name",
      "Lateral Intake",
      "Sanction Intake",
      "Status",
    ]);
    setFilterOpen(false);
  };

  const handleChange = (event) => {
    setFilterOpen(true);
    const {
      target: { value },
    } = event;
    if (value.length > filteredList.length) {
      let row = [];
      const a = classDetailList.map((qualiData) => {
        let trust = value.filter((namesRow) => namesRow === qualiData.title);

        if (trust.length !== 0) {
          console.log("shraddha");
          let qData = {
            ...qualiData,
            showInscreen: true,
          };
          row.push(qData);
        } else {
          let qData = {
            ...qualiData,
            showInscreen: false,
          };
          row.push(qualiData);
        }

        return setClassDetailList(row);
      });
      console.log(row);
    } else {
      let row = [];
      let difference = filteredList.filter((x) => !value.includes(x));
      const qualificationList = classDetailList.filter((data) => {
        if (data.title === difference[0]) {
          let QualiData = {
            ...data,
            showInscreen: false,
          };
          row.push(QualiData);
        } else {
          row.push(data);
        }
      });
      console.log("row");
      console.log(row);
      setClassDetailList(row);
    }

    setfilterList(typeof value === "string" ? value.split(",") : value);

    if (value.length === 0) {
      setFilterOpen(false);
    }
  };

  // const handleChange = (event) => {
  //   setFilterOpen(true);
  //   const {
  //     target: { value },
  //   } = event;

  //   if (value.length > filteredList.length) {
  //     const a = value.map((value) => {
  //       const clsdet = names.filter((row) => row.title === value);
  //       const clsindet = [...classDetailList, clsdet];

  //       return setClassDetailList(clsindet);
  //     });
  //   } else {
  //     let difference = filteredList.filter((x) => !value.includes(x));
  //     const classIntListNew = classDetailList.filter((data) => {
  //       return data[0].title !== difference[0];
  //     });
  //     setClassDetailList(classIntListNew);
  //   }

  //   setfilterList(typeof value === "string" ? value.split(",") : value);

  //   if (value.length === 0) {
  //     setFilterOpen(false);
  //   }
  // };
  if (isFetch) {
    return (
      <>
        <div>
          <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
            <TitleAndBreadCrum
              title="Class Intake Detail"
              breadCrumName={breadCrumName}
            />
          </Paper>

          <br />
          {/* {classIntakeDetailList.isDelete && classIntakeDetailList.error == true && (
            <Alert msg={myConstClass.deleteFailMsg} closeAlert={resetDelete} />
          )}
          {classIntakeDetailList.isSave && classIntakeDetailList.error == true && (
            <Alert msg={myConstClass.saveFailMsg} closeAlert={resetDelete} />
          )}
          {classIntakeDetailList.isServerFail && <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />} */}

          {/* < ErrorHand error={"error"} msg={'This is an error alert — check it out!'} flag={true}/>  */}

          {classIntakeDetailList.isDelete &&
          classIntakeDetailList.error == false ? (
            <DirectionSnackbar
              open={openSnak}
              severity="success"
              msg={myConstClass.deleteMsg}
              closeSnak={setOpenSnak}
            />
          ) : classIntakeDetailList.isSave &&
            classIntakeDetailList.error == false ? (
            <DirectionSnackbar
              open={openSnak}
              severity="success"
              msg={snakMsg}
              closeSnak={setOpenSnak}
            />
          ) : (
            ""
          )}

          <Paper sx={{ p: 2, pt: 4, borderRadius: 2 }} elevation={0}>
            <div>
              {viewRow.length >= 0 && editRow.length >= 0 && isEdit && (
                <ClassDetIntForm
                  OnSave={data}
                  isEdit={editRow}
                  onCancel={stopEdit}
                  saveAndnext={saveAndNextData}
                  handleFile={addFile}
                />
              )}
            </div>
            {open && (
              <ViewClassDetIntake
                data={viewRow}
                openparam={open}
                closeView={setOpen}
              />
            )}
            <Grid>
              {!isEdit && (
                <div>
                  {width > breakpoint ? (
                    <>
                      <Box
                        sx={{ mr: 2 }}
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Box sx={{ m: -1, width: 300 }}>
                          {!filteropen && (
                            <Search
                              filteredRows={filteredRows}
                              searchValue={searchValue}
                            />
                          )}
                        </Box>
                        <Box>
                          <FormControl sx={{ m: -1, width: 250 }}>
                            <Select
                              labelId="demo-multiple-checkbox-label"
                              id="demo-multiple-checkbox"
                              multiple
                              value={filteredList}
                              name="sdf"
                              size="small"
                              onChange={handleChange}
                              renderValue={(selected) => selected.join(", ")}
                              MenuProps={MenuProps}
                            >
                              {names.map((name) => {
                                return (
                                  <MenuItem key={name.name} value={name.title}>
                                    <Checkbox
                                      checked={
                                        filteredList.indexOf(name.title) > -1
                                      }
                                    />
                                    <ListItemText primary={name.title} />
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>

                          {filteropen && (
                            <>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <ClassIntDetExcel
                                csvData={classIntakeDetailFilteredData}
                                classIntakeDetList={classDetailList}
                                fileName="Class Intake Detail"
                                type="pdf"
                              />
                            </>
                          )}
                          {filteropen && (
                            <>
                              &nbsp;&nbsp;
                              <ClassIntDetExcel
                                csvData={classIntakeDetailFilteredData}
                                classIntakeDetList={classDetailList}
                                fileName="Class Intake Detail"
                                type="excel"
                              />
                            </>
                          )}

                          {filteropen && (
                            <>
                              &nbsp;&nbsp;
                              <FontAwesomeIconCompo
                                className="fa-times-circle"
                                color="primary"
                                title="Cancel"
                                baseClassName="fas"
                                onClick={closeFilter}
                              />
                            </>
                          )}
                          {!filteropen && filteredList.length >= 0 && (
                            <>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <ClassIntDetExcel
                                csvData={classIntakeDetailFilteredData}
                                classIntakeDetList={classDetailList}
                                fileName="Class Intake Detail"
                                type="pdf"
                              />
                            </>
                          )}

                          {!filteropen && filteredList.length >= 0 && (
                            <>
                              &nbsp;&nbsp;
                              <ClassIntDetExcel
                                csvData={classIntakeDetailFilteredData}
                                classIntakeDetList={classDetailList}
                                fileName="Class Intake Detail"
                                type="excel"
                              />
                            </>
                          )}

                          {!filteropen && filteredList.length >= 0 && (
                            <>
                              &nbsp;&nbsp;&nbsp;
                              <MyComponentWithIconProps
                                statusImage={AddCircleIcon}
                                color="primary"
                                title=""
                                fontSize="large"
                                sx={{ m: -1 }}
                                onClick={startEdit}
                              />
                            </>
                          )}
                        </Box>
                      </Box>
                      <Box sx={{ mt: 2, p: 1.5 }}>
                        <DynamicTable
                          data={classIntakeDetailFilteredData}
                          tableHead={classDetailList}
                          rowEdit={getRowID}
                          rowDelete={deleteData}
                          rowStatus={rowStatus}
                          showHeadDelete={true}
                          showHeadEdit={true}
                        />
                        {/* <ClassIntDetailTable
                      filteredColumns={classDetailList}
                      data={classIntakeDetailFilteredData}
                      rowEdit={getRowID}
                      rowView={getRowData}
                      rowDelete={deleteData}
                      rowStatus={rowStatus}
                    />{" "} */}
                      </Box>
                    </>
                  ) : (
                    <>
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        rowSpacing={2}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid xs={12} sm={12} md={12} lg={12} rowSpacing={2}>
                          {!filteropen && (
                            <Search
                              filteredRows={filteredRows}
                              searchValue={searchValue}
                            />
                          )}
                        </Grid>
                        <Grid xs={12} sm={12} md={12} lg={12} sx={{ m: 1 }}>
                          <FormControl sx={{ width: "100%" }}>
                            <Select
                              labelId="demo-multiple-checkbox-label"
                              id="demo-multiple-checkbox"
                              multiple
                              value={filteredList}
                              name="sdf"
                              size="small"
                              onChange={handleChange}
                              renderValue={(selected) => selected.join(", ")}
                              MenuProps={MenuProps}
                            >
                              {names.map((name) => {
                                return (
                                  <MenuItem key={name.name} value={name.title}>
                                    <Checkbox
                                      checked={
                                        filteredList.indexOf(name.title) > -1
                                      }
                                    />
                                    <ListItemText primary={name.title} />
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                          <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{ m: 1, textAlign: "right" }}
                          >
                            {filteropen && (
                              <>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <ClassIntDetExcel
                                  csvData={classIntakeDetailFilteredData}
                                  classIntakeDetList={classDetailList}
                                  fileName="Class Intake Detail"
                                  type="pdf"
                                />
                              </>
                            )}
                            {filteropen && (
                              <>
                                &nbsp;&nbsp;
                                <ClassIntDetExcel
                                  csvData={classIntakeDetailFilteredData}
                                  classIntakeDetList={classDetailList}
                                  fileName="Class Intake Detail"
                                  type="excel"
                                />
                              </>
                            )}

                            {filteropen && (
                              <>
                                &nbsp;&nbsp;
                                <FontAwesomeIconCompo
                                  className="fa-times-circle"
                                  color="primary"
                                  title="Cancel"
                                  baseClassName="fas"
                                  onClick={closeFilter}
                                />
                              </>
                            )}
                            {!filteropen && filteredList.length >= 0 && (
                              <>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <ClassIntDetExcel
                                  csvData={classIntakeDetailFilteredData}
                                  classIntakeDetList={classDetailList}
                                  fileName="Class Intake Detail"
                                  type="pdf"
                                />
                              </>
                            )}

                            {!filteropen && filteredList.length >= 0 && (
                              <>
                                &nbsp;&nbsp;
                                <ClassIntDetExcel
                                  csvData={classIntakeDetailFilteredData}
                                  classIntakeDetList={classDetailList}
                                  fileName="Class Intake Detail"
                                  type="excel"
                                />
                              </>
                            )}

                            {!filteropen && filteredList.length >= 0 && (
                              <>
                                &nbsp;&nbsp;&nbsp;
                                <MyComponentWithIconProps
                                  statusImage={AddCircleIcon}
                                  color="primary"
                                  title=""
                                  fontSize="large"
                                  sx={{ m: -1 }}
                                  onClick={startEdit}
                                />
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Box sx={{ mt: 2, p: 1.5 }}>
                        <DynamicTable
                          data={classIntakeDetailFilteredData}
                          tableHead={classDetailList}
                          rowEdit={getRowID}
                          rowDelete={deleteData}
                          rowStatus={rowStatus}
                          showHeadDelete={true}
                          showHeadEdit={true}
                        />
                        {/* <ClassIntDetailTable
                      filteredColumns={classDetailList}
                      data={classIntakeDetailFilteredData}
                      rowEdit={getRowID}
                      rowView={getRowData}
                      rowDelete={deleteData}
                      rowStatus={rowStatus}
                    />{" "} */}
                      </Box>
                    </>
                  )}
                </div>
              )}
            </Grid>
            {!classIntakeDetailList?.isServerFail &&
            !classIntakeDetailList?.isFetch ? (
              <Loading1 size={50} />
            ) : status && !classIntakeDetailList?.isSave ? (
              <Loading1 size={50} />
            ) : save && !classIntakeDetailList?.isSave ? (
              <Loading1 size={50} />
            ) : del ? (
              <Loading1 size={50} />
            ) : (
              ""
            )}
          </Paper>
        </div>
      </>
    );
  } else {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }
};
const mapStateToProps = (state) => ({
  classIntakeDetailList: state.ClassIntakeDetail,
  isFetch: state.ClassIntakeDetail,
  loginUser: state.user,
  common: state.common,
});

const mapDispatchToProps = {
  getClassIntakeDetail,
  filterClassIntakeData,
  resetSave,
  deleteClassIntakeDetailById,
  saveClassIntakeDetail,
  saveClassIntakeDetailFail,
  getAlert,
  resetDelete,
  logOut,
};
export default connect(mapStateToProps, mapDispatchToProps)(ClassIntakeDetail);
