import React from "react";
import ExportCSV from "../../components/Comman/ExportCSV";
import { generatePDF } from "../../components/Comman/GeneratePDF";
import { FontAwesomeIconCompo } from "../../components/Comman/IconButton";
const ProgExcelData = ({ csvData, programList, fileName, type }) => {
  const tablecol = programList.map((title) => {
    if (title.title !== "Action") {
      return title.title;
    }
  });
  let index = 0;
  const instituteData = csvData.map((csvData) => {
    index = index + 1;
    let inst = {
      "Sr. No.": index,
    };

    if (programList.length !== 7) {
      programList.map((title) => {
        if (title.name === "name") {
          inst = {
            ...inst,
            "Program Type Name": csvData.name,
          };
        }
        if (title.name === "progName") {
          inst = {
            ...inst,
            Program: csvData.progName,
          };
        }
        if (title.name === "uniName") {
          inst = {
            ...inst,
            "University Name": csvData.uniName,
          };
        }
        if (title.name === "progDuration") {
          inst = {
            ...inst,
            "Program Duration (In years)": csvData.progDuration,
          };
        }
        if (title.name === "feBrName") {
          inst = {
            ...inst,
            "First Year Program title ": csvData.feBrName,
          };
        }
        if (title.name === "isActive") {
          inst = {
            ...inst,
            Status: csvData.isActive,
          };
        }
        return title;
      });
    } else {
      inst = {
        ...inst,
        Program: csvData.progName,
        "University Name": csvData.uniName,
        "Program Type Name": csvData.name,
        "Program Duration (In years)": csvData.progDuration,
        "Fisrt Year Program title ": csvData.feBrName,
        Status: csvData.isActive,
      };
    }

    return inst;
  });
  if (type === "pdf") {
    return (
      <FontAwesomeIconCompo
        color="error"
        fontSize="small"
        title="PDF"
        baseClassName="fas"
        className="fa-file-pdf"
        onClick={() =>
          generatePDF(instituteData, tablecol, "Program Type Master")
        }
      />
    );
  }
  return <ExportCSV csvData={instituteData} fileName={fileName} />;
};
export default ProgExcelData;
