import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid, Paper } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import { logOut } from "../Login/login.slice";
import { getAlert } from "../../Alert/alert.slice";
import { useNavigate } from "react-router";

import Select from "@mui/material/Select";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import Alert from "../../Alert/Alert";
import {
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "../../components/Comman/IconButton";
import { DirectionSnackbar } from "../../components/Comman/SnakBarCompo";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
import { Loading1 } from "../../components/Loading1";
import Search from "../../components/Search";
import * as myConstClass from "../../config/messageconstant";
import {
  deleteClassDetailById,
  resetDetailsSave,
  saveClassDetails,
} from "../ClassDetail/classDetail.slice";
import ClassDetailForm from "../ClassDetail/ClassDetailForm";
import {
  deleteClassById,
  filterClassData,
  getClass,
  resetSave,
  saveClass,
  resetDelete,
} from "./class.slice";
import ClassExcelData from "./ClassExcelData";
import ClassForm from "./ClassForm";
import ClassTable from "./ClassTable";
import ViewClass from "./ClassView";

const breakpoint = 600;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  { title: "Program Type", name: "progName" },
  { title: "Name", name: "name" },
  { title: "Institute Name", name: "tenantName" },
  { title: "Sort Order No", name: "sortOrderNo" },
  { title: "Status", name: "isActive" },
];

const ClassMaster = ({
  saveClassDetails,
  resetDetailsSave,
  resetSave,
  loginUser,
  getAlert,
  resetDelete,
  logOut,
  getClass,
  saveClass,
  deleteClassById,
  classMaster,
  classDetails,
  deleteClassDetailById,
  filterClassData,
}) => {
  const [isEdit, setEdit] = useState(false);
  const [status, setStatus] = useState(false);
  const [status1, setStatus1] = useState(false);
  const [del, setDel] = useState(false);
  const [save, setSave] = useState(false);
  const [snakMsg, setSnakMsg] = useState("");
  const [editRow, setEditRow] = useState([]);
  const [editClassDetailRow, setClassDetailEditRow] = useState([]);
  const [viewRow, setViewRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteropen, setFilterOpen] = useState(false);
  const [openSnak, setOpenSnak] = useState(false);
  const navigate = useNavigate();
  const [classDetailsList, setClassDetails] = useState(0);
  const [classDetailsOpen, setClassDetailsOpen] = React.useState(false);
  const [filteredList, setfilterList] = React.useState([
    "Program Type",
    "Name",
    "Institute Name",
    "Sort Order No",
    "Status",
  ]);
  const [classList, setClassList] = React.useState([
    [{ title: "Sr. No.", name: "index" }],
    [{ title: "Program Type", name: "progName" }],
    [{ title: "Name", name: "name" }],
    [{ title: "Institute Name", name: "tenantName" }],
    [{ title: "Sort Order No", name: "sortOrderNo" }],
    [{ title: "Status", name: "isActive" }],
  ]);

  const [breadCrumName, setBreadCrum] = useState("Class ");
  const [width, setWidth] = React.useState(window.innerWidth);

  const rowStatus = async (id) => {
    swal(myConstClass.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        const viewRow = classMaster?.class.filter((row) => row.id === id);
        let classData = {
          programType: {
            id: viewRow[0].progNameId,
          },
          tenant: {
            id: viewRow[0].instNameId,
          },
          id: viewRow[0].id,
          className: viewRow[0].name,
          sortOrderNo: viewRow[0].sortOrderNo,
          isActive: viewRow[0].isActive === "Active" ? "0" : "1",
          delStatus: 1,
        };
        resetSave();
        setStatus(true);
        saveClass({ classData: classData }).then((respo) => {
          if (!respo) {
            getAlert({ message: myConstClass.serverMsg });
          } else {
            setOpenSnak(true);
            setSnakMsg(myConstClass.activeStatus);
            getClass().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
          }
        });
      }
    });
  };

  const rowClassDetailStatus = async (id, classdDetailid) => {
    swal(myConstClass.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        const viewRow = classMaster?.class.filter((row) => row.id === id);
        let classdata = [];
        const editedRow = classMaster?.class.map((data) => {
          if (data.id == id) {
            classdata = data?.classDetails.filter(
              (row) => row.id === classdDetailid
            );
          }
        });
        let classData1 = {
          year: {
            id: id,
          },
          programTitle: {
            id: classdata[0].courseId,
          },
          id: classdata[0].id,
          isActive: classdata[0].isActive === "Active" ? "0" : "1",
        };
        resetDetailsSave();
        setStatus1(true);
        saveClassDetails({ classDetail: classData1 }).then((respo) => {
          if (!respo) {
            getAlert({ message: myConstClass.serverMsg });
          } else {
            setOpenSnak(true);
            setSnakMsg(myConstClass.activeStatus);
            getClass().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
          }
        });
      }
    });
  };
  const getRowID = async (id) => {
    setViewRow([]);
    const editedRow = classMaster?.class.filter((row) => row.id === id);
    if (editedRow.length > 0) {
      setEdit(true);
      setEditRow([]);
      return await setEditRow(editedRow);
    }
    return setEditRow([]);
  };

  const rowClassDetailEdit = async (id, classdDetailid) => {
    setViewRow([]);
    let classdata = [];
    const viewRow = classMaster?.class.filter((row) => row.id === id);
    setClassDetails(viewRow);
    const editedRow = classMaster?.class.map((data) => {
      if (data.id == id) {
        classdata = data?.classDetails.filter(
          (row) => row.id === classdDetailid
        );
      }
    });
    if (editedRow.length > 0) {
      setClassDetailsOpen(true);
      setClassDetailEditRow([]);
      return await setClassDetailEditRow(classdata);
    }
    return setClassDetailEditRow([]);
  };
  const getRowData = async (id) => {
    setOpen(true);
    const viewRow = classMaster?.class.filter((row) => row.id === id);
    if (viewRow.length > 0) {
      return await setViewRow(viewRow);
    }
    return setViewRow([]);
  };

  const data = (instData) => {
    resetSave();
    setSave(true);
    saveClass({ classData: instData }).then(() => {
      setOpenSnak(true);
      if (instData.id != null) {
        setSnakMsg(myConstClass.updateMsg);
      } else {
        setSnakMsg(myConstClass.saveMsg);
      }
      getClass().then((respo) => {
        if (!respo) {
          getAlert({ message: myConstClass.serverMsg });
        }
      });
    });

    setEdit(false);
    setEditRow([]);
  };

  useEffect(() => {
    getClass().then((respo) => {
      if (!respo) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(async () => {
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
  });

  const startEdit = () => {
    let t = localStorage.getItem("token");
    setEdit(true);
  };

  const saveAndNextClassDetailsData = (data) => {
    saveClassDetails({ classDetail: data }).then(() => {
      setOpenSnak(true);
      setClassDetailsOpen(true);
      if (data.id != null) {
        setSnakMsg(myConstClass.updateMsg);
      } else {
        setSnakMsg(myConstClass.saveMsg);
      }
      getClass().then((respo) => {
        if (!respo) {
          getAlert({ message: myConstClass.serverMsg });
        }
      });
    });
  };
  const saveAndNextData = (data) => {
    saveClass({ classData: data }).then(() => {
      setOpenSnak(true);
      if (data.id != null) {
        setSnakMsg(myConstClass.updateMsg);
      } else {
        setSnakMsg(myConstClass.saveMsg);
      }
      getClass().then((respo) => {
        if (!respo) {
          getAlert({ message: myConstClass.serverMsg });
        }
      });
    });
    setEdit(true);
    setEditRow([]);
  };

  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setDel(true);
        deleteClassById({ classId: id }).then((respo) => {
          if (!respo) {
            getAlert({ message: myConstClass.serverMsg });
          } else {
            setOpenSnak(true);
            setSnakMsg(myConstClass.deleteMsg);
            setDel(false);
            getClass().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
          }
        });
      }
    });
  };

  const rowClassDetailDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setDel(true);
        deleteClassDetailById({ classDetailsId: id }).then((respo) => {
          if (!respo) {
            getAlert({ message: myConstClass.serverMsg });
          } else {
            setOpenSnak(true);
            setSnakMsg(myConstClass.deleteMsg);
            setDel(false);
            getClass().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
          }
        });
      }
    });
  };

  const classFilteredData = classMaster?.class.filter(
    (item) =>
      item.progName.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.tenantName.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.sortOrderNo === parseInt(searchValue) ||
      item.isActive.toLowerCase() === searchValue
  );

  const filteredRows = (searchvalue) => {
    setSearchValue(searchvalue);
    const filteredRow = classMaster?.class.filter((row) => {
      let progName = row?.progName;
      let name = row?.name;
      let tenantName = row?.tenantName;
      let sortOrderNo = row?.sortOrderNo;
      let isActive = row?.isActive;
      return (
        progName.toLowerCase().includes(searchvalue.toLowerCase()) ||
        name.toLowerCase().includes(searchvalue.toLowerCase()) ||
        tenantName.toLowerCase().includes(searchvalue.toLowerCase()) ||
        sortOrderNo === parseInt(searchvalue) ||
        isActive.toLowerCase() === searchvalue
      );
    });
    if (classMaster?.class.length == filteredRows.length) {
      filterClassData({ row: filteredRow, flag: false });
    } else {
      filterClassData({ row: filteredRow, flag: true });
    }
  };

  const stopEdit = () => {
    setBreadCrum("   >   Master   >   Year  ");
    setEdit(false);
    setEditRow([]);
  };

  const addFile = (file) => {}; //

  const closeFilter = () => {
    setClassList([
      [{ title: "Sr. No.", name: "index" }],
      [{ title: "Program Type", name: "progName" }],
      [{ title: "Name", name: "name" }],
      [{ title: "Institute Name", name: "tenantName" }],
      [{ title: "Sort Order No", name: "sortOrderNo" }],
      [{ title: "Status", name: "isActive" }],
    ]);
    setfilterList([
      "Program Type",
      "Name",
      "Institute Name",
      "Sort Order No",
      "Status",
    ]);
    setFilterOpen(false);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value.length > filteredList.length) {
      const a = value.map((value) => {
        const class1 = names.filter((row) => row.title === value);
        const class2 = [...classList, class1];

        return setClassList(class2);
      });
    } else {
      let difference = filteredList.filter((x) => !value.includes(x));
      const classList1 = classList.filter((data) => {
        return data[0].title !== difference[0];
      });
      setClassList(classList1);
    }

    setfilterList(typeof value === "string" ? value.split(",") : value);

    if (value.length === 0) {
      setFilterOpen(false);
    }
  };
  const addClassDetails = (id) => {
    // const { getprogramTitleSuccess } = this.props;
    const viewRow = classMaster?.class.filter((row) => row.id === id);
    setClassDetails(viewRow);
    console.log(viewRow);
    console.log(viewRow[0].progNameId);
    // getprogramTitleSuccess({programTypeId: viewRow[0].progNameId})
    setClassDetailsOpen(true);
  };
  const closeClassDetails = () => {
    setClassDetailsOpen(false);
    setClassDetailEditRow([]);
  };

  const saveClassDetailsData = (data) => {
    setSave(true);
    saveClassDetails({ classDetail: data }).then(() => {
      setOpenSnak(true);
      setClassDetailsOpen(false);
      if (data.id != null) {
        setSnakMsg(myConstClass.updateMsg);
      } else {
        setSnakMsg(myConstClass.saveMsg);
      }
      setSave(false);
      getClass().then((respo) => {
        if (!respo) {
          getAlert({ message: myConstClass.serverMsg });
        }
      });
    });
  };

  const handleCallback = (data) => {
    setClassDetailsOpen(false);
    setEdit(false);
    setEditRow([]);
  };
  return (
    <>
      <div>
        <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
          <TitleAndBreadCrum title="Year Title" />
        </Paper>
        &nbsp;&nbsp;
        <br />
        {/* {classMaster.isDelete && classMaster.error == true && (
          <Alert msg={myConstClass.deleteFailMsg} closeAlert={resetDelete} />
        )}
        {classMaster.isServerFail && <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />} */}
        {/* < ErrorHand error={"error"} msg={'This is an error alert — check it out!'} flag={true}/>  */}
        {classMaster.isDelete && classMaster.error == false ? (
          <DirectionSnackbar
            open={openSnak}
            severity="success"
            msg={myConstClass.deleteMsg}
            closeSnak={setOpenSnak}
          />
        ) : classMaster.isSave ? (
          <DirectionSnackbar
            open={openSnak}
            severity="success"
            msg={snakMsg}
            closeSnak={setOpenSnak}
          />
        ) : (
          ""
        )}
        <Paper sx={{ p: 2, pt: 4, borderRadius: 2 }} elevation={0}>
          <div>
            {viewRow.length >= 0 && editRow.length >= 0 && isEdit && (
              <ClassForm
                OnSave={data}
                isEdit={editRow}
                onCancel={stopEdit}
                handleFile={addFile}
                saveAndNext={saveAndNextData}
              />
            )}
          </div>

          {open && (
            <ViewClass data={viewRow} openparam={open} closeView={setOpen} />
          )}
          {classDetailsOpen && !isEdit && (
            <ClassDetailForm
              isEdit={editClassDetailRow}
              OnSave={saveClassDetailsData}
              classDetails={classDetailsList}
              onCancel={closeClassDetails}
              saveAndNext={saveAndNextClassDetailsData}
            />
          )}
          <Grid>
            {!isEdit && !classDetailsOpen && (
              <div>
                {width > breakpoint ? (
                  <>
                    <Box
                      sx={{ mr: 2 }}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Box sx={{ m: -1, width: 300 }}>
                        {!filteropen && (
                          <Search
                            filteredRows={filteredRows}
                            // rows={row}
                            searchValue={searchValue}
                          />
                        )}
                      </Box>

                      <Box>
                        {/* <FormControl sx={{ m: -1, width: 250 }}>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={filteredList}
                        name="sdf"
                        size="small"
                        onChange={handleChange}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => {
                          return (
                            <MenuItem key={name.name} value={name.title}>
                              <Checkbox
                                checked={filteredList.indexOf(name.title) > -1}
                              />
                              <ListItemText primary={name.title} />
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl> */}

                        {filteropen && (
                          <>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <ClassExcelData
                              csvData={classFilteredData}
                              classesList={classList}
                              fileName="Year Master"
                              type="pdf"
                            />
                          </>
                        )}

                        {filteropen && (
                          <>
                            &nbsp;&nbsp;
                            <ClassExcelData
                              csvData={classFilteredData}
                              classesList={classList}
                              fileName="Year Master"
                              type="excel"
                            />
                          </>
                        )}

                        {filteropen && (
                          <>
                            &nbsp;&nbsp;
                            <FontAwesomeIconCompo
                              className="fa-times-circle"
                              color="primary"
                              title="Cancel"
                              baseClassName="fas"
                              onClick={closeFilter}
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <ClassExcelData
                              csvData={classFilteredData}
                              classesList={classList}
                              fileName="Year Master"
                              type="pdf"
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;
                            <ClassExcelData
                              csvData={classFilteredData}
                              classesList={classList}
                              fileName="Year Master"
                              type="excel"
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;&nbsp;
                            <MyComponentWithIconProps
                              statusImage={AddCircleIcon}
                              color="primary"
                              title=""
                              fontSize="large"
                              sx={{ m: -1 }}
                              onClick={startEdit}
                            />
                          </>
                        )}
                      </Box>
                    </Box>
                    <Box sx={{ mt: 2, p: 1.5 }}>
                      <ClassTable
                        filteredColumns={classList}
                        data={classFilteredData}
                        rowEdit={getRowID}
                        rowView={getRowData}
                        rowDelete={deleteData}
                        rowStatus={rowStatus}
                        rowAdd={addClassDetails}
                        rowClassDetailStatus={rowClassDetailStatus}
                        rowClassDetailEdit={rowClassDetailEdit}
                        rowClassDetailDelete={rowClassDetailDelete}
                      />{" "}
                    </Box>
                  </>
                ) : (
                  <>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      rowSpacing={2}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid xs={12} sm={12} md={12} lg={12} rowSpacing={2}>
                        {!filteropen && (
                          <Search
                            filteredRows={filteredRows}
                            // rows={row}
                            searchValue={searchValue}
                          />
                        )}
                      </Grid>

                      <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{ m: 1, textAlign: "right" }}
                      >
                        {/* <FormControl sx={{ m: -1, width: 250 }}>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={filteredList}
                        name="sdf"
                        size="small"
                        onChange={handleChange}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => {
                          return (
                            <MenuItem key={name.name} value={name.title}>
                              <Checkbox
                                checked={filteredList.indexOf(name.title) > -1}
                              />
                              <ListItemText primary={name.title} />
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl> */}

                        {filteropen && (
                          <>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <ClassExcelData
                              csvData={classFilteredData}
                              classesList={classList}
                              fileName="Year Master"
                              type="pdf"
                            />
                          </>
                        )}

                        {filteropen && (
                          <>
                            &nbsp;&nbsp;
                            <ClassExcelData
                              csvData={classFilteredData}
                              classesList={classList}
                              fileName="Year Master"
                              type="excel"
                            />
                          </>
                        )}

                        {filteropen && (
                          <>
                            &nbsp;&nbsp;
                            <FontAwesomeIconCompo
                              className="fa-times-circle"
                              color="primary"
                              title="Cancel"
                              baseClassName="fas"
                              onClick={closeFilter}
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <ClassExcelData
                              csvData={classFilteredData}
                              classesList={classList}
                              fileName="Year Master"
                              type="pdf"
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;
                            <ClassExcelData
                              csvData={classFilteredData}
                              classesList={classList}
                              fileName="Year Master"
                              type="excel"
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;&nbsp;
                            <MyComponentWithIconProps
                              statusImage={AddCircleIcon}
                              color="primary"
                              title=""
                              fontSize="large"
                              sx={{ m: -1 }}
                              onClick={startEdit}
                            />
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Box sx={{ mt: 2, p: 1.5 }}>
                      <ClassTable
                        filteredColumns={classList}
                        data={classFilteredData}
                        rowEdit={getRowID}
                        rowView={getRowData}
                        rowDelete={deleteData}
                        rowStatus={rowStatus}
                        rowAdd={addClassDetails}
                        rowClassDetailStatus={rowClassDetailStatus}
                        rowClassDetailEdit={rowClassDetailEdit}
                        rowClassDetailDelete={rowClassDetailDelete}
                      />{" "}
                    </Box>
                  </>
                )}
              </div>
            )}
          </Grid>

          {!classMaster.isServerFail && !classMaster?.isFetchClass ? (
            <Loading1 size={50} />
          ) : status && !classMaster?.isSave ? (
            <Loading1 size={50} />
          ) : save && !classMaster?.isSave ? (
            <Loading1 size={50} />
          ) : status1 && !classDetails?.isSave ? (
            <Loading1 size={50} />
          ) : del ? (
            <Loading1 size={50} />
          ) : (
            ""
          )}
        </Paper>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  classMaster: state.class,
  loginUser: state.user,
  classDetails: state.classDetails,
  common: state.common,
});

const mapDispatchToProps = {
  getClass,
  saveClass,
  resetDetailsSave,
  resetSave,
  deleteClassById,
  saveClassDetails,
  deleteClassDetailById,
  filterClassData,
  getAlert,
  resetDelete,
  logOut,
};
export default connect(mapStateToProps, mapDispatchToProps)(ClassMaster);
