import { TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { onlyNumber } from "../../components/Comman/Util/Validations";
let columns;
export default function ReservationStructureTable({
  resRow,
  rowTData,
  editCategory,
}) {
  const [values, setValues] = useState([]);

  columns = [
    { field: "index", headerName: "Sr. No.", minWidth: 100, sortable: false },
    { field: "name", headerName: "Category", sortable: false, flex: 1 },
    {
      field: "percentage",
      headerName: "Percentage",
      sortable: false,
      minWidth: 250,
      renderCell: (params) => {
        const changeHandler = (id, e) => {
          const { value } = e.target;
          if (!onlyNumber.test(value)) {
            setValues((data) =>
              data?.map((list, index) =>
                list.id === id
                  ? { ...list, percentage: value, percentageValid: 2 }
                  : list
              )
            );
          } else if (value > 100) {
            setValues((data) =>
              data?.map((list, index) =>
                list.id === id
                  ? { ...list, percentage: value, percentageValid: 3 }
                  : list
              )
            );
          } else {
            let categoryData = values.filter((data, index) => data.id !== id);
            let sum = 0;
            categoryData.map((data, index) => {
              sum = +data.percentage + sum;
              return data;
            });

            let currentValue = +value;

            sum = sum + currentValue;

            if (sum > 100) {
              setValues((data) =>
                data?.map((list, index) =>
                  list.id === id
                    ? { ...list, percentage: value, percentageValid: 4 }
                    : list
                )
              );
            } else {
              setValues((data) =>
                data?.map((list, index) =>
                  list.id === id
                    ? { ...list, percentage: value, percentageValid: 0 }
                    : list
                )
              );
            }
          }
        };

        return (
          <div>
            <TextField
              label=""
              placeholder="Percentage"
              color="primary"
              name={"Percentage" + params.id}
              size="small"
              value={params.row.percentage}
              {...(params.row.percentageValid === 1
                ? { error: true, helperText: "please enter percentage " }
                : params.row.percentageValid === 2
                ? { error: true, helperText: "please enter Valid percentage" }
                : params.row.percentageValid === 3
                ? {
                    error: true,
                    helperText: "please enter less than 100",
                  }
                : params.row.percentageValid === 4
                ? {
                    error: true,
                    helperText: "sum of all percentage less than  100",
                  }
                : "")}
              onChange={(e) => changeHandler(params.row.id, e)}
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    rowTData(values);
  });
  useEffect(() => {
    if (editCategory.length === 0) {
      setValues(resRow);
    } else {
      setValues(editCategory);
    }
  }, []);
  return (
    <div align="center">
      <DataGrid
        autoHeight={true}
        rows={values}
        columns={columns}
        disableColumnMenu
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
    </div>
  );
}
