import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const ViewInst = ({ openparam, closeView, fileData }) => {
  return (
    <div>
      <Grid style={{ display: "flex", alignItems: "baseline" }}>
        {fileData.isDownload && window.open(fileData.filePath)}
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  fileData: state.file,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ViewInst);
