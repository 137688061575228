import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Grid, TextField, Paper, Typography, Button } from "@mui/material";
import { isEmpty } from "lodash-es";
import { ButtonCompo } from "../../components/Comman/Buttton";
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

let matrixData;
export default function BasicTable(props) {
  const [total, setTotal] = useState({});

  const [reservList, setreservList] = useState(props.cat ? props.cat : "");

  const changeHandler = (row, name, e) => {
    let genderAll = 0;
    let genderF = 0;
    let genderM = 0;
    let genderT = 0;
    let allotmentPriority = 0;
    let totalReserv = 0;
    setreservList((data) =>
      data?.map((list, index) => {
        let total = 0;
        if (list.id === row.id) {
          if (
            name === "genderAll" ||
            name === "genderF" ||
            name === "genderT" ||
            name === "genderM" || name === "allotmentPriority"  
          ) {
            if (name === "genderAll" && e !== "") {
              genderAll = genderAll + +e;
              total = +e + +list.genderF + +list.genderT + +list.genderM;
            } else if (name === "genderAll") {
              total = +list.genderF + +list.genderT + +list.genderM;
            }

            if (name === "genderF" && e !== "") {
              genderF = genderF + +e;
              total = +e + +list.genderAll + +list.genderT + +list.genderM;
            } else if (name === "genderF") {
              total = +list.genderAll + +list.genderT + +list.genderM;
            }

            if (name === "genderT" && e !== "") {
              genderT = genderT + +e;
              total = +e + +list.genderAll + +list.genderF + +list.genderM;
            } else if (name === "genderT") {
              total = +list.genderAll + +list.genderF + +list.genderM;
            }

            if (name === "genderM" && e !== "") {
              genderM = genderM + +e;
              total = +e + +list.genderAll + +list.genderF + +list.genderT;
            } else if (name === "genderM") {
              total = +list.genderAll + +list.genderF + +list.genderT;
            }
            if (name === "allotmentPriority" && e !== "") {
              allotmentPriority = allotmentPriority + +e;
             
            } else if (name === "allotmentPriority") {
              allotmentPriority = allotmentPriority + +list.allotmentPriority;
            }
            totalReserv = totalReserv + +total;
          
          } else {
            genderAll = genderAll + +list.genderAll;
            genderF = genderF + +list.genderF;
            genderM = genderM + +list.genderM;
            genderT = genderT + +list.genderT;
            allotmentPriority = allotmentPriority + +list.allotmentPriority;
            totalReserv = totalReserv + +list.total;
            total =
              +list.genderAll + +list.genderF + +list.genderT + +list.genderM;
          }
        } else {
          genderAll = genderAll + +list.genderAll;
            genderF = genderF + +list.genderF;
            genderM = genderM + +list.genderM;
            genderT = genderT + +list.genderT;
            allotmentPriority = allotmentPriority + +list.allotmentPriority;
            totalReserv = totalReserv + +list.total;
          total =
            +list.genderAll + +list.genderF + +list.genderT + +list.genderM;
        }

        return list.id === row.id ? { ...list, [name]: e, total: total } : list;
      })
    );
    const totalOfReserv = {
      genderAll: genderAll,
      genderF: genderF,
      genderM: genderM,
      genderT: genderT,
      allotmentPriority: allotmentPriority,
      total: totalReserv,
    };
    console.log(totalOfReserv);
    setTotal(totalOfReserv);
  };
  useEffect(() => {
    if (Object.keys(total).length === 0 && reservList.length !== 0) {
      let genderAll = 0;
      let genderF = 0;
      let genderM = 0;
      let genderT = 0;
      let allotmentPriority = 0;
      let totalReserv = 0;
      reservList.map((reservData) => {
        genderAll = genderAll + reservData.genderAll;
        genderF = genderF + reservData.genderF;
        genderM = genderM + reservData.genderM;
        genderT = genderT + reservData.genderT;
        allotmentPriority = allotmentPriority + reservData.allotmentPriority;
        totalReserv = totalReserv + reservData.total;
      });
      const totalOfReserv = {
        genderAll: genderAll,
        genderF: genderF,
        genderM: genderM,
        genderT: genderT,
        allotmentPriority: allotmentPriority,
        total: totalReserv,
      };
      console.log(totalOfReserv);
      setTotal(totalOfReserv);
    }
  });
  const saveHandler = (e) => {
    e.preventDefault();
    props.saveHandler(reservList);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    props.submitHandler(reservList);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            {
              <TableRow>
                <TableCell></TableCell>
                {props &&
                  props.gender.map((gender) => {
                    return (
                      <TableCell sx={{ width: "15%" }} align="center">
                        {gender.gender}
                      </TableCell>
                    );
                  })}
                <TableCell sx={{ width: "15%" }} align="center">
                  Total
                </TableCell>
              </TableRow>
            }
          </TableHead>
          <TableBody>
            {reservList.map((dataRow, indexRow) => (
              <TableRow key={dataRow.id}>
                <TableCell component="td" scope="row">
                  {dataRow.category.name}
                </TableCell>

                <>
                  <TableCell align="center">
                    <TextField
                      size="small"
                      color="primary"
                      value={dataRow.genderAll}
                      fullWidth={true}
                      type="number"
                      // onChange={changeHandler}
                      onChange={(e) =>
                        changeHandler(dataRow, "genderAll", e.target.value)
                      }
                    ></TextField>
                  </TableCell>

                  <TableCell align="center">
                    <TextField
                      size="small"
                      color="primary"
                      type="number"
                      value={dataRow.genderM}
                      fullWidth={true}
                      onChange={(e) =>
                        changeHandler(dataRow, "genderM", e.target.value)
                      }
                    ></TextField>
                  </TableCell>

                  <TableCell align="center">
                    <TextField
                      size="small"
                      type="number"
                      color="primary"
                      value={dataRow.genderF}
                      fullWidth={true}
                      onChange={(e) =>
                        changeHandler(dataRow, "genderF", e.target.value)
                      }
                    ></TextField>
                  </TableCell>

                  <TableCell align="center">
                    <TextField
                      size="small"
                      color="primary"
                      type="number"
                      value={dataRow.genderT}
                      fullWidth={true}
                      onChange={(e) =>
                        changeHandler(dataRow, "genderT", e.target.value)
                      }
                    ></TextField>
                  </TableCell>

                  <TableCell align="center">
                    <TextField
                      size="small"
                      color="primary"
                      type="number"
                      value={dataRow.allotmentPriority}
                      fullWidth={true}
                      onChange={(e) =>
                        changeHandler(
                          dataRow,
                          "allotmentPriority",
                          e.target.value
                        )
                      }
                    ></TextField>
                  </TableCell>
                  <TableCell align="left">{dataRow.total}</TableCell>
                </>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>Total</TableCell>
              <TableCell>
                {Object.keys(total).length !== 0 ? total.genderAll : 0}
              </TableCell>
              <TableCell>
                {Object.keys(total).length !== 0 ? total.genderM : 0}
              </TableCell>
              <TableCell>
                {Object.keys(total).length !== 0 ? total.genderF : 0}
              </TableCell>
              <TableCell>
                {Object.keys(total).length !== 0 ? total.genderT : 0}
              </TableCell>
              <TableCell>
                {Object.keys(total).length !== 0 ? total.allotmentPriority : 0}
              </TableCell>

              <TableCell>
                {Object.keys(total).length !== 0 ? total.total : 0}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <br></br>

      <Grid
        item
        sm={12}
        xs={12}
        md={12}
        lg={12}
        rowSpacing={2}
        columnSpacing={{ xs: 12, sm: 6, md: 1 }}
        container
        justifyContent="right"
      >
        <Grid
          item
          sm={12}
          xs={12}
          md={1.1}
          lg={1.1}
          container
          justifyContent="space-evenly"
        >
          <ButtonCompo
            size="medium"
            type="submit"
            variant="contained"
            name="Save"
            fullWidth={true}
            onClick={submitHandler}
          />
        </Grid>

        <Grid
          item
          sm={12}
          xs={12}
          md={1.7}
          lg={1.7}
          container
          justifyContent="space-evenly"
        >
          <ButtonCompo
            size="medium"
            type="button"
            variant="contained"
            name="Save and Next"
            onClick={saveHandler}
            fullWidth={true}
          />
        </Grid>

        <Grid
          item
          sm={12}
          xs={12}
          md={1}
          lg={1}
          container
          justifyContent="space-evenly"
        >
          <ButtonCompo
            size="medium"
            type="button"
            variant="contained"
            name="Cancel"
            onClick={props.onCancel}
            fullWidth={true}
          />
        </Grid>
      </Grid>
    </>
  );
}
