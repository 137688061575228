import { Grid, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import moment from "moment";
import * as React from "react";
import AutoComplete from "../../components/Comman/AutoComplete";

import PasswordStrengthBar from "react-password-strength-bar";
import { connect } from "react-redux";
import {
  LoadCanvasTemplate,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import {
  onlyNumber,
  validEmail,
  validPassword,
} from "../../components/Comman/Util/Validations";
import Navbar from "../../components/Navbar";
import Header from "../../components/NewHeader";
import * as myConstClass from "../../config/messageconstant";
import { resetFlag } from "./studentRegister.slice";

let docs;
class StudentRegisterForm extends React.Component {
  state = {
    id: 0,
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    genderList: [
      { id: "m", name: "Male", isActive: "Active" },
      { id: "f", name: "Female", isActive: "Active" },
      { id: "t", name: "Transgender", isActive: "Active" },
    ],
    dob: "",
    contactNo: "",
    emailId: "",
    enterPassword: "",
    confirmPassword: "",
    enterOtp: "",
    captcha: "",
    isActive: "1",
    formErrors: [],
    breadCrum: "",
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    if (event.target.value === "" || onlyNumber.test(event.target.value)) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };
  validateOtp = () => {
    const { enterOtp } = this.state;
    if (this.handleFormValidationMobile()) {
      if (this.handleFormValidationOtp()) {
        if (this.state.enterOtp !== null) {
          this.props.submitOtp(enterOtp);
        }
      }
    }
  };

  componentDidMount() {
    let date = new Date();
  }

  doSubmit = () => {
    let user_captcha = document.getElementById("captcha").value;
    if (validateCaptcha(user_captcha) === true) {
      alert("Captcha Matched");
      loadCaptchaEnginge(6);
      document.getElementById("captcha").value = "";
    } else {
      alert("Captcha Does Not Match");
      document.getElementById("captcha").value = "";
    }
  };

  handleValidation(name) {
    const { firstName, middleName, lastName, gender, dob, emailId } =
      this.state;

    let formErrors = {};
    let formIsValid = true;

    if (firstName === "") {
      formIsValid = false;
      formErrors["firstNameErr"] = myConstClass.firstNameMsg;
    }

    // if (middleName === "") {
    //   formIsValid = false;
    //   formErrors["middleNameErr"] = myConstClass.middleNameMsg;
    // }

    // if (lastName === "") {
    //   formIsValid = false;
    //   formErrors["lastNameErr"] = myConstClass.lastNameMsg;
    // }

    if (gender === "") {
      formIsValid = false;
      formErrors["genderErr"] = myConstClass.genderEmptyMsg;
    }

    if (dob === "") {
      formIsValid = false;
      formErrors["dobErr"] = myConstClass.dobEmptyMsg;
    }

    if (emailId === "") {
      formIsValid = false;
      formErrors["emailIdErr"] = myConstClass.emailIdMsg;
    }

    if (!validEmail.test(emailId)) {
      formIsValid = false;
      formErrors["invalidEmailIdErr"] = myConstClass.invalidEmailIdMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  handleFormValidation(name) {
    const {
      firstName,
      middleName,
      lastName,
      gender,
      dob,
      contactNo,
      emailId,
      enterPassword,
      confirmPassword,
      captcha,
    } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (firstName === "") {
      formIsValid = false;
      formErrors["firstNameErr"] = myConstClass.firstNameMsg;
    }

    // if (middleName === "" && middleName.trim() === "") {
    //   formIsValid = false;
    //   formErrors["middleNameErr"] = myConstClass.middleNameMsg;
    // }

    // if (lastName === "") {
    //   formIsValid = false;
    //   formErrors["lastNameErr"] = myConstClass.lastNameMsg;
    // }

    if (gender === "") {
      formIsValid = false;
      formErrors["genderErr"] = myConstClass.genderEmptyMsg;
    }

    if (dob === "") {
      formIsValid = false;
      formErrors["dobErr"] = myConstClass.dobEmptyMsg;
    }

    if (enterPassword === "") {
      formIsValid = false;
      formErrors["enterPasswordErr"] = myConstClass.enterPasswordMsg;
    }

    if (!validPassword.test(enterPassword)) {
      formIsValid = false;
      formErrors["enterPasswordErr"] = myConstClass.specialPasswordMsg;
    }

    if (confirmPassword === "") {
      formIsValid = false;
      formErrors["confirmPasswordErr"] = myConstClass.confirmPasswordMsg;
    }

    if (contactNo === "" || contactNo.length !== 10) {
      if (contactNo === "") {
        formIsValid = false;
        formErrors["contactNoErr"] = myConstClass.contactNoMsg;
      } else if (contactNo.length <= 9 || contactNo.length > 10) {
        formIsValid = false;
        formErrors["contactNumberLenErr"] = myConstClass.contactNoLengthMsg;
      }
    }

    if (emailId === "") {
      formIsValid = false;
      formErrors["emailIdErr"] = myConstClass.emailIdMsg;
    }

    if (!validEmail.test(emailId)) {
      formIsValid = false;
      formErrors["emailIdErr"] = myConstClass.invalidEmailIdMsg;
    }

    if (confirmPassword !== enterPassword) {
      formIsValid = false;
      formErrors["confirmPasswordErr"] =
        myConstClass.confirmPasswordIncorrectMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  handleFormValidationOtp(name) {
    const { enterOtp } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (enterOtp === "") {
      formIsValid = false;
      formErrors["enterOtpErr"] = myConstClass.otpEmptyMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  handleFormValidationMobile(name) {
    const { contactNo } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (contactNo === "" || contactNo.length !== 10) {
      if (contactNo === "") {
        formIsValid = false;
        formErrors["contactNoErr"] = myConstClass.contactNoMsg;
      } else if (contactNo.length <= 9 || contactNo.length > 10) {
        formIsValid = false;
        formErrors["contactNumberLenErr"] = myConstClass.contactNoLengthMsg;
      }
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  changeFromDate = (date) => {
    this.setState({
      dob: date,
    });
  };

  saveHandler = (e) => {
    e.preventDefault();
    const { firstName, middleName, lastName, gender, dob, emailId } =
      this.state;
    let dateOF = moment(dob).format("DD-MM-YYYY");

    docs = {
      //id: id === 0 ? 0 : id,
      user: {
        dateOfBirth: dateOF,
        emailId: emailId,
      },
      userRegistrationSave: {
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        gender: gender,
      },
    };
    if (this.handleValidation()) {
      this.props.saveNext(docs);
    }
  };
  generateOtp = () => {
    const { emailId, contactNo } = this.state;
    if (this.handleFormValidationMobile()) {
      if (this.state.emailId !== null) {
        this.props.saveMobile(emailId, contactNo);
      }
    }
  };

  editContactNo = () => {
    const { resetFlag } = this.props;
    resetFlag();
  };

  submitHandler = (e) => {
    e.preventDefault();
    const {
      firstName,
      middleName,
      lastName,
      gender,
      dob,
      contactNo,
      emailId,
      enterPassword,
      isActive,
    } = this.state;
    let dateOF = moment(dob).format("DD-MM-YYYY");

    docs = {
      // id: id === 0 ? 0 : id,
      userRegistrationSave: {
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        gender: gender,
      },
      user: {
        dateOfBirth: dateOF,
        emailId: emailId,
        mobileNo: contactNo,
        password: enterPassword,
        trust: {
          id: 1,
        },
        isActive: isActive,
      },
    };
    if (this.handleFormValidation()) {
      this.props.OnSave(docs);
    }
  };
  render() {
    const {
      firstName,
      middleName,
      lastName,
      gender,
      dob,
      genderList,
      contactNo,
      emailId,
      enterPassword,
      confirmPassword,
      captcha,
      enterOtp,
    } = this.state;

    const {
      firstNameErr,
      middleNameErr,
      lastNameErr,
      genderErr,
      dobErr,
      contactNoErr,
      emailIdErr,
      enterPasswordErr,
      confirmPasswordErr,
      incorrectPasswordErr,
      namePasswordErr,
      contactNumberLenErr,
      specialPasswordErr,
      invalidEmailIdErr,
      captchaErr,
      enterOtpErr,
    } = this.state.formErrors;

    const { onCancel, studentData } = this.props;

    return (
      <>
        <div align="center">
          {/* <Header/> */}
          <Header />
          <Navbar />
          <div className="new_content">
            <div className="dash_login">
              <Grid item container justifyContent="space-between">
                <Grid item md={3}></Grid>
                <Grid item md={6}>
                  <div className="lgn_bx">
                    <h3 className="lgn_title">Registration</h3>

                    <div className="lgn_container">
                      {studentData.isMailUnique &&
                      studentData.msg === "Email Id Is Unique.." ? (
                        <div className="user_lgn fmlnm">
                          <div className="user_label">*First Name :</div>
                          <div className="user_field">
                            <TextField
                              color="primary"
                              name="firstName"
                              size="small"
                              value={firstName}
                              onChange={this.changedHandler}
                              fullWidth
                              disabled
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="user_lgn fmlnm">
                          <div className="user_label">*First Name :</div>
                          <div className="user_field">
                            <TextField
                              color="primary"
                              name="firstName"
                              size="small"
                              value={firstName}
                              onChange={this.changedHandler}
                              fullWidth
                              error={firstNameErr ? true : false}
                              helperText={firstNameErr ? firstNameErr : " "}
                            />
                          </div>
                        </div>
                      )}
                      {studentData.isMailUnique &&
                      studentData.msg === "Email Id Is Unique.." ? (
                        <div className="user_lgn fmlnm">
                          <div className="user_label">*Middle Name :</div>
                          <div className="user_field">
                            <TextField
                              color="primary"
                              name="middleName"
                              size="small"
                              value={middleName}
                              onChange={this.changedHandler}
                              fullWidth
                              disabled
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="user_lgn fmlnm">
                          <div className="user_label">*Middle Name :</div>
                          <div className="user_field">
                            <TextField
                              color="primary"
                              name="middleName"
                              size="small"
                              value={middleName}
                              onChange={this.changedHandler}
                              fullWidth
                              error={middleNameErr ? true : false}
                              helperText={middleNameErr ? middleNameErr : " "}
                            />
                          </div>
                        </div>
                      )}
                      {studentData.isMailUnique &&
                      studentData.msg === "Email Id Is Unique.." ? (
                        <div className="user_lgn fmlnm">
                          <div className="user_label">*Last Name :</div>
                          <div className="user_field">
                            <TextField
                              color="primary"
                              name="lastName"
                              size="small"
                              value={lastName}
                              onChange={this.changedHandler}
                              fullWidth
                              disabled
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="user_lgn fmlnm">
                          <div className="user_label">*Last Name :</div>
                          <div className="user_field">
                            <TextField
                              color="primary"
                              name="lastName"
                              size="small"
                              value={lastName}
                              onChange={this.changedHandler}
                              fullWidth
                              error={lastNameErr ? true : false}
                              helperText={lastNameErr ? lastNameErr : " "}
                            />
                          </div>
                        </div>
                      )}
                      {studentData.isMailUnique &&
                      studentData.msg === "Email Id Is Unique.." ? (
                        <div className="user_lgn genDob">
                          <div className="user_label">*Gender :</div>
                          <div className="user_field">
                            <TextField
                              color="primary"
                              name="gender"
                              size="small"
                              value={
                                gender === "m"
                                  ? "Male"
                                  : gender === "f"
                                  ? "Female"
                                  : gender === "t"
                                  ? "Transgender"
                                  : ""
                              }
                              onChange={this.changedHandler}
                              fullWidth
                              disabled
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="user_lgn genDob">
                          <div className="user_label">*Gender :</div>
                          <div className="user_field">
                            {/* <FormControl color={"primary"} fullWidth={true}>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                className="text-black"
                                name="gender"
                                value={gender}
                                onChange={this.changedHandler}
                                size="small"
                                fullWidth={true}
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                <MenuItem value="m">Male</MenuItem>
                                <MenuItem value="f">Female</MenuItem>
                                <MenuItem value="t">Transgender</MenuItem>
                              </Select>
                              <FormHelperText error>
                                
                                {genderErr ? genderErr : " "}
                              </FormHelperText>
                            </FormControl> */}

                            <AutoComplete
                              sx={{ marginTop: -2 }}
                              keyColName={"id"}
                              value={gender}
                              name={"gender"}
                              fullWidth
                              options={genderList}
                              onChange={this.ChangeHandlerSearch}
                            />
                          </div>
                        </div>
                      )}
                      {studentData.isMailUnique &&
                      studentData.msg === "Email Id Is Unique.." ? (
                        <div className="user_lgn genDob">
                          <div className="user_label">*D.O.B :</div>
                          <div className="user_field">
                            <BasicDatePicker
                              name="dob"
                              id="dob"
                              value={dob}
                              maxDate={new Date()}
                              onChange={this.changeFromDate}
                              disabled
                              error={" "}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="user_lgn genDob">
                          <div className="user_label">*D.O.B :</div>
                          <div className="user_field">
                            <BasicDatePicker
                              name="dob"
                              id="dob"
                              value={dob}
                              maxDate={new Date()}
                              onChange={this.changeFromDate}
                              error={dobErr ? dobErr : " "}
                            />
                          </div>
                        </div>
                      )}
                      {studentData.isMailUnique &&
                      studentData.msg === "Email Id Is Unique.." ? (
                        <div className="user_lgn">
                          <div className="user_label">*Email ID :</div>
                          <div className="user_field">
                            <TextField
                              placeholder="abcd@gmail.com"
                              color="primary"
                              name="emailId"
                              size="small"
                              value={emailId}
                              onChange={this.changedHandler}
                              fullWidth
                              disabled
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="user_lgn">
                          <div className="user_label">*Email ID :</div>
                          <div className="user_field">
                            <TextField
                              placeholder="abcd@gmail.com"
                              color="primary"
                              name="emailId"
                              size="small"
                              value={emailId}
                              onChange={this.changedHandler}
                              fullWidth
                              error={emailIdErr ? true : false}
                              helperText={emailIdErr ? emailIdErr : " "}
                            />
                          </div>
                        </div>
                      )}
                      {studentData.isMailUnique &&
                      studentData.msg === "Email Id Is Unique.." ? (
                        <></>
                      ) : (
                        <div className="lgn_btn">
                          <button
                            type="button"
                            className="register_btn sendOtp"
                            onClick={this.saveHandler}
                          >
                            Submit
                          </button>

                          <button
                            type="button"
                            className="register_btn sendOtp"
                            onClick={onCancel}
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                      <br />
                      {studentData.isMailUnique &&
                        studentData.msg === "Email Id Is Unique.." &&
                        !studentData.isGetOTP &&
                        studentData.GetOTPmsg === "" && (
                          <>
                            <div className="user_lgn">
                              <div className="user_label">
                                *Contact Number :
                              </div>
                              <div className="user_field">
                                <TextField
                                  color="primary"
                                  name="contactNo"
                                  size="small"
                                  value={contactNo}
                                  inputProps={{ maxLength: 10 }}
                                  onChange={this.changeHandler}
                                  fullWidth
                                  error={
                                    contactNoErr
                                      ? true
                                      : contactNumberLenErr
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    contactNoErr
                                      ? contactNoErr
                                      : !contactNoErr
                                      ? contactNumberLenErr
                                      : " "
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}
                      {studentData.isMailUnique &&
                        studentData.msg === "Email Id Is Unique.." &&
                        studentData.isGetOTP &&
                        studentData.GetOTPmsg ===
                          "Otp has been send successfully.." && (
                          <>
                            <div className="user_lgn">
                              <div className="user_label">
                                *Contact Number :
                              </div>
                              <div className="user_field">
                                <TextField
                                  color="primary"
                                  name="contactNo"
                                  size="small"
                                  value={contactNo}
                                  inputProps={{ maxLength: 10 }}
                                  onChange={this.changeHandler}
                                  fullWidth
                                  disabled
                                  error={
                                    contactNoErr
                                      ? true
                                      : contactNumberLenErr
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    contactNoErr
                                      ? contactNoErr
                                      : !contactNoErr
                                      ? contactNumberLenErr
                                      : " "
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}
                      {studentData.isMailUnique &&
                        studentData.msg === "Email Id Is Unique.." &&
                        !studentData.isGetOTP && (
                          <>
                            <button
                              type="button"
                              className="register_btn sendOtp"
                              onClick={this.generateOtp}
                            >
                              Submit
                            </button>
                            <button
                              type="button"
                              className="register_btn sendOtp"
                              onClick={onCancel}
                            >
                              Cancel
                            </button>
                          </>
                        )}

                      {studentData.isGetOTP &&
                        studentData.GetOTPmsg ===
                          "Otp has been send successfully.." && (
                          <>
                            <div className="user_lgn">
                              <div className="user_label">*Enter OTP</div>
                              <div className="user_field">
                                <TextField
                                  color="primary"
                                  name="enterOtp"
                                  size="small"
                                  value={enterOtp}
                                  onChange={this.changeHandler}
                                  fullWidth
                                  error={enterOtpErr ? true : false}
                                  helperText={enterOtpErr ? enterOtpErr : " "}
                                  disabled={
                                    studentData.isGetOTP &&
                                    studentData.GetOTPmsg ===
                                      "Otp has been send successfully.." &&
                                    studentData.isValidateOtp &&
                                    studentData.getValidateOtpmsg ===
                                      "Otp verified.."
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}
                      {studentData.isGetOTP &&
                        studentData.GetOTPmsg ===
                          "Otp has been send successfully.." &&
                        !studentData.isValidateOtp && (
                          <>
                            <div
                              style={{
                                display: "block",
                                padding: " 0 10px 15px",
                              }}
                            >
                              <button
                                type="button"
                                className="register_btn sendOtp"
                                onClick={this.validateOtp}
                              >
                                Submit
                              </button>
                              <button
                                type="button"
                                className="register_btn sendOtp"
                                onClick={this.generateOtp}
                              >
                                Resend
                              </button>
                              <button
                                type="button"
                                className="register_btn sendOtp"
                                onClick={this.editContactNo}
                              >
                                Edit Contact no.
                              </button>
                            </div>
                          </>
                        )}
                      {studentData.isGetOTP &&
                        studentData.GetOTPmsg ===
                          "Otp has been send successfully.." &&
                        studentData.isValidateOtp &&
                        studentData.getValidateOtpmsg === "Otp verified.." && (
                          <></>
                        )}

                      {studentData.isValidateOtp &&
                        studentData.getValidateOtpmsg === "Otp verified.." && (
                          <>
                            <div className="user_lgn genDob">
                              <div className="user_label">*Enter Password</div>
                              <div className="user_field">
                                <TextField
                                  color="primary"
                                  name="enterPassword"
                                  size="small"
                                  type="password"
                                  value={enterPassword}
                                  onChange={this.changedHandler}
                                  onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                  }}
                                  onCopy={(e) => {
                                    e.preventDefault();
                                    return false;
                                  }}
                                  fullWidth
                                  error={enterPasswordErr ? true : false}
                                  helperText={
                                    enterPasswordErr ? enterPasswordErr : " "
                                  }
                                />
                                <PasswordStrengthBar password={enterPassword} />
                              </div>
                            </div>
                            <div className="user_lgn genDob">
                              <div className="user_label">
                                *Confirm Password
                              </div>
                              <div className="user_field">
                                <TextField
                                  color="primary"
                                  name="confirmPassword"
                                  size="small"
                                  type="password"
                                  value={confirmPassword}
                                  onChange={this.changedHandler}
                                  fullWidth
                                  onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                  }}
                                  onCopy={(e) => {
                                    e.preventDefault();
                                    return false;
                                  }}
                                  error={confirmPasswordErr ? true : false}
                                  helperText={
                                    confirmPasswordErr
                                      ? confirmPasswordErr
                                      : " "
                                  }
                                />
                                <PasswordStrengthBar
                                  password={confirmPassword}
                                />
                              </div>
                            </div>

                            <button
                              type="button"
                              className="register_btn sendOtp"
                              onClick={this.submitHandler}
                            >
                              Register
                            </button>

                            <button
                              type="button"
                              className="register_btn sendOtp"
                              onClick={onCancel}
                            >
                              Cancel
                            </button>
                          </>
                        )}
                    </div>
                  </div>
                </Grid>
                <Grid item md={3}></Grid>
              </Grid>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  studentData: state.studentRegister,
});

const mapDispatchToProps = {
  resetFlag,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentRegisterForm);
