import React from "react";
import { generatePDF } from "../../components/Comman/GeneratePDF";
import ExportCSV from "../../components/Comman/ExportCSV";
import { FontAwesomeIconCompo } from "../../components/Comman/IconButton";
const BranchExcelData = ({ csvData, instList, fileName, type }) => {
  const tablecol = instList.map((title) => {
    if (title.title !== "Action") {
      return title.title;
    }
  });
  let index = 0;
  const BranchData = csvData.map((csvData) => {
    index = index + 1;
    let inst = {
      "Sr. No.": index,
    };

    if (instList.length != 0) {
      instList.map((title) => {
        if (title.name == "progName") {
          inst = {
            ...inst,
            "Program Type Name": csvData.progName,
          };
        }
        if (title.name == "name") {
          inst = {
            ...inst,
            "Program Title": csvData.name,
          };
        }

        if (title.name == "courseCode") {
          inst = {
            ...inst,
            "Course Code": csvData.courseCode,
          };
        }

        if (title.name == "isActive") {
          inst = {
            ...inst,
            Status: csvData.isActive,
          };
        }
      });
    } else {
      inst = {
        ...inst,
        "Programe Name": csvData.progName,
        "Program Title": csvData.name,
        "Course Code": csvData.courseCode,
        "Active Status": csvData.isActive,
      };
    }

    return inst;
  });

  if (type === "pdf") {
    return (
      <FontAwesomeIconCompo
        color="error"
        fontSize="small"
        title="PDF"
        baseClassName="fas"
        className="fa-file-pdf"
        onClick={() =>
          generatePDF(BranchData, tablecol, "Program Title Master")
        }
      />
    );
  }

  return <ExportCSV csvData={BranchData} fileName={fileName} />;
};
export default BranchExcelData;
