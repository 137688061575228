import { Checkbox, Divider, FormGroup, Grid, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import * as myConstClass from "../../config/messageconstant";
import { getDist, getTal } from "../Student/student.slice";
import AutoComplete from "../../components/Comman/AutoComplete";
import { createFilterOptions } from "@mui/material/Autocomplete";
import {
  onlyNumber,
  validEmail,
  textOnly,
} from "../../components/Comman/Util/Validations";
import swal from "sweetalert";

let docs;
class StudAddress extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    id: 0,
    perAddress1: "",
    perAddress2: "",
    perAddress3: "",
    pin: "",
    state: "",
    district: "",
    taluka: "",
    city: "",

    curAddress1: "",
    curAddress2: "",
    curAddress3: "",
    curpin: "",
    curstate: "",
    curdistrict: "",
    curtaluka: "",
    curcity: "",
    checkForm: 0,
    flag: 0,
    formErrors: [],
  };

  changeHandler = (event) => {
    const { name, value, checked } = event.target;
    const { getdistById, gettalukaById, getDist, getTal } = this.props;

    if (name === "checkForm") {
      const {
        id,
        perAddress1,
        perAddress2,
        perAddress3,
        taluka,
        district,
        city,
        state,
        pin,
      } = this.state;

      if (checked === true) {
        this.setState({
          ...this.state,
          [name]: 1,
          id: id,
          curAddress1: perAddress1,
          curAddress2: perAddress2,
          curAddress3: perAddress3,
          curtaluka: taluka,
          curcity: city,
          curstate: state,
          curpin: pin,
          curdistrict: district,
        });
      } else {
        this.setState({
          ...this.state,
          [name]: 0,
          id: id,
          curAddress1: "",
          curAddress2: "",
          curAddress3: "",
          curtaluka: "",
          curcity: "",
          curstate: "",
          curpin: "",
          curdistrict: "",
        });
      }
    } else {
      this.setState({
        ...this.state,
        [name]: value,
      });
      // }
    }
  };

  componentDidUpdate() {}

  changeHandlerNumber = (event) => {
    const { name, value } = event.target;
    {
      if (event.target.value === "" || onlyNumber.test(event.target.value)) {
        this.setState({
          ...this.state,
          [name]: value,
        });
      }
    }
  };
  componentDidMount() {
    const { onCancel, religionMaster, categoryList, student } = this.props;

    this.setState({
      id: student.applicant.id,
    });

    if (Object.keys(student.applicant).length != 0) {
      this.setState({
        id: student.applicant.id,
        perAddress1:
          student.applicant.permanentAddressLine1 === null
            ? ""
            : student.applicant.permanentAddressLine1,
        perAddress2:
          student.applicant.permanentAddressLine2 === null
            ? ""
            : student.applicant.permanentAddressLine2,
        perAddress3:
          student.applicant.permanentAddressLine3 === null
            ? ""
            : student.applicant.permanentAddressLine3,
        pin:
          student.applicant.permanentPin === null
            ? ""
            : student.applicant.permanentPin,
        state:
          student.applicant.permanentState === null
            ? "2a81d959-4a11-45ad-a54c-001a22611fcd"
            : student.applicant.permanentState,
        district:
          student.applicant.permanentDistrict === null
            ? ""
            : student.applicant.permanentDistrict,
        taluka:
          student.applicant.permanentTaluka === null
            ? ""
            : student.applicant.permanentTaluka,
        city:
          student.applicant.permanentCity === null
            ? ""
            : student.applicant.permanentCity,
        curAddress1:
          student.applicant.temporaryAddressLine1 === null
            ? ""
            : student.applicant.temporaryAddressLine1,
        curAddress2:
          student.applicant.temporaryAddressLine2 === null
            ? ""
            : student.applicant.temporaryAddressLine2,
        curAddress3:
          student.applicant.temporaryAddressLine3 === null
            ? ""
            : student.applicant.temporaryAddressLine3,
        curpin:
          student.applicant.temporaryPin === null
            ? ""
            : student.applicant.temporaryPin,
        curstate:
          student.applicant.temporaryState === null
            ? ""
            : student.applicant.temporaryState,
        curdistrict:
          student.applicant.temporaryDistrict === null
            ? ""
            : student.applicant.temporaryDistrict,
        curtaluka:
          student.applicant.temporaryTaluka === null
            ? ""
            : student.applicant.temporaryTaluka,
        curcity:
          student.applicant.temporaryCity === null
            ? ""
            : student.applicant.temporaryCity,
      });
    }
  }

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };
  handleFormValidation() {
    const {
      perAddress1,
      curAddress1,
      taluka,
      district,
      state,
      city,
      pin,
      curpin,
      curcity,
      curstate,
      curdistrict,
      curtaluka,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;
    let hasNumber = /\d/;

    if (perAddress1 === "") {
      formIsValid = false;
      formErrors["perAddressErr"] = myConstClass.perAddressMsg;
    }

    if (city === "") {
      formIsValid = false;
      formErrors["cityErr"] = myConstClass.cityMsg;
    }

    if (curcity === "") {
      formIsValid = false;
      formErrors["curcityErr"] = myConstClass.cityMsg;
    }

    if (taluka === "" || taluka === null) {
      formIsValid = false;
      formErrors["talukaErr"] = myConstClass.talukaMsg;
    }
    if (district === "" || district === null) {
      formIsValid = false;
      formErrors["districtErr"] = myConstClass.districtMsg;
    }

    if (state === "" || state === null) {
      formIsValid = false;
      formErrors["stateErr"] = myConstClass.stateMsg;
    }

    if (curstate === "" || curstate === null) {
      formIsValid = false;
      formErrors["curstateErr"] = myConstClass.stateMsg;
    }

    if (curdistrict === "" || curdistrict === null) {
      formIsValid = false;
      formErrors["curdistrictErr"] = myConstClass.districtMsg;
    }

    if (curtaluka === "" || curtaluka === null) {
      formIsValid = false;
      formErrors["curtalukaErr"] = myConstClass.talukaMsg;
    }

    if (pin === "") {
      formIsValid = false;
      formErrors["pinErr"] = myConstClass.pinMsg;
    }

    if (curpin === "") {
      formIsValid = false;
      formErrors["currpinErr"] = myConstClass.pinMsg;
    }

    if (curAddress1 === "") {
      formIsValid = false;
      formErrors["curAddressErr"] = myConstClass.localAddMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const {
      id,
      perAddress1,
      perAddress2,
      perAddress3,
      curAddress1,
      curAddress2,
      curAddress3,
      curtaluka,
      curdistrict,
      curcity,
      curstate,
      curpin,
      taluka,
      district,
      city,
      state,
      pin,
    } = this.state;

    docs = {
      id: id === 0 ? 0 : id,
      permanentAddressLine1: perAddress1,
      permanentAddressLine2: perAddress2,
      permanentAddressLine3: perAddress3,
      permanentPin: pin,
      permanentState: state,
      permanentDistrict: district,
      permanentTaluka: taluka,
      permanentCity: city,
      temporaryAddressLine1: curAddress1,
      temporaryAddressLine2: curAddress2,
      temporaryAddressLine3: curAddress3,
      temporaryPin: curpin,
      temporaryState: curstate,
      temporaryDistrict: curdistrict,
      temporaryTaluka: curtaluka,
      temporaryCity: curcity,
      type: 2,
    };

    if (this.handleFormValidation()) {
      this.props.studAddressData(docs);
      this.setState({ flag: 1 });
    } else {
      swal("Some fields are empty!");
    }
  };
  filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.name,
  });
  render() {
    const {
      perAddress1,
      perAddress2,
      perAddress3,
      curAddress1,
      curAddress2,
      curAddress3,
      taluka,
      city,
      district,
      state,
      pin,
      curtaluka,
      curcity,
      curdistrict,
      curstate,
      curpin,
    } = this.state;
    const {
      talukaErr,
      curtalukaErr,
      districtErr,
      curdistrictErr,
      cityErr,
      stateErr,
      curstateErr,
      pinErr,
      curAddressErr,
      perAddressErr,
      currpinErr,
      curcityErr,
    } = this.state.formErrors;

    const { onCancel, student } = this.props;
    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            <Grid columnSpacing={1} container justifyContent="space-evenly">
              <Grid item xs={12} sm={12} md={12}>
                &nbsp; Permanent Address
              </Grid>
              <Grid sx={{ marginBottom: 1 }} item xs={12} sm={12} md={12}>
                <Divider textAlign="left"></Divider>
              </Grid>

              <Grid sx={{ marginTop: 1 }} item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="*Address Line : "
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  color="primary"
                  name="perAddress1"
                  size="small"
                  value={perAddress1}
                  onChange={this.changeHandler}
                  fullWidth
                  error={perAddressErr ? true : false}
                  helperText={perAddressErr ? perAddressErr : " "}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label=" Road : "
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  color="primary"
                  name="perAddress2"
                  size="small"
                  value={perAddress2}
                  onChange={this.changeHandler}
                  fullWidth
                  helperText=" "
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label=" Landmark : "
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  color="primary"
                  name="perAddress3"
                  size="small"
                  value={perAddress3}
                  onChange={this.changeHandler}
                  fullWidth
                  helperText=" "
                />
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Pincode :"
                  />
                </Grid>

                <Grid sx={{ marginTop: -1 }} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="pin"
                    size="small"
                    value={pin}
                    onChange={this.changeHandlerNumber}
                    fullWidth
                    inputProps={{ maxLength: 6 }}
                    error={pinErr ? true : false}
                    helperText={pinErr ? pinErr : " "}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*State :"
                  />
                </Grid>

                <Grid sx={{ marginTop: -2 }} item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={state}
                    name={"state"}
                    options={student?.state}
                    onChange={this.ChangeHandlerSearch}
                    filterOptions={this.filterOptions}
                  />

                  <FormHelperText error>
                    {stateErr ? stateErr : " "}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*District :"
                  />
                </Grid>

                <Grid sx={{ marginTop: -2 }} item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={district}
                    name={"district"}
                    options={student?.district.filter(
                      (district) => district.stid === state
                    )}
                    filterOptions={this.filterOptions}
                    onChange={this.ChangeHandlerSearch}
                  />

                  <FormHelperText error>
                    {districtErr ? districtErr : " "}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Taluka :"
                  />
                </Grid>

                <Grid sx={{ marginTop: -2 }} item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={taluka}
                    name={"taluka"}
                    options={student?.taluka.filter(
                      (taluka) => taluka.distid === district
                    )}
                    filterOptions={this.filterOptions}
                    onChange={this.ChangeHandlerSearch}
                  />
                  <FormHelperText error>
                    {talukaErr ? talukaErr : " "}
                  </FormHelperText>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*City/Village : "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="city"
                    size="small"
                    value={city}
                    onChange={this.changeHandler}
                    fullWidth
                    error={cityErr ? true : false}
                    helperText={cityErr ? cityErr : " "}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={4} md={6}></Grid>

              <Grid item xs={12} sm={12} md={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkForm"
                        id="checkForm"
                        value={1}
                        onChange={this.changeHandler}
                      />
                    }
                    label="Same as of Permanent Address"
                    // labelPlacement="right"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                &nbsp; Correspondence Address
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Divider textAlign="left"></Divider>
              </Grid>
              <Grid sx={{ marginTop: 2 }} item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="*Address Line : "
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  color="primary"
                  name="curAddress1"
                  size="small"
                  value={curAddress1}
                  onChange={this.changeHandler}
                  fullWidth
                  error={curAddressErr ? true : false}
                  helperText={curAddressErr ? curAddressErr : " "}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label=" Road : "
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  color="primary"
                  name="curAddress2"
                  size="small"
                  value={curAddress2}
                  onChange={this.changeHandler}
                  fullWidth
                  helper=" "
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}></Grid>

              <Grid sx={{ marginTop: 3 }} item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label=" Landmark : "
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  color="primary"
                  name="curAddress3"
                  size="small"
                  value={curAddress3}
                  onChange={this.changeHandler}
                  fullWidth
                  helper=" "
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}></Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid sx={{ marginTop: 3 }} item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Pincode :"
                  />
                </Grid>

                <Grid sx={{ marginTop: -1 }} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="curpin"
                    size="small"
                    value={curpin}
                    onChange={this.changeHandlerNumber}
                    fullWidth
                    inputProps={{ maxLength: 6 }}
                    error={currpinErr ? true : false}
                    helperText={currpinErr ? currpinErr : " "}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                sx={{ marginTop: 3 }}
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*State :"
                  />
                </Grid>

                <Grid sx={{ marginTop: -2 }} item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={curstate}
                    name={"curstate"}
                    options={student?.state}
                    filterOptions={this.filterOptions}
                    onChange={this.ChangeHandlerSearch}
                  />
                  <FormHelperText error>
                    {curstateErr ? curstateErr : " "}
                  </FormHelperText>
                </Grid>
              </Grid>
              {/* new code */}
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*District : "
                  />
                </Grid>

                <Grid sx={{ marginTop: -2 }} item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={curdistrict}
                    name={"curdistrict"}
                    options={student?.district.filter(
                      (curdistrict) => curdistrict.stid === curstate
                    )}
                    filterOptions={this.filterOptions}
                    onChange={this.ChangeHandlerSearch}
                  />
                  <FormHelperText error>
                    {curdistrictErr ? curdistrictErr : " "}
                  </FormHelperText>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Taluka :"
                  />
                </Grid>

                <Grid sx={{ marginTop: -2 }} item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={curtaluka}
                    name={"curtaluka"}
                    options={student?.taluka.filter(
                      (taluka) => taluka.distid === curdistrict
                    )}
                    filterOptions={this.filterOptions}
                    onChange={this.ChangeHandlerSearch}
                  />
                  <FormHelperText error>
                    {curtalukaErr ? curtalukaErr : " "}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="*City/Village :"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  color="primary"
                  name="curcity"
                  size="small"
                  value={curcity}
                  onChange={this.changeHandler}
                  fullWidth
                  error={curcityErr ? true : false}
                  helperText={curcityErr ? curcityErr : " "}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}></Grid>

              <Grid
                rowSpacing={2}
                // columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                columnSpacing={1}
                justifyContent="right"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1.1}
                  lg={1.1}
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Cancel"
                    onClick={onCancel}
                    fullWidth={true}
                  />
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={2.5}
                  lg={2.5}
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name="Save and Proceed"
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}

const Navigation = (props) => {
  let navigate = useNavigate();
  return <StudAddress {...props} navigate={navigate} />;
};

const mapStateToProps = (state) => ({
  student: state.student,
});

const mapDispatchToProps = {
  // getdistById,
  // gettalukaById,
  getDist,

  getTal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
