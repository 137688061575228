import { Grid, Paper } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import { getInstAndBranchByBrId } from "../BranchMaster/branch.slice";
import Matrix from "./Matrix";
import { getAlert } from "../../Alert/alert.slice";
import * as myConstClass from "../../config/messageconstant";
let matrixData = [];
class InstituteAndCourses extends React.Component {
 
  state = {
    id: 0,
    matrixName: "",
    classIntakeName: "",
    acYearName: "",
    Matrix: [],
    isActive: "Yes",
    matrixNameValid: 0,
    classIntakeNameValid: 0,
    acYearNameValid: 0,
    catNameValid: 0,
  };

  changeMatrix = (mat) => {
    matrixData = [];
    const { InstBranch } = this.props.branchList;
    mat.map((mat1, rowindex) => {
      let rowData = {};
      mat1.map((matData, col) => {
        if (matData === true) {
          rowData = {
            programTypeId: InstBranch[col].programTitle.programType.id,
            programTitleId: InstBranch[col].programTitle.id,
            tenantId: InstBranch[rowindex].tenant.id,
          };
          matrixData = [...matrixData, rowData];
        }
        return rowData;
      });
      return matrixData;
    });
  };
  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  setMatrix(data) {
    this.state.Matrix.push(data);
  }

  componentDidMount() {
    const { getInstAndBranchByBrId, appliData } = this.props;
    if (appliData.applicationData !== "" && appliData.applicationData.type >= 2) {
      this.setState({
        id: appliData.applicationData.id,
      });
      getInstAndBranchByBrId({ branchId: appliData.applicationData.programTitleIds }).then((response) => {
        if (!response) {
          this.props.getAlert({ message: myConstClass.serverMsg });
        }
      });
    }
  }

  submitHandler = (data) => {
    const { id } = this.state;
    data = {
      id: id,
      applicationProgramTitleInstitutes: data,
      type: 3,
    };
    this.props.OnSave(data);
  };
  render() {
    const { onCancel, branchList } = this.props;

    return (
      <>
          {/* <Grid
            container
            justifyContent="space-evenly"8
            direction="row"
            spacing={2}
          > */}
            {branchList.isFetchInstBranch && (
              <Matrix
                instituteProgramData={branchList.InstBranch}
                onCancel={onCancel}
                onChange={this.changeMatrix}
                onSave={this.submitHandler}
              />
            )}
          {/* </Grid> */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  branchList: state.branch,
  appliData: state.appli,
});
const mapDispatchToProps = {
  getInstAndBranchByBrId,
  getAlert
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstituteAndCourses);
