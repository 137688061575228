import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import React from "react";

export default function Highlights({
  sx,
  name,
  options,
  onChange,
  value = "",
  keyColName = "",
  filterOptions,
}) {
  return (
    <Autocomplete
      id={name}
      sx={sx}
      size="small"
      options={options.filter((row) => row.isActive === "Active")}
      getOptionLabel={(option) => option && option.name}
      renderInput={(params) => <TextField {...params} margin="normal" />}
      filterOptions={filterOptions}
      name={name}
      onChange={(event, newValue) => {
        console.log(event);
        onChange(name, newValue && newValue.id);
        // setValue(newValue);
      }}
      value={
        keyColName &&
        options.filter((item) => item[keyColName] === value).length > 0
          ? options.filter((item) => item[keyColName] === value)[0]
          : ""
      }
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.name, inputValue);
        const parts = parse(option.name, matches);

        return (
          <li {...props}>
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        );
      }}
    />
  );
}
