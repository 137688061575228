
import { Grid } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import { LabelCompo } from "../../components/Comman/Label";
import "../../CSS/View.css";
import {viewMultipleFile} from '../UploadFile/file.slice'
const Step4 = ({data ,fileData,viewMultipleFile }) => {
  const [profile, setProfile] = React.useState("");
  const [signature, setSignature] = React.useState("");

  React.useEffect(() => {
    
    let profilePic = {
      fileName: data.profilePic,
      name: "profilePic",
    };
    viewMultipleFile({
      file: profilePic,
    }).then((response)=>{
      if(response){
        setProfile(response.fileName);
      }
    });

    let sign = {
      fileName: data.signature,
      name: "signature",
    };
    viewMultipleFile({
      file: sign,
    }).then((response)=>{
      if(response){
        setSignature(response.fileName);
      }
    });

  }, []);
  return (
    
    <>
      <Grid container justifyContent="space-evenly" direction="row" spacing={2}>
        <Grid item xs={12} sm={4} md={3}>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 20 }}
            label="Photo"
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 20 }}
            label="Signature"
          />
        </Grid>

        <Grid item xs={12} sm={4} md={6}></Grid>
        <Grid item xs={12} sm={4} md={3}>
          
          <img src={profile} style={{ height: "100px", width: "100px" }} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <img src={signature} style={{ height: "60px", width: "150px" }} />
        </Grid>
        <Grid item xs={12} sm={4} md={6}></Grid>
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => ({
  fileData: state.file,
});

const mapDispatchToProps = {
  viewMultipleFile
};

export default connect(mapStateToProps, mapDispatchToProps)(Step4);
