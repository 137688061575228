import React from "react";
import ExportCSV from "../../components/Comman/ExportCSV";
import { generatePDF } from "../../components/Comman/GeneratePDF";
import { FontAwesomeIconCompo } from "../../components/Comman/IconButton";

const CategorySeatIntakeExcel = ({ csvData, instList, fileName, type }) => {
  const tablecol = instList.map((title) => {
    return title[0].title;
  });

  let index = 0;
  const schemeData = csvData.map((csvData) => {
    index = index + 1;
    let inst = {
      "Sr. No.": index,
    };

    if (instList.length !== 4) {
      instList.map((title) => {
        if (title[0].name == "instituteName") {
          inst = {
            ...inst,
            "Institute Name": csvData.instituteName,
          };
        }
        if (title[0].name == "classIntakeDetaill") {
          inst = {
            ...inst,
            "Class Intake Name": csvData.classIntakeDetaill,
          };
        }
        if (title[0].name == "reservationStructHeader") {
          inst = {
            ...inst,
            "Reservation Structure Header": csvData.reservationStructHeader,
          };
        }

        if (title[0].name == "isActive") {
          inst = {
            ...inst,
            Status: csvData.isActive,
          };
        }
      });
    } else {
      inst = {
        ...inst,
        "Institute Name": csvData.instituteName,
        "Class Intake Name": csvData.classIntakeDetaill,
        "Reservation Structure Header": csvData.reservationStructHeader,
        Status: csvData.isActive,
      };
    }

    return inst;
  });

  if (type === "pdf") {
    return (
      <FontAwesomeIconCompo
        color="error"
        fontSize="small"
        title="PDF"
        baseClassName="fas"
        className="fa-file-pdf"
        onClick={() =>
          generatePDF(schemeData, tablecol, "Category Seat Intake")
        }
      />
    );
  }

  return <ExportCSV csvData={schemeData} fileName={fileName} />;
};
export default CategorySeatIntakeExcel;
