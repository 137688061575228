import { Grid, Paper, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { getAlert } from "../../Alert/alert.slice";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicTable from "../../components/Comman/MaterialUIRTTable";
import PaymentGateway from "../../components/PaymentPayPhe";
import { DirectionSnackbar } from "../../components/Comman/SnakBarCompo";
import { decodeData } from "../../components/Comman/Util/Base64";
import { Loading1 } from "../../components/Loading1";
import SearchExternalPlacedStudList from "../../components/SearchTable";
import endpoint from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import {
  getCourseDataById,
  getDeclrationById,
  getProgramTitleByApplicationId,
  getDeclrationLiveForm,
} from "../AdminDirectAdmission/directAdmission.slice";
import { getCategoryType } from "../CategoryMaster/category.slice";
import { logOut } from "../Login/login.slice";
import { getApplicationsByPrgTypeId } from "../ProgramMaster/program.slice";
import { getProfile } from "../Student/student.slice";
import StudentPreview1 from "../StudentPreview1/index";
import { deleteFileData } from "../UploadFile/file.slice";
import {
  getApplicant,
  getApplicantDetailsById,
  getApplicantPrefById,
  getCapRoundById,
  resetDelete,
  saveApplicant,
  saveConfirmPassword,
  saveQualificationDetails,
  saveRoundPreference,
  getPaymentHashcode,
  forSaveApplicantData,
  savePaymentTransactionPojo,
  savePaymentTransaction,
} from "./studentDirectAdmission.slice";
import DocumentUpload from "./StudentDirectAdmissionDocument";
import ApplicationInstructions from "./StudentDirectAdmissionInstruction";
import QualificationDetails from "./StudentDirectAdmissionQualification";

const InstituteMaster = ({
  getProfile,
  userApplication,
  studentInfo,
  selectPreference,
  appliData,
  getDeclrationById,
  getCourseDataById,
  applicationList,
  getCapRoundById,
  getApplicationsByPrgTypeId,
  saveApplicant,
  getApplicant,
  getApplicantPrefById,
  saveRoundPreference,
  deleteFileData,
  saveConfirmPassword,
  resetDelete,
  loginUser,
  logOut,
  getAlert,
  getCategoryType,
  files,
  gotoBack,
  getPaymentHashcode,
  getProgramTitleByApplicationId,
  getDeclrationLiveForm,
  forSaveApplicantData,
  savePaymentTransactionPojo,
  savePaymentTransaction,
  instFlag,
}) => {
  const [row, setRows] = useState([]);
  const [id1, setId] = useState(4);
  const [choiceCodeData, setChoiceCodeData] = useState({});
  const [flag, setFlag] = React.useState(false);
  const [filteredList, setfilterList] = React.useState([]);
  const [trustList, setTrustList] = React.useState([]);
  const [saveAllData, setSaveAllData] = React.useState({});
  const [openDocUpload, setopenDocUpload] = React.useState(false);
  const [openQua, setOpenQua] = React.useState(false);
  const [openPerference, setOpenPerference] = React.useState(false);
  const [choiseInstitute, setChoiseInstitute] = React.useState(false);
  const [priorityProcess, setpriorityProcess] = React.useState(false);
  const [fess, setFees] = React.useState(false);
  const [chalanForm, setChalanForm] = React.useState(false);
  const [savepriorityProcess, setSavepriorityProcess] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [feePay, setFeePay] = React.useState(false);
  const [openSnak, setOpenSnak] = useState(false);
  const [openMsg, setOpenMsg] = useState(false);
  const [error, setError] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [password, setpassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const applicationformId = localStorage.getItem("applicationformId");

    console.log(applicationformId);
    if (applicationformId) {
      getDeclrationById({ appliId: applicationformId }).then((response) => {
        if (response) {
          instFlag();
          setFlag(true);
        } else {
          getAlert({ message: myConstClass.serverMsg });
        }
      });
    }
    deleteFileData();
    setLoader(true);
    getProfile().then((response) => {
      if (response) {
        setLoader(false);
      } else {
        setLoader(false);
        getAlert({ message: myConstClass.serverMsg });
      }
    });
    getDeclrationLiveForm().then((response) => {
      if (response) {
        setLoader(false);
      } else {
        setLoader(false);

        getAlert({ message: myConstClass.serverMsg });
      }
    });

    getCategoryType();
  }, []);

  useEffect(() => {
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
  });

  //526af1cf-71bf-40c5-a08a-d753ccee73e4
  //
  const getRowData = async (id) => {
    let saveData = localStorage.getItem("flagTosave");
    let userId = localStorage.getItem("userRegistrationId");
    console.log(userId);
    {
      setLoader(true);
      getDeclrationById({ appliId: id }).then((response) => {
        if (response !== "") {
          setLoader(false);
          if (saveData !== "" && saveData == id) {
            Swal.fire({
              icon: "question",
              title: "Proceed for payment",
              text: "This application already have been filled, if you apply for another application, the information from the first one will be lost.",
              showConfirmButton: true,
              confirmButtonColor: "#1f89f4",
              confirmButtonText: "Proceed",
              // allowOutsideClick: false,
            }).then((okLogin) => {
              if (okLogin.isConfirmed) {
                let ids = localStorage.getItem("programTitleIdsFor");
                let docs = {
                  programTitleId: ids,
                  amount: response.amount,
                  returnURL:
                    "https://eduerpsuccess.aaryatechindia.in/student-payment-success.php?userId=" +
                    userId,
                };
                console.log(docs);
                getPaymentHashcode({
                  paymentData: docs,
                }).then((response) => {
                  console.log(response);
                  setFeePay(true);
                });
              }
            });
          } else {
            console.log(response);
            localStorage.removeItem("flagTosave");

            getProgramTitleByApplicationId({ applicationId: id }).then(
              (response) => {
                if (response) {
                  instFlag();
                  setFlag(true);
                } else {
                  getAlert({ message: myConstClass.serverMsg });
                }
              }
            );
          }
        } else {
          setLoader(false);
          console.log(response);
          getAlert({ message: myConstClass.serverMsg });
        }
      });
    }
  };

  const getQualiDetails = (data) => {
    let QualiData = [];

    data.map((data1) => {
      let bData = {
        university: data1.university,
        yearOfPass: data1.passingYear,
        marksObtained: data1.marksObtain,
        marksOutof: data1.marksOutOf,
        percentage: data1.percentage,
        isActive: 1,
        delStatus: 1,
        qualification: {
          id: data1.id,
        },
        examSeatNo: data1.examSeat,
        certificateNo: data1.certificate,
        nameOfSchoolCollege: data1.school,
        country: data1.country,
        state: data1.board,
        board: data1.state, //board column in database is qualificationStatus => (Values are Completed & Pursuing)
      };
      QualiData.push(bData);
    });
    let data1 = {
      ...saveAllData,
      userQualificationDetailDirectAdmissions: QualiData,
    };
    console.log(data1);
    setSaveAllData(data1);
    setopenDocUpload(true);
  };

  const docData = (data) => {
    setSavepriorityProcess(true);
    let data1 = {
      ...saveAllData,
      userDocumentDetailDirectAdmissions: data,
    };
    setSaveAllData(data1);
  };
  const getUserRowData = (id) => {
    getDeclrationById({ appliId: id }).then((resp) => {
      if (!resp) {
        getAlert({ message: myConstClass.serverMsg });
      } else {
        getCourseDataById({ ids: resp?.programTitleIds }).then((response) => {
          if (!response) {
            getAlert({ message: myConstClass.serverMsg });
          } else {
            localStorage.setItem("appliId", id);
            navigate("/admin-direct-admission-view");
          }
        });
      }
    });
  };
  const ConfirmCapRoundDetails = (data) => {
    console.log(saveAllData);
    let userId = localStorage.getItem("userRegistrationId");
    swal("Do you want to confirm", {
      buttons: ["Cancel", true],
    }).then((willDelete) => {
      if (willDelete) {
        setLoader(true);
        let URL = endpoint.confirmPassword;
        const name = localStorage.getItem("token");
        const token = decodeData(name);
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
          shouldrefresh: "true",
        };
        let resp = axios
          .get(URL + "?password=" + password, { headers })
          .then((response) => {
            const { message } = response.data.data;
            if (message === "Correct Password.") {
              if (appliData.applicationData.paymentApplicable === 0) {
                setLoader(false);
                localStorage.setItem(
                  "forSaveApplicantData",
                  JSON.stringify(saveAllData)
                );

                saveApplicant({ appliData: saveAllData }).then((response) => {
                  if (response) {
                    setOpenSnak(true);
                    setError(false);
                    setOpenMsg("Application applied successfully.");
                    window.location.replace("/applicationForm");
                  }
                });
              } else if (appliData.applicationData.paymentApplicable === 1) {
                console.log(appliData.applicationData);
                localStorage.setItem(
                  "programTitleIdsFor",
                  saveAllData.programTitleId
                );
                setLoader(false);
                let docs = {
                  programTitleId: saveAllData.programTitleId,
                  amount: saveAllData.amount,
                  returnURL:
                    "https://eduerpsuccess.aaryatechindia.in/student-payment-success.php?userId=" +
                    userId,
                };
                localStorage.setItem(
                  "flagTosave",
                  appliData.applicationData.id
                );
                localStorage.setItem(
                  "forSaveApplicantData",
                  JSON.stringify(saveAllData)
                );

                getPaymentHashcode({
                  paymentData: docs,
                }).then((response) => {
                  // if (response) {
                  setOpenSnak(true);
                  setError(false);
                  setOpenMsg("Password validate successfully.");
                  setFeePay(true);
                  // }
                });
              } else {
                setLoader(false);
              }
            } else {
              setLoader(false);
              setOpenSnak(true);
              setOpenMsg("Please enter valid password.");
            }
            return message;
          });
      }
    });

    // setFeePay(true);
  };
  const getInstruct = (data) => {
    console.log(appliData.applicationData);
    let data1 = {
      seatAccepted: 0,
      status: 0,
      roundStatus: 0,
      category: {
        id: studentInfo?.applicant.studCategory,
      },
      isSeatAllocatedByInstitute: 0,
      isSeatAcceptedByStudent: 0,
      paymentApplicable: appliData.applicationData.paymentApplicable,
      paidStatus: appliData.applicationData.paymentApplicable === 1 ? 1 : 0,
      amount: appliData.applicationData.amount,
      paymentRef: 0,
      paymentMode: 0,
      minority: data.minority,
      applicableFor: data.programTitleIds,
      isActive: 0,
      delStatus: 0,
      programTitleId: data.programTitle,
      applicationDeclareDirectAdmission: {
        id: appliData.applicationData.id,
      },
      userRegistration: {
        id: studentInfo.applicant.id,
      },
    };
    setSaveAllData(data1);
    setOpenQua(true);
  };
  const inst = () => {
    // setFlag(false);
    deleteFileData();
    localStorage.removeItem("applicationformId");
    window.location.replace("/applicationForm");
  };
  const backToInstructions = () => {
    setOpenQua(false);
  };
  const goToQualiCriteria = () => {
    setopenDocUpload(false);
  };
  const cancelHandler = () => {
    setFeePay(false);
  };

  const backtoList = () => {
    setChalanForm(false);
    setFlag(false);
    setOpenQua(false);
    setopenDocUpload(false);
    setOpenPerference(false);
    setChoiseInstitute(false);
    setpriorityProcess(false);
    setSavepriorityProcess(false);
    setFeePay(false);
    setFees(false);
    let intrestIn = localStorage.getItem("intrestIn");
    getApplicationsByPrgTypeId({ intrestedIn: intrestIn }).then((response) => {
      if (!response) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
  };
  const getFilteredTableData = () => {
    return appliData?.LiveFormsList.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["applicationName"] &&
          currentRow["applicationName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["index"] &&
          currentRow["index"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["intrestedPrograms"] &&
          currentRow["intrestedPrograms"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["lastDate"] &&
          currentRow["lastDate"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  const onSearch = (value) => {
    setSearchValue(value);
  };

  const postMethode = () => {
    let docs = {
      userRegistrationId: studentInfo.applicant.id,
      firstName: studentInfo.applicant.firstName,
      lastName: studentInfo.applicant.lastName,
      email: studentInfo.applicant.studentEmail,
      mobileNo: studentInfo.applicant.studentMobile,
      txnId: userApplication.paymentHashcode.key,
      paymentReturnStatus: "",
      paymentReturnData: "",
      mihpayid: "",
      productInfo: "Application Fee",
    };

    localStorage.setItem("savePaymentTransactionPojo", JSON.stringify(docs));
    console.log(docs);
    let docsToSave = {
      userRegistrationId: studentInfo.applicant.id,
      firstName: studentInfo.applicant.firstName,
      lastName: studentInfo.applicant.lastName,
      email: studentInfo.applicant.studentEmail,
      mobileNo: studentInfo.applicant.studentMobile,
      txnId: userApplication.paymentHashcode.merchantTxnNo,
      amount: appliData.applicationData.amount,
      paymentReturnStatus: "",
      paymentReturnData: "",
      mihpayid: "",
      productInfo: "Application Fee",
    };

    savePaymentTransaction({ paymentData: docsToSave });
    // savePaymentTransactionPojo({ appliData: docs });
  };
  return (
    <>
      <Paper sx={{ borderRadius: 2 }} elevation={0}>
        <DirectionSnackbar
          open={openSnak}
          severity={error ? "error" : "success"}
          msg={openMsg}
          closeSnak={setOpenSnak}
        />

        {!selectPreference && (
          <>
            {!flag && (
              <>
                {/* <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  spacing={2}
                  columnSpacing={1}
                  container
                  sx={{ m: 0.5 }}
                  justifyContent="space-evenly"
                > */}
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  {/* <Grid item xs={12} sm={12} md={0.5}></Grid> */}
                  <Grid item xs={12} sm={9} md={9}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 20, paddingLeft: 10 }}
                      label="New Direct Admission Application Form"
                    />
                  </Grid>
                  <Grid style={{ paddingLeft: 15 }} item xs={12} sm={3} md={3}>
                    <SearchExternalPlacedStudList onSearch={onSearch} />
                  </Grid>
                </Grid>
                <br />
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: "#808080" }}
                    label="Note: The data you have filled will be lost once you log out."
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                  style={{ paddingRight: 1 }}
                >
                  <DynamicTable
                    tableHead={[
                      {
                        title: "Sr. No.",
                        name: "index",
                        positionCenter: true,
                        showInscreen: true,
                        alignCenter: "center",
                      },
                      {
                        title: "Applications",
                        name: "title",
                        positionCenter: false,
                        showInscreen: true,
                        alignCenter: "left",
                      },
                      {
                        title: "Last Date",
                        name: "lastDate",
                        positionCenter: false,
                        showInscreen: true,
                        alignCenter: "left",
                      },
                      {
                        title: "Action",
                        name: "action",
                        positionCenter: false,
                        showInscreen: true,
                        alignCenter: "right",
                      },
                    ]}
                    data={getFilteredTableData()}
                    rowView={getRowData}
                    rowViewData={getUserRowData}
                    showHeadDelete={false}
                    showHeadEdit={false}
                    showApply={true}
                    showView={true}
                    showPegination={false}
                  />
                </Grid>
                {/* </Grid> */}
              </>
            )}
            {flag &&
              !openQua &&
              !chalanForm &&
              !openDocUpload &&
              !openPerference &&
              !choiseInstitute &&
              !priorityProcess &&
              !savepriorityProcess &&
              !userApplication?.isFetchConfirm &&
              !feePay &&
              !fess && (
                <ApplicationInstructions
                  openDocUpload={getInstruct}
                  onCancel={inst}
                />
              )}
            {openQua &&
              !openDocUpload &&
              !chalanForm &&
              !openPerference &&
              !choiseInstitute &&
              !priorityProcess &&
              !savepriorityProcess &&
              !feePay &&
              !fess && (
                <QualificationDetails
                  appliId={id1}
                  getQualiDetails={getQualiDetails}
                  backToInstructions={backToInstructions}
                  saveAllData={saveAllData}
                />
              )}
            {openDocUpload &&
              !chalanForm &&
              !openPerference &&
              !choiseInstitute &&
              !priorityProcess &&
              !savepriorityProcess &&
              !userApplication?.isFetchConfirm &&
              !feePay &&
              !fess && (
                <DocumentUpload
                  saveAllData={saveAllData}
                  docData={docData}
                  onCancel={goToQualiCriteria}
                />
              )}
          </>
        )}

        {savepriorityProcess &&
          !chalanForm &&
          !userApplication?.isFetchConfirm &&
          !feePay &&
          !fess && (
            <>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={9.5}
                  lg={9.5}
                  rowSpacing={1}
                  // columnSpacing={{xs:6, sm:6, md:1}}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Enter Password :"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      size="small"
                      color="primary"
                      name="password"
                      id="password"
                      type="password"
                      onChange={(e) => {
                        setpassword(e.target.value);
                      }}
                      value={password}
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={2.5}
                  lg={2.5}
                  rowSpacing={4}
                  // columnSpacing={{xs:6, sm:6, md:1}}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label=""
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Submit"
                      fullWidth={true}
                      onClick={ConfirmCapRoundDetails}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 12, color: "#808080" }}
                    label="Note: The password should be same as your Login Password"
                  />
                </Grid>
              </Grid>
            </>
          )}

        {fess &&
          userApplication?.isFetchUserAppli &&
          selectPreference === undefined && (
            <StudentPreview1
              prefCapList={userApplication?.prefCapList}
              data={userApplication?.userApplicationData}
              flag={true}
              backtoList={backtoList}
            />
          )}
        {fess &&
          userApplication?.isFetchUserAppli &&
          selectPreference != undefined && (
            <StudentPreview1
              prefCapList={userApplication?.prefCapList}
              data={userApplication?.userApplicationData}
              backtoList={backtoList}
            />
          )}
        {feePay && (
          <PaymentGateway
            currencyCode={userApplication.paymentHashcode.currencyCode}
            amount={userApplication.paymentHashcode.amount}
            merchantId={userApplication.paymentHashcode.merchantId}
            merchantTxnNo={userApplication.paymentHashcode.merchantTxnNo}
            returnURL={userApplication.paymentHashcode.returnURL}
            txnDate={userApplication.paymentHashcode.txnDate}
            hashcode={userApplication.paymentHashcode.hash}
            aggregatorID={userApplication.paymentHashcode.aggregatorId}
            handleClose={cancelHandler}
            postMethode={postMethode}
            firstName={studentInfo.applicant.firstName}
            lastName={studentInfo.applicant.lastName}
            email={studentInfo.applicant.studentEmail}
            mobile={studentInfo.applicant.studentMobile}
          />
        )}
        {loader && <Loading1 size={50} />}
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({
  userApplication: state.studentDirectAdmission,
  studentInfo: state.student,
  appliData: state.directAdmission,
  files: state.file,
  loginUser: state.user,
  applicationList: state.program,
});

const mapDispatchToProps = {
  getApplicationsByPrgTypeId,
  getDeclrationById,
  saveQualificationDetails,
  saveRoundPreference,
  getApplicantDetailsById,
  saveConfirmPassword,
  saveApplicant,
  getCapRoundById,
  getApplicant,
  getApplicantPrefById,
  deleteFileData,
  resetDelete,
  logOut,
  getAlert,
  getCategoryType,
  getProfile,
  getCourseDataById,
  getPaymentHashcode,
  getProgramTitleByApplicationId,
  getDeclrationLiveForm,
  forSaveApplicantData,
  savePaymentTransactionPojo,
  savePaymentTransaction,
};
export default connect(mapStateToProps, mapDispatchToProps)(InstituteMaster);
