import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import endpoints from "../../config/endpoints";
const initialState = {
  userPassword: [],
  isFetch: false,
  msg: "",
  isMailUnique: false,
  GetpassOTPmsg: "",
  ispassGetOTP: false,
  getValidateOtpmsg: "",
  isValidateOtp: false,
  isFail: false,
  error: "",
  isValidateOtpFail: false,
  errorMsg: "",
  userPasswordUpdated: "",
  isFetchPasswordUpdate: false,
};
let resetPassword = endpoints.resetPassword;
let URL = endpoints.userPassword;
let passwordOtp = endpoints.passwordOtp;
const userPasswordSlice = createSlice({
  name: "userPassword",
  initialState,
  reducers: {
    userPasswordSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        userPassword: row,
        isFetch: true,
      };
    },
    passwordMailSuccess: (state = cloneDeep(initialState), action) => {
      const { message } = action.payload;
      return {
        ...state,
        msg: message,
        isMailUnique: true,
      };
    },
    passwordGenrateOTPSuccess: (state = cloneDeep(initialState), action) => {
      const { message } = action.payload;
      return {
        ...state,
        GetpassOTPmsg: message,
        ispassGetOTP: true,
        isValidateOtpFail: false,
        errorMsg: "",
      };
    },
    passwordValidateOTPSuccess: (state = cloneDeep(initialState), action) => {
      const { message } = action.payload;
      return {
        ...state,
        getValidateOtpmsg: message,
        isValidateOtp: true,
      };
    },
    passwordValidateOTPFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        errorMsg: "Invalid otp",
        isValidateOtpFail: true,
      };
    },
    passwordGenerateOTPFailure: (state = cloneDeep(initialState), action) => {
      const errorMsg = action.payload;

      return {
        ...state,
        error: errorMsg,
        isFail: true,
      };
    },

    passwordValidateOtpDetailsFail: (
      state = cloneDeep(initialState),
      action
    ) => {
      return {
        ...state,
        errorMsg: "",
        isValidateOtpFail: false,
      };
    },
    passwordGenerateOtpDetailsFail: (
      state = cloneDeep(initialState),
      action
    ) => {
      return {
        ...state,
        error: "",
        isFail: false,
      };
    },
    userPasswordFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        userPassword: [],
        isFetch: false,
      };
    },
    personRegistrationSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        userPassword: row,
        isFetch: true,
      };
    },
    personRegistrationSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        userPassword: [],
        isFetch: false,
      };
    },
    passwordUpdateSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        userPasswordUpdated: "Password Update",
        isFetchPasswordUpdate: true,
      };
    },
  },
});

export const {
  userPasswordSuccess,
  passwordGenerateOTPFailure,
  passwordMailSuccess,
  passwordGenerateOtpDetailsFail,
  passwordGenrateOTPSuccess,
  passwordValidateOTPSuccess,
  passwordValidateOTPFailure,
  userPasswordFailure,
  passwordUpdateSuccess,
  passwordValidateOtpDetailsFail,
} = userPasswordSlice.actions;

export default userPasswordSlice.reducer;

export const saveuserPassword =
  ({ userName, newPassword }) =>
  async (dispatch) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      };
      await axios
        .get(
          resetPassword + "userName=" + userName + "&newPassword=" + newPassword
        )
        .then((response) => {
          console.log("Success With saveReservCat ========>", response.data);
          const { data } = response.data;
          dispatch(passwordUpdateSuccess());
          return data;
        })
        .catch((error) => {
          console.log("ErrorsaveReservCat ========>", error.response.data);
        });
    } catch (e) {
      return console.error(e.message);
    }
  };
//
export const getGenerateOTP =
  ({ userId, dob }) =>
  async (dispatch) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      };
      const response = await axios
        .get(
          URL +
            "userName=" +
            userId +
            "&dob=" +
            dob +
            "&baseUrl=" +
            window.location.host
        )
        .then((response) => {
          console.log("Success With OTP Send ========>", response.data);
          const { message } = response.data.data;
          dispatch(passwordGenrateOTPSuccess({ message }));
          return true;
        })
        .catch((error) => {
          console.log("Error With OTP Send ========>", error.response.data);
          const errorMsg = error.response.data.errorMessage;
          dispatch(passwordGenerateOTPFailure(errorMsg));
          return false;
        });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getValidateOTP =
  ({ userName, enterOtp }) =>
  async (dispatch) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      };
      const response = await axios
        .get(passwordOtp + "userName=" + userName + "&otp=" + enterOtp)
        .then((response) => {
          console.log("Success With id validate OTP ========>", response);
          const { message } = response.data.data;
          dispatch(passwordValidateOTPSuccess({ message }));
          return true;
        })
        .catch((error) => {
          console.log("Error With Validate OTP ========>", error.response.data);
          dispatch(passwordValidateOTPFailure());
          return false;
        });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const resetError = () => async (dispatch) => {
  try {
    dispatch(passwordGenerateOtpDetailsFail());
  } catch (e) {
    return console.error(e.message);
  }
};

export const resetOtpError = () => async (dispatch) => {
  try {
    dispatch(passwordValidateOtpDetailsFail());
  } catch (e) {
    return console.error(e.message);
  }
};
