import { Card, CardContent, Grid, Paper, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import { DirectionSnackbar } from "../../components/Comman/SnakBarCompo";
// import "../../CSS/table.css";
import { logOut } from "../Login/login.slice";
import DashboardHeader from "../../components/DashboardHeader";
import Alert from "../../Alert/Alert";
import swal from "sweetalert";
import { useNavigate } from "react-router";
import * as myConstClass from "../../config/messageconstant";
import DashboardPage from "../ApplicantDashboard/DashboardPage";
import { downloadF } from "../PDF/PDFDownload";
import endpoints from "../../config/endpoints";

import {
  getseatAcceptLetter,
  resetDelete,
  getseatAcceptLetterStatus,
  getseatAcceptLetterApproved,
  getseatRejectLetter,
} from "./seatAcceptLetter.slice";
const styles = (theme) => ({
  MuiTable: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
  },
  MuiTableHeadRoot: {
    textAlign: "left",
    padding: "8px",
    border: "1px solid #ddd",
  },
});

const SeatAcceptanceLetter = ({
  getseatAcceptLetter,
  seatAcceptLetterList,
  getseatAcceptLetterApproved,
  getseatRejectLetter,
  resetDelete,
  loginUser,
  classes,
}) => {
  const navigate = useNavigate();
  const [snakMsg, setSnakMsg] = useState("");
  const [openSnak, setOpenSnak] = useState(false);
  const [seatAcceptLength, setSeatAcceptLength] = useState(false);

  useEffect(() => {
    getseatAcceptLetter().then((data1) => {
      console.log(data1);

      if (data1.length !== 0) {
        data1.map((data) => {
          if (data.acceptanceStatus == 2 && data.applicantStatus == 1) {
            setSeatAcceptLength(true);
          }
        });
      }
    });
    // getseatAcceptLetter();
    // console.log(seatAcceptLetterList.user);
    // seatAcceptLetterList.user.length !== 0 &&
    //   seatAcceptLetterList.user.map((data) => {
    //     if(data.acceptanceStatus == 2 || data.applicantStatus == 1){
    //       setSeatAcceptLength(true)
    //     }
    //   });
  }, []);

  useEffect(() => {
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
  });

  console.log(seatAcceptLetterList);
  console.log("data");

  const changeHandler = (data, e) => {
    console.log(data);
    console.log("hey");
    console.log("getseatAcceptLetter");
    swal({
      title: "Are you sure?",
      text: "Do you want to Accept?",
      icon: "warning",
      buttons: ["Cancel", true],
      dangerMode: true,
    }).then((success) => {
      if (success) {
        getseatAcceptLetterApproved({ roundId: data.id });
      }
    });
  };

  const rejectChangeHandler = (data, e) => {
    console.log(data);
    console.log("rejectChangeHandler");
    swal({
      title: "Are you sure?",
      text: "Do you want to Reject?",
      icon: "warning",
      buttons: ["Cancel", true],
      dangerMode: true,
    }).then((success) => {
      if (success) {
        getseatRejectLetter({ roundId: data.id });
      }
    });
  };

  const userSeatAllotedPdf = (userRoundAllocId, e) => {
    e.stopPropagation();
    console.log(userRoundAllocId);
    let userAppReportURL = endpoints.userSeatAllotedPdf;
    downloadF({ url: userAppReportURL + "/" + userRoundAllocId });
  };

  console.log(seatAcceptLetterList.user);
  return (
    <>
      {seatAcceptLetterList.isServerFail && (
        <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />
      )}

      <div>
        {
          <DirectionSnackbar
            open={openSnak}
            severity="success"
            msg={snakMsg}
            closeSnak={setOpenSnak}
          />
        }
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          {/* <DashboardHeader /> */}
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              container
              justifyContent="center"
            >
              <Typography>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 22 }}
                  label={
                    seatAcceptLetterList.user.length !== 0 && seatAcceptLength
                      ? " Seat Allotment Details "
                      : "No Seat Allocated "
                  }
                />
              </Typography>
            </Grid>
            {seatAcceptLetterList.user.length > 0 &&
              seatAcceptLetterList.user.map((data) => {
                if (data.acceptanceStatus == 2 || data.applicantStatus == 1) {
                  return (
                    <Grid item xs={12} sm={12} md={12}>
                      <Card variant="outlined">
                        <CardContent sx={{ color: "green" }}>
                          {console.log(seatAcceptLetterList.user)}
                          {/* { seatAcceptLetterList.isFetch === true ?
                      <SeatAcceptanceForm row={seatAcceptLetterList.user} />
                    :""
                   
                   } */}
                          <table className={classes.MuiTable}>
                            <tr>
                              <td
                                style={{ color: "#5a5a5a" }}
                                width={"25%"}
                                className={classes.MuiTableHeadRoot}
                              >
                                &nbsp; Application Name :{" "}
                              </td>
                              <td className={classes.MuiTableHeadRoot}>
                                &nbsp;&nbsp;{data.applicationName}
                              </td>
                            </tr>{" "}
                            <tr>
                              <td
                                style={{ color: "#5a5a5a" }}
                                className={classes.MuiTableHeadRoot}
                              >
                                &nbsp; Round Details :{" "}
                              </td>
                              <td className={classes.MuiTableHeadRoot}>
                                &nbsp;&nbsp;{data.roundDetails}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ color: "#5a5a5a" }}
                                className={classes.MuiTableHeadRoot}
                              >
                                &nbsp; Alloted Institute :{" "}
                              </td>
                              <td className={classes.MuiTableHeadRoot}>
                                &nbsp;&nbsp;{data.institute}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ color: "#5a5a5a" }}
                                className={classes.MuiTableHeadRoot}
                              >
                                &nbsp; Seat Allotment Quota :{" "}
                              </td>
                              <td className={classes.MuiTableHeadRoot}>
                                &nbsp;&nbsp;{data.seatAllotedQuota}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ color: "#5a5a5a" }}
                                className={classes.MuiTableHeadRoot}
                              >
                                &nbsp; Program :{" "}
                              </td>
                              <td className={classes.MuiTableHeadRoot}>
                                &nbsp;&nbsp;{data.program}
                              </td>
                            </tr>
                          </table>
                          <br></br>
                        </CardContent>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          container
                          justifyContent="center"
                        >
                          <ButtonCompo
                            size="medium"
                            type="button"
                            variant="contained"
                            name="Accept"
                            onClick={(event) => changeHandler(data, event)}
                          />
                          &nbsp;&nbsp;
                          <ButtonCompo
                            size="medium"
                            type="button"
                            variant="outlined"
                            name="Reject"
                            onClick={(event) =>
                              rejectChangeHandler(data, event)
                            }
                          />
                          &nbsp;&nbsp;
                          <ButtonCompo
                            size="medium"
                            type="button"
                            variant="contained"
                            name="Print"
                            onClick={(event) =>
                              userSeatAllotedPdf(data.id, event)
                            }
                          />
                        </Grid>
                        <br></br>
                      </Card>
                    </Grid>
                  );
                }
              })}
          </Grid>
        </Paper>
        <DashboardPage />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  // religionMaster: state.religion,
  seatAcceptLetterList: state.seatAcceptLetter,

  loginUser: state.user,
});

const mapDispatchToProps = {
  getseatAcceptLetter,
  resetDelete,
  getseatAcceptLetterStatus,
  getseatAcceptLetterApproved,
  getseatRejectLetter,
  downloadF,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SeatAcceptanceLetter));
