import React, { Component } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { connect } from "react-redux";
import * as myConstClass from "../../config/messageconstant";
import { LabelCompo } from "../../components/Comman/Label";
class Matrix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matrixData: [],
      columnList: [],
      instituteNames: [],
      columnHeaderStatus: {},
      rowHeaderStatus: {},
      matrixError: false,
    };
  }

  componentDidMount() {
    const { instituteProgramData, appliData } = this.props;
    if (appliData.appliData !== "" && appliData.appliData.type >= 3) {
      console.log(appliData.appliData.applicationProgramTitleInstitutes);
    }
    this.createMatrixData(instituteProgramData);
  }

  componentWillReceiveProps(nextProps) {
    const { instituteProgramData } = this.props;
    if (instituteProgramData !== nextProps.instituteProgramData) {
      this.createMatrixData(nextProps.instituteProgramData);
    }
  }

  createMatrixData(instituteProgramData) {
    const columnList = instituteProgramData
      ? [
          ...new Set(
            instituteProgramData.map((item) => item.programTitle.brName)
          ),
        ]
      : [];
    const instituteNames = instituteProgramData
      ? [...new Set(instituteProgramData.map((item) => item.tenant.tenantName))]
      : [];

    if (instituteProgramData) {
      let matrixData = [];
      let instData = [];
      instituteProgramData.forEach((intProgRow) => {
        let mData = matrixData.filter(
          (item) => item.instituteName === intProgRow.tenant.tenantName
        );
        const { appliData } = this.props;
        let selectBranch = [];

        if (
          appliData.applicationData !== "" &&
          appliData.applicationData.type >= 3
        ) {
          selectBranch =
            appliData.applicationData.applicationProgramTitleInstitutes.filter(
              (item) =>
                item.programTitleId === intProgRow.programTitle.id &&
                item.tenantId === intProgRow.tenant.id
            );
        }
        if (mData.length) {
          mData[0][intProgRow.programTitle.brName] =
            selectBranch.length == 0 ? false : true;
        } else {
          mData = {
            tenantId: intProgRow.tenant.id,
            instituteName: intProgRow.tenant.tenantName,
          };
          mData[intProgRow.programTitle.brName] =
            selectBranch.length == 0 ? false : true;
          matrixData.push(mData);
        }
      });
      this.setState({
        matrixData: matrixData,
        columnList: columnList,
        instituteNames: instituteNames,
      });
    }
  }

  onProgramChange = (instituteName, programName) => {
    let { matrixData } = this.state;

    let matrixDataRow = matrixData.filter(
      (item) => item.instituteName === instituteName
    );
    if (matrixDataRow.length > 0) {
      matrixDataRow[0][programName] = !matrixDataRow[0][programName];
    }
    this.setState({ matrixData: matrixData });
  };

  onColumnHeaderChange = (programName) => {
    let { matrixData, columnHeaderStatus } = this.state;
    columnHeaderStatus[programName] = !columnHeaderStatus[programName];
    matrixData.forEach((matrixDataRow) => {
      if (matrixDataRow[programName] !== undefined) {
        matrixDataRow[programName] = columnHeaderStatus[programName];
      }
    });
    this.setState({
      matrixData: matrixData,
      columnHeaderStatus: columnHeaderStatus,
    });
  };

  onRowHeaderChange = (instituteName) => {
    let { matrixData, rowHeaderStatus, columnList } = this.state;
    rowHeaderStatus[instituteName] = !rowHeaderStatus[instituteName];
    let matrixDataRow = matrixData.filter(
      (item) => item.instituteName === instituteName
    );
    if (matrixDataRow.length > 0) {
      columnList.forEach((columnName) => {
        if (matrixDataRow[0][columnName] !== undefined) {
          matrixDataRow[0][columnName] = rowHeaderStatus[instituteName];
        }
      });
    }

    this.setState({ matrixData: matrixData, rowHeaderStatus: rowHeaderStatus });
  };
  submitHandler = (e) => {
    e.preventDefault();
    let { matrixData, columnList } = this.state;
    let { instituteProgramData } = this.props;

    let programList = [];
    let dataToSave = [];
    columnList.forEach((columnRowData) => {
      const programData = instituteProgramData.filter(
        (item) => item.programTitle.brName === columnRowData
      )[0];
      programList.push({
        programTypeId: programData.programTitle.programType.id,
        programTitleId: programData.programTitle.id,
        brName: programData.programTitle.brName,
      });
    });
    matrixData.forEach((matrixDataRow) => {
      programList.forEach((programData) => {
        if (matrixDataRow[programData.brName]) {
          dataToSave.push({
            programTypeId: programData.programTypeId,
            programTitleId: programData.programTitleId,
            tenantId: matrixDataRow.tenantId,
          });
        }
      });
    });
    if (dataToSave.length != 0) {
      this.props.onSave(dataToSave);
    } else {
      this.setState({
        matrixError: true,
      });
    }
  };
  render() {
    const {
      matrixData,
      columnList,
      columnHeaderStatus,
      matrixError,
      rowHeaderStatus,
    } = this.state;
    const { onCancel } = this.props;
    return (
      <>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          m={2}
          rowSpacing={1}
          container
          justifyContent="left"
        >
          <Grid sx={{ ml: -1.5 }} item xs={12} sm={12} md={12}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 12, color: "#808080" }}
              label="Note: Select institute and program time which is configure for this application"
            />
          </Grid>

          {matrixError === true && (
            <FormHelperText error>{myConstClass.instCourseMsg}</FormHelperText>
          )}
        </Grid>
        {
          <TableContainer>
            <Table
              sx={{ minWidth: 600 }}
              aria-label="simple table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Institute</TableCell>
                  {columnList.map((columnName, index) => {
                    return (
                      <TableCell align="center">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={"Course" + index}
                                onChange={(e) =>
                                  this.onColumnHeaderChange(columnName)
                                }
                                checked={columnHeaderStatus[columnName]}
                                id={"Course" + index}
                              />
                            }
                            label={columnName}
                            labelPlacement="bottom"
                          />
                        </FormGroup>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {matrixData.map((matrixDataRow, indexRow) => {
                  return (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="td" scope="row" sx={{ width: 5 }}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={"Inst" + indexRow}
                                onChange={(e) =>
                                  this.onRowHeaderChange(
                                    matrixDataRow.instituteName
                                  )
                                }
                                id={"Inst" + indexRow}
                                checked={
                                  rowHeaderStatus[matrixDataRow.instituteName]
                                }
                                // value={instState[indexRow]}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label={matrixDataRow.instituteName}
                          />
                        </FormGroup>
                      </TableCell>
                      {columnList.map((columnName, index) => {
                        return (
                          <TableCell align="center">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name={"Course" + index}
                                    onChange={(e) =>
                                      this.onProgramChange(
                                        matrixDataRow.instituteName,
                                        columnName
                                      )
                                    }
                                    checked={matrixDataRow[columnName]}
                                    {...(matrixDataRow[columnName] ===
                                      undefined && { disabled: true })}
                                    id={"Course" + index}
                                  />
                                }
                                label={""}
                                labelPlacement="bottom"
                              />
                            </FormGroup>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        }

        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          m={2}
          rowSpacing={1}
          columnSpacing={{ xs: 12, sm: 6, md: 1 }}
          container
          justifyContent="right"
        >
          <Grid
            item
            sm={12}
            xs={12}
            md={1}
            lg={1}
            container
            justifyContent="space-evenly"
          >
            <ButtonCompo
              size="medium"
              type="button"
              variant="outlined"
              name=" Cancel"
              fullWidth={true}
              onClick={onCancel}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={2.2}
            lg={2.2}
            container
            justifyContent="space-evenly"
          >
            <ButtonCompo
              size="medium"
              type="submit"
              variant="contained"
              name="Save and Proceed"
              fullWidth={true}
              onClick={this.submitHandler}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  appliData: state.appli,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Matrix);
