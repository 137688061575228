import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { onlyNumber } from "../../components/Comman/Util/Validations";
export default ({
  data,
  appdet,
  saveAllData,
  apptodate,
  appdate,
  Rows,
  onCancle,
}) => {
  const [value, setValue] = useState([]);
  const columns = [
    {
      field: "qualification",
      headerName: "Details",
      width: 200,
      sortable: false,
    },
    {
      field: "country",
      headerName: "Country",
      width: 200,
      sortable: false,
      renderCell: (params, index1) => {
        const changeHandler = (id, e) => {
          const { value } = e.target;
          setValue((data) =>
            data?.map((list, index) =>
              list.id === id ? { ...list, country: value } : list
            )
          );
        };

        return (
          <Grid item sm={12} xs={12} md={12} lg={12} container>
            <FormControl color={"primary"} fullWidth={true}>
              <Select
                className="text-black"
                name={"country" + params.row.id}
                value={params.row.country}
                onChange={(e) => changeHandler(params.row.id, e)}
                size="small"
                fullWidth={true}
                {...(params.row.countryValid == 1
                  ? {
                      error: true,
                    }
                  : "")}
              >
                <MenuItem value="">
                  <em>NA</em>
                </MenuItem>

                <MenuItem value="0">India</MenuItem>

                <MenuItem value="1">Other</MenuItem>
              </Select>

              <FormHelperText error>
                {params.row.countryValid ? "select country" : " "}
              </FormHelperText>
            </FormControl>
          </Grid>
        );
      },
    },
    {
      field: "board",
      headerName: "Board",
      width: 200,
      sortable: false,
      renderCell: (params, index1) => {
        const changeHandler = (id, e) => {
          const { value } = e.target;
          setValue((data) =>
            data?.map((list, index) =>
              list.id === id ? { ...list, board: value } : list
            )
          );
        };

        return (
          <Grid item sm={12} xs={12} md={12} lg={12} container>
            <FormControl color={"primary"} fullWidth={true}>
              <Select
                className="text-black"
                name={"board" + params.row.id}
                value={params.row.board}
                onChange={(e) => changeHandler(params.row.id, e)}
                size="small"
                fullWidth={true}
                {...(params.row.boardValid == 1
                  ? {
                      error: true,
                    }
                  : "")}
              >
                <MenuItem value="">
                  <em>NA</em>
                </MenuItem>

                <MenuItem value="0">Maharashtra State board</MenuItem>

                <MenuItem value="1">Delhi/Center Boards</MenuItem>
              </Select>

              <FormHelperText error>
                {params.row.boardValid ? "select board" : " "}
              </FormHelperText>
            </FormControl>
          </Grid>
        );
      },
    },
    {
      field: "university",
      headerName: "University",
      width: 200,
      sortable: false,
      renderCell: (params, index1) => {
        const changeHandler = (id, e) => {
          const { value } = e.target;
          setValue((data) =>
            data?.map((list, index) =>
              list.id === id ? { ...list, university: value } : list
            )
          );
        };

        return (
          <Grid item sm={12} xs={12} md={12} lg={12} container>
            <FormControl color={"primary"} fullWidth={true}>
              <Select
                className="text-black"
                name={"university" + params.row.id}
                value={params.row.university}
                onChange={(e) => changeHandler(params.row.id, e)}
                size="small"
                fullWidth={true}
                {...(params.row.uniValid == 1
                  ? {
                      error: true,
                    }
                  : "")}
              >
                <MenuItem value="">
                  <em>NA</em>
                </MenuItem>

                <MenuItem value="0">
                  Directorate of Arts Maharashtra State
                </MenuItem>

                <MenuItem value="1">
                  Maharashtra State Board of Secondary and Higher Secondary
                  Education
                </MenuItem>

                <MenuItem value="2">
                  Maharashtra state Board of Technical Education
                </MenuItem>

                <MenuItem value="3">
                  Maharashtra state Board of Vocational Education
                  Examination(MSBVEE)
                </MenuItem>

                <MenuItem value="4">
                  Shreemati Nathibai Damodar Thackarsey Women's University
                </MenuItem>

                <MenuItem value="5">University of Mumbai</MenuItem>

                <MenuItem value="6">Other</MenuItem>
              </Select>

              <FormHelperText error>
                {params.row.uniValid ? " please enter university" : " "}
              </FormHelperText>
            </FormControl>
          </Grid>
        );
      },
    },
    {
      field: "school",
      headerName: "School/Collage (Full name)",
      width: 220,
      sortable: false,
      renderCell: (params, index1) => {
        const changeHandler = (id, e) => {
          const { value } = e.target;
          setValue((data) =>
            data?.map((list, index) =>
              list.id === id ? { ...list, school: value } : list
            )
          );
        };

        return (
          <div>
            <TextField
              color="primary"
              name={"school" + params.row.id}
              size="small"
              value={params.row.school}
              {...(params.row.schoolValid == 1
                ? { error: true, helperText: "please enter school/collage" }
                : "")}
              onChange={(e) => changeHandler(params.row.id, e)}
              fullWidth={true}
            />
          </div>
        );
      },
    },
    {
      field: "certificateNo",
      width: 200,
      headerName: "Certificate Number",
      sortable: false,
      renderCell: (params, index1) => {
        const changeHandler = (id, e) => {
          const { value } = e.target;
          setValue((data) =>
            data?.map((list, index) =>
              list.id === id ? { ...list, certificateNo: value } : list
            )
          );
        };

        return (
          <div>
            <TextField
              color="primary"
              name={"certificateNo" + params.row.id}
              size="small"
              value={params.row.certificateNo}
              {...(params.row.certificateNoValid == 1
                ? { error: true, helperText: "please enter certificate Number" }
                : "")}
              onChange={(e) => changeHandler(params.row.id, e)}
              fullWidth={true}
            />
          </div>
        );
      },
    },
    {
      field: "examSeatNo",
      headerName: "Exam Seat Number",
      width: 200,
      sortable: false,
      renderCell: (params, index1) => {
        const changeHandler = (id, e) => {
          const { value } = e.target;
          setValue((data) =>
            data?.map((list, index) =>
              list.id === id ? { ...list, examSeatNo: value } : list
            )
          );
        };

        return (
          <div>
            <TextField
              color="primary"
              name={"examSeatNo" + params.row.id}
              size="small"
              value={params.row.examSeatNo}
              {...(params.row.examSeatNoValid == 1
                ? { error: true, helperText: "please enter exam seat number" }
                : "")}
              onChange={(e) => changeHandler(params.row.id, e)}
              fullWidth={true}
            />
          </div>
        );
      },
    },
    {
      field: "passingyears",
      headerName: "Passing Years",
      width: 150,
      sortable: false,
      renderCell: (params, index1) => {
        const changeHandler = (id, e) => {
          const { value } = e.target;

          if (!onlyNumber.test(value)) {
            setValue((data) =>
              data?.map((list, index) =>
                list.id === id ? { ...list, year: value, yearValid: 2 } : list
              )
            );
          } else if (params.row.year != "") {
            if (value.length <= 4) {
              setValue((data) =>
                data?.map((list, index) =>
                  list.id === id ? { ...list, year: value } : list
                )
              );
            } else {
              setValue((data) =>
                data?.map((list, index) =>
                  list.id === id ? { ...list, yearValid: 3 } : list
                )
              );
            }
          } else {
            setValue((data) =>
              data?.map((list, index) =>
                list.id === id ? { ...list, year: value } : list
              )
            );
          }
        };

        return (
          <div>
            <TextField
              color="primary"
              name={"passingYears" + params.row.id}
              size="small"
              type="number"
              inputProps={{ maxLength: 4 }}
              value={params.row.year}
              {...(params.row.yearValid == 1
                ? { error: true, helperText: "please enter passing year" }
                : params.row.yearValid == 2
                ? { error: true, helperText: "please enter Valid passing year" }
                : params.row.yearValid == 4
                ? {
                    error: true,
                    helperText: "same year not except ",
                  }
                : "")}
              onChange={(e) => changeHandler(params.row.id, e)}
              fullWidth={true}
            />
          </div>
        );
      },
    },
    {
      field: "marksobt",
      headerName: "Marks Obtained",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const changeHandler = (id, e) => {
          const { value } = e.target;
          if (!onlyNumber.test(value)) {
            setValue((data) =>
              data?.map((list, index) =>
                list.id === id
                  ? { ...list, marksObt: value, marksObtValid: 2 }
                  : list
              )
            );
          } else {
            if (params.row.marksOutOff != "") {
              if (+params.row.marksOutOff < +value) {
                setValue((data) =>
                  data?.map((list, index) =>
                    list.id === id ? { ...list, marksObtValid: 3 } : list
                  )
                );
              } else {
                setValue((data) =>
                  data?.map((list, index) =>
                    list.id === id ? { ...list, marksObtValid: 0 } : list
                  )
                );
              }
              let per = (value / params.row.marksOutOff) * 100;
              setValue((data) =>
                data?.map((list, index) =>
                  list.id === id
                    ? { ...list, marksObt: value, percentage: per }
                    : list
                )
              );
            } else {
              setValue((data) =>
                data?.map((list, index) =>
                  list.id === id ? { ...list, marksObt: value } : list
                )
              );
            }
          }
        };

        return (
          <div>
            <TextField
              color="primary"
              name={"Marksobt" + params.row.id}
              size="small"
              value={params.row.marksObt}
              {...(params.row.marksObtValid == 1
                ? { error: true, helperText: "please enter Marks " }
                : params.row.marksObtValid == 2
                ? { error: true, helperText: "please enter Valid Marks" }
                : params.row.marksObtValid == 3
                ? {
                    error: true,
                    helperText: "please enter less than totalMarks",
                  }
                : "")}
              onChange={(e) => changeHandler(params.row.id, e)}
              fullWidth={true}
            />
          </div>
        );
      },
    },
    {
      field: "marksoutoff",
      headerName: "Marks Out Off",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const changeHandler = (id, e) => {
          const { value } = e.target;
          if (!onlyNumber.test(value)) {
            setValue((data) =>
              data?.map((list, index) =>
                list.id === id
                  ? { ...list, marksOutOff: value, marksOutOffValid: 2 }
                  : list
              )
            );
          } else {
            if (params.row.marksObt != "") {
              if (+params.row.marksObt > +value) {
                setValue((data) =>
                  data?.map((list, index) =>
                    list.id === id ? { ...list, marksOutOffValid: 3 } : list
                  )
                );
              } else {
                setValue((data) =>
                  data?.map((list, index) =>
                    list.id === id ? { ...list, marksOutOffValid: 0 } : list
                  )
                );
              }
              let per = (params.row.marksObt / value) * 100;
              setValue((data) =>
                data?.map((list, index) =>
                  list.id === id
                    ? {
                        ...list,
                        marksOutOff: value,
                        percentage: per.toFixed(2),
                      }
                    : list
                )
              );
            } else {
              setValue((data) =>
                data?.map((list, index) =>
                  list.id === id ? { ...list, marksOutOff: value } : list
                )
              );
            }
          }
        };

        return (
          <div>
            <TextField
              color="primary"
              name={"marksoutoff" + params.row.id}
              size="small"
              value={params.row.marksOutOff}
              {...(params.row.marksOutOffValid == 1
                ? { error: true, helperText: "please enter Marks " }
                : params.row.marksOutOffValid == 2
                ? { error: true, helperText: "please enter Valid Marks" }
                : params.row.marksOutOffValid == 3
                ? { error: true, helperText: "grater than Marks obtain" }
                : "")}
              onChange={(e) => changeHandler(params.row.id, e)}
              fullWidth={true}
            />
          </div>
        );
      },
    },
    {
      field: "percentage",
      headerName: "Percentage",
      sortable: false,
      width: 110,
      renderCell: (params) => {
        const changeHandler = (id, e) => {
          const { value } = e.target;

          setValue((data) =>
            data?.map((list, index) =>
              list.id === id ? { ...list, percentage: value } : list
            )
          );
        };

        return (
          <div>
            <TextField
              color="primary"
              name={"percentage" + params.row.id}
              size="small"
              value={params.row.percentage}
              onChange={(e) => changeHandler(params.row.id, e)}
              fullWidth={true}
              disabled
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (saveAllData.userQualificationDetails == undefined) {
      setValue(Rows);
    } else {
      const QualiData = Rows.map((data, index) => {
        const selRow = saveAllData.userQualificationDetails.filter((row) => {
          return row.qualification.id === data.qualificationId;
        });
        if (selRow.length !== 0) {
          let qData = {
            id: data.id,
            index: data.index,
            marksObt: selRow[0].marksObtained,
            marksObtValid: data.marksObtValid,
            marksOutOff: selRow[0].marksOutof,
            school: selRow[0].nameOfSchoolCollege,
            schoolValid: data.schoolValid,
            certificateNoValid: data.certificateNoValid,
            certificateNo: selRow[0].certificateNo,
            examSeatNoValid: data.examSeatNoValid,
            examSeatNo: selRow[0].examSeatNo,
            marksOutOffValid: data.marksOutOffValid,
            percentage: selRow[0].percentage,
            percentageValid: data.percentageValid,
            qualification: data.qualification,
            qualificationId: data.qualificationId,
            uniValid: data.uniValid,
            university: selRow[0].university,
            boardValid: data.boardValid,
            board: selRow[0].board,
            countryValid: data.countryValid,
            country: selRow[0].country,
            year: selRow[0].yearOfPass,
            yearValid: data.yearValid,
          };
          return qData;
        }
      });
      setValue(QualiData);
    }
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();

    let errorFlag = false;
    let listData = value.map((selectedRow, selIndex) => {
      const a = value.filter((item, index) => item.year === selectedRow.year);

      if (selectedRow.marksOutOff == "") {
        selectedRow = {
          ...selectedRow,
          marksOutOffValid: 1,
        };
      } else if (!onlyNumber.test(selectedRow.marksOutOff)) {
        selectedRow = {
          ...selectedRow,
          marksOutOffValid: 2,
        };
      } else if (+selectedRow.marksObt > +selectedRow.marksOutOff) {
        selectedRow = {
          ...selectedRow,
          marksOutOffValid: 3,
        };
      } else {
        selectedRow = {
          ...selectedRow,
          marksOutOffValid: 0,
        };
      }

      if (selectedRow.university == "") {
        selectedRow = {
          ...selectedRow,
          uniValid: 1,
        };
      } else {
        selectedRow = {
          ...selectedRow,
          uniValid: 0,
        };
      }
      if (selectedRow.country == "") {
        selectedRow = {
          ...selectedRow,
          countryValid: 1,
        };
      } else {
        selectedRow = {
          ...selectedRow,
          countryValid: 0,
        };
      }
      if (selectedRow.board == "") {
        selectedRow = {
          ...selectedRow,
          boardValid: 1,
        };
      } else {
        selectedRow = {
          ...selectedRow,
          boardValid: 0,
        };
      }
      if (selectedRow.school == "") {
        selectedRow = {
          ...selectedRow,
          schoolValid: 1,
        };
      } else {
        selectedRow = {
          ...selectedRow,
          schoolValid: 0,
        };
      }
      if (selectedRow.certificateNo == "") {
        selectedRow = {
          ...selectedRow,
          certificateNoValid: 1,
        };
      } else {
        selectedRow = {
          ...selectedRow,
          certificateNoValid: 0,
        };
      }
      if (selectedRow.examSeatNo == "") {
        selectedRow = {
          ...selectedRow,
          examSeatNoValid: 1,
        };
      } else {
        selectedRow = {
          ...selectedRow,
          examSeatNoValid: 0,
        };
      }
      if (selectedRow.marksObt == "") {
        selectedRow = {
          ...selectedRow,
          marksObtValid: 1,
        };
      } else if (!onlyNumber.test(selectedRow.marksObt)) {
        selectedRow = {
          ...selectedRow,
          marksObtValid: 2,
        };
      } else if (+selectedRow.marksOutOff < +selectedRow.marksObt) {
        selectedRow = {
          ...selectedRow,
          marksObtValid: 3,
        };
      } else {
        selectedRow = {
          ...selectedRow,
          marksObtValid: 0,
        };
      }
      if (selectedRow.year == "") {
        selectedRow = {
          ...selectedRow,
          yearValid: 1,
        };
      } else if (selectedRow.year.length != 4) {
        selectedRow = {
          ...selectedRow,
          yearValid: 2,
        };
      } else if (a.length > 1) {
        selectedRow = {
          ...selectedRow,
          yearValid: 4,
        };
      } else {
        selectedRow = {
          ...selectedRow,
          yearValid: 0,
        };
      }

      if (
        selectedRow.yearValid != 0 ||
        selectedRow.examSeatNoValid != 0 ||
        selectedRow.countryValid != 0 ||
        selectedRow.boardValid != 0 ||
        selectedRow.schoolValid != 0 ||
        selectedRow.certificateNoValid != 0 ||
        selectedRow.marksObtValid != 0 ||
        selectedRow.uniValid != 0 ||
        selectedRow.marksOutOffValid != 0
      ) {
        errorFlag = true;
      }
      return selectedRow;
    });
    setValue(listData);
    if (errorFlag == false) {
      data(value);
    }
  };
  return (
    <div align="center">
      
      <DataGrid
        autoHeight={true}
        rows={value}
        columns={columns}
        disableColumnMenu
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
      &nbsp;&nbsp; &nbsp;&nbsp;
      <div>
        <div align="right">
          <ButtonCompo
            size="medium"
            type="button"
            variant="outlined"
            name="Back"
            onClick={onCancle}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <ButtonCompo
            size="button"
            type="button"
            variant="contained"
            name="Save and Proceed"
            onClick={submitHandler}
          />
        </div>
      </div>
    </div>
  );
};
