import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/DynamicScreens/DynamicReportGenerate";
import { DynamicReportJson } from "../../DynamicFormsJson/DynamicReport";
import
{
  getAcademicYear,
  resetAcadamicServerFailed,
} from "../AcademicYearMaster/academicYear.slice";
import { logOut } from "../Login/login.slice";
import * as myConstClass from "../../config/messageconstant";
import Alert from "../../Alert/Alert";
import { getAlert } from "../../Alert/alert.slice";

import
{
  getProgram,
  getProgramTitleById,
  resetProgramServerFailed
} from "../ProgramMaster/program.slice";
import { getInstitute } from '../Institute/institute.slice'
import { getClassDetailsByInstituteAndProgramTitle, resetClassDetailServerFailed } from '../ClassDetail/classDetail.slice'
class DynamicReport extends React.Component
{
  constructor( props )
  {
    super( props );
    this.state = {
      dynamicMasterData: {
        typeMaster: [
          {
            id: "1",
            name: "Live Application",
            isActive: "Active",
          },
          {
            id: "0",
            name: "Closed Application",
            isActive: "Active",
          },
        ],
        programTypeMaster: this.props.programList?.program,
        programTitleMaster: this.props.programList?.programTitleData,
        classMaster: this.props.classDetList?.classDetails,
        instituteMaster: this.props.instituteList.institute,
        acadamicMaster: this.props.academicYearList.academicYear,
      },
    };
  }
  componentWillReceiveProps( nextProps )
  {
    if ( this.props.loginUser !== nextProps.loginUser )
    {
      if ( this.props.loginUser.token !== nextProps.loginUser.token )
      {
        localStorage.removeItem( "token" );
        window.location.replace( "/login" );
        this.props.logOut();
      }
    }

    if ( this.props.instituteList !== nextProps.instituteList )
    {
      if (
        this.props.instituteList.institute !==
        nextProps.instituteList.institute
      )
      {
        this.setState( {
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            instituteMaster: nextProps.instituteList.institute,
          },
        } );
      }
    }
    if ( this.props.academicYearList !== nextProps.academicYearList )
    {
      if (
        this.props.academicYearList.academicYear !==
        nextProps.academicYearList.academicYear
      )
      {
        this.setState( {
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            acadamicMaster: nextProps.academicYearList.academicYear,
          },
        } );
      }
    }
    if ( this.props.programList !== nextProps.programList )
    {
      if ( this.props.programList.program !== nextProps.programList.program )
      {
        this.setState( {
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            programTypeMaster: nextProps.programList.program,
          },
        } );
      }
      if (
        this.props.programList.programTitleData !==
        nextProps.programList.programTitleData
      )
      {
        this.setState( {
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            programTitleMaster: nextProps.programList.programTitleData,
          },
        } );
      }
    }
    if ( this.props.classDetList !== nextProps.classDetList )
    {
      if (
        this.props.classDetList.classDetails !==
        nextProps.classDetList.classDetails
      )
      {
        this.setState( {
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            classMaster: nextProps.classDetList.classDetails,
          },
        } );
      }
    }
  }
  componentDidMount()
  {
    this.props.getProgram().then( ( respo ) =>
    {
      if ( !respo )
      {
        this.props.getAlert( { message: myConstClass.serverMsg } );
      }
    } );

    this.props.getInstitute().then( ( respo ) =>
    {
      if ( !respo )
      {
        this.props.getAlert( { message: myConstClass.serverMsg } );
      }
    } );

    this.props.getAcademicYear().then( ( respo ) =>
    {
      if ( !respo )
      {
        this.props.getAlert( { message: myConstClass.serverMsg } );
      }
    } );
  }
  rowData = [
    {
      index: 1,
      reportName: "Application Summary Report",
      filter: "type",
      url: "/api/report/v1/application-summary-report/get-application-summary?type={type}",
    },
    {
      index: 2,
      reportName: "Fees Collection Summary Report",
      filter: "academicYearId,yearDetailIds",
      url: "/api/report/v1/fees-collection-summary-report/get-fees-collection-summary-report?academicYearId={academicYearId}&yearDetailIds={yearDetailIds}",
    },
    {
      index: 3,
      reportName: "Application Fees Collection Summary Report ",
      filter: "type",
      url: "/api/report/v1/application-fees-collection-summary-report/{type}/get-application-fees-collection-summary",
    },
  ];
  getListById = ( data ) =>
  {
    if ( data.programType )
    {
      this.props.getProgramTitleById( {
        programTypeId: data.programType,
      } );
    }
    if ( data.programTitle && data.institute )
    {
      this.props.getClassDetailsByInstituteAndProgramTitle( {
        instituteId: data.institute,
        programTitleId: data.programTitle,
      } );
    }
  };
  render()
  {
    const { dynamicMasterData } = this.state;
    console.log( DynamicReportJson );
    return (
      <>


        {/* {this.props.academicYearList.isServerFail && (
          <Alert msg={myConstClass.serverMsg} closeAlert={this.props.resetAcadamicServerFailed} />
        )}
        {this.props.programList.isServerFail && (
          <Alert msg={myConstClass.serverMsg} closeAlert={this.props.resetProgramServerFailed} />
        )}
        {this.props.classDetList.isServerFail && (
          <Alert msg={myConstClass.serverMsg} closeAlert={this.props.resetClassDetailServerFailed} />
        )} */}


        <DynamicMainScreen
          screenTitle={DynamicReportJson.screenTitle}
          fieldMeta={DynamicReportJson.fieldMeta}
          buttonCenter={DynamicReportJson.buttonCenter}
          dynamicMasterData={dynamicMasterData}
          getListById={this.getListById}
          searchButton={DynamicReportJson.searchButton}
          PDFButton={DynamicReportJson.PDFButton}
          EXCELButton={DynamicReportJson.EXCELButton}
          baseIdColumn={DynamicReportJson.baseIdColumn}
          apiBaseURL={DynamicReportJson.apiBaseURL}
          marginTop={DynamicReportJson.marginTop}
          openNewTab={true}
          rowData={this.rowData}
        />
      </>
    );
  }
}
const mapStateToProps = ( state ) => ( {
  classDetList: state.classDetails,
  programList: state.program,
  academicYearList: state.academicYear,
  loginUser: state.user,
  instituteList: state.institute,
} );
const mapDispatchToProps = {
  getProgram,
  resetAcadamicServerFailed,
  resetProgramServerFailed,
  getProgramTitleById,
  getAcademicYear,
  logOut,
  getInstitute,
  resetClassDetailServerFailed,
  getClassDetailsByInstituteAndProgramTitle,
  getAlert

};
export default connect( mapStateToProps, mapDispatchToProps )( DynamicReport );
