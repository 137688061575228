import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";

const styles = (theme) => ({
  MuiTable: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
  },
  MuiTableHeadRoot: {
    textAlign: "left",
    padding: "8px",
    border: "1px solid #ddd",
  },
});

const BasicTable = ({ data, onAccepted, classes }) => {
  return (
    <>
      <LabelCompo
        className="text-black"
        style={{ fontSize: 20 }}
        label="College Preferences"
      />
      <br></br>
      <div style={{ overflow: "auto" }}>
        <table className={classes.MuiTable}>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp;&nbsp;Pref. No
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp;&nbsp;Course Type
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp;&nbsp;Institute Name
            </td>
          </tr>

          {data.userRoundPrefrences.map((detail, index) => (
            <tr>
              <td className={classes.MuiTableHeadRoot}>
                &nbsp;&nbsp;{detail.prefNo}
              </td>

              <td className={classes.MuiTableHeadRoot}>
                &nbsp;&nbsp;{detail.programTitle.brName}
              </td>

              <td className={classes.MuiTableHeadRoot}>
                &nbsp;&nbsp;{detail.tenant.tenantName}
              </td>
            </tr>
          ))}
        </table>

        <br></br>
        <LabelCompo
          className="text-black"
          style={{ fontSize: 20 }}
          label="Payment Details"
        />
        <Grid>&nbsp;&nbsp;</Grid>

        <table className={classes.MuiTable}>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Fees Status
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.paidStatus === 0 ? "Paid" : "Remaining"}
            </td>
          </tr>
        </table>
      </div>
      <br></br>
    </>
  );
};
export default withStyles(styles)(BasicTable);
