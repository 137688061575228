import { Button, Grid, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as React from "react";
import * as myConstClass from "../../config/messageconstant";
import AutoComplete from "../../components/Comman/AutoComplete";

import Matrix from "./Matrix1";
import { LabelCompo } from "../../components/Comman/Label";
import { getAcademicYear } from "../AcademicYearMaster/academicYear.slice";
import { getCategory } from "../CategoryMaster/category.slice";
import { getClassIntakeDetail } from "../ClassIntakeDetail/claasIntakeDetail.slice";
import { getReservDetails } from "../ReservationStructure/reservationStructure.slice";
import {
  categorySeatIntakeDetailListReserveList,
  getCatSeatIntakeDetail,
  setReserv,
} from "../CategorySeatIntakeDetail/catSeatIntakeDetail.slice";
import { connect } from "react-redux";

let data;

class CategorySeatIntakeForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      tenantId: "",
      trustId: "",
      matrixName: "",
      sanctionIntake: "",
      classIntakeName: "",
      academicYearId: "",
      acYearName: "",
      Matrix: [],
      isActive: "1",
      formErrors: [],
      cr: "",
    };
  }
  changeHandler = (name, newValue) => {
    this.setState({
      ...this.state,
      [name]: newValue,
    });
    const { academicYearId, classDetailId } = this.state;
    const { categorySeatIntakeDetailListReserveList } = this.props;
    if (classDetailId && newValue != null) {
      categorySeatIntakeDetailListReserveList(newValue, classDetailId);
    }
  };

  changedHandler = (name, newValue) => {
    this.setState({
      ...this.state,
      [name]: newValue,
    });
    const { academicYearId, classDetailId } = this.state;
    const { categorySeatIntakeDetailListReserveList } = this.props;
    console.log(
      "categorySeatIntakeDetailListReserveList" +
        classDetailId +
        ".." +
        newValue +
        "..." +
        academicYearId
    );
    if (academicYearId && newValue != null) {
      categorySeatIntakeDetailListReserveList(academicYearId, newValue);
    }
  };

  setMatrix = (data) => {
    this.setState({
      Matrix: data,
    });
  };

  componentDidMount() {
    const {
      isEdit,
      getAcademicYear,
      getClassIntakeDetail,
      getReservDetails,
      categorySeatIntakeDetailListReserveList,
      getCatSeatIntakeDetail,
      setReserv,
    } = this.props;
    getCatSeatIntakeDetail();
    getAcademicYear();
    getClassIntakeDetail();
    getReservDetails();
    let trustId = localStorage.getItem("trustId");
    this.setState({
      trustId: trustId,
    });
    if (isEdit.length > 0) {
      setReserv({ reservData: isEdit[0].categorySeatIntakeDetailList });
      this.setState({
        id: isEdit[0].id,
        academicYearId: isEdit[0].reservationStructHeaderId,
        acYearName: isEdit[0].acYearName,
        classDetailId: isEdit[0].classIntakeDetaillId,
        classIntakeName: isEdit[0].classIntakeName,
        isActive: isEdit[0].isActive === "Active" ? 1 : 0,
      });
    }
  }

  saveHandler = (data) => {
    const {
      id,
      trustId,
      classDetailId,
      sanctionIntake,
      academicYearId,
      cr,
      Matrix,
      isActive,
    } = this.state;
    const { classIntakeDetailList, isEdit } = this.props;
    const viewRow = classIntakeDetailList?.ClassIntakeDetail.filter(
      (row) => row.id === classDetailId
    );
    let total = 0;

    let CategoryData = [];
    data.map((catData) => {
      total =
        total +
        catData.genderAll +
        catData.genderF +
        catData.genderM +
        catData.genderT;
      let cat = {
        category: {
          id: catData.category.id,
        },
        sanctionIntake: 0,
        genderAll: catData.genderAll,
        genderM: catData.genderM,
        genderF: catData.genderF,
        genderT: catData.genderT,
        allotmentPriority: catData.allotmentPriority,
        isActive: catData.isActive,
        delStatus: 1,
      };
      if (isEdit.length > 0) {
        cat = {
          ...cat,
          id: catData.id,
        };
      }
      CategoryData.push(cat);
    });
    this.setState({
      sanctionIntake: viewRow[0].sanctionIntake,
      Matrix: data,
    });

    let docs = {
      tenant: {
        id: viewRow[0].tenantId,
      },
      reservationStructure: {
        id: academicYearId,
      },
      classIntakeDetail: {
        id: classDetailId,
      },
      isActive: isActive,
      delStatus: 1,
      categorySeatIntakeDetailList: CategoryData,
    };

    if (id != 0) {
      docs = {
        ...docs,
        id: id,
      };
    }

    if (total == viewRow[0].sanctionIntake) {
      this.props.saveAndNext(docs);
      this.setState({
        id: 0,
        academicYearId: "",
        classDetailId: "",
        otherPercentage: "",
        malePercentage: "",
        femalePercentage: "",
        categorySeatIntakeDetailList: "",
        isActive: 1,
        formErrors: [],
      });
    } else {
      let formErrors = [];
      formErrors["sanctionIntakeErr"] = myConstClass.sanctionIntakeVaildMsg;
      this.setState({ formErrors: formErrors });
    }
    // }
  };

  submitHandler = (data) => {
    const {
      id,
      trustId,
      classDetailId,
      sanctionIntake,
      academicYearId,
      cr,
      Matrix,
      isActive,
    } = this.state;
    if (
      classDetailId !== "" &&
      academicYearId !== "" &&
      classDetailId !== null &&
      academicYearId !== null
    ) {
      const { classIntakeDetailList, isEdit } = this.props;
      const viewRow = classIntakeDetailList?.ClassIntakeDetail.filter(
        (row) => row.id === classDetailId
      );
      if (viewRow.length !== 0) {
        let total = 0;

        let CategoryData = [];
        data.map((catData) => {
          total =
            total +
            catData.genderAll +
            catData.genderF +
            catData.genderM +
            catData.genderT;
          let cat = {
            category: {
              id: catData.category.id,
            },
            sanctionIntake: 0,
            genderAll: catData.genderAll,
            genderM: catData.genderM,
            genderF: catData.genderF,
            genderT: catData.genderT,
            allotmentPriority: catData.allotmentPriority,
            isActive: catData.isActive,
            delStatus: 1,
          };
          if (isEdit.length > 0) {
            cat = {
              ...cat,
              id: catData.id,
            };
          }
          CategoryData.push(cat);
        });
        this.setState({
          sanctionIntake: viewRow.length !== 0 ? viewRow[0].sanctionIntake : "",
          Matrix: data,
        });

        let docs = {
          tenant: {
            id: viewRow.length !== 0 ? viewRow[0].tenantId : "",
          },
          reservationStructure: {
            id: academicYearId,
          },
          classIntakeDetail: {
            id: classDetailId,
          },
          isActive: isActive,
          delStatus: 1,
          categorySeatIntakeDetailList: CategoryData,
        };

        if (id != 0) {
          docs = {
            ...docs,
            id: id,
          };
        }
        if (viewRow.length !== 0 && total == viewRow[0].sanctionIntake) {
          this.props.OnSave(docs);
        } else {
          let formErrors = [];
          formErrors["sanctionIntakeErr"] = myConstClass.sanctionIntakeVaildMsg;
          this.setState({ formErrors: formErrors });
        }
      }
    } else {
      let formErrors = [];
      if (classDetailId == "" || classDetailId == null) {
        formErrors["classIntakeNameErr"] = myConstClass.classIntakeName;
      }
      if (academicYearId == "" || academicYearId == null) {
        formErrors["reservStuctureErr"] = myConstClass.reservStuctureMsg;
      }
      this.setState({ formErrors: formErrors });
    }
  };

  render() {
    const { sanctionIntake, academicYearId, classDetailId } = this.state;
    const { reservStuctureErr, classIntakeNameErr, sanctionIntakeErr } =
      this.state.formErrors;
    const {
      onCancel,
      academicYearList,
      classIntakeDetailList,
      ReservationStructure,
      categoryList,
      catSeatIntakeDetailList,
      gender,
      onClear,
    } = this.props;
    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            <Grid
              item
              xs={12}
              md={12}
              size="small"
              container
              justifyContent="flex-end"
            >
              <Button type="button" variant="outlined" onClick={onCancel}>
                Back to List
              </Button>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Select Reservation Structure :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} mt={-2}>
                  <AutoComplete
                    keyColName={"id"}
                    value={academicYearId}
                    name={"academicYearId"}
                    options={ReservationStructure?.reservCat}
                    onChange={this.changeHandler}
                  />
                  {reservStuctureErr && (
                    <FormHelperText error>{reservStuctureErr}</FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Select Year Name :"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} mt={-2}>
                  <AutoComplete
                    keyColName={"id"}
                    value={classDetailId}
                    name={"classDetailId"}
                    options={classIntakeDetailList?.ClassIntakeDetail}
                    onChange={this.changedHandler}
                  />
                  {classIntakeNameErr && (
                    <FormHelperText error>{classIntakeNameErr}</FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={6}></Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid item xs={12} sm={6} md={1}></Grid>
              <Grid item xs={12} sm={6} md={12}>
                {/* <Matrix cat={categoryList} gender={this.props.gender} value={this.state.Matrix.value} onChange={this.setMatrix} /> */}
                {/* <Matrix cat={categoryList?.category} gender={this.props.gender} onChange={this.setMatrix} /> */}

                {/*     <Matrix cat={catSeatIntakeDetailList?.categorySeatIntakeDetailList[0]} gender={this.props.gender} onChange={this.setMatrix} /> */}
                {catSeatIntakeDetailList.ReserveList.length != 0 && (
                  <>
                    {sanctionIntakeErr && (
                      <FormHelperText error>
                        {sanctionIntakeErr + "  " + sanctionIntake}
                      </FormHelperText>
                    )}
                    <br></br>
                    {/* <LabelCompo
                      className="text-black"
                      style={{
                        fontSize: 12,
                        color: "#808080",
                        float: "left",
                        display: "block",
                      }}
                      label="  Note: Total should be equal to sanction intake in selected Year."
                    />
                    <br></br> */}

                    <Matrix
                      cat={catSeatIntakeDetailList.ReserveList}
                      gender={this.props.gender}
                      onChange={this.setMatrix}
                      saveHandler={this.saveHandler}
                      submitHandler={this.submitHandler}
                      onCancel={onCancel}
                    />
                  </>
                )}
              </Grid>{" "}
              <Grid item xs={12} sm={6} md={1}></Grid>
              <br></br>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  academicYearList: state.academicYear,
  classIntakeDetailList: state.ClassIntakeDetail,
  ReservationStructure: state.reservCat,
  categoryList: state.category,
  catSeatIntakeDetailList: state.catSeatIntakeDetail,

  common: state.common,
});

const mapDispatchToProps = {
  getAcademicYear,
  getClassIntakeDetail,
  getReservDetails,
  //getCategory,
  categorySeatIntakeDetailListReserveList,
  getCatSeatIntakeDetail,
  setReserv,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategorySeatIntakeForm);
