import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid, Paper } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Box } from "@mui/system";
import { logOut } from "../Login/login.slice";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Alert from "../../Alert/Alert";
import swal from "sweetalert";
import { useNavigate } from "react-router";
import {
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "../../components/Comman/IconButton";
import { DirectionSnackbar } from "../../components/Comman/SnakBarCompo";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
import { Loading1 } from "../../components/Loading1";
import Search from "../../components/Search";
import * as myConstClass from "../../config/messageconstant";
import { getCategoryFilterData } from "../CategoryMaster/category.slice";
import { getAlert } from "../../Alert/alert.slice";

import {
  deleteReservCatById,
  filterReservCatData,
  getReservDetails,
  resetSave,
  saveReservCat,
  resetDelete,
} from "./reservationStructure.slice";
import ReservationStructureExcel from "./ReservationStructureExcel";
import ReservationStructureForm from "./ReservationStructureForm";
import ReservationStructureMainTable from "./ReservationStructureMainTable";
import ViewInst from "./ReservationStructureView";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const breakpoint = 600;

const names = [
  { title: "Matrix Name", name: "matrixName" },
  { title: "General Percentage", name: "generalPercentage" },
  { title: "Other Percentage", name: "otherPercentage" },
  { title: "Male Percentage", name: "malePercentage" },
  { title: "Female Percentage", name: "femalePercentage" },
  { title: "Status", name: "isActive" },
];

const ReservationCategoryDetail = ({
  getReservDetails,
  getCategoryFilterData,
  reservCat,
  resetSave,
  saveReservCat,
  filterReservCatData,
  deleteReservCatById,
  resetDelete,
  loginUser,
  getAlert,
  logOut,
}) => {
  const [isEdit, setEdit] = useState(false);
  const [status, setStatus] = useState(false);
  const [del, setDel] = useState(false);
  const [save, setSave] = useState(false);
  const [rows, setRows] = React.useState([]);
  const [flag, setFlag] = useState(false);
  const [snakMsg, setSnakMsg] = useState("");
  const [editRow, setEditRow] = useState([]);
  const [viewRow, setViewRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [filteropen, setFilterOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [openSnak, setOpenSnak] = useState(false);
  const navigate = useNavigate();
  const [filteredList, setfilterList] = React.useState([
    "Reservation Name",
    "General Percentage",
    "Other Percentage",
    "Male Percentage",
    "Female Percentage",
    "Status",
  ]);
  const [resStructList, setResStructList] = React.useState([
    [{ title: "Sr. No.", name: "index" }],
    [{ title: "Reservation Name", name: "matrixName" }],
    [{ title: "General Percentage", name: "generalPercentage" }],
    [{ title: "Other Percentage", name: "otherPercentage" }],
    [{ title: "Male Percentage", name: "malePercentage" }],
    [{ title: "Female Percentage", name: "femalePercentage" }],
    [{ title: "Status", name: "isActive" }],
  ]);

  const [breadCrumName, setBreadCrum] = useState(
    "   >   Master   >   Reservation Structure "
  );
  const [width, setWidth] = React.useState(window.innerWidth);
  const rowStatus = async (id) => {
    swal(myConstClass.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        const viewRow = reservCat?.reservCat.filter((row) => row.id === id);
        let trustId = localStorage.getItem("trustId");

        let reservCatData = {
          id: viewRow[0].id,
          name: viewRow[0].name,
          generalPer: viewRow[0].generalPer,
          otherPer: viewRow[0].otherPer,
          malePer: viewRow[0].malePer,
          famalePer: viewRow[0].famalePer,

          trust: {
            id: trustId,
          },
          isActive: viewRow[0].isActive === "Active" ? "0" : "1",
        };

        resetSave();
        setStatus(true);
        saveReservCat({ reservCat: reservCatData }).then((respo) => {
          if (!respo) {
            getAlert({ message: myConstClass.serverMsg });
          } else {
            setOpenSnak(true);
            setSnakMsg(myConstClass.activeStatus);
            getReservDetails().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
          }
        });
      }
    });
  };
  const getRowID = async (id) => {
    setViewRow([]);
    setRows([]);
    const editedRow = reservCat?.reservCat.filter((row) => row.id === id);
    let index = 0;
    editedRow[0].reservationStructureDetail.map((data) => {
      index = index + 1;
      let catData = {
        index: index,
        reservId: data.id,
        id: data.category.id,
        name: data.category.name,
        percentage: data.per,
      };
      setRows((prevDocs) => {
        return [...prevDocs, catData];
      });
    });
    if (editedRow.length > 0) {
      setEdit(true);
      return await setEditRow(editedRow);
    }

    return setEditRow([]);
  };
  const getRowData = async (id) => {
    setOpen(true);
    const viewRow = reservCat?.reservCat.filter((row) => row.id === id);

    if (viewRow.length > 0) {
      return await setViewRow(viewRow);
    }
  };

  const data = (instData) => {
    resetSave();
    setSave(true);
    saveReservCat({ reservCat: instData }).then((respo) => {
      if (!respo) {
        getAlert({ message: myConstClass.serverMsg });
      } else {
        setOpenSnak(true);
        if (instData.id != null) {
          setSnakMsg(myConstClass.updateMsg);
        } else {
          setSnakMsg(myConstClass.saveMsg);
        }
        getReservDetails().then((respo) => {
          if (!respo) {
            getAlert({ message: myConstClass.serverMsg });
          }
        });
        setSave(false);
      }
    });

    setEdit(false);
    setEditRow([]);
  };
  const saveAndNextData = (data) => {
    saveReservCat({ reservCat: data }).then((respo) => {
      if (!respo) {
        getAlert({ message: myConstClass.serverMsg });
      } else {
        setOpenSnak(true);
        if (data.id != null) {
          setSnakMsg(myConstClass.updateMsg);
        } else {
          setSnakMsg(myConstClass.saveMsg);
        }
        getReservDetails().then((respo) => {
          if (!respo) {
            getAlert({ message: myConstClass.serverMsg });
          }
        });
      }
    });

    setEdit(true);
    setRows([]);
    setEditRow([]);
  };

  useEffect(() => {
    getCategoryFilterData().then((respo) => {
      if (!respo) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
    getReservDetails().then((respo) => {
      if (!respo) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
  });

  const startEdit = () => {
    setEdit(true);
  };

  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setDel(true);
        deleteReservCatById({ reservCatId: id }).then((respo) => {
          if (!respo) {
            getAlert({ message: myConstClass.serverMsg });
          } else {
            setOpenSnak(true);
            setSnakMsg(myConstClass.deleteMsg);
            setDel(false);
            getReservDetails().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
          }
        });
      }
    });
  };

  const reservCatFilteredData = reservCat?.reservCat.filter(
    (item) =>
      item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.generalPer === parseInt(searchValue) ||
      item.otherPer === parseInt(searchValue) ||
      item.malePer === parseInt(searchValue) ||
      item.famalePer === parseInt(searchValue) ||
      item.isActive.toLowerCase() === searchValue
  );

  const filteredRows = (searchvalue) => {
    setSearchValue(searchvalue);
    const filteredRow = reservCat?.reservCat.filter((row) => {
      let name = row?.name;
      let generalPer = row?.generalPer;
      let otherPer = row?.otherPer;
      let malePer = row?.malePer;
      let famalePer = row?.famalePer;
      let isActive = row?.isActive;
      return (
        name.toLowerCase().includes(searchvalue.toLowerCase()) ||
        generalPer === parseInt(searchvalue) ||
        otherPer === parseInt(searchvalue) ||
        malePer === parseInt(searchvalue) ||
        famalePer === parseInt(searchvalue) ||
        isActive.toLowerCase() === searchvalue
      );
    });

    if (reservCat?.reservCat.length === filteredRows.length) {
      filterReservCatData({ row: filteredRow, flag: false });
    } else {
      filterReservCatData({ row: filteredRow, flag: true });
    }
  };

  const stopEdit = () => {
    setBreadCrum("   >   Master   >   Reservation Structure  ");
    setEditRow([]);
    setRows([]);
    setEdit(false);
  };

  const closeFilter = () => {
    setResStructList([
      [{ title: "Sr. No.", name: "index" }],
      [{ title: "Reservation Name", name: "matrixName" }],
      [{ title: "General Percentage", name: "generalPercentage" }],
      [{ title: "Other Percentage", name: "otherPercentage" }],
      [{ title: "Male Percentage", name: "malePercentage" }],
      [{ title: "Female Percentage", name: "femalePercentage" }],
      [{ title: "Status", name: "isActive" }],
    ]);
    setfilterList([
      "Matrix Name",
      "General Percentage",
      "Other Percentage",
      "Male Percentage",
      "Female Percentage",
      "Status",
    ]);
    setFilterOpen(false);
  };
  const handleChange = (event) => {
    setFilterOpen(true);
    const {
      target: { value },
    } = event;

    if (value.length > filteredList.length) {
    } else {
      let difference = filteredList.filter((x) => !value.includes(x));
      const reservStructList = resStructList.filter((data) => {
        return data[0].title !== difference[0];
      });
      setResStructList(reservStructList);
    }

    setfilterList(typeof value === "string" ? value.split(",") : value);

    if (value.length === 0) {
      setFilterOpen(false);
    }
  };

  return (
    <>
      <div>
        <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
          <TitleAndBreadCrum
            title="Reservation Structure"
            breadCrumName={breadCrumName}
          />
        </Paper>
        <br />
        {/* {reservCat.isDelete && reservCat.error == true && (
          <Alert msg={myConstClass.deleteFailMsg} closeAlert={resetDelete} />
        )}
        {reservCat.isServerFail && <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />} */}

        {/* < ErrorHand error={"error"} msg={'This is an error alert — check it out!'} flag={true}/>  */}

        {reservCat.isDelete && reservCat.error == false ? (
          <DirectionSnackbar
            open={openSnak}
            severity="success"
            msg={myConstClass.deleteMsg}
            closeSnak={setOpenSnak}
          />
        ) : reservCat.isSave ? (
          <DirectionSnackbar
            open={openSnak}
            severity="success"
            msg={snakMsg}
            closeSnak={setOpenSnak}
          />
        ) : (
          ""
        )}
        <Paper sx={{ p: 2, pt: 4, borderRadius: 2 }} elevation={0}>
          <div>
            {viewRow.length >= 0 && editRow.length >= 0 && isEdit && (
              <ReservationStructureForm
                OnSave={data}
                onCancel={stopEdit}
                isEdit={editRow}
                rows={rows}
                saveAndNext={saveAndNextData}
              />
            )}
          </div>
          {open && (
            <ViewInst data={viewRow} openparam={open} closeView={setOpen} />
          )}
          <Grid>
            {!isEdit && (
              <div>
                {width > breakpoint ? (
                  <>
                    <Box
                      sx={{ mr: 2 }}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Box sx={{ m: -1, width: 300 }}>
                        <Search
                          filteredRows={filteredRows}
                          searchValue={searchValue}
                        />
                      </Box>
                      <Box>
                        {/* <FormControl sx={{ m: -1, width: 250 }}>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={filteredList}
                        name="sdf"
                        size="small"
                        onChange={handleChange}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => {
                          return (
                            <MenuItem key={name.name} value={name.title}>
                              <Checkbox
                                checked={filteredList.indexOf(name.title) > -1}
                              />
                              <ListItemText primary={name.title} />
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl> */}
                        {filteropen && (
                          <>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <ReservationStructureExcel
                              csvData={reservCatFilteredData}
                              reservStructList={resStructList}
                              fileName="Reservation Structure"
                              type="pdf"
                            />
                          </>
                        )}

                        {filteropen && (
                          <>
                            &nbsp;&nbsp;
                            <ReservationStructureExcel
                              csvData={reservCatFilteredData}
                              reservStructList={resStructList}
                              fileName="Reservation Structure"
                              type="excel"
                            />
                          </>
                        )}

                        {filteropen && (
                          <>
                            &nbsp;&nbsp;
                            <FontAwesomeIconCompo
                              className="fa-times-circle"
                              color="primary"
                              title="Cancel"
                              baseClassName="fas"
                              onClick={closeFilter}
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <ReservationStructureExcel
                              csvData={reservCatFilteredData}
                              reservStructList={resStructList}
                              fileName="Reservation Structure"
                              type="pdf"
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;
                            <ReservationStructureExcel
                              csvData={reservCatFilteredData}
                              reservStructList={resStructList}
                              fileName="Reservation Structure"
                              type="excel"
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;&nbsp;
                            <MyComponentWithIconProps
                              statusImage={AddCircleIcon}
                              color="primary"
                              title=""
                              fontSize="large"
                              sx={{ m: -1 }}
                              onClick={startEdit}
                            />
                          </>
                        )}
                      </Box>
                    </Box>

                    <Box sx={{ mt: 2, p: 1.5 }}>
                      <ReservationStructureMainTable
                        filteredColumns={resStructList}
                        data={reservCatFilteredData}
                        rowEdit={getRowID}
                        rowView={getRowData}
                        rowDelete={deleteData}
                        rowStatus={rowStatus}
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      rowSpacing={2}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid xs={12} sm={12} md={12} lg={12} rowSpacing={2}>
                        <Search
                          filteredRows={filteredRows}
                          searchValue={searchValue}
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{ m: 1, textAlign: "right" }}
                      >
                        {/* <FormControl sx={{ m: -1, width: 250 }}>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={filteredList}
                        name="sdf"
                        size="small"
                        onChange={handleChange}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => {
                          return (
                            <MenuItem key={name.name} value={name.title}>
                              <Checkbox
                                checked={filteredList.indexOf(name.title) > -1}
                              />
                              <ListItemText primary={name.title} />
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl> */}
                        {filteropen && (
                          <>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <ReservationStructureExcel
                              csvData={reservCatFilteredData}
                              reservStructList={resStructList}
                              fileName="Reservation Structure"
                              type="pdf"
                            />
                          </>
                        )}

                        {filteropen && (
                          <>
                            &nbsp;&nbsp;
                            <ReservationStructureExcel
                              csvData={reservCatFilteredData}
                              reservStructList={resStructList}
                              fileName="Reservation Structure"
                              type="excel"
                            />
                          </>
                        )}

                        {filteropen && (
                          <>
                            &nbsp;&nbsp;
                            <FontAwesomeIconCompo
                              className="fa-times-circle"
                              color="primary"
                              title="Cancel"
                              baseClassName="fas"
                              onClick={closeFilter}
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <ReservationStructureExcel
                              csvData={reservCatFilteredData}
                              reservStructList={resStructList}
                              fileName="Reservation Structure"
                              type="pdf"
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;
                            <ReservationStructureExcel
                              csvData={reservCatFilteredData}
                              reservStructList={resStructList}
                              fileName="Reservation Structure"
                              type="excel"
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;&nbsp;
                            <MyComponentWithIconProps
                              statusImage={AddCircleIcon}
                              color="primary"
                              title=""
                              fontSize="large"
                              sx={{ m: -1 }}
                              onClick={startEdit}
                            />
                          </>
                        )}
                      </Grid>
                    </Grid>

                    <Box sx={{ mt: 2, p: 1.5 }}>
                      <ReservationStructureMainTable
                        filteredColumns={resStructList}
                        data={reservCatFilteredData}
                        rowEdit={getRowID}
                        rowView={getRowData}
                        rowDelete={deleteData}
                        rowStatus={rowStatus}
                      />
                    </Box>
                  </>
                )}
              </div>
            )}
          </Grid>
          {!reservCat.isServerFail &&
            (!reservCat?.isFetch ? (
              <Loading1 size={50} />
            ) : status && !reservCat?.isSave ? (
              <Loading1 size={50} />
            ) : save && !reservCat?.isSave ? (
              <Loading1 size={50} />
            ) : del ? (
              <Loading1 size={50} />
            ) : (
              ""
            ))}
        </Paper>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  reservCat: state.reservCat,
  common: state.common,
  loginUser: state.user,
});

const mapDispatchToProps = {
  getReservDetails,
  getCategoryFilterData,
  resetDelete,
  saveReservCat,
  deleteReservCatById,
  resetSave,
  filterReservCatData,
  logOut,
  getAlert,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReservationCategoryDetail);
