import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { decodeData } from "../../components/Comman/Util/Base64";
import endpoints from "../../config/endpoints";
import { tokenExp } from "../Login/login.slice";

const initialState = {
  newsDocNotification: [],
  newsDocNotificationData: [],
  newsDocNotificationFilter: [],
  newsDocNotificationDetails: {},
  isFilter: false,
  isFetch: false,
  isSave: false,
  error: false,
  isServerFail: false,
  isDelete: false,
};
let URL = endpoints.newsDocNotification;
const newsDocNotificationSlice = createSlice( {
  name: "newsDocNotification",
  initialState,
  reducers: {
    newsDocNotificationSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        newsDocNotification: row,
        isFetch: true,
      };
    },
    newsDocNotificationFilterSuccess: (
      state = cloneDeep( initialState ),
      action
    ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        newsDocNotificationFilter: row,
        isFilter: true,
      };
    },
    newsDocNotificationDetailsSuccess: (
      state = cloneDeep( initialState ),
      action
    ) =>
    {
      const { data } = action.payload;
      return {
        ...state,
        newsDocNotificationDetails: data,
        isSave: true,
      };
    },
    newsDocNotificationDetailsFail: (
      state = cloneDeep( initialState ),
      action
    ) =>
    {
      return {
        ...state,
        newsDocNotificationDetails: {},
        isSave: false,
      };
    },
    newsDocNotificationFilterFailure: (
      state = cloneDeep( initialState ),
      action
    ) =>
    {
      return {
        ...state,
        newsDocNotificationFilter: [],
        isFilter: false,
      };
    },
    newsDocNotificationFailure: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        newsDocNotification: [],
      };
    },

    newsDocsDeleteSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        error: false,
        isDelete: true,
      };
    },

    newsDocNotificationFail: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        error: true,
        isDelete: true,
        isSave: false,
      };
    },

    serverFailed: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        isServerFail: true,
        error: false,
        isDelete: false,
      };
    },

    resetDeleteSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        error: false,
        isDelete: false,
        isSave: false,
        isServerFail: false,
      };
    },
  },
} );

export const {
  newsDocNotificationFilterSuccess,
  newsDocNotificationDetailsSuccess,
  newsDocNotificationDetailsFail,
  newsDocNotificationFilterFailure,
  newsDocNotificationSuccess,
  newsDocNotificationFailure,
  serverFailed,
  resetDeleteSuccess,
  newsDocsDeleteSuccess,
  newsDocNotificationFail,
} = newsDocNotificationSlice.actions;

export default newsDocNotificationSlice.reducer;

export const getNewsDocNotification = () => async ( dispatch ) =>
{
  try
  {
    const name = localStorage.getItem( "token" );
    const token = decodeData( name );

    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get( URL + '?sort={"insertdatetime": "DESC"}', { headers } )
      .then( ( response ) =>
      {
        const { error } = response.data;
        if ( error == true )
        {
          dispatch( tokenExp() );
        } else
        {
          console.log( "Success get newsDocNotification  ========>", response );
          let data = response.data.data.content;
          let row = [];
          let index = 0;
          data.map( ( data1 ) =>
          {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              documentName: data1.documentName,
              showOnScreen: data1.showOnScreen,
              description: data1.description,
              type:
                data1.type == 0
                  ? "Online Admission"
                  : data1.type == 1
                    ? "Latest News"
                    : data1.type == 2
                      ? "Notification"
                      : "",
              showLabel: data1.showLable === 1 ? "Yes" : "No",
              labelName: data1.lableName,
              orderNo: data1.orderNo,
              title: data1.title,
              date: data1.date,
              isActive: data1.isActive === 1 ? "Active" : "Inactive",
            };
            row.push( bData );
            return data1;
          } );
          dispatch( newsDocNotificationSuccess( { row } ) );
          return row;
        }
      } )
      .catch( ( error ) =>
      {
        dispatch( serverFailed() );
        console.log( "Error get newsDocNotification========>", error );
        return false;
      } );
    return doc;
  } catch ( e )
  {
    dispatch( serverFailed() );
    return false;
  }
};

export const getNewsDocNotificationById = () => async ( dispatch ) =>
{
  try
  {
    const name = localStorage.getItem( "token" );
    const token = decodeData( name );
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    let doc = await axios
      .get( URL + "/1", { headers } )
      .then( ( response ) =>
      {
        const { error } = response.data;
        if ( error == true )
        {
          dispatch( tokenExp() );
        }
        console.log(
          "Success with id get newsDocNotification by id ========>",
          response
        );
      } )
      .catch( ( error ) =>
      {
        dispatch( serverFailed() );
        console.log(
          "Error get newsDocNotification by id ========>",
          error.response.data
        );
        return false;
      } );
    return doc;
  } catch ( e )
  {
    dispatch( serverFailed() );
    return false;
  }
};

export const saveNewsDocNotification =
  ( { newsDocNotificationData } ) =>
    async ( dispatch ) =>
    {
      try
      {
        let data = JSON.stringify( newsDocNotificationData );
        const name = localStorage.getItem( "token" );
        const token = decodeData( name );
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
          shouldrefresh: "true",
        };
        let doc = await axios
          .post( URL, data, { headers } )
          .then( ( response ) =>
          {
            const { error } = response.data;
            if ( error == true )
            {
              dispatch( tokenExp() );
            }
            console.log(
              "Success With saveNewsDocNotification ========>",
              response.data
            );
            const { data } = response.data;
            dispatch( newsDocNotificationDetailsSuccess( { data } ) );
            return data;
          } )
          .catch( ( error ) =>
          {
            dispatch( serverFailed() );
            return false;
          } );
        return doc;
      } catch ( e )
      {
        dispatch( serverFailed() );
        return false;
      }
    };

export const filterNewsDocNotificationData =
  ( { row, flag } ) =>
    async ( dispatch ) =>
    {
      try
      {
        if ( flag === true )
        {
          dispatch( newsDocNotificationFilterSuccess( { row } ) );
        } else
        {
          dispatch( newsDocNotificationSuccess( { row } ) );
        }
      } catch ( e )
      {
        return console.error( e.message );
      }
    };

export const resetSave = () => async ( dispatch ) =>
{
  try
  {
    dispatch( newsDocNotificationDetailsFail() );
  } catch ( e )
  {
    return console.error( e.message );
  }
};

export const deleteNewsDocNotificationById =
  ( { newsDocNotificationId } ) =>
    async ( dispatch ) =>
    {
      try
      {
        const name = localStorage.getItem( "token" );
        const token = decodeData( name );
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
          shouldrefresh: "true",
        };
        let response = await axios
          .delete( URL + "/" + newsDocNotificationId, { headers } )
          .then( ( response ) =>
          {
            console.log(
              "Success with delete newsDocNotification by id ========>",
              response
            );
            dispatch( newsDocsDeleteSuccess() );
            return true;
          } )
          .catch( ( error ) =>
          {
            if ( error.response )
            {
              console.log( "error.response.status" );
              console.log( error.response.status );
              console.log( "error.response.data" );
              console.log( error.response.data );
            }
            dispatch( newsDocNotificationFail() );
            return false;
          } );
        return response;
      } catch ( e )
      {
        dispatch( serverFailed() );
        return false;
      }
    };

export const resetDelete = () => async ( dispatch ) =>
{
  try
  {
    dispatch( resetDeleteSuccess() );
  } catch ( e )
  {
    return console.error( e.message );
  }
};
