import * as React from "react";
import { connect } from "react-redux";

const Navbar = () => {
  return (
    <>
      <div className="navbar">
        <div className="main_menu">
          <ul>
            <li>
              <a href="/dashboard">Home</a>
            </li>
           
            <li>
              <a href="/allNotifications2">Latest News</a>
            </li>
            <li>
              <a href="/allNotifications">Admission Details</a>
            </li>
            <li>
              <a href="/allNotifications3">Notification</a>
            </li>
            <li>
              <a href="/contact-us">Contact Us</a>
            </li>
          </ul>
        </div>
        <div className="login_area">
          <div className="login_r">
            <a href="/login">Login / Register</a>
          </div>
        </div>
        <div className="clr"></div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  common: state.common,
});

const mapDispatchToProps = {  };

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
