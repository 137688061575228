import { Grid, Paper } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getLatestNews,
  getNewsDocNotification,
  getNotification,
  getOnlineAdmission,
} from "../Landing/Landing.slice";
import { getProfile } from "../Student/student.slice";
import { getApplicationsByPrgTypeId } from "../ProgramMaster/program.slice";
import { viewMultipleFile } from "../UploadFile/file.slice";

const styles = (theme) => ({
  hello: {
    position: "absolute",
    left: "15.17%",
    right: "15.08%",
    top: "63.4%",
    bottom: "32.6%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: "normal",
    fontsize: "36px",
    lineheight: "40px",
    color: "#000000",
  },
  buttonC: {
    position: "absolute",

    left: "39.17%",
    right: "39.17%",
    top: "79.7%",
    bottom: "14.8%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: 500,
    fontsize: "18px",
    lineheight: "40px",
    color: "#FFFFFF",
  },
  helper: {
    position: "absolute",
    left: "9.58%",
    right: "9.5%",
    top: "70%",
    bottom: "22%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: "normal",
    fontsize: "20px",
    lineheight: "40px",

    textAlign: "center",

    color: "#666666",
  },
  title: {
    position: "absolute",
    left: "43.42%",
    right: "43.33%",
    top: "57%",
    bottom: "39%",

    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: 500,
    fontsize: "55px",
    lineheight: "40px",
    color: "#000000",
  },
});
const DashboardLandingPage = ({
  getNewsDocNotification,
  applicationList,
  getLatestNews,
  viewMultipleFile,
  getNotification,
  getProfile,
  getApplicationsByPrgTypeId,
  getOnlineAdmission,
  classes,
}) => {
  useEffect(() => {
    getNewsDocNotification().then(() => {
      console.log(applicationList?.application);
    });

    getLatestNews().then((response) => {
      console.log(response);
      // response.map((data)=>{
      //   if(data.documentName!=''){
      //     let documentName = {
      //       fileName: data.documentName,
      //       name: data.documentName,
      //     };
      //     viewMultipleFile({
      //       file: documentName,
      //     });
      //   }

      // })
    });

    getNotification().then(() => {});
    getProfile().then((response) => {
      console.log(response);
      if (response) {
        getApplicationsByPrgTypeId({ intrestedIn: response.intrestIn });
    
      }
    });
    // getOnlineAdmission().then(() => {});
  }, []);

  const gotoApplication = (id, e) => {
    e.stopPropagation();
    window.location.replace("/applicationform");
    localStorage.setItem("applicationformId", id);
  };
  return (
    <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
      <div className="new_content nopadding">
        <Grid item container justifyContent="space-between">
          <Grid item md={12}>
            <div className="box_one">
              <h3 className="event_title">Online Admission</h3>

              {applicationList?.application.slice(0, 3).map((data) => {
                return (
                  // <div className="event_cont" key={data.id}>
                  //   <div className="admission_one">
                  //     <div className="admission_title">
                  //       <div className="admission_date">
                  //         End Date <span> {data.lastDate}</span>
                  //       </div>
                  //       <div className="admission_cont">
                  //         <h6 className="event_sub_nm">
                  //           {data.title}
                  //         </h6>
                  //       </div>
                  //     </div>
                  //     <p className="admission_txt">
                  //       {ReactHtmlParser(data.detailedDescription)}
                  //     </p>
                  //     <a href='#' onClick={(e) => gotoApplication(data.id, e)}className="admission_apply">
                  //       Apply Now
                  //     </a>
                  //   </div>
                  // </div>
                  <div className="event_cont_new" key={data.id}>
                    {/*admission-one*/}

                    <div className="admission_one padding">
                      <div className="admission_title">
                        <div className="admission_date">
                          Date <span> {data.lastDate}</span>
                        </div>
                        <div className="admission_cont">
                          <h6 className="event_sub_nm">
                            {data.applicationName}
                            {/* <span>Am Univercity 7 April in, Mumbai.</span> */}
                          </h6>
                        </div>
                      </div>

                      <div className="noti_title">
                        <span>
                          {data.applicationName}
                          {data.showLabel === 1 && (
                            <div className="blink_me">{data.labelName}</div>
                          )}
                        </span>
                      </div>
                      <p className="admission_txt">
                        {data.shortSummary === data.shortSummary.slice(0, 70)
                          ? data.shortSummary.slice(0, 70)
                          : data.shortSummary.slice(0, 70) + " ... "}
                        {data.shortSummary !==
                          data.shortSummary.slice(0, 70) && (
                          <a href="/notification" className="news">
                            Read more
                          </a>
                        )}
                      </p>
                      <div className="news_time home">
                        <span>Date :</span> {data.lastDate}
                      </div>

                      <a
                        href="#"
                        onClick={(e) => gotoApplication(data.id, e)}
                        className="admission_apply"
                      >
                        Apply Now
                      </a>
                    </div>
                  </div>
                );
              })}

              <div className="event_cont">
                <div className="more_noti">
                  <a href="/admission" className="more">
                  See all ongoing admissions
                  </a>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};
const mapStateToProps = (state) => ({
  applicationList: state.program,
});

const mapDispatchToProps = {
  getNewsDocNotification,
  getLatestNews,
  viewMultipleFile,
  getNotification,
  getProfile,
  getApplicationsByPrgTypeId,
  getOnlineAdmission,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DashboardLandingPage));
