import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import AddIcon from "@mui/icons-material/Add";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import * as React from "react";
import EditIcon from "@mui/icons-material/Edit";
import {
  ChipCompo,
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "./IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TouchAppRoundedIcon from "@mui/icons-material/TouchAppRounded";
import ListTwoToneIcon from "@mui/icons-material/ListTwoTone";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Dessert (100g serving)",
  },
  {
    id: "calories",
    numeric: true,
    disablePadding: false,
    label: "Calories",
  },
  {
    id: "fat",
    numeric: true,
    disablePadding: false,
    label: "Fat (g)",
  },
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    label: "Carbs (g)",
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "Protein (g)",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    tableHead,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {tableHead.map((headCell) => {
          if (headCell.showInscreen) {
            return (
              <TableCell
                key={headCell.name}
                style={{ minWidth: headCell.width }}
                // padding={headCell.disablePadding ? 'none' : 'normal'}
              >
                {headCell.title}
                {orderBy === headCell.name ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
}
function Row({
  row,
  tableHead,
  rowStatus,
  rowDelete,
  rowEdit,
  rowId,
  rowView,
  rowViewData,
  showHeadEdit,
  showHeadDelete,
  showHeadAdd,
  showApply,
  showHeadPdf,
  showHeadExcel,
  pdfDetailsId,
  excelDetailsId,
  showView,
  rowViewDocument,
  showViewDocument,
  showRoundDetails,
  showPdfButton,
  rounds,
  isActionColActive,
  rowStatusAdmission,
  pdfDownload,
}) {
  const [open, setOpen] = React.useState(false);

  const changeStatus = (e, row) => {
    e.stopPropagation();
    rowStatus(row.id);
  };

  const changeStatusAdmission = (e, row) => {
    e.stopPropagation();
    rowStatusAdmission(row.id);
  };
  const onClickDelete = (e, id) => {
    e.stopPropagation();
    rowDelete(id);
  };

  const onClickEdit = (e, id) => {
    e.stopPropagation();
    rowEdit(id);
  };
  const onClickRowId = (e, id) => {
    e.stopPropagation();
    rowId(id);
  };
  const onClickApply = (e, id) => {
    e.stopPropagation();
    rowView(id);
  };

  const onClickViewPreview = (e, id) => {
    e.stopPropagation();
    rowViewData(id);
  };
  const pdfDetails = (e, id) => {
    e.stopPropagation();
    pdfDetailsId(row);
  };
  const excelDetails = (e, id) => {
    e.stopPropagation();
    excelDetailsId(row);
  };

  const onClickViewDocument = (e, row) => {
    e.stopPropagation();
    rowViewDocument(row);
  };

  const roundForm = (e, id) => {
    e.stopPropagation();
    rounds(id);
  };

  const pdfDownloadForm = (e, id) => {
    e.stopPropagation();
    pdfDownload(id);
  };

  React.useEffect(() => {});
  return (
    <TableRow
      key={row.name}
      // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      {Object.keys(row).map(function (key, index) {
        const filteredList = tableHead.filter(
          (row) => row.name === key && row.showInscreen
        );
        if (filteredList.length !== 0) {
          if (filteredList[0].positionCenter && key !== "isActive")
            return (
              <TableCell style={{ width: "10%" }}>
                <center>{row[key]}</center>
              </TableCell>
            );
          else if (key === "isActive") {
            return (
              <TableCell style={{ width: "15%" }}>
                {row[key] === "Active" ? (
                  <center>
                    <ChipCompo
                      label="Active"
                      variant="outlined"
                      size="small"
                      color="success"
                      onClick={(e) => changeStatus(e, row)}
                    />
                  </center>
                ) : (
                  <center>
                    <ChipCompo
                      label="Inactive"
                      variant="outlined"
                      size="small"
                      color="error"
                      onClick={(e) => changeStatus(e, row)}
                    />
                  </center>
                )}
              </TableCell>
            );
          } else if (key === "admissionOpen") {
            return (
              <TableCell style={{ width: "15%" }}>
                {row[key] === "Yes" ? (
                  <center>
                    <ChipCompo
                      label="Yes"
                      variant="outlined"
                      size="small"
                      color="success"
                      onClick={(e) => changeStatusAdmission(e, row)}
                    />
                  </center>
                ) : (
                  <center>
                    <ChipCompo
                      label="No"
                      variant="outlined"
                      size="small"
                      color="error"
                      onClick={(e) => changeStatusAdmission(e, row)}
                    />
                  </center>
                )}
              </TableCell>
            );
          } else
            return (
              <TableCell align={filteredList[0].alignCenter}>
                {row[key]}
              </TableCell>
            );
        }
      })}
      {isActionColActive && (
        <>
          <TableCell style={{ width: "15%" }}>
            {showHeadEdit && (
              <MyComponentWithIconProps
                statusImage={EditIcon}
                color="primary"
                fontSize="medium"
                title="Edit"
                onClick={(e) => onClickEdit(e, row.id)}
              />
            )}
            &nbsp;&nbsp;
            {showHeadDelete && (
              <MyComponentWithIconProps
                statusImage={DeleteIcon}
                color="error"
                fontSize="medium"
                title="Delete"
                onClick={(e) => onClickDelete(e, row.id)}
              />
            )}
            &nbsp;&nbsp;
            {showHeadAdd && (
              <MyComponentWithIconProps
                statusImage={AddIcon}
                color="primary"
                fontSize="medium"
                title="Institute Course"
                onClick={(e) => onClickRowId(e, row.id)}
              />
            )}
            &nbsp;&nbsp;
            {showApply && (
              <MyComponentWithIconProps
                statusImage={TouchAppRoundedIcon}
                color="primary"
                fontSize="medium"
                title="Apply"
                onClick={(e) => onClickApply(e, row.id)}
              />
            )}
            &nbsp;&nbsp;
            {showView && (
              <MyComponentWithIconProps
                statusImage={VisibilityIcon}
                color="primary"
                fontSize="medium"
                title="View"
                onClick={(e) => onClickViewPreview(e, row.id)}
              />
            )}
            {showHeadPdf && (
              <>
                &nbsp;&nbsp;
                <FontAwesomeIconCompo
                  className="fa-file-pdf"
                  color="error"
                  title="PDF"
                  baseClassName="fas"
                  onClick={(e) => pdfDetails(e, row)}
                />
              </>
            )}
            {showHeadExcel && (
              <>
                &nbsp;&nbsp;
                <FontAwesomeIconCompo
                  className="fa-file-excel"
                  color="primary"
                  title="Excel"
                  baseClassName="fas"
                  onClick={(e) => excelDetails(e, row)}
                />
              </>
            )}
            &nbsp;&nbsp;
            {showViewDocument && (
              <MyComponentWithIconProps
                statusImage={VisibilityIcon}
                color="primary"
                fontSize="medium"
                title="View"
                onClick={(e) => onClickViewDocument(e, row)}
              />
            )}
            &nbsp;&nbsp;
            {showRoundDetails && (
              <MyComponentWithIconProps
                statusImage={ListTwoToneIcon}
                fontSize="medium"
                color="primary"
                title="Round Details"
                onClick={(e) => roundForm(e, row.id)}
              />
            )}
            &nbsp;&nbsp;
            {showPdfButton && (
              <MyComponentWithIconProps
                statusImage={PictureAsPdfIcon}
                fontSize="medium"
                color="error"
                title="PDF"
                onClick={(e) => pdfDownloadForm(e, row.id)}
              />
            )}
          </TableCell>
        </>
      )}
    </TableRow>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  tableHead,
  rowStatus,
  rowDelete,
  rowEdit,
  data,
  showHeadDelete,
  showHeadEdit,
  rowId,
  showHeadAdd,
  rowView,
  rowViewData,
  showHeadPdf,
  showHeadExcel,
  pdfDetailsId,
  excelDetailsId,
  showApply,
  showView,
  rowViewDocument,
  showViewDocument,
  showRoundDetails,
  showPdfButton,
  rounds,
  pdfDownload,
  isActionColActive = true,
  showPegination = true,
  rowStatusAdmission,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
        >
          <EnhancedTableHead
            tableHead={tableHead}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
          />
          {showPegination ? (
            <TableBody>
              {stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <Row
                      key={row.id}
                      row={row}
                      tableHead={tableHead}
                      showHeadDelete={showHeadDelete}
                      showHeadEdit={showHeadEdit}
                      rowEdit={rowEdit}
                      rowStatus={rowStatus}
                      rowDelete={rowDelete}
                      showHeadAdd={showHeadAdd}
                      rowId={rowId}
                      rowView={rowView}
                      rowViewData={rowViewData}
                      showApply={showApply}
                      showHeadPdf={showHeadPdf}
                      showHeadExcel={showHeadExcel}
                      pdfDetailsId={pdfDetailsId}
                      excelDetailsId={excelDetailsId}
                      showView={showView}
                      rowViewDocument={rowViewDocument}
                      showViewDocument={showViewDocument}
                      showRoundDetails={showRoundDetails}
                      showPdfButton={showPdfButton}
                      rounds={rounds}
                      pdfDownload={pdfDownload}
                      isActionColActive={isActionColActive}
                      rowStatusAdmission={rowStatusAdmission}
                      //   tableSubList={tableSubList}
                    />
                    // <TableRow
                    //   hover
                    //   onClick={(event) => handleClick(event, row.name)}
                    //   role="checkbox"
                    //   aria-checked={isItemSelected}
                    //   tabIndex={-1}
                    //   key={row.name}
                    //   selected={isItemSelected}
                    // >
                    //   {/* <TableCell padding="checkbox">
                    //     <Checkbox
                    //       color="primary"
                    //       checked={isItemSelected}
                    //       inputProps={{
                    //         'aria-labelledby': labelId,
                    //       }}
                    //     />
                    //   </TableCell> */}
                    //   <TableCell
                    //     component="th"
                    //     id={labelId}
                    //     scope="row"
                    //     padding="none"
                    //   >
                    //     {row.name}
                    //   </TableCell>
                    //   <TableCell align="right">{row.calories}</TableCell>
                    //   <TableCell align="right">{row.fat}</TableCell>
                    //   <TableCell align="right">{row.carbs}</TableCell>
                    //   <TableCell align="right">{row.protein}</TableCell>
                    // </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          ) : (
            <TableBody>
              {stableSort(data, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <Row
                      key={row.id}
                      row={row}
                      tableHead={tableHead}
                      showHeadDelete={showHeadDelete}
                      showHeadEdit={showHeadEdit}
                      rowEdit={rowEdit}
                      rowStatus={rowStatus}
                      rowDelete={rowDelete}
                      showHeadAdd={showHeadAdd}
                      rowId={rowId}
                      rowView={rowView}
                      rowViewData={rowViewData}
                      showApply={showApply}
                      showHeadPdf={showHeadPdf}
                      showHeadExcel={showHeadExcel}
                      pdfDetailsId={pdfDetailsId}
                      excelDetailsId={excelDetailsId}
                      showView={showView}
                      rowViewDocument={rowViewDocument}
                      showViewDocument={showViewDocument}
                      showRoundDetails={showRoundDetails}
                      showPdfButton={showPdfButton}
                      rounds={rounds}
                      pdfDownload={pdfDownload}
                      isActionColActive={isActionColActive}
                      rowStatusAdmission={rowStatusAdmission}
                      //   tableSubList={tableSubList}
                    />
                    // <TableRow
                    //   hover
                    //   onClick={(event) => handleClick(event, row.name)}
                    //   role="checkbox"
                    //   aria-checked={isItemSelected}
                    //   tabIndex={-1}
                    //   key={row.name}
                    //   selected={isItemSelected}
                    // >
                    //   {/* <TableCell padding="checkbox">
                    //     <Checkbox
                    //       color="primary"
                    //       checked={isItemSelected}
                    //       inputProps={{
                    //         'aria-labelledby': labelId,
                    //       }}
                    //     />
                    //   </TableCell> */}
                    //   <TableCell
                    //     component="th"
                    //     id={labelId}
                    //     scope="row"
                    //     padding="none"
                    //   >
                    //     {row.name}
                    //   </TableCell>
                    //   <TableCell align="right">{row.calories}</TableCell>
                    //   <TableCell align="right">{row.fat}</TableCell>
                    //   <TableCell align="right">{row.carbs}</TableCell>
                    //   <TableCell align="right">{row.protein}</TableCell>
                    // </TableRow>
                  );
                }
              )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {showPegination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
}
