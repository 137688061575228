import { Box, Grid, Paper, Typography, FormHelperText } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React from "react";
import { connect } from "react-redux";
import AttachFile from "../../components/Comman/AttachFile";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import "../../CSS/View.css";
import profile from "../../Images/profile.png";
import signDefault from "../../Images/signDefault.png";
import {
  deleteFileData,
  saveMultipleFile,
  viewMultipleFile,
} from "../UploadFile/file.slice";
let docs;
class ViewPage extends React.Component {
  state = {
    id: 0,
    signatureFile: "",
    profilePicFile: "",
    profilePic: profile,
    isUpdateProfile: false,
    isSubmit: false,
    isUpdateSign: false,
    signature: signDefault,
    file: "",
    formErrors: [],
    error: false,
  };

  changeH = (fileName, name, index) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      if (name === "profilePicFile") {
        this.setState({ profilePic: e.target.result });
      } else {
        this.setState({ signature: e.target.result });
      }
    };
    reader.readAsDataURL(fileName);

    this.setState({
      ...this.state,
      [name]: fileName,
    });
  };

  submitHandler = (e) => {
    e.preventDefault();
    const { profilePicFile, signatureFile } = this.state;

    const { saveMultipleFile } = this.props;
    if (profilePicFile !== "") {
      let profilePic = {
        fileName: profilePicFile,
        name: "profilePic",
      };
      this.setState({
        isUpdateProfile: true,
      });
      saveMultipleFile({ file: profilePic });
    }
    if (signatureFile !== "") {
      let signature = {
        fileName: signatureFile,
        name: "signature",
      };
      this.setState({
        isUpdateSign: true,
      });
      saveMultipleFile({ file: signature });
    }

    if (signatureFile === "" && profilePicFile === "") {
      if (
        this.props.student.applicant.profilePic !== null &&
        this.props.student.applicant.signature !== null
      ) {
        this.setState({
          isSubmit: true,
        });
      } else {
        this.setState({
          error: true,
        });
      }
    } else if (signatureFile === "" || profilePicFile === "") {
      if (
        profilePicFile === "" &&
        this.props.student.applicant.profilePic === null
      ) {
        this.setState({
          error: true,
        });
      }
      if (
        signatureFile === "" &&
        this.props.student.applicant.signature === null
      ) {
        this.setState({
          error: true,
        });
      }
    }

    //   this.props.OnSave(docs);
    // }
  };

  uploadImage = () => {
    const { saveFile } = this.props;
    const { file, label } = this.state;

    if (file !== "") {
      this.setState({
        upload: true,
      });
      let saveFileData = {
        file: file,
        name: label,
      };
      saveFile({ file: saveFileData });
    } else {
      this.setState({
        uploadError: true,
      });
    }
  };

  handleFormValidation() {
    let formErrors = {};
    let formIsValid = true;
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  componentDidMount() {
    const { student, viewMultipleFile, deleteFileData } = this.props;
    deleteFileData();
    if (
      student.applicant.signature !== null &&
      student.applicant.profilePic !== null
    ) {
      let sign = {
        fileName: student.applicant.signature,
        name: "signature",
      };
      let profilee = {
        fileName: student.applicant.profilePic,
        name: "profilePic",
      };
      viewMultipleFile({
        file: sign,
      });
      viewMultipleFile({
        file: profilee,
      });
    }

    this.setState({
      id: student.applicant.id,
    });
    // this.setState({
    //   id: profile.profile.id,
    //   profilePic: profile.profile.profilePic,
    //   signature: profile.profile.signature
    // })

    // if (Object.keys(student.applicant).length != 0) {
    //   this.setState({
    //     id: student.applicant.id,
    //     profilePic: student.applicant.profilePic,
    //     signature: student.applicant.signature
    //   });
    // }
  }

  componentDidUpdate() {
    const { student, fileData, deleteFileData } = this.props;
    const { id, isSubmit, isUpdateProfile, isUpdateSign } = this.state;

    if (fileData.isFetchFile === true && fileData.fileDataView.length === 2) {
      const profile = fileData.fileDataView.filter(
        (fileData) => fileData.name === "profilePic"
      );
      const signature = fileData.fileDataView.filter(
        (fileData) => fileData.name === "signature"
      );

      this.setState({
        profilePic: profile[0].fileName,
        signature: signature[0].fileName,
      });
      deleteFileData();
    }
    if (isUpdateProfile === true && isUpdateSign === true) {
      if (fileData.isFetch === true && fileData.fileData.length === 2) {
        const profile = fileData.fileData.filter(
          (fileData) => fileData.name === "profilePic"
        );
        const signature = fileData.fileData.filter(
          (fileData) => fileData.name === "signature"
        );
        docs = {
          id: id,
          profilePic: profile[0].fileName,
          signature: signature[0].fileName,
          type: 4,
        };

        this.props.uploadphotoandsign(docs);
        deleteFileData();
      }
    } else if (
      (isUpdateProfile === true || isUpdateSign === true) &&
      this.state.error == false
    ) {
      if (fileData.isFetch === true) {
        if (isUpdateProfile === true) {
          const profile = fileData.fileData.filter(
            (fileData) => fileData.name === "profilePic"
          );
          docs = {
            id: id,
            profilePic: profile[0].fileName,
            signature: student.applicant.signature,
            type: 4,
          };
          this.props.uploadphotoandsign(docs);
          deleteFileData();
        } else {
          const signature = fileData.fileData.filter(
            (fileData) => fileData.name === "signature"
          );
          docs = {
            id: id,
            profilePic: student.applicant.profilePic,
            signature: signature[0].fileName,
            type: 4,
          };

          this.props.uploadphotoandsign(docs);
          deleteFileData();
        }
      }
    } else if (
      isSubmit &&
      student.applicant.profilePic !== null &&
      student.applicant.signature !== null
    ) {
      docs = {
        id: id,
        profilePic: student.applicant.profilePic,
        signature: student.applicant.signature,
        type: 4,
      };

      this.props.uploadphotoandsign(docs);
      this.setState({
        isSubmit: false,
      });
    }
  }

  render() {
    const { onCancel } = this.props;
    return (
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <Grid container justifyContent="space-evenly" spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <LabelCompo
              className="text-black"
              style={{
                fontSize: 18,
                color: "black",
                fontFamily: "Poppins",
              }}
              label="Instructions:"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Box display="flex" justifyContent="space-between ">
              <Box>
                <Card
                  variant="outlined"
                  sx={{
                    color: "#ed1c24",
                    fontFamily: "poppins",
                    mt: 1,
                    p: 1,
                  }}
                >
                  <CardContent style={{ lineHeight: "34px" }}>
                    <Grid>
                      <Typography variant="span">a)</Typography>
                      &emsp;
                      <Typography variant="span">
                        The Photo should be Colorful. Black and White Photo
                        should not be Accepted.
                      </Typography>
                    </Grid>

                    <Grid>
                      <Typography variant="span">b)</Typography>
                      &emsp;
                      <Typography variant="span">
                        Size of the Photograph should be in between 20KB to
                        100KB.
                      </Typography>
                    </Grid>

                    <Grid>
                      <Typography variant="span">c)</Typography>
                      &emsp;
                      <Typography variant="span">
                        The photograph and Signature Should be Scanned.
                      </Typography>
                    </Grid>

                    <Grid>
                      <Typography variant="span">d)</Typography>
                      &emsp;
                      <Typography variant="span">
                        The Photograph Dimension should be of 4.5 cm * 3.5 cm
                      </Typography>
                    </Grid>

                    <Grid>
                      <Typography variant="span">e)</Typography>
                      &emsp;
                      <Typography variant="span">
                        Photo Should be without CAP, Googles and both ears
                        should be visible.
                      </Typography>
                    </Grid>

                    <Grid>
                      <Typography variant="span">f)</Typography>
                      &emsp;
                      <Typography variant="span">
                        The Signature should be of black and blue ink.
                      </Typography>
                    </Grid>

                    <Grid>
                      <Typography variant="span">g)</Typography>
                      &emsp;
                      <Typography variant="span">
                        The Signature should be in the box only. Outside of the
                        box or on the box will not be accepted.
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography variant="span">h)</Typography>
                      &emsp;
                      <Typography variant="span">
                        The Signature should be on blank page.
                      </Typography>
                    </Grid>

                    <Grid>
                      <Typography variant="span">i)</Typography>
                      &emsp;
                      <Typography variant="span">
                        Size of the Signature Should be in between 20 KB to 50
                        KB.
                      </Typography>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Box>
          </Grid>

          <Grid container justifyContent="space-evenly" m={2}>
            <Grid
              item
              sm={12}
              xs={12}
              md={4}
              lg={4}
              rowSpacing={3}
              justifyContent="space-evenly"
            >
              <Grid item xs={6} sm={6} md={12}>
                {/* <Typography variant="span">SCANNED PHOTO</Typography> */}
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 14 }}
                  label="Scanned Photo  :"
                />
              </Grid>

              <Grid item xs={6} sm={6} md={12}>
                <Grid m={2} item xs={6} sm={6} md={12}>
                  <AttachFile name="profilePicFile" fileName={this.changeH} />
                </Grid>

                <Grid item xs={6} sm={6} md={12}>
                  <img
                    id="target"
                    src={this.state.profilePic}
                    height="100px"
                    width="100"
                    align="left"
                    style={{ display: "block", margin: " 0 auto" }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={4}
              lg={4}
              rowSpacing={3}
              justifyContent="space-evenly"
            >
              <Grid item xs={6} sm={6} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 14 }}
                  label="Scanned Sign :"
                />
              </Grid>

              <Grid item xs={6} sm={6} md={12}>
                <Grid m={2} item xs={6} sm={6} md={12}>
                  <AttachFile name="signatureFile" fileName={this.changeH} />
                </Grid>

                <Grid item xs={6} sm={6} md={12}>
                  <img
                    id="target"
                    src={this.state.signature}
                    height="100px"
                    width="100"
                    align="left"
                    style={{ display: "block", margin: " 0 auto" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            {this.state.error && (
              <FormHelperText error>
                {"Please select photo and sign"}{" "}
              </FormHelperText>
            )}
          </Grid>
          <Grid
            rowSpacing={2}
            // columnSpacing={{ xs: 12, sm: 6, md: 1 }}
            container
            columnSpacing={1}
            justifyContent="right"
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={1.1}
              lg={1.1}
              justifyContent="space-evenly"
            >
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name="Cancel"
                onClick={onCancel}
                fullWidth={true}
              />
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={2.5}
              lg={2.5}
              justifyContent="space-evenly"
            >
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Save and Proceed"
                fullWidth={true}
                onClick={this.submitHandler}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}
const mapStateToProps = (state) => ({
  student: state.student,
  fileData: state.file,
});

const mapDispatchToProps = {
  saveMultipleFile,
  deleteFileData,
  viewMultipleFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPage);
