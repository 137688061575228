
import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { DirectionSnackbar } from "../../components/Comman/SnakBarCompo";
import { saveCapRound } from "./addingcapround.slice";
import AddingCapRoundForm from "./AddingCapRoundForm";
import {logOut} from "../Login/login.slice";
import { useNavigate } from "react-router";
import Alert from "../../Alert/Alert";
import { getAlert } from "../../Alert/alert.slice";
import * as myConstClass from "../../config/messageconstant";
const rows = [
  {
    id: 1,
    firstName: "sds",
    middleName: "sad",
    lastName: "sdasd",
    gender: "Male",
    contactNo: 343567891,
    emailId: "Scsxa@gmail.com",
    enterPassword: "sdsd",
    confirmPassword: "sdsd",
    captcha: "dfwdw",
  },
  {
    id: 2,
    firstName: "sds",
    middleName: "sad",
    lastName: "sdasd",
    gender: "Male",
    contactNo: 343567891,
    emailId: "Scsxa@gmail.com",
    enterPassword: "sdsd",
    confirmPassword: "sdsd",
    captcha: "dfwdw",
  },
  {
    id: 3,
    firstName: "sds",
    middleName: "sad",
    lastName: "sdasd",
    gender: "Male",
    contactNo: 343567891,
    emailId: "Scsxa@gmail.com",
    enterPassword: "sdsd",
    confirmPassword: "sdsd",
    captcha: "dfwdw",
  },
];


const names = [
  { title: "First Name", name: "firstName" },
  { title: "Middle Name", name: "middleName" },
  { title: "Last Name", name: "lastName" },
  { title: "Gender", name: "gender" },
  { title: "Contact Number", name: "contactNo" },
  { title: "Email id", name: "emailId" },
  { title: "Enter Password", name: "enterPassword" },
  { title: "Confirm Password", name: "confirmPassword" },
  { title: "Captcha", name: "captcha" },
  { title: "Status", name: "isActive" },
];


const AddingCapRound = ({ saveCapRound,loginUser }) => {
  const [isEdit, setEdit] = useState(false);
  const [row, setRows] = useState(rows);
  const [snakMsg, setSnakMsg] = useState('');
  const [editRow, setEditRow] = useState([]);
  const [viewRow, setViewRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteropen, setFilterOpen] = useState(false);
  const [openSnak, setOpenSnak] = useState(false);
  const navigate = useNavigate();
  const [filteredList, setfilterList] = React.useState([
    "First Name",
    "Middle Name",
    "Last Name",
    "Gender",
    "Contact Number",
    "Email id",
    "Enter Password",
    "Confirm Password",
    "Captcha",
    "Status"
  ]);
 
  useEffect(()=>{
    if(loginUser.token){
      localStorage.removeItem("token");
      navigate("/login");
      logOut()
    }
     }) 

  const data = (instData) => {
    saveCapRound({ capRoundData: instData }).then((response) => {
      if(!response){
        getAlert({ message: myConstClass.serverMsg });
      }else{
      setOpenSnak(true);
      setSnakMsg("Data save successfully");
    }
    });
  };
  useEffect(() => {
  });
    
  const stopEdit = () => {
    setEditRow([]);
    setEdit(false);
  };
  const saveAndNextData = (data) => {
    setEdit(true);
    setOpenSnak(true)
    setSnakMsg('Data save successfully')
  };
 
  const addFile = (file) => {
  };
  

  return (
    <>
     {/* {loginUser.isServerFail && <Alert msg={myConstClass.serverMsg} />} */}
      <div>
        {<DirectionSnackbar open={openSnak} severity='success' msg={snakMsg} closeSnak={setOpenSnak} />}
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <AddingCapRoundForm
            OnSave={data}
            isEdit={editRow}
            onCancel={stopEdit}
            saveAndNext={saveAndNextData}
            handleFile={addFile}
          />

        </Paper>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({

  loginUser:state.user,
});

const mapDispatchToProps = {
  saveCapRound,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddingCapRound);