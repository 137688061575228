import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import {
  getLatestNews,
  getNewsDocNotification,
  getNotification,
  getOnlineAdmission,
} from "../Pages/Landing/Landing.slice";
import { viewMultipleFile } from "../Pages/UploadFile/file.slice";
import Header from "./NewHeader";
import Navbar from "./Navbar";
import place_icn from "../Images/place_icn.png";
import road_icn from "../Images/road_icn.png";
import bus_icn from "../Images/bus_icn.png";
import train_icn from "../Images/train_icn.png";
import "../CSS/stylesheet.css";
import { FontAwesomeIconCompo } from "../components/Comman/IconButton";
import CircleIcon from "@mui/icons-material/Circle";

const ErrorPage = ({}) => {
  // const date=new Date()
  return (
    <>
      <Header />
      <Navbar />
      <section className="slide_bx">
        <div className="container">
          <div className="row">
            <Grid
              item
              sx={{ pl: 5,pr:5 }}
              sm={12}
              md={12}
              lg={12}
              justifyContent="space-between"
            >
              <h3 className="research_title">
                Dr. BMN College of Home Science
              </h3>
              <div className="address_bor">
                <div className="row">
                  <Grid item md={6}>
                    <div className="address_left">
                      <h4 className="detail_title">Contact Details</h4>
                      <div className="address_one">
                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                        <span>Address :</span> 338, Rafi Ahmed Kidwai Road,
                        Matunga, Mumbai -400019
                      </div>
                      <div className="address_one">
                        <i className="fa fa-phone" aria-hidden="true"></i>
                        <span>Phone : </span> 022 2409 5792/ 022 2403 529 <br />
                      </div>
                      <div className="address_one">
                        <i
                          className="fa fa-envelope cont"
                          aria-hidden="true"
                        ></i>
                        <span>Email ID :</span> smesedu@gmail.com
                      </div>
                    </div>
                  </Grid>
                  <Grid item container md={6} sx={{margin:'auto'}}>
                    <div className="address_right">
                      <h4 className="detail_title">Travelling options </h4>
                      <img src={place_icn} className="place_icn" alt="" />
                      <div className="travel_one">
                        <img src={road_icn} alt="" /> <span>Via Road :</span>{" "}
                        Rafi Ahmed Kidwai Marg
                      </div>
                      <div className="travel_one">
                        <img src={bus_icn} alt="" />
                        <span>Near by Bus Stop :</span> Maheshwari Udyaan
                      </div>
                      <div className="travel_one">
                        <img src={train_icn} alt="" />
                        <span>Via Train :</span> WR – Matunga Road <br />
                        CR – Matunga station <br />
                        HR – Kingcircle station / Wadala station
                      </div>
                    </div>
                  </Grid>
                </div>
              </div>
            </Grid>
          </div>
        </div>
        <Grid
          item
          sx={{ pl: 5,pr:5 }}
          sm={12}
          md={12}
          lg={12}
          container
          justifyContent="space-between"
        >
          <div className="row">
            <Grid item md={12}>
              <h3 className="research_title">Help Needed</h3>
              <form action="" method="get">
                <div className="row">
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    rowSpacing={2}
                    columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                    container
                    justifyContent="right"
                  >
                    <Grid item md={6}>
                      <h5 className="cont_field_txt">
                        Your Name <span>*</span>{" "}
                      </h5>
                      <input
                        name=""
                        type="text"
                        className="form-control input_pop"
                        placeholder=""
                      />
                    </Grid>
                    <Grid item md={6}>
                      <h5 className="cont_field_txt">
                        Email Id <span>*</span>{" "}
                      </h5>
                      <input
                        name=""
                        type="text"
                        className="form-control input_pop"
                        placeholder=""
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className="row">
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                    justifyContent="right"
                  >
                    <Grid item md={6}>
                      <h5 className="cont_field_txt">Phone No. </h5>
                      <input
                        name=""
                        type="text"
                        className="form-control input_pop"
                        placeholder=""
                      />
                    </Grid>
                    <Grid item md={6}>
                      <h5 className="cont_field_txt">Enquiry for</h5>
                      <select
                        className="form-select input_pop cont"
                        aria-label="Default select example"
                      >
                        <option selected>Enquiry for</option>
                        <option>Admission enquiry</option>
                        <option> Examination, Courses enquiry</option>
                      </select>
                    </Grid>
                  </Grid>
                </div>
                <div className="row">
                  <Grid item md={12}>
                    <h5 className="cont_field_txt">
                      In what way can we help you?{" "}
                    </h5>
                    <textarea
                      name=""
                      cols=""
                      rows="5"
                      className="form-control input_pop"
                      placeholder=""
                    ></textarea>
                  </Grid>
                </div>

                <div className="row">
                  <Grid item md={12}>
                    <input
                      name=""
                      type="button"
                      value="Submit"
                      className="pop_btn contact"
                    />
                  </Grid>
                </div>
              </form>
            </Grid>
            {/* <Grid item md={12} sx={{float: 'right'}}>
              <h3 className="research_title">Site Map</h3>
              <div className="college_map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.799596805155!2d72.85765081430644!3d19.0285504584084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cf2f051bdd6f%3A0xf26f202e33ba0e2b!2sDr.%20BMN%20College%20of%20Home%20Science!5e0!3m2!1sen!2sin!4v1640724225657!5m2!1sen!2sin" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
              </div>
            </Grid> */}
          </div>
        </Grid>
      </section>
    </>
  );
};

export default ErrorPage;
