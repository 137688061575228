import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid, Paper } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import { getAlert } from "../../Alert/alert.slice";
import {
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "../../components/Comman/IconButton";
import DynamicTable from "../../components/Comman/MaterialUIRTTable";
import { DirectionSnackbar } from "../../components/Comman/SnakBarCompo";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
import { Loading1 } from "../../components/Loading1";
import Search from "../../components/Search";
import * as myConstClass from "../../config/messageconstant";
import { getInstitute } from "../Institute/institute.slice";
import {
  deleteInstituteCourseById,
  getInstituteCourse,
  saveInstituteCourse,
} from "../InstituteCourse/instituteCourse.slice";
import InstituteCourseForm from "../InstituteCourse/InstituteCourseForm";
import { logOut } from "../Login/login.slice";
import {
  deleteBranchById,
  deleteBranchInstituteData,
  filterBranchData,
  getbranch,
  getbranchById,
  getInstituteCoruseById,
  resetDataById,
  resetDelete,
  resetSave,
  saveBranch,
} from "./branch.slice";
import BranchExcelData from "./BranchExcelData";
import BranchForm from "./BranchForm";
import ViewInst from "./BranchView";

const breakpoint = 600;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const names = [
  { title: "Sr. No.", name: "index" },
  { title: "Program Type", name: "progName" },
  { title: "Program Title", name: "name" },
  { title: "Course Code", name: "courseCode" },
  { title: "Status", name: "isActive" },
];

const BranchMaster = ({
  getbranch,
  getbranchById,
  saveInstituteCourse,
  deleteBranchById,
  getInstitute,
  resetDataById,
  resetSave,
  resetDelete,
  instituteList,
  instituteCourseList,
  getInstituteCoruseById,
  saveBranch,
  branchMaster,
  deleteBranchInstituteData,
  deleteInstituteCourseById,
  filterBranchData,
  loginUser,
  logOut,
  getAlert,
}) => {
  const [isEdit, setEdit] = useState(false);
  const [brId, setBrId] = useState("");
  const [status, setStatus] = useState(false);
  const [del, setDel] = useState(false);
  const [del1, setDel1] = useState(false);
  const [save, setSave] = useState(false);
  const [save1, setSave1] = useState(false);
  const [snakMsg, setSnakMsg] = useState("");
  const [editRow, setEditRow] = useState([]);
  const [viewRow, setViewRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteropen, setFilterOpen] = useState(false);
  const [openSnak, setOpenSnak] = useState(false);
  const [progDetails, setProgDetails] = useState([]);
  const [instDetails, setInstDetails] = useState([]);
  const [progDocOpen, setProgDocOpen] = React.useState(false);
  const [filteredList, setfilterList] = React.useState([
    "Sr. No.",
    "Program Type",
    "Program Title",
    "Course Code",
    "Status",
  ]);
  const navigate = useNavigate();
  const [branchList, setBranchList] = React.useState([
    [{ title: "Sr. No.", name: "index" }],
    [{ title: "Program Type", name: "progName" }],
    [{ title: "Program Title", name: "name" }],
    [{ title: "Course Code", name: "courseCode" }],
    [{ title: "Status", name: "isActive" }],
  ]);
  const [width, setWidth] = React.useState(window.innerWidth);

  const [branchDataList, setBranchDataList] = React.useState([
    {
      title: "Sr. No.",
      name: "index",
      positionCenter: true,
      alignCenter: "center",
      showInscreen: true,
      width: 100,
    },
    {
      title: "Program Type",
      name: "progName",
      positionCenter: false,
      showInscreen: true,
      width: 300,
    },
    {
      title: "Program Title",
      name: "name",
      positionCenter: false,
      showInscreen: true,
      width: 300,
    },
    {
      title: "Course Code",
      name: "courseCode",
      positionCenter: true,
      showInscreen: true,
      width: 100,
    },
    {
      title: "Status",
      name: "isActive",
      positionCenter: true,
      alignCenter: "center",
      showInscreen: true,
      width: 50,
    },
    {
      title: "Action",
      name: "action",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
      width: 50,
    },
  ]);

  const rowStatus = async (id) => {
    swal(myConstClass.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        const viewRow = branchMaster?.branch.filter((row) => row.id === id);
        let trustId = localStorage.getItem("trustId");
        let branchData = {
          programType: {
            id: viewRow[0].progNameId,
          },
          id: viewRow[0].id,
          brName: viewRow[0].branchName,
          brShortName: viewRow[0].branchShortName,
          courseCode: viewRow[0].courseCode,
          dsAdmission: viewRow[0].dsAdmission === "Yes" ? 1 : 0,
          isActive: viewRow[0].isActive === "Active" ? "0" : "1",
          delStatus: 1,
          trust: {
            id: trustId,
          },
        };
        resetSave();
        setStatus(true);
        saveBranch({ branchData: branchData }).then((response) => {
          if (!response) {
            getAlert({ message: myConstClass.serverMsg });
          } else {
            setOpenSnak(true);
            setSnakMsg(myConstClass.activeStatus);
            getbranch().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
            setStatus(false);
          }
        });
      }
    });
  };

  const getRowID = async (id) => {
    setViewRow([]);
    getbranchById({ branchId: id }).then((respo) => {
      if (!respo) {
        getAlert({ message: myConstClass.serverMsg });
      } else {
        setEdit(true);
      }
    });
  };

  const getRowData = async (id) => {
    setOpen(true);
    const viewRow = branchMaster?.branch.filter((row) => row.id === id);
    if (viewRow.length > 0) {
      return await setViewRow(viewRow);
    }
    return setViewRow([]);
  };

  const data = (instData) => {
    setSave(true);
    saveBranch({ branchData: instData }).then((response) => {
      if (!response) {
        getAlert({ message: myConstClass.serverMsg });
      } else {
        setOpenSnak(true);
        if (instData.id != null) {
          setSnakMsg(myConstClass.updateMsg);
        } else {
          setSnakMsg(myConstClass.saveMsg);
        }
        setSave(false);

        getbranch().then((respo) => {
          if (!respo) {
            getAlert({ message: myConstClass.serverMsg });
          }
        });
      }
    });
    setEdit(false);
    setEditRow([]);
  };

  useEffect(async () => {
    getbranch().then((respo) => {
      if (!respo) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
  });
  const startEdit = () => {
    resetDataById();
    setEdit(true);
  };

  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setDel(true);
        deleteBranchById({ branchId: id }).then((response) => {
          if (!response) {
            getAlert({ message: myConstClass.serverMsg });
          } else {
            setOpenSnak(true);
            setSnakMsg(myConstClass.deleteMsg);
            setDel(false);
            getbranch().then((respo) => {
              if (!respo) {
                getAlert({ message: myConstClass.serverMsg });
              }
            });
          }
        });
      }
    });
  };

  const branchFilteredData = branchMaster?.branch.filter(
    (item) =>
      item.progName.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.branchShortName.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.courseCode.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.isActive.toLowerCase() === searchValue
  );

  const filteredRows = (searchvalue) => {
    setSearchValue(searchvalue);
    const filteredRow = branchMaster?.branch.filter((row) => {
      let progName = row?.progName;
      let name = row?.name;
      let branchShortName = row?.branchShortName;
      let courseCode = row?.courseCode;
      let isActive = row?.isActive;
      return (
        progName.toLowerCase().includes(searchvalue.toLowerCase()) ||
        name.toLowerCase().includes(searchvalue.toLowerCase()) ||
        branchShortName.toLowerCase().includes(searchvalue.toLowerCase()) ||
        courseCode.toLowerCase() === searchvalue ||
        isActive.toLowerCase() === searchvalue
      );
    });
    if (branchMaster?.branch.length == filteredRows.length) {
      filterBranchData({ row: filteredRow, flag: false });
    } else {
      filterBranchData({ row: filteredRow, flag: true });
    }
  };

  const stopEdit = () => {
    setEdit(false);
    setEditRow([]);
  };
  const saveAndNextData = (data) => {
    setSave(true);
    saveBranch({ branchData: data }).then((response) => {
      if (!response) {
        getAlert({ message: myConstClass.serverMsg });
      } else {
        setOpenSnak(true);
        if (data.id != null) {
          setSnakMsg(myConstClass.updateMsg);
        } else {
          setSnakMsg(myConstClass.saveMsg);
        }
        setSave(false);
        getbranch().then((respo) => {
          if (!respo) {
            getAlert({ message: myConstClass.serverMsg });
          }
        });
      }
    });
    setEdit(true);
    setEditRow([]);
  };

  const closeFilter = () => {
    setBranchDataList([
      {
        title: "Sr. No.",
        name: "index",
        positionCenter: true,
        alignCenter: true,
        showInscreen: true,
      },
      {
        title: "Program Type",
        name: "progName",
        positionCenter: false,
        showInscreen: true,
      },
      {
        title: "Program Title",
        name: "name",
        positionCenter: false,
        showInscreen: true,
      },
      {
        title: "Course Code",
        name: "courseCode",
        positionCenter: true,
        showInscreen: true,
      },
      {
        title: "Status",
        name: "isActive",
        positionCenter: true,
        alignCenter: true,
        showInscreen: true,
      },
      {
        title: "Action",
        name: "action",
        positionCenter: false,
        showInscreen: true,
        alignCenter: "left",
      },
    ]);
    setfilterList([
      "Sr. No.",
      "Program Type",
      "Program Title",
      "Course Code",
      "Status",
    ]);
    setFilterOpen(false);
  };

  const DocData = (data) => {
    if (data != "") {
      setProgDocOpen(false);
      setInstDetails(true);
    }
    let eData = data.map((data1) => {
      let docs = {
        programTitle: {
          id: data1.branchId,
        },
        tenant: {
          id: data1.id,
        },
        isActive: 1,
      };
      return docs;
    });
    setSave1(true);
    saveInstituteCourse({ instituteCourseData: eData }).then((respo) => {
      if (!respo) {
        getAlert({ message: myConstClass.serverMsg });
      } else {
        setSave1(false);
        setOpenSnak(true);
        setSnakMsg("Data save successfully");
        getInstituteCourse().then((respo) => {
          if (!respo) {
            getAlert({ message: myConstClass.serverMsg });
          }
        });
        deleteBranchInstituteData();
      }
    });
  };

  const ProgDoc = (id) => {
    const viewRow = branchMaster?.branch.filter((row) => row.id === id);
    getInstituteCoruseById({ branchId: id }).then((respo) => {
      if (!respo) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
    setBrId(id);
    getInstitute().then((respo) => {
      if (!respo) {
        getAlert({ message: myConstClass.serverMsg });
      } else {
        setProgDetails(viewRow);
        setProgDocOpen(true);
      }
    });
  };
  const closeProgDoc = () => {
    setProgDocOpen(false);
    deleteBranchInstituteData();
  };

  const handleChange = (event) => {
    setFilterOpen(true);
    const {
      target: { value },
    } = event;
    if (value.length > filteredList.length) {
      let row = [];
      const a = branchDataList.map((qualiData) => {
        let trust = value.filter((namesRow) => namesRow === qualiData.title);

        if (trust.length !== 0) {
          let qData = {
            ...qualiData,
            showInscreen: true,
          };
          row.push(qData);
        } else {
          let qData = {
            ...qualiData,
            showInscreen: false,
          };
          row.push(qualiData);
        }

        return setBranchDataList(row);
      });
      console.log(row);
    } else {
      let row = [];
      let difference = filteredList.filter((x) => !value.includes(x));
      const qualificationList = branchDataList.filter((data) => {
        if (data.title === difference[0]) {
          let QualiData = {
            ...data,
            showInscreen: false,
          };
          row.push(QualiData);
        } else {
          row.push(data);
        }
      });
      console.log("row");
      console.log(row);
      setBranchDataList(row);
    }

    setfilterList(typeof value === "string" ? value.split(",") : value);

    if (value.length === 0) {
      setFilterOpen(false);
    }
  };

  const rowDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be retrived,Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setDel1(true);
        deleteInstituteCourseById({ institutecourseId: id }).then(
          (response) => {
            if (!response) {
              getAlert({ message: myConstClass.serverMsg });
            } else {
              setOpenSnak(true);
              setSnakMsg(myConstClass.deleteMsg);
              getInstituteCoruseById({ branchId: brId }).then((respo) => {
                if (!respo) {
                  getAlert({ message: myConstClass.serverMsg });
                }
              });
              setDel1(false);
            }
          }
        );
      }
    });
  };

  return (
    <>
      <div>
        <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
          <TitleAndBreadCrum title="Program Title" />
        </Paper>
        <br />
        {/* {branchMaster.isDelete && branchMaster.error == true && (
          <Alert msg={myConstClass.deleteFailMsg} closeAlert={resetDelete} />
        )} */}

        {branchMaster.isDelete && branchMaster.error == false ? (
          <DirectionSnackbar
            open={openSnak}
            severity="success"
            msg={myConstClass.deleteMsg}
            closeSnak={setOpenSnak}
          />
        ) : branchMaster.isSave ? (
          <DirectionSnackbar
            open={openSnak}
            severity="success"
            msg={snakMsg}
            closeSnak={setOpenSnak}
          />
        ) : (
          ""
        )}
        <Paper sx={{ p: 2, pt: 4, borderRadius: 2 }} elevation={0}>
          <div>
            {viewRow.length >= 0 && isEdit && (
              <BranchForm
                OnSave={data}
                isEdit={branchMaster?.branchById}
                onCancel={stopEdit}
                saveAndNext={saveAndNextData}
              />
            )}
          </div>

          {progDocOpen &&
            branchMaster?.isFetchInstBranch &&
            instituteList?.isFetchInstitute && (
              <InstituteCourseForm
                DocRows={branchMaster?.InstCourse}
                progDetails={progDetails}
                data={DocData}
                instData={instituteList?.institute}
                onCancel={closeProgDoc}
                rowDelete={rowDelete}
              />
            )}

          {open && (
            <ViewInst data={viewRow} openparam={open} closeView={setOpen} />
          )}

          <Grid>
            {!isEdit && !progDocOpen && (
              <div>
                {width > breakpoint ? (
                  <>
                    <Box
                      sx={{ mr: 2 }}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Box sx={{ m: -1, width: 300 }}>
                        <Search
                          filteredRows={filteredRows}
                          searchValue={searchValue}
                        />
                      </Box>

                      <Box>
                        <FormControl sx={{ m: -1, width: 250 }}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={filteredList}
                            name="sdf"
                            size="small"
                            onChange={handleChange}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {names.map((name) => {
                              return (
                                <MenuItem key={name.name} value={name.title}>
                                  <Checkbox
                                    checked={
                                      filteredList.indexOf(name.title) > -1
                                    }
                                  />
                                  <ListItemText primary={name.title} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>

                        {filteropen && (
                          <>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <BranchExcelData
                              csvData={branchFilteredData}
                              instList={branchDataList}
                              fileName="Program Title"
                              type="pdf"
                            />
                          </>
                        )}
                        {filteropen && (
                          <>
                            &nbsp;&nbsp;
                            <BranchExcelData
                              csvData={branchFilteredData}
                              instList={branchDataList}
                              fileName="Program Title"
                              type="excel"
                            />
                          </>
                        )}

                        {filteropen && (
                          <>
                            &nbsp;&nbsp;
                            <FontAwesomeIconCompo
                              className="fa-times-circle"
                              color="primary"
                              title="Cancel"
                              baseClassName="fas"
                              onClick={closeFilter}
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <BranchExcelData
                              csvData={branchFilteredData}
                              instList={branchDataList}
                              fileName="Program Title"
                              type="pdf"
                            />
                          </>
                        )}

                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;
                            <BranchExcelData
                              csvData={branchFilteredData}
                              instList={branchDataList}
                              fileName="Program Title"
                              type="excel"
                            />
                          </>
                        )}
                        {!filteropen && filteredList.length >= 0 && (
                          <>
                            &nbsp;&nbsp;&nbsp;
                            <MyComponentWithIconProps
                              statusImage={AddCircleIcon}
                              color="primary"
                              title=""
                              fontSize="large"
                              sx={{ m: -1 }}
                              onClick={startEdit}
                            />
                          </>
                        )}
                      </Box>
                    </Box>
                    <Box sx={{ mt: 2, p: 1.5 }}>
                      {/* <BranchTable
                        filteredColumns={branchDataList}
                        // data={branchMaster?.branch}
                        data={branchFilteredData}
                        rowEdit={getRowID}
                        rowView={getRowData}
                        rowDelete={deleteData}
                        rowStatus={rowStatus}
                        ProgDoc={ProgDoc}
                      />{" "} */}

                      <DynamicTable
                        data={branchFilteredData}
                        tableHead={branchDataList}
                        rowEdit={getRowID}
                        rowDelete={deleteData}
                        rowStatus={rowStatus}
                        rowId={ProgDoc}
                        showHeadDelete={true}
                        showHeadAdd={true}
                        showHeadEdit={true}
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      rowSpacing={2}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid xs={12} sm={12} md={12} lg={12} rowSpacing={2}>
                        {!filteropen && (
                          <Search
                            filteredRows={filteredRows}
                            searchValue={searchValue}
                          />
                        )}
                      </Grid>

                      <Grid xs={12} sm={12} md={12} lg={12} sx={{ m: 1 }}>
                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={filteredList}
                            name="sdf"
                            size="small"
                            onChange={handleChange}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {names.map((name) => {
                              return (
                                <MenuItem key={name.name} value={name.title}>
                                  <Checkbox
                                    checked={
                                      filteredList.indexOf(name.title) > -1
                                    }
                                  />
                                  <ListItemText primary={name.title} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <Grid
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          sx={{ m: 1, textAlign: "right" }}
                        >
                          {filteropen && (
                            <>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <BranchExcelData
                                csvData={branchFilteredData}
                                instList={branchDataList}
                                fileName="Program Title"
                                type="pdf"
                              />
                            </>
                          )}
                          {filteropen && (
                            <>
                              &nbsp;&nbsp;
                              <BranchExcelData
                                csvData={branchFilteredData}
                                instList={branchDataList}
                                fileName="Program Title"
                                type="excel"
                              />
                            </>
                          )}

                          {filteropen && (
                            <>
                              &nbsp;&nbsp;
                              <FontAwesomeIconCompo
                                className="fa-times-circle"
                                color="primary"
                                title="Cancel"
                                baseClassName="fas"
                                onClick={closeFilter}
                              />
                            </>
                          )}

                          {!filteropen && filteredList.length >= 0 && (
                            <>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <BranchExcelData
                                csvData={branchFilteredData}
                                instList={branchDataList}
                                fileName="Program Title"
                                type="pdf"
                              />
                            </>
                          )}

                          {!filteropen && filteredList.length >= 0 && (
                            <>
                              &nbsp;&nbsp;
                              <BranchExcelData
                                csvData={branchFilteredData}
                                instList={branchDataList}
                                fileName="Program Title"
                                type="excel"
                              />
                            </>
                          )}
                          {!filteropen && filteredList.length >= 0 && (
                            <>
                              &nbsp;&nbsp;&nbsp;
                              <MyComponentWithIconProps
                                statusImage={AddCircleIcon}
                                color="primary"
                                title=""
                                fontSize="large"
                                sx={{ m: -1 }}
                                onClick={startEdit}
                              />
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid sx={{ mt: 2, p: 1.5 }}>
                      {/* <BranchTable
                        filteredColumns={branchDataList}
                        // data={branchMaster?.branch}
                        data={branchFilteredData}
                        rowEdit={getRowID}
                        rowView={getRowData}
                        rowDelete={deleteData}
                        rowStatus={rowStatus}
                        ProgDoc={ProgDoc}
                      />{" "} */}
                      <DynamicTable
                        data={branchFilteredData}
                        tableHead={branchDataList}
                        rowEdit={getRowID}
                        rowDelete={deleteData}
                        rowStatus={rowStatus}
                        showHeadDelete={true}
                        showHeadEdit={true}
                        showHeadAdd={true}
                      />
                    </Grid>
                  </>
                )}
              </div>
            )}
          </Grid>

          {!branchMaster.isServerFail &&
            (!branchMaster?.isFetchBranch ? (
              <Loading1 size={50} />
            ) : status && !branchMaster?.isSave ? (
              <Loading1 size={50} />
            ) : save && !branchMaster?.isSave ? (
              <Loading1 size={50} />
            ) : save1 && !instituteCourseList?.isSave ? (
              <Loading1 size={50} />
            ) : del1 ? (
              <Loading1 size={50} />
            ) : del ? (
              <Loading1 size={50} />
            ) : (
              ""
            ))}
        </Paper>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  branchMaster: state.branch,
  instituteList: state.institute,
  instituteCourseList: state.instituteCourse,
  common: state.common,
  loginUser: state.user,
});

const mapDispatchToProps = {
  getbranch,
  resetSave,
  resetDelete,
  getInstitute,
  resetDataById,
  deleteBranchById,
  getbranchById,
  saveBranch,
  saveInstituteCourse,
  getInstituteCoruseById,
  deleteBranchInstituteData,
  deleteInstituteCourseById,
  filterBranchData,
  logOut,
  getAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(BranchMaster);
