
import { Grid, TextField } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import * as React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import { onlyNumber } from "../../components/Comman/Util/Validations";
import * as myConstClass from "../../config/messageconstant";
import { Loading1 } from "../../components/Loading1";
import { getCat } from "../CategoryMaster/category.slice";
import { getCategoryFilterData } from "../CategoryMaster/category.slice";

import ReservationStructureTable from "./ReservationStructureTable";

let docs;
let category = [];
let editCategory = [];
class ReservationStructureForm extends React.Component
{
  constructor( props )
  {
    super( props );
  }

  state = {
    id: 0,
    categoryDetails: [],
    matrixName: "",
    generalPercentage: 0,
    otherPercentage: 0,
    malePercentage: 0,
    femalePercentage: 0,
    flag: 0,
    isActive: 1,
    trustId: "",
    formErrors: [],
    editCategory: []
  };

  changeHandler = ( event ) =>
  {
    const { name, value } = event.target;
    if ( event.target.value === "" || onlyNumber.test( event.target.value ) )
    {
      this.setState( {
        ...this.state,
        [ name ]: value,
      } );
    }
  };

  changedHandler = ( event ) =>
  {
    const { name, value } = event.target;
    this.setState( {
      ...this.state,
      [ name ]: value,
    } );
  };

  componentDidMount()
  {
    const { isEdit, getCategoryFilterData } = this.props;
    getCategoryFilterData();
    editCategory = []
    let trustId = localStorage.getItem( "trustId" );
    this.setState( {
      trustId: trustId,
    } );
    console.log( isEdit );
    if ( isEdit.length > 0 )
    {
      this.setState( {
        id: isEdit[ 0 ].id,
        otherPercentage: isEdit[ 0 ].otherPer,
        malePercentage: isEdit[ 0 ].malePer,
        matrixName: isEdit[ 0 ].name,
        femalePercentage: isEdit[ 0 ].famalePer,
        generalPercentage: isEdit[ 0 ].generalPer,
        isActive: isEdit[ 0 ].isActive === "Active" ? 1 : 0,
      } );
      let index = 0
      isEdit[ 0 ].reservationStructureDetail.map( ( data ) =>
      {
        index = index + 1
        let catData = {
          index: index,
          reservId: data.id,
          id: data.category.id,
          name: data.category.name,
          percentage: data.per,
        };
        editCategory.push( catData )
        return data;
      } );
      this.setState( {
        editCategory: editCategory
      } );
    }
  }
  componentDidUpdate() { }

  handleFormValidation()
  {
    const {
      otherPercentage,
      malePercentage,
      femalePercentage,
      matrixName,
      generalPercentage,
    } = this.state;

    let formErrors = {};
    let formIsValid = true;
    let sum = 0;
    category.map( ( data ) =>
    {
      sum = +data.per + sum;
    } );
    if ( sum != 100 )
    {
      formIsValid = false;
      formErrors[ "CategoryErr" ] = myConstClass.categoryPercentage;
    }
    if ( matrixName.trim() === "" )
    {
      formIsValid = false;
      formErrors[ "matrixNameErr" ] = myConstClass.matrixNameMsg;
    }
    var total =
      parseFloat( otherPercentage ) +
      parseFloat( malePercentage ) +
      parseFloat( femalePercentage ) +
      parseFloat( generalPercentage );
    if ( total != 100 )
    {
      formIsValid = false;
      formErrors[ "TotalErr" ] = myConstClass.categoryPercentage;
    }

    this.setState( { formErrors: formErrors } );
    return formIsValid;
  }

  saveHandler = ( e ) =>
  {
    e.preventDefault();
    const {
      id,
      isActive,
      matrixName,
      generalPercentage,
      otherPercentage,
      malePercentage,
      femalePercentage,
      tsId,
      trustId,
    } = this.state;

    docs = {
      name: matrixName,
      trust: {
        id: trustId,
      },
      generalPer: +generalPercentage,
      otherPer: otherPercentage,
      malePer: malePercentage,
      famalePer: femalePercentage,
      reservationStructureDetail: category,
      isActive: isActive,
    };
    if ( id != 0 )
    {
      docs = {
        ...docs,
        id: id,
      };
    }
    if ( this.handleFormValidation() )
    {
      const { getCat, categoryList } = this.props;
      getCat( { row: categoryList?.category } );
      this.props.saveAndNext( docs );

      this.setState( {
        id: 0,
        matrixName: "",
        generalPercentage: 0,
        otherPercentage: 0,
        malePercentage: 0,
        femalePercentage: 0,
        categoryDetails: "",
        isActive: 1,
        formErrors: [],
      } );
    }
  };

  changeTable = ( data ) =>
  {
    category = data.map( ( catData ) =>
    {

      let cat = {
        category: {
          id: catData.id,
        },
        per: catData.percentage,
      };
      if ( catData.reservId != undefined )
      {
        cat = {
          ...cat,
          id: catData.reservId
        }
      }
      return cat;
    } );

  };
  submitHandler = ( e ) =>
  {
    e.preventDefault();
    this.setState( {
      flag: 2,
    } );
    const {
      id,
      matrixName,
      isActive,
      generalPercentage,
      otherPercentage,
      malePercentage,
      femalePercentage,
      trustId,
    } = this.state;

    docs = {
      name: matrixName,
      trust: {
        id: trustId,
      },
      generalPer: +generalPercentage,
      otherPer: otherPercentage,
      malePer: malePercentage,
      famalePer: femalePercentage,
      reservationStructureDetail: category,
      isActive: isActive,
    };
    if ( id != 0 )
    {
      docs = {
        ...docs,
        id: id,
      };
    }

    if ( this.handleFormValidation() )
    {
      this.props.OnSave( docs );
    }
  };

  render()
  {
    const {
      matrixName,
      flag,
      generalPercentage,
      otherPercentage,
      malePercentage,
      femalePercentage,
    } = this.state;

    const {
      malePercentageErr,
      femalePercentageErr,
      otherPercentageErr,
      matrixNameErr,
      generalPercentageErr,
      CategoryErr,
      TotalErr,
    } = this.state.formErrors;
    const { onCancel, categoryList } = this.props;
    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            <Grid
              sx={{ m: -1 }}
              item
              xs={12}
              md={12}
              size="small"
              container
              justifyContent="flex-end"
            >
              <ButtonCompo
                size="small"
                type="button"
                variant="outlined"
                name=" Back to List"
                onClick={onCancel}
              />
            </Grid>


            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="space-evenly"
            >

              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="*Reservation Name : "
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  size="small"
                  color="primary"
                  name="matrixName"
                  id="matrixName"
                  value={matrixName}
                  onChange={this.changedHandler}
                  fullWidth
                  // {...( matrixNameErr
                  //   ? { error: true, helperText: matrixNameErr }
                  //   : "" )}
                  error={matrixNameErr ? true : false}
                  helperText={matrixNameErr ? matrixNameErr : " "}
                ></TextField>
              </Grid>



              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={2}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*General Percentage : "
                  />
                </Grid>


                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    size="small"
                    color="primary"
                    name="generalPercentage"
                    id="generalPercentage"
                    value={generalPercentage}
                    onChange={this.changeHandler}
                    fullWidth
                    // {...( generalPercentageErr
                    //   ? { error: true, helperText: generalPercentageErr }
                    //   : "" )}
                    error={generalPercentageErr ? true : false}
                    helperText={generalPercentageErr ? generalPercentageErr : " "}
                  ></TextField>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={2}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >

                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Male Percentage : "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    size="small"
                    color="primary"
                    name="malePercentage"
                    id="malePercentage"
                    value={malePercentage}
                    onChange={this.changeHandler}
                    fullWidth
                    // {...( malePercentageErr
                    //   ? { error: true, helperText: malePercentageErr }
                    //   : "" )}
                    error={malePercentageErr ? true : false}
                    helperText={malePercentageErr ? malePercentageErr : " "}
                  ></TextField>
                </Grid>

              </Grid>


              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={2}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Female Percentage : "
                  />
                </Grid>


                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    size="small"
                    color="primary"
                    name="femalePercentage"
                    id="femalePercentage"
                    value={femalePercentage}
                    onChange={this.changeHandler}
                    fullWidth
                    // {...( femalePercentageErr
                    //   ? { error: true, helperText: femalePercentageErr }
                    //   : "" )}
                    error={femalePercentageErr ? true : false}
                    helperText={femalePercentageErr ? femalePercentageErr : " "}
                  ></TextField>
                </Grid>
              </Grid>



              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={2}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >

                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Other Percentage : "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    size="small"
                    color="primary"
                    name="otherPercentage"
                    id="otherPercentage"
                    value={otherPercentage}
                    onChange={this.changeHandler}
                    fullWidth
                    // {...( otherPercentageErr
                    //   ? { error: true, helperText: otherPercentageErr }
                    //   : "" )}
                    error={otherPercentageErr ? true : false}
                    helperText={otherPercentageErr ? otherPercentageErr : " "}
                  ></TextField>
                </Grid>
              </Grid>

            </Grid>

          </Grid>

          {TotalErr != "" && <FormHelperText error>{TotalErr}</FormHelperText>}
          <br></br>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 20 }}
            label="Student Reservation Form :"
          />
          {CategoryErr != "" && (
            <FormHelperText error>{CategoryErr}</FormHelperText>
          )}
          <ReservationStructureTable
            rowTData={this.changeTable}
            resRow={categoryList?.categoryFilterData}
            editCategory={this.props.rows}
          />

          <br></br>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={1}
            container
            justifyContent="right"
          >


            <Grid
              item
              justifyContent="space-evenly"
            >
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Save"
              /></Grid>
            <Grid
              item
              justifyContent="space-evenly"
            >
              <ButtonCompo
                size="medium"
                type="button"
                variant="contained"
                name="Save and Next"
                onClick={this.saveHandler}
              />
            </Grid>


            <Grid
              item
              justifyContent="space-evenly"
            >
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name="Cancel"
                onClick={onCancel}
              />
            </Grid>
          </Grid>
          {console.log( categoryList.isFetch )}
          {flag != 0 && categoryList.isFetch && <Loading1 size={50} />}
        </form>
      </>
    );
  }
}

const mapStateToProps = ( state ) => ( {
  categoryList: state.category,
  common: state.common,
} );

const mapDispatchToProps = {
  getCat,
  getCategoryFilterData,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)( ReservationStructureForm );