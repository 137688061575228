import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import "../../CSS/paymentSuccess.css";
import {
  saveApplicant,
  savePaymentTransaction,
  getUpdateTransaction,
} from "./studentDirectAdmission.slice";
import { DirectionSnackbar } from "../../components/Comman/SnakBarCompo";

class PaymentSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      convertedJson: "",
      txniId: "",
      userId: "",
      showSuccess: false,
      snakMsg: "",
      openSnak: false,
      severity: "",
    };
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    logErrorToMyService(error, errorInfo);
  }
  componentDidMount() {
    let response = window.location.search;
    const urlParams = new URLSearchParams(response);

    let type = localStorage.getItem("savePaymentTransactionPojo");
    let savePaymentTransactionPojoData = JSON.parse(type);

    let type1 = localStorage.getItem("forSaveApplicantData");
    let forSaveApplicantData = JSON.parse(type1);

    const product = urlParams.get("returndata");
    const transactionId = urlParams.get("txnid");
    const userRegId = urlParams.get("userId");
    let userId = localStorage.getItem("userRegistrationId");
    let convertedJson = atob(product);
    console.log(convertedJson);
    // console.log(convertedJson.get("responseCode"));
    var obj = JSON.parse(convertedJson);
    // var values = Object.keys(obj).map(function (key) { return obj[key]; });
    console.log(obj.responseCode);
    let resp = "";
    let payStatus;
    if (obj.responseCode == "0000") {
      resp = "success";
      this.setState({
        showSuccess: true,
      });
      payStatus = 1;
    } else {
      resp = "fail";
      this.setState({
        showSuccess: false,
      });
      payStatus = 0;
    }

    let documentToSave = {
      amount: forSaveApplicantData.amount,
      applicableFor: forSaveApplicantData.applicableFor,
      applicationDeclareDirectAdmission:
        forSaveApplicantData.applicationDeclareDirectAdmission,
      category: forSaveApplicantData.category,
      delStatus: forSaveApplicantData.delStatus,
      isActive: forSaveApplicantData.isActive,
      isSeatAcceptedByStudent: forSaveApplicantData.isSeatAcceptedByStudent,
      isSeatAllocatedByInstitute:
        forSaveApplicantData.isSeatAllocatedByInstitute,
      minority: forSaveApplicantData.minority,
      paidStatus: payStatus,
      paymentApplicable: forSaveApplicantData.paymentApplicable,
      paymentMode: forSaveApplicantData.paymentMode,
      paymentRef: forSaveApplicantData.paymentRef,
      programTitleId: forSaveApplicantData.programTitleId,
      roundStatus: forSaveApplicantData.roundStatus,
      seatAccepted: forSaveApplicantData.seatAccepted,
      status: forSaveApplicantData.status,
      userDocumentDetailDirectAdmissions:
        forSaveApplicantData.userDocumentDetailDirectAdmissions,
      userQualificationDetailDirectAdmissions:
        forSaveApplicantData.userQualificationDetailDirectAdmissions,
      userRegistration: forSaveApplicantData.userRegistration,
    };

    let acadamicYearId = localStorage.getItem("acadamicYearId");
    let tDate = moment(new Date()).format("DD-MM-YYYY");
    let savePaymentTransactionNewPojo = localStorage.getItem(
      "savePaymentTransactionNewPojo"
    );

    if (payStatus == 1) {
      this.props
        .saveApplicant({
          appliData: documentToSave,
        })
        .then((response) => {
          if (response) {
            this.props
              .getUpdateTransaction({
                id: obj.merchantTxnNo,
                resp: resp,
                product: product,
              })
              .then((response) => {
                if (response) {
                  localStorage.removeItem("savePaymentTransactionPojo");
                  localStorage.removeItem("forSaveApplicantData");
                  localStorage.removeItem("flagTosave");
                  localStorage.removeItem("savePaymentTransactionNewPojo");
                  if (resp == "fail") {
                    this.setState({
                      snakMsg: "Payment Failed",
                      openSnak: true,
                      severity: "error",
                    });
                  } else {
                    this.setState({
                      snakMsg: "Payment Successfull",
                      openSnak: true,
                      severity: "success",
                    });
                  }
                  window.location.replace("/applicationForm");
                } else {
                  window.location.replace("/applicationForm");
                  this.setState({
                    snakMsg: "Payment Failed",
                    openSnak: true,
                  });
                }
              });
          }
        });
    } else {
      this.setState({
        snakMsg: "Payment Failed",
        openSnak: true,
        severity: "error",
      });
    }
  }

  closeSnakbar() {
    this.setState({
      snakMsg: "",
      openSnak: false,
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return (
      <>
        {
          <DirectionSnackbar
            open={this.state.openSnak}
            severity={this.state.severity}
            msg={this.state.snakMsg}
            closeSnak={this.closeSnakbar}
          />
        }
        {this.state.showSuccess && (
          <>
            <body className="payment-success-body">
              <div className="payment-success-card">
                <div
                  style={{
                    borderRadius: "200px",
                    height: "200px",
                    width: "200px",
                    background: "#F8FAF5",
                    margin: "0 auto",
                  }}
                >
                  <i className="payment-success-i">
                    <span className="success-checkmark">✓</span>
                  </i>
                </div>
                <h1 className="payment-success-h1">Success</h1>
                <p className="payment-success-p">
                  Payment successfully done
                  <br />
                </p>
              </div>
            </body>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  userApplication: state.studentDirectAdmission,
});
const mapDispatchToProps = {
  saveApplicant,
  savePaymentTransaction,
  getUpdateTransaction,
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess);
