import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import AttachFile from "../../components/Comman/AttachFile";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicTable from "../../components/Comman/MaterialUIRTTable";
import * as myConstClass from "../../config/messageconstant";
import swal from "sweetalert";
import {
  deleteFile,
  deleteFileData,
  downloadFile,
  openFileInNewTab,
  saveFile,
} from "../UploadFile/file.slice";
let docs;
let count = [];

let fileNameTitle = [];
class AttachmentF extends React.Component {
  state = {
    id: 0,
    addTextField: [],
    labelList: [
      {
        id: "1",
        label: "Guidlines For The Form",
      },
      {
        id: "2",
        label: "Time Table",
      },
      {
        id: "3",
        label: "Schedule of the Form",
      },
      {
        id: "4",
        label: "Other",
      },
    ],
    remark: "",
    label: "",
    fileName: "",
    file: "",
    other: "",
    AttachFileName: [],
    AttachFileTitle: [],
    formErrors: [],
    open: false,
    upload: false,
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  fileChangeHandler = (e, index) => {
    const { value } = e.target;
    fileNameTitle[index] = value;

    this.setState({ AttachFileTitle: fileNameTitle });
  };

  onChangeInEditor = (event, editor) => {
    const data = editor.getData();
    this.setState({ remark: data });
  };

  componentDidMount() {
    const { appliData, deleteFileData } = this.props;
    deleteFileData();
    if (
      appliData.applicationData !== "" &&
      appliData.applicationData.type === 5
    ) {
      if (this.props.row.length === 0) {
        appliData.applicationData.applicationRefDocuments.map((data, index) => {
          let refData = {
            id: index + 1,
            docDesc: data.docDesc,
            documentPath: data.documentPath,
          };
          this.props.rowData(refData);
          return refData;
        });
      }

      this.setState({
        remark:
          appliData.applicationData.undertaking === null
            ? ""
            : appliData.applicationData.undertaking,
        id: appliData.applicationData.id,
      });
    } else {
      this.setState({
        id: appliData.applicationData.id,
      });
    }
  }

  handleFormValidation() {
    const { remark } = this.state;
    let formErrors = {};
    let formIsValid = true;
    if (remark === "") {
      formIsValid = false;
      formErrors["remarkErr"] = myConstClass.remarkMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  changeH = (fileName, name, index) => {
    this.setState({
      ...this.state,
      file: fileName,
    });
  };
  addButtonHandler = () => {
    count.push(1);
    this.setState({ addTextField: count });
  };
  submitHandler = (e) => {
    e.preventDefault();
    const { id, remark } = this.state;

    const fileDataDetails = this.props.row.map((data, index) => {
      let bdata = {
        docDesc: data.docDesc,
        documentPath: data.documentPath,
        delStatus: 1,
      };

      return bdata;
    });
    docs = {
      id: id,
      undertaking: remark,
      applicationRefDocuments: fileDataDetails,
      type: 5,
    };
    // if (this.handleFormValidation()) {
    swal({
      title: "Are you sure?",
      text: "Do you want to submit?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((response) => {
      if (response) {
        this.props.OnSave(docs);
      }
    });
    // }
  };
  uploadImage = () => {
    const { saveFile } = this.props;
    const { file, label } = this.state;
    if (file !== "" && label !== "") {
      this.setState({
        upload: true,
      });
      saveFile({ file: file });
    } else {
      this.setState({
        uploadError: true,
      });
    }
  };
  setOpenDia = (data) => {
    this.setState({
      ...this.state,
      open: data,
    });
  };

  rowViewDocument = (data) => {
    const { openFileInNewTab } = this.props;

    console.log("data");
    console.log(data);
    openFileInNewTab({ file: data.documentPath });
  };

  componentDidUpdate() {
    const { fileData, deleteFileData } = this.props;
    const { label, other } = this.state;
    if (fileData.isFetch === true) {
      let data = {
        docDesc:
          label === "1"
            ? "Guidlines For The Form"
            : label === "2"
            ? "Time Table"
            : label === "3"
            ? "Schedule of the Form"
            : other,
        documentPath: fileData.file,
      };
      this.props.rowData(data);

      deleteFileData();
      this.setState({
        ...this.state,
        label: "",
        file: "",
        upload: false,
        uploadError: false,
      });
    }
  }
  render() {
    const { remarkErr } = this.state.formErrors;
    const { other, remark, label, upload, uploadError, file } = this.state;
    const { row, labelList, rowDelete, fileData } = this.props;
    console.log(labelList);
    return (
      <>
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <form
            onSubmit={this.submitHandler}
            autoComplete="off"
            noValidate={true}
          >
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={2}
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={1}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 18 }}
                    label="Important Instructions :"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={remark}
                    onChange={(event, editor) =>
                      this.onChangeInEditor(event, editor)
                    }
                    onBlur={() => {}}
                    onFocus={() => {}}
                    onReady={(editor) => {
                      editor && editor.setData(remark);
                    }}
                  />
                  <FormHelperText error>{remarkErr} </FormHelperText>
                </Grid>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={12} /> */}
              <Grid
                item
                sm={6}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 18, fontWeight: "medium" }}
                    label="Attachments :"
                  />
                  {uploadError && (
                    <FormHelperText error>
                      {"please select file and label"}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="label"
                      className="text-black"
                      name="label"
                      value={label}
                      onChange={this.changeHandler}
                      size="small"
                      fullWidth={true}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {console.log(labelList)}
                      {labelList.map((data) => {
                        return (
                          <MenuItem key={data.id} value={data.id}>
                            {data.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={5}>
                  {label === "4" && (
                    <TextField
                      size="small"
                      color="primary"
                      name="other"
                      id="other"
                      value={other}
                      onChange={this.changeHandler}
                      fullWidth
                    ></TextField>
                  )}
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 12, color: "#808080" }}
                    label="Note : Attachments attached here will be displayed with the application form"
                  />
                </Grid>
                <Grid item xs={12} sm={1.2} md={1.2}>
                  <AttachFile
                    style={{
                      width: "100%",
                      display: "block",
                      textAlign: "center",
                    }}
                    name={label}
                    fileName={this.changeH}
                  />
                </Grid>
                <Grid item xs={12} sm={1.3} md={1.3}>
                  {file !== "" && (
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="contained"
                      fullWidth={true}
                      {...(label !== "" &&
                        upload &&
                        !fileData.isFetch && { disable: true })}
                      name="Upload"
                      onClick={this.uploadImage}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12} />
              <Grid m={-1} item xs={12} sm={4} md={12}>
                {/* <AttachmentTable
                  data={row}
                  rowDelete={rowDelete}
                /> */}
                <DynamicTable
                  data={row}
                  tableHead={[
                    {
                      name: "docDesc",
                      title: "Document Name",

                      positionCenter: false,
                      showInscreen: true,
                    },

                    {
                      name: "action",
                      title: "Actions",
                      positionCenter: false,
                      showInscreen: true,
                    },
                  ]}
                  rowViewDocument={this.rowViewDocument}
                  showViewDocument={true}
                  rowDelete={rowDelete}
                  showHeadDelete={true}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={12} />

              <Grid item xs={12} sm={6} md={12} />
              <Grid item xs={2} md={12} container justifyContent="flex-end">
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Save"
                />
              </Grid>
            </Grid>
          </form>
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  appliData: state.directAdmission,
  fileData: state.file,
});

const mapDispatchToProps = {
  saveFile,
  downloadFile,
  deleteFileData,
  openFileInNewTab,
  deleteFile,
};
export default connect(mapStateToProps, mapDispatchToProps)(AttachmentF);
