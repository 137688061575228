import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicTable from "../../components/Comman/MaterialUIRTTable";
import MultipleSelect from "../../components/Comman/MultipleSelect";
import {
  deleteBranchInstituteData,
  getInstituteCoruseById,
} from "../BranchMaster/branch.slice";
import { getAlert } from "../../Alert/alert.slice";
import * as myConstClass from "../../config/messageconstant";
import GetProgDetails from "../BranchMaster/GetProgDetails";
import { saveInstituteCourse } from "./instituteCourse.slice";
let columns;
let cList = [];

const InstituteCourseForm = ({
  instData,
  DocRows,
  data,
  deleteBranchInstituteData,
  getInstituteCoruseById,
  onCancel,
  rowDelete,
  progDetails,
  saveInstituteCourse,
  instituteList,
}) => {
  const [inst, setInst] = React.useState([]);
  const [type, setType] = React.useState([]);
  const [save, setSave] = React.useState(false);

  const [updatedlist, setUpdatedlist] = React.useState([]);

  const columnExtensions = [
    { columnName: "index", width: 100, align: "center" },
    { columnName: "instName", width: 700 },
  ];

  const multiSelectHandler = (value, name) => {
    setType(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    if (instituteList.isFetchInstitute === true) {
      cList = [];
      instituteList.institute.map((br) => {
        const editedRow = DocRows.filter((row) => row.tenantId === br.id);

        if (editedRow.length == 0) {
          let data1 = {
            name: br.name,
            id: br.id,
          };
          cList.push(data1);
        }

        return data;
      });

      setInst(cList);
    }
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();

    let eData = type.map((data1) => {
      let docs = {
        programTitle: {
          id: progDetails[0].id,
        },
        tenant: {
          id: data1,
        },
        isActive: 1,
      };
      return docs;
    });

    setSave(true);
    saveInstituteCourse({ instituteCourseData: eData }).then((resp) => {
      if (!resp) {
        getAlert({ message: myConstClass.serverMsg });
      } else {
        getInstituteCoruseById({ branchId: progDetails[0].id }).then(
          (respo) => {
            if (!respo) {
              getAlert({ message: myConstClass.serverMsg });
            }
          }
        );
        setType([]);
        // setOpenSnak(true);
        // setSnakMsg("Data save successfully");
      }
    });
  };
  return (
    <div>
      <Grid container justifyContent="space-evenly" direction="row" spacing={1}>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          container
          justifyContent="space-evenly"
        >
          <Grid
            item
            sm={8}
            xs={8}
            md={10}
            container
            justifyContent="flex-start"
          >
            <Typography sx={{ fontSize: "1.1rem" }}>
              Institute Configuration
            </Typography>
          </Grid>
          <Grid
            item
            sm={4}
            xs={4}
            md={2}
            sx={{ display: "inline-block" }}
            container
          >
            <ButtonCompo
              size="small"
              sx={{ float: "right" }}
              type="button"
              variant="outlined"
              name=" Back to List"
              onClick={onCancel}
            />
          </Grid>
        </Grid>
        &nbsp;&nbsp;
        <GetProgDetails progDetails={progDetails} />
        &nbsp;&nbsp;
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={1}
          container
          justifyContent="space-evenly"
        >
          <Grid item xs={12} sm={12} md={12}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 16 }}
              label="Select Institute : "
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <MultipleSelect
              // er={courseErr}
              name1="type"
              value1={type}
              names={inst}
              onChange={multiSelectHandler}
            />
          </Grid>
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={2}
          columnSpacing={{ xs: 12, sm: 6, md: 1 }}
          container
          justifyContent="right"
        >
          <Grid
            item
            sm={12}
            xs={12}
            md={1}
            lg={1}
            container
            justifyContent="space-evenly"
          >
            <ButtonCompo
              size="medium"
              type="submit"
              variant="contained"
              name="Submit"
              onClick={submitHandler}
              fullWidth={true}
            />
          </Grid>
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          container
          justifyContent="space-evenly"
        >
          <Grid item sm={12} xs={12} md={12} lg={12}>
            <DynamicTable
              data={DocRows}
              tableHead={[
                {
                  title: "Sr. No.",
                  name: "index",
                  positionCenter: true,
                  alignCenter: "center",
                  showInscreen: true,
                },
                {
                  title: "Institute name",
                  name: "instName",
                  positionCenter: false,
                  showInscreen: true,
                },
                {
                  title: "Action",
                  name: "action",
                  positionCenter: false,
                  alignCenter: "right",
                  showInscreen: true,
                },
              ]}
              rowDelete={rowDelete}
              showHeadDelete={true}
            />
          </Grid>
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={2}
          columnSpacing={{ xs: 12, sm: 6, md: 1 }}
          container
          justifyContent="right"
        >
          <Grid
            item
            sm={12}
            xs={12}
            md={1}
            lg={1}
            container
            justifyContent="space-evenly"
          >
            <ButtonCompo
              size="medium"
              type="button"
              variant="outlined"
              name="Cancel"
              fullWidth={true}
              onClick={onCancel}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  instituteList: state.institute,
});

const mapDispatchToProps = {
  deleteBranchInstituteData,
  saveInstituteCourse,
  getInstituteCoruseById,
  getAlert,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstituteCourseForm);
