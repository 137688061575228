import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { connect } from "react-redux";
import {
  ChipCompo,
  MyComponentWithIconProps,
} from "../../components/Comman/IconButton";

function Row({ row, rowStatus, rowEdit, rowDelete, classInfo, getClassById }) {
  const changeStatus = (e, id) => {
    e.stopPropagation();
    rowStatus(id);
  };
  const onClickEdit = (e, id) => {
    e.stopPropagation();
    rowEdit(id);
  };
  const onClickDelete = (e, id) => {
    e.stopPropagation();
    rowDelete(id);
  };

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {});

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="td" scope="row">
          <center>{row.index}</center>
        </TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell align="right">{row.generalPer}</TableCell>
        <TableCell align="right">{row.malePer}</TableCell>
        <TableCell align="right">{row.famalePer}</TableCell>
        <TableCell align="right">{row.otherPer}</TableCell>
        <TableCell>
          {row.isActive === "Active" ? (
            <ChipCompo
              label="Active"
              variant="outlined"
              size="small"
              color="success"
              onClick={(e) => changeStatus(e, row.id)}
            />
          ) : (
            <ChipCompo
              label="Inactive"
              variant="outlined"
              size="small"
              color="error"
              onClick={(e) => changeStatus(e, row.id)}
            />
          )}
        </TableCell>

        <TableCell>
          <MyComponentWithIconProps
            statusImage={EditIcon}
            color="primary"
            fontSize="medium"
            title="Edit"
            onClick={(e) => onClickEdit(e, row.id)}
          />
          <MyComponentWithIconProps
            statusImage={DeleteIcon}
            color="error"
            fontSize="medium"
            title="Delete"
            onClick={(e) => onClickDelete(e, row.id)}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <left>Sr. No.</left>
                    </TableCell>
                    <TableCell>Category Name</TableCell>
                    <TableCell>Percentage</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.reservationStructureDetail.map((historyRow, index) => (
                    <TableRow key={historyRow.id}>
                      <TableCell component="td" scope="row">
                        <center>{index + 1}</center>
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {historyRow.category.name}
                      </TableCell>

                      <TableCell component="td" scope="row" align="right">
                        {historyRow.per}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function CollapsibleTable({
  rowStatus,
  rowEdit,
  rowDelete,
  getClassById,
  classInfo,
  data,
}) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="left">Sr. No.</TableCell>
            <TableCell>Reservation Name</TableCell>
            <TableCell>General Percentage</TableCell>
            <TableCell>Male Percentage</TableCell>

            <TableCell>Female Percentage</TableCell>
            <TableCell>Other Percentage</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <Row
              key={row.className}
              row={row}
              rowStatus={rowStatus}
              rowEdit={rowEdit}
              rowDelete={rowDelete}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CollapsibleTable);
