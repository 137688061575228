import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { decodeData } from "../../components/Comman/Util/Base64";
import endpoints from "../../config/endpoints";
import { tokenExp } from "../Login/login.slice";

const initialState = {
  meritList: [],
  meritData: [],
  isFetchList: false,
  isFetchData: false,
  isServerFail: false,
};
let URL = endpoints.generatemeritlist;
const generateMeritListSlice = createSlice({
  name: "meritlist",
  initialState,
  reducers: {
    meritListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        meritList: row,
        isFetchList: true,
      };
    },
    meritListFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isFetchList: false,
        meritList: [],
      };
    },
    meritDataSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        meritData: row,
        isFetchData: true,
      };
    },
    meritDataFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isFetchData: false,
        meritData: [],
      };
    },
    serverFailed: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: true,
      };
    },
    resetDeleteSuccess : (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: false,
     
      };
    },
  },
});

export const {
  meritListSuccess,
  meritListFailure,
  meritDataSuccess,
  meritDataFailure,
  serverFailed,
  resetDeleteSuccess
} = generateMeritListSlice.actions;

export default generateMeritListSlice.reducer;

export const getMeritList =
  ({ appDecId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);

      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      let doc = await axios
        .get(URL + "/" + appDecId + "/application-declare", { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          console.log(response);
          if (response.data.error == false) {
            let data = response.data.data;
            let row = [];
            let index = 0;
            data.map((data1) => {
              index = index + 1;
              let bData = {
                index: index,
                applicationDeclareId: data1.applicationDeclareId,
                id: data1.id,
                name: data1.name,
                type:data1.type===0?'Provisional':'Final Merit List'
              };
              row.push(bData);
            });
            dispatch(meritListSuccess({ row }));
            return row;
          } else {
            console.log("%%%%%%%%%%%%");
            // dispatch(naviLogin({ row }));
          }
        })

        .catch((error) => {
          dispatch(serverFailed());

          console.log("Error getbranch========>", error);
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());

      return false;
    }
  };

export const saveMeritList =
  ({ id, meritname, type }) =>
  async (dispatch) => {
    try {
      //   let data = JSON.stringify(meritData);

      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      let doc = await axios
        .post(
          URL +
            "?application_declare_id=" +
            id +
            "&name=" +
            meritname +
            "&type=" +
            type,
          {},
          { headers }
        )
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          console.log("Success With saveMeritList ========>", response.data);
          const { data } = response.data;
          return data;
        })
        .catch((error) => {
          dispatch(serverFailed());

          console.log("Error saveMeritList ========>", error.response.data);
        });
        return doc
    } catch (e) {
      dispatch(serverFailed());

      return console.error(e.message);
    }
  };

export const reserMerit = () => async (dispatch) => {
  try {
    dispatch(meritListFailure());
  } catch (e) {
    return console.error(e.message);
  }
};
export const resetDelete = () => async (dispatch) => {
  try {
    dispatch(resetDeleteSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
