import {
  Card,
  CardContent,
  Divider,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import * as myConstClass from "../../config/messageconstant";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import React from "react";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";

const program = [
  {
    id: 1,
    progName: "be",
  },
  {
    id: 1,
    progName: "BSC",
  },
  {
    id: 1,
    progName: "BTech",
  },
  {
    id: 1,
    progName: "be",
  },
  {
    id: 1,
    progName: "BSC",
  },
  {
    id: 1,
    progName: "BTech",
  },
];
let docs;

class PriorityProcess extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    id: 0,
    courseOrInstitute: 0,
    nonMiniority: false,
    miniority: false,
    UniversityManaged: false,
    Government: false,
    GovernmentAided: false,
    unAided: false,
    nonAutonomous: false,
    autonomous: false,
    formErrors: [],
  };

  changeHandler = (event) => {
    const { name, value, checked } = event.target;
    if (name == "courseOrInstitute") {
      this.setState({
        ...this.state,
        [name]: value,
      });
    } else {
      this.setState({
        ...this.state,
        [name]: checked,
      });
    }
  };
  componentDidMount() {
    const { choiceCodeData } = this.props;
    if (Object.keys(choiceCodeData).length !== 0) {
      this.setState({
        courseOrInstitute: choiceCodeData.courseOrInstitute,
        nonMiniority: choiceCodeData.nonMiniority,
        miniority: choiceCodeData.miniority,
        UniversityManaged: choiceCodeData.UniversityManaged,
        Government: choiceCodeData.Government,
        GovernmentAided: choiceCodeData.GovernmentAided,
        unAided: choiceCodeData.unAided,
        nonAutonomous: choiceCodeData.nonAutonomous,
        autonomous: choiceCodeData.autonomous,
      });
      console.log(choiceCodeData);
    } else {
      console.log("choiceCodeData");
    }
  }

  submitHandler = (e) => {
    e.preventDefault();
    const {
      UniversityManaged,
      nonMiniority,
      miniority,
      nonAutonomous,
      autonomous,
      Government,
      GovernmentAided,
      unAided,
      autoStatus,
      inst,
      courseOrInstitute,
    } = this.state;
    console.log(this.state);

    docs = {
      courseOrInstitute: courseOrInstitute,
      UniversityManaged: UniversityManaged,
      nonMiniority: nonMiniority,
      miniority: miniority,
      nonAutonomous: nonAutonomous,
      autonomous: autonomous,
      GovernmentAided: GovernmentAided,
      Government: Government,
      unAided: unAided,
    };
    console.log(docs);
    if (this.handleFormValidation()) {
      this.props.PriorityProcessData(docs);
    }
  };

  handleFormValidation() {
    const {
      UniversityManaged,
      nonMiniority,
      miniority,
      nonAutonomous,
      autonomous,
      Government,
      GovernmentAided,
      unAided,
      autoStatus,
      courseOrInstitute,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;
    if (
      UniversityManaged === false &&
      nonMiniority === false &&
      miniority === false &&
      nonAutonomous === false &&
      autonomous === false &&
      GovernmentAided === false &&
      unAided === false &&
      Government === false
    ) {
      formIsValid = false;
      formErrors["selectErr"] = myConstClass.SelectInstType;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  render() {
    const {
      UniversityManaged,
      nonMiniority,
      miniority,
      nonAutonomous,
      autonomous,
      Government,
      GovernmentAided,
      unAided,
      autoStatus,
      inst,
      courseOrInstitute,
    } = this.state;
    const { selectErr } = this.state.formErrors;
    const { onCancel } = this.props;
    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              spacing={2}
              columnSpacing={1}
              container
              sx={{ m: 0.5 }}
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                {/* <Grid item xs={12} sm={12} md={0.5}></Grid> */}
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 20 }}
                    label="Option Form for CAP Round 1"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={1}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={1}
                // columnSpacing={{xs:12, sm:6, md:1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{
                      fontSize: 18,
                      color: "black",
                      fontFamily: "Poppins",
                    }}
                    label="Instructions :"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Box display="flex" justifyContent="space-between ">
                    <Box>
                      <Card
                        variant="outlined"
                        sx={{
                          color: "#ed1c24",
                          fontFamily: "poppins",
                          mt: 1,
                          p: 1,
                        }}
                      >
                        <CardContent style={{ lineHeight: "34px" }}>
                          <Grid>
                            <Typography variant="span">a)</Typography>
                            &emsp;
                            <Typography variant="span">
                              The Photo should be Colorful. Black and White
                              Photo should not be Accepted.
                            </Typography>
                          </Grid>

                          <Grid>
                            <Typography variant="span">b)</Typography>
                            &emsp;
                            <Typography variant="span">
                              Size of the Photograph should be in between 20KB
                              to 100KB.
                            </Typography>
                          </Grid>

                          <Grid>
                            <Typography variant="span">c)</Typography>
                            &emsp;
                            <Typography variant="span">
                              The photograph and Signature Should be Scanned.
                            </Typography>
                          </Grid>

                          <Grid>
                            <Typography variant="span">d)</Typography>
                            &emsp;
                            <Typography variant="span">
                              The Photograph Dimension should be of 4.5 cm * 3.5
                              cm
                            </Typography>
                          </Grid>

                          <Grid>
                            <Typography variant="span">e)</Typography>
                            &emsp;
                            <Typography variant="span">
                              Photo Should be without CAP, Googles and both ears
                              should be visible.
                            </Typography>
                          </Grid>

                          <Grid>
                            <Typography variant="span">f)</Typography>
                            &emsp;
                            <Typography variant="span">
                              The Signature should be of black and blue ink.
                            </Typography>
                          </Grid>

                          <Grid>
                            <Typography variant="span">g)</Typography>
                            &emsp;
                            <Typography variant="span">
                              The Signature should be in the box only. Outside
                              of the box or on the box will not be accepted.
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography variant="span">h)</Typography>
                            &emsp;
                            <Typography variant="span">
                              The Signature should be on blank page.
                            </Typography>
                          </Grid>

                          <Grid>
                            <Typography variant="span">i)</Typography>
                            &emsp;
                            <Typography variant="span">
                              Size of the Signature Should be in between 20 KB
                              to 50 KB.
                            </Typography>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Box>
                  </Box>
                </Grid>
                <br />
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, fontWeight: 500 }}
                  label="Select Institute Type :"
                />
              </Grid>
              <Grid m={-1} item xs={12} sm={6} md={6}>
                <FormGroup aria-label="position" row />
                <FormControlLabel
                  name="Government"
                  value="0"
                  control={<Checkbox />}
                  label="Government"
                  checked={Government}
                  labelPlacement="right"
                  onChange={this.changeHandler}
                />
              </Grid>
              <Grid m={-1} item xs={12} sm={6} md={6}>
                <FormGroup aria-label="position" row />
                <FormControlLabel
                  name="GovernmentAided"
                  value="1"
                  control={<Checkbox />}
                  label="Government Aided"
                  checked={GovernmentAided}
                  labelPlacement="right"
                  onChange={this.changeHandler}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormGroup aria-label="position" row />
                <FormControlLabel
                  name="unAided"
                  value="2"
                  control={<Checkbox />}
                  label="Un-Aided"
                  checked={unAided}
                  labelPlacement="right"
                  onChange={this.changeHandler}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormGroup aria-label="position" row />
                <FormControlLabel
                  name="UniversityManaged"
                  value="3"
                  control={<Checkbox />}
                  checked={UniversityManaged}
                  label="University managed"
                  labelPlacement="right"
                  onChange={this.changeHandler}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, fontWeight: 500 }}
                  label="Select Autonomous Status You are looking for :"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormGroup aria-label="position" row />
                <FormControlLabel
                  value="0"
                  control={<Checkbox />}
                  name="autonomous"
                  label="Autonomous"
                  checked={autonomous}
                  labelPlacement="right"
                  onChange={this.changeHandler}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormGroup aria-label="position" row />
                <FormControlLabel
                  value="1"
                  name="nonAutonomous"
                  control={<Checkbox />}
                  checked={nonAutonomous}
                  label="Non-Autonomous"
                  labelPlacement="right"
                  onChange={this.changeHandler}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, fontWeight: 500 }}
                  label="Select Minority Status of Institute You are looking for :"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormGroup aria-label="position" row />
                <FormControlLabel
                  value="0"
                  control={<Checkbox />}
                  name="miniority"
                  label="Miniority"
                  checked={miniority}
                  labelPlacement="right"
                  onChange={this.changeHandler}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormGroup aria-label="position" row />
                <FormControlLabel
                  value="1"
                  control={<Checkbox />}
                  name="nonMiniority"
                  label="Non-Miniority"
                  checked={nonMiniority}
                  labelPlacement="right"
                  onChange={this.changeHandler}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, fontWeight: 500 }}
                  label="Do you want to filter on the basis of institutes :"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="upi"
                    name="courseOrInstitute"
                    value={courseOrInstitute}
                    onChange={this.changeHandler}
                    row
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Courses"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="Institutes"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {selectErr && <FormHelperText error>{selectErr} </FormHelperText>}
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={1}
                lg={1}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Back"
                  onClick={onCancel}
                  fullWidth={true}
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={2.5}
                lg={2.5}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Save and Proceed"
                  fullWidth={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}
export default PriorityProcess;
