import { Grid, Paper, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import FormHelperText from "@mui/material/FormHelperText";
import * as myConstClass from "../../config/messageconstant";
import { onlyNumber } from "../../components/Comman/Util/Validations";
import InstituteDefinedTable from "../../components/Comman/CheckBoxSelectionTable";
import UserDefinedTable from "../../components/Comman/CheckBoxSelectionTable";
// import InstituteDefinedTable from  "./InstituteDefinedTable";
// import UserDefinedTable from "./UserDefinedTable";
let columns;
let columns1;
const QualificationCriteria = ({ appliData, data, rows, onCancel }) => {
  const [selection, setSelection] = useState([]);
  const [list, setList] = useState([]);
  const [selectionInst, setSelectionInst] = useState([]);
  const [instList, setInstList] = useState([]);
  const [instD, setInstD] = useState([]);
  const [userD, setuserD] = useState([]);
  const [isEmptyList, setIsEmptyList] = useState(false);
  useEffect(() => {
    if (
      appliData.applicationData !== "" &&
      appliData.applicationData.type >= 3
    ) {
      let qualiRows = rows.map((data, rowIndex) => {
        let rowData = "";
        let qualiData =
          appliData.applicationData.applicationElegibilityDetails.filter(
            (row) => row?.qualificationId === data.id
          );

        if (qualiData.length != 0) {
          rowData = {
            id: data.id,
            index: data.index,
            isActive: data.isActive,
            qualificationName: data.qualificationName,
            qualificationType: data.qualificationType,
            seqNO: qualiData[0].marksSequenceOrderNo,
            seqNOValid: data.seqNOValid,
            sortOrderNo: data.sortOrderNo,
            weightage: qualiData[0].weightagePer,
            weightageValid: data.weightageValid,
          };
        }

        if (rowData != "") {
          return rowData;
        }
        return data;
      });

      let UserFill = qualiRows.filter(
        (row) => row?.qualificationType === "UserFill"
      );

      let TrustFill = qualiRows.filter(
        (row) => row?.qualificationType === "TrustFill"
      );

      UserFill.map((user, index) => {
        let userdata =
          appliData.applicationData.applicationElegibilityDetails.filter(
            (row) => row?.qualificationId === user.id
          );
        if (userdata.length != 0) {
          selection.push(user.id);
          list.push(user);
        }
      });
      TrustFill.map((trust, index) => {
        let trustdata =
          appliData.applicationData.applicationElegibilityDetails.filter(
            (row) => row?.qualificationId === trust.id
          );
        if (trustdata.length != 0) {
          selectionInst.push(trust.id);
          instList.push(trust);
        }
      });
      setuserD(UserFill);
      setInstD(TrustFill);
    } else {
      setuserD(rows.filter((row) => row?.qualificationType === "UserFill"));
      setInstD(rows.filter((row) => row?.qualificationType === "TrustFill"));
    }
  }, []);
  const columnExtensions = [
    { columnName: "index", width: "100%", align: "center" },
    { columnName: "qualificationName", width: 350 },
    { columnName: "mandatoryOptional", width: 350 },
    { columnName: "remarks" },
  ];
  const changeseqNOUserHandler = (id, e) => {
    const { value } = e.target;
    setuserD((user) =>
      user?.map((list, index) =>
        list.id === id ? { ...list, seqNO: value } : list
      )
    );
  };

  const changeWeightageUserHandler = (id, e) => {
    const { value } = e.target;
    setuserD((user) =>
      user?.map((list, index) =>
        list.id === id ? { ...list, weightage: value } : list
      )
    );
  };
  columns = [
    { field: "index", minWidth: "10%", headerName: "Sr No" },
    { field: "qualificationName", headerName: "Qualification" },
    {
      field: "seqNO",
      headerName: "Sequence No",
      minWidth: "20%",
      // renderCell: (params) => {

      // return (
      //   <div>
      //     <TextField
      //       color="primary"
      //       name={"seqNO" + params.row.id}
      //       size="small"
      //       autoComplete="off"
      //       value={params.row.seqNO}
      //       {...(params.row.seqNOValid === 1
      //         ? { error: true, helperText: "please enter sequence number" }
      //         : params.row.seqNOValid === 2
      //         ? {
      //             error: true,
      //             helperText: "please enter Valid sequence number",
      //           }
      //         : "")}
      //       //onChange={(e) => changeHandler(params.row.id, e)}
      //     />
      //   </div>
      // );
      // },
    },
    {
      field: "weightage",
      headerName: "Weightage",
      minWidth: "20%",
      // renderCell: (params) => {

      //   return (
      //     <div>
      //       <TextField
      //         color="primary"
      //         name={"weightage" + params.row.id}
      //         size="small"
      //         autoComplete="off"
      //         value={params.row.weightage}
      //         {...(params.row.weightageValid === 1
      //           ? { error: true, helperText: "please enter weightage" }
      //           : params.row.weightageValid === 2
      //           ? {
      //               error: true,
      //               helperText: "please enter weightage in number",
      //             }
      //           : "")}
      //         onChange={(e) => changeHandler(params.row.id, e)}
      //       />
      //     </div>
      //   );
      // },
    },
  ];
  const changeseqNOInstHandler = (id, e) => {
    const { value } = e.target;
    setInstD((user) =>
      user?.map((list, index) =>
        list.id === id ? { ...list, seqNO: value } : list
      )
    );
  };
  const changeWeightageInstHandler = (id, e) => {
    const { value } = e.target;
    setInstD((user) =>
      user?.map((list, index) =>
        list.id === id ? { ...list, weightage: value } : list
      )
    );
  };
  columns1 = [
    { field: "index", minWidth: "10%", headerName: "Sr No" },
    { field: "qualificationName", headerName: "Qualification", minWidth: 300 },
    {
      field: "seqNO",
      headerName: "Sequence No",
      minWidth: "20%",
      renderCell: (params) => {
        return (
          <div>
            <TextField
              color="primary"
              name={"remarks" + params.row.id}
              size="small"
              autoComplete="off"
              value={params.row.seqNO}
              {...(params.row.seqNOValid === 1
                ? { error: true, helperText: "please enter sequence number" }
                : params.row.seqNOValid === 2
                ? {
                    error: true,
                    helperText: "please enter Valid sequence number",
                  }
                : "")}
              // onChange={(e) => changeHandler(params.row.id, e)}
            />
          </div>
        );
      },
    },
    {
      field: "weightage",
      headerName: "Weightage",
      minWidth: "20%",
      renderCell: (params) => {
        return (
          <div>
            <TextField
              color="primary"
              name={"weightage" + params.row.id}
              size="small"
              autoComplete="off"
              value={params.row.weightage}
              {...(params.row.weightageValid === 1
                ? { error: true, helperText: "please enter weightage" }
                : params.row.weightageValid === 2
                ? {
                    error: true,
                    helperText: "please enter weightage in number",
                  }
                : "")}
              // onChange={(e) => changeHandler(params.row.id, e)}
            />
          </div>
        );
      },
    },
  ];
  const getRow = (e) => {
    setSelection(e);

    let selectedList = [];

    e.forEach((data) => {
      const selRow = userD.filter((row, index) => {
        return row.id === data;
      });
      selectedList = [...selectedList, selRow[0]];
    });
    console.log(selectedList);
    setList(selectedList);
  };

  const getInstRow = (e) => {
    setSelectionInst(e);

    let selectedList = [];
    e.forEach((data) => {
      const selRow = instD.filter((row, index) => {
        return row.id === data;
      });
      selectedList = [...selectedList, selRow[0]];
    });

    setInstList(selectedList);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    let selData = [];

    list.map((selectedRow, selIndex) => {
      const a = userD.filter((item, index) => item.id === selectedRow.id);

      let userDSqlNo = a[0].seqNO;
      let userDWeightage = a[0].weightage;
      if (userDSqlNo === "" || userDWeightage === "") {
        if (userDSqlNo === "") {
          setuserD((user) =>
            user?.map((list, index) =>
              list.id === selectedRow.id ? { ...list, seqNOValid: 1 } : list
            )
          );
        } else {
          setuserD((user) =>
            user?.map((list, index) =>
              list.id === selectedRow.id ? { ...list, weightageValid: 1 } : list
            )
          );
        }
      } else if (!onlyNumber.test(userDSqlNo) && userDSqlNo !== "") {
        setuserD((user) =>
          user?.map((list, index) =>
            list.id === selectedRow.id ? { ...list, seqNOValid: 2 } : list
          )
        );
      } else if (!onlyNumber.test(userDWeightage) && userDWeightage !== "") {
        setuserD((user) =>
          user?.map((list, index) =>
            list.id === selectedRow.id ? { ...list, weightageValid: 2 } : list
          )
        );
      } else {
        setuserD((user) =>
          user?.map((list, index) =>
            list.id === selectedRow.id
              ? { ...list, seqNOValid: 0, weightageValid: 0 }
              : list
          )
        );

        let qualificationData = {
          qualificationId: selectedRow.id,
          marksSequenceOrderNo: userDSqlNo,
          weightagePer: userDWeightage,
        };
        selData = [...selData, qualificationData];
      }
      return selData;
    });
    instList.map((selectedRow, selIndex) => {
      const a = instD.filter((item, index) => item.id === selectedRow.id);
      let userDSqlNo = a[0].seqNO;
      let userDWeightage = a[0].weightage;
      if (userDSqlNo === "" || userDWeightage === "") {
        if (userDSqlNo === "") {
          setInstD((user) =>
            user?.map((list, index) =>
              list.id === selectedRow.id ? { ...list, seqNOValid: 1 } : list
            )
          );
        } else {
          setInstD((user) =>
            user?.map((list, index) =>
              list.id === selectedRow.id ? { ...list, weightageValid: 1 } : list
            )
          );
        }
      } else if (!onlyNumber.test(userDSqlNo) && userDSqlNo !== "") {
        setInstD((user) =>
          user?.map((list, index) =>
            list.id === selectedRow.id ? { ...list, seqNOValid: 2 } : list
          )
        );
      } else if (!onlyNumber.test(userDWeightage) && userDWeightage !== "") {
        setInstD((user) =>
          user?.map((list, index) =>
            list.id === selectedRow.id ? { ...list, weightageValid: 2 } : list
          )
        );
      } else {
        setInstD((user) =>
          user?.map((list, index) =>
            list.id === selectedRow.id
              ? { ...list, seqNOValid: 0, weightageValid: 0 }
              : list
          )
        );

        let instdata = {
          qualificationId: selectedRow.id,
          marksSequenceOrderNo: userDSqlNo,
          weightagePer: userDWeightage,
        };
        selData = [...selData, instdata];
      }

      return selData;
    });
    if (selData.length === list.length + instList.length) {
      if (selData.length !== 0) {
        data(selData);
      } else {
        console.log("Please Select atleast one qualification");
        setIsEmptyList(true);
      }
    }
  };
  return (
    <>
      <Grid
        item
        sm={12}
        xs={12}
        md={12}
        lg={12}
        rowSpacing={1}
        // columnSpacing={{xs:12, sm:6, md:1}}
        container
        justifyContent="space-evenly"
      >
        <Grid item xs={12} sm={12} md={12}>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 12, color: "#808080" }}
            label=" Note: "
          />
        </Grid>
        <Grid sx={{ m: -1 }} item xs={12} sm={12} md={12}>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 12, color: "#808080" }}
            label="1. Sequence number: It indicates qualification's priority of comparision while making merit list e.g.priority 1 of all students will compare first and if it's equal then system will compare priority 2 paramenter and so on."
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 12, color: "#808080" }}
            label="2. Weightage: It indicates qualification's consideration while preparing merit list. Incase of weightage distribution sequence number of eligible qualification should be same"
          />
        </Grid>
        <Grid sx={{ m: -1 }} item xs={12} sm={12} md={12}>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 12, color: "#808080" }}
            label="3. Incase of applying more than 1 qualification parameter to consider for comparison and merit list generation different weightages can be used."
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 12, color: "#808080" }}
            label="4. Incase of different weightage distribution, sequence number of eligible qualification should be same."
          />
        </Grid>
      </Grid>
      <br />
      {isEmptyList === true && (
        <FormHelperText error>{myConstClass.qualiMsg}</FormHelperText>
      )}
      <b>
        <LabelCompo
          className="text-black"
          style={{ fontSize: 16 }}
          label="Qualification - User Entered"
        />
      </b>
      <br />
      <LabelCompo
        className="text-black"
        style={{ fontSize: 12, color: "#808080" }}
        label="Note: Select qualifications expected to be filled by applicants. "
      />

      <br></br>
      <br></br>
      <div align="center">
        <UserDefinedTable
          rows={userD}
          columns={columns}
          changeseqNOHandler={changeseqNOUserHandler}
          changeWeightageHandler={changeWeightageUserHandler}
          selectedIndexData={getRow}
          selection={selection}
        />
        <br></br>
      </div>
      <b>
        <LabelCompo
          className="text-black"
          style={{ fontSize: 16 }}
          label="Qualification - Institute Entered"
        />
      </b>
      <br />
      <LabelCompo
        className="text-black"
        style={{ fontSize: 12, color: "#808080" }}
        label="Note: Select qualification's expected to be filled by members internally. e.g. Viva test marks ,etc"
      />

      <br></br>
      <br></br>
      <div align="center">
        <InstituteDefinedTable
          // selection={selectionInst}
          // onSelectionChange={getInstRow}
          // columnExtensions={columnExtensions}
          rows={instD}
          columns={columns1}
          changeseqNOHandler={changeseqNOInstHandler}
          changeWeightageHandler={changeWeightageInstHandler}
          selectedIndexData={getInstRow}
          selection={selectionInst}
        />
        <br></br>

        <>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            m={2}
            rowSpacing={1}
            columnSpacing={{ xs: 12, sm: 6, md: 1 }}
            container
            justifyContent="right"
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={1}
              lg={1}
              container
              justifyContent="space-evenly"
            >
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name="Back"
                fullWidth={true}
                onClick={onCancel}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={2.5}
              lg={2.5}
              container
              justifyContent="space-evenly"
            >
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Save and Proceed"
                fullWidth={true}
                onClick={submitHandler}
              />
            </Grid>
          </Grid>
        </>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  appliData: state.directAdmission,
});

const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QualificationCriteria);
