import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { decodeData } from "../../components/Comman/Util/Base64";
import endpoints from "../../config/endpoints";
import { tokenExp } from "../Login/login.slice";
const initialState = {
  branch: [],
  branchData: [],
  InstBranch: [],
  ShortNameData: [],
  InstCourse: [],
  isFetch: false,
  isFetchInstBranch: false,
  isFetchShortName: false,
  isFetchBranch: false,
  isFetchInstBranch: false,
  branchDetails: {},
  isSave: false,
  branchFilter: [],
  isFetchBranchById: false,
  branchById: {},
  isFilter: false,
  error: false,
  isDelete: false,
  isServerFail: false,
  programTitleByProgramIdAndInstId: [],
  programTitleByProgramIdAndInstIdFetch: false,
};
let URL = endpoints.course;
const branchSlice = createSlice({
  name: "branch",
  initialState,
  reducers: {
    programTitleDeleteSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error: false,
        isDelete: true,
      };
    },
    programTitleDeleteFail: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error: true,
        isDelete: true,
        isSave: false,
      };
    },
    branchSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        branch: row,
        isFetchBranch: true,
      };
    },
    InstbranchSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        InstCourse: row,
        error: false,
        isDelete: false,
        isFetchInstBranch: true,
      };
    },
    InstbranchFail: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        InstCourse: [],
        isFetchInstBranch: false,
        error: false,
        isDelete: false,
      };
    },

    branchFilterSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        branchFilter: row,
        isFilter: true,
        error: false,
        isDelete: false,
      };
    },
    branchFilterFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        branchFilter: [],
        isFilter: false,
        error: false,
        isDelete: false,
      };
    },
    branchFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isFetchBranch: false,
        error: false,
        isDelete: false,
        branch: [],
      };
    },

    getInstByBrIdSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        InstBranch: data,
        isFetchInstBranch: true,
        error: false,
        isDelete: false,
      };
    },
    getBranchIdSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        branchById: data,
        isFetchBranchById: true,
        error: false,
        isDelete: false,
      };
    },
    getBranchIdFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        branchById: {},
        isFetchBranchById: false,
        error: false,
        isDelete: false,
      };
    },
    branchFetched: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error: false,
        isDelete: false,
        isFetchBranch: false,
      };
    },
    branchDetailsSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        branchDetails: data,
        isSave: true,
        error: false,
        isDelete: false,
      };
    },
    branchDetailsFail: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        branchDetails: {},
        isSave: false,
        error: false,
        isDelete: false,
      };
    },
    naviLogin: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error: false,
        isDelete: false,
        branch: [],
      };
    },
    branchListByNameSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        branchData: data,
        isFetch: true,
        error: false,
        isDelete: false,
      };
    },
    branchListByNameFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        branchData: [],
        isFetch: false,
        error: false,
        isDelete: false,
      };
    },
    branchListByShortNameSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        isFetchShortName: true,
        ShortNameData: data,
        error: false,
        isDelete: false,
      };
    },
    branchListByShortNameFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isFetchShortName: false,
        ShortNameData: [],
        error: false,
        isDelete: false,
      };
    },
    resetDeleteSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error: false,
        isDelete: false,
        isSave: false,
      };
    },
    serverFailed: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: true,
        error: false,
        isDelete: false,
      };
    },
    programTitleByProgramIdAndInstIdSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        programTitleByProgramIdAndInstId: row,
        programTitleByProgramIdAndInstIdFetch: true,
      };
    },
  },
});

export const {
  programTitleDeleteFail,
  serverFailed,
  programTitleDeleteSuccess,
  resetDeleteSuccess,
  branchFilterSuccess,
  getBranchIdSuccess,
  getBranchIdFailure,
  InstbranchFail,
  InstbranchSuccess,
  branchDetailsFail,
  branchDetailsSuccess,
  branchSuccess,
  getInstByBrIdSuccess,
  branchFailure,
  branchFetched,
  branchListByShortNameFailure,
  branchListByShortNameSuccess,
  branchListByNameFailure,
  branchListByNameSuccess,
  programTitleByProgramIdAndInstIdSuccess,
} = branchSlice.actions;

export default branchSlice.reducer;

export const getbranch = () => async (dispatch) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get(URL + '?sort={"insertdatetime": "DESC"}', { headers })
      .then((response) => {
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          let data = response.data.data.content;
          let row = [];
          let index = 0;
          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              progName:
                data1.programType.name + " - " + data1.programType.program.name,
              name: data1.brName + " ( " + data1.brShortName + " ) ",
              branchName: data1.brName,
              courseCode: data1.courseCode,
              progNameId: data1.programType.id,
              progTypeId: data1.programType.id,
              dsAdmission: data1.dsAdmission === 1 ? "Yes" : "No",
              isActive: data1.isActive === 1 ? "Active" : "Inactive",
              branchShortName: data1.brShortName,
            };
            row.push(bData);
          });
          dispatch(branchSuccess({ row }));
          return row;
        }
      })

      .catch((error) => {
        dispatch(serverFailed());
        return false;
      });
    return doc;
  } catch (e) {
    dispatch(serverFailed());
    return false;
  }
};
export const getbranchByName =
  ({ id, branchName }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      if (id == 0) {
        const doc = await axios
          .get(
            URL + '?filters={"brName":"' + branchName + '","_mode" : "EXACT"}',
            { headers }
          )
          .then((response) => {
            console.log("Success getDoc  ========>", response);
            const { error } = response.data;
            if (error == true) {
              dispatch(tokenExp());
            } else {
              let data = response.data.data.content;
              dispatch(branchListByNameSuccess({ data }));
              return data;
            }
          })
          .catch((error) => {
            dispatch(serverFailed());
            return false;
          });
        return doc;
      } else {
        const doc = await axios
          .get(
            URL +
              '?filters={"id":"' +
              id +
              '","_mode" : "NIN"},{"brName":"' +
              branchName +
              '","_mode" : "EXACT"}',
            { headers }
          )
          .then((response) => {
            console.log("Success getDoc  ========>", response);
            const { error } = response.data;
            if (error == true) {
              dispatch(tokenExp());
            } else {
              let data = response.data.data.content;

              console.log(data);
              dispatch(branchListByNameSuccess({ data }));
              return data;
            }
          })
          .catch((error) => {
            dispatch(serverFailed());
            return false;
          });
        return doc;
      }
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };
export const getbranchByShortName =
  ({ id, shortName }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      if (id == 0) {
        const doc = await axios
          .get(
            URL +
              '?filters={"brShortName":"' +
              shortName +
              '","_mode" : "EXACT"}',
            { headers }
          )
          .then((response) => {
            console.log("Success getDoc  ========>", response);
            const { error } = response.data;
            if (error == true) {
              dispatch(tokenExp());
            } else {
              let data = response.data.data.content;
              dispatch(branchListByShortNameSuccess({ data }));
              return data;
            }
          })
          .catch((error) => {
            dispatch(serverFailed());
            return false;
          });
        return doc;
      } else {
        const doc = await axios
          .get(
            URL +
              '?filters={"id":"' +
              id +
              '","_mode" : "NIN"},{"brShortName":"' +
              shortName +
              '","_mode" : "EXACT"}',
            { headers }
          )
          .then((response) => {
            console.log("Success getDoc  ========>", response);
            const { error } = response.data;
            if (error == true) {
              dispatch(tokenExp());
            } else {
              let data = response.data.data.content;
              dispatch(branchListByShortNameSuccess({ data }));
              return data;
            }
          })
          .catch((error) => {
            dispatch(serverFailed());
            return false;
          });
        return doc;
      }
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const deleteBranchList = () => async (dispatch) => {
  try {
    dispatch(branchFetched());
  } catch (e) {
    return false;
  }
};
export const deleteBranchData = () => async (dispatch) => {
  try {
    dispatch(branchListByNameFailure());
    dispatch(branchListByShortNameFailure());
  } catch (e) {
    return false;
  }
};
export const deleteBranchInstituteData = () => async (dispatch) => {
  try {
    dispatch(InstbranchFail());
  } catch (e) {
    return false;
  }
};
export const getbranchById =
  ({ branchId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .get(URL + "/" + branchId, { headers })
        .then((response) => {
          console.log("Success With id getbranchById ========>", response);
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            const { data } = response.data;
            dispatch(getBranchIdSuccess({ data }));
            return data;
          }
        })
        .catch((error) => {
          dispatch(serverFailed());
          console.log("ErrorgetbranchById ========>", error.response.data);
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const saveBranch =
  ({ branchData }) =>
  async (dispatch) => {
    try {
      let data = JSON.stringify(branchData);
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .post(URL, data, { headers })
        .then((response) => {
          console.log("Success With saveBranch ========>", response.data);
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            const { data } = response.data.data;
            dispatch(branchDetailsSuccess({ data }));
            return response;
          }
        })
        .catch((error) => {
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };
export const resetDataById = () => async (dispatch) => {
  try {
    dispatch(getBranchIdFailure());
  } catch (e) {
    return false;
  }
};
export const resetSave = () => async (dispatch) => {
  try {
    dispatch(branchDetailsFail());
  } catch (e) {
    return false;
  }
};
export const deleteBranchById =
  ({ branchId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      let response = await axios
        .delete(URL + "/" + branchId, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            console.log(
              "Success With deletedeleteBranchById ========>",
              response
            );
            dispatch(programTitleDeleteSuccess());
          }
          return true;
        })

        .catch((error) => {
          if (error.response) {
            console.log("error.response.status");
            console.log(error.response.status);
            console.log("error.response.data");
            console.log(error.response.data);
          }
          dispatch(programTitleDeleteFail());
          return error.response.data;
        });
      return response;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const getInstAndBranchByBrId =
  ({ branchId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .get(URL + "/" + branchId + "/institute-program-title", { headers })
        .then((response) => {
          console.log("Success With id getbranchById ========>", response);
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            const { data } = response.data;
            dispatch(getInstByBrIdSuccess({ data }));
            return true;
          }
        })
        .catch((error) => {
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };

export const getInstituteCoruseById =
  ({ branchId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .get(URL + "/" + branchId + "/institute-program-title", { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            const { data } = response.data;
            let row = [];
            let index = 0;
            data.map((data1) => {
              index = index + 1;
              let bData = {
                index: index,
                id: data1.id,
                tenantId: data1.tenant.id,
                instName: data1.tenant.tenantName,
                isActive: data1.tenant.isActive === 1 ? "Active" : "Inactive",
              };
              row.push(bData);
            });
            dispatch(InstbranchSuccess({ row }));
            console.log("Success With id getInstituteById ========>", response);
            return true;
          }
        })
        .catch((error) => {
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };
export const filterBranchData =
  ({ row, flag }) =>
  async (dispatch) => {
    try {
      if (flag == true) {
        dispatch(branchFilterSuccess({ row }));
      } else {
        dispatch(branchSuccess({ row }));
      }
    } catch (e) {
      return false;
    }
  };

export const resetDelete = () => async (dispatch) => {
  try {
    dispatch(resetDeleteSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

export const getProgramTitleByProgramIdAndInstId =
  ({ instId, programId, yearId }) =>
  async (dispatch) => {
    try {
      let URLs = endpoints.course;
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };

      const doc = await axios
        .get(
          URLs +
            "/by-instituteId-and-program-type-id?instituteId=" +
            instId +
            "&programTypeId=" +
            programId +
            "&yearId=" +
            yearId,
          { headers }
        )
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            console.log(
              "Success With id ProgramTitleByProgramIdAndInstId ========>",
              response
            );
            const { data } = response.data;
            let row = [];
            let index = 0;
            data.map((data1) => {
              index = index + 1;
              let bData = {
                index: index,
                id: data1.id,
                progName:
                  data1.programType.name +
                  " - " +
                  data1.programType.program.name,
                name: data1.brName + " ( " + data1.brShortName + " ) ",
                branchName: data1.brName,
                courseCode: data1.courseCode,
                progNameId: data1.programType.id,
                progTypeId: data1.programType.id,
                dsAdmission: data1.dsAdmission === 1 ? "Yes" : "No",
                isActive: data1.isActive === 1 ? "Active" : "Inactive",
                branchShortName: data1.brShortName,
              };
              row.push(bData);
            });

            dispatch(programTitleByProgramIdAndInstIdSuccess({ row }));
          }
          return response;
        })
        .catch((error) => {
          console.log("ErrorgetCourseDataById ========>", error.response.data);
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };
