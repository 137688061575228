import { Grid, Typography } from "@mui/material";
import * as React from "react";
import { ChipCompo } from "../../components/Comman/IconButton";
import "../../CSS/View.css";

const ViewPage = ({ data }) => {
  if (data.length > 0) {
    return (
      <Grid className="parent">
        <Grid className="LeftText">
          <Typography id="content1"> Academic Year Name: </Typography>

          <Typography id="content1"> From Date: </Typography>

          <Typography id="content1">To Date: </Typography>

          <Typography id="content1">Is Active:</Typography>
        </Grid>

        {data.map((data) => {
          return (
            <Grid className="RightText">
              <Typography id="content1">{data.academicYearName}</Typography>
              <Typography id="content1">{data.fromDate}</Typography>
              <Typography id="content1">{data.toDate}</Typography>
              <Typography id="content1">
                {data.isActive == "Active" ? (
                  <ChipCompo
                    label="Active"
                    variant="outlined"
                    size="small"
                    color="success"
                  />
                ) : (
                  <ChipCompo
                    label="Inactive"
                    variant="outlined"
                    size="small"
                    color="error"
                  />
                )}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    );
  } else {
    return <label>Error in view</label>;
  }
};
export default ViewPage;
