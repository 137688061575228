import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import * as React from "react";
import "../../CSS/View.css";

const styles = (Theme) => ({
  MuiTable: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
  },

  MuiTableHead: {
    background: "#6673fd",
    color: "#FFF",
    border: "1px solid #ddd",
    fontFamily: "poppins",
  },
  MuiTableCell: {
    color: "#FFF",
    fontSize: "16px",
    fontFamily: "poppins",
  },
  MuiTableCellRoot: {
    padding: "7px",
    borderRight: "1px solid #ddd",
    fontFamily: "poppins",
  },
  MuiTableHeadRoot: {
    textAlign: "left",
    padding: "8px",
    border: "1px solid #ddd",
  },
});

const Step4 = ({ data, classes }) => {
  return (
    <>
      <Grid item xs={12} sm={6} md={12} />
      <div style={{ overflow: "auto" }}>
        <table className={classes.MuiTable}>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              align="left"
              width="20%"
            >
              &nbsp;Sr. No.{" "}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              align="left"
            >
              &nbsp;Documents Required
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              align="left"
              width="20%"
            >
              &nbsp; Mandatory/Optional{" "}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
              align="left"
            >
              &nbsp;Remarks
            </td>
          </tr>

          {data &&
            data.applicationDocumentDetails.map(
              (applicationDocumentData, index) => (
                <tr>
                  <td
                    className={classes.MuiTableHeadRoot}
                    align="left"
                    key={applicationDocumentData.id}
                  >
                    &nbsp;{index + 1}
                  </td>

                  <td
                    className={classes.MuiTableHeadRoot}
                    align="left"
                    key={applicationDocumentData.id}
                  >
                    &nbsp;{applicationDocumentData.documentName}
                  </td>

                  <td className={classes.MuiTableHeadRoot} align="left">
                    &nbsp;{applicationDocumentData.type == 1 ? "Yes" : "No"}
                  </td>
                  <td className={classes.MuiTableHeadRoot} align="left">
                    &nbsp;{applicationDocumentData.remark}
                  </td>
                </tr>
              )
            )}
        </table>
      </div>
      <br />
    </>
  );
};

export default withStyles(styles)(Step4);
