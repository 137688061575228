import { TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { onlyNumber } from "../../components/Comman/Util/Validations";

export default ({ data, Rows, onCancle }) => {
  const [value, setValue] = useState(Rows);
  console.log(data);
  const columns = [
    {
      field: "name",
      headerName: "Details",
      width: 200,
      sortable: false,
    },
    {
      field: "university",
      headerName: "University/Board",
      sortable: false,
      width: 200,
      renderCell: (params, index1) => {
        const changeHandler = (id, e) => {
          const { value } = e.target;
          setValue((data) =>
            data?.map((list, index) =>
              list.id === id ? { ...list, university: value } : list
            )
          );
        };

        return (
          <div>
            <TextField
              color="primary"
              name={"university" + params.row.id}
              size="small"
              value={params.row.university}
              onChange={(e) => changeHandler(params.row.id, e)}
              fullWidth={true}
            />
          </div>
        );
      },
    },
    {
      field: "school",
      headerName: "School/Collage",
      sortable: false,
      width: 200,
      renderCell: (params, index1) => {
        const changeHandler = (id, e) => {
          const { value } = e.target;
          setValue((data) =>
            data?.map((list, index) =>
              list.id === id ? { ...list, school: value } : list
            )
          );
        };

        return (
          <div>
            <TextField
              color="primary"
              name={"school" + params.row.id}
              size="small"
              value={params.row.school}
              // {...( params.row.schoolValid == 1
              //   ? { error: true, helperText: "please enter school/collage" }
              //   : "")}
              onChange={(e) => changeHandler(params.row.id, e)}
              fullWidth={true}
            />
          </div>
        );
      },
    },
    {
      field: "certificateNo",
      headerName: "Certificate Number",
      sortable: false,
      width: 200,
      renderCell: (params, index1) => {
        const changeHandler = (id, e) => {
          const { value } = e.target;
          setValue((data) =>
            data?.map((list, index) =>
              list.id === id ? { ...list, certificateNo: value } : list
            )
          );
        };

        return (
          <div>
            <TextField
              color="primary"
              name={"certificateNo" + params.row.id}
              size="small"
              value={params.row.certificateNo}
              // {...( params.row.certificateNoValid == 1
              //   ? { error: true, helperText: "please enter certificate Number" }
              //   : "")}
              onChange={(e) => changeHandler(params.row.id, e)}
              fullWidth={true}
            />
          </div>
        );
      },
    },
    {
      field: "examSeatNo",
      headerName: "Exam Seat Number",
      sortable: false,
      width: 200,
      renderCell: (params, index1) => {
        const changeHandler = (id, e) => {
          const { value } = e.target;
          setValue((data) =>
            data?.map((list, index) =>
              list.id === id ? { ...list, examSeatNo: value } : list
            )
          );
        };

        return (
          <div>
            <TextField
              color="primary"
              name={"examSeatNo" + params.row.id}
              size="small"
              value={params.row.examSeatNo}
              // {...( params.row.examSeatNoValid == 1
              //   ? { error: true, helperText: "please enter exam seat number" }
              //   : "")}
              onChange={(e) => changeHandler(params.row.id, e)}
              fullWidth={true}
            />
          </div>
        );
      },
    },
    {
      field: "passingyears",
      headerName: "Passing Years",
      width: 150,
      sortable: false,
      renderCell: (params, index1) => {
        const changeHandler = (id, e) => {
          const { value } = e.target;

          if (!onlyNumber.test(value)) {
            setValue((data) =>
              data?.map((list, index) =>
                list.id === id ? { ...list, year: value, yearValid: 2 } : list
              )
            );
          } else if (params.row.year != "") {
            if (value.length <= 4) {
              setValue((data) =>
                data?.map((list, index) =>
                  list.id === id ? { ...list, year: value } : list
                )
              );
            } else {
              setValue((data) =>
                data?.map((list, index) =>
                  list.id === id ? { ...list, yearValid: 3 } : list
                )
              );
            }
          } else {
            setValue((data) =>
              data?.map((list, index) =>
                list.id === id ? { ...list, year: value } : list
              )
            );
          }
        };

        return (
          <div>
            <TextField
              color="primary"
              name={"passingYears" + params.row.id}
              size="small"
              value={params.row.year}
              // {...( params.row.yearValid == 1
              //   ? { error: true, helperText: "please enter passing year" }
              //   :  params.row.yearValid == 2
              //   ? { error: true, helperText: "please enter Valid passing year" }
              //   :  params.row.yearValid == 3
              //   ? {
              //       error: true,
              //       helperText: "please enter 4 digit passing year",
              //     }
              //   :  params.row.yearValid == 4
              //   ? {
              //       error: true,
              //       helperText: "same year not except ",
              //     }
              //   : "")}
              onChange={(e) => changeHandler(params.row.id, e)}
              fullWidth={true}
            />
          </div>
        );
      },
    },
    {
      field: "marksobt",
      headerName: "Marks Obtained",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        const changeHandler = (id, e) => {
          const { value } = e.target;
          if (!onlyNumber.test(value)) {
            setValue((data) =>
              data?.map((list, index) =>
                list.id === id
                  ? { ...list, marksObt: value, marksObtValid: 2 }
                  : list
              )
            );
          } else {
            if (params.row.marksOutOff != "") {
              if (+params.row.marksOutOff < +value) {
                setValue((data) =>
                  data?.map((list, index) =>
                    list.id === id ? { ...list, marksObtValid: 3 } : list
                  )
                );
              } else {
                setValue((data) =>
                  data?.map((list, index) =>
                    list.id === id ? { ...list, marksObtValid: 0 } : list
                  )
                );
              }
              let per = (value / params.row.marksOutOff) * 100;
              setValue((data) =>
                data?.map((list, index) =>
                  list.id === id
                    ? { ...list, marksObt: value, percentage: per }
                    : list
                )
              );
            } else {
              setValue((data) =>
                data?.map((list, index) =>
                  list.id === id ? { ...list, marksObt: value } : list
                )
              );
            }
          }
        };

        return (
          <div>
            <TextField
              color="primary"
              name={"Marksobt" + params.row.id}
              size="small"
              value={params.row.marksObt}
              {...(params.row.marksObtValid == 1
                ? { error: true, helperText: "please enter Marks " }
                : params.row.marksObtValid == 2
                ? { error: true, helperText: "please enter Valid Marks" }
                : params.row.marksObtValid == 3
                ? {
                    error: true,
                    helperText: "please enter less than totalMarks",
                  }
                : "")}
              onChange={(e) => changeHandler(params.row.id, e)}
              fullWidth={true}
            />
          </div>
        );
      },
    },
    {
      field: "marksoutoff",
      headerName: "Marks Out Off",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        const changeHandler = (id, e) => {
          const { value } = e.target;
          if (!onlyNumber.test(value)) {
            setValue((data) =>
              data?.map((list, index) =>
                list.id === id
                  ? { ...list, marksOutOff: value, marksOutOffValid: 2 }
                  : list
              )
            );
          } else {
            if (params.row.marksObt != "") {
              if (+params.row.marksObt > +value) {
                setValue((data) =>
                  data?.map((list, index) =>
                    list.id === id ? { ...list, marksOutOffValid: 3 } : list
                  )
                );
              } else {
                setValue((data) =>
                  data?.map((list, index) =>
                    list.id === id ? { ...list, marksOutOffValid: 0 } : list
                  )
                );
              }
              let per = (params.row.marksObt / value) * 100;
              setValue((data) =>
                data?.map((list, index) =>
                  list.id === id
                    ? {
                        ...list,
                        marksOutOff: value,
                        percentage: per.toFixed(2),
                      }
                    : list
                )
              );
            } else {
              setValue((data) =>
                data?.map((list, index) =>
                  list.id === id ? { ...list, marksOutOff: value } : list
                )
              );
            }
          }
        };

        return (
          <div>
            <TextField
              color="primary"
              name={"marksoutoff" + params.row.id}
              size="small"
              value={params.row.marksOutOff}
              {...(params.row.marksOutOffValid == 1
                ? { error: true, helperText: "please enter Marks " }
                : params.row.marksOutOffValid == 2
                ? { error: true, helperText: "please enter Valid Marks" }
                : params.row.marksOutOffValid == 3
                ? { error: true, helperText: "grater than Marks obtain" }
                : "")}
              onChange={(e) => changeHandler(params.row.id, e)}
              fullWidth={true}
            />
          </div>
        );
      },
    },
    {
      field: "percentage",
      headerName: "Percentage",
      sortable: false,
      width: 110,
      renderCell: (params) => {
        const changeHandler = (id, e) => {
          const { value } = e.target;

          setValue((data) =>
            data?.map((list, index) =>
              list.id === id ? { ...list, percentage: value } : list
            )
          );
        };

        return (
          <div>
            <TextField
              color="primary"
              name={"percentage" + params.row.id}
              size="small"
              value={params.row.percentage}
              onChange={(e) => changeHandler(params.row.id, e)}
              fullWidth={true}
              disabled
            />
          </div>
        );
      },
    },
  ];

  const [tableColumnExtensions] = useState([
    { columnName: "passingyears", width: 200 },
    { columnName: "passingyears", width: 125 },
    { columnName: "marksobt", width: 150 },
    { columnName: "marksoutoff", width: 150 },
    { columnName: "percentage", width: 150 },
  ]);
  const [percentColumns] = useState([]);

  const submitHandler = (e) => {
    e.preventDefault();

    let errorFlag = false;
    let listData = value.map((selectedRow, selIndex) => {
      const a = value.filter((item, index) => item.year === selectedRow.year);

      if (selectedRow.marksOutOff == "") {
        selectedRow = {
          ...selectedRow,
          marksOutOffValid: 1,
        };
      } else if (!onlyNumber.test(selectedRow.marksOutOff)) {
        selectedRow = {
          ...selectedRow,
          marksOutOffValid: 2,
        };
      } else if (+selectedRow.marksObt > +selectedRow.marksOutOff) {
        selectedRow = {
          ...selectedRow,
          marksOutOffValid: 3,
        };
      } else {
        selectedRow = {
          ...selectedRow,
          marksOutOffValid: 0,
        };
      }
      if (selectedRow.marksObt == "") {
        selectedRow = {
          ...selectedRow,
          marksObtValid: 1,
        };
      } else if (!onlyNumber.test(selectedRow.marksObt)) {
        selectedRow = {
          ...selectedRow,
          marksObtValid: 2,
        };
      } else if (+selectedRow.marksOutOff < +selectedRow.marksObt) {
        selectedRow = {
          ...selectedRow,
          marksObtValid: 3,
        };
      } else {
        selectedRow = {
          ...selectedRow,
          marksObtValid: 0,
        };
      }

      if (selectedRow.marksObtValid != 0 || selectedRow.marksOutOffValid != 0) {
        errorFlag = true;
      }
      return selectedRow;
    });
    setValue(listData);
    if (errorFlag == false) {
      data(value);
    }
  };
  return (
    <div align="center">
      <DataGrid
        autoHeight={true}
        rows={value}
        columns={columns}
        disableColumnMenu
        pageSize={5}
        rowsPerPageOptions={[5]}
      />
      &nbsp;&nbsp; &nbsp;&nbsp;
      <div>
        <div align="right">
          <ButtonCompo
            size="medium"
            type="button"
            variant="outlined"
            name="Back"
            onClick={onCancle}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <ButtonCompo
            size="button"
            type="button"
            variant="contained"
            name="  Save and Proceed"
            onClick={submitHandler}
          />
        </div>
      </div>
    </div>
  );
};
