import { Paper } from "@mui/material";
import { withStyles } from "@mui/styles";
//import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import news from '../../Images/news.png';
import { downloadFile } from "../UploadFile/file.slice";
import {getNewsDocNotification} from "../NewsDocNotification/newsDocNotification.slice"
import { getAlert } from "../../Alert/alert.slice";
import * as myConstClass from "../../config/messageconstant";
const styles = (theme) => ({
  hello: {
    position: "absolute",
    left: "15.17%",
    right: "15.08%",
    top: "63.4%",
    bottom: "32.6%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: "normal",
    fontsize: "36px",
    lineheight: "40px",
    color: "#000000",
  },
  buttonC: {
    position: "absolute",

    left: "39.17%",
    right: "39.17%",
    top: "79.7%",
    bottom: "14.8%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: 500,
    fontsize: "18px",
    lineheight: "40px",
    color: "#FFFFFF",
  },
  helper: {
    position: "absolute",
    left: "9.58%",
    right: "9.5%",
    top: "70%",
    bottom: "22%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: "normal",
    fontsize: "20px",
    lineheight: "40px",

    textAlign: "center",

    color: "#666666",
  },
  title: {
    position: "absolute",
    left: "43.42%",
    right: "43.33%",
    top: "57%",
    bottom: "39%",

    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: 500,
    fontsize: "55px",
    lineheight: "40px",
    color: "#000000",
  },
})
const ErrorPage = ({ getNewsDocNotification,getAlert,latestnews,classes }) => {
  useEffect(() => {
    getNewsDocNotification().then((response) => {
      if(!response){
        getAlert( { message: myConstClass.serverMsg } );
      }
    });
  }, []);
  console.log(latestnews?.newsDocNotification.filter((row) => row.type == "Latest News"));
  return (
    <>
       <Paper sx={{ p: 0, borderRadius: 2 }} elevation={0}>
      <div align="center">
        <div className="new_content">
        <h3 className="event_title1"><span>All</span> Latest News</h3>
          {(latestnews?.newsDocNotification.filter((row) => row.type == "Latest News"&&row.isActive=="Active")).map((data) => {
            return (
              <div className="news_detail">
                <div className="det_news_pic pic">
                <img src={data.documentName===''?news:data.documentName}></img>
                </div>
                <div className="admission_cont detil">
                  <h6 className="event_sub_nm">
                    {data.title}
                    {/* <span>{data.date}</span> */}

                    

                  </h6>

                  <h6 class="news_time latest"><span class="blue">Date :</span> {data.date}</h6>
                </div>
                <p className="admission_txt">{data.description}</p>
              </div>
            );
          })}
        </div>
      </div>
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({
  latestnews: state.newsDocNotification,
});

const mapDispatchToProps = {
  getNewsDocNotification,
  getAlert,
  downloadFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ErrorPage));
