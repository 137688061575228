import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import * as React from 'react';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export const DirectionSnackbar=({open,msg,closeSnak,severity})=>{
  //const [open, setOpen] = React.useState(false);
  const [transition, setTransition] = React.useState(undefined);

 

  return (
    <div>
      <Snackbar
        open={open}
        onClose={()=>{closeSnak(false)}}
        TransitionComponent={transition}
        autoHideDuration={5000}
        key={transition ? transition.name : ''}
      >
          <Alert onClose={()=>{closeSnak(false)}} severity={severity} sx={{ width: '100%' }}>{msg}
          </Alert></Snackbar>
    </div>
  );
}

