import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { FormHelperText, Grid } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import AttachFile from "../../components/Comman/AttachFile";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import { LabelCompo } from "../../components/Comman/Label";
import { Loading1 } from "../../components/Loading1";
import {
  openFileInNewTab,
  resetFileData,
  saveMultipleFile,
} from "../UploadFile/file.slice";

let row = [];
let fileNameData = [];
class DocumentUpload extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    id: 0,
    flag: false,
    label: "",
    formErrors: [],
    fileName: "",
    upload: false,
    loader: false,
  };
  componentDidMount() {
    if (this.props.fileData.fileData.length === 0) {
      fileNameData = [];
      this.setState({
        id: 0,
        flag: false,
        label: "",
        formErrors: [],
        fileName: "",
        upload: false,
        loader: false,
      });
    }
  }
  uploadImage = (docId) => {
    let fileDataList = fileNameData.filter((qData) => qData.name === docId);
    const { fileData } = this.props;
    let filterSaveFileData = fileData.fileData.filter(
      (qData) => qData.name !== docId
    );

    const { saveMultipleFile, resetFileData } = this.props;
    if (filterSaveFileData.length != 0) {
      resetFileData({ fileData: filterSaveFileData });
    }

    if (fileDataList.length !== 0) {
      this.setState({
        upload: true,
        uploadError: false,
        loader: true,
      });
      let fileData = {
        fileName: fileDataList[0].fileName,
        name: fileDataList[0].name,
      };
      saveMultipleFile({ file: fileData }).then((resp) => {
        if (resp) {
          this.setState({ loader: false });
          let fileData = {
            fileName: fileDataList[0].fileName,
            name: fileDataList[0].name,
            uploadFile: true,
          };
          let filterFileList = fileNameData.filter(
            (qData) => qData.name !== docId
          );
          fileNameData = [...filterFileList, fileData];
          this.setState({ upload: true });
          console.log(fileNameData);
        } else {
          this.setState({ loader: false });
        }
      });
    } else {
      this.setState({
        uploadError: true,
      });
    }
  };

  changeH = (fileName, name) => {
    let fileData = {
      uploadFile: false,
      fileName: fileName,
      name: name,
    };
    fileNameData = fileNameData.filter((qData) => qData.name !== name);
    fileNameData = fileNameData;

    fileNameData.push(fileData);
    this.setState({ file: fileName, label: name });
    this.setState({
      ...this.state,
      fileName: fileName.name,
    });
  };
  handleFormValidation() {
    const { AttachFileName } = this.state;
    let formErrors = {};
    let formIsValid = true;

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  submitHandler = (e) => {
    e.preventDefault();
    const { docData, fileData, appliData } = this.props;
    row = [];
    fileData.fileData.map((fileDetails) => {
      let fileList = {
        documentFileName: fileDetails.fileName,
        isActive: 1,
        delStatus: 1,
        document: {
          id: fileDetails.name,
        },
      };
      row.push(fileList);
    });
    let error = false;
    appliData.applicationData.applicationDocumentDetails.map((documentData) => {
      let docName = row.filter(
        (rowData) => rowData.document.id === documentData.documentId
      );
      if (documentData.type === 1 && docName.length === 0) {
        console.log("docName");
        error = true;
        this.setState({
          flag: true,
        });
      }
      console.log(docName);
    });
    if (!error) {
      docData(row);
    }
  };
  onClickView = (e, documentId) => {
    const fileName = this.props.fileData.fileData.filter(
      (qData) => qData.name === documentId
    );
    if (fileName.length !== 0) {
      this.props.openFileInNewTab({ file: fileName[0].fileName });
    }
  };
  render() {
    const { label, upload } = this.state.formErrors;

    const { onCancel, appliData, fileData } = this.props;
    const { uploadError, flag, loader } = this.state;
    return (
      <>
        {loader && <Loading1 size={50} />}
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={1}
          >
            {" "}
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              spacing={2}
              columnSpacing={1}
              container
              sx={{ m: 0.5 }}
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                {/* <Grid item xs={12} sm={12} md={0.5}></Grid> */}
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 20 }}
                    label="Upload Documents"
                  />
                  {uploadError && (
                    <FormHelperText error>
                      {"*Please select file"}{" "}
                    </FormHelperText>
                  )}
                  {flag && (
                    <FormHelperText error>
                      {"*Please upload required file"}{" "}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {appliData.applicationData.applicationDocumentDetails.map(
              (docs, index) => {
                let docName = fileNameData.filter(
                  (qData) => qData.name === docs.documentId
                );
                console.log(docName);
                return (
                  <>
                    <Grid item xs={12} sm={6} md={12} />
                    <Grid item xs={12} sm={6} md={12} />
                    <Grid m={1} item xs={2} sm={4} md={2}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label={
                          docs.type === 1
                            ? "* " + docs.documentName
                            : "  " + docs.documentName
                        }
                      />
                    </Grid>
                    <Grid item xs={2} sm={4} md={6}>
                      <AttachFile
                        name={docs.documentId}
                        fileName={this.changeH}
                      />
                      &nbsp; {docName.length !== 0 && docName[0].fileName.name}
                    </Grid>
                    <Grid item xs={2} sm={4} md={1.5}>
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        {...(upload && !fileData.isFetch && { disable: true })}
                        name="Upload"
                        onClick={(e) => this.uploadImage(docs.documentId)}
                      />
                    </Grid>
                    <Grid item xs={2} sm={0.5} md={0.5}>
                      {docName.length !== 0 &&
                        docName[0].uploadFile == true && (
                          <>
                            <MyComponentWithIconProps
                              statusImage={CheckCircleOutlinedIcon}
                              color="primary"
                              fontSize="medium"
                              title=""
                              sx={{}}
                            />
                          </>
                        )}
                    </Grid>
                    <Grid item xs={2} sm={1} md={1}>
                      {docName.length !== 0 &&
                        docName[0].uploadFile == true && (
                          <>
                            <MyComponentWithIconProps
                              statusImage={VisibilityIcon}
                              color="primary"
                              fontSize="medium"
                              title="View"
                              onClick={(e) =>
                                this.onClickView(e, docs.documentId)
                              }
                            />
                          </>
                        )}
                    </Grid>
                  </>
                );
              }
            )}
            <Grid item xs={2} sm={4} md={12} />
            <Grid item container justifyContent="right">
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name="Back"
                onClick={onCancel}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Save and Proceed"
              />
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  appliData: state.directAdmission,
  fileData: state.file,
  applicationList: state.program,
});

const mapDispatchToProps = {
  saveMultipleFile,
  resetFileData,
  openFileInNewTab,
  // saveQualificationDetails,
};
export default connect(mapStateToProps, mapDispatchToProps)(DocumentUpload);
