import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AttachmentIcon from "@mui/icons-material/Attachment";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import InfoIcon from "@mui/icons-material/Info";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import SchoolIcon from "@mui/icons-material/School";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Paper } from "@mui/material";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import DeclarationForm from "../DeclarationForm/index";
import { getDocument } from "../DocumentMaster/document.slice";
import { getQualification } from "../QualificationMaster/qualification.slice";
import { deleteFile, downloadFile } from "../UploadFile/file.slice";
import AttachmentForm from "./DirectAdmissionAttachmentForm";
import AttachPhotoView from "./DirectAdmissionViewAttachmentForm";
import {
  deleteDeclrationData,
  getCourseDataById,
  getDeclrationById,
  saveappli,
  resetDelete,
} from "./directAdmission.slice";
import OnlineApplicationForm from "./DirectAdmissionBasicDetailsForm";
import QualificationCriteria from "./DirectAdmissionQualificationForm";
import ReqDocuments from "./DirectAdmissionReqDocumentForm";
import * as myConstClass from "../../config/messageconstant";

import SelectionType from "./DirectAdmissionSelectionForm";

import { getAlert } from "../../Alert/alert.slice";
import { logOut } from "../Login/login.slice";
import { useNavigate } from "react-router";
import Alert from "../../Alert/Alert";
import { Loading1 } from "../../components/LoadorPre";
let labelData = [
  {
    id: "1",
    label: "Guidlines For The Form",
  },
  {
    id: "2",
    label: "Time Table",
  },
  {
    id: "3",
    label: "Schedule of the Form",
  },
  {
    id: "4",
    label: "Other",
  },
];
const ApplicationDelcareForm = ({
  getDocument,
  downloadFile,
  getDeclrationById,
  getCourseDataById,
  deleteFile,
  getQualification,
  documentList,
  resetDelete,
  branchList,
  saveappli,
  qualificationList,
  appliData,
  loginUser,
  deleteDeclrationData,
  files,
  getAlert,
  logOut,
}) => {
  const [isEdit, setEdit] = useState(false);
  const [editRow, setEditRow] = useState([]);
  const [row, setRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [snakMsg, setSnakMsg] = useState("");
  const [openSnak, setOpenSnak] = useState(false);
  const [subjectSelectDisable, setSubjectSelectDisable] = useState(true);
  const [qualificationDisable, setQualificationDisable] = useState(true);
  const [documentDisable, setDocumentDisable] = useState(true);
  const [attachmentDisable, setAttachmentDisable] = useState(true);
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  const [labelList, setLabelList] = useState(labelData);

  const [value, setValue] = React.useState("1");
  const [viewDeclr, setViewDeclr] = useState(false);

  const data = (ApplicData) => {
    setLoader(true);
    saveappli({ appliData: ApplicData }).then((response) => {
      if (!response) {
        setLoader(false);
        getAlert({ message: myConstClass.serverMsg });
      } else {
        setValue("2");
        setSubjectSelectDisable(false);
        setLoader(false);
      }
    });
  };

  const SelectionTypeData = (ApplicData) => {
    setLoader(true);
    saveappli({ appliData: ApplicData }).then((response) => {
      if (!response) {
        setLoader(false);
        getAlert({ message: myConstClass.serverMsg });
      } else {
        setQualificationDisable(false);
        setLoader(false);
        setValue("3");
      }
    });
    deleteDeclrationData();
  };

  // applicationElegibilityDetails: (instData = {
  const qualificationData = (instData) => {
    let ApplicData = instData.map((data1) => {
      let bData = {
        qualificationId: data1.qualificationId,
        marksSequenceOrderNo: data1.marksSequenceOrderNo,
        weightagePer: data1.weightagePer,
        applicationDeclareDirectAdmissionId: appliData.applicationData.id,
        delStatus: "1",
      };
      return bData;
    });
    let dataToSave = {
      applicationElegibilityDetails: ApplicData,
      id: appliData.applicationData.id,
      type: 3,
    };
    setLoader(true);
    saveappli({ appliData: dataToSave }).then((response) => {
      if (!response) {
        setLoader(false);
        getAlert({ message: myConstClass.serverMsg });
      } else {
        setLoader(false);
        getDocument().then((resp) => {
          if (!resp) {
            getAlert({ message: myConstClass.serverMsg });
          } else {
            setValue("4");
            setDocumentDisable(false);
          }
        });
      }
    });
  };

  const ReqDocsData = (instData) => {
    let ApplicData = instData.map((data1) => {
      let bData = {
        applicationDeclareDirectAdmissionId: appliData.applicationData.id,
        documentId: data1.documentId,
        type: data1.type,
        marksSequenceOrderNo: data1.marksSequenceOrderNo,
        delStatus: 1,
        remark: data1.remark,
      };
      return bData;
    });
    let dataToSave = {
      applicationDocumentDetails: ApplicData,
      id: appliData.applicationData.id,
      type: 4,
    };
    setLoader(true);
    saveappli({ appliData: dataToSave }).then((response) => {
      if (!response) {
        setLoader(false);
        getAlert({ message: myConstClass.serverMsg });
      } else {
        setValue("5");
        setAttachmentDisable(false);
        setLoader(false);
      }
    });
  };

  const AttachmentData = (instData) => {
    setLoader(true);
    saveappli({ appliData: instData }).then((response) => {
      if (response) {
        setLoader(false);
        navigate("/list-of-application-delcare");
      } else {
        setLoader(false);
        getAlert({ message: myConstClass.serverMsg });
      }
    });
  };

  useEffect(() => {
    const appliId = localStorage.getItem("appliId");
    console.log(appliId);
    if (appliId !== null) {
      getDeclrationById({ appliId: appliId }).then((response) => {
        if (!response) {
          getAlert({ message: myConstClass.serverMsg });
        } else {
        }
      });
    }
    getDocument().then((response) => {
      if (!response) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
    getQualification().then((response) => {
      if (!response) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
  }, []);

  useEffect(() => {
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
  });

  const changeTabs = (event, newValue) => {
    if (newValue === "6") setOpen(false);
    setValue(newValue);
  };

  const rowDelete = (id) => {
    const viewRow = row.filter((row) => row.id === id);
    swal({
      title: "Are you sure?",
      text: "Do you want to delete the file?",
      icon: "warning",
      buttons: ["Cancel", true],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteFile({ file: viewRow[0].fileName }).then(() => {
          setOpenSnak(true);
          setSnakMsg(myConstClass.deleteMsg);
        });
        setRow(row.filter((row) => row.id !== id));
      }
      const rowData = row.filter((row) => row.id !== id);
      setRow(rowData);
      if (
        viewRow[0].docDesc === "Guidlines For The Form" ||
        viewRow[0].docDesc === "Time Table" ||
        viewRow[0].docDesc === "Schedule of the Form"
      ) {
        let labelInfo = labelData.filter(
          (labelRow) => labelRow.label == viewRow[0].docDesc
        );
        let label1 = {
          id: labelInfo[0].id,
          label: labelInfo[0].label,
        };
        setLabelList((prevData) => {
          return [...prevData, label1];
        });
      }
    });
  };

  const stopEdit = () => {
    setEditRow([]);
    setEdit(false);
    navigate("/list-of-application-delcare");
  };
  const GoToOnlineApplicationForm = () => {
    setEditRow([]);
    setValue("1");
  };
  const gotoSelectProg = () => {
    setEditRow([]);
    setValue("2");
  };
  const gotoQualificationCriteria = () => {
    setEditRow([]);
    setValue("3");
  };
  const gotoReqDocuments = () => {
    setEditRow([]);
    setValue("4");
  };
  const setRowData = (data) => {
    if (data.id === undefined) {
      data = {
        ...data,
        id: row.length + 1,
      };
      setRow((prevData) => {
        return [...prevData, data];
      });
    } else {
      setRow((prevData) => {
        return [...prevData, data];
      });
    }
    if (
      data.docDesc === "Guidlines For The Form" ||
      data.docDesc === "Time Table" ||
      data.docDesc === "Schedule of the Form"
    ) {
      const viewRow = labelList.filter((list) => list.label !== data.docDesc);

      setLabelList(viewRow);
    }
  };
  const backToForm = () => {
    setViewDeclr(false);
    setValue("1");
  };
  return (
    <>
      {/* {appliData.isServerFail && (
        <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />
      )}
      {files.isServerFail && (
        <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />
      )} */}

      {/* < ErrorHand error={"error"} msg={'This is an error alert — check it out!'} flag={true}/>  */}

      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        {!viewDeclr && (
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={changeTabs}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="lab API tabs example"
                >
                  <Tab
                    icon={<InfoIcon />}
                    textColor="secondary"
                    indicatorColor="secondary"
                    label="Basic Details"
                    value="1"
                  />
                  <Tab
                    icon={<CheckBoxIcon />}
                    textColor="secondary"
                    indicatorColor="secondary"
                    label="Select Program"
                    value="2"
                    // disabled={subjectSelectDisable}
                    {...(Object.keys(appliData?.applicationData).length === 0
                      ? {
                          disabled: true,
                        }
                      : {
                          disabled: false,
                        })}
                  />
                  <Tab
                    icon={<SchoolIcon />}
                    textColor="secondary"
                    indicatorColor="secondary"
                    label="Qualification"
                    value="3"
                    // disabled={qualificationDisable}

                    {...(Object.keys(appliData?.applicationData).length === 0
                      ? {
                          disabled: true,
                        }
                      : appliData?.applicationData.type < 3
                      ? {
                          disabled: true,
                        }
                      : {
                          disabled: false,
                        })}
                  />
                  <Tab
                    icon={<PhotoSizeSelectActualIcon />}
                    textColor="secondary"
                    indicatorColor="secondary"
                    label="Document Details"
                    value="4"
                    // disabled={documentDisable}

                    {...(Object.keys(appliData?.applicationData).length === 0
                      ? {
                          disabled: true,
                        }
                      : appliData?.applicationData.type < 4
                      ? {
                          disabled: true,
                        }
                      : {
                          disabled: false,
                        })}
                  />
                  <Tab
                    icon={<AttachmentIcon />}
                    textColor="secondary"
                    indicatorColor="secondary"
                    label="Important Text & Attachement"
                    value="5"
                    // disabled={attachmentDisable}

                    {...(Object.keys(appliData?.applicationData).length === 0
                      ? {
                          disabled: true,
                        }
                      : appliData?.applicationData.type < 5
                      ? {
                          disabled: true,
                        }
                      : {
                          disabled: false,
                        })}
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                {
                  <OnlineApplicationForm
                    OnSave={data}
                    isEdit={editRow}
                    onCancel={stopEdit}
                  />
                }
              </TabPanel>

              <TabPanel value="2">
                {
                  <SelectionType
                    OnSave={SelectionTypeData}
                    onCancel={GoToOnlineApplicationForm}
                    isEdit={editRow}
                  />
                }
              </TabPanel>
              <TabPanel value="3">
                {
                  <QualificationCriteria
                    data={qualificationData}
                    onCancel={gotoSelectProg}
                    isEdit={editRow}
                    rows={qualificationList?.qualification}
                  />
                }
              </TabPanel>
              <TabPanel value="4">
                {
                  <ReqDocuments
                    data={ReqDocsData}
                    onCancel={gotoQualificationCriteria}
                    isEdit={editRow}
                    eduRows={documentList?.document}
                  />
                }
              </TabPanel>
              <TabPanel value="5">
                {
                  <>
                    <AttachmentForm
                      OnSave={AttachmentData}
                      onCancel={gotoReqDocuments}
                      isEdit={editRow}
                      row={row}
                      labelList={labelList}
                      rowDelete={rowDelete}
                      rowData={setRowData}
                    />

                    {open && (
                      <AttachPhotoView openparam={open} closeView={setOpen} />
                    )}
                  </>
                }
              </TabPanel>
            </TabContext>
          </Box>
        )}
        {viewDeclr && appliData.isFetchApplication && (
          <DeclarationForm backToForm={backToForm} />
        )}
        {loader && <Loading1 size={50} />}
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({
  appliData: state.directAdmission,
  branchList: state.branch,
  loginUser: state.user,
  qualificationList: state.qualification,
  documentList: state.document,
  files: state.file,
});

const mapDispatchToProps = {
  saveappli,
  getDeclrationById,
  getCourseDataById,
  deleteDeclrationData,
  getQualification,
  getDocument,
  downloadFile,
  deleteFile,
  getAlert,
  resetDelete,
  logOut,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationDelcareForm);
