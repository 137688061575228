import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid, Paper } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../Alert/alert.slice";
import {
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "../../components/Comman/IconButton";
import { DirectionSnackbar } from "../../components/Comman/SnakBarCompo"; //new added
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
import Search from "../../components/Search";
import * as myConstClass from "../../config/messageconstant";
import {
  deleteCategoryById,
  getCategory,
  saveCategory,
} from "./category.slice";
import CategoryExcelData from "./CategoryExcel";
import CategoryMasterForm from "./CategoryMasterForm";
import ViewCategory from "./CategoryView";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  { title: "Category Name", name: "categoryName" },
  { title: "Type", name: "type" },
  { title: "Status", name: "isActive" },
];

const CategoryMaster = ({
  getCategory,
  saveCategory,
  deleteCategoryById,
  catMaster,
  getAlert,
}) => {
  const [isEdit, setEdit] = useState(false);
  const [editRow, setEditRow] = useState([]);
  const [viewRow, setViewRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [filteropen, setFilterOpen] = useState(false);
  const [snakMsg, setSnakMsg] = useState("");
  const [openSnak, setOpenSnak] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredList, setfilterList] = React.useState([
    "Category Name",
    "Type",
    "Status",
  ]);

  const [categoryList, setCategoryList] = React.useState([
    [{ title: "Sr. No.", name: "index" }],
    [{ title: "Category Name", name: "categoryName" }],
    [{ title: "Type", name: "type" }],
    [{ title: "Status", name: "isActive" }],
  ]);
  const getRowID = async (id) => {
    setViewRow([]);
    const editedRow = catMaster?.category.filter((row) => row.id === id);
    if (editedRow.length > 0) {
      setEdit(true);
      setEditRow([]);
      return await setEditRow(editedRow);
    }
    return setEditRow([]);
  };

  const getRowData = async (id) => {
    setOpen(true);
    const viewRow = catMaster?.category.filter((row) => row.id === id);
    if (viewRow.length > 0) {
      return await setViewRow(viewRow);
    }
    return setViewRow([]);
  };

  const data = (instData) => {
    saveCategory({ categoryData: instData }).then((response) => {
      setOpenSnak(true);
      if (instData.id != null) {
        setSnakMsg(myConstClass.updateMsg);
      } else {
        setSnakMsg(myConstClass.saveMsg);
      }
      getCategory().then((respo) => {
        if (!respo) {
          getAlert({ message: myConstClass.serverMsg });
        }
      });
    });

    setEdit(false);
    setEditRow([]);
  };

  useEffect(async () => {
    getCategory().then((respo) => {
      if (!respo) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
  }, []);

  const startEdit = () => {
    setEdit(true);
  };

  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be retrived,Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteCategoryById({ categoryId: id }).then(() => {
          setOpenSnak(true);
          setSnakMsg(myConstClass.deleteMsg);
          getCategory().then((respo) => {
            if (!respo) {
              getAlert({ message: myConstClass.serverMsg });
            }
          });
        });
      }
    });
  };

  const filteredRows = (searchvalue) => {
    const { value } = searchvalue.target;
    setSearchValue(value);
    const filteredRow = catMaster?.category.filter((row) => {
      let categoryName = row?.categoryName;
      let type = row?.type;
      let isActive = row?.isActive;
      return (
        categoryName.toLowerCase().includes(value.toLowerCase()) ||
        type.toLowerCase().includes(value.toLowerCase()) ||
        isActive.toLowerCase() === value
      );
    });
  };

  const stopEdit = () => {
    setEditRow([]);
    setEdit(false);
  };
  const rowStatus = async (id) => {
    swal(myConstClass.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        const viewRow = catMaster?.category.filter((row) => row.id === id);
        let categoryData = {
          id: viewRow[0].id,
          name: viewRow[0].categoryName,
          type:
            viewRow[0].type === "Original Category"
              ? 0
              : viewRow[0].type === "defence"
              ? 1
              : viewRow[0].type === "handicap"
              ? 2
              : viewRow[0].type === "TFWS"
              ? 3
              : viewRow[0].type === "J&K"
              ? 4
              : 5,
          isActive: viewRow[0].isActive === "Active" ? "0" : "1",
          delStatus: 1,
        };
        saveCategory({ categoryData: categoryData }).then(() => {
          setOpenSnak(true);
          setSnakMsg(myConstClass.activeStatus);
          getCategory().then((respo) => {
            if (!respo) {
              getAlert({ message: myConstClass.serverMsg });
            }
          });
        });
      }
    });
  };

  const addFile = (file) => {};

  const saveAndNextData = (data) => {
    saveCategory({ categoryData: data }).then(() => {
      setOpenSnak(true);
      if (data.id != null) {
        setSnakMsg(myConstClass.updateMsg);
      } else {
        setSnakMsg(myConstClass.saveMsg);
      }
      getCategory().then((respo) => {
        if (!respo) {
          getAlert({ message: myConstClass.serverMsg });
        }
      });
    });
    setEdit(true);
    setEditRow([]);
  };
  const closeFilter = () => {
    setCategoryList([
      [{ title: "Sr. No.", name: "index" }],
      [{ title: "Category Name", name: "categoryName" }],
      [{ title: "Type", name: "type" }],
      [{ title: "Status", name: "isActive" }],
    ]);
    setfilterList(["Category Name", "Type", "Status"]);
    setFilterOpen(false);
  };

  const handleChange = (event) => {
    setFilterOpen(true);
    const {
      target: { value },
    } = event;

    if (value.length > filteredList.length) {
      const a = value.map((value) => {
        const cat = names.filter((row) => row.title === value);
        const cat1 = [...categoryList, cat];

        return setCategoryList(cat1);
      });
    } else {
      let difference = filteredList.filter((x) => !value.includes(x));
      const categoryListNew = categoryList.filter((data) => {
        return data[0].title !== difference[0];
      });
      setCategoryList(categoryListNew);
    }

    setfilterList(typeof value === "string" ? value.split(",") : value);

    if (value.length === 0) {
      setFilterOpen(false);
    }
  };

  return (
    <>
      <div>
        <Paper sx={{ p: 1, borderRadius: 2 }} elevation={2}>
          <TitleAndBreadCrum title="Category" />
        </Paper>
        &nbsp;&nbsp;
        {
          <DirectionSnackbar
            open={openSnak}
            severity="success"
            msg={snakMsg}
            closeSnak={setOpenSnak}
          />
        }
        <Paper sx={{ p: 4, borderRadius: 4 }} elevation={2}>
          <div>
            {viewRow.length >= 0 && editRow.length >= 0 && isEdit && (
              <CategoryMasterForm
                OnSave={data}
                isEdit={editRow}
                onCancel={stopEdit}
                handleFile={addFile}
                saveAndNext={saveAndNextData}
              />
            )}
          </div>

          {open && (
            <ViewCategory data={viewRow} openparam={open} closeView={setOpen} />
          )}

          <Grid>
            {!isEdit && (
              <div>
                <Box display="flex" justifyContent="space-between">
                  <Box>
                    {!filteropen && (
                      <Search
                        filteredRows={filteredRows}
                        // rows={row}
                        searchValue={searchValue}
                      />
                    )}
                  </Box>

                  <Box>
                    <FormControl sx={{ m: -1, width: 250 }}>
                      <InputLabel id="demo-multiple-checkbox-label">
                        Column Filter
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={filteredList}
                        name="sdf"
                        size="small"
                        onChange={handleChange}
                        input={<OutlinedInput label="Column Filter" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => {
                          return (
                            <MenuItem key={name.name} value={name.title}>
                              <Checkbox
                                checked={filteredList.indexOf(name.title) > -1}
                              />
                              <ListItemText primary={name.title} />
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>

                    {filteropen && (
                      <>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <CategoryExcelData
                          csvData={catMaster?.category}
                          catList={categoryList}
                          fileName="Category"
                          type="pdf"
                        />
                      </>
                    )}

                    {filteropen && (
                      <>
                        &nbsp;&nbsp;
                        <CategoryExcelData
                          csvData={catMaster?.category}
                          catList={categoryList}
                          fileName="Category"
                          type="excel"
                        />
                      </>
                    )}

                    {filteropen && (
                      <>
                        &nbsp;&nbsp;
                        <FontAwesomeIconCompo
                          className="fa-times-circle"
                          color="primary"
                          title="Cancel"
                          baseClassName="fas"
                          onClick={closeFilter}
                        />
                      </>
                    )}

                    {!filteropen && filteredList.length >= 0 && (
                      <>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <CategoryExcelData
                          csvData={catMaster?.category}
                          catList={categoryList}
                          fileName="Category"
                          type="pdf"
                        />
                      </>
                    )}

                    {!filteropen && filteredList.length >= 0 && (
                      <>
                        &nbsp;&nbsp;
                        <CategoryExcelData
                          csvData={catMaster?.category}
                          catList={categoryList}
                          fileName="Category"
                          type="excel"
                        />
                      </>
                    )}

                    {!filteropen && filteredList.length >= 0 && (
                      <>
                        &nbsp;&nbsp;&nbsp;
                        <MyComponentWithIconProps
                          statusImage={AddCircleIcon}
                          color="primary"
                          fontSize="large"
                          sx={{ m: -1 }}
                          onClick={startEdit}
                        />
                      </>
                    )}
                  </Box>
                </Box>

                {/* <CategoryTable
                  filteredColumns={categoryList}
                  data={catMaster?.category}
                  rowEdit={getRowID}
                  rowView={getRowData}
                  rowDelete={deleteData}
                  rowStatus={rowStatus}
                /> */}
              </div>
            )}
          </Grid>
        </Paper>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  catMaster: state.category,

  common: state.common,
});

const mapDispatchToProps = {
  getCategory,
  saveCategory,
  getAlert,
  deleteCategoryById,
};
export default connect(mapStateToProps, mapDispatchToProps)(CategoryMaster);
