import { FormHelperText, Grid, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { Loading1 } from "../../components/Loading1";
import * as myConstClass from "../../config/messageconstant";
import { deleteCategoryData, getCategoryByName } from "./category.slice";
let docs;
class CategoryMasterForm extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    id: 0,
    categoryName: "",
    type: "",
    isActive: 1,
    formErrors: [],
    breadCrum: "",
    flag: 0,
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  componentDidMount() {
    const { isEdit } = this.props;
    if (isEdit.length > 0) {
      this.setState({
        id: isEdit[0].id,
        categoryName: isEdit[0].categoryName,
        type: isEdit[0].type,
        isActive: isEdit[0].isActive === "Active" ? "1" : "0",
      });
    }
  }

  componentDidUpdate() {
    if (this.props.category.isFetch) {
      const { id, categoryName, type, isActive, flag } = this.state;

      docs = {
        name: categoryName,
        type: type,
        isActive: isActive,
        delStatus: 1,
      };

      if (id != 0) {
        docs = {
          ...docs,
          id: id,
        };
      }
      if (this.props.category.categoryData.length == 0) {
        if (flag == 1) {
          this.props.saveAndNext(docs);
          this.setState({
            id: 0,
            categoryName: "",
            type: "",
            isActive: "0",
            formErrors: [],
            flag: 0,
          });
        } else if (flag == 2) {
          this.props.OnSave(docs);
        }
      } else {
        let formErrors = {};
        if (this.props.category.categoryData.length != 0) {
          formErrors["categoryNameErr"] = myConstClass.catUniMsg;
        }

        this.setState({ formErrors: formErrors });
      }
      this.props.deleteCategoryData();
    }
  }

  handleFormValidation(name) {
    const { categoryName, type } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (categoryName === "") {
      formIsValid = false;
      formErrors["categoryNameErr"] = myConstClass.catNameMsg;
    }

    if (type === "") {
      formIsValid = false;
      formErrors["typeErr"] = myConstClass.typeEmptyMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const { id, categoryName, type, isActive } = this.state;
    if (this.handleFormValidation()) {
      this.setState({
        flag: 2,
      });
      this.props.getCategoryByName({ categoryName: categoryName });
    }
  };

  saveHandler = (e) => {
    e.preventDefault();
    this.setState({
      flag: 1,
    });
    const { id, categoryName, type, isActive } = this.state;
    if (this.handleFormValidation()) {
      this.setState({
        flag: 1,
      });
      this.props.getCategoryByName({ categoryName: categoryName });
    }
  };

  render() {
    const { categoryName, type, flag } = this.state;
    const { categoryNameErr, typeErr } = this.state.formErrors;
    const { onCancel, category } = this.props;
    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={4}
          >
            <Grid
              sx={{ m: -1 }}
              item
              xs={2}
              md={12}
              size="small"
              container
              justifyContent="flex-end"
            >
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name=" Back to List"
                onClick={onCancel}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="*Category Name"
                placeholder="*Enter your category name"
                color="primary"
                name="categoryName"
                id="categoryName"
                size="small"
                value={categoryName}
                onChange={this.changeHandler}
                fullWidth
                {...(categoryNameErr
                  ? { error: true, helperText: categoryNameErr }
                  : "")}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <FormControl color={"primary"} fullWidth={true}>
                <InputLabel
                  id="demo-simple-select-helper-label"
                  className="text-black"
                >
                  *Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="*Type"
                  className="text-black"
                  name="type"
                  value={type}
                  onChange={this.changeHandler}
                  size="small"
                  fullWidth={true}
                  {...(typeErr ? { error: true, helperText: typeErr } : "")}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="0">Original Category</MenuItem>
                  <MenuItem value="1">defence</MenuItem>
                  <MenuItem value="2">handicap</MenuItem>
                  <MenuItem value="3">TFWS</MenuItem>
                  <MenuItem value="4">J&K</MenuItem>
                  <MenuItem value="5">EWS</MenuItem>
                </Select>
                {typeErr && (
                  <FormHelperText error>
                    {myConstClass.typeEmptyMsg}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4}></Grid>

            <Grid item xs={2} md={12} container justifyContent="flex-end">
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Save"
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <ButtonCompo
                size="medium"
                type="button"
                variant="contained"
                name="Save and Next"
                onClick={this.saveHandler}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <ButtonCompo
                size="medium"
                type="button"
                variant="contained"
                name="Cancel"
                onClick={onCancel}
              />
            </Grid>
          </Grid>
          {flag != 0 && !category.isFetch && <Loading1 size={50} />}
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  category: state.category,
  common: state.common,
});

const mapDispatchToProps = {
  getCategoryByName,
  deleteCategoryData,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryMasterForm);
