import { Grid, TextField } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import { Loading1 } from "../../components/Loading1";
import * as myConstClass from "../../config/messageconstant";
import { deleteDocumentData, getDocumentByName } from "./document.slice";
let docs;
class DocumentMForm extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    id: 0,
    docName: "",
    sortOrderNo: "",
    trustId: "",
    isActive: "1",
    formErrors: [],
    flag: 0,
  };

  saveHandler = (e) => {
    e.preventDefault();
    this.setState({
      flag: 1,
    });
    const { id, docName, sortOrderNo, trustId, isActive } = this.state;
    docs = {
      name: docName,
      sortOrderNo: sortOrderNo,
      isActive: isActive,
      delStatus: 1,
      trust: {
        id: trustId,
      },
    };
    if (this.handleFormValidation()) {
      this.props.getDocumentByName({ id: id, docName: docName.trim() });
    }
    if (this.handleFormValidation()) {
      this.props.saveAndNext(docs);
      this.setState({
        id: 0,
        docName: "",
        sortOrderNo: "",
      });
    }
  };

  handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({ logo: e.target.result });
    };
    reader.readAsDataURL(event.target.files[0]);
    this.props.handleFile(fileUploaded);
  };
  handleClick = (event) => {
    this._name.click();
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  componentDidMount() {
    const { isEdit, breadCrum } = this.props;
    let trustId = localStorage.getItem("trustId");
    this.setState({
      trustId: trustId,
    });
    if (isEdit.length > 0) {
      this.setState({
        id: isEdit[0].id,
        docName: isEdit[0].docName,
        sortOrderNo: isEdit[0].sortOrderNo,
        isActive: isEdit[0].isActive === "Active" ? 1 : 0,
      });
    }
  }

  componentDidUpdate() {
    if (this.props.document.isFetch) {
      const { id, docName, sortOrderNo, isActive, flag, trustId } = this.state;
      docs = {
        name: docName,
        sortOrderNo: sortOrderNo,
        isActive: isActive,
        delStatus: 1,
        trust: {
          id: trustId,
        },
      };

      if (id != 0) {
        docs = {
          ...docs,
          id: id,
        };
      }

      if (this.props.document.documentData.length == 0) {
        if (flag == 1) {
          this.props.saveAndNext(docs);
          this.setState({
            id: 0,
            docName: "",
            sortOrderNo: "",
            isActive: "1",
            formErrors: [],
            flag: 0,
          });
        } else if (flag == 2) {
          this.props.OnSave(docs);
        }
      } else {
        let formErrors = {};
        if (this.props.document.documentData.length != 0) {
          formErrors["docNameErr"] = myConstClass.docUniMsg;
        }
        this.setState({ formErrors: formErrors });
      }
      this.props.deleteDocumentData();
    }
  }

  handleFormValidation(name) {
    const { docName, sortOrderNo } = this.state;
    let formErrors = {};
    let formIsValid = true;
    if (docName.trim() === "") {
      formIsValid = false;
      formErrors["docNameErr"] = myConstClass.docNameMsg;
    }
    if (sortOrderNo === "") {
      formIsValid = false;
      formErrors["sortOrderNoErr"] = myConstClass.sortOrdNoMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    this.setState({
      flag: 2,
    });
    const { id, docName, sortOrderNo, trustId, isActive } = this.state;
    docs = {
      name: docName,
      sortOrderNo: sortOrderNo,
      isActive: isActive,
      delStatus: 1,
      trust: {
        id: trustId,
      },
    };
    if (this.handleFormValidation()) {
      this.props.getDocumentByName({ id: id, docName: docName.trim() });
    } else {
      if (this.handleFormValidation()) {
        this.props.OnSave(docs);
      }
    }
  };

  render() {
    const { flag, docName, sortOrderNo } = this.state;
    const { docNameErr, sortOrderNoErr } = this.state.formErrors;
    const { onCancel, document } = this.props;

    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            <Grid
              sx={{ m: -1 }}
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              size="small"
              container
              justifyContent="flex-end"
            >
              <ButtonCompo
                size="small"
                type="button"
                variant="outlined"
                name=" Back to List"
                onClick={onCancel}
              />
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
              md={6}
              lg={6}
              rowSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={6} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 14 }}
                  label="*Document Name :"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <TextField
                  color="primary"
                  name="docName"
                  size="small"
                  value={docName}
                  fullWidth
                  onChange={this.changeHandler}
                  error={docNameErr ? true : false}
                  helperText={docNameErr ? docNameErr : " "}
                />
              </Grid>
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
              md={6}
              lg={6}
              rowSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={6} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 14 }}
                  label="*Sort Order Number :"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <TextField
                  color="primary"
                  name="sortOrderNo"
                  size="small"
                  type={"number"}
                  value={sortOrderNo}
                  fullWidth
                  onChange={this.changeHandler}
                  error={sortOrderNoErr ? true : false}
                  helperText={sortOrderNoErr ? sortOrderNoErr : " "}
                />
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={1.2}
                lg={1.2}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Save"
                  fullWidth={true}
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={1.8}
                lg={1.8}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Save and Next"
                  fullWidth={true}
                  onClick={this.saveHandler}
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={1.2}
                lg={1.2}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Cancel"
                  fullWidth={true}
                  onClick={onCancel}
                />
              </Grid>
            </Grid>
          </Grid>
          {flag != 0 && !document.isFetch && !docNameErr && (
            <Loading1 size={50} />
          )}
        </form>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  document: state.document,
  common: state.common,
});
const mapDispatchToProps = {
  getDocumentByName,
  deleteDocumentData,
};
export default connect(mapStateToProps, mapDispatchToProps)(DocumentMForm);
