import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import Alert from "../../Alert/Alert";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicTable from "../../components/Comman/MaterialUIRTTable";
import { Loading1 } from "../../components/Loading1";
import * as myConstClass from "../../config/messageconstant";
import PriorityProcess from "../ApplicationForm/PriorityProcess";
import { logOut } from "../Login/login.slice";
import {
  getCourseDataById,
  getDeclration,
  getDeclrationById,
} from "../onlineAppProcess/declaration.slice";
import RoundDetails from "../RoundDetails";
import SearchExternalPlacedStudList from "../../components/SearchTable";
import { getAlert } from "../../Alert/alert.slice";
import ListOfApplicationDirectAdmission from "../StudentDirectAdmission/studentDirectAdmissionAppliedList";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const rows = [
  {
    id: 1,
    application: "10th Form",
  },
  {
    id: 2,
    application: "12th Form",
  },
  {
    id: 3,
    application: "Bsc Form",
  },
];

const names = [
  { title: "Trust Name", name: "trustName" },
  { title: "Institute Name", name: "instName" },
  { title: "Institute Short Name", name: "application" },
  { title: "Institute Affiliation", name: "instAffi" },
  { title: "Educational Status", name: "isEducational" },
  { title: "EPF Number", name: "EPFNo" },
  { title: "Prof. Tax number", name: "taxNo" },
  { title: "TAN Number", name: "tanNo" },
  { title: "PAN Number", name: "panNo" },
  { title: "University Code", name: "uniCode" },
  { title: "AICTE Code", name: "AICTE" },
  { title: "DTE Code", name: "DTECode" },
  { title: "Active Status", name: "isActive" },
];

let newDocs;

const ListOfApplication = ({
  applicationList,
  getAlert,
  getDeclration,
  resetDelete,
  loginUser,
  logOut,
  getDeclrationById,
  getCourseDataById,
}) => {
  const [appId, setAppId] = useState(0);
  const [roundView, setRoundView] = useState(0);
  const [isEdit, setEdit] = useState(false);
  const [row, setRows] = useState(rows);
  const [id1, setId] = useState("");
  const [editRow, setEditRow] = useState([]);
  const [viewRow, setViewRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [capForm, setCapForm] = useState(false);
  const [filteropen, setFilterOpen] = useState(false);
  const [FL, setFL] = React.useState([]);
  const [filteredList, setfilterList] = React.useState([]);
  const [trustList, setTrustList] = React.useState([]);
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();

  const round = async (id) => {
    setRoundView(1);
    setAppId(id);
  };

  /* 
  useEffect(() => {},[]); */

  useEffect(() => {
    getDeclration().then((response) => {
      if (!response) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
  }, []);

  useEffect(() => {
    if (loginUser.token) {
      localStorage.removeItem("token");
      navigate("/login");
      logOut();
    }
  });

  const getUserRowData = (id) => {
    getDeclrationById({ appliId: id }).then((resp) => {
      if (!resp) {
        getAlert({ message: myConstClass.serverMsg });
      } else {
        getCourseDataById({ ids: resp?.programTitleIds }).then((response) => {
          if (!response) {
            getAlert({ message: myConstClass.serverMsg });
          }
          navigate("/userdeclarationpreview");
          localStorage.setItem("listLocation", "/listofapplication");
        });
      }
    });
  };

  const getRowData = async (id) => {
    setOpen(true);
    const viewRow = row.filter((row) => row.id === id);

    if (viewRow.length > 0) {
      return await setViewRow(viewRow);
    }
    return setViewRow([]);
  };
  const capAdd = (id) => {
    setId(id);

    setCapForm(true);
  };
  const goTolistOfApplications = () => {
    setRoundView(0);
  };
  const getFilteredTableData = () => {
    return applicationList?.appli.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["title"] &&
          currentRow["title"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["index"] &&
          currentRow["index"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["intrestedPrograms"] &&
          currentRow["intrestedPrograms"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["lastDate"] &&
          currentRow["lastDate"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  const onSearch = (value) => {
    setSearchValue(value);
  };
  return (
    <>
      <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
        {/* {applicationList.isServerFail && (
          <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />
        )} */}

        {roundView == 0 && (
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            spacing={2}
            columnSpacing={1}
            container
            sx={{ m: 0.5 }}
            justifyContent="space-evenly"
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={1}
              container
              justifyContent="row"
            >
              {/* <Grid item xs={12} sm={12} md={0.5}></Grid> */}
              <Grid
                item
                xs={12}
                sm={!capForm && roundView == 0 ? 9 : 12}
                md={!capForm && roundView == 0 ? 9 : 12}
              >
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 20 }}
                  label="Applied Application Form"
                />
              </Grid>
              {!capForm && roundView == 0 && (
                <Grid sx={{ marginBottom: 2 }} item xs={12} sm={3} md={3}>
                  <SearchExternalPlacedStudList onSearch={onSearch} />
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        {!capForm && roundView == 0 && (
          <>
            <DynamicTable
              tableHead={[
                {
                  title: "Sr. No.",
                  name: "index",
                  positionCenter: true,
                  showInscreen: true,
                  alignCenter: "left",
                },
                {
                  title: "Applications",
                  name: "title",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "left",
                },
                {
                  title: "Intrested Program",
                  name: "intrestedPrograms",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "left",
                },
                {
                  title: "Last Date",
                  name: "lastDate",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "left",
                },
                {
                  title: "Action",
                  name: "action",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "left",
                },
              ]}
              data={getFilteredTableData()}
              rowView={getRowData}
              showHeadDelete={false}
              showHeadEdit={false}
              rounds={round}
              showApply={false}
              showRoundDetails={true}
              showView={true}
              rowViewData={getUserRowData}
              showPegination={false}
            />
            {/* <ListOfApplicationTable
              filteredColumns={trustList}
              data={applicationList?.appli}
              rounds={round}
              rowView={getRowData}
              capAdd={capAdd}
           
            /> */}
            {!applicationList.isServerFail && !applicationList?.isFetch ? (
              <Loading1 size={50} />
            ) : (
              ""
            )}
            <ListOfApplicationDirectAdmission />
          </>
        )}
        {capForm && <PriorityProcess appliId={id1} />}
        {!capForm && roundView == 1 && (
          <RoundDetails
            apllicationId={appId}
            goTolistOfApp={goTolistOfApplications}
          />
        )}
        <br></br>
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({
  applicationList: state.appli,
  loginUser: state.user,
});

const mapDispatchToProps = {
  getDeclration,
  // resetDelete,
  logOut,
  getDeclrationById,
  getCourseDataById,
  getAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListOfApplication);
