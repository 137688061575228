import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { decodeData } from "../../components/Comman/Util/Base64";
import endpoints from "../../config/endpoints";
import { tokenExp } from "../Login/login.slice";

const initialState = {
  ClassIntakeDetail: [],
  classData: {},
  classIntakeDetailData: [],
  ClassIntakeDetailFilter: [],
  isFilter: false,
  isFetch: false,
  isSave: false,
  error: false,
  isServerFail: false,
  isDelete: false,


};
let URL = endpoints.ClassIntakeDetail;
const classIntakeDetailSlice = createSlice( {
  name: "ClassIntakeDetail",
  initialState,
  reducers: {
    classIntakeDetailSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        isFetch: true,
        ClassIntakeDetail: row,
      };
    },
    ClassIntakeSaveSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { data } = action.payload;
      return {
        ...state,
        classData: data,
        error: false,
        isSave: true,
      };
    },

    classIntakeDetailsFail: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        classData: {},
        isSave: false,
      };
    },
    ClassIntakeDetailFilterSuccess: (
      state = cloneDeep( initialState ),
      action
    ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        ClassIntakeDetailFilter: row,
        isFilter: true,
      };
    },
    classIntakeDetailDeleteSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        error: false,
        isDelete: true,
      };
    },
    ClassIntakeDetailFilterFailure: (
      state = cloneDeep( initialState ),
      action
    ) =>
    {
      return {
        ...state,
        ClassIntakeDetailFilter: [],
        isFilter: false,
      };
    },

    ClassIntakeSaveFailure: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        error: true,
        classData: {},
        isSave: true,
      };
    },
    ClassIntakeSaveReset: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        error: false,
        isSave: false,
      };
    },

    classIntakeDetailDeleteFail: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        error: true,
        isDelete: true,
        isSave: false,
      };
    },
    ClassIntakeFailure: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        classData: {},
        isSave: false,
      };
    },

    classIntakeDetailFailure: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        isFetch: false,
        ClassIntakeDetail: [],
      };
    },
    serverFailed: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        isServerFail: true,
        error: false,
        isDelete: false
      };
    },
    resetDeleteSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        error: false,
        isDelete: false,
        isSave: false,
      };
    },
  },
} );

export const {
  documentSuccess,
  ClassIntakeDetailFilterFailure,
  classIntakeDetailDeleteSuccess,
  ClassIntakeDetailFilterSuccess,
  ClassIntakeFailure,
  resetDeleteSuccess,
  classIntakeDetailSuccess,
  classIntakeDetailsFail,
  classIntakeDetailDeleteFail,
  classIntakeDetailFailure,
  ClassIntakeSaveSuccess,
  serverFailed,
  ClassIntakeSaveFailure,
  ClassIntakeSaveReset
} = classIntakeDetailSlice.actions;

export default classIntakeDetailSlice.reducer;

export const getClassIntakeDetail = () => async ( dispatch ) =>
{
  try
  {
    const name = localStorage.getItem( "token" );
    const token = decodeData( name );
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get( URL + '?sort={"insertdatetime": "DESC"}', { headers } )
      .then( ( response ) =>
      {
        const { error } = response.data
        if ( error == true )
        {
          dispatch( tokenExp() );

        }
        else
        {
          let data = response.data.data.content;
          let row = [];
          let index = 0;
          data.map( ( data1 ) =>
          {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              acYearName: data1.academicYear.name,
              acYearId: data1.academicYear.id,
              branchName: data1.yearDetail.programTitle.brName,
              name:
                data1.yearDetail.year.tenant.tenantName +
                " - " +
                data1.yearDetail.year.programType.name +
                "-" +
                data1.yearDetail.programTitle.brName +
                " - " +
                data1.yearDetail.year.className +
                " - " + data1.academicYear.name,
              sanctionIntake: data1.actualSanctionIntake,
              lateralIntake: data1.sanctionIntake,
              isActive: data1.isActive === 1 ? "Active" : "Inactive",
              pgName: data1.yearDetail.programTitle.programType.program.name,
              tName: data1.yearDetail.year.tenant.tenantName,
              tenantId: data1.yearDetail.year.tenant.id,
              progName: data1.academicYear.id,
              classDetailId: data1.yearDetail.id,
            };
            row.push( bData );
          } );
          dispatch( classIntakeDetailSuccess( { row } ) );
          return row;
        }
      } )

      .catch( ( error ) =>
      {
        dispatch( serverFailed() );
        console.log( "Error getDocument========>", error );
        return false;
      } );
    return doc;
  } catch ( e )
  {
    dispatch( serverFailed() );
    return false;


  }
};

export const resetSave = () => async ( dispatch ) =>
{
  try
  {
    dispatch( classIntakeDetailsFail() );
  } catch ( e )
  {
    return console.error( e.message );
  }
};
export const saveClassIntakeDetail =
  ( { classIntakeDetailData } ) =>
    async ( dispatch ) =>
    {
      try
      {
        let data = JSON.stringify( classIntakeDetailData );
        const name = localStorage.getItem( "token" );
        const token = decodeData( name );
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
          shouldrefresh: "true",
        };
        let doc = await axios
          .post( URL, data, { headers } )
          .then( ( response ) =>
          {
            const { error } = response.data
            if ( error == true )
            {
              dispatch( tokenExp() );

            }
            console.log( "Success With saveClassIntakeDetail ========>", response );
            const { data } = response.data;
            dispatch( ClassIntakeSaveSuccess( { data } ) );
            return data;
          } )
          .catch( ( error ) =>
          {
            console.log(
              "Error saveClassIntakeDetail ========>", error.response.data
            );
            dispatch( ClassIntakeSaveFailure() );
            // dispatch(serverFailed())
            return false;


          } );
        return doc;
      } catch ( e )
      {
        dispatch( serverFailed() )
        return false;
        // return console.error(e.message);
      }
    };

export const deleteClassIntakeDetailById =
  ( { classIntakeDetailId } ) =>
    async ( dispatch ) =>
    {
      try
      {
        console.log( "delete classIntakeDetail " );
        const name = localStorage.getItem( "token" );
        const token = decodeData( name );
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
          shouldrefresh: "true",
        };
        await axios
          .delete( URL + "/" + classIntakeDetailId, { headers } )
          .then( ( response ) =>
          {
            console.log(
              "Success With deleteClassIntakeDetailById ========>",
              response

            );
            dispatch( classIntakeDetailDeleteSuccess() );
            return true;

          } )
          .catch( ( error ) =>
          {
            if ( error.response )
            {
              console.log( 'error.response.status' )
              console.log( error.response.status )
              console.log( 'error.response.data' )
              console.log( error.response.data )
            }
            dispatch( classIntakeDetailDeleteFail() );
            return false;
            // return error.response.data;
          } );
      } catch ( e )
      {
        dispatch( serverFailed() );
        return false;
        // return console.error(e.message);
      }
    };

export const saveClassIntakeDetailFail = () => async ( dispatch ) =>
{
  try
  {
    dispatch( ClassIntakeFailure() );
  } catch ( e )
  {
    return console.error( e.message );
  }
};
export const filterClassIntakeData =
  ( { row, flag } ) =>
    async ( dispatch ) =>
    {
      try
      {
        if ( flag == true )
        {
          dispatch( ClassIntakeDetailFilterSuccess( { row } ) );
        } else
        {
          dispatch( classIntakeDetailSuccess( { row } ) );
        }
      } catch ( e )
      {
        return console.error( e.message );
      }
    };
export const resetDelete = () => async ( dispatch ) =>
{
  try
  {
    dispatch( resetDeleteSuccess() );
  } catch ( e )
  {
    return console.error( e.message );
  }
};

export const resetClassSave = () => async ( dispatch ) =>
{
  try
  {
    dispatch( ClassIntakeSaveReset() );
  } catch ( e )
  {
    return console.error( e.message );
  }
};