import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { decodeData } from "../../components/Comman/Util/Base64";
import endpoints from "../../config/endpoints";
import { tokenExp } from "../Login/login.slice";

const initialState = {
  user: [],
  isFetch: false,

  bloodGroup: [],
  isFetchbg: false,

  annualIncome: [],
  isFetchannualIncome: false,

  state: [],
  isFetchstate: false,

  district: [],
  isFetchDistrict: false,

  taluka: [],
  isFetchTaluka: false,

  applicant: {},
  isSave: false,

  profile: {},
  isFetch: false,

  error: false,
  isServerFail: false,

  parentMobileValidate: true,
  // basicDetailsData:[],
  // isFetchannualIncome:false,
};

let URL = endpoints.userRegistration;
let profileURL = endpoints.profileInfo;
const studentSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    studentSuccess: (state = cloneDeep(initialState), action) => {
      console.log(action.payload);
      const { row } = action.payload;
      return {
        ...state,
        isFetch: true,
        user: row,
      };
    },
    studentFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isFetch: false,
        user: [],
      };
    },

    applicantSuccess: (state = cloneDeep(initialState), action) => {
      console.log(action.payload);
      const { userRegistrationSave } = action.payload;
      return {
        ...state,
        isSave: true,
        applicant: userRegistrationSave,
      };
    },

    applicantFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isSave: false,
        applicant: {},
      };
    },
    studentbloodgroupSuccess: (state = cloneDeep(initialState), action) => {
      console.log(action.payload);
      const { row } = action.payload;
      return {
        ...state,
        isFetchbg: true,
        bloodGroup: row,
      };
    },
    studentbloodgroupFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isFetchbg: false,
        bloodGroup: [],
      };
    },

    studentannualIncomeSuccess: (state = cloneDeep(initialState), action) => {
      console.log(action.payload);
      const { row } = action.payload;
      return {
        ...state,
        isFetchannualIncome: true,
        annualIncome: row,
      };
    },

    studentannualIncomeFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isFetchannualIncome: false,
        annualIncome: [],
      };
    },

    studentStateSuccess: (state = cloneDeep(initialState), action) => {
      console.log(action.payload);
      const { row } = action.payload;
      return {
        ...state,
        isFetchstate: true,
        state: row,
      };
    },
    studentStateFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isFetchstate: false,
        state: [],
      };
    },

    //For District

    studentDistrictSuccess: (state = cloneDeep(initialState), action) => {
      console.log(action.payload);
      const { row } = action.payload;
      return {
        ...state,
        isFetchDistrict: true,
        district: row,
      };
    },
    studentDistrictFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isFetchDistrict: false,
        district: [],
      };
    },

    studenTalukaSuccess: (state = cloneDeep(initialState), action) => {
      console.log(action.payload);
      const { row } = action.payload;
      return {
        ...state,
        isFetchTaluka: true,
        taluka: row,
      };
    },
    studentTalukaFailure: (state = cloneDeep(initialState), action) => {
      console.log("hi");
      return {
        ...state,
        isFetchTaluka: false,
        taluka: [],
      };
    },

    profileSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        profile: data,
        isFetch: true,
      };
    },
    profileFailure: (state = cloneDeep(initialState), action) => {
      console.log("hi");
      return {
        ...state,
        profile: {},
        isFetch: false,
      };
    },

    serverFailed: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: true,
        error: false,
        isSave: false,
      };
    },

    resetDeleteSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error: false,
        isSave: false,
        isServerFail: false,
      };
    },

    parentMobileSuccess: (state = cloneDeep(initialState), action) => {
      const { error } = action.payload;
      return {
        ...state,
        parentMobileValidate: error,
      };
    },
  },
});

export const {
  resetDeleteSuccess,
  serverFailed,
  profileSuccess,
  profileFailure,
  applicantFailure,
  applicantSuccess,
  studentSuccess,
  studentbloodgroupSuccess,
  studentFailure,
  studentannualIncomeSuccess,
  studentStateSuccess,
  studentDistrictSuccess,
  studenTalukaSuccess,
  parentMobileSuccess,
} = studentSlice.actions;

export default studentSlice.reducer;

export const getStudent = () => async (dispatch) => {
  try {
    console.log("get students /////");
    console.log("URL" + URL);
    console.log(Headers);
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get(URL + '?filters={"delStatus": "1","_mode" : "EXACT"}', { headers })
      .then((response) => {
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          console.log(
            "Success getStudent ========>",
            response.data.data.content
          );
          let data = response.data.data.content;
          console.log("abc");
          let row = [];
          let index = 0;
          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              firstName: data1.firstName,
              middleName: data1.middleName !== null ? data1.middleName : "",
              lastName: data1.lastName !== null ? data1.lastName : "",
              motherName: data1.motherName,
              gender: data1.gender,
              // admissionStatus: data1.name,
              isActive: data1.isActive === 1 ? "Active" : "Inactive",
            };
            row.push(bData);
            console.log(row);
            return bData;
          });
          dispatch(studentSuccess({ row }));
          return row;
        }
      })
      .catch((error) => {
        console.log("Error getStudent========>", error);
        dispatch(serverFailed());
        return false;
      });
    return doc;
  } catch (e) {
    dispatch(serverFailed());
    return false;
    // return console.error(e.message);
  }
};

// getMethod for blood group
let URL1 = endpoints.bloodGroup;
export const getBloodGroup = () => async (dispatch) => {
  try {
    console.log("get blood group /////");
    console.log("URL" + URL1);
    console.log(Headers);
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get(URL1 + '?filters={"delStatus": "1","_mode" : "EXACT"}', { headers })
      .then((response) => {
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          console.log("Success getBloodgroup ========>", response.data.data);
          let data = response.data.data.content;
          console.log(data);
          let row = [];
          let index = 0;

          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              isActive: data1.isActive === 1 ? "Active" : "Inactive",
            };
            row.push(bData);
            console.log(row);
            return bData;
          });
          dispatch(studentbloodgroupSuccess({ row }));
          return row;
        }
      })
      .catch((error) => {
        console.log("Error getBlood Group========>", error);
        dispatch(serverFailed());
        return false;
      });
    return doc;
  } catch (e) {
    dispatch(serverFailed());
    return false;
    // return console.error(e.message);
  }
};
//

//get Method for annual income
let annualurl = endpoints.annualIncome;
export const getAnnualIncome = () => async (dispatch) => {
  try {
    console.log("get Annual Income /////");
    console.log("URL" + annualurl);
    console.log(Headers);
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get(annualurl + '?filters={"delStatus": "1","_mode" : "EXACT"}', {
        headers,
      })
      .then((response) => {
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          console.log("Success :: Annual Income ========>", response.data.data);
          let data = response.data.data.content;
          console.log(data);
          let row = [];
          let index = 0;
          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              isActive: data1.isActive === 1 ? "Active" : "Inactive",
            };
            row.push(bData);
            console.log(row);
            return bData;
          });
          dispatch(studentannualIncomeSuccess({ row }));
          return row;
        }
      })
      .catch((error) => {
        console.log("Error getStudent========>", error);
        dispatch(serverFailed());
        return false;
      });
    return doc;
  } catch (e) {
    dispatch(serverFailed());
    return false;
    // return console.error(e.message);
  }
};
//

export const saveUserIntrest =
  ({ intrestedIn }) =>
  async (dispatch) => {
    try {
      console.log(intrestedIn);
      console.log(URL + "/intrest-in?intrestIn=" + intrestedIn + "");
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .post(
          URL + "/intrest-in?intrestIn=" + intrestedIn + "",
          {},
          { headers }
        )
        // .post('http://192.168.2.21:8088/application/v1/user-registration/intrest-in?intrestIn=c446616e-2cb2-4f2e-8dbc-e89fdd64de09',{}, { headers })

        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            console.log("Success With saveStudent ========>", response);
          }
          return response;
        })
        .catch((error) => {
          console.log("Error ========>", error);
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;

      // return console.error( e.message );
    }
  };

export const deleteStudentById =
  ({ studentId }) =>
  async (dispatch) => {
    try {
      console.log("delete students ");
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .delete(URL + "/" + studentId, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            console.log(
              "Success With deletedeleteStudentById ========>",
              response
            );
          }
          return response;
        })
        .catch((error) => {
          console.log("Error deleteStudentById ========>", error.response.data);
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;

      // return console.error( e.message );
    }
  };

// save method for basic details
// saveBasicDetails

export const saveStudent =
  ({ studData }) =>
  async (dispatch) => {
    try {
      let data = JSON.stringify(studData);
      console.log(data);
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .post(URL, data, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            console.log("Success with save  ========>", response);
            const { userRegistrationSave } = response.data.data.data;
            console.log("saveStudent");
            console.log(userRegistrationSave);
            dispatch(applicantSuccess({ userRegistrationSave }));
            return data;
          }
        })
        .catch((error) => {
          console.log("Error save category=======>", error.response.data);
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;

      // return console.error( e.message );
    }
  };

// GetState

let stateurl = endpoints.state;
export const getState = () => async (dispatch) => {
  try {
    console.log("get State /////");
    console.log("URL" + stateurl);
    console.log(Headers);
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    let doc = await axios
      .get(stateurl, { headers })
      .then((response) => {
        const { error } = response.data;
        if (error == true) {
          dispatch(token());
        } else {
          console.log("Success getState ========>", response.data.data);
          let data = response.data.data.content;
          console.log(data);
          let row = [];
          let index = 0;

          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              isActive: data1.isActive === 1 ? "Active" : "Inactive",
            };
            row.push(bData);
            console.log(row);
            return bData;
          });

          dispatch(studentStateSuccess({ row }));
          return row;
        }
      })
      .catch((error) => {
        console.log("Error getState========>", error);
        dispatch(serverFailed());
        return false;
      });
    return doc;
  } catch (e) {
    dispatch(serverFailed());
    return false;

    // return console.error( e.message );
  }
};

let disturl = endpoints.district;
export const getDist = () => async (dispatch) => {
  try {
    console.log("get District /////");
    console.log("URL" + disturl);
    console.log(Headers);
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get(disturl, { headers })
      .then((response) => {
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          console.log("Success getDist ========>", response.data.data);
          let data = response.data.data.content;
          console.log(data);
          let row = [];
          let index = 0;
          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              isActive: data1.isActive === 1 ? "Active" : "Inactive",
              stid: data1.state.id,
            };
            row.push(bData);
            console.log(row);
            return bData;
          });
          dispatch(studentDistrictSuccess({ row }));
          return row;
        }
      })
      .catch((error) => {
        console.log("Error getState========>", error);
        dispatch(serverFailed());
        return false;
      });
    return doc;
  } catch (e) {
    dispatch(serverFailed());
    return false;

    // return console.error( e.message );
  }
};

let talkurl = endpoints.taluka;
export const getTal = () => async (dispatch) => {
  try {
    console.log("get Taluka /////");
    console.log("URL" + talkurl);
    console.log(Headers);
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get(talkurl, { headers })
      .then((response) => {
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          console.log("Success getTaluka ========>", response.data.data);
          let data = response.data.data.content;
          console.log(data);
          let row = [];
          let index = 0;
          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              distid: data1.district.id,
              isActive: data1.isActive === 1 ? "Active" : "Inactive",
            };
            row.push(bData);
            console.log(row);
            return bData;
          });

          dispatch(studenTalukaSuccess({ row }));
          return row;
        }
      })

      .catch((error) => {
        console.log("Error getTaluka========>", error);
        dispatch(serverFailed());
        return false;
      });
    return doc;
  } catch (e) {
    dispatch(serverFailed());
    return false;
    // return console.error( e.message );
  }
};

export const getStudentById =
  ({ studentId }) =>
  async (dispatch) => {
    try {
      console.log("get Student By Id /////");
      console.log("URL" + URL);
      console.log("studentId" + studentId);
      console.log(Headers);
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .get(URL + "/" + studentId, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          } else {
            console.log("Success Student By Id  ========>", response.data.data);
            const { data } = response.data;
            console.log("***********");
            console.log(data);
            let userRegistrationSave = {
              id: data.id,
              userId: data.userId,
              tsId: data.tsId,
              firstName: data.firstName,
              middleName: data.middleName !== null ? data.middleName : "",
              lastName: data.lastName !== null ? data.lastName : "",
              motherName: data.motherName,
              dateOfBirth: data.dateOfBirth,
              gender: data.gender,
              aadhaarNo: data.aadhaarNo,
              annualIncome: data.annualIncome.id,
              annualIncomeName: data.annualIncome.name,
              birthPlace: data.birthPlace,
              bloodGroupName: data.bloodGroup.name,
              bloodGroup: data.bloodGroup.id,
              studCategory: data.category.id,
              category: data.category.name,
              cast: data.cast,
              castCertificateDate: data.castCertificateDate,
              castCertificateNo: data.castCertificateNo,
              religion: data.religion.name,
              religionId: data.religion.id,
              intrestIn: data.intrestIn,
              defenceType: data.defenceType,
              domicileof: data.domicileof,
              fatherName: data.fatherName,
              fatherOccupation: data.fatherOccupation,

              handicapCategory: data.handicapCategory,
              isOrphan: data.isOrphan,
              isStudentNriForeignNational: data.isStudentNriForeignNational,
              landlineNo: data.landlineNo,

              martialStatus: data.martialStatus,

              minority: data.minority,

              motherTongue: data.motherTongue,
              nationality: data.nationality,
              oms: data.oms,
              parentEmail: data.parentEmail,
              parentMobile: data.parentMobile,
              permanentAddressLine1: data.permanentAddressLine1,
              permanentAddressLine2: data.permanentAddressLine2,
              permanentAddressLine3: data.permanentAddressLine3,
              permanentCity: data.permanentCity,
              permanentDistrictName: data.permanentDistrict.name,
              permanentDistrict: data.permanentDistrict.id,
              permanentPin: data.permanentPin,
              permanentState: data.permanentState.id,
              permanentTaluka: data.permanentTaluka.id,
              permanentStateName: data.permanentState.name,
              permanentTalukaName: data.permanentTaluka.name,
              profilePic: data.profilePic,
              remark: data.remark,
              ruralUrban: data.ruralUrban,
              scholarship: data.scholarship,
              signature: data.signature,
              studentEmail: data.studentEmail,
              studentMobile: data.studentMobile,
              subCast: data.subCast,
              temporaryAddressLine1: data.temporaryAddressLine1,
              temporaryAddressLine2: data.temporaryAddressLine2,
              temporaryAddressLine3: data.temporaryAddressLine3,
              temporaryCity: data.temporaryCity,
              temporaryDistrict: data.temporaryDistrict.id,
              temporaryDistrictName: data.temporaryDistrict.name,
              temporaryPin: data.temporaryPin,
              temporaryState: data.temporaryState.id,
              temporaryStateName: data.temporaryState.name,
              temporaryTaluka: data.temporaryTaluka.id,
              temporaryTalukaName: data.temporaryTaluka.name,
              type: data.type,
              validityCdDate: data.validityCdDate,
              validityCdNo: data.validityCdNo,
            };
            console.log("getStudentById");
            console.log(userRegistrationSave);
            dispatch(applicantSuccess({ userRegistrationSave }));
            return response;
          }
        })
        .catch((error) => {
          console.log("Error========>", error);
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;

      // return console.error( e.message );
    }
  };

export const getProfile = () => async (dispatch) => {
  try {
    console.log(URL);
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    const doc = await axios
      .get(profileURL, { headers })
      .then((response) => {
        console.log("Success institute getProfile========>", response);
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        } else {
          let data = response.data.data;
          let row = [];
          console.log(data);
          let userRegistrationSave = {
            id: data.id === null ? "" : data.id,
            userId: data.userId === null ? "" : data.userId,
            tsId: data.tsId === null ? "" : data.tsId,
            firstName: data.firstName === null ? "" : data.firstName,
            middleName: data.middleName === null ? "" : data.middleName,
            lastName: data.lastName === null ? "" : data.lastName,
            motherName: data.motherName === null ? "" : data.motherName,
            dateOfBirth: data.dateOfBirth === null ? "" : data.dateOfBirth,
            gender: data.gender === null ? "" : data.gender,
            aadhaarNo: data.aadhaarNo === null ? "" : data.aadhaarNo,
            annualIncome:
              data.annualIncome === null ? "" : data.annualIncome.id,
            annualIncomeName:
              data.annualIncome === null ? "" : data.annualIncome.name,
            birthPlace: data.birthPlace === null ? "" : data.birthPlace,
            bloodGroupName:
              data.bloodGroup === null ? "" : data.bloodGroup.name,
            bloodGroup: data.bloodGroup === null ? "" : data.bloodGroup.id,
            studCategory: data.category === null ? "" : data.category.id,
            category: data.category === null ? "" : data.category.name,
            cast: data.cast === null ? "" : data.cast,
            castCertificateDate:
              data.castCertificateDate === null ? "" : data.castCertificateDate,
            castCertificateNo:
              data.castCertificateNo === null ? "" : data.castCertificateNo,
            religion: data.religion === null ? "" : data.religion.name,
            religionId: data.religion === null ? "" : data.religion.id,
            intrestIn: data.intrestIn === null ? "" : data.intrestIn,
            defenceType: data.defenceType === null ? "" : data.defenceType,
            domicileof: data.domicileof === null ? "" : data.domicileof,
            fatherName: data.fatherName === null ? "" : data.fatherName,
            fatherOccupation:
              data.fatherOccupation === null ? "" : data.fatherOccupation,
            handicapCategory:
              data.handicapCategory === null ? "" : data.handicapCategory,
            isOrphan: data.isOrphan === null ? "" : data.isOrphan,
            isStudentNriForeignNational:
              data.isStudentNriForeignNational === null
                ? ""
                : data.isStudentNriForeignNational,
            landlineNo: data.landlineNo === null ? "" : data.landlineNo,
            martialStatus:
              data.martialStatus === null ? "" : data.martialStatus,
            minority: data.minority === null ? "" : data.minority,
            motherTongue: data.motherTongue === null ? "" : data.motherTongue,
            nationality: data.nationality === null ? "" : data.nationality,
            oms: data.oms === null ? "" : data.oms,
            parentEmail: data.parentEmail === null ? "" : data.parentEmail,
            parentMobile: data.parentMobile === null ? "" : data.parentMobile,
            permanentAddressLine1:
              data.permanentAddressLine1 === null
                ? ""
                : data.permanentAddressLine1,
            permanentAddressLine2:
              data.permanentAddressLine2 === null
                ? ""
                : data.permanentAddressLine2,
            permanentAddressLine3:
              data.permanentAddressLine3 === null
                ? ""
                : data.permanentAddressLine3,
            permanentCity:
              data.permanentCity === null ? "" : data.permanentCity,
            permanentDistrictName:
              data.permanentDistrict === null
                ? ""
                : data.permanentDistrict.name,
            permanentDistrict:
              data.permanentDistrict === null ? "" : data.permanentDistrict.id,
            permanentPin: data.permanentPin === null ? "" : data.permanentPin,
            permanentState:
              data.permanentState === null ? "" : data.permanentState.id,
            permanentTaluka:
              data.permanentTaluka === null ? "" : data.permanentTaluka.id,
            permanentStateName:
              data.permanentState === null ? "" : data.permanentState.name,
            permanentTalukaName:
              data.permanentTaluka === null ? "" : data.permanentTaluka.name,
            profilePic: data.profilePic === null ? "" : data.profilePic,
            remark: data.remark === null ? "" : data.remark,
            mediumOfLanguageInPreviousCollege:
              data.mediumOfLanguageInPreviousCollege === null
                ? ""
                : data.mediumOfLanguageInPreviousCollege,
            ruralUrban: data.ruralUrban === null ? "" : data.ruralUrban,
            scholarship: data.scholarship === null ? "" : data.scholarship,
            signature: data.signature === null ? "" : data.signature,
            studentEmail: data.studentEmail === null ? "" : data.studentEmail,
            studentMobile:
              data.studentMobile === null ? "" : data.studentMobile,
            subCast: data.subCast === null ? "" : data.subCast,
            temporaryAddressLine1:
              data.temporaryAddressLine1 === null
                ? ""
                : data.temporaryAddressLine1,
            temporaryAddressLine2:
              data.temporaryAddressLine2 === null
                ? ""
                : data.temporaryAddressLine2,
            temporaryAddressLine3:
              data.temporaryAddressLine3 === null
                ? ""
                : data.temporaryAddressLine3,
            temporaryCity:
              data.temporaryCity === null ? "" : data.temporaryCity,
            temporaryDistrict:
              data.temporaryDistrict === null ? "" : data.temporaryDistrict.id,
            temporaryDistrictName:
              data.temporaryDistrict === null
                ? ""
                : data.temporaryDistrict.name,
            temporaryPin: data.temporaryPin === null ? "" : data.temporaryPin,
            temporaryState:
              data.temporaryState === null ? "" : data.temporaryState.id,
            temporaryStateName:
              data.temporaryState === null ? "" : data.temporaryState.name,
            temporaryTaluka:
              data.temporaryTaluka === null ? "" : data.temporaryTaluka.id,
            temporaryTalukaName:
              data.temporaryTaluka === null ? "" : data.temporaryTaluka.name,
            type: data.type === null ? "" : data.type,
            validityCdDate:
              data.validityCdDate === null ? "" : data.validityCdDate,
            validityCdNo: data.validityCdNo === null ? "" : data.validityCdNo,
          };
          localStorage.setItem(
            "person",
            data.firstName === null ? "" : data.firstName
          );
          localStorage.setItem("type", data.type === null ? "" : data.type);
          localStorage.setItem(
            "userRegistrationId",
            data.id === null ? "" : data.id
          );
          localStorage.setItem(
            "intrestIn",
            data.intrestIn === null ? "" : data.intrestIn
          );
          console.log("userRegistrationSave");
          console.log(userRegistrationSave);
          dispatch(applicantSuccess({ userRegistrationSave }));

          return userRegistrationSave;
        }
      })
      .catch((error) => {
        console.log("Error getInstitute ========>", error);
        dispatch(serverFailed());
        return false;
      });
    return doc;
  } catch (e) {
    profileFailure();
    dispatch(serverFailed());
    return false;

    // return console.error( e.message );
  }
};

export const resetDelete = () => async (dispatch) => {
  try {
    dispatch(resetDeleteSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

let parentMobileUrl = endpoints.parentMobile;
export const getParentContactValidation =
  ({ contact }) =>
  async (dispatch) => {
    try {
      console.log("get Annual Income /////");
      console.log(Headers);
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      const doc = await axios
        .get(parentMobileUrl + "?mobileNumber=" + contact, {
          headers,
        })
        .then((response) => {
          dispatch(parentMobileSuccess({ response }));
          return response;
        })
        .catch((error) => {
          console.log("Error getStudent========>", error);
          dispatch(serverFailed());
          return false;
        });
      return doc;
    } catch (e) {
      dispatch(serverFailed());
      return false;
    }
  };
