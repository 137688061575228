import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { decodeData } from "../../components/Comman/Util/Base64";
import endpoints from "../../config/endpoints";
import { tokenExp } from "../Login/login.slice";

const initialState = {
  religion: [],
  religionData: [],
  errorMsg: "",
  isFetch: false,
};
let URL = endpoints.religion;
const religionSlice = createSlice({
  name: "religion",
  initialState,
  reducers: {
    religionSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        religion: row,
      };
    },
    religionFailure: (state = cloneDeep(initialState), action) => {
      const { errormsg } = action.payload;
      return {
        ...state,
        errorMsg: errormsg,
        religion: [],
      };
    },
    religionListByNameSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        religionData: data,
        isFetch: true,
      };
    },
    religionListByNameFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        religionData: [],
        isFetch: false,
      };
    },
    serverFailed: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: true,
        error: false,
        isDelete: false,
      };
    },
    resetDeleteSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error: false,
        isDelete: false,
        isSave: false,
        isServerFail: false,
      };
    },
  },
});

export const {
  religionSuccess,
  resetDeleteSuccess,
  serverFailed,
  religionFailure,
  religionListByNameSuccess,
  religionListByNameFailure,
} = religionSlice.actions;

export default religionSlice.reducer;

export const getReligion = () => async (dispatch) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    let docs = await axios
      .get(URL + '?filters={"delStatus": "1","_mode" : "EXACT"}', { headers })
      .then((response) => {
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        }
        console.log(
          "Success get religion  ========>",
          response.data.data.content
        );
        let data = response.data.data.content;

        let row = [];
        let index = 0;
        data.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            isActive: data1.isActive === 1 ? "Active" : "Inactive",
          };
          row.push(bData);
          return data1;
        });
        dispatch(religionSuccess({ row }));
        return row;
      })
      .catch((error) => {
        dispatch(serverFailed());

        console.log("Error get religion========>", error);
      });
    return docs;
  } catch (e) {
    dispatch(serverFailed());

    return console.error(e.message);
  }
};
export const getReligionByName =
  ({ religionName }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      let docs = await axios
        .get(
          URL +
            '?filters={"delStatus": "1","_mode" : "EXACT"},{"name":"' +
            religionName +
            '","_mode" : "EXACT"}',
          { headers }
        )
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          console.log(
            "Success getReligionByName  ========>",
            response.data.data.content
          );
          let data = response.data.data.content;

          dispatch(religionListByNameSuccess({ data }));
          return data;
        })
        .catch((error) => {
          dispatch(serverFailed());

          console.log("Error getReligion========>", error);
        });
      return docs;
    } catch (e) {
      dispatch(serverFailed());

      return console.error(e.message);
    }
  };

export const deleteReligionData = () => async (dispatch) => {
  try {
    dispatch(religionListByNameFailure());
  } catch (e) {
    return console.error(e.message);
  }
};
export const getReligionById = () => async (dispatch) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
    let docs = await axios
      .get(URL + "/1", { headers })
      .then((response) => {
        const { error } = response.data;
        if (error == true) {
          dispatch(tokenExp());
        }
        console.log("Success With id getReligionById ========>", response);
      })
      .catch((error) => {
        dispatch(serverFailed());

        console.log("ErrorgetReligionById ========>", error.response.data);
      });
    return docs;
  } catch (e) {
    dispatch(serverFailed());

    return console.error(e.message);
  }
};

export const saveReligion =
  ({ religionData }) =>
  async (dispatch) => {
    try {
      let data = JSON.stringify(religionData);

      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      let docs = await axios
        .post(URL, data, { headers })
        .then((response) => {
          const { error } = response.data;
          if (error == true) {
            dispatch(tokenExp());
          }
          console.log("Success With saveReligion ========>", response.data);
          const { data } = response.data;
          return data;
        })
        .catch((error) => {
          dispatch(serverFailed());

          console.log("ErrorsaveReligion ========>", error.response.data);
        });
      return docs;
    } catch (e) {
      dispatch(serverFailed());

      return console.error(e.message);
    }
  };

export const deleteReligionById =
  ({ religionId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      await axios
        .delete(URL + "/" + religionId, { headers })
        .then((response) => {
          console.log("Success With deleteReligionById ========>", response);
        })
        .catch((error) => {
          console.log("Error deleteReligionById========>", error.response.data);
          let errormsg = "Error Will Delete Data";

          dispatch(religionFailure({ errormsg }));

          return errormsg;
        });
    } catch (e) {
      return console.error(e.message);
    }
  };
  export const resetDelete = () => async (dispatch) => {
    try {
      dispatch(resetDeleteSuccess());
    } catch (e) {
      return console.error(e.message);
    }
  };
