import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Collapse, ListItemButton, Toolbar } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { alpha } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import * as React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import config from "../config/config";
import { logOut } from '../Pages/Login/login.slice';
const styles = (theme) => ({
  drawer: {
    color: theme.palette.primary.white,
    overflow: "hidden",
    // padding: `${theme.spacing(1)} ${theme.spacing(2)} !important`,
    cursor: "pointer",
    height: "auto",
    overflow: "auto",
    // borderTop: `1px solid ${alpha(theme.palette.primary.black, 0.16)}`,
    "& li": {
      color: theme.palette.primary.black,

      "text-decoration": "none",
      // marginLeft: theme.spacing(1),
      // marginRight: theme.spacing(1),
      borderRadius: '5px',
      // marginBottom: theme.spacing(2),
      // marginTop: theme.spacing(2)
    },
    "& li:hover": {
      "text-decoration": "none",
    },
    "& li:active": {
      color: alpha(theme.palette.primary.white, 3),
      backgroundColor: theme.palette.primary.main,
      "text-decoration": "none",
    },
  },
  paper: {
    // backgroundColor: theme.palette.primary.smokeGrey,
  },
  itemList: {
    "& .MuiTypography-root": {
      fontSize: "14px",
    },
  },
  navMenu: {
    color: alpha(theme.palette.primary.black, 0.9),

    cursor: "pointer",
    "text-decoration": "none",
    marginLeft: "16px !important",
    // marginRight: theme.spacing(1),
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    // padding: `${theme.spacing(1)} ${theme.spacing(2)} !important`,
    border: "1px",
    display: "block !important",
    "& :hover": {
      // color: theme.palette.primary.main,
      borderRadius: theme.spacing(2),
      "text-decoration": "none",
      width: "100%",
      display: "block",
    },
    "& :active": {
      color: theme.palette.primary.main,
      backgroundColor: alpha(theme.palette.primary.white, 3),
      "text-decoration": "none",
      borderRadius: theme.spacing(2),
    },
    "& .MuiListItemButton-root.Mui-selected": {
      //  backgroundColor: theme.palette.primary.smokeGrey,
      borderRadius: theme.spacing(2),
      width: "100%",
      backgroundColor: theme.palette.primary.white,
      color: theme.palette.primary.main,
      // padding: `${theme.spacing(1)} ${theme.spacing(2)} !important`,
    },
    "& .MuiListItemButton-root": {
      padding: "2px 0px",
    },

    "& .MuiListItemButton-root.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.white,
    },
    "& .MuiListItemButton-root:hover": {
      backgroundColor: theme.palette.primary.white,
    },
  },

  "MuiButtonBase-root": {
    border: "1px !important",
    cursor: "pointer",
    display: "inline-flex",
    position: "relative",
    "align-items": "center",
    "user-select": "none",
    "border-radius": "1px !important",
    "vertical-align": "middle",
    "justify-content": "center",
    "text-decoration": "none",
    "background-color": "transparent",
  },
});

const DrawerNav = ({ classes, logOut ,toggle, student, common }) => {
  const [open, setOpen] = React.useState(true);
  const [itemOpen, setItemOpen] = React.useState("");
  const navigate = useNavigate();
  const [currentSelectedMenu, setCurrentSelectedMenu] = React.useState("");
  const [currentSelectedSubMenu, setCurrentSelectedSubMenu] =
    React.useState("");
  const [loginRole, setLoginRole] = React.useState("");
  const handleClick = (itemName) => {
    setItemOpen(itemName);
    setOpen(!open);
  };
  React.useEffect(() => {
    let role = localStorage.getItem("role");
    let type = localStorage.getItem("type");
    if (role === "applicant" && type == 4) {
      setLoginRole("student");
    } else if (role === "applicant" && type != 4) {
      setLoginRole(role);
    } else {
      setLoginRole(role);
    }
  }, []);
  const changePath = (submenu, menu) => {
    if(menu==="/logout"){
      localStorage.removeItem("token");
      localStorage.clear()
      logOut()
      window.location.replace("/login");
    }
    if (submenu === "") {
      navigate(menu);
    } else {
      navigate(submenu);
    }
    setCurrentSelectedMenu(menu);
    setCurrentSelectedSubMenu(submenu);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        anchor="left"
        open={common.toggleNav}
        onClose={toggle}
        variant="persistent"
        sx={{
          width: config.drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: config.drawerWidth,
            boxSizing: "border-box",
            bgcolor: "primary.white",
            border: "none",
          },
          [`& .MuiListItem-root.Mui-selected`]: {
            color: "primary.white",
            bgcolor: "primary.main",
            border: "none",
          },  
          [`& .MuiListItem-root`]: {
            color: "primary.black",
           bgcolor: "primary.white",
            border: "none",
            padding:'2px 16px'
          },
          [`& .MuiList-root`]: {
            padding:'0px 8px'
          },
          
        }}
      >
        <Toolbar />
        <List className={classes.drawer}>
          {common.nav.map((item) => {
            let role = localStorage.getItem("role");
            let type = localStorage.getItem("type");
            // if (role === "applicant" && type == 4) {
            //   setLoginRole("student");
            // } else if (role === "applicant" && type != 4) {
            //   setLoginRole(role);
            // } else {
            //   setLoginRole(role);
            // }
            console.log("loginRole" + loginRole + "item" + item.roles[0]);
            if (
              role === "applicant" &&
              type == "4" &&
              "student" === item.roles[0]
            ) {
              return item?.subMenu ? (
                <>
                  <ListItem
                    selected={currentSelectedMenu === item.path}
                    key={item.name}
                    onClick={() => handleClick(item.name)}
                  >
                    <ListItemText
                      primary={item.name}
                      onClick={() => changePath("", item.path)}
                    />
                    {open && item.name === itemOpen ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItem>
                  <Collapse
                    in={open && item.name === itemOpen}
                    timeout="auto"
                    unmountOnExit
                  >
                    {item.subMenu.map((nav) => {
                      return (
                        <List
                          component="div"
                          disablePadding
                          className={classes.navMenu}
                        >
                          <ListItemButton
                            selected={currentSelectedSubMenu === nav.path}
                            name={nav.name}
                            onClick={() => changePath(nav.path, item.path)}
                          >
                            <ListItemText primary={nav.name} />
                          </ListItemButton>
                        </List>
                      );
                    })}
                  </Collapse>
                </>
              ) : (
                <>
                  <ListItem
                    key={item.name}
                    selected={currentSelectedMenu === item.path}
                    name={item.name}
                    onClick={() => changePath("", item.path)}
                  >
                    <ListItemText primary={item.name} />
                  </ListItem>
                </>
              );
            } else if (role === item.roles[0]) {
              return item?.subMenu ? (
                <>
                  <ListItem
                    selected={currentSelectedMenu === item.path}
                    key={item.name}
                    onClick={() => handleClick(item.name)}
                  >
                    <ListItemText
                      primary={item.name}
                      onClick={() => changePath("", item.path)}
                    />
                    {open && item.name === itemOpen ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItem>
                  <Collapse
                    in={open && item.name === itemOpen}
                    timeout="auto"
                    unmountOnExit
                  >
                    {item.subMenu.map((nav) => {
                      return (
                        <List
                          component="div"
                          disablePadding
                          className={classes.navMenu}
                        >
                          <ListItemButton
                            selected={currentSelectedSubMenu === nav.path}
                            name={nav.name}
                            onClick={() => changePath(nav.path, item.path)}
                          >
                            <ListItemText
                              className={classes.itemList}
                              primary={nav.name}
                            />
                          </ListItemButton>
                        </List>
                      );
                    })}
                  </Collapse>
                </>
              ) : (
                <>
                  <ListItem
                    key={item.name}
                    selected={currentSelectedMenu === item.path}
                    name={item.name}
                    onClick={() => changePath("", item.path)}
                  >
                    <ListItemText primary={item.name} />
                  </ListItem>
                </>
              );
            }
          })}
        </List>
      </Drawer>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  common: state.common,
  student: state.student,
});

const mapDispatchToProps = {logOut};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DrawerNav));
